import { EAvatarDir, type EFurniBehavior, EItemType } from '@vmk-legacy/common-ts'
import { getText } from '../assets/ExternalConfigManager.js'
import { DummyItem } from '../data/ItemStack.js'

export class RoomItem {
    itemId: number
    userId: number
    roomId: number
    defUid: string
    ref: number
    hgt: number
    index: number
    wallRef?: number
    wallX?: number
    wallY?: number
    rotation: EAvatarDir
    customData?: any
    createdAt: Date
    updatedAt: Date
    state: number
    teleporterId: number
    defType: EItemType
    internal: boolean
    behavior?: EFurniBehavior

    constructor({
        itemId,
        userId,
        roomId,
        defUid,
        ref,
        hgt = 0,
        index,
        wallRef,
        wallX,
        wallY,
        rotation,
        customData,
        createdAt,
        updatedAt,
        state,
        teleporterId,
        defType,
        internal = false,
        behavior
    }: {
        itemId: number
        userId: number
        index: number
        roomId: number
        defUid: string
        ref: number
        hgt: number
        wallRef: number
        wallX: number
        wallY: number
        rotation: EAvatarDir
        customData?: any
        createdAt: Date
        updatedAt: Date
        state: number
        teleporterId: number
        defType: EItemType
        internal?: boolean
        behavior?: EFurniBehavior
    }) {
        this.itemId = itemId
        this.userId = userId
        this.roomId = roomId
        this.defUid = defUid
        this.ref = ref
        this.hgt = hgt
        this.index = index
        this.wallRef = wallRef
        this.wallX = wallX
        this.wallY = wallY
        this.rotation = +rotation
        this.customData = customData
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.state = state
        this.teleporterId = teleporterId
        this.defType = defType
        this.internal = internal
        this.behavior = behavior

        if (defType === EItemType.Poster && ![3, 5].includes(this.rotation)) {
            this.rotation = EAvatarDir.SouthEast
        }
    }

    getName(): string {
        return getText('item.' + this.defUid)
    }

    toDummy(): DummyItem {
        return new DummyItem(this.itemId, this.defUid, this.defType, null, this.teleporterId, false)
    }
}
