import type { ScrollArea } from '../containers/ScrollArea.js'
import type { IAccordionField } from '../fields/IAccordionField.js'
import { ResponsiveContainer } from '../views/AlertView.js'
import type { AccordionListItem } from './AccordionListItem.js'

export class AccordionList extends ResponsiveContainer {
    #rootItems: AccordionListItem[] = []
    #childItems: AccordionListItem[] = []
    #scrollContainer?: ScrollArea

    constructor(container?: ScrollArea) {
        super()

        this.eventMode = 'static'
        this.interactiveChildren = true
        this.#scrollContainer = container
        this.#scrollContainer?.setContent(this)
    }

    override sizeDidChange(): void {
        //
    }

    clear(): void {
        this.#rootItems = []
        this.#childItems = []
        this.removeChildren()
    }

    clearSelection(): void {
        for (const item of this.#rootItems) {
            const field = item.getChildAt(0) as IAccordionField
            field.deselect()
        }
        for (const item of this.#childItems) {
            const field = item.getChildAt(0) as IAccordionField
            field.deselect()
        }
    }

    add<T extends AccordionListItem>(item: T, doUpdate = true): T {
        this.#rootItems.push(item)

        if (doUpdate) {
            this.update()
        }

        return item
    }

    setExpanded(item: AccordionListItem, expanded: boolean): void {
        console.log(expanded ? 'expanding' : 'contracting')
        item.expanded = expanded
        this.update()
    }

    update(): void {
        this.removeChildren()
        this.#childItems = []

        const listItemPadding = 4
        let nextY = 0

        for (const item of this.#rootItems) {
            this.addChild(item)
            for (let k = 1; k < item.children.length; k++) {
                item.children[k].visible = false
            }
            item.y = nextY

            if (item.expanded) {
                let subNextY = item.height + listItemPadding
                for (let k = 1; k < item.children.length; k++) {
                    const subItem = item.children[k] as AccordionListItem
                    this.#childItems.push(subItem)
                    subItem.visible = true
                    subItem.x = item.width - subItem.width
                    subItem.y = subNextY
                    subNextY += subItem.height + listItemPadding
                }
            }
            nextY += item.height + listItemPadding
        }

        this.refitChildren()
    }
}
