import { EPerm } from '@vmk-legacy/common-ts'
import type { AvatarOutfit } from '@vmk-legacy/render-utils'
import type { DummyItem, ItemStack } from './ItemStack.js'

export class SelfRecord {
    #credits: number
    #id: number
    #ign: string
    #lanyard: DummyItem[] = []
    #inventory: Map<string, ItemStack> = new Map()
    #badges: number[] = []
    #outfit: AvatarOutfit
    #signature: string
    #mickeys: number[] = []
    #perms: EPerm[] = []
    #sesToken: string
    #film: number | null
    #rechargingPins: number[] = []

    getIgn(): string {
        return this.#ign
    }

    setIgn(ign: string): void {
        this.#ign = ign
    }

    setTkn(tkn: string): void {
        this.#sesToken = tkn
    }

    getTkn(): string {
        return this.#sesToken
    }

    setMickeys(mickeys: number[]): void {
        this.#mickeys = mickeys
    }

    setPerms(perms: EPerm[]): void {
        this.#perms = perms
    }

    isStaff(): boolean {
        return this.can(EPerm.MKAccessClient)
    }

    can(perm: EPerm): boolean {
        return this.#perms && this.#perms.includes(perm)
    }

    getMickeys(): number[] {
        return this.#mickeys
    }

    getId(): number {
        return this.#id
    }

    getRoomIdentifier(): string {
        return String(this.getId())
    }

    setId(id: number): void {
        this.#id = id
    }

    setFilm(film: number): void {
        this.#film = film
    }

    setSignature(signature: string): void {
        this.#signature = signature
    }

    getSignature(): string {
        return this.#signature
    }

    setLanyard(items: DummyItem[]): void {
        this.#lanyard = items
    }

    setBadges(ids: number[]): void {
        this.#badges = ids
    }

    setOutfit(outfit: AvatarOutfit): void {
        this.#outfit = outfit
    }

    getOutfit(): AvatarOutfit {
        return this.#outfit
    }

    getLanyard(): DummyItem[] {
        return this.#lanyard
    }

    setCredits(credits: number): void {
        this.#credits = credits
    }

    getCredits(): number {
        return this.#credits
    }

    getInventory(): Map<string, ItemStack> {
        return this.#inventory
    }

    getFilm(): number {
        return this.#film
    }

    setRecharging(pins: number[]): void {
        this.#rechargingPins = pins
    }

    getRecharging(): number[] {
        return this.#rechargingPins
    }
}
