import { Client } from '../../../Client'
import type { Animation } from '../../Animation.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class ShipwreckHandler extends WalkableRoomExtension {
    static override identifier = 'shipwreckDoors'

    doorLeft: { open: Animation; close: Animation }
    doorRight: { open: Animation; close: Animation }

    async init() {
        await super.init()

        this.doorLeft = {
            open: this.room.animationMap.get('vmk_anim_2') as Animation,
            close: this.room.animationMap.get('vmk_anim_1') as Animation
        }
        this.doorRight = {
            open: this.room.animationMap.get('vmk_anim_4') as Animation,
            close: this.room.animationMap.get('vmk_anim_6') as Animation
        }
        /*
            "vmk_anim_1": "shipwreck_l_door_a_close.ani",
        "vmk_anim_2": "shipwreck_l_door_a_open.ani",
        "vmk_anim_3": "shipwreck_r_door_a_close.ani",
        "vmk_anim_4": "shipwreck_r_door_b_open.ani",
        "vmk_anim_5": "shipwreck_l_door_b_close.ani",
        "vmk_anim_6": "shipwreck_r_door_b_close.ani",
        "vmk_anim_7": "shipwreck_sharkfish.ani",
        "vmk_anim_10": "shipwreck_l_door_b_open.ani",
        "vmk_anim_11": "shipwreck_r_door_a_open.ani",
        "vmk_anim_8": "shipwreck_light_beams.ani"
             */

        for (const door of [this.doorRight, this.doorLeft]) {
            door.open.score.autoplays = false
            door.open.score.loops = false
            door.close.score.autoplays = false
            door.close.score.loops = false
            door.open.score.hidesWhenPaused = true
            door.close.score.hidesWhenPaused = false
        }

        this.doorLeft.close.score.goToFrame(this.doorLeft.close.score.getLastFrame())
        this.doorRight.close.score.goToFrame(this.doorRight.close.score.getLastFrame())

        Client.shared.serverBroker.onEvent('door', ([left, open]: [left: boolean, open: boolean]) => {
            console.log('door', left, open)
            if (open) {
                this.openDoor(left ? 'Left' : 'Right')
            } else {
                this.closeDoor(left ? 'Left' : 'Right')
            }
        })

        return this
    }

    openDoor(side: 'Left' | 'Right') {
        console.log('opening ' + side + ' door')

        const door = this['door' + side] as {
            open: Animation
            close: Animation
        }

        door.close.score.hidesWhenPaused = true
        door.open.score.hidesWhenPaused = false
        door.open.score.playFromBeginning(true)
        door.close.score.pause()
    }

    closeDoor(side: 'Left' | 'Right') {
        console.log('closing ' + side + ' door')

        const door = this['door' + side] as {
            open: Animation
            close: Animation
        }

        door.open.score.hidesWhenPaused = true
        door.close.score.hidesWhenPaused = false
        door.close.score.playFromBeginning(true)
        door.open.score.pause()
    }

    override teardown() {
        super.teardown()

        Client.shared.serverBroker.offEvent('door')
    }
}
