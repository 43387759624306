import { Pooler } from '@vmk-legacy/render-utils'
import { Container } from 'pixi.js'

export class ContentBox extends Container {
    constructor(innerWidth: number, innerHeight: number, excludeTop = false) {
        super()
        const topLeft = Pooler.newSprite('content.top.left')
        const topMiddle = Pooler.newSprite('content.top.middle')
        const topRight = Pooler.newSprite('content.top.right')
        const rightMiddle = Pooler.newSprite('content.right.middle')
        const bottomRight = Pooler.newSprite('content.bottom.right')
        const bottomMiddle = Pooler.newSprite('content.bottom.middle')
        const bottomLeft = Pooler.newSprite('content.bottom.left')
        const leftMiddle = Pooler.newSprite('content.left.middle')
        const middle = Pooler.newSprite('content.middle.middle')
        topLeft.x = topLeft.y = 0
        topMiddle.x = topLeft.width
        topMiddle.width = innerWidth
        topRight.x = topLeft.width + topMiddle.width
        rightMiddle.x = topRight.x
        rightMiddle.y = topMiddle.height
        rightMiddle.height = innerHeight
        bottomRight.x = rightMiddle.x
        bottomRight.y = topRight.height + rightMiddle.height
        bottomMiddle.x = bottomLeft.width
        bottomMiddle.y = bottomRight.y
        bottomMiddle.width = innerWidth
        bottomLeft.y = bottomMiddle.y
        leftMiddle.y = topLeft.height
        leftMiddle.height = innerHeight
        middle.x = topLeft.width
        middle.y = topLeft.height
        middle.width = innerWidth
        middle.height = innerHeight
        this.addChild(
            topLeft,
            topMiddle,
            topRight,
            rightMiddle,
            bottomRight,
            bottomMiddle,
            bottomLeft,
            leftMiddle,
            middle
        )

        if (excludeTop) {
            topLeft.visible = topMiddle.visible = topRight.visible = false
        }
    }
}
