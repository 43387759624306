import type { ItemDefUid } from '@vmk-legacy/common-ts'
import { EItemType } from '@vmk-legacy/common-ts'
import type { AssetProvider } from '@vmk-legacy/render-utils'
import { buildClothingItem, Pooler } from '@vmk-legacy/render-utils'
import type { RenderTexture, Texture } from 'pixi.js'
import { getText } from '../assets/ExternalConfigManager.js'
import { ItemPackCache, PinCache } from '../assets/PinCache.js'
import { Client } from '../Client.js'

export class ItemStack implements StackedProvider {
    readonly defUid: ItemDefUid

    constructor(
        protected mainOwnedId: number,
        defUid: string | number,
        readonly defType: EItemType,
        protected count: number,
        readonly stars?: number,
        readonly teleporterId?: number,
        readonly single?: boolean,
        readonly expires?: number
    ) {
        this.defUid = String(defUid)
    }

    get name(): string {
        return this.getName()
    }

    getName(): string {
        return getText('item.' + this.defUid)
    }

    get key(): string {
        return this.defUid + '.' + (this.teleporterId || 0) + '.' + (this.stars || 0) + '.' + (this.expires || 0)
    }

    setMainId(id: number): void {
        this.mainOwnedId = id
    }

    setCount(count: number): void {
        this.count = count
    }

    getMainId(): number {
        return this.mainOwnedId
    }

    getKey(): string {
        return this.key
    }

    getCount(): number {
        return this.count
    }

    getNote(): string | null {
        if (this.teleporterId) {
            return String(this.mainOwnedId)
        }

        return null
    }

    getRendition(provider: AssetProvider): Promise<Texture> {
        return ItemStack.getRendition(this.defUid, this.defType, provider)
    }

    static async getRendition(defUid: ItemDefUid, defType: EItemType, provider: AssetProvider): Promise<Texture> {
        switch (defType) {
            case EItemType.Clothing: {
                const container = await buildClothingItem(defUid, 3, Client.shared.figuresMgr, provider)
                const tex = Client.shared.renderer.generateTexture(container)
                Pooler.release(container)
                return tex
            }
            case EItemType.Poster:
            case EItemType.Furniture:
                return await Client.shared.furniMapMgr.getThumb(
                    Client.shared.renderer,
                    defUid,
                    defType === EItemType.Poster,
                    provider
                )
            case EItemType.Pin:
                return await PinCache.instance.getTexture(defUid, true)
            case EItemType.Pack:
                return await ItemPackCache.instance.getTexture(`pack_${defUid}`)
            default:
                throw new Error(`could not load thumb for item type ${defType}`)
        }
    }
}

export class DummyItem implements StackedProvider {
    readonly defUid: ItemDefUid

    constructor(
        readonly id: number,
        defUid: ItemDefUid,
        readonly defType: EItemType,
        readonly stars: number | null = null,
        readonly teleporterId: number | null = null,
        readonly single: boolean | null = null,
        readonly expires: number | null = null
    ) {
        this.defUid = String(defUid)
    }

    getName(): string {
        return getText(`item.${this.defUid}`)
    }

    getKey(): string {
        return `${this.id}=${this.defUid}.${this.teleporterId || 0}.${this.stars || 0}.${this.expires || 0}`
    }

    getNote(): string | null {
        return null
    }

    getCount(): number {
        return 1
    }

    getRendition(provider: AssetProvider): Promise<Texture> {
        return ItemStack.getRendition(this.defUid, this.defType, provider)
    }
}

export interface StackedProvider {
    getKey(): string

    getCount(): number

    getRendition(provider: AssetProvider): Promise<Texture | RenderTexture>

    getName(): string

    getNote(): string | null
}
