import type { EAvatarDir } from '@vmk-legacy/common-ts'
import type { AssetProvider } from '@vmk-legacy/render-utils'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { Container, Texture } from 'pixi.js'
import type { RoomEntityVisualizing } from '../RoomObject.js'
import type { AvatarEntity } from './AvatarEntity.js'
import type { TileBoundVisual } from './WalkableEntity.js'

export class NPCVisual extends Container implements RoomEntityVisualizing, TileBoundVisual {
    factor: AvatarEntity

    readonly type = 'npc'
    sprite: Sprite
    private pulseCounter = 0
    animFrame = 0
    private currentTex: string

    constructor(
        readonly provider: AssetProvider,
        private direction: number,
        private ign: string,
        private actions: {
            wave?: boolean
            move?: boolean
            steer?: boolean
            sit?: boolean
            dance?: boolean
            carry?: boolean
            eat?: boolean
        } = {}
    ) {
        super()

        this.sprite = this.addChild(Pooler.newSprite('std_npc_1_1_0'))
        this.sprite.pivot.y = 38
    }

    getChatTint(): number | string | undefined {
        return '#1971C2' // push color
    }

    applyServerActions(actions: any): void {
        let didUpdate = false
        for (const a in actions) {
            if (actions.hasOwnProperty(a)) {
                if (this.actions[a] !== actions[a]) {
                    didUpdate = true
                    this.actions[a] = actions[a]
                }
            }
        }
        if (didUpdate) {
            this.animFrame = 0
        }
    }

    startWalking(): void {
        //
    }

    stopWalking(): void {
        //
    }

    setDirection(dir: EAvatarDir): void {
        this.direction = dir
        this.sprite.scale.x = dir <= 4 ? 1 : -1
    }

    setTileSize(tileSize: number): void {
        this.scale.set(tileSize / 32)
    }

    update = (): void => {
        if (++this.pulseCounter === 4) {
            this.pulseCounter = 0
        } else {
            return
        }
        const dir = this.direction > 4 ? 8 - this.direction : this.direction
        let newTex = 'std_npc_1_' + dir + '_0'

        if (this.actions.move) {
            this.animFrame = this.animFrame === 3 ? 0 : this.animFrame + 1
            newTex = 'wlk_npc_1_' + dir + '_' + this.animFrame
        } else if (this.actions.wave) {
            this.animFrame = this.animFrame === 1 ? 0 : this.animFrame + 1
            newTex = 'wav_npc_1_' + dir + '_' + this.animFrame
        } else {
            this.animFrame = 0
        }

        if (newTex !== this.currentTex) {
            this.currentTex = newTex
            this.sprite.texture = Texture.from(newTex)
            this.sprite.anchor.copyFrom(this.sprite.texture.defaultAnchor)
        }
    }
}
