import { Client } from '../../Client.js'
import type { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class ShowStep extends ServerEvent {
    static readonly type = MessageType.ShowStep

    async handle(payload: { step: string; data: any }) {
        const room = Client.shared.roomViewer as WalkableRoomViewer
        room?.serverShow?.stepReceived(payload)
    }
}
