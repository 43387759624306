import { Pooler } from '@vmk-legacy/render-utils'
import type { BitmapText, IDestroyOptions, Sprite } from 'pixi.js'
import { Graphics, Texture } from 'pixi.js'
import { LoadingScreensManager } from '../../assets/LoadingScreensManager.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { EWindow, UILayer } from '../../enums.js'
import { RoomJoinModule } from '../../server/messages/RoomJoinModule.js'
import type { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { MagicPinBox } from '../windows/FXWindow.js'
import LegacyWindow from '../windows/LegacyWindow.js'
import { VMKPassWindow } from '../windows/rooms/VMKPassWindow.js'
import { UIWindow } from '../windows/UIWindow.js'

const barWidth = 200
const barHeight = 20

export class RoomLoadingView extends UIWindow {
    readonly layer: UILayer = UILayer.Loaders
    readonly kind: EWindow = EWindow.Other

    async windowWasBuilt(): Promise<void> {
        //
    }

    private destText: BitmapText | Text
    private bg: Sprite
    progress = 0

    private barInner: Graphics
    private queuePlaceTxt: BitmapText | Text
    private cancelQueueBtn: BitmapTextButton
    private vmkPassBtn: BitmapTextButton
    private queuePlace: number

    constructor(
        protected destination: string,
        protected queuePlace?: number,
        protected canCancel = false
    ) {
        super()

        this.eventMode = 'static'
        this.isDraggable = false
    }

    async _buildWindow(innerHeight: number): Promise<void> {
        Client.shared.userInterface.toolbar?.disableChatbar()

        const black = Pooler.newSprite(Texture.WHITE)
        black.tint = 0x000000
        black.width = Client.shared.size.width
        black.height = Client.shared.size.height
        this.addChild(black)

        const bgTex = LoadingScreensManager.instance.getRandomLoadingScreenTexture()
        this.bg = Pooler.newSprite(bgTex)
        // in case image is not full size, center it
        this.bg.anchor.set(0.5, 0.5)
        this.bg.position.set(Client.shared.size.width / 2, Client.shared.size.height / 2)
        if (bgTex) {
            const fit = MagicPinBox.calculateAspectRatioFit(
                bgTex.width,
                bgTex.height,
                Client.shared.size.width,
                Client.shared.size.height
            )
            this.bg.width = fit.width
            this.bg.height = fit.height
        }
        this.addChild(this.bg)

        let loadingBox: LegacyWindow

        if (typeof this.queuePlace === 'undefined') {
            loadingBox = new LegacyWindow('vmk_loader')
            await loadingBox.waitToBeBuilt()

            loadingBox.setField('roomqueue.destination', this.destination)

            if (!this.canCancel) {
                loadingBox.hide('roomqueue.cancel')
            }
        } else {
            loadingBox = new LegacyWindow('vmk_loader_guest')
            await loadingBox.waitToBeBuilt()

            loadingBox.setField('roomqueue.destination', this.destination)

            this.cancelQueueBtn = <BitmapTextButton>loadingBox.getElement('roomqueue.cancel')
            this.vmkPassBtn = <BitmapTextButton>loadingBox.getElement('nav.fastpass.button')
            this.queuePlaceTxt = <Text>loadingBox.getElement('fastpass.queue')
            this.queuePlaceTxt.text = String(this.queuePlace)

            this.cancelQueueBtn.addEventListener('pointerup', () => {
                this.cancelQueueBtn.disable()
                Client.shared.serverBroker.send('queue_leave')
                Client.shared.userInterface.showWindow(EWindow.Navigator)
                Client.shared.userInterface.toolbar?.enableMapButtons()
                Client.shared.loadingView.setVisible(false)
                this.destroy()
            })

            this.vmkPassBtn.addEventListener('pointerup', () => {
                this.vmkPassBtn.disable()

                Client.shared.helpers
                    .confirm({
                        title: 'fastpass.get.hd',
                        message: 'fastpass.confirm.text'
                    })
                    .then((gotPass) => {
                        if (gotPass) {
                            if (VMKPassWindow.instance) {
                                VMKPassWindow.instance.setDestination(this.destination)
                                VMKPassWindow.instance.setQueuePlace(this.queuePlace)
                                Client.shared.userInterface.bringToFront(VMKPassWindow.instance)
                                return
                            }
                            Client.shared.userInterface.bringToFront(
                                new VMKPassWindow(this.destination, this.queuePlace)
                            )
                            Client.shared.userInterface.toolbar?.enableMapButtons()
                            Client.shared.userInterface.showWindow(EWindow.Navigator)
                            Client.shared.loadingView.setVisible(false)

                            this.destroy()
                        } else {
                            this.vmkPassBtn.enable()
                        }
                    })
            })
        }

        loadingBox.position.set(
            (Client.shared.size.width - loadingBox.width) / 2,
            (Client.shared.size.height - loadingBox.height) / 2
        )

        const barOuter = new Graphics()
        barOuter.beginFill(Constants.UI_LOADER_BAR_BG)
        barOuter.lineStyle(1, Constants.UI_LOADER_BAR_COLOR)
        barOuter.drawRect(0, 0, barWidth, barHeight)

        this.barInner = new Graphics()
        this.barInner.position.set(2, 2)

        this.setProgress(this.progress)

        barOuter.addChild(this.barInner)

        loadingBox.replaceElement('bar', barOuter)

        this.addChild(loadingBox)

        this.alreadyBuilt = true
        await this.windowWasBuilt()
    }

    setQueuePlace(place: number): void {
        this.queuePlace = place
        this.queuePlaceTxt.text = String(place)
        this.queuePlaceTxt.visible = true
        this.cancelQueueBtn.visible = true

        if (place === 0) {
            Client.shared.serverBroker.send('queue_pass')
        }
    }

    reset(): this {
        this.progress = 0

        return this
    }

    setProgress(prog: number): this {
        this.progress = prog

        if (this.barInner) {
            if (prog < this.progress) {
                this.barInner.clear()
            }

            this.barInner.beginFill(Constants.UI_LOADER_BAR_COLOR)
            this.barInner.drawRect(0, 0, ((barWidth - 4) * prog) / 100, barHeight - 4)
            this.barInner.endFill()
        }

        return this
    }

    destroy(_options?: IDestroyOptions | boolean): void {
        super.destroy(_options)

        if (RoomJoinModule.loader === this) {
            RoomJoinModule.loader = null
        }

        Client.shared.userInterface.toolbar.enableChatbar()
    }
}
