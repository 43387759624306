import { Client } from '../../Client.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class DisableTileRefs extends ServerEvent {
    static readonly type = MessageType.DISABLE_REFS

    async handle(refs: number[]) {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            Client.shared.roomViewer.setDisabledRefs(refs)
        }
    }
}
