interface IRoomInfo {
    name: string
    desc: string
    layoutId: string
    folders: string[]
}

export class RoomInfoManager {
    private data = new Map<number, IRoomInfo>()

    // [ layout_id_str, name, desc, asset_folders ]
    fill(data: { [space: number]: [string, string, string, string[]] }): void {
        this.data.clear()

        for (const [spaceId, info] of Object.entries(data)) {
            if (!info[0] || !info[3]) {
                continue
            }
            this.data.set(Number(spaceId), {
                layoutId: info[0],
                name: info[1],
                desc: info[2],
                folders: info[3]
            })
        }
    }

    getInfo(spaceId: number): IRoomInfo {
        return this.data.get(spaceId)
    }

    getAll(): Map<number, IRoomInfo> {
        return this.data
    }
}
