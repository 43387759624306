import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText, Sprite } from 'pixi.js'
import { Fonts } from '../../Fonts.js'
import type { IAccordionField } from './IAccordionField.js'

export class FieldHlp2 extends Sprite implements IAccordionField {
    private activeState: Container
    private selectedState: Container
    private selected: boolean
    private text: string

    constructor(text: string) {
        super()
        this.text = text
        this.activeState = Pooler.newContainer()
        this.selectedState = Pooler.newContainer()

        const active = Pooler.newContainer()
        const activeBG = Pooler.newSprite('field.hlp2.active')
        active.addChild(activeBG)

        const selected = Pooler.newContainer()
        const selectedBG = Pooler.newSprite('field.hlp2.selected')
        const fieldText = new BitmapText(text, {
            ...Fonts.Foxley_16
        })
        selected.addChild(selectedBG)

        this.activeState.addChild(active)
        this.selectedState.addChild(selected)

        this.addChild(this.selectedState)
        this.addChild(this.activeState)
        this.addChild(fieldText)
        fieldText.x = 5
        fieldText.y = 5

        this.selectedState.alpha = 0
        this.activeState.eventMode = 'static'
        this.activeState.cursor = 'pointer'

        this.selected = false
    }

    select(): void {
        this.selectedState.alpha = 1
        this.activeState.alpha = 0
        this.selected = true
    }

    deselect(): void {
        this.selectedState.alpha = 0
        this.activeState.alpha = 1
        this.selected = false
    }

    setSelected(selected: boolean): void {
        if (selected) {
            this.select()
        } else {
            this.deselect()
        }
    }

    isSelected(): boolean {
        return this.selected
    }

    getText(): string {
        return this.text
    }
}
