/*
 * RoomExtensions.ts
 * VMK Legacy Client
 */

import { BoatRoomHandler } from './extensions/BoatRoomHandler.js'
import { CollectablesExtension } from './extensions/CollectablesExtension'
import { DungeonHandler } from './extensions/DungeonHandler.js'
import { EsmeraldaHandler } from './extensions/EsmeraldaHandler.js'
import { GatorWaiterHandler } from './extensions/GatorWaiterHandler.js'
import { JCLobbyDockHandler } from './extensions/JCLobbyDockHandler'
import { MatterhornHandler } from './extensions/MatterhornHandler.js'
import { MonorailStationHandler } from './extensions/MonorailStationHandler.js'
import { MonorailTrainHandler } from './extensions/MonorailTrainHandler.js'
import { NedHandler } from './extensions/NedHandler.js'
import { PiratesPreshowHandler } from './extensions/PiratesPreshowHandler.js'
import { SharkReefHandler } from './extensions/SharkReefHandler.js'
import { ShipwreckHandler } from './extensions/ShipwreckHandler.js'
import { SteamboatHandler } from './extensions/SteamboatHandler.js'
import { StreetPartyMusicHandler } from './extensions/StreetPartyMusicHandler.js'
import { TomorrowlandRocketRideHandler } from './extensions/TomorrowlandRocketRideHandler.js'
import { TreasureRoomHandler } from './extensions/TreasureRoomHandler.js'
import { TreehouseHandler } from './extensions/TreehouseHandler.js'
import type { WalkableRoomExtension } from './extensions/WalkableRoomExtension.js'

// Returns extensions to use for the given space layout ID, in addition to any defined by the server.
// These may or may not have corresponding server-side extensions.
export function getBuiltInSpaceExtensions<ExtConcreteConstructor extends new (...args: any) => WalkableRoomExtension>(
    layoutId: string
): ExtConcreteConstructor[] {
    return (
        {
            shark_reef: [SharkReefHandler],
            castle_dungeon: [DungeonHandler],
            matterhorn: [MatterhornHandler],
            treehouse: [TreehouseHandler],
            emporium: [EsmeraldaHandler],
            steamboat: [SteamboatHandler],
            shrunken_neds_shop: [NedHandler],
            blue_bayou: [GatorWaiterHandler],
            treasure_room: [TreasureRoomHandler],
            dock: [JCLobbyDockHandler],
            tomorrowland_hub: [TomorrowlandRocketRideHandler],
            pirates_game_preshow: [PiratesPreshowHandler],
            monorail_station: [MonorailStationHandler],
            monorail_train: [MonorailTrainHandler],
            musicmix: [StreetPartyMusicHandler],
            shipwreck: [ShipwreckHandler]
        }[layoutId] ?? []
    )
}

// Room extensions which have server-side counterparts.
export const serverExtensions = [
    BoatRoomHandler,
    DungeonHandler,
    EsmeraldaHandler,
    GatorWaiterHandler,
    JCLobbyDockHandler,
    MonorailStationHandler,
    MonorailTrainHandler,
    PiratesPreshowHandler,
    SharkReefHandler,
    ShipwreckHandler,
    SteamboatHandler,
    StreetPartyMusicHandler,
    TomorrowlandRocketRideHandler,
    TreasureRoomHandler,
    CollectablesExtension
]
