import { Client } from '../../Client.js'
import type { Animation } from '../../room/Animation.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RoomScoreModule extends ServerEvent {
    static readonly type = MessageType.ROOM_SCORE_UPDATE

    async handle([scoreName, state, hideWhenPaused]: [string, 0 | 1 | string, boolean]): Promise<void> {
        if (Client.shared.roomViewer) {
            const anim =
                Client.shared.roomViewer.animationMap.get(scoreName) ??
                (Client.shared.roomViewer.sprites.getChildByName(scoreName) as Animation)
            const score = anim?.score

            if (!score) {
                console.log('Score not found to update.')
                return
            }
            score.hidesWhenPaused = hideWhenPaused

            if (typeof state === 'string') {
                score.goToMarker(state)
            } else if (state === 0) {
                // stopped and hidden
                score.reset(0, true)
            } else {
                score.playFromBeginning(true)
            }
        }
    }
}
