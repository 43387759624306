import type { Sprite } from 'pixi.js'
import { Client } from '../../../Client.js'
import { JungleCruiseInstructionPopup } from '../../../minigame/jungle_cruise/JungleCruiseInstructionPopup.js'
import { BoatRoomHandler } from './BoatRoomHandler.js'

export class JCLobbyDockHandler extends BoatRoomHandler {
    static override identifier = 'dock'

    private popup?: JungleCruiseInstructionPopup

    override async init(): Promise<this> {
        await super.init()

        const signupBoard = this.room.sprites.getChildByName('dock_signup_board') as Sprite

        signupBoard.eventMode = 'static'
        signupBoard.cursor = 'pointer'
        signupBoard.addEventListener('pointerup', () => {
            this.popup = new JungleCruiseInstructionPopup()

            Client.shared.userInterface.register(this.popup)
            Client.shared.userInterface.bringToFront(this.popup)
        })
        return this
    }

    override teardown(): void {
        if (this.popup) {
            Client.shared.userInterface.removeWindow(this.popup)
            delete this.popup
        }

        super.teardown()
    }
}
