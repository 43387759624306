import type { EItemType, ItemDefUid } from '@vmk-legacy/common-ts'
import type { AssetProvider } from '@vmk-legacy/render-utils'
import { getText } from '../../../../assets/ExternalConfigManager.js'
import type { StackedProvider } from '../../../../data/ItemStack.js'
import { ItemStack } from '../../../../data/ItemStack.js'
import type { ClientCatalogItem } from '../../../../server/messages/CatalogModule.js'

export class ShopItem implements StackedProvider {
    private catalogItem: ClientCatalogItem

    constructor(catalogItem: ClientCatalogItem) {
        this.catalogItem = catalogItem
    }

    getId(): number {
        return this.getCatalogId()
    }

    getCatalogId(): number {
        return this.catalogItem.id
    }

    getStars(): number {
        return 0
    }

    getDefUid(): ItemDefUid | number | undefined {
        return this.catalogItem.itemUid || this.catalogItem.spaceId || this.catalogItem.packId
    }

    getTeleporterId(): number {
        return 0
    }

    getName(): string {
        if (this.catalogItem.itemUid) {
            let name = getText('item.' + this.catalogItem.itemUid)

            if (name.includes('Teleporter')) {
                name += ' (Pair)'
            }
            return name
        }
        if (this.catalogItem.spaceId) {
            return getText('room.' + this.catalogItem.spaceId + '.name')
        }
        if (this.catalogItem.packId) {
            return getText('pack.' + this.catalogItem.packId)
        }
        return 'Unknown'
    }

    getPrice(): number {
        return this.catalogItem.price
    }

    getType(): EItemType {
        return this.catalogItem.type
    }

    isSingleUse(): boolean {
        return false
    }

    getKey(): string {
        return 'catitem-' + this.getCatalogId()
    }

    getCount(): number {
        return 1
    }

    getNote() {
        return null
    }

    getRendition(provider: AssetProvider) {
        return ItemStack.getRendition(String(this.getDefUid()), this.getType(), provider)
    }
}
