import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { VMKPassWindow } from '../../ui/windows/rooms/VMKPassWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import { RoomJoinModule } from './RoomJoinModule.js'

export class BootedModule extends ServerEvent {
    static override readonly type = MessageType.BOOTED

    override async handle(data?: any) {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            if (!data.roomId || data.roomId === Client.shared.roomViewer.getRoomId()) {
                Client.shared.userInterface.getWin(EWindow.Navigator, true)
                Client.shared.roomViewer?.teardown()
                Client.shared.loadingView.setVisible(false)
                RoomJoinModule.loader?.destroy()
                RoomJoinModule.loader = null
                Client.shared.userInterface.toolbar.enableMapButtons()
            }
        }

        if (data?.queue) {
            VMKPassWindow.instance?.destroy()
        }
    }
}
