import { BitmapText, Container, Graphics } from 'pixi.js'
import { Client } from '../../../Client.js'
import type { Ticket } from '../../../data/Ticket.js'
import { Fonts } from '../../../Fonts.js'
import type { ModerationView } from './views/ModerationView.js'
import { TicketTypes } from './views/ModerationView.js'

export class ReportListItem extends Container {
    constructor(
        readonly view: ModerationView,
        ticket: Ticket
    ) {
        super()

        const reportBG = new Graphics()
        reportBG.beginFill(
            ticket.modId === Client.shared.selfRecord.getId() ? 0x00aab3 : ticket.modId ? 0xb39500 : 0x255592
        )
        reportBG.drawRect(0, 0, 250, 56)
        reportBG.endFill()
        this.addChild(reportBG)

        const textOffsetX = 7
        const textOffsetY = 7

        const id = new BitmapText('CFH #' + ticket.ticketId, {
            ...Fonts.FoxleyBold_16
        })
        id.x = textOffsetX
        id.y = textOffsetY
        this.addChild(id)

        const from = new BitmapText(' from ', {
            ...Fonts.Foxley_16
        })
        from.x = id.x + id.textWidth
        from.y = id.y + 1
        this.addChild(from)

        const user = new BitmapText(ticket.sender, {
            ...Fonts.FoxleyBold_16
        })
        user.x = from.x + from.textWidth + 3
        user.y = id.y
        this.addChild(user)

        const dateStr =
            ticket.createdAt.toISOString().split('T')[0] + ' ' + ticket.createdAt.toTimeString().split('G')[0]

        const date = new BitmapText(dateStr + ' - ' + (ticket.modId ? 'Claimed' : 'Open'), Fonts.Foxley_16)
        date.x = id.x
        date.y = id.y + 16
        this.addChild(date)

        const subject = new BitmapText(
            TicketTypes[ticket.type] + ' ' + ('' !== ticket.reportedUser ? '(' + ticket.reportedUser + ')' : ''),
            Fonts.Foxley_16
        )
        subject.x = id.x
        subject.y = date.y + 15
        this.addChild(subject)

        this.eventMode = 'static'
        this.cursor = 'pointer'
    }
}
