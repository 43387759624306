import { Client } from '../../Client.js'
import type { AvatarEntity } from '../../room/entities/AvatarEntity.js'
import type { NPCEntity } from '../../room/entities/NPCEntity.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class EntityBeginMoving extends ServerEvent {
    static readonly type = MessageType.ENTITY_MOVING

    async handle([entityId, path]: [number, number[]]): Promise<void> {
        if (!(Client.shared.roomViewer instanceof WalkableRoomViewer)) {
            return
        }

        const entity = Client.shared.roomViewer.getEntityByRef(entityId) as AvatarEntity | NPCEntity

        entity?.startWalkingPath(path)
    }
}
