import { ERoomObjType } from '../RoomObject.js'
import { NPCVisual } from './NPCVisual.js'
import { WalkableEntity } from './WalkableEntity.js'

/**
 * A room entity which is a specially rendered NPC.
 */
export class NPCEntity extends WalkableEntity<NPCVisual> {
    readonly entityType = ERoomObjType.NPC

    protected createVisual(): NPCVisual {
        return new NPCVisual(this.roomContext.provider, this.direction, this.name, this.actions)
    }
}
