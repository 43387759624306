import type { Text } from 'pixi.js'
import { getText } from '../../../../assets/ExternalConfigManager.js'
import { Client } from '../../../../Client.js'
import { EWindow } from '../../../../enums.js'
import type { IMessageData } from '../../../../server/messages/ReceivedMessage.js'
import type { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import type { Scrollbar } from '../../../scrollbars/Scrollbar.js'
import type { HelpWindow } from '../../HelpWindow.js'
import LegacyWindow from '../../LegacyWindow.js'
import { MessengerWindow } from '../MessengerWindow.js'

export class MessengerView extends LegacyWindow {
    private countText: Text
    private fromText: Text
    private timeText: Text
    private messageScrollbar: Scrollbar
    private messageText: Text
    private reportBtn: BitmapTextButton
    private replyBtn: BitmapTextButton
    private deleteBtn: BitmapTextButton

    private currentMessage?: IMessageData

    windowTitle = 'MessagesNone'

    constructor(readonly msgWin: MessengerWindow) {
        super(MessengerWindow.messageData?.total ? 'vmk_mes_messages_new' : 'vmk_mes_messages_none')
    }

    override async windowWasBuilt(): Promise<void> {
        this.countText = this.getElement('mes.messages.newamount')
        const count = MessengerWindow.messageData?.total || 0
        this.countText.text = `You have ${count === 0 ? 'no' : count} new message${count === 1 ? '' : 's'}.`

        if (this.winDef === 'vmk_mes_messages_new') {
            this.fromText = this.getElement('mes.messages.sender')
            this.timeText = this.getElement('mes.messages.time')
            this.messageText = this.getElement('mes.messages.messagetext')
            this.messageScrollbar = this.getElement('mes.scrollbar')
            this.reportBtn = this.getElement('mes.report.button')
            this.deleteBtn = this.getElement('mes.nextmessage.button')
            this.replyBtn = this.getElement('mes.reply.button')

            this.reportBtn.addEventListener('pointerup', () => this.onReportTap())
            this.replyBtn.addEventListener('pointerup', () => this.onReplyTap())
            this.deleteBtn.addEventListener('pointerup', () => this.onDeleteTap())
        }
    }

    async setCurrentMessage(message: IMessageData) {
        console.log('showing message', message)
        // clear last message
        this.currentMessage = null

        // return if message is null
        if (!message) {
            if (this.winDef !== 'vmk_mes_messages_none') {
                this.winDef = 'vmk_mes_messages_none'
                await this.rebuild()
            }
            Client.shared.userInterface.toolbar.stopMessageGlow()
            return
        }

        if (this.winDef !== 'vmk_mes_messages_new') {
            this.winDef = 'vmk_mes_messages_new'
            await this.rebuild()
        } else {
            this.messageScrollbar?.setPercentScrolled(0)
        }
        this.reportBtn.enable()
        this.replyBtn.enable()
        this.deleteBtn.enable()
        this.reportBtn.visible = this.replyBtn.visible = this.deleteBtn.visible = true

        const time = new Date(message.ts * 1000)
        let body = message.txt

        this.setField('mes.messages.sender', message.ign)
        this.setField(
            'mes.messages.time',
            time.toLocaleDateString('en-US', {
                timeZone: 'America/New_York',
                hour: 'numeric',
                minute: '2-digit'
            })
        )

        // from staff?
        if (!message.pid) {
            // disable report and reply
            this.reportBtn.disable()
            this.replyBtn.disable()

            while (body.indexOf('%item.') > -1) {
                const startIndex = body.indexOf('%item.') + 6
                const endIndex = startIndex + body.substring(startIndex).indexOf('%')
                const id = body.substring(startIndex, endIndex)
                const itemName = getText('item.' + id)
                body = body.replace('%item.' + id + '%', itemName)
            }
        }

        // set new message
        this.currentMessage = message

        this.setField('mes.messages.messagetext', body)
    }

    setMessages() {
        const messages = MessengerWindow.messageData

        const count = messages.total
        const currentMessage = count ? messages.data[0] : null
        this.setCurrentMessage(currentMessage).then(() => {
            this.countText.text = `You have ${count === 0 ? 'no' : count} new message${count === 1 ? '' : 's'}.`
        })
    }

    onReplyTap() {
        this.msgWin.friendsView.toId = this.currentMessage.pid
        this.msgWin.friendsView.toIgn = this.currentMessage.ign

        this.msgWin.friendsView.setMessageContainerVisible(true)
        this.msgWin.setActiveTab(this.msgWin.friendsView.tab)

        this.onDeleteTap()
    }

    async onReportTap() {
        const help = (await Client.shared.userInterface.getWin(EWindow.Help, true)) as HelpWindow
        if (help) {
            help.beginReport(this.getElement('mes.messages.sender').text)
        }
    }

    onDeleteTap() {
        this.deleteBtn.disable()
        const requestMore = MessengerWindow.messageData.total > 1 && MessengerWindow.messageData.data.length === 1

        console.log(
            'Deleting ' +
                this.currentMessage.id +
                ', ' +
                MessengerWindow.messageData.data.length +
                '/' +
                MessengerWindow.messageData.total +
                ' loaded (' +
                MessengerWindow.messageData.per +
                ' per page)'
        )

        if (requestMore) {
            console.log('REQUESTING NEXT PAGE')
        }

        MessengerWindow.messageData.total--
        MessengerWindow.messageData.data.shift()

        Client.shared.serverBroker
            .sendAck('message_delete', {
                id: this.currentMessage.id,
                more: requestMore
            })
            .then((doUpdate) => {
                if (doUpdate) {
                    this.setMessages()
                }
                this.deleteBtn.enable()
            })
    }

    update() {}
}
