import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { MessengerWindow } from '../../ui/windows/messenger/MessengerWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import { MessagesModule } from './MessagesModule.js'

export interface IMessageData {
    id: number
    pid?: number
    ign: string
    ts: number
    txt: string
    urg?: boolean
}

export class ReceivedMessage extends ServerEvent {
    static readonly type = MessageType.MSG_SINGLE

    async handle(singleMsg: IMessageData) {
        console.log('received single message', singleMsg)

        MessengerWindow.messageData.total++
        MessengerWindow.messageData.data.unshift(singleMsg)

        const visible = (await Client.shared.userInterface?.getWin(EWindow.Messenger)) as MessengerWindow
        visible?.updateMessages()

        MessagesModule.glowOrShow()
    }
}
