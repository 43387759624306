import { Pooler } from '@vmk-legacy/render-utils'
import type { DisplayObject } from 'pixi.js'
import { Container, Sprite, Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import { UILayer } from '../../enums.js'
import type { IWindow } from '../windows/IWindow.js'

export interface ISizeChanging {
    refitChildren(size: SizeInfo): void

    sizeDidChange(size: SizeInfo): void

    // if this implementation gets a global position, make sure it is called after its parent has changed position
    refit(size: SizeInfo): void
}

export abstract class ResponsiveContainer extends Container implements ISizeChanging {
    constructor() {
        super()

        this.once('added', () => this.refit(Client.shared.size))
    }

    // Calls refit on the children. Do not override.
    refitChildren(size: SizeInfo): void {
        this.children.forEach((c) => {
            if ('refit' in c) {
                ;(c as ISizeChanging).refit(size)
            }
        })
    }

    // Calls sizeDidChange and propagates the call to the children. Do not override.
    refit(size: SizeInfo): void {
        this.sizeDidChange(size)
        this.refitChildren(size)
    }

    // Update the size and position of this object for the new parent size. Override me!
    sizeDidChange(size: SizeInfo): void {
        throw new Error('SizeChanging class does not implement sizeDidChange! Do not call the parent method.')
    }
}

export class SizeInfo {
    constructor(
        public bounds: {
            width: number
            height: number
        },
        public safeArea: {
            minX: number
            minY: number
            maxX: number
            maxY: number

            width: number
            height: number
        }
    ) {}

    safeAreaRespectsBars(): SizeInfo {
        const cloned = new SizeInfo({ ...this.bounds }, { ...this.safeArea })

        cloned.safeArea.minY += Client.shared.userInterface.getObscuringTopHeight()
        cloned.safeArea.maxY -= Client.shared.userInterface.getObscuringBottomHeight()
        cloned.safeArea.height -=
            Client.shared.userInterface.getObscuringBottomHeight() + Client.shared.userInterface.getObscuringTopHeight()

        return cloned
    }

    get width(): number {
        return this.bounds.width
    }

    get height(): number {
        return this.bounds.height
    }

    getBoundsCenter(forView?: Container): { x: number; y: number } {
        let x = this.bounds.width / 2
        if (forView) {
            if (forView instanceof Sprite) {
                x += forView.width * forView.anchor.x
            }
            x += forView.pivot.x
            x -= forView.width / 2
        }

        let y = this.bounds.height / 2
        if (forView) {
            if (forView instanceof Sprite) {
                y += forView.height * forView.anchor.y
            }
            y += forView.pivot.y
            y -= forView.height / 2
        }

        return {
            x,
            y
        }
    }

    boundsCenter(forView: Container): void {
        const center = this.getBoundsCenter(forView)

        forView.position.set(center.x, center.y)
    }

    getSafeAreaCenter(forView?: Container, fixedWidth?: number, fixedHeight?: number): { x: number; y: number } {
        let x = this.safeArea.minX + this.safeArea.width / 2
        if (forView) {
            const width = fixedWidth ?? forView.width
            if (forView instanceof Sprite) {
                x += forView.width * forView.anchor.x
            }
            x += forView.pivot.x
            x -= width / 2
        }

        let y = this.safeArea.minY + this.safeArea.height / 2
        if (forView) {
            const height = fixedHeight ?? forView.height
            if (forView instanceof Sprite) {
                y += forView.height * forView.anchor.y
            }
            y += forView.pivot.y
            y -= height / 2
        }

        return {
            x,
            y
        }
    }

    safeAreaCenter(forView: Container, fixedWidth?: number, fixedHeight?: number): void {
        const center = this.getSafeAreaCenter(forView, fixedWidth, fixedHeight)

        console.log('Centering ', forView, 'at', center)

        forView.position.set(center.x, center.y)
    }
}

export class AlertView extends ResponsiveContainer implements IWindow {
    private bg: Sprite
    layer = UILayer.AlwaysOnTop

    constructor(
        readonly alert: DisplayObject & ISizeChanging,
        readonly backgroundOpacity: number,
        readonly bgDismiss = false
    ) {
        super()

        this.bg = Pooler.newSprite(Texture.WHITE)
        this.bg.tint = 0x000000
        this.bg.width = Client.shared.size.bounds.width
        this.bg.height = Client.shared.size.bounds.height
        this.bg.alpha = backgroundOpacity
        this.addChild(this.bg)

        if (bgDismiss) {
            this.bg.eventMode = 'static'
            this.bg.addEventListener(
                'pointertap',
                (e) => {
                    e.stopPropagation()
                    this.destroy()
                },
                { once: true }
            )
        }

        this.eventMode = 'static'

        this.addChild(alert)
    }

    override sizeDidChange(size: SizeInfo): void {
        this.bg.width = size.bounds.width
        this.bg.height = size.bounds.height
    }
}
