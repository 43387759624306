import { Pooler } from '@vmk-legacy/render-utils'
import { BitmapText, Texture } from 'pixi.js'
import { Client } from '../../../../Client.js'
import { Fonts } from '../../../../Fonts.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import { DOMText } from '../../../DOMText.js'
import { FieldG } from '../../../fields/FieldG.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { InventoryWindow } from '../InventoryWindow.js'

export class RedemptionView extends UIWindowView<InventoryWindow> {
    errorTF: BitmapText
    errorTXT = 'Please enter 16 characters.'
    private code: string

    constructor() {
        super()

        // Text fields

        const redemptionTF = new BitmapText('Do you have a secret code to get exclusive VMK loot?', {
            ...Fonts.FoxleyBold_16,
            align: 'left',
            maxWidth: 374
        })
        redemptionTF.x = 0
        redemptionTF.y = 20
        this.addChild(redemptionTF)

        const redemptionHelpTF = new BitmapText(
            'Type in your code and press OK. Please wait 30 seconds between redemptions.',
            {
                ...Fonts.Foxley_16,
                maxWidth: 374,
                align: 'left'
            }
        )
        redemptionHelpTF.x = 0
        redemptionHelpTF.y = 40
        this.addChild(redemptionHelpTF)

        this.errorTF = new BitmapText(this.errorTXT, {
            ...Fonts.FoxleyBold_16,
            tint: 0xff0000
        })
        this.errorTF.x = Math.round((374 - this.errorTF.textWidth) / 2)
        this.errorTF.y = 110
        this.errorTF.alpha = 0
        this.addChild(this.errorTF)

        // Container for fields + button
        const redemptionBox = Pooler.newContainer()

        const spacer = redemptionBox.addChild(Pooler.newSprite(Texture.EMPTY))
        spacer.height = 70

        redemptionBox.y = 80

        const redemptionField1 = new DOMText({
            kind: 'field',
            className: 'redemption',
            id: 'code.1',
            maxlength: 4,
            fieldWidth: 60,
            fieldHeight: 18,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: '',
            bgObject: new FieldG(60, 11),
            padLeft: 20,
            padTop: 0
        })
        redemptionBox.addChild(redemptionField1)

        const separatorTF1 = new BitmapText('-', {
            ...Fonts.Foxley_16
        })
        separatorTF1.y = 5
        separatorTF1.x = redemptionField1.x + 72
        redemptionBox.addChild(separatorTF1)

        const redemptionField2 = new DOMText({
            kind: 'field',
            className: 'redemption',
            id: 'code.2',
            maxlength: 4,
            fieldWidth: 60,
            fieldHeight: 18,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: '',
            bgObject: new FieldG(60, 11),
            padLeft: 20,
            padTop: 0
        })
        redemptionField2.x = redemptionField1.x + 80

        redemptionBox.addChild(redemptionField2)

        const separatorTF2 = new BitmapText('-', {
            ...Fonts.Foxley_16
        })
        separatorTF2.y = 5
        separatorTF2.x = redemptionField2.x + 72
        redemptionBox.addChild(separatorTF2)

        const redemptionField3 = new DOMText({
            kind: 'field',
            className: 'redemption',
            id: 'code.3',
            maxlength: 4,
            fieldWidth: 60,
            fieldHeight: 18,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: '',
            bgObject: new FieldG(60, 11),
            padLeft: 20,
            padTop: 0
        })
        redemptionField3.x = redemptionField2.x + 80
        redemptionBox.addChild(redemptionField3)

        const separatorTF3 = new BitmapText('-', {
            ...Fonts.Foxley_16
        })
        separatorTF3.y = 5
        separatorTF3.x = redemptionField3.x + 72
        redemptionBox.addChild(separatorTF3)

        const redemptionField4 = new DOMText({
            kind: 'field',
            className: 'redemption',
            id: 'code.4',
            maxlength: 4,
            fieldWidth: 60,
            fieldHeight: 18,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: '',
            bgObject: new FieldG(60, 11),
            padLeft: 20,
            padTop: 0
        })

        redemptionField4.x = redemptionField3.x + 80
        redemptionBox.addChild(redemptionField4)

        const redeemBtn = new BitmapTextButton(55, 'OK', 'a')
        redeemBtn.x = redemptionField4.x + 80
        redemptionBox.addChild(redeemBtn)

        const elem1 = redemptionField1.getElement()
        const elem2 = redemptionField2.getElement()
        const elem3 = redemptionField3.getElement()
        const elem4 = redemptionField4.getElement()
        ;[elem1, elem2, elem3, elem4].forEach((el) => {
            el.spellcheck = false
            el.style.textTransform = 'uppercase'
            el.addEventListener('paste', (e: ClipboardEvent) => {
                const data = e.clipboardData.getData('text')
                if (data) {
                    const code = data.replaceAll('-', '').toUpperCase()

                    redemptionField1.setValue(code.substr(0, 4))
                    redemptionField2.setValue(code.substr(4, 4))
                    redemptionField3.setValue(code.substr(8, 4))
                    redemptionField4.setValue(code.substr(12, 4))
                    redemptionField4.forceFocus()
                }
            })
        })

        elem1.addEventListener('keyup', (e: KeyboardEvent) => {
            elem1.value = elem1.value.toUpperCase()
            if (elem1.value.length === 4) {
                if (e.key !== 'Backspace') {
                    redemptionField2.forceFocus()
                }
            } else if (elem1.value.length === 0) {
                //
            }
        })
        elem2.addEventListener('keyup', (e: KeyboardEvent) => {
            elem2.value = elem2.value.toUpperCase()
            if (elem2.value.length === 4) {
                if (e.key !== 'Backspace') {
                    redemptionField3.forceFocus()
                }
            } else if (elem2.value.length === 0) {
                if (e.key === 'Backspace') {
                    redemptionField1.forceFocus(e)
                }
            }
        })
        elem3.addEventListener('keyup', (e: KeyboardEvent) => {
            elem3.value = elem3.value.toUpperCase()
            if (elem3.value.length === 4) {
                if (e.key !== 'Backspace') {
                    redemptionField4.forceFocus()
                }
            } else if (elem3.value.length === 0) {
                if (e.key === 'Backspace') {
                    redemptionField2.forceFocus(e)
                }
            }
        })
        elem4.addEventListener('keyup', (e: KeyboardEvent) => {
            elem4.value = elem4.value.toUpperCase()
            if (elem4.value.length === 4) {
                //
            } else if (elem4.value.length === 0) {
                if (e.key === 'Backspace') {
                    redemptionField3.forceFocus(e)
                }
            }
        })

        redeemBtn.addEventListener('pointerup', () => {
            if (
                redemptionField1.getValue().length !== 4 ||
                redemptionField2.getValue().length !== 4 ||
                redemptionField3.getValue().length !== 4 ||
                redemptionField4.getValue().length !== 4
            ) {
                this.errorTF.alpha = 1
                this.errorTF.text = 'Please enter 16 characters.'
                this.errorTF.x = Math.round((374 - this.errorTF.textWidth) / 2)
                return
            }

            redeemBtn.disable()
            redeemBtn.setText('WAIT')

            this.code =
                redemptionField1.getValue() +
                redemptionField2.getValue() +
                redemptionField3.getValue() +
                redemptionField4.getValue()
            this.errorTF.alpha = 0

            Client.shared.serverBroker.sendAck('redeem_code', this.code).then((err) => {
                if (typeof err === 'string') {
                    this.errorTF.alpha = 1
                    this.errorTF.text = err
                    this.errorTF.x = Math.round((374 - this.errorTF.textWidth) / 2)
                } else {
                    this.errorTF.alpha = 0
                }
                setTimeout(() => {
                    if (!redeemBtn.destroyed) {
                        redeemBtn.enable()
                        redeemBtn.setText('OK')
                    }
                }, 25000)
            })
        })

        this.addChild(redemptionBox)

        this.update()
    }

    update() {}

    setReward() {
        // TODO: List rewards
    }
}
