import { FurniMapItem } from '@vmk-legacy/render-utils'
import { Point } from 'pixi.js'
import { Client } from '../../../Client.js'
import type { RoomItem } from '../../RoomItem.js'
import type FurniController from '../FurniController.js'
import { FurniEntity } from './FurniEntity.js'
import type { TileEntity } from './TileEntity.js'
import type { Wall } from './Wall.js'

export class WallPoster extends FurniEntity {
    override readonly type = 'poster'
    override furnimap: FurniMapItem

    private wallX?: number
    private wallY?: number

    constructor(
        override readonly controller: FurniController,
        protected override item: RoomItem,
        private wall: Wall,
        public override isPlaced: boolean
    ) {
        super(controller, undefined, item, undefined, isPlaced)

        let furnimap = Client.shared.furniMapMgr.getFurnimapByItemDefUid(this.item.defUid)

        if (!furnimap) {
            furnimap = new FurniMapItem([], item.defUid, true, 32, true)

            const dir = {
                num: 3,
                blocks: [
                    {
                        sprites: [
                            {
                                gfx: 'loadingfx_0',
                                x: 0,
                                y: 0,
                                z: 0
                            }
                        ],
                        hgt: 32
                    }
                ]
            }

            furnimap.addDirection(dir)
        }

        this.furnimap = furnimap

        this.setWall(wall, item.wallX, item.wallY, isPlaced)
    }

    override getTile(): TileEntity {
        throw new Error('getTile called for poster')
    }

    getPoint(): Point {
        if (!this.wall) {
            return null
        }
        const tile = this.wall.plane.crop(this.wallX * 5, this.wallY * 5, 5, 5)

        return new Point(tile.origin.screenX, tile.origin.screenY)
    }

    override rotate(): void {
        // cannot rotate WallPoster
    }

    override sendPlacement(opts: any = {}): void {
        if (this.placeTimer) {
            clearTimeout(this.placeTimer)
        }
        this.placeTimer = setTimeout(() => {
            if (this.isPlaced) {
                Client.shared.serverBroker.send('furni_move', {
                    itemId: this.item.itemId,
                    wallRef: this.item.wallRef,
                    wallX: this.item.wallX,
                    wallY: this.item.wallY,
                    rotation: this.item.rotation,
                    ...opts
                })
            } else {
                Client.shared.serverBroker
                    .sendAck('furni_place', {
                        defUid: this.item.defUid,
                        itemId: this.item.itemId,
                        wallRef: this.item.wallRef,
                        wallX: this.item.wallX,
                        wallY: this.item.wallY,
                        rotation: this.item.rotation,
                        ...opts
                    })
                    .then((success) => {
                        if (success) {
                            this.isPlaced = true
                            if (typeof success === 'number') {
                                this.item.itemId = success
                            } else {
                                this.itemUpdated(success, false)
                            }
                            this.emit('place_finished')
                        } else {
                            this.controller.removeItem(this)
                            this.off('place_finished')
                        }
                    })
            }
        }, 250)
    }

    override updatePartTiles(finalPlace: boolean, stackFloor = false): void {
        console.log('>> WallPoster.updatePartTiles', finalPlace)
        const dir = this.furnimap.getDirection(this.item.rotation) || this.furnimap.getDirection(3)

        if (!dir) {
            console.error('ERROR! DIRECTION ' + this.item.rotation + ' NOT FOUND ON ITEM ID ' + this.item.defUid)
            return
        }

        if (!this.wall) {
            console.log('cant update tile parts for entity on missing wall')
            return
        }
        if (!this.parts.length) {
            console.log('poster has no parts')
            return
        }

        const tile = this.wall.plane.crop(this.wallX * 5, this.wallY * 5, 5, 5)
        this.parts[0].eventMode = finalPlace ? 'static' : 'none'
        this.parts[0].zIndex = tile.origin.screenStack - 1000
        this.parts[0].position.set(tile.origin.screenX, tile.origin.screenY)
    }

    override setTile(tile: TileEntity, finalPlace: boolean, stackFloor = false): void {
        console.error('setTile called for poster!')
    }

    setWall(wall: Wall, x: number, y: number, finalPlace: boolean): void {
        this.wall?.removePoster(this)
        this.wall = wall

        if (wall) {
            if (wall.plane.dir !== this.item.rotation) {
                this.item.rotation = wall.plane.dir
                this.render(false)
            }

            this.item.wallRef = wall.ref
            this.item.wallX = x
            this.item.wallY = y
            this.wallX = x
            this.wallY = y

            wall.addPoster(this)
        }

        this.updatePartTiles(finalPlace, false)
    }
}
