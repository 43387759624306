import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import { Sprite, Texture } from 'pixi.js'

export class ImageButton extends Sprite {
    private isDown = false
    private isOver = false
    private isDisabled = false

    private textureButton: Texture
    private textureButtonDown?: Texture
    private textureButtonDisabled?: Texture
    private textureButtonOver?: Texture

    sound?: AudioBuffer

    glowSprite: Sprite | null

    constructor(
        normalTexture: string,
        downTexture?: string,
        disabledTexture?: string,
        overTexture?: string,
        sound?: AudioBuffer
    ) {
        super()

        this.replaceTextures(normalTexture, downTexture, disabledTexture, overTexture)

        this.addEventListener('pointerdown', this.onButtonDown)
        this.addEventListener('pointerup', this.onButtonUp)
        this.addEventListener('pointerover', this.onButtonOver)
        this.addEventListener('pointerout', this.onButtonOut)
        this.addEventListener('pointerup', this.onButtonTap)

        this.eventMode = 'static'
        this.interactiveChildren = true
        this.cursor = 'pointer'
    }

    replaceTextures(normalTexture: string, downTexture?: string, disabledTexture?: string, overTexture?: string): void {
        try {
            this.textureButton = Texture.from(normalTexture)
            this.textureButtonDown = downTexture ? Texture.from(downTexture) : null
            this.textureButtonDisabled = disabledTexture ? Texture.from(disabledTexture) : null
            this.textureButtonOver = overTexture ? Texture.from(overTexture) : null
        } catch (error) {
            console.warn(error)
        }

        this.texture = this.isDisabled && this.textureButtonDisabled ? this.textureButtonDisabled : this.textureButton
    }

    disable(): void {
        this.cursor = 'default'
        this.eventMode = 'auto'
        this.isDisabled = true
        if (this.textureButtonDisabled) {
            this.texture = this.textureButtonDisabled
        } else {
            this.alpha = 0.3
        }
    }

    enable(): void {
        this.cursor = 'pointer'
        this.eventMode = 'static'
        this.cursor = 'pointer'
        this.alpha = 1
        this.isDisabled = false
        this.texture = this.textureButton
    }

    private onButtonDown = (): void => {
        if (!this.isDisabled) {
            this.isDown = true
            if (this.textureButtonDown) {
                this.texture = this.textureButtonDown
            }
            this.alpha = 1
        }
    }

    private onButtonUp = (): void => {
        if (!this.isDisabled) {
            if (this.isOver && this.isDown) {
                this.isDown = false
                this.texture = this.textureButton
            } else {
                this.isDown = false
                this.texture = this.textureButton
            }
        }
    }

    private onButtonTap = (): void => {
        if (!this.isDisabled) {
            if (this.sound) {
                SoundManager.shared.play(ESndGrp.UI, this.sound)
            }
        }
    }

    private onButtonOver = (): void => {
        if (!this.isDisabled) {
            this.isOver = true

            if (this.isDown) {
                if (this.textureButtonDown) {
                    this.texture = this.textureButtonDown
                }
            } else if (this.textureButtonOver) {
                this.texture = this.textureButtonOver
            }
        }
    }

    private onButtonOut = (): void => {
        if (!this.isDisabled) {
            this.isOver = false

            this.texture = this.textureButton
        }
    }

    isEnabled(): boolean {
        return !this.isDisabled
    }

    isPressed(): boolean {
        return this.isDown // && this.isOver;
    }

    beginGlow(): void {
        if (!this.glowSprite) {
            this.glowSprite = this.addChild(Pooler.newSprite(this.textureButtonDown))
        }
        this.glowSprite.alpha = 0
        this.glowSprite.visible = true
        this.glow()
    }

    stopGlow(): void {
        if (this.glowSprite) {
            gsap.killTweensOf(this.glowSprite)
            this.glowSprite.visible = false
        }
    }

    private glow(): void {
        gsap.to(this.glowSprite, {
            duration: 2,
            alpha: 1,
            onComplete: () => {
                this.fade()
            }
        })
    }

    private fade(): void {
        gsap.to(this.glowSprite, {
            duration: 1,
            alpha: 0,
            onComplete: () => {
                this.glow()
            }
        })
    }
}
