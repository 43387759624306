/*
 * VStack.ts
 * VMK Legacy Client
 */

import { Container } from 'pixi.js'
import type { View } from './View.js'
import { ViewBuilder } from './ViewBuilder.js'

export class VStack extends ViewBuilder {
    private opts: {
        alignment?: 'leading' | 'trailing' | 'center'
        spacing?: number
    }

    private actualWidth = 0

    constructor(
        { alignment = 'center', spacing = 5 }: { alignment?: 'leading' | 'trailing' | 'center'; spacing?: number },
        ...views: View[]
    ) {
        super()

        this.opts = {
            alignment,
            spacing
        }

        this.content = views
    }

    override loadView(): void {
        const view = new Container()

        for (const subview of this.content) {
            if (!subview) {
                continue
            }
            subview.loadView()
            view.addChild(subview.view)
        }

        this.view = view
    }

    override sizeToFit(proposed: { width: number; height: number }): void {
        let maxSubviewWidth = 0

        for (const subview of this.content) {
            if (!subview) {
                continue
            }
            subview.sizeToFit({ width: proposed.width })
            subview.layoutSubviews()

            if (subview.view.width > maxSubviewWidth) {
                maxSubviewWidth = subview.view.width
            }
        }

        this.actualWidth = maxSubviewWidth
    }

    override layoutSubviews(): void {
        let y = 0

        for (const child of this.content) {
            if (!child) {
                continue
            }
            child.layoutSubviews()

            if (y !== 0) {
                y += this.opts.spacing
            }
            let x = 0
            if (this.opts.alignment === 'center') {
                x = this.actualWidth / 2 - child.getActualSize().width / 2
            } else if (this.opts.alignment === 'trailing') {
                x = this.actualWidth - child.getActualSize().width
            }
            child.view.position.set(x, y)

            y += child.getActualSize().height
        }
    }
}

export class HStack extends ViewBuilder {
    private opts: {
        alignment?: 'leading' | 'trailing' | 'center'
        spacing?: number
    }

    private actualHeight = 0

    constructor(
        { alignment = 'center', spacing = 5 }: { alignment?: 'leading' | 'trailing' | 'center'; spacing?: number },
        ...views: View[]
    ) {
        super()

        this.opts = {
            alignment,
            spacing
        }

        this.content = views
    }

    override loadView(): void {
        const view = new Container()

        for (const subview of this.content) {
            if (!subview) {
                continue
            }
            subview.loadView()
            view.addChild(subview.view)
        }

        this.view = view
    }

    override sizeToFit(proposed: { width: number; height: number }): void {
        let maxSubviewHeight = 0

        for (const subview of this.content) {
            if (!subview) {
                continue
            }
            subview.sizeToFit({ height: proposed.height })
            subview.layoutSubviews()

            if (subview.view.height > maxSubviewHeight) {
                maxSubviewHeight = subview.view.height
            }
        }

        this.actualHeight = maxSubviewHeight
    }

    override layoutSubviews(): void {
        let x = 0

        for (const child of this.content) {
            if (!child) {
                continue
            }
            child.layoutSubviews()

            if (x !== 0) {
                x += this.opts.spacing
            }
            let y = 0
            if (this.opts.alignment === 'center') {
                y = this.actualHeight / 2 - child.getActualSize().height / 2
            } else if (this.opts.alignment === 'trailing') {
                y = this.actualHeight - child.getActualSize().height
            }
            child.view.position.set(x, y)

            x += child.getActualSize().width
        }
    }
}
