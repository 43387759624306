const Config = {
    version: 16,
    environment: {
        which: process.env['NODE_ENV'],
        debug: process.env['VMKL_APP_DEBUG'] === 'true',
        assetRoot: String(process.env['VMKL_ASSETS_URL'] ?? 'https://download.vmklegacy.com'),
        apiUrl: String(process.env['VMKL_API_URL'] ?? 'https://api.vmklegacy.com'),
        url: String(process.env['VMKL_CLIENT_URL'] ?? 'https://play.vmklegacy.com'),
        resetUrl: String(process.env['VMKL_PASS_RESET_URL'] ?? 'https://www2.vmklegacy.com/password/reset'),
        uploadsRoot: String(process.env['VMKL_UPLOADS_URL'] ?? 'https://www2.vmklegacy.com/storage'),
        thumbsUrl: String(process.env['VMKL_THUMBS_URL'] ?? 'https://thumbs.vmklegacy.com'),
        serverUrl: (() => {
            let uri = String(process.env['VMKL_WS_URI'])
            if (uri.startsWith('/')) {
                uri = `wss://${location.host}${uri}`
            } else {
                uri = uri.replace('https://', 'wss://')
            }
            return uri
        })()
    }
}

export default Config
