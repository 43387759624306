import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../../Client.js'
import type { Ticket } from '../../../../data/Ticket.js'
import { Fonts } from '../../../../Fonts.js'
import { MessageType } from '../../../../server/MessageType.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../buttons/BitmapTextButtonType.js'
import { ScrollArea } from '../../../containers/ScrollArea.js'
import { Field } from '../../../fields/Field.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { ModWindow } from '../ModWindow.js'
import { ReportListItem } from '../ReportListItem.js'

export const TicketTypes = {
    1: 'Harassment',
    2: 'Lost stuff',
    3: 'Other'
}

export class ModerationView extends UIWindowView<ModWindow> {
    padding = 5

    // Text tint colors
    private green = 0x49eb34
    private orange = 0xff8f0f
    private red = 0xe01212

    private selectedTicketId: number
    private selectedRoomId: number

    private isClaimed: boolean

    private tickets: Ticket[]
    private reportListContainer: Container
    private reportBodyContainer: Container
    private scrollArea: ScrollArea
    private userInfoBg: Field
    private subjectBg: Field
    private reportBg: ScrollArea
    private ticketStateBg: Field
    private reportedUserBg: Field
    private lastUpdatedBg: Field

    private claimBtn: BitmapTextButton
    private closeBtn: BitmapTextButton

    private userName: BitmapText
    private userState = 'online'
    // TODO: Refactor "reason" -> subject
    private subject: BitmapText
    private report: BitmapText
    private ticketState: BitmapText
    private reportedUser: BitmapText
    private lastUpdated: BitmapText

    constructor() {
        super()

        const ticketBox: Container = Pooler.newContainer()

        const userInfoTitle = new BitmapText('Player IGN:', {
            ...Fonts.Foxley_16
        })
        userInfoTitle.x = +2
        ticketBox.addChild(userInfoTitle)

        this.userInfoBg = new Field('b', 175)
        this.userInfoBg.y = userInfoTitle.y + 15
        ticketBox.addChild(this.userInfoBg)

        this.userName = new BitmapText('', {
            ...Fonts.FoxleyBold_16,
            align: 'center'
        })

        this.userInfoBg.addChild(this.userName)

        const userStateTitle = new BitmapText('Player Status:', {
            ...Fonts.Foxley_16
        })
        userStateTitle.x = 202
        ticketBox.addChild(userStateTitle)

        const userStateBg: Field = new Field('b', 175)
        userStateBg.y = userStateTitle.y + 15
        userStateBg.x = 200
        ticketBox.addChild(userStateBg)

        const userStatusTxt = new BitmapText(this.userState, {
            ...Fonts.FoxleyBold_16
        })
        userStatusTxt.x = (userStateBg.width - userStatusTxt.textWidth) / 2
        userStatusTxt.y = (userStateBg.height - userStatusTxt.textHeight + 4) / 2
        userStatusTxt.tint = 0x07fc03
        userStateBg.addChild(userStatusTxt)

        const reasonTitle = new BitmapText('Subject:', {
            ...Fonts.Foxley_16
        })
        reasonTitle.x = +2
        reasonTitle.y = userInfoTitle.y + 50
        ticketBox.addChild(reasonTitle)

        this.subjectBg = new Field('b', 175)
        this.subjectBg.y = reasonTitle.y + 15
        ticketBox.addChild(this.subjectBg)

        this.subject = new BitmapText('', {
            ...Fonts.Foxley_16,
            align: 'center'
        })
        this.subjectBg.addChild(this.subject)

        const ticketStateTitle = new BitmapText('Ticket State:', {
            ...Fonts.Foxley_16
        })
        ticketStateTitle.x = 202
        ticketStateTitle.y = userStateTitle.y + 50
        ticketBox.addChild(ticketStateTitle)

        this.ticketStateBg = new Field('b', 175)
        this.ticketStateBg.x = 200
        this.ticketStateBg.y = ticketStateTitle.y + 15
        ticketBox.addChild(this.ticketStateBg)

        this.ticketState = new BitmapText('', {
            ...Fonts.FoxleyBold_16,
            align: 'center'
        })

        this.ticketStateBg.addChild(this.ticketState)

        const reportedUserTitle = new BitmapText('Reported Player:', {
            ...Fonts.Foxley_16
        })
        reportedUserTitle.x = +2
        reportedUserTitle.y = reasonTitle.y + 50
        ticketBox.addChild(reportedUserTitle)

        this.reportedUserBg = new Field('b', 175)
        this.reportedUserBg.y = reportedUserTitle.y + 15
        ticketBox.addChild(this.reportedUserBg)

        this.reportedUser = new BitmapText('', {
            ...Fonts.FoxleyBold_16,
            align: 'center'
        })
        this.reportedUserBg.addChild(this.reportedUser)

        const lastUpdatedTitle = new BitmapText('Last Updated:', {
            ...Fonts.Foxley_16
        })
        lastUpdatedTitle.x = 202
        lastUpdatedTitle.y = ticketStateTitle.y + 50
        ticketBox.addChild(lastUpdatedTitle)

        this.lastUpdatedBg = new Field('b', 175)
        this.lastUpdatedBg.x = 200
        this.lastUpdatedBg.y = lastUpdatedTitle.y + 15
        ticketBox.addChild(this.lastUpdatedBg)

        this.lastUpdated = new BitmapText('', {
            ...Fonts.Foxley_16,
            align: 'center'
        })
        this.lastUpdatedBg.addChild(this.lastUpdated)

        const reportTitle = new BitmapText('Message from Player:', {
            ...Fonts.Foxley_16
        })
        reportTitle.x = +2
        reportTitle.y = lastUpdatedTitle.y + 50
        ticketBox.addChild(reportTitle)

        this.reportBg = new ScrollArea(330, 150)
        this.reportBg.y = reportTitle.y + 15
        ticketBox.addChild(this.reportBg)

        this.reportBodyContainer = Pooler.newContainer()

        this.reportBg.setContent(this.reportBodyContainer)

        this.report = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        this.report.maxWidth = 330
        this.reportBodyContainer.addChild(this.report)

        // Action Buttons:

        const actionButtons: Container = Pooler.newContainer()

        this.claimBtn = new BitmapTextButton(80, 'Claim CFH', BitmapTextButtonType.GREEN)
        actionButtons.addChild(this.claimBtn)
        actionButtons.y = ticketBox.height + 15

        this.closeBtn = new BitmapTextButton(80, 'Close CFH', BitmapTextButtonType.RED)
        this.closeBtn.visible = false
        actionButtons.addChild(this.closeBtn)

        const viewReported = new BitmapTextButton(120, 'VIEW REPORTED USER', BitmapTextButtonType.LIGHT_BLUE)
        viewReported.x = this.claimBtn.x + 95
        actionButtons.addChild(viewReported)

        const viewReporting = new BitmapTextButton(120, 'VIEW REPORTING USER', BitmapTextButtonType.LIGHT_BLUE)
        viewReporting.x = this.claimBtn.x + 95 + 120 + 15
        actionButtons.addChild(viewReporting)

        // const visitBtn: BitmapTextButton = new BitmapTextButton(60, "Visit Reporter", "i");
        // visitBtn.x = this.claimBtn.x + 75;
        // actionButtons.addChild(visitBtn);

        // const alertBtn: BitmapTextButton = new BitmapTextButton(60, "Alert", "a");
        // alertBtn.x = visitBtn.x + 75;
        // actionButtons.addChild(alertBtn);

        // const kickBtn: BitmapTextButton = new BitmapTextButton(60, "Kick", "e");
        // kickBtn.x = alertBtn.x + 75;
        // actionButtons.addChild(kickBtn);

        // const banBtn: BitmapTextButton = new BitmapTextButton(60, "Ban", "e");
        // banBtn.x = kickBtn.x + 75;
        // actionButtons.addChild(banBtn);

        this.addChild(ticketBox)
        this.addChild(actionButtons)

        // tslint:disable-next-line:max-line-length
        const reportsHeader = new BitmapText('UNANSWERED CALLS FOR HELP', {
            fontName: 'Folio',
            fontSize: 12
        })
        reportsHeader.x = 392
        this.addChild(reportsHeader)

        const refreshButton = new BitmapTextButton(60, 'Refresh', 'i')
        refreshButton.x = reportsHeader.x + 200
        this.addChild(refreshButton)

        this.scrollArea = new ScrollArea(250, 333, this.reportListContainer)
        this.scrollArea.x = reportsHeader.x
        this.scrollArea.y = reportsHeader.y + 22
        this.reportListContainer = Pooler.newContainer()
        this.scrollArea.setContent(this.reportListContainer)

        this.addChild(this.scrollArea)

        refreshButton.addEventListener('pointerup', () => {
            this.update()
        })

        this.claimBtn.addEventListener('pointerup', () => {
            if (!this.selectedTicketId) {
                return
            }
            Client.shared.serverBroker.send('cfh_claim_request', {
                ticketId: this.selectedTicketId
            })
            // FIXME: yeah...
            this.ticketState.text = 'claimed'
            this.ticketState.tint = this.orange
            this.isClaimed = true
            this.closeBtn.visible = true
            this.claimBtn.visible = false
            this.format()
        })

        this.closeBtn.addEventListener('pointerup', () => {
            if (!this.selectedTicketId) {
                return
            }
            Client.shared.serverBroker.send('cfh_close_request', {
                ticketId: this.selectedTicketId
            })
            // FIXME: yeah...
            this.ticketState.text = 'closed'
            this.ticketState.tint = this.red
            this.closeBtn.disable()
            this.format()
        })

        viewReported.addEventListener('pointerup', () => {
            if (!this.selectedTicketId) {
                return
            }
            if (this.reportedUser && this.reportedUser.text !== null && this.reportedUser.text !== '') {
                Client.shared.serverBroker.send('mk_search', {
                    ign: this.reportedUser.text
                })
                this.parent.setActiveTab(this.parent.usersTab)
            }
        })

        viewReporting.addEventListener('pointerup', () => {
            if (!this.selectedTicketId) {
                return
            }
            if (this.userName && this.userName.text !== null && this.userName.text !== '') {
                Client.shared.serverBroker.send('mk_search', {
                    ign: this.userName.text
                })
                this.parent.setActiveTab(this.parent.usersTab)
            }
        })

        this.once('added', () => {
            Client.shared.serverBroker.onEvent(MessageType.CFH_RECEIVED, () => {
                this.update()
            })
        })
    }

    update() {
        if (!this.parent) {
            return
        }
        this.tickets = []
        Client.shared.staffData.getTickets().forEach((ticketItem: Ticket) => {
            this.tickets.push(ticketItem)
        })

        this.reportListContainer.removeChildren()

        for (let i = 0; i < this.tickets.length; i++) {
            const spacingY = 63
            const ticket = this.tickets[i]
            // @ts-expect-error
            // tslint:disable-next-line:max-line-length
            const r = new ReportListItem(this, ticket)

            this.reportListContainer.addChild(r)

            r.y = spacingY * i
            if (this.tickets.length >= 6) {
                this.scrollArea.enableScrollbar()
            }
            // TODO: Make this less dirty.

            r.addEventListener('pointerup', () => {
                // @ts-expect-error
                this.selectedTicketId = ticket.ticketId
                // @ts-expect-error
                this.selectedRoomId = ticket.roomId
                // @ts-expect-error
                this.userName.text = ticket.sender
                // @ts-expect-error
                this.subject.text = TicketTypes[ticket.type] || 'Other'
                // @ts-expect-error
                this.reportedUser.text = ticket.reportedUser
                // @ts-expect-error
                this.report.text = ticket.issue
                // @ts-expect-error
                this.lastUpdated.text =
                    ticket.updatedAt.toISOString().split('T')[0] + ' ' + ticket.updatedAt.toTimeString().split('G')[0]
                // @ts-expect-error
                if (ticket.state === 1) {
                    this.ticketState.text = 'open'
                    this.ticketState.tint = this.green
                    this.isClaimed = false
                    this.closeBtn.enable()
                    // @ts-expect-error
                } else if (ticket.state === 2) {
                    this.ticketState.text = ticket.modIgn + ' claimed'
                    this.ticketState.tint = this.orange
                    this.isClaimed = true
                    this.closeBtn.enable()
                } else {
                    this.ticketState.text = ticket.modIgn + ' closed'
                    this.ticketState.tint = this.red
                    this.closeBtn.disable()
                }

                if (this.isClaimed) {
                    this.closeBtn.visible = true
                    this.claimBtn.visible = false
                } else {
                    this.closeBtn.visible = false
                    this.claimBtn.visible = true
                }

                this.format()
            })
        }
    }

    format() {
        this.userName.x = (this.userInfoBg.width - this.userName.textWidth) / 2
        this.userName.y = (this.userInfoBg.height - this.userName.textHeight + 4) / 2

        this.subject.x = (this.subjectBg.width - this.subject.textWidth) / 2
        this.subject.y = (this.subjectBg.height - this.subject.textHeight + 4) / 2

        this.ticketState.x = (this.ticketStateBg.width - this.ticketState.textWidth) / 2
        this.ticketState.y = (this.ticketStateBg.height - this.ticketState.textHeight + 4) / 2

        this.reportedUser.x = (this.reportedUserBg.width - this.reportedUser.textWidth) / 2
        this.reportedUser.y = (this.reportedUserBg.height - this.reportedUser.textHeight + 4) / 2

        this.lastUpdated.x = (this.lastUpdatedBg.width - this.lastUpdated.textWidth) / 2
        this.lastUpdated.y = (this.lastUpdatedBg.height - this.lastUpdated.textHeight + 4) / 2
    }
}
