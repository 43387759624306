import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class SharkReefHandler extends WalkableRoomExtension {
    override async init(): Promise<this> {
        await super.init()

        console.log('Shark reef handler')
        console.log(this.room.animationMap)

        const leftSplash = this.room.animationMap.get('vmk_anim_1')?.score
        const rightSplash = this.room.animationMap.get('vmk_anim_2')?.score

        if (leftSplash && rightSplash) {
            leftSplash.reset(0)
            rightSplash.reset(0)

            leftSplash.autoplays = false
            rightSplash.autoplays = false

            leftSplash.loops = false
            rightSplash.loops = false

            leftSplash.hidesWhenPaused = true
            rightSplash.hidesWhenPaused = true
        }

        // this.room.namedTiles.get('Left_slide_3').on()

        return this
    }
}
