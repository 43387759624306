import type { Polygon } from 'pixi.js'
import { Graphics } from 'pixi.js'

class RailGraphics extends Graphics {
    hitPoly?: Polygon
    bounceDir?: 'up' | 'down'
    rail?: string
}

export default RailGraphics
