import { Pooler } from '@vmk-legacy/render-utils'
import { ClientNPCVisit, NPC } from '@vmk-legacy/server-protos'
import type { Sprite } from 'pixi.js'
import { Client } from '../../../Client.js'
import { RandomUtil } from '../../../util/RandomUtil.js'
import type { Animation } from '../../Animation.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class MatterhornHandler extends WalkableRoomExtension {
    animation?: Animation

    animMarkers = ['ROAR', 'THROW', 'PHONE']

    async init() {
        await super.init()

        const sprite = this.room.sprites.getChildByName('yeti') as Sprite

        if (sprite) {
            const npc = this.room.addNPC('npc-yeti', 0, 0, {}, 'The Yeti', true)
            npc.getVisual().position.set(550, 100)
            this.room.addEntity(npc)

            this.animation = await this.room.setupAnimation('yeti_all.ani', true)

            if (this.animation) {
                sprite.visible = false

                this.animation.name = 'yeti'
                this.animation.zIndex = sprite.zIndex
                this.animation.position.set(sprite.x, sprite.y)
                this.room.animationMap.set(this.animation.name, this.animation)

                Pooler.release(sprite)

                this.room.sprites.addChild(this.animation)

                this.setInterval(() => this.doRandomAnim(), 10000)

                this.animation.cursor = 'pointer'
                this.animation.eventMode = 'static'
                this.animation.addEventListener('pointerup', () => {
                    this.onTap()
                })
            }
        }

        return this
    }

    doRandomAnim() {
        const anim = this.animMarkers[RandomUtil.generateRandomIntegerInRange(0, this.animMarkers.length - 1)]

        this.animation?.score.goToMarker(anim)
    }

    private onTap() {
        Client.shared.serverBroker.send(
            new ClientNPCVisit({
                npc: NPC.Yeti
            })
        )
    }
}
