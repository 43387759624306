import { Pooler } from '@vmk-legacy/render-utils'
import type { RenderTexture, Sprite } from 'pixi.js'
import { BitmapText, Container, Point, Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { ChatBubble } from '../../room/ChatBubble.js'
import { ImageButton } from '../../ui/buttons/ImageButton.js'
import type { IWindow } from '../../ui/windows/IWindow.js'
import { Joystick } from './Joystick.js'
import type { JungleCruiseDelegate } from './JungleCruiseDelegate.js'
import { InputMode } from './JungleCruiseDelegate.js'
import { JungleCruiseViewfinder } from './JungleCruiseViewfinder.js'

export class JungleCruiseUI extends Container implements IWindow {
    layer = UILayer.AlwaysOnTop

    private gasTf: BitmapText
    private filmTf: BitmapText
    private scoreTf: BitmapText
    private nedTf: ChatBubble
    private ned: Sprite
    private currentNedFrame = 0
    private nedFrames: string[] = [
        'jungleguide0000',
        'jungleguide0001',
        'jungleguide0002',
        'jungleguide0003',
        'jungleguide0004',
        'jungleguide0003',
        'jungleguide0002',
        'jungleguide0001'
    ]
    private speakInterval: any
    reticle: JungleCruiseViewfinder
    private photos: (Texture | RenderTexture)[] = []
    private photosContain: Container
    private gameExitButton: ImageButton
    private joystick: Joystick

    constructor(readonly delegate: JungleCruiseDelegate) {
        super()

        const topStrip = this.addChild(Pooler.newSprite('jc.ui.bg'))

        const member = this.nedFrames[this.currentNedFrame]
        this.ned = Pooler.newSprite(member)
        this.addChild(this.ned)

        this.ned.position.set(Constants.SIZE[0] / 2, Constants.SIZE[1] / 2)

        this.nedTf = new ChatBubble('', '', 79, true, 0.85)
        this.nedTf.position.set(80, 565)
        this.addChild(this.nedTf)

        this.gameExitButton = new ImageButton('button.exitpirates.active', 'button.exitpirates.pressed')
        this.gameExitButton.position.set(
            Constants.SIZE[0] - this.gameExitButton.width,
            Constants.SIZE[1] -
                this.gameExitButton.height -
                Client.shared.userInterface.getObscuringTopHeight() +
                this.delegate.getObscuringTopHeight()
        )
        this.addChild(this.gameExitButton)
        this.gameExitButton.addEventListener('pointerup', async () => {
            if (
                await Client.shared.helpers.confirm({
                    title: 'End Game',
                    message:
                        'Are you sure you want to end your Jungle Cruise game? You will get credits for the progress you have made so far.'
                })
            ) {
                delegate.endGame()
            }
        })

        const NUM_FILM_STRIPS = 11
        for (let i = 0; i < NUM_FILM_STRIPS; i++) {
            const spr = Pooler.newSprite('filmstrip')
            spr.x = 150 + i * spr.width
            spr.alpha = 0.5
            this.addChild(spr)
        }
        this.photosContain = this.addChild(Pooler.newContainer())
        this.photosContain.x = 163
        this.photosContain.y = 7

        this.reticle = new JungleCruiseViewfinder()
        this.addChild(this.reticle)

        const points = new BitmapText('points', {
            fontName: 'Folio',
            fontSize: 16
        })
        points.position.set(90, 13)
        this.addChild(points)

        this.scoreTf = new BitmapText('0', {
            fontName: 'Folio',
            fontSize: 26
        })
        this.scoreTf.position.set(85 - this.scoreTf.textWidth, 12)
        this.addChild(this.scoreTf)

        this.filmTf = new BitmapText('25', {
            fontName: 'Folio',
            fontSize: 20
        })
        this.filmTf.position.set(85 + 633 - 20, 12)
        this.addChild(this.filmTf)

        if (this.delegate.inputMode === InputMode.Touch) {
            this.joystick = new Joystick({
                outerScale: {
                    x: 1.25,
                    y: 1.25
                },
                onChange: (data) => {
                    if (
                        data.direction === 'left' ||
                        data.direction === 'top_left' ||
                        data.direction === 'bottom_left'
                    ) {
                        Client.shared.keysDown.set(-4, true)
                    } else {
                        Client.shared.keysDown.set(-4, false)
                    }
                    if (
                        data.direction === 'right' ||
                        data.direction === 'top_right' ||
                        data.direction === 'bottom_right'
                    ) {
                        Client.shared.keysDown.set(-2, true)
                    } else {
                        Client.shared.keysDown.set(-2, false)
                    }
                    if (
                        data.direction === 'bottom' ||
                        data.direction === 'bottom_left' ||
                        data.direction === 'bottom_right'
                    ) {
                        Client.shared.keysDown.set(-3, true)
                    } else {
                        Client.shared.keysDown.set(-3, false)
                    }
                    if (data.direction === 'top' || data.direction === 'top_left' || data.direction === 'top_right') {
                        Client.shared.keysDown.set(-1, true)
                    } else {
                        Client.shared.keysDown.set(-1, false)
                    }
                },

                onEnd: () => {
                    Client.shared.keysDown.set(-1, false)
                    Client.shared.keysDown.set(-2, false)
                    Client.shared.keysDown.set(-3, false)
                    Client.shared.keysDown.set(-4, false)
                }
            }) as Joystick & Container
            this.addChild(this.joystick)
            this.repositionJoystick()
            window.addEventListener('resize', this.repositionJoystick)
            this.gameExitButton.position.set(Constants.SIZE[0] - this.gameExitButton.width, topStrip.height)
        } else {
            window.addEventListener('pointermove', this.reticleFollow, {
                passive: true
            })
        }

        this.gasTf = new BitmapText('140', {
            fontName: 'Folio',
            fontSize: 20
        })
        this.gasTf.position.set(85 + 683, 12)
        this.addChild(this.gasTf)
    }

    repositionJoystick = (): void => {
        this.joystick.position.set(this.width - this.joystick.width - 15, this.height - this.joystick.height - 15)
    }

    addPhoto(texture: Texture | RenderTexture): void {
        this.photos.push(texture)
        const NUM_FILM_STRIPS = 11
        if (this.photos.length > NUM_FILM_STRIPS) {
            this.photos.shift()
        }

        this.photosContain.removeChildren()

        for (let i = 0; i < this.photos.length; i++) {
            const spr = Pooler.newSprite(this.photos[i])
            spr.x = i * 45
            spr.width = 32
            spr.height = 22
            this.photosContain.addChild(spr)
        }
    }

    reticleFollow = (e: MouseEvent): void => {
        const mousePoint = new Point(
            e.clientX - Client.shared.containerEl.offsetLeft,
            e.clientY - Client.shared.containerEl.offsetTop
        )

        if (this.reticle.visible) {
            const point = Client.shared.viewport.toLocal(mousePoint)
            this.reticle.position.copyFrom(point)

            this.reticle.setFocused(this.delegate.animalWithin(point))
        }
    }

    setGas(gas: number): void {
        this.gasTf.text = gas.toString()
    }

    setFilm(film: number): void {
        this.filmTf.text = film.toString()
    }

    setScore(score: number): void {
        this.scoreTf.text = score.toString()
        this.scoreTf.x = 85 - this.scoreTf.textWidth
    }

    nedStopSpeaking(): void {
        clearInterval(this.speakInterval)
        this.ned.texture = Texture.from(this.nedFrames[0])
        this.ned.anchor.copyFrom(this.ned.texture.defaultAnchor)
        this.currentNedFrame = 0
    }

    nedSpeakIndefinitely(): void {
        if (this.speakInterval) {
            window.clearInterval(this.speakInterval)
        }
        this.speakInterval = window.setInterval(
            () => {
                this.currentNedFrame++
                if (this.currentNedFrame >= this.nedFrames.length) {
                    this.currentNedFrame = 0
                }

                const member = this.nedFrames[this.currentNedFrame]

                this.ned.texture = Texture.from(member)
                this.ned.anchor.copyFrom(this.ned.texture.defaultAnchor)
            },
            1000 / 24 / 1.25
        )
    }

    hideNedTextTimer?: NodeJS.Timeout

    setNedText(text: string): void {
        clearTimeout(this.hideNedTextTimer)
        this.nedTf.setMessage(text)
        this.nedTf.visible = true
        this.hideNedTextTimer = setTimeout(() => (this.nedTf.visible = false), text.length * 200)
    }

    override destroy(options?: {
        children?: boolean
        texture?: boolean
        baseTexture?: boolean
    }): void {
        super.destroy(options)
        console.trace()

        console.log('Destroying UI')

        this.photos = null

        window.removeEventListener('resize', this.repositionJoystick)
        window.removeEventListener('pointermove', this.reticleFollow)

        window.clearInterval(this.speakInterval)
    }
}
