export class Friend {
    private id: number
    private ign: string
    private lastOnline: Date
    private roomId: number
    private roomName: string
    private signature: string
    private isOnline: boolean

    constructor(
        id: number,
        ign: string,
        lastOnline: Date,
        roomId: number,
        roomName: string,
        signature: string,
        isOnline: boolean
    ) {
        this.id = id
        this.ign = ign
        this.lastOnline = lastOnline
        this.roomId = roomId
        this.roomName = roomName
        this.signature = signature
        this.isOnline = isOnline
    }

    getIsOnline(): boolean {
        return this.isOnline
    }

    getId(): number {
        return this.id
    }

    getIgn(): string {
        return this.ign
    }

    getLastOnline(): Date {
        return this.lastOnline
    }

    getRoomId(): number {
        return this.roomId
    }

    getRoomName(): string {
        return this.roomName
    }

    getSignature(): string {
        return this.signature
    }
}
