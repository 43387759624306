import { EPerm } from '@vmk-legacy/common-ts'
import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import { ImageButton } from '../../buttons/ImageButton.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import { UIWindow } from '../UIWindow.js'
import { UIWindowTab } from '../UIWindowTab.js'
import { UIWindowType } from '../UIWindowType.js'
import { ChatLogView } from './views/ChatLogView.js'
import { IGNView } from './views/IGNView.js'
import { ModerationView } from './views/ModerationView.js'
import { StatusView } from './views/StatusView.js'
import { UsersView } from './views/UsersView.js'

export class ModWindow extends UIWindow {
    readonly kind = EWindow.Mod

    isDraggable: boolean
    exitBtn: ImageButton
    isFocused = true
    usersTab: UIWindowTab
    chatLogTab: UIWindowTab

    constructor() {
        super(400, UIWindowType.MOUSEKEEPING)

        this.x = 25
        this.y = 82
        this.isDraggable = true
    }

    override async windowWasBuilt(): Promise<void> {
        this.exitBtn = new ImageButton('button.close.active', 'button.close.pressed')
        this.addChild(this.exitBtn)
        this.exitBtn.x = this.header.x + this.header.width - 18
        this.exitBtn.y = this.header.y + 2

        const statusView = new StatusView()
        this.views.push(this.addChild(statusView))
        const statusTab = new UIWindowTab('Status', statusView)
        this.tabs.push(this.addChild(statusTab))

        if (Client.shared.selfRecord.can(EPerm.CFHBrowse)) {
            const moderationView = new ModerationView()
            this.views.push(this.addChild(moderationView))
            const moderationTab = new UIWindowTab('Calls for Help', moderationView)
            this.tabs.push(this.addChild(moderationTab))
        }

        if (Client.shared.selfRecord.can(EPerm.PlayersBrowse)) {
            const usersView = new UsersView()
            this.views.push(this.addChild(usersView))
            this.usersTab = new UIWindowTab('Users', usersView)
            this.tabs.push(this.addChild(this.usersTab))
        }

        if (Client.shared.selfRecord.can(EPerm.PlayersIGNApproval)) {
            const ignView = new IGNView()
            this.views.push(this.addChild(ignView))
            const ignTab = new UIWindowTab('In-game Names', ignView)
            this.tabs.push(this.addChild(ignTab))
        }

        if (Client.shared.selfRecord.can(EPerm.RoomsPublicChatLogs)) {
            const chatLogView = new ChatLogView()
            this.views.push(this.addChild(chatLogView))
            this.chatLogTab = new UIWindowTab('Chat Logs', chatLogView)
            this.tabs.push(this.addChild(this.chatLogTab))
        }

        let currentX = 0
        this.tabs.forEach((t) => {
            t.view.x = 33
            t.view.y = 85
            t.eventMode = 'static'
            t.interactiveChildren = true
            t.cursor = 'pointer'

            t.y = 44 // static
            t.x = currentX + 28
            currentX += t.getWidth()

            t.setActive(false)

            t.addEventListener('pointerup', (e: FederatedEvent) => {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.PopupTabChange)
                this.setActiveTab(t)
            })
        })

        // set default tab as active
        this.setActiveTab(statusTab)
    }
}
