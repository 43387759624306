export enum BitmapTextButtonType {
    ORANGE = 'a',
    PINK = 'b',
    BLUE = 'c',
    GREEN = 'd',
    RED = 'e',
    LIME = 'f',
    TEAL = 'g',
    GOLD = 'h',
    LIGHT_BLUE = 'i'
}
