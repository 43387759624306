/*
 * AvatarVisual.ts
 *
 * VMK Legacy Client
 */

import type { EAvatarDir, IClientOutfit } from '@vmk-legacy/common-ts'
import { EPerm } from '@vmk-legacy/common-ts'
import type { AssetProvider, IClothingItem, MagicEffect, MagicSubject } from '@vmk-legacy/render-utils'
import { AvatarOutfit, BodyRenderer, Limb, LimbAnimation, Pooler } from '@vmk-legacy/render-utils'
import { ClientChat } from '@vmk-legacy/server-protos'
import { gsap } from 'gsap'
import type { FederatedEvent, FederatedMouseEvent, FederatedPointerEvent, Sprite } from 'pixi.js'
import { AlphaFilter, AnimatedSprite, Container, Rectangle } from 'pixi.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { ContextMenu } from '../../ui/ContextMenu.js'
import { AlertView } from '../../ui/views/AlertView.js'
import type { TileEntity } from '../renderer/types/TileEntity.js'
import { WalkableRoomViewer } from '../renderer/WalkableRoomViewer.js'
import type { RoomEntityVisualizing } from '../RoomObject.js'
import type { AvatarEntity } from './AvatarEntity.js'

export class AvatarVisual extends Container implements RoomEntityVisualizing, MagicSubject {
    factor: AvatarEntity

    readonly type = 'avatar'

    // the actual body, separated so it can be floated independent of shadow, etc
    bodyContain: Container
    private renderer: BodyRenderer

    private loadingVortex?: AnimatedSprite
    private outfit?: AvatarOutfit

    private emoteTimeout: any
    private emote: Sprite

    private bodyAlpha = 1
    private direction: EAvatarDir
    private rotateSetInterval?: NodeJS.Timeout
    private savedDirectionIndex?: number
    private headOnly = false
    private currentClothingItems: IClothingItem[]

    private ey: Sprite
    private blk: Sprite
    private spk: Sprite

    private ign: string
    private blinkInterval: number
    private speakInterval: number
    private vortexInterval: number
    private fxTimeout: number
    private blinkTimeout: number
    private tweener: gsap.core.Tween
    private shadow: Sprite

    private activeMagic?: MagicEffect

    static globalArrow?: Sprite

    actions = {
        move: false, // move action exists only on client while moving between tiles
        wave: false,
        steer: false,
        sit: false,
        dance: false,
        carry: false,
        eat: false
    }

    constructor(
        readonly provider: AssetProvider,
        direction: number,
        ign?: string,
        actions?: any,
        dummy?: boolean
    ) {
        super()

        this.actions = actions || {}
        this.shadow = Pooler.newSprite('room_avatar_shadow')
        this.shadow.alpha = 0.5

        this.hitArea = new Rectangle(-15, -100, 30, 100)
        this.eventMode = 'static'
        this.interactiveChildren = false

        this.currentClothingItems = []

        this.bodyContain = Pooler.newContainer()
        this.renderer = new BodyRenderer(Client.shared.figuresMgr, this.provider.getProvides(), direction)

        if (ign) {
            this.ign = ign
        } else {
            this.ign = 'Unknown'
        }

        this.direction = direction

        // Draw vortex
        this.animateVortex()

        if (!dummy) {
            if (Client.shared.selfRecord.can(EPerm.GameTeleport)) {
                this.cursor = 'draggable'
                this.addEventListener('pointerdown', (e: FederatedEvent) => {
                    if (e.data.button === 2) {
                        return
                    }
                    this.downAt = Date.now()
                })
                this.addEventListener('pointerup', (e: FederatedEvent) => {
                    if (e.data.button === 2) {
                        return
                    }
                    this.downAt = null
                    Client.shared.roomViewer?.furniController?.placeFurni()
                })
                this.addEventListener('pointerupoutside', (e: FederatedEvent) => {
                    if (e.data.button === 2) {
                        return
                    }
                    this.downAt = null
                    Client.shared.roomViewer?.furniController?.placeFurni()
                })
                this.addEventListener('pointermove', this.activateDragMode)
            }

            this.addEventListener('pointerdown', (e: FederatedMouseEvent) => {
                if (e && e.data.button === 2 && this.factor) {
                    e.stopPropagation()
                    const menu = new ContextMenu()
                    const items: any[] = [this.getTile(), ...this.getTile()?.itemParts.map((p) => p.furni), this]
                    menu.populateFor(...items)
                    menu.position.copyFrom(Client.shared.viewport.toLocal(e.global))

                    if (menu.y + menu.height > Constants.SIZE[1] - 27) {
                        menu.y -= menu.height
                    }
                    if (menu.x + menu.width > Constants.SIZE[0]) {
                        menu.x -= menu.width
                    }
                    Client.shared.viewport.addChild(new AlertView(menu, 0, true))

                    return
                }
            })
            this.addEventListener('pointerup', this.didClick)
            this.addEventListener('pointerover', () => this.factor?.setHovering(true))
            this.addEventListener('pointerout', () => this.factor?.setHovering(false))
            this.eventMode = 'static'
        } else {
            this.eventMode = 'auto'
        }

        this.addChild(this.shadow)

        this.bodyContain.addChild(this.renderer)

        this.addChild(this.bodyContain)

        if (actions) {
            this.applyServerActions(actions)
        }
    }

    applyServerActions(actions: any): void {
        if (actions.wave) {
            this.wave()
        }
        if (actions.sit) {
            this.startSitting()
        } else {
            this.stopSitting()
        }
        if (actions.dance) {
            this.startDancing()
        } else {
            this.stopDancing()
        }
    }

    isSelf(): boolean {
        return this.factor.isSelf()
    }

    setBodyVerticalPivot(pivotY: number): void {
        this.bodyContain.pivot.y = pivotY
    }

    setVerticalPivot(pivotY: number): void {
        this.pivot.y = pivotY
    }

    setInvisible(invisible: boolean): void {
        this.factor.setInvisible(invisible)
    }

    setLevitate(levitate: boolean, floatHeight: number, floatDelta: number): void {
        if (levitate) {
            this.startFloating(floatHeight, floatDelta)
        } else {
            this.stopFloating()
        }
    }

    setRotate(rotating: boolean): void {
        if (rotating) {
            this.startRotating(rotating)
        } else {
            this.startRotating(rotating)
        }
    }

    clearMagic(magic: MagicEffect): void {
        if (this.activeMagic === magic) {
            this.activeMagic = undefined
        }
    }

    downAt: number

    didClick = (e: FederatedPointerEvent) => {
        console.log('clicked avatar')
        if (e.altKey && Client.shared.selfRecord.isStaff()) {
            Client.shared.serverBroker.send('mk_boot', {
                id: this.factor.getRoomIdentifier()
            })

            return
        }
        if (e.nativeEvent.shiftKey) {
            const value = Client.shared.userInterface.toolbar.chatbarInput.getValue()
            Client.shared.userInterface.toolbar.chatbarInput.setValue(value + this.factor.getName())
            Client.shared.userInterface.toolbar.chatbarInput.forceFocus()

            return
        }
        this.factor?.roomContext.showArrowFor(this.factor)
        this.factor?.populateLanyard()
    }

    getChatTint(): number | string | undefined {
        const shirtId = this.outfit?.shirtId
        if (!shirtId) {
            return undefined
        }

        return Client.shared.figuresMgr.getShirtColorByDef(shirtId)
    }

    setMagic(magic?: MagicEffect): void {
        this.activeMagic?.teardown()
        this.activeMagic = magic
        if (magic) {
            magic.setDirection(this.direction)
        } else if (this.isMoving()) {
            this.stopWalking()
            this.startWalking()
        }
    }

    getMagic(): MagicEffect | undefined {
        return this.activeMagic
    }

    getTile(): TileEntity | undefined {
        if (this.factor?.roomContext instanceof WalkableRoomViewer) {
            return this.factor.roomContext.tiles.get(this.factor.getTileRef())
        }
        return undefined
    }

    stopDrag(): void {
        this.stopPulse()

        this.downAt = null
        this.cursor = 'draggable'
        Client.shared.view.classList.remove('draggable')
    }

    activateDragMode = (e: FederatedEvent) => {
        if (!this.downAt || this.downAt > Date.now() - 500 || !e.currentTarget) {
            return
        }
        this.cursor = 'dragging'
        Client.shared.view.classList.add('draggable')
        this.downAt = null

        e.stopPropagation()

        this.factor?.roomContext.furniController.setActiveFurniEntity(this)
        this.factor?.roomContext.furniController.setMoveMode(true)
    }

    rotate(): void {
        const allDirs = [0, 1, 2, 3, 4, 5, 6, 7]
        const currentIndex = allDirs.indexOf(+this.direction)

        if (currentIndex === allDirs.length - 1) {
            this.setDirection(allDirs[0])
        } else {
            this.setDirection(allDirs[currentIndex + 1])
        }
    }

    pulsing?: gsap.core.Tween
    floating?: gsap.core.Tween

    stopPulse(): void {
        this.pulsing?.kill()
        this.pulsing = null
        this.alpha = this.factor.isInvisible() ? 0.4 : 1
    }

    pulseOverlay(): void {
        this.pulsing?.kill()
        this.pulsing = gsap.fromTo(
            this,
            { alpha: this.factor.isInvisible() ? 0.4 : 1 },
            {
                alpha: this.factor.isInvisible() ? 0.1 : 0.5,
                duration: 0.5,
                repeat: -1,
                yoyoEase: true
            }
        )
    }

    stopFloating(): void {
        this.floating?.kill()
        this.floating = null
    }

    startFloating(floatHeight: number, floatDelta: number): void {
        this.floating?.kill()
        this.floating = gsap.to(this.bodyContain.pivot, {
            y: floatHeight,
            repeat: -1,
            yoyo: true,
            ease: 'steps(' + floatDelta + ')',
            duration: 0.5
        })
    }

    startRotating(rotating: boolean): void {
        const allDirs = [0, 1, 2, 3, 4, 5, 6, 7]
        let currentIndex = allDirs.indexOf(+this.direction)
        if (rotating === true) {
            this.savedDirectionIndex = allDirs.indexOf(+this.direction)
            this.rotateSetInterval = setInterval(() => {
                if (currentIndex < 8) {
                    currentIndex++
                    this.renderer.setDirection(currentIndex)
                } else {
                    currentIndex = 0
                    this.renderer.setDirection(currentIndex)
                }
            }, 50)
        } else {
            clearInterval(this.rotateSetInterval)
            this.renderer.setDirection(this.savedDirectionIndex)
        }
    }

    sendPlacement(): void {
        Client.shared.serverBroker.send(
            new ClientChat({
                message:
                    '/teleport ' +
                    this.factor.getTileRef() +
                    ' ' +
                    this.direction +
                    ' ' +
                    this.factor.getRoomIdentifier()
            })
        )
    }

    update = () => {
        this.renderer.tick()
    }

    setTileSize(tileSize: number): void {
        this.scale.set(tileSize / 32)
    }

    async loadItemsFromOutfit(): Promise<IClothingItem[]> {
        // avatar/bodyparts should be cached globally so this will just hydrate it into this provider
        await this.provider.loadIfNecessary('avatar/bodyparts')

        if (!this.outfit) {
            return []
        }

        const clothingPromises: Promise<IClothingItem | undefined>[] = [
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.hairId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.hatId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.shirtId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.pantsId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.shoesId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.heldLeftId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.heldRightId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.faceWearId, this.provider),
            Client.shared.figuresMgr.loadClothingItemByDef(this.outfit.backWearId, this.provider)
        ]
        const outfitItems = await Promise.all(clothingPromises)

        return outfitItems.filter((i) => !!i)
    }

    setUpdatedOutfit(outfit: IClientOutfit): void {
        console.log('outfit updated')
        this.outfit = AvatarOutfit.from(outfit) // prevent using a reference
        this.animateVortex()
        this.reloadOutfitItems()
    }

    reloadOutfitItems(): Promise<void> {
        return this.loadItemsFromOutfit()
            .then((clothingItems: IClothingItem[]) => {
                this.currentClothingItems = clothingItems
                this.renderAvi()
                this.disableVortex()
            })
            .catch((e) => {
                console.error('Problem loading outfit items', e)
            })
    }

    renderAvi(): void {
        console.log('>> setting outfit on renderer')
        this.renderer.setOutfit(this.outfit, this.currentClothingItems)
    }

    clearEmote(): void {
        Pooler.release(this.emote)
        this.emote = null
    }

    setEmote(id: number | null, timeout = true): void {
        if (id === null || id === -1) {
            this.clearEmote()
            return
        }

        if (this.emoteTimeout) {
            clearTimeout(this.emoteTimeout)
        }

        if (this.emote && !this.emote.destroyed) {
            this.emote?.destroy()
        }
        this.emote = Pooler.newSprite(`emoticon_${id}`)
        this.emote.position.set(-15, -145)
        this.addChild(this.emote)

        if (timeout) {
            this.emoteTimeout = window.setTimeout(() => {
                this.clearEmote()
            }, 5000)
        }
    }

    setHeadOnly(headOnly: boolean): void {
        this.headOnly = headOnly
        this.shadow.visible = !headOnly
        // this.pivot.y        = headOnly ? -74 : 0;
        this.renderer.setHeadOnly(headOnly)
    }

    setLegsVisible(legsVisible: boolean): void {
        this.renderer.setLegsVisible(legsVisible)
    }

    setShadowVisible(visible: boolean): void {
        this.shadow.visible = visible
    }

    setBodyAlpha(alpha: number): void {
        if (alpha === this.bodyAlpha) {
            return
        }
        this.bodyAlpha = alpha
        const existingAlphaFilter = this.renderer.filters?.find((f) => f instanceof AlphaFilter) as AlphaFilter
        if (existingAlphaFilter) {
            if (alpha >= 1) {
                // if not translucent anymore, remove the filter
                this.renderer.filters.splice(this.renderer.filters.indexOf(existingAlphaFilter), 1)
            } else {
                // update existing alpha filter
                existingAlphaFilter.alpha = alpha
            }
        } else if (alpha < 1) {
            // add an alpha filter if alpha is now translucent
            this.renderer.filters = [...(this.renderer.filters || []), new AlphaFilter(alpha)]
        }
    }

    disableVortex(): void {
        this.loadingVortex?.stop()
        this.loadingVortex?.destroy()
        this.loadingVortex = undefined
    }

    animateVortex(): void {
        if (!this.loadingVortex) {
            this.loadingVortex = AnimatedSprite.fromFrames(
                [0, 1, 2, 3, 4, 5, 6, 7].map((n) => 'magic_loadingfx_0_' + n)
            )
            this.loadingVortex.animationSpeed = 0.2
            this.loadingVortex.updateAnchor = true

            this.addChild(this.loadingVortex)
            this.loadingVortex.play()
        }
    }

    private waveInstance?: string
    private danceInstance?: string
    private walkInstance?: string
    private sitInstance?: string

    wave(): void {
        if (this.waveInstance) {
            return
        }
        this.waveInstance = this.renderer.startAnim(LimbAnimation.Wave, true, [Limb.ArmLeft])

        setTimeout(() => {
            this.renderer.endAnim(this.waveInstance)
            this.waveInstance = undefined
        }, 2000)
    }

    steer(): void {
        console.log('Steering')
        this.renderer.startAnim(LimbAnimation.Carry, true, [Limb.ArmLeft, Limb.ArmRight])
    }

    isMoving(): boolean {
        return this.factor.isWalking()
    }

    startWalking(): void {
        if (this.walkInstance) {
            return
        }
        this.stopSitting()
        this.walkInstance = this.renderer.startAnim(LimbAnimation.Move, true)
    }

    stopWalking(): void {
        if (this.walkInstance) {
            this.renderer.endAnim(this.walkInstance)
            this.walkInstance = undefined
        }
    }

    startDancing(): void {
        if (this.danceInstance) {
            return
        }
        this.danceInstance = this.renderer.startAnim(LimbAnimation.Dance, true)
    }

    stopDancing(): void {
        if (this.danceInstance) {
            this.renderer.endAnim(this.danceInstance)
            this.danceInstance = undefined
        }
    }

    startSitting(): void {
        if (this.sitInstance) {
            return
        }
        this.sitInstance = this.renderer.startAnim(LimbAnimation.Sit, true)
    }

    stopSitting(): void {
        if (this.sitInstance) {
            this.renderer.endAnim(this.sitInstance)
            this.sitInstance = undefined
        }
    }

    lockAnimsForLimbs(limbs: Limb[]): void {
        this.renderer.lockAnimsForLimbs(limbs)
    }

    preventAnims(anims: LimbAnimation[]): void {
        this.renderer.preventAnims(anims)
    }

    stopAnims(): void {
        this.renderer.stopAnims()
    }

    startAnim(anim: LimbAnimation, loops: boolean, limbs?: Limb[], identifier?: string): string {
        return this.renderer.startAnim(anim, loops, limbs, identifier)
    }

    endAnim(identifier: string): void {
        this.renderer.endAnim(identifier)
    }

    setDirection(direction: number): void {
        this.direction = direction
        this.renderer.setDirection(direction)
        this.activeMagic?.setDirection(direction)
    }

    setHeadDirection(direction?: EAvatarDir): void {
        this.renderer.setHeadDirection(direction)
    }

    getDirection(): EAvatarDir {
        return this.direction
    }

    blink(): void {
        if (this.blk && this.ey) {
            this.blk.visible = true
            this.ey.visible = false
            this.blinkTimeout = window.setTimeout(() => {
                this.blk.visible = false
                this.ey.visible = true
            }, 150)
        }
    }

    speak(times = 1): void {
        const speakInstance = this.renderer.startAnim(LimbAnimation.Speak, true)
        setTimeout(() => this.renderer.endAnim(speakInstance), times * 1000)
    }

    getOutfit(): AvatarOutfit {
        return this.outfit
    }

    destroy(): void {
        AvatarVisual.globalArrow = null

        if (!this.destroyed) {
            super.destroy({ children: true })
        }
        this.pulsing?.kill()

        clearInterval(this.blinkInterval)
        clearInterval(this.speakInterval)
        clearInterval(this.vortexInterval)
        clearTimeout(this.emoteTimeout)
        clearTimeout(this.blinkTimeout)
        clearTimeout(this.fxTimeout)

        this.tweener?.kill()

        if (this.activeMagic) {
            this.activeMagic.teardown()
            this.activeMagic = null
        }

        gsap.killTweensOf(this)
    }
}
