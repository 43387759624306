import { AdvancedBloomFilter } from '@pixi/filter-advanced-bloom'
import { EPref } from '@vmk-legacy/common-ts'
import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import { Sprite, Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import { AvatarTooltip } from '../AvatarTooltip.js'

export class HighlightButton extends Sprite {
    private isDown = false
    private isOver = false
    private isDisabled = false

    sound?: AudioBuffer

    private adjustFilter: AdvancedBloomFilter
    private tooltip?: AvatarTooltip

    constructor(
        normalTexture: string,
        readonly title?: string
    ) {
        super()

        this.replaceTextures(normalTexture)

        this.addEventListener('pointerdown', this.onButtonDown)
        this.addEventListener('pointerup', this.onButtonUp)
        this.addEventListener('pointerover', this.onButtonOver)
        this.addEventListener('pointerout', this.onButtonOut)
        this.addEventListener('pointerup', this.onButtonTap)

        this.adjustFilter = new AdvancedBloomFilter()
        this.adjustFilter.enabled = false
        this.adjustFilter.bloomScale = 0.5
        this.adjustFilter.brightness = 1.2
        this.adjustFilter.blur = 2
        this.filters = [this.adjustFilter]

        this.eventMode = 'static'
        this.cursor = 'pointer'
    }

    replaceTextures(normalTexture: string): void {
        this.texture = Texture.from(normalTexture)
    }

    disable(): void {
        this.cursor = 'default'
        this.eventMode = 'auto'
        this.isDisabled = true

        this.alpha = 0.3
    }

    enable(): void {
        this.cursor = 'pointer'
        this.eventMode = 'static'
        this.cursor = 'pointer'
        this.alpha = 1
        this.isDisabled = false
    }

    private onButtonDown = (): void => {
        if (!this.isDisabled) {
            this.adjustFilter.enabled = true
            this.isDown = true
            this.alpha = 1
        }
    }

    private onButtonUp = (): void => {
        if (!this.isDisabled) {
            this.adjustFilter.enabled = false

            if (this.isOver && this.isDown) {
                this.isDown = false
            } else {
                this.isDown = false
            }
        }
    }

    private onButtonTap = (): void => {
        if (!this.isDisabled) {
            if (this.sound) {
                SoundManager.shared.play(ESndGrp.UI, this.sound)
            }
        }
    }

    private onButtonOver = (): void => {
        if (!this.isDisabled) {
            this.isOver = true
            if (this.title && Client.shared.prefs.get(EPref.ShowExtraTooltips)) {
                this.tooltip = this.parent.addChild(new AvatarTooltip(this.title))
                this.tooltip.position.set(this.x + this.width / 2, this.y - this.tooltip.height - 5)
            }
        }
    }

    private onButtonOut = (): void => {
        this.tooltip?.destroy()
        this.tooltip = undefined
        if (!this.isDisabled) {
            this.isOver = false
        }
    }

    isEnabled(): boolean {
        return !this.isDisabled
    }

    isPressed(): boolean {
        return this.isDown // && this.isOver;
    }

    beginGlow(): void {
        this.adjustFilter.enabled = true
        this.glow()
    }

    stopGlow(): void {
        if (this.adjustFilter) {
            gsap.killTweensOf(this.adjustFilter)
            this.adjustFilter.enabled = false
        }
    }

    private glow(): void {
        gsap.to(this.adjustFilter, {
            bloomScale: 0.5,
            brightness: 1.2,
            blur: 2,
            duration: 2,
            onComplete: () => {
                this.fade()
            }
        })
    }

    private fade(): void {
        gsap.to(this.adjustFilter, {
            bloomScale: 0,
            brightness: 1,
            blur: 0,
            duration: 1,
            onComplete: () => {
                this.glow()
            }
        })
    }
}
