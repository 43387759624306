import type { ERoomWhoEnters } from '@vmk-legacy/common-ts'
import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import { EWindow, UILayer } from '../../enums.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { UISoundLibrary } from '../../ui/UISoundLibrary.js'
import { IndeterminateLoadingView } from '../../ui/views/IndeterminateLoadingView.js'
import { RoomLoadingView } from '../../ui/views/RoomLoadingView.js'
import { RoomInfoBox } from '../../ui/windows/RoomInfoBox.js'
import { VMKPassWindow } from '../../ui/windows/rooms/VMKPassWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import { RoomJoinModule } from './RoomJoinModule.js'

export interface IExtensionData {
    id: string
    [k: string]: unknown
}

type Payload = {
    roomId: number
    title?: string
    desc?: string
    ownerId?: number
    ownerIgn?: string
    spaceId: number
    variation?: string
    instance?: string
    own: boolean
    extensions?: IExtensionData[]
    disabledRefs: number[]
    tileTypes: { [type: string]: number[] }
    whoEnters?: ERoomWhoEnters
    clearQueue?: boolean
    data?: any
}

/**
 * Server sends this when entering a room. We load assets needed and tell the server when the room viewer
 * is initialized and our avatar can be placed in the room. Prevents new avatars from showing up for others
 * while the user's client is still loading the room.
 *
 * 1. ROOM ASSETS - load_room -> BeginRoomLoad -> room_ready
 * 2. FURNI ASSETS - room_furni -> RoomFurniLoad -> room_furnished
 * 3. POPULATE ROOM - room_entered -> RoomJoinModule
 */
export class BeginRoomLoad extends ServerEvent {
    static readonly type = MessageType.BEGIN_LOAD_ROOM

    static currentLoadingPayload?: Payload

    override async handle(data: Payload): Promise<void> {
        if (BeginRoomLoad.currentLoadingPayload) {
            if (JSON.stringify(BeginRoomLoad.currentLoadingPayload) === JSON.stringify(data)) {
                // possible the queue loop told us to load again before ready, don't reset
                return
            }
        }
        await Client.shared.runningMinigame?.teardown()
        Client.shared.runningMinigame = undefined

        SoundManager.shared.stopAll(ESndGrp.Music)

        const spaceInfo = Client.shared.roomInfoMgr.getInfo(data.spaceId)
        const title: string = data.title || (spaceInfo ? spaceInfo[0] : 'Room')

        RoomJoinModule.loader?.destroy()

        if (data.clearQueue) {
            VMKPassWindow.instance?.destroy()
        }

        Client.shared.userInterface.closeWindows(UILayer.GameWindows)

        RoomJoinModule.loader = new RoomLoadingView(title)
        Client.shared.userInterface.register(RoomJoinModule.loader)
        Client.shared.loadingView.setVisible(false)

        const desc: string = data.desc || (spaceInfo ? spaceInfo[1] : '')

        const extraData = data.data as any

        if (Client.shared.roomViewer) {
            Client.shared.viewport.removeChild(Client.shared.roomViewer)
            await Client.shared.roomViewer.teardown()
            Client.shared.roomViewer.destroy()
            Client.shared.roomViewer = undefined
        }
        RoomJoinModule.loader?.setProgress(5)

        const viewer = (Client.shared.roomViewer = new WalkableRoomViewer())

        Client.shared.viewport.addChildAt(viewer, 0)

        if (Client.shared.userInterface?.toolbar) {
            Client.shared.userInterface.toolbar.chatbarInput.setValue('')
        }

        viewer.init(data.own || false, data.roomId, data.spaceId, data.variation, data.disabledRefs, data.tileTypes)

        viewer.whoEnters = data.whoEnters

        Client.shared.userInterface.closeWindow(EWindow.Navigator)
        Client.shared.userInterface.toolbar?.enableAll()

        RoomInfoBox.currentData = {
            roomName: title,
            roomId: data.roomId,
            roomOwner: data.ownerIgn,
            roomDesc: desc,
            instance: data.instance,
            whoEnters: data.whoEnters
        }

        await viewer.furniController.setFurniMode(data.ownerId === Client.shared.selfRecord.getId())

        RoomJoinModule.loader?.setProgress(10)

        if (extraData?.sound) {
            viewer.replaceSoundCast = extraData.sound
        }

        try {
            await viewer.loadAssets()
        } catch (error) {
            console.log('Error caught in prepare')
            console.log(error)

            await viewer.teardown()

            Client.shared.helpers.alert({
                title: 'Room Load Error',
                message:
                    'Something went wrong while loading this room. Please try again or report this to staff: ' +
                    ('name' in error ? error.name + ': ' + error.message : JSON.stringify(error)),
                tag: 'roomload.error'
            })

            Client.shared.userInterface.showWindow(EWindow.Navigator)
            Client.shared.loadingView.setVisible(false)
            RoomJoinModule.loader?.destroy()
            RoomJoinModule.loader = undefined

            throw error
        }

        RoomJoinModule.loader?.setProgress(50)

        await viewer.setupExtensions(data.extensions)

        RoomJoinModule.loader?.setProgress(60)

        let showLoader: IndeterminateLoadingView | undefined
        let sfx: AudioBufferSourceNode | undefined
        if (extraData?.show) {
            showLoader = new IndeterminateLoadingView('Loading show items', 'Please wait')
            Client.shared.userInterface.register(showLoader, true)

            sfx = SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.LoadingLoop, true)
        }

        await viewer.receiveExtraData(extraData)

        if (showLoader) {
            Client.shared.userInterface.removeWindow(showLoader)
        }
        if (sfx) {
            SoundManager.shared.release(sfx)
        }

        RoomJoinModule.loader?.setProgress(75)

        Client.shared.userInterface?.toolbar?.chatbarInput.setValue('')

        console.log('>> room load stage 1 done')
        Client.shared.serverBroker.send('room_loaded', 1)
    }
}
