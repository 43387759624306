import { Client } from '../../Client.js'
import { FurniAppearAnim } from '../../room/renderer/FurniAppearAnim'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { RoomItem } from '../../room/RoomItem.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class FurniPlacedModule extends ServerEvent {
    static readonly type = MessageType.FURNI_PLACED

    async handle(data: { item: any }): Promise<void> {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            const roomItem = new RoomItem(data.item)

            Client.shared.roomViewer.furniController.addItem(roomItem, false, FurniAppearAnim.dropBounce)
        }
    }
}
