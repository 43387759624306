import type { ItemDefUid } from '@vmk-legacy/common-ts'
import { EPerm } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { DisplayObject, FederatedEvent, Sprite } from 'pixi.js'
import { BitmapText, Container, Graphics } from 'pixi.js'
import { getText, getVar } from '../../assets/ExternalConfigManager.js'
import { PinCache } from '../../assets/PinCache.js'
import { Client } from '../../Client.js'
import { EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { MagicDefinitions } from '../../room/entities/fx/MagicDefinitions.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../buttons/ImageButton.js'
import type { ThumbnailBox } from '../containers/ThumbnailBox.js'
import { FieldH } from '../fields/FieldH.js'
import type { IWindow } from './IWindow.js'
import { PopupBox } from './PopupBox.js'

export class FXWindow extends Container implements IWindow {
    readonly kind = EWindow.Magic
    layer = UILayer.GameWindows
    isDraggable = false
    exitBtn: ImageButton
    header: DisplayObject
    readonly BOX_WIDTH: number = 253
    readonly BOX_HEIGHT: number = 215
    ROW_1_Y = -125
    readonly NAME_FIELD_X: number = 27
    readonly NAME_FIELD_WIDTH: number = 190
    lanyardThumbnails: ThumbnailBox[]
    private titleField: BitmapText
    currentInstanceId = -1

    private topTf: BitmapText
    private magicWord: BitmapText
    private waitHeader: BitmapText
    private wait: BitmapText

    private selected?: MagicPinBox

    constructor() {
        super()

        this.x = 494
        this.y = 488 + Client.shared.userInterface.getObscuringTopHeight()

        this.isDraggable = false

        const staff: boolean = Client.shared.selfRecord.can(EPerm.MKAccessClient)
        const staffOffY: number = staff ? 32 : 0

        const background = Pooler.newSprite()

        const bgBox: PopupBox = new PopupBox(this.BOX_WIDTH, this.BOX_HEIGHT + staffOffY, false)
        background.addChild(bgBox)
        bgBox.y = -130 - staffOffY

        this.addChild(background)

        this.ROW_1_Y -= staffOffY

        const qButton: ImageButton = new ImageButton('button.helpb.active', 'button.helpb.pressed')
        qButton.x = 8
        qButton.y = this.ROW_1_Y
        this.addChild(qButton)

        const xButton: ImageButton = new ImageButton('button.closeb.active', 'button.closeb.pressed')
        xButton.x = this.BOX_WIDTH - 24
        xButton.y = this.ROW_1_Y
        this.addChild(xButton)
        xButton.addEventListener('pointerup', () => {
            this.setVisible(false)
        })

        //

        const offY: number = -127 - staffOffY
        this.topTf = new BitmapText('', Fonts.Foxley_16)
        this.topTf.position.set(10, 24 + offY)
        this.addChild(this.topTf)

        const magicWordHeader = new BitmapText(getText('magics.word.hd'), Fonts.FoxleyBold_16)
        magicWordHeader.position.set(10, 39 + offY)
        this.addChild(magicWordHeader)

        this.magicWord = new BitmapText('', Fonts.Foxley_16)
        this.magicWord.position.set(93, 40 + offY)
        this.addChild(this.magicWord)

        this.waitHeader = new BitmapText(getText('magics.timeout.hd'), Fonts.FoxleyBold_16)
        this.waitHeader.position.set(10, 54 + offY)
        this.addChild(this.waitHeader)

        this.wait = new BitmapText('', Fonts.Foxley_16)
        this.wait.position.set(46, 55 + offY)
        this.addChild(this.wait)

        this.exitBtn = xButton

        const f = new FieldH(this.NAME_FIELD_WIDTH)
        f.x = this.NAME_FIELD_X
        f.y = this.ROW_1_Y
        this.addChild(f)

        const fx = Client.shared.selfRecord.getLanyard().filter((p) => p.single || p.stars > 0)
        if (!fx.length) {
            this.topTf.text = getText('magics.nomagic')
            this.magicWord.visible = false
            this.waitHeader.visible = false
            magicWordHeader.visible = false
        }
        let index = 0

        for (let r = 0; r < 3; r++) {
            for (let c = 0; c < 5; c++) {
                const box = new MagicPinBox()
                box.x = 8 + (42 + 4) * c + 5
                box.y = 10 + (42 + 4) * r - 70 + 3 - staffOffY
                this.addChild(box)
                if (index < fx.length) {
                    box.defUid = fx[index].defUid
                    box.ownedId = fx[index].id
                    box.stars = fx[index].stars
                    box.setIcon(Client.shared.selfRecord.getRecharging().includes(box.ownedId), fx[index].single)
                    box.eventMode = 'static'
                    box.addEventListener('pointerup', this.clickMagic)
                }
                index++
            }
        }

        if (staff) {
            const invisibleButton = new BitmapTextButton(215, 'TOGGLE INVISIBILITY', BitmapTextButtonType.LIGHT_BLUE)
            invisibleButton.position.set(14, 54)
            this.addChild(invisibleButton)
            invisibleButton.addEventListener('pointerup', () => {
                console.log('TAP')
                Client.shared.serverBroker.send('invisibility_toggle')
            })
        }

        this.titleField = new BitmapText('Magic', {
            ...Fonts.FoxleyBold_16
        })
        const distX: number = Math.round((this.NAME_FIELD_WIDTH - this.titleField.textWidth) / 2)
        this.titleField.x = 4 + this.NAME_FIELD_X + distX
        this.titleField.y = this.ROW_1_Y + 1
        this.addChild(this.titleField)
    }

    updateIcons() {
        for (const child of this.children) {
            if (child instanceof MagicPinBox) {
                child.setIcon(Client.shared.selfRecord.getRecharging().includes(child.ownedId))
            }
        }
    }

    clickMagic = (e: FederatedEvent) => {
        this.selected?.setSelected(false)
        const box = e.currentTarget as MagicPinBox
        box.setSelected(true)
        this.selected = box

        let magicType
        const fxTypes = getVar('fxtypes')
        for (const type in fxTypes) {
            if (fxTypes[type].includes(box.defUid)) {
                magicType = type
            }
        }
        this.topTf.text = getText('item.' + box.defUid)
        if (magicType && MagicDefinitions.defs[magicType]) {
            this.magicWord.text = MagicDefinitions.defs[magicType].word + '!'
        }
        const now = new Date().getTime()
        if (now - box.lastTap < 500) {
            Client.shared.serverBroker.send('use_magic', box.ownedId)
            Client.shared.userInterface.closeWindow(EWindow.Magic)
        } else {
            box.lastTap = now
        }
    }

    getWindowWidth(): number {
        return this.BOX_WIDTH
    }

    getWindowHeight(): number {
        return this.BOX_HEIGHT
    }

    setVisible(visible: boolean) {
        this.visible = visible
    }

    reset() {
        // clear fields,
        // set active tab,
        // etc.
    }
}

export class MagicPinBox extends Container {
    private bg: Graphics
    private border: Graphics
    private icon: Sprite
    defUid: ItemDefUid

    lastTap: number

    boxSize = 42
    borderSize = 2
    ownedId: number
    stars: number

    constructor() {
        super()
        this.bg = new Graphics()
        this.bg.beginFill(0x285692)
        this.bg.drawRect(0, 0, this.boxSize, this.boxSize)
        this.bg.endFill()
        this.addChild(this.bg)
    }

    setSelected(selected = true): void {
        if (this.icon) {
            if (this.border) {
                this.removeChild(this.border)
                this.border = null
            }
            this.border = new Graphics()
            this.border.beginFill(selected ? 0xffd200 : 0x50a2d4)
            this.border.drawRect(0, 0, this.borderSize, this.boxSize)
            this.border.drawRect(0, 0, this.boxSize, this.borderSize)
            this.border.drawRect(this.boxSize - this.borderSize, 0, this.borderSize, this.boxSize)
            this.border.drawRect(0, this.boxSize - this.borderSize, this.boxSize, this.borderSize)
            this.border.endFill()
            this.addChild(this.border)
        }
    }

    getId(): ItemDefUid {
        return this.defUid
    }

    setIcon(isRecharging = false, isSingle = false): void {
        if (this.icon) {
            Pooler.release(this.icon)
        }
        if (!this.defUid) {
            return
        }
        if (isRecharging) {
            this.icon = this.addChild(Pooler.newSprite('magic.timer'))
            this.icon.position.set(2)
            return
        }

        PinCache.instance.getTexture(this.defUid, true).then((tex) => {
            this.icon = Pooler.newSprite(tex)
            const ratio = MagicPinBox.calculateAspectRatioFit(
                this.icon.width,
                this.icon.height,
                this.boxSize - 2 * this.borderSize,
                this.boxSize - 2 * this.borderSize
            )
            this.icon.width = ratio.width
            this.icon.height = ratio.height

            this.addChild(this.icon)

            this.icon.x = Math.round((this.boxSize - ratio.width) / 2)
            this.icon.y = Math.round((this.boxSize - ratio.height) / 2)

            if (isSingle) {
                this.icon = this.addChild(Pooler.newSprite('icon.singleuse'))
                this.icon.position.set(2)
            } else {
                for (let i = 0; i < this.stars; i++) {
                    const star = Pooler.newSprite('inv.pin.star.thumb')
                    star.x = i * 7 + 4
                    star.y = this.boxSize - star.height - 2
                    this.addChild(star)
                }
            }

            this.setSelected(false)
        })
    }

    static calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

        return {
            width: srcWidth * ratio,
            height: srcHeight * ratio
        }
    }
}
