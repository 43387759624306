import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { Graphics } from 'pixi.js'
import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { LoadingScreensManager } from '../../assets/LoadingScreensManager.js'
import { Client } from '../../Client.js'
import { MagicPinBox } from '../windows/FXWindow.js'
import { ResponsiveContainer } from './AlertView.js'
import { IndeterminateLoadingView } from './IndeterminateLoadingView.js'

export class LoadingView extends ResponsiveContainer {
    private loaderBox: IndeterminateLoadingView
    private bg: Sprite

    constructor(status: string, wait: string) {
        super()

        const black = new Graphics()
        black.beginFill(0x000000)
        black.drawRect(0, 0, Client.shared.size.width, Client.shared.size.height)
        this.addChild(black)

        const bgTex = LoadingScreensManager.instance.getRandomLoadingScreenTexture()

        this.bg = Pooler.newSprite(bgTex)
        // in case image is not full size, center it
        this.bg.anchor.set(0.5, 0.5)
        this.bg.position.set(Client.shared.size.width / 2, Client.shared.size.height / 2)
        if (bgTex) {
            const fit = MagicPinBox.calculateAspectRatioFit(
                bgTex.width,
                bgTex.height,
                Client.shared.size.width,
                Client.shared.size.height
            )
            this.bg.width = fit.width
            this.bg.height = fit.height
        }
        this.addChild(this.bg)

        this.loaderBox = new IndeterminateLoadingView(status, wait)

        this.addChild(this.loaderBox)

        this.eventMode = 'static'
    }

    setVisible(isVisible: boolean): void {
        if (isVisible && !this.visible) {
            const bgTex = LoadingScreensManager.instance.getRandomLoadingScreenTexture()
            if (bgTex) {
                const fit = MagicPinBox.calculateAspectRatioFit(
                    bgTex.width,
                    bgTex.height,
                    Client.shared.size.width,
                    Client.shared.size.height
                )
                this.bg.texture = bgTex
                this.bg.width = fit.width
                this.bg.height = fit.height
            }
        }
        this.visible = isVisible
        if (isVisible && Client.shared.userInterface?.toolbar) {
            Client.shared.userInterface.toolbar.visible = false
            Client.shared.userInterface.toolbar?.disableChatbar()
        }
    }

    setStatusText(status: TextKey): void {
        this.loaderBox.setStatusText(status)
    }

    setWaitText(wait: TextKey): void {
        this.loaderBox.setWaitText(wait)
    }

    override sizeDidChange(): void {
        this.bg.x = Math.round(Client.shared.size.width / 2)
        this.bg.y = Math.round(Client.shared.size.height / 2)

        if (this.bg.texture) {
            const fit = MagicPinBox.calculateAspectRatioFit(
                this.bg.texture.width,
                this.bg.texture.height,
                Client.shared.size.width,
                Client.shared.size.height
            )
            this.bg.width = fit.width
            this.bg.height = fit.height
        }
    }
}
