import type { Point3D } from '../3DUtils.js'
import type { TileEntity } from './TileEntity.js'

export class Floor {
    private grid: TileEntity[][] = []
    private tiles: TileEntity[] = []

    constructor(
        public name?: string,
        public point: Point3D
    ) {}

    setOrigin(x: number, y: number, z: number) {
        this.point.set3DX(x)
        this.point.set3DY(y)
        this.point.set3DZ(z)

        this.tiles.forEach((t) => {
            t.updatePosition()
        })
    }

    getTile(x: number, y: number): TileEntity | undefined {
        if (this.grid[x] && this.grid[x][y]) {
            return this.grid[x][y]
        }

        return undefined
    }

    getTiles() {
        return this.tiles
    }

    addTile(tile: TileEntity): void {
        const x = tile.getMapX()
        const y = tile.getMapY()
        if (!this.grid[x]) {
            this.grid[x] = []
        }
        this.grid[x][y] = tile
        this.tiles.push(tile)
    }

    destroy(): void {
        for (const t of this.tiles) {
            t.destroy()
        }
        this.tiles = []
        this.grid = []
    }
}
