import type { DisplayObject } from 'pixi.js'
import { EWindow, UILayer } from '../../enums.js'
import LegacyWindow from '../../ui/windows/LegacyWindow.js'

export class PotcModal extends LegacyWindow {
    kind = EWindow.PiratesModal
    layer = UILayer.GameWindows

    protected casts = ['minigame/pirates2_interface']

    constructor(embedding?: DisplayObject) {
        super('vmk_pirates_modal', embedding)
    }
}
