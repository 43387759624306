import { AssetProvider, Pooler } from '@vmk-legacy/render-utils'
import type { Container, Sprite } from 'pixi.js'
import { Graphics } from 'pixi.js'
import { Client } from '../../Client.js'
import type { EWindow } from '../../enums.js'
import { UILayer } from '../../enums.js'
import type { ImageButton } from '../buttons/ImageButton.js'
import { ResponsiveContainer } from '../views/AlertView.js'
import type { IWindow } from './IWindow.js'
import type LegacyWindow from './LegacyWindow.js'
import type { UIWindowTab } from './UIWindowTab.js'
import { UIWindowType } from './UIWindowType.js'
import { UIWindowView } from './UIWindowView.js'

const HeaderMarginX = 15
const ContentPadding = 5
const ContentBorder = 5
const ContentMargin = 10
const WinTints = {
    [UIWindowType.GUEST_ROOMS]: 0xbca527,
    [UIWindowType.TRADE]: 0xb8d226,
    [UIWindowType.CHARACTER]: 0xc02190,
    [UIWindowType.MESSENGER]: 0x23d124,
    [UIWindowType.SETTINGS]: 0x346fbd,
    [UIWindowType.MOUSEKEEPING]: 0x346fbd,
    [UIWindowType.INVENTORY]: 0xfc832c,
    [UIWindowType.HELP]: 0x4f00e7,
    [UIWindowType.GIFTS]: 0xe8cb1d,
    [UIWindowType.SHOP]: 0x1cbcba
}

export abstract class UIWindow extends ResponsiveContainer implements IWindow {
    playsOpenSound = true
    abstract readonly kind: EWindow
    readonly layer: UILayer = UILayer.GameWindows

    protected views: Container[] = []
    protected activeTab: UIWindowTab
    protected tabs: UIWindowTab[] = []

    exitBtn?: ImageButton
    protected totalWidth = 0
    protected totalHeight = 0

    protected windowType: UIWindowType

    isDraggable = true
    dragBounds = false
    protected innerWidth: number
    protected innerHeight: number

    header: Sprite

    winBg: Graphics
    contentBg: Graphics

    provider = new AssetProvider(Client.shared.assetLoader)

    alreadyBuilt = false
    protected buildingPromise: Promise<void>

    constructor(
        readonly initialHeight?: number,
        readonly windowType?: UIWindowType
    ) {
        super()

        this.eventMode = 'static'
        this.interactiveChildren = true

        this.windowType = windowType
    }

    abstract windowWasBuilt(): Promise<void>

    waitToBeBuilt(): Promise<void> {
        if (this.buildingPromise) {
            return this.buildingPromise
        }
        return this.alreadyBuilt ? Promise.resolve() : this.rebuild()
    }

    async rebuild(): Promise<void> {
        if (this.buildingPromise) {
            await this.buildingPromise
        }

        this.buildingPromise = this._buildWindow()

        return this.buildingPromise
    }

    async _buildWindow(): Promise<void> {
        if (!this.windowType) {
            console.log('not building window, no type set')
            return
        }

        this.removeChildren()

        if (this.windowType === UIWindowType.SETTINGS) {
            this.header = Pooler.newSprite('set.settings')
        } else {
            this.header = Pooler.newSprite(this.windowType + '.main')
        }

        this.header.x = HeaderMarginX
        this.header.y = 8

        const tabBG = new Graphics()
        tabBG.lineStyle(2, 0xf0f0f0, 1, 0)
        tabBG.beginFill(WinTints[this.windowType])
        tabBG.drawRoundedRect(0, 0, this.header.width + 17, 25, 25)
        tabBG.endFill()

        tabBG.position.set(6, this.header.y + this.header.height)
        this.totalHeight = innerHeight + tabBG.y + tabBG.height + 25

        this.addChild(this.header, tabBG)

        const innerWidth = (this.innerWidth = this.header.width)

        this.innerWidth = innerWidth
        this.innerHeight = innerHeight

        this.winBg = new Graphics()

        this.addChildAt(this.winBg, 0)

        this.totalWidth = innerWidth

        this.contentBg = new Graphics()
        this.contentBg.position.set(ContentMargin, tabBG.y + tabBG.height - 2)

        this.addChildAt(this.contentBg, 1)

        for (const t of this.tabs) {
            this.addChild(t)
        }
        for (const v of this.views) {
            this.addChild(v)
        }

        if (this.alreadyBuilt) {
            this.addChild(this.exitBtn)
        } else {
            this.alreadyBuilt = true
            await this.windowWasBuilt()
        }
    }

    async setActiveTab(tab: UIWindowTab): Promise<void> {
        if (!tab) {
            tab = this.tabs[0]
        }
        if (!tab) {
            return
        }
        if (tab.view instanceof UIWindowView) {
            tab.view.update()
        }
        if ('embed' in this) {
            await (this as LegacyWindow).embed(tab.view)
        } else {
            const extraPadding = tab.view instanceof UIWindowView ? tab.view.padding : 0
            tab.view.position.set(
                ContentMargin + ContentBorder + ContentPadding + extraPadding,
                this.contentBg.y + ContentBorder + ContentPadding + extraPadding
            )
            this.addChild(tab.view)
        }
        if (tab === this.activeTab) {
            return
        }
        this.activeTab = tab

        for (const t of this.tabs) {
            t.setActive(tab === t)
        }

        this.sizeDidChange()
    }

    getWindowWidth(): number {
        return this.totalWidth
    }

    getWindowHeight(): number {
        return this.totalHeight
    }

    reset(): void {
        //
    }

    setVisible(visible: boolean): this {
        if (this.visible === visible) {
            return this
        }

        this.visible = visible

        return this
    }

    sizeDidChange(): void {
        if (!this.header) {
            return
        }
        const extraPadding = this.activeTab?.view instanceof UIWindowView ? this.activeTab.view.padding : 0
        let viewHeight = this.activeTab?.view ? this.activeTab.view.height : this.initialHeight
        if (viewHeight < this.initialHeight - 25) {
            viewHeight = this.initialHeight - 25
        }
        const contentHeight = viewHeight + ContentPadding * 2 + ContentBorder * 2 + extraPadding * 2
        const contentWidth = this.header.width + 5

        this.totalHeight = contentHeight + this.header.height + 40

        this.winBg.clear()

        this.winBg.lineStyle(3, 0xc0c0c0, 1, 0)
        this.winBg.beginFill(0x011e55, 1)

        // start in top right
        this.winBg.moveTo(HeaderMarginX + this.header.width + HeaderMarginX, 0)

        // topleft corner
        this.winBg.arcTo(0, 0, 0, 61, 55)

        // bottom left
        this.winBg.arcTo(0, this.totalHeight, 10, this.totalHeight, 10)

        // bottom right
        this.winBg.arcTo(
            HeaderMarginX + this.header.width + HeaderMarginX,
            this.totalHeight,
            HeaderMarginX + this.header.width + HeaderMarginX,
            this.totalHeight - 10,
            10
        )

        // top right corner
        this.winBg.lineTo(HeaderMarginX + this.header.width + HeaderMarginX, 0)

        this.winBg.endFill()

        this.contentBg.clear()
        this.contentBg.lineStyle(5, 0xadd1fe, 1, 0)

        // fill in top left hard corner
        this.contentBg.moveTo(5, 0)
        this.contentBg.lineTo(5, 10)

        // fill in top right hard corner
        this.contentBg.moveTo(contentWidth, 0)
        this.contentBg.lineTo(contentWidth, 10)

        this.contentBg.beginFill(0x275692, 1)

        // start in top right
        this.contentBg.moveTo(contentWidth, 0)

        // topleft corner
        this.contentBg.arcTo(0, 0, 0, 10, 10)

        // bottom left
        this.contentBg.arcTo(0, contentHeight, 10, contentHeight, 10)

        // bottom right
        this.contentBg.arcTo(contentWidth, contentHeight, contentWidth, contentHeight - 10, 10)

        // top right corner
        this.contentBg.arcTo(contentWidth, 0, contentWidth - 10, 0, 10)

        this.contentBg.moveTo(contentWidth, 0)
        this.contentBg.lineTo(contentWidth, 10)

        this.contentBg.endFill()
    }

    destroy(): void {
        if (this._destroyed) {
            return
        }
        console.log('Destroying UIWindow')

        super.destroy({ children: true })

        this.provider?.teardown()
        this.provider = undefined
    }
}
