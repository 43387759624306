export enum EWindow {
    Other = 0,
    Inventory = 1,
    Shopping = 2,
    Character = 3,
    Settings = 4,
    Help = 5,
    Quests = 6,
    Trading = 7,
    Messenger = 8,
    Mod = 9,
    GuestRooms = 10,
    RoomInfoBox = 11,
    Lanyard = 12,
    Emotes = 13,
    Navigator = 14,
    Magic = 15,
    CamButtons = 16,
    CamViewfinder = 17,
    PhotoCatalog = 18,
    PiratesModal = 19
}

export enum UILayer {
    AlwaysOnTop = 0,
    Legacy = 1,
    Loaders = 2,
    GameWindows = 3
}

export type EntityRoomIdentifier = string
export type PlayerDatabaseId = number
