import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import LegacyWindow from '../LegacyWindow.js'

export class CameraWindow extends LegacyWindow {
    readonly kind = EWindow.CamButtons

    constructor() {
        super('vmk_photo_popup')

        this.isDraggable = false
    }

    override async windowWasBuilt(): Promise<void> {
        const takePhotoBtn = this.getElement('camera.takephoto.button')
        const catalogBtn = this.getElement('camera.opencatalog.button')

        takePhotoBtn.once('pointertap', () => {
            Client.shared.userInterface.removeWindow(this)
            Client.shared.userInterface.showWindow(EWindow.CamViewfinder)
        })
        catalogBtn.once('pointertap', () => {
            Client.shared.userInterface.removeWindow(this)
            Client.shared.userInterface.showWindow(EWindow.PhotoCatalog)
        })
    }
}
