import type { DisplayObject } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { BasicBox } from '../containers/BasicBox.js'
import { DOMText } from '../DOMText.js'
import { FieldG } from '../fields/FieldG.js'
import { FieldH } from '../fields/FieldH.js'
import { RoomInfoBox } from './RoomInfoBox.js'
import { UIWindow } from './UIWindow.js'

export class RoomInfoPopup extends UIWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop

    header: DisplayObject
    isDraggable = true
    exitBtn: ImageButton

    private title: string
    private body: string // \n for newline

    private nameTf: DOMText
    private descTf: DOMText

    constructor(
        readonly roomId: number,
        readonly oldName: string,
        readonly oldDesc: string
    ) {
        super()
    }

    async _buildWindow() {
        this.title = 'Edit Room'
        this.body =
            "Type a name and description for your room. You can change these later so don't worry if you can't think of the perfect name just now."

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        const bg = new BasicBox(260, 280)
        this.addChild(bg)

        const titleBg = new FieldH(253)

        this.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titleTextWidth: number = titleText.textWidth
        titleText.x = Math.round((bg.width - titleTextWidth) / 2)
        this.addChild(titleText)

        const bodyText = new BitmapText(this.body || '', {
            ...Fonts.Foxley_16
        })
        bodyText.maxWidth = 260
        bodyText.x = Math.round((bg.width - bodyText.textWidth) / 2)
        bodyText.y = 38

        this.addChild(bodyText)
        this.header = titleBg

        const roomName = new BitmapText('ROOM NAME', {
            fontName: 'Folio',
            fontSize: 12
        })
        roomName.x = 12
        roomName.y = 38 + 52
        this.addChild(roomName)
        this.nameTf = new DOMText({
            kind: 'field',
            className: 'room',
            id: 'room.edit.name',
            maxlength: 40,
            fieldWidth: 242,
            fieldHeight: 18,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: RoomInfoBox.currentData?.roomName || '',
            bgObject: new FieldG(245, 11),
            padLeft: 9
        })
        this.addChild(this.nameTf)
        this.nameTf.position.set(12, 38 + 52 + 19)

        const roomDesc = new BitmapText('ROOM DESCRIPTION', {
            fontName: 'Folio',
            fontSize: 12
        })
        roomDesc.x = 12
        roomDesc.y = 38 + 52 + 48
        this.addChild(roomDesc)

        this.descTf = new DOMText({
            kind: 'area',
            className: 'room',
            id: 'room.edit.desc',
            maxlength: 162,
            fieldWidth: 242,
            fieldHeight: 90,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: RoomInfoBox.currentData?.roomDesc || '',
            bgObject: new FieldG(245, 88),
            padLeft: 9
        })
        this.addChild(this.descTf)
        this.descTf.position.set(12, 38 + 52 + 19 + 46)

        const submitBtn = new BitmapTextButton(100, 'OK', BitmapTextButtonType.TEAL)
        submitBtn.position.set(20, 265)
        this.addChild(submitBtn)
        submitBtn.addEventListener('pointerup', () => {
            if (this.nameTf.getValue().trim() === '') {
                Client.shared.helpers.alert('We would lose your room without a name, please choose one!')
                return
            }
            submitBtn.disable()
            Client.shared.serverBroker.send('room_info_change', {
                id: +this.roomId,
                name: String(this.nameTf.getValue()),
                desc: String(this.descTf.getValue())
            })
            Client.shared.userInterface.removeWindow(this)
        })

        const cancelBtn = new BitmapTextButton(100, 'CANCEL', BitmapTextButtonType.TEAL)
        cancelBtn.position.set(29 + 9 + 115, 265)
        this.addChild(cancelBtn)
        cancelBtn.addEventListener(
            'pointerup',
            () => {
                Client.shared.userInterface.removeWindow(this)
            },
            { once: true }
        )

        this.center()
    }

    async windowWasBuilt(): Promise<any> {
        return Promise.resolve(undefined)
    }

    center() {
        console.log('centering', this)
        this.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }
}
