/*
 * ServerControlledShow.ts
 * VMK Legacy Client
 */

import type { EAvatarDir, ItemDefUid } from '@vmk-legacy/common-ts'
import { EItemType } from '@vmk-legacy/common-ts'
import type { ScoreSoundItem, SoundChannel } from '@vmk-legacy/render-utils'
import { Pooler, ScoreRunner, SoundChannelItem } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { Assets, Ticker } from 'pixi.js'
import { Client } from '../../Client.js'
import Config from '../../Config.js'
import { Constants } from '../../Constants.js'
import { Helpers } from '../../util/Helpers.js'
import { RandomUtil } from '../../util/RandomUtil.js'
import { Point3D } from '../renderer/3DUtils.js'
import { Floor } from '../renderer/types/Floor.js'
import type { FurniEntity } from '../renderer/types/FurniEntity.js'
import { TileEntity } from '../renderer/types/TileEntity.js'
import type { WalkableRoomViewer } from '../renderer/WalkableRoomViewer.js'
import { RoomItem } from '../RoomItem.js'
import { RoomTileType } from '../RoomTile.js'

interface FloatInfo {
    floor: string
    coord: number
    axis: 'x' | 'y'
    direction: 'inc' | 'dec'
    speed: number
    stopAt: number
    pauseAt?: number
    pauseMs?: number
    mediaId: number
    addedAt?: number
}

export class ServerControlledShow {
    private score: ScoreRunner = new ScoreRunner()
    refGen = 999999
    idGen = 999999
    mainTicker: Ticker
    tickers: Ticker[] = []
    floors: Floor[] = []
    didStartScore = false
    banner?: Sprite
    speedMultiplier = 1
    assetAliasesToClear: string[] = []

    constructor(
        readonly room: WalkableRoomViewer,
        readonly data: {
            startedAt?: number
            activeFloats?: FloatInfo[]
            preload: {
                name: string
                mediaUrls: string[]
                furniItemDefUids: string[]
                score: {
                    mediaId: number
                    startMs: number
                    loops?: number
                    channel: number
                    fadesOut: boolean
                }[]
            }
        }
    ) {
        this.score.addSoundChannel('Show 1', this.speedMultiplier)
        this.score.addSoundChannel('Show 2', this.speedMultiplier)
        this.score.addSoundChannel('Show 3', this.speedMultiplier)
        this.score.loops = false

        this.mainTicker = new Ticker()
        this.mainTicker.minFPS = this.mainTicker.maxFPS = ScoreRunner.frameRate * this.speedMultiplier
        this.mainTicker.start()

        this.mainTicker.add(() => this.score.tick(), this as any)
    }

    async loadShow(): Promise<void> {
        try {
            const manifest = this.data.preload
            console.log('[SHOW] Loading manifest: ', manifest)
            await Helpers.delay(RandomUtil.generateRandomIntegerInRange(0, 1000))

            const loaded = await Assets.load(
                manifest.mediaUrls.map((u) => {
                    const alias = 'showmedia-' + u.substring(0, u.indexOf('/'))

                    this.assetAliasesToClear.push(alias)

                    return {
                        src: Config.environment.uploadsRoot + '/' + u,
                        alias: [alias]
                    }
                })
            )

            let furniItemDefUids: string[] = []
            for (const name in loaded) {
                console.log('[SHOW] Loaded media ' + name, loaded[name])
                const floatData = loaded[name]
                if (typeof floatData !== 'object' || floatData.type !== 'RoomFurniList') {
                    continue
                }
                furniItemDefUids.push(...floatData.items.map((i) => i.itemDefUid))
            }

            furniItemDefUids = [...new Set(furniItemDefUids)]

            await this.preloadFurnis(furniItemDefUids)

            let lastFrame = 0

            for (const item of manifest.score) {
                const media = Assets.get('showmedia-' + item.mediaId) as AudioBuffer
                if (!media) {
                    console.log('[SHOW] Missing score media showmedia-' + item.mediaId)
                    continue
                }
                const channel = this.score.channels[item.channel - 1] as SoundChannel
                const startFrame = Math.floor((item.startMs / 1000) * ScoreRunner.frameRate) + 1
                console.log(
                    '[SHOW] Will add audio to ' +
                        channel.label +
                        ' (showmedia-' +
                        item.mediaId +
                        ') duration ' +
                        media.duration +
                        ' startMs ' +
                        item.startMs +
                        ' aka frame ' +
                        startFrame
                )

                const overlaps = channel.getItemAtFrame(startFrame)
                if (overlaps) {
                    console.log(
                        '[SHOW] Shortening overlapping audio ' +
                            overlaps.label +
                            ' from frame ' +
                            overlaps.endFrame +
                            ' to ' +
                            startFrame
                    )
                    overlaps.setEndFrame(startFrame)
                }

                const musicStep: ScoreSoundItem = {
                    start: startFrame,
                    end: startFrame + Math.ceil(media.duration * ScoreRunner.frameRate) * (item.loops || 1),
                    asset: 'Show media ' + item.mediaId,
                    type: 'music'
                }
                const snd = new SoundChannelItem(channel, musicStep)
                snd.loopCount = item.loops ?? 1
                snd.setAudio(media)
                const playItem = snd.channel.addItem(snd, musicStep.start, musicStep.end)
                console.log(
                    '[SHOW] Added audio to channel ' +
                        channel.label +
                        ' (showmedia-' +
                        item.mediaId +
                        ') frames ' +
                        playItem.startFrame +
                        '-' +
                        playItem.endFrame
                )

                if (item.fadesOut) {
                    console.log('[SHOW] Adding fade out to channel ' + channel.label)
                    const fadeOutStep: ScoreSoundItem = {
                        // NAME: 'Fade out media ' + item.mediaId,
                        start: playItem.endFrame - ScoreRunner.frameRate * 2,
                        end: playItem.endFrame,
                        type: 'vol',
                        vol: [255, 0, 2000]
                    }
                    const fadeOutItem = channel.addItem(
                        new SoundChannelItem(channel, fadeOutStep),
                        fadeOutStep.start,
                        fadeOutStep.end
                    )

                    const resetVolStep: ScoreSoundItem = {
                        // NAME: 'Reset vol',
                        start: fadeOutItem.endFrame + 1,
                        end: fadeOutItem.endFrame + 1,
                        type: 'vol',
                        vol: 255
                    }

                    channel.addItem(new SoundChannelItem(channel, resetVolStep), resetVolStep.start)
                }

                const channelMax = Math.max(...channel.currentItems.map((i) => i.endFrame))
                if (channelMax > lastFrame) {
                    lastFrame = channelMax
                }
            }

            this.score.setLastFrame(lastFrame)
        } catch (e) {
            Client.shared.helpers.alert('There was an issue loading the show. Please try again. Error: ' + String(e))
            console.error('[SHOW] Error in loadShow', e)
        }
    }

    roomIsReady(): void {
        if (this.didStartScore) {
            console.log('[SHOW] Score should have already started, playing!')
            this.muteRoomMusic(false)
            this.score.playFromBeginning()
        } else if (this.data.startedAt) {
            this.didStartScore = true
            const secElapsed = (Date.now() - this.data.startedAt) / 1000
            const framesElapsed = Math.round(secElapsed * ScoreRunner.frameRate)
            console.log('[SHOW] Show already going, ' + secElapsed + 's ' + framesElapsed + 'f elapsed!')
            this.muteRoomMusic(false)
            this.score.goToFrame(framesElapsed)

            if (this.data.activeFloats) {
                this.data.activeFloats.forEach((float) => {
                    this.generateFloat(float)
                })
            }
        }
    }

    muteRoomMusic(fade = true): void {
        for (let i = 0; i < Client.shared.soundScore.channels.length; i++) {
            if (i === 4 - 1) {
                continue
            }
            const channel = Client.shared.soundScore.channels[i] as SoundChannel
            console.log('>> muting channel', channel)

            if (fade) {
                channel.fadeVolume(1, 0, 1000)
                channel.muted = true
            } else {
                channel.mute()
            }
        }
    }

    async stepReceived(payload: { step: string; data: any }): Promise<void> {
        if (payload.step === 'play') {
            console.log('[SHOW] Starting show!')

            this.muteRoomMusic()

            this.score?.playFromBeginning()
            this.didStartScore = true
            this.data.startedAt = Date.now()
        } else if (payload.step === 'float-enter') {
            void this.generateFloat(payload.data)
        } else if (payload.step === 'show-banner') {
            const sprite = Pooler.newSprite('showmedia-' + payload.data.mediaId)
            if (sprite) {
                this.clearBanner()
                sprite.alpha = 0
                this.banner = sprite
                sprite.anchor.set(0.5, 0.5)
                sprite.position.set(payload.data.x, payload.data.y)
                if (payload.data.clickToRemove) {
                    sprite.eventMode = 'static'
                    sprite.cursor = 'pointer'
                    sprite.once('click', () => {
                        this.clearBanner()
                    })
                } else {
                    sprite.eventMode = 'none'
                }
                Client.shared.stage.addChild(sprite)
            }
        } else if (payload.step === 'hide-banner') {
            this.clearBanner()
        } else if (payload.step === 'ended') {
            console.log('[SHOW] Show has ended.')

            Client.shared.soundScore.channels.forEach((c: SoundChannel) => {
                if (c.muted) {
                    c.muted = false
                    c.fadeVolume(0, 1, 2000)
                }
            })
            this.teardown()
        }
    }

    clearBanner(): void {
        if (this.banner) {
            const clearBanner = this.banner
            this.banner = undefined
            clearBanner.eventMode = 'none'
            gsap.to(clearBanner, {
                alpha: 0,
                onComplete: () => {
                    clearBanner.destroy()
                }
            })
        }
    }

    async generateFloat(payload: FloatInfo): Promise<void> {
        if (!this.didStartScore) {
            console.log('[SHOW] Not generating float, not started yet.')
            return
        }
        let elapsedMs = payload.addedAt ? Date.now() - payload.addedAt : 0
        if (elapsedMs < 1000) {
            elapsedMs = 0
        }
        const save = Assets.get(`showmedia-${payload.mediaId}`) as {
            items: {
                itemDefUid: string
                mapX: number
                mapY: number
                rotation: EAvatarDir
                state: string | number | undefined
                stack: number | undefined
                height: number | undefined
                data: any
            }[]
        }

        if (!save) {
            console.log('[SHOW] Missing float save for media ' + payload.mediaId)
            return
        }

        console.log('[SHOW] Float is being added!')

        let minX = Number.POSITIVE_INFINITY
        let minY = Number.POSITIVE_INFINITY

        let sizeX = 0
        let sizeY = 0
        for (const item of save.items) {
            const furnimap = Client.shared.furniMapMgr.getFurnimapByItemDefUid(item.itemDefUid)
            if (!furnimap) {
                console.log('[SHOW] FLOAT - Missing furnimap for item with uid ' + item.itemDefUid)
                continue
            }

            if (item.mapX < minX) {
                minX = item.mapX
            }
            if (item.mapY < minY) {
                minY = item.mapY
            }

            const blocks = furnimap.getDirection(item.rotation)

            for (const block of blocks.blocks) {
                sizeX = Math.max(sizeX, item.mapX + block.x + 1)
                sizeY = Math.max(sizeY, item.mapY + block.y + 1)
            }
        }

        sizeX -= minX
        sizeY -= minY

        let floorXOffset = payload.axis === 'x' ? -sizeX : 0
        let floorYOffset = payload.axis === 'y' ? -sizeY : 0
        const alongFloor = this.room.floors.find((f) => f.name === payload.floor) ?? this.room.floors[0]
        console.log('[SHOW] Float is ' + sizeX + 'x' + sizeY + ' moving along floor ' + alongFloor.name)

        if (payload.coord) {
            if (payload.axis === 'x') {
                //moves along x, center on Y
                // center float on given coordinate
                floorYOffset += payload.coord - sizeY / 2

                // move to first visible tile on axis
                let firstTileOnAxis: TileEntity
                for (const tile of alongFloor.getTiles().filter((t) => t.getMapY() === payload.coord)) {
                    if (!firstTileOnAxis || tile.getMapX() < firstTileOnAxis.getMapX()) {
                        firstTileOnAxis = tile
                    }
                }
                if (firstTileOnAxis) {
                    floorXOffset += firstTileOnAxis.getMapX()
                }
            } else if (payload.axis === 'y') {
                floorXOffset += payload.coord - sizeX / 2

                let firstTileOnAxis: TileEntity
                for (const tile of alongFloor.getTiles().filter((t) => t.getMapX() === payload.coord)) {
                    if (!firstTileOnAxis || tile.getMapY() < firstTileOnAxis.getMapY()) {
                        firstTileOnAxis = tile
                    }
                }
                if (firstTileOnAxis) {
                    floorXOffset += firstTileOnAxis.getMapY()
                }
            }
        }

        const floor = new Floor(
            'float',
            alongFloor.point.plus(floorXOffset * this.room.getTilesize(), floorYOffset * this.room.getTilesize(), 0)
        )
        for (let x = 0; x < sizeX; x++) {
            for (let y = 0; y < sizeY; y++) {
                const tileCoord = new Point3D(x * this.room.getTilesize(), y * this.room.getTilesize(), 0)
                const tileEntity = new TileEntity(
                    this.room,
                    this.room.getTilesize(),
                    RoomTileType.TILE,
                    null,
                    this.refGen++,
                    null,
                    null,
                    tileCoord,
                    floor
                )
                tileEntity.point = tileCoord
                tileEntity.setMapX(x)
                tileEntity.setMapY(y)
                tileEntity.updatePosition()
                floor.addTile(tileEntity)
                this.room.tiles.set(tileEntity.ref, tileEntity)
                this.room.sprites.addChild(tileEntity)
            }
        }

        this.floors.push(floor)

        const furni: FurniEntity[] = []
        for (const item of save.items) {
            try {
                const furniEntity = await this.room.furniController.addItem(
                    new RoomItem({
                        itemId: this.idGen++,
                        ref: floor.getTile(item.mapX - minX, item.mapY - minY)?.ref,
                        defUid: item.itemDefUid,
                        hgt: item.height,
                        index: item.stack,
                        rotation: item.rotation,
                        state: item.state,
                        customData: item.data,
                        defType: EItemType.Furniture
                    })
                )
                if (furniEntity) {
                    furni.push(furniEntity)
                }
            } catch (e) {
                console.error(e)
            }
        }

        const ticksPerSecond = payload.speed * this.speedMultiplier
        const ticksPerMS = ticksPerSecond / 1000

        const tickDiff = payload.direction === 'inc' ? 1 : -1
        let paused = false
        const floatTick = () => {
            if (paused) {
                return
            }
            if (floor.point.screenY > Constants.SIZE[0] || floor.point.screenX > Constants.SIZE[1]) {
                ticker.destroy()
                console.log('>> FLOAT WENT OFF SCREEN')

                furni.forEach((f) => this.room.furniController.removeItem(f))
                this.room.sprites.removeChild(...floor.getTiles())
                floor.destroy()
                const floorIndex = this.floors.indexOf(floor)
                if (floorIndex !== -1) {
                    this.floors.splice(floorIndex, 1)
                }
                const tickerIndex = this.tickers.indexOf(ticker)
                if (tickerIndex !== -1) {
                    this.tickers.splice(tickerIndex, 1)
                }
                return
            }
            const floatMapPos = floor.point
                .minus(alongFloor.point.get3DX(), alongFloor.point.get3DY(), alongFloor.point.get3DZ())
                .toMap(this.room.getTilesize())
            console.log(
                '[SHOW] float is at ' +
                    floor.point.get3DX() +
                    ', ' +
                    floor.point.get3DY() +
                    ' map: ' +
                    floatMapPos[0] +
                    ', ' +
                    floatMapPos[1]
            )

            if (payload.axis === 'x') {
                floor.setOrigin(floor.point.get3DX() + tickDiff, floor.point.get3DY(), floor.point.get3DZ())
                if (payload.pauseAt <= floatMapPos[0]) {
                    console.log('[SHOW] FLOAT - Paused @ ' + payload.pauseAt)
                    paused = true
                    payload.pauseAt = undefined
                    setTimeout(() => (paused = false), Math.max(0, payload.pauseMs - elapsedMs) / this.speedMultiplier)
                }
            } else {
                floor.setOrigin(floor.point.get3DX(), floor.point.get3DY() + tickDiff, floor.point.get3DZ())
                if (payload.pauseAt <= floatMapPos[1]) {
                    console.log('[SHOW] FLOAT - Paused!')
                    paused = true
                    payload.pauseAt = undefined
                    setTimeout(() => (paused = false), Math.max(0, payload.pauseMs - elapsedMs) / this.speedMultiplier)
                }
            }
            this.room.sprites.sortChildren()
        }

        if (elapsedMs) {
            const missedTicks = Math.round(ticksPerMS * elapsedMs)
            console.log('>> missedTicks ' + payload.mediaId + ' ' + missedTicks)
            for (let i = 0; i < missedTicks; i++) {
                floatTick()
            }
        }

        const ticker = new Ticker()
        ticker.minFPS = ticksPerSecond
        ticker.maxFPS = ticksPerSecond
        ticker.add(floatTick)
        ticker.start()

        this.tickers.push(ticker)

        console.log('[SHOW] FLOAT - Launched!')
    }

    async preloadFurnis(furniItemDefUids: ItemDefUid[], retry = true): Promise<void> {
        const retryFurni: ItemDefUid[] = []
        for (const furniDefId of furniItemDefUids) {
            const modelUid = Client.shared.furniMapMgr.getLinkedModelUid(furniDefId)
            if (!modelUid) {
                continue
            }
            try {
                const provided = await this.room.provider.get('hof_furni/furni_' + modelUid)
                if (provided.files._furnimap) {
                    Client.shared.furniMapMgr.addMap(modelUid, provided.files._furnimap)
                }
            } catch (e) {
                console.log(e)
                if (retry) {
                    retryFurni.push(furniDefId)
                }
            }
        }
        if (retry && retryFurni.length) {
            await Helpers.delay(2000)
            await this.preloadFurnis(retryFurni, false)
        }
    }

    teardown(): void {
        console.log('[SHOW] Tearing down show!')
        if (this.room.serverShow === this) {
            this.room.serverShow = undefined
        }
        this.mainTicker?.destroy()
        this.mainTicker = undefined
        this.score?.teardown()
        this.score = undefined
        this.tickers.forEach((t) => t.destroy())
        this.tickers = []
        this.floors.forEach((f) => f.destroy())
        this.floors = []
        console.log('[SHOW] Unloading assets: ', this.assetAliasesToClear.join(', '))
        Assets.unload(this.assetAliasesToClear).then(() => {
            console.log('[SHOW] Unloaded assets')
            this.assetAliasesToClear = []
        })
    }
}
