import { AdjustmentFilter } from '@pixi/filter-adjustment'
import { BlurFilter } from '@pixi/filter-blur'
import { DotFilter } from '@pixi/filter-dot'
import { OldFilmFilter } from '@pixi/filter-old-film'
import { EAvatarDir, type IRoomLayout, type IRoomLayoutElem, type IRoomLayoutElemSprite } from '@vmk-legacy/common-ts'
import type { ICastProvides, LegacyFilmLoop } from '@vmk-legacy/render-utils'
import { AssetProvider, hybridRoomAniToScoreDef, Pooler, roomSoundsToScore } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { Container, DisplayObject, FederatedEvent, RenderTexture } from 'pixi.js'
import { BaseRenderTexture, BLEND_MODES, Graphics, Point, Sprite, Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import ClientHelpers, { _inkToBlend } from '../../ClientHelpers.js'
import { Constants } from '../../Constants.js'
import { EWindow } from '../../enums.js'
import type { IExtensionData } from '../../server/messages/BeginRoomLoad'
import { RoomJoinModule } from '../../server/messages/RoomJoinModule.js'
import { AvatarTooltip } from '../../ui/AvatarTooltip.js'
import type { SizeInfo } from '../../ui/views/AlertView.js'
import { ResponsiveContainer } from '../../ui/views/AlertView.js'
import { DevSpriteMover } from '../../ui/windows/mod/DevSpriteMover.js'
import type { ShopWindow } from '../../ui/windows/shop/ShopWindow.js'
import { Helpers } from '../../util/Helpers'
import { Animation } from '../Animation.js'
import { ChatView } from '../ChatView.js'
import { AvatarEntity } from '../entities/AvatarEntity.js'
import type { WalkableEntity } from '../entities/WalkableEntity.js'
import type { ERoomObjType, RoomEntity } from '../RoomObject.js'
import { Point3D } from './3DUtils.js'
import type { WalkableRoomExtension } from './extensions/WalkableRoomExtension.js'
import { serverExtensions, getBuiltInSpaceExtensions } from './RoomExtensions.js'
import { RoomVisualSpriteTex } from './types/RoomVisualSpriteTex.js'

export abstract class RoomViewer extends ResponsiveContainer {
    protected tileSize: number

    protected disableExtensions = false
    protected disableSound = false
    protected disableProgress = false
    protected disableMickeys = false

    layoutIdentifier = '_unset'

    visualSprites = new Map<string, Texture | RenderTexture>()
    sprites: Container
    hasBeenRevealed = false
    extensions: WalkableRoomExtension[] = []
    animPlaceholders = new Map<string, Animation>()

    animationMap = new Map<string, Animation>()

    devMode = false
    spriteMover?: DevSpriteMover

    spritePoints: { [name: string]: Point3D } = {}

    roomSprites = new Set<DisplayObject>()
    loadedSounds = new Map<string, AudioBuffer>()

    filmLoops: { [name: string]: LegacyFilmLoop } = {}
    tooltipLayer: Container

    avatarTooltip?: AvatarTooltip
    avatarArrow?: Sprite
    showingTooltipFor?: WalkableEntity<any>
    showingArrowFor?: WalkableEntity<any>

    chatView: ChatView

    // non-static things within the room - avatars, furniture, etc
    protected entities: RoomEntity<any>[] = []

    static readonly roomWidth = 800
    static readonly roomHeight = 600
    static readonly roomClippedHeight = RoomViewer.roomHeight - 28

    replaceSoundCast?: string

    provider = new AssetProvider(Client.shared.assetLoader)
    private extraData?: {
        filters?: { name: string; opts: any }[]
        flip?: boolean
        flipdelay?: boolean
    }

    abstract getCastNames(): string[]

    assets: ICastProvides

    constructor() {
        super()

        this.eventMode = 'static'
        this.interactiveChildren = true

        this.zIndex = 0
        this.pivot.set(RoomViewer.roomWidth / 2, RoomViewer.roomClippedHeight / 2)

        this.sprites = Pooler.newContainer()

        const mask = new Graphics()
        mask.beginFill(0x000000)
        if (Client.shared.responsiveMode) {
            mask.drawRoundedRect(0, 0, RoomViewer.roomWidth, RoomViewer.roomClippedHeight, 7)
        } else {
            mask.drawRect(0, 0, RoomViewer.roomWidth, RoomViewer.roomHeight)
        }
        mask.endFill()
        this.addChild(mask)

        this.sprites.mask = mask
        this.sprites.name = 'room sprites'
        this.sprites.zIndex = 0
        this.sprites.pivot.set(-RoomViewer.roomWidth / 2, -RoomViewer.roomHeight / 2)
        this.sprites.eventMode = 'static'
        this.sprites.interactiveChildren = true
        this.addChild(this.sprites)

        this.tooltipLayer = Pooler.newContainer()
        this.tooltipLayer.name = 'tooltips'
        this.tooltipLayer.zIndex = 1
        this.tooltipLayer.eventMode = 'auto'
        this.tooltipLayer.interactiveChildren = false
        this.addChild(this.tooltipLayer)

        this.chatView = new ChatView(this)
        this.chatView.visible = false
        this.chatView.eventMode = 'auto'
        this.chatView.interactiveChildren = false

        this.chatView.zIndex = 2
        this.addChild(this.chatView)

        this.sortChildren()
    }

    override sizeDidChange(size: SizeInfo): void {
        size.safeAreaRespectsBars().safeAreaCenter(this, RoomViewer.roomWidth, RoomViewer.roomClippedHeight)
    }

    turnToFace(faceEntity: WalkableEntity<any>): void {
        for (const entity of this.entities) {
            // don't turn head of person that spoke
            if (entity === faceEntity) {
                continue
            }
            // can only turn heads of avatars
            if (entity instanceof AvatarEntity) {
                const faceRightwards = entity.getVisual().x < faceEntity.getVisual().x
                const faceUpwards = entity.getVisual().y < faceEntity.getVisual().y

                let headDir: EAvatarDir | undefined

                const bodyDir = entity.getVisual().getDirection()

                switch (bodyDir) {
                    case EAvatarDir.North:
                        if (faceRightwards) {
                            headDir = EAvatarDir.NorthEast
                        } else {
                            headDir = EAvatarDir.NorthWest
                        }

                        break
                    case EAvatarDir.NorthEast:
                        if (faceRightwards && faceUpwards) {
                            headDir = EAvatarDir.North
                        } else if (!faceRightwards && !faceUpwards) {
                            headDir = EAvatarDir.East
                        }
                        break
                    case EAvatarDir.East:
                        if (!faceRightwards && faceUpwards) {
                            headDir = EAvatarDir.NorthEast
                        } else if (!faceRightwards && !faceUpwards) {
                            headDir = EAvatarDir.SouthEast
                        }
                        break
                    case EAvatarDir.SouthEast:
                        if (!faceRightwards && faceUpwards) {
                            headDir = EAvatarDir.East
                        } else if (!faceRightwards && !faceUpwards) {
                            headDir = EAvatarDir.South
                        }
                        break
                    case EAvatarDir.South:
                        if (faceRightwards) {
                            headDir = EAvatarDir.SouthEast
                        } else {
                            headDir = EAvatarDir.SouthWest
                        }
                        break
                    case EAvatarDir.SouthWest:
                        if (faceRightwards) {
                            headDir = EAvatarDir.South
                        } else {
                            headDir = EAvatarDir.West
                        }
                        break
                    case EAvatarDir.West:
                        if (faceUpwards) {
                            headDir = EAvatarDir.NorthWest
                        } else {
                            headDir = EAvatarDir.SouthWest
                        }
                        break
                    case EAvatarDir.NorthWest:
                        if (faceRightwards && faceUpwards) {
                            headDir = EAvatarDir.North
                        } else if (!faceUpwards) {
                            headDir = EAvatarDir.West
                        }
                        break
                }

                entity.getVisual().setHeadDirection(headDir)
            }
        }
    }

    getEntityByRef(id: string): RoomEntity<any> | undefined {
        return this.entities.find((o) => o.id === id)
    }

    showTooltipFor(factor: WalkableEntity<any>): void {
        if (this.showingTooltipFor === factor) {
            return
        }
        this.showingTooltipFor = factor
        if (!this.avatarTooltip || this.avatarTooltip.destroyed) {
            this.avatarTooltip = new AvatarTooltip()
            this.tooltipLayer.addChild(this.avatarTooltip)
        } else {
            this.avatarTooltip.visible = true
        }

        this.updateTooltip(factor)
    }

    hideTooltipFor(factor: WalkableEntity<any>): void {
        if (this.showingTooltipFor !== factor) {
            return
        }
        this.showingTooltipFor = null
        if (this.avatarTooltip) {
            if (this.avatarTooltip.destroyed) {
                this.avatarTooltip = null
            } else {
                this.avatarTooltip.visible = false
            }
        }
    }

    updateTooltip(factor: WalkableEntity<any>): void {
        if (this.avatarTooltip && factor === this.showingTooltipFor) {
            const point = factor.getTooltipPosition()

            this.avatarTooltip.position.set(point.x, point.y)
            this.avatarTooltip.setText(factor.getName(), factor.getVisual().getChatTint())
        }
    }

    // arrows
    showArrowFor(factor: WalkableEntity<any>): void {
        if (this.showingArrowFor === factor) {
            return
        }
        this.showingArrowFor = factor
        if (!this.avatarArrow || this.avatarArrow.destroyed) {
            this.avatarArrow = Pooler.newSprite('avatar.arrow.24')
            this.avatarArrow.anchor.set(0.5, 0)
            this.tooltipLayer.addChild(this.avatarArrow)
        } else {
            this.avatarArrow.visible = true
        }

        this.updateArrow(factor)
    }

    hideArrow(forPerson?: WalkableEntity<any>): void {
        if (forPerson && this.showingArrowFor !== forPerson) {
            return
        }
        this.showingArrowFor = null
        if (this.avatarArrow) {
            if (this.avatarArrow.destroyed) {
                this.avatarArrow = null
            } else {
                this.avatarArrow.visible = false
            }
        }
    }

    updateArrow(factor: WalkableEntity<any>): void {
        if (this.avatarArrow && factor === this.showingArrowFor) {
            const point = factor.getTooltipPosition()
            //5, -25
            this.avatarArrow.position.set(point.x, point.y - 20)
        }
    }

    getEntitiesByType(type: ERoomObjType): RoomEntity<any>[] {
        return this.entities.filter((o) => o.entityType === type)
    }

    sendDebugData(): void {
        Client.shared.roomDebugWin?.postMessage(
            {
                type: 'room-info',
                room: this.getDebugData()
            },
            '*'
        )
    }

    getDebugData(): any {
        return {
            'Model base name': this.layoutIdentifier,
            'Tile size': this.tileSize
        }
    }

    setDevMode(mode: boolean): void {
        if (this.devMode === mode) {
            return
        }
        this.devMode = mode

        if (!mode) {
            this.spriteMover?.destroy()
            this.spriteMover = undefined
        } else {
            this.spriteMover = Client.shared.userInterface.register(new DevSpriteMover())

            this.roomSprites.forEach((spr) => {
                spr.eventMode = 'static'
                spr.cursor = 'pointer'
                spr.addEventListener('pointertap', this.setDevSprite)
            })
        }
    }

    setDevSprite = (e: FederatedEvent): void => {
        this.spriteMover?.setSprite(e.currentTarget.name)
    }

    async reveal(): Promise<void> {
        if (this.hasBeenRevealed) {
            console.log('Not revealing room, already visible')
            return
        }
        if (!this.disableProgress) {
            RoomJoinModule.loader?.setProgress(98)
        }

        Client.shared.ticker.remove(this.logicTick, this as any)
        Client.shared.slowTicker.remove(this.animTick, this as any)

        Client.shared.ticker.add(this.logicTick, this as any)
        Client.shared.slowTicker.add(this.animTick, this as any)

        if (!this.disableProgress) {
            RoomJoinModule.loader?.setProgress(99)
        }

        this.removeChild(this.chatView)
        this.addChild(this.chatView)

        this.chatView.startAutoShifting()
        await this.startAnimating()

        if (!this.disableProgress) {
            RoomJoinModule.loader?.setProgress(100)
        }

        this.visible = true
        this.chatView.visible = true

        for (const ext of this.extensions) {
            await ext.roomWillReveal()
        }

        if (this.extraData?.flipdelay) {
            gsap.to(this, {
                angle: 180,
                duration: 3,
                delay: 1,
                ease: 'expo.inOut'
            })
        }
        this.hasBeenRevealed = true
    }

    logicTick(dt: number): void {
        for (const object of this.entities) {
            object.update?.(dt)
        }
    }

    animTick(dt: number): void {
        for (const object of this.entities) {
            object.visual?.update(dt)
        }
    }

    getTilesize(): number {
        return this.tileSize
    }

    async loadAssets(hideSprites = false, retrying = false): Promise<void> {
        console.log('>> loadAssets')
        let castNames = this.getCastNames()

        if (this.replaceSoundCast) {
            castNames = castNames.filter((n) => n.indexOf('snd') === -1)
            if (this.replaceSoundCast !== 'off') {
                castNames.push(this.replaceSoundCast)
            }
        }

        const onProgress = this.disableProgress
            ? undefined
            : (percent: number) => RoomJoinModule.loader?.setProgress(5 + 70 * percent)

        try {
            const provided = await this.provider.get(castNames, onProgress)
            if (!provided) {
                throw new Error('Unable to load room assets.')
            }

            this.assets = provided

            this.filmLoops = provided.filmLoops
            this.loadedSounds = provided.sounds

            await this.#parseRoomLayout(hideSprites)
        } catch (error) {
            if (!retrying) {
                console.log('>> Retrying in 3 seconds...')
                // try again after 3 seconds in case of temporary network issue
                await Helpers.delay(3000)

                await this.loadAssets(hideSprites, true)
                return
            }

            throw error
        }
    }

    async #parseRoomLayout(hideSprites = false): Promise<void> {
        console.log('Parsing room layout ' + this.layoutIdentifier)

        const roomCastName = `room.${this.layoutIdentifier}.object`
        const visualCastName = `${this.layoutIdentifier}.visual`

        const roomModel = this.assets.files[roomCastName] as IRoomLayout
        const visual = this.assets.files[visualCastName]

        if (!roomModel) {
            throw new Error(`Missing room cast "${roomCastName}"`)
        }

        if (!visual) {
            throw new Error(`Missing visual cast "${visualCastName}"`)
        }

        const sprites = visual['SCORE']['SPRITE']

        if (sprites) {
            // only purpose of this is to preload sprite textures and apply ink->blendMode and blend->alpha values
            for (const layoutSpr of sprites) {
                const s = layoutSpr.$

                if (s.TYPE === 'filmLoop') {
                    continue
                }
                if (s.MEMBER.length === 2 && s.MEMBER[0] === 't') {
                    if (this.disableMickeys) {
                        continue
                    }
                    await Client.shared.assetLoader.loadCasts(['questengine/quest_hidden_mickey'], {
                        retains: this.provider
                    })
                }

                let sprite: Sprite
                try {
                    sprite = Pooler.newSprite(this.assets.textures[s.MEMBER] || s.MEMBER, 'vis_' + s['ID'])
                } catch (error) {
                    console.warn('Room visual: skipping `' + s.MEMBER + '` absent from texture cache')
                    continue
                }

                if (s.INK) {
                    sprite.blendMode = _inkToBlend(s.INK)
                } else {
                    sprite.blendMode = BLEND_MODES.NORMAL
                }

                sprite.width = +s['W']
                sprite.height = +s['H']

                let texture: Texture | RoomVisualSpriteTex

                if (sprite.width === 0 || sprite.height === 0) {
                    texture = Texture.EMPTY
                } else {
                    const anchor = sprite.isSprite ? sprite.texture.defaultAnchor.copyTo(new Point()) : null
                    const blendMode = sprite.isSprite ? sprite.blendMode : null

                    sprite.anchor.set(0)

                    const resolution =
                        sprite.texture.width === 1 || sprite.texture.height === 1 ? 1 : sprite.texture.height / +s['H']

                    texture = new RoomVisualSpriteTex(
                        new BaseRenderTexture({
                            width: +s['W'],
                            height: +s['H'],
                            resolution: resolution
                        })
                    )

                    texture.textureCacheIds = [s.MEMBER]

                    Client.shared.renderer.render(sprite, {
                        renderTexture: texture as RenderTexture,
                        clear: false,
                        skipUpdateTransform: false
                    })

                    Pooler.release(sprite)

                    if (anchor) {
                        texture.defaultAnchor = anchor
                    }
                    if (blendMode !== null) {
                        texture['blendToApply'] = blendMode
                    }
                    if ('BLEND' in s) {
                        texture['alphaToApply'] = s.BLEND / 100
                    }
                }
                this.visualSprites.set(s.ID, texture)
            }
        }
        if (!this.disableProgress) {
            RoomJoinModule.loader?.setProgress(75)
        }

        await this.enumerateScene(roomModel, hideSprites)

        if (!this.disableProgress) {
            RoomJoinModule.loader?.setProgress(80)
        }

        this.sprites.sortChildren()

        if (!this.disableSound) {
            try {
                console.log('Setting up sound score')
                this.setupSoundScore()
                console.log('Sound score set up')
            } catch (error) {
                console.warn(error)
            }
        }
        if (!this.disableProgress) {
            RoomJoinModule.loader?.setProgress(85)
        }
    }

    async enumerateScene(roomModel: IRoomLayout, hideSprites = false): Promise<void> {
        console.log('Build grid for ' + this.layoutIdentifier)

        this.spritePoints = {}

        if (!roomModel) {
            throw new Error('Missing room model layout.')
        }

        this.tileSize = roomModel.tileSize

        if (roomModel.elems) {
            for (const node of roomModel.elems as IRoomLayoutElemSprite[]) {
                if (node.type === 'default') {
                    if (node.name === 'jchazards' || node.name === 'jcpowerups' || node.name === 'jcanimals') {
                        continue
                    }
                    if (node.elems?.length) {
                        const offset = new Point3D(node.x, node.y, node.z)

                        for (const childNode of node.elems as IRoomLayoutElemSprite[]) {
                            if (childNode.type === 'default') {
                                const spr = await this.setSpriteNode(
                                    childNode.sprite,
                                    childNode.name,
                                    new Point3D(childNode.x, childNode.y, childNode.z),
                                    0,
                                    hideSprites
                                )
                                if (spr) {
                                    offset.addTo(spr)
                                }
                            }
                        }
                    }
                    await this.setSpriteNode(
                        node.sprite,
                        node.name,
                        new Point3D(node.x, node.y, node.z),
                        0,
                        hideSprites
                    )
                } else {
                    await this.handleModelNode(node)
                }
            }
        }

        this.sprites.sortChildren()
    }

    async handleModelNode(node: IRoomLayoutElem): Promise<void> {
        // handle special nodes, see implementations in subclasses
    }

    // Sets up the room's built-in extensions, and the ones specified by the server.
    // If the server specifies an extension that is "built-in", it only gets loaded once
    // but will still be passed the data from the server.
    async setupExtensions(serverExtData: IExtensionData[] = []): Promise<void> {
        if (this.disableExtensions) {
            return
        }
        if (this.extensions.length) {
            console.error('There are already extensions setup on this room!', this.extensions, serverExtData)
            return
        }

        console.log('Setting up extensions. From server: ', serverExtData)

        const extensions: Set<{
            new (...args: any): WalkableRoomExtension
            identifier: string
        }> = new Set(
            [
                ...getBuiltInSpaceExtensions(this.layoutIdentifier),
                ...serverExtData.map((extData) => serverExtensions.find((e) => e.identifier === extData.id))
            ].filter((e) => e !== undefined)
        )

        for (const extClass of extensions) {
            try {
                console.log('Initializing room extension ' + extClass.name)
                const ext = new extClass(this)
                this.extensions.push(ext)
                await ext.init()

                const initialData = serverExtData.find((e) => e.id === extClass.identifier)
                ext.receiveInitialData(initialData)
            } catch (error) {
                console.error('Error initializing extension ' + extClass.name, error)
            }
        }
    }

    async receiveExtraData(extraData: {
        filters?: { name: string; opts: any }[]
        flip?: boolean
        flipdelay?: boolean
    }): Promise<void> {
        this.extraData = extraData
        this.sprites.filters = []

        if (extraData?.filters && Array.isArray(extraData.filters)) {
            for (const f of extraData.filters) {
                if (!f.name || !f.opts) {
                    continue
                }
                try {
                    this.addFilter(f.name, f.opts)
                } catch (e) {
                    console.log('Error adding filter ' + f.name, f.opts, e)
                }
            }
        }
        if (extraData?.flip || extraData.flipdelay) {
            if (this.hasBeenRevealed) {
                gsap.to(this, {
                    angle: 180,
                    duration: 3,
                    ease: 'expo.inOut'
                })
            } else if (!extraData.flipdelay) {
                this.angle = 180
            }
        } else if (this.angle) {
            if (this.hasBeenRevealed) {
                gsap.to(this, {
                    angle: 0,
                    duration: 3,
                    ease: 'expo.inOut'
                })
            } else {
                this.angle = 0
            }
        }
    }

    resetForReuse(): void {
        console.log('resetting for reuse')
        for (const o of this.entities) {
            o.visual.destroy()
        }
        this.entities = []
        Client.shared.slowTicker.remove(this.animTick, this as any)

        this.visible = false
        this.chatView.visible = false
        this.chatView.pause()

        this.showingTooltipFor = null
        this.showingArrowFor = null
        this.tooltipLayer.removeChildren()

        this.animationMap.forEach((anim) => {
            anim.score.reset()
            Client.shared.detachScore(anim.score)
        })
        this.parent?.removeChild(this)

        this.hasBeenRevealed = false
    }

    async teardown(): Promise<void> {
        if (this.layoutIdentifier === 'torndown') {
            console.warn('not re-tearing down already torn down roomviewer')
            return
        }
        this.visible = false
        this.hasBeenRevealed = false
        this.layoutIdentifier = 'torndown'

        this.animationMap?.forEach((anim) => {
            for (const chan of anim.score.channels) {
                chan.clear()
            }
            for (const c of anim.removeChildren()) {
                Pooler.release(c)
            }
            anim.score.teardown()
        })
        this.animationMap?.clear()

        if (this.show) {
            this.show.teardown()
            this.show = null
        }

        for (const ext of this.extensions) {
            ext.teardown()
        }
        this.extensions.length = 0

        this.sprites.filters = []

        this.chatView.teardown()
        this.showingTooltipFor = null
        this.showingArrowFor = null
        this.tooltipLayer.removeChildren()

        Client.shared.slowTicker.remove(this.animTick, this as any)

        for (const s of this.roomSprites) {
            Pooler.release(s)
        }
        this.roomSprites.clear()

        await this.provider.teardown()
        this.loadedSounds?.clear()

        this.visualSprites?.forEach((tex: Texture) => {
            tex.destroy(false)
        })
        this.visualSprites?.clear()
        this.filmLoops = {}
        Client.shared.soundScore.teardown()

        for (const obj of this.entities) {
            Pooler.release(obj.visual)
        }
        this.entities = []

        Pooler.release(this.sprites)

        this.sprites = null
        this.spritePoints = {}
        this.spriteMover?.destroy()

        this.hasBeenRevealed = false
    }

    protected async setSpriteNode(
        spriteName: string,
        identifier: string | undefined,
        mapCoord: Point3D,
        height: number,
        hide = false
    ): Promise<DisplayObject | undefined> {
        // some og client xml files have blank nodes, ignore
        if (!spriteName && !identifier) {
            return undefined
        }

        let sprite: DisplayObject

        // the NAME (identifier) may simply be there for identification, but it may also correspond to an
        // animation

        if (identifier) {
            // see if this node identifier corresponds to a defined animation
            const alias = this.assets.vars[identifier]

            if (alias) {
                const anim = await this.setupAnimation(alias)

                if (anim) {
                    this.animationMap.set(identifier, anim)

                    sprite = anim
                }
            }
        }

        if (!sprite && !spriteName) {
            console.warn(`Node identifier "${identifier}" not an alias, no sprite set either.`)
            return undefined
        }

        // if an aliased animation was not found, node is simply a static sprite
        if (!sprite) {
            let tex = this.visualSprites.get(spriteName)
            if (!tex) {
                console.log('Texture ' + spriteName + ' not from room visual, using raw asset.')
                tex = this.assets.textures[spriteName]
            }

            sprite = Pooler.newSprite(tex, identifier || spriteName)

            if (tex) {
                if (sprite instanceof Sprite) {
                    if ('blendToApply' in tex) {
                        sprite.blendMode = tex['blendToApply']
                    }
                    if ('alphaToApply' in tex) {
                        sprite.alpha = tex['alphaToApply']
                    }
                    sprite.anchor.copyFrom(tex.defaultAnchor)
                }
            } else {
                console.warn(
                    `Sprite "${spriteName}" not loaded in room visual, node identifier "${identifier}" not an alias.`
                )
            }
        }
        this.spritePoints[sprite.name] = mapCoord
        mapCoord.applyTo(sprite)

        sprite.y += height || 0
        sprite.zIndex += height || 0

        sprite.interactiveChildren = false

        const mickeySprites = ['t1', 't2', 't3', 't4', 't5', 't6', 't7', 't8', 't9', 't10']
        if (mickeySprites.includes(spriteName)) {
            const mickeyNum = +identifier.replace(/mickey/, '')
            if (Client.shared.selfRecord.getMickeys().includes(mickeyNum)) {
                sprite.visible = false
                sprite.eventMode = 'auto'
            } else {
                sprite.visible = true
                sprite.eventMode = 'static'
                sprite.alpha = Constants.HIDDEN_MICKEY_ALPHA
            }
            sprite.addEventListener('pointerup', () => {
                const newArr = Client.shared.selfRecord.getMickeys()
                newArr.push(mickeyNum)
                Client.shared.selfRecord.setMickeys(newArr)

                sprite.eventMode = 'auto'
                const duration = 0.5
                Client.shared.serverBroker.send('mickey_found', {
                    id: mickeyNum
                })
                gsap.to(sprite, {
                    duration,
                    alpha: 1,
                    onComplete() {
                        gsap.to(sprite, {
                            duration,
                            alpha: 0
                        })
                    }
                })
            })
        } else if (identifier === 'shop_cash') {
            sprite.eventMode = 'static'
            sprite.cursor = 'pointer'
            sprite.addEventListener('pointertap', async () => {
                const shop = (await Client.shared.userInterface.getWin(EWindow.Shopping, true)) as ShopWindow
                if (shop) {
                    await shop.setActiveTab(shop.specialsView.tab)
                }
            })
        } else {
            sprite.eventMode = 'none'
        }
        if (hide) {
            sprite.visible = false
        }
        this.roomSprites.add(sprite)
        this.sprites.addChild(sprite)
        this.sprites.sortChildren()

        return sprite
    }

    protected async startAnimating(playSound = true): Promise<void> {
        console.log('Now animating in ' + this.layoutIdentifier)

        playSound = playSound && !this.disableSound

        if (playSound) {
            console.log('Playing sound')
        }

        if (playSound) {
            Client.shared.soundScore.playFromBeginning(false)
        }

        this.animationMap.forEach((a) => {
            Client.shared.attachScore(a.score)
            a.score.playFromBeginning()
        })

        this.sprites.sortChildren()
    }

    addFilter(name: string, options?: any): void {
        let filter
        switch (name) {
            case 'old-film':
                filter = new OldFilmFilter(options)
                break
            case 'adjustment':
                filter = new AdjustmentFilter(options)
                break
            case 'dot':
                filter = new DotFilter(options.scale, options.angle)
                break
            case 'blur':
                filter = new BlurFilter(options.strength, options.quality)
                break
            default:
                console.log('Unknown room filter: ' + name)
                return
        }

        if (filter) {
            this.sprites.filters.push(filter)
        }
    }

    private setupSoundScore(): void {
        // look for new format sound score
        if ('sound.score' in this.assets.files) {
            Client.shared.soundScore.loadDefinition(this.assets, this.assets.files['sound.score'])
            return
        }

        // otherwise, must be an og-style sound list & tracker
        const assetEntries = Object.entries(this.assets.files)
        const soundList = (assetEntries.find(([name]) => name.toLowerCase() === 'sound') ??
            assetEntries.find(([name]) => name.toLowerCase().endsWith('_sound')))?.[1]
        const soundTracker = (assetEntries.find(([name]) => name.toLowerCase() === 'tracker') ??
            assetEntries.find(([name]) => name.toLowerCase().endsWith('_tracker')))?.[1]

        if (!soundList || !soundTracker) {
            throw new Error('No new-format score or old Sound + Tracker definition member not found for room.')
        }

        Client.shared.soundScore.loadDefinition(this.assets, roomSoundsToScore(this.assets, soundList, soundTracker))
    }

    async setupAnimation(animName: string, genHitmaps = false): Promise<Animation | undefined> {
        animName = animName.toString().trim()
        const animMember = this.assets.files[animName]
        if (!animMember) {
            return undefined
        }

        const anim = new Animation(this, hybridRoomAniToScoreDef(animName, animMember))

        if (genHitmaps) {
            anim.children.forEach((c) => c instanceof Sprite && ClientHelpers.genHitmap(c.texture, 125))
        }

        return anim
    }

    didBecomeHidden(): void {
        //
    }

    addEntity(object: RoomEntity<any>): RoomEntity<any> {
        this.entities.push(object)

        console.log('Created new object', object)

        return object
    }

    removeEntity(entity: RoomEntity<any>): void {
        const objectIndex = this.entities.indexOf(entity)

        if (objectIndex !== -1) {
            this.entities.splice(objectIndex, 1)
        }
        entity.visual.destroy()
    }

    removeEntityByRef(ref: string): void {
        const objectIndex = this.entities.findIndex((o) => o.id === ref)

        if (objectIndex !== -1) {
            const [object] = this.entities.splice(objectIndex, 1)

            object.getVisual().destroy()
        }
    }
}
