import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { Constants } from '../../Constants.js'
import { UILayer } from '../../enums.js'
import { RandomUtil } from '../../util/RandomUtil.js'
import type { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import LegacyWindow from './LegacyWindow.js'

// vmk_basic_1 small alert with single green button
// vmk_basic_2 small alert with two green buttons
// vmk_basic_3 wide alert with three green buttons
// vmk_basic_4 small alert with checkbox and single green button

export class AlertWindow extends LegacyWindow {
    readonly layer = UILayer.AlwaysOnTop

    constructor(
        readonly title: TextKey,
        readonly body: TextKey,
        readonly actionButtonText?: TextKey | false,
        readonly actionButtonCallback?: () => any,
        readonly cancelButtonText?: TextKey | false,
        readonly cancelButtonCallback?: () => any,
        large = false,
        protected headerBtn = false,
        protected randomLoc = false
    ) {
        super(
            headerBtn ? 'vmk_basic' : 'vmk_modal',
            new LegacyWindow(large ? 'vmk_basic_3' : cancelButtonText ? 'vmk_basic_2' : 'vmk_basic_1', null, {
                'basic.content.text': body
            }),
            {
                'basic.window.title': title
            }
        )
    }

    async windowWasBuilt(): Promise<void> {
        const def = (this.embedding as LegacyWindow)?.winDef as
            | 'vmk_basic_1'
            | 'vmk_basic_2'
            | 'vmk_basic_3'
            | 'vmk_basic_4'
        const okButton = def === 'vmk_basic_1' ? 'basic.dialog.button1' : 'basic.dialog.button2'
        if (this.actionButtonText) {
            this.setField(okButton, this.actionButtonText)

            if (this.actionButtonCallback) {
                ;(<BitmapTextButton>this.getElement(okButton)).addEventListener('pointerup', () =>
                    this.actionButtonCallback()
                )
            }
        } else {
            this.hide(okButton)
        }

        if (def !== 'vmk_basic_1') {
            if (this.cancelButtonText) {
                this.setField('basic.dialog.button1', this.cancelButtonText)

                if (this.cancelButtonCallback) {
                    ;(<BitmapTextButton>this.getElement('basic.dialog.button1')).on('pointerup', () =>
                        this.cancelButtonCallback()
                    )
                }
            } else {
                this.hide('basic.dialog.button1')
            }
        }

        if (def === 'vmk_basic_3') {
            this.hide('basic.dialog.button3')
        }

        this.sizeDidChange()
    }

    sizeDidChange(): void {
        if (this.randomLoc) {
            this.x = RandomUtil.generateRandomNumberInRange(0, Constants.SIZE[0] - this.width)
            this.y = RandomUtil.generateRandomNumberInRange(0, Constants.SIZE[1] - this.height - 27)
        } else {
            this.x = Math.round((Constants.SIZE[0] - this.width) / 2)
            this.y = Math.round((Constants.SIZE[1] - this.height) / 2)
        }
    }
}
