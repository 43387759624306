import { Graphics, Rectangle, Sprite, Texture } from 'pixi.js'

export class JungleCruiseViewfinder extends Sprite {
    #redLight: Graphics
    #greenLight: Graphics

    constructor() {
        super(Texture.from('cam_viewfinder'))

        this.eventMode = 'auto'
        this.hitArea = new Rectangle()
        this.visible = false

        this.#redLight = new Graphics().beginFill(0xfc2631).drawCircle(0, 0, 3).endFill()
        this.#redLight.visible = false
        this.#greenLight = new Graphics().beginFill(0x3dcc33).drawCircle(0, 0, 3).endFill()

        this.addChild(this.#redLight, this.#greenLight)

        this.#redLight.position.set(164 - 184 / 2, 10 - 124 / 2)
        this.#greenLight.position.set(174 - 184 / 2, 10 - 124 / 2)
    }

    setFocused(focused: boolean): void {
        this.#redLight.visible = focused
        this.#greenLight.visible = !focused
    }
}
