import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { BitmapText, Sprite, Texture } from 'pixi.js'
import { Fonts } from '../../Fonts.js'
import { UISoundLibrary } from '../UISoundLibrary.js'
import type { ShopItem } from '../windows/shop/model/ShopItem.js'

export class FieldShop extends Sprite {
    private defaultState: Texture
    private selectedState: Texture
    private selected: boolean
    private text: string
    private items: ShopItem[] = []
    private logoId = 0

    constructor(text: string, isParent: boolean, logoId: number, items: ShopItem[]) {
        super()
        this.text = text
        this.logoId = logoId
        this.items = items

        this.selectedState = Texture.from(isParent ? 'field.c.selected' : 'field.d.pressed')
        this.defaultState = Texture.from(isParent ? 'field.c.active' : 'field.d.active')

        const fieldText = new BitmapText(text, {
            ...Fonts.Foxley_16
        })

        this.addChild(fieldText)
        fieldText.x = 5
        fieldText.y = 5

        this.texture = this.defaultState
        this.eventMode = 'static'
        this.cursor = 'pointer'
        this.cursor = 'pointer'

        this.selected = false
    }

    setLogoId(id: number): void {
        this.logoId = id
    }

    getLogoId(): number {
        return this.logoId
    }

    setItems(items: ShopItem[]): void {
        this.items = items
    }

    getItems(): ShopItem[] {
        return this.items
    }

    select(): void {
        this.texture = this.selectedState
        this.selected = true
        SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.UIClick)
    }

    deselect(): void {
        this.texture = this.defaultState
        this.selected = false
    }

    setSelected(selected: boolean): void {
        if (selected) {
            this.select()
        } else {
            this.deselect()
        }
    }

    isSelected(): boolean {
        return this.selected
    }

    getText(): string {
        return this.text
    }
}
