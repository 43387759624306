import type { EAvatarDir } from '@vmk-legacy/common-ts'
import type { Container, Sprite } from 'pixi.js'
import type { EntityRoomIdentifier } from '../enums.js'
import type { RoomViewer } from './renderer/RoomViewer.js'

export enum ERoomObjType {
    Avatar = 0,
    NPC = 1,
    Furni = 2,
    Poster = 3
}

export abstract class RoomEntity<Visual extends RoomEntityVisualizer> {
    abstract readonly entityType: ERoomObjType

    protected visual: Visual

    protected constructor(
        protected roomContext: RoomViewer,
        public id: EntityRoomIdentifier
    ) {
        this.visual = this.createVisual()

        if (this.visual && !this.isDummy()) {
            roomContext.sprites.addChild(this.visual)
        }
    }

    protected abstract createVisual(): Visual

    isDummy(): boolean {
        return false
    }

    getName(): string

    getVisual(): Visual {
        return this.visual
    }

    move?(dt: number): void

    update?(dt: number): void
}

export interface RoomEntityVisualizing {
    factor: RoomEntity

    applyServerActions(actions: any): void

    setDirection(dir: EAvatarDir): void

    update(dt: number): void

    getChatTint(): number | string | undefined
}

export type RoomEntityVisualizer = RoomEntityVisualizing & (Sprite | Container)
