import { EClothingPartSet, EItemType } from '@vmk-legacy/common-ts'
import type { AssetProvider } from '@vmk-legacy/render-utils'
import type { IDestroyOptions } from 'pixi.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { Client } from '../../../../Client.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import type { ItemStack } from '../../../../data/ItemStack.js'
import type { AvatarVisual } from '../../../../room/entities/AvatarVisual.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { CharacterWindow } from '../CharacterWindow.js'

export class CharacterClothingView extends UIWindowView<CharacterWindow> {
    padding = 10
    avatar: AvatarVisual

    private clothingOptions: PagedThumbnailBox

    private removeHatButton: BitmapTextButton
    private removeShoesButton: BitmapTextButton

    constructor(
        readonly provider: AssetProvider,
        avatar: AvatarVisual
    ) {
        super()

        this.avatar = avatar

        this.clothingOptions = new PagedThumbnailBox({
            search: true,
            rows: 4,
            cols: 7,
            items: [],
            spacing: 3,
            onItemTap: (item: ItemStack) => {
                this.clothingOptions.setSelected(item)
                const outfit = this.avatar.getOutfit()
                if (item.defType === EItemType.Clothing) {
                    if (Client.shared.figuresMgr.getClothingItemPart(item.defUid) === EClothingPartSet.Hat) {
                        outfit.hatId = item.defUid
                    }
                    if (Client.shared.figuresMgr.getClothingItemPart(item.defUid) === EClothingPartSet.Chest) {
                        outfit.shirtId = item.defUid
                    }
                    if (Client.shared.figuresMgr.getClothingItemPart(item.defUid) === EClothingPartSet.Pants) {
                        outfit.pantsId = item.defUid
                    }
                    if (Client.shared.figuresMgr.getClothingItemPart(item.defUid) === EClothingPartSet.Shoes) {
                        outfit.shoesId = item.defUid
                    }
                    // Addition for Accessories
                    /*if (Client.shared.figuresMgr.getClothingItemPart(item.defUid) === EClothingPartSet.Accessory) {
						outfit.accesoryId = item.defUid
					}*/
                    this.avatar.setUpdatedOutfit(outfit)
                }
            },
            pageTurnSpacing: 80,
            pageTurnFormat: PagedThumbnailBox.FORMAT_C
        })
        this.clothingOptions.y = 10
        this.addChild(this.clothingOptions)

        const controller = this.clothingOptions.getControllerContainer()
        controller.y = 225
        this.addChild(controller)

        this.removeHatButton = new BitmapTextButton(100, 'TAKE OFF', 'b')
        this.removeShoesButton = new BitmapTextButton(100, 'TAKE OFF', 'b')
        this.removeHatButton.x = this.removeShoesButton.x = 205
        this.removeHatButton.y = this.removeShoesButton.y = controller.y
        this.removeHatButton.addEventListener('pointerup', () => {
            const outfit = this.avatar.getOutfit()
            outfit.hatId = undefined
            this.avatar.setUpdatedOutfit(outfit)
        })
        this.removeShoesButton.addEventListener('pointerup', () => {
            const outfit = this.avatar.getOutfit()
            outfit.shoesId = undefined
            this.avatar.setUpdatedOutfit(outfit)
        })
        this.addChild(this.removeHatButton, this.removeShoesButton)
        this.removeHatButton.visible = false
        this.removeShoesButton.visible = false
    }

    updateHatOptions() {
        const hatOptions = this.getClothingItemsFromInventory(EClothingPartSet.Hat)
        this.clothingOptions.setItems(...hatOptions)
        this.removeHatButton.visible = true
        this.removeShoesButton.visible = false
    }

    updateShirtOptions() {
        const shirtOptions = this.getClothingItemsFromInventory(EClothingPartSet.Chest)
        this.clothingOptions.setItems(...shirtOptions)
        this.removeHatButton.visible = false
        this.removeShoesButton.visible = false
    }

    updatePantsOptions() {
        const pantsOptions = this.getClothingItemsFromInventory(EClothingPartSet.Pants)
        this.clothingOptions.setItems(...pantsOptions)
        this.removeHatButton.visible = false
        this.removeShoesButton.visible = false
    }

    updateShoesOptions() {
        const shoeOptions = this.getClothingItemsFromInventory(EClothingPartSet.Shoes)
        this.clothingOptions.setItems(...shoeOptions)
        this.removeHatButton.visible = false
        this.removeShoesButton.visible = true
    }

    /*updateAccessoryOptions() {
		const accessoryOptions = this.getClothingItemsFromInventory(EClothingPartSet.Accessory)
		this.clothingOptions.setItems(...accessoryOptions)
		this.removeHatButton.visible = false
		this.removeShoesButton.visible = false
	}*/

    getClothingItemsFromInventory(clothingItemPart?: EClothingPartSet): ItemStack[] {
        const items: ItemStack[] = []
        Client.shared.selfRecord.getInventory().forEach((item) => {
            if (item.defType === EItemType.Clothing) {
                if (clothingItemPart) {
                    if (Client.shared.figuresMgr.getClothingItemPart(item.defUid) === clothingItemPart) {
                        console.log(item)
                        items.push(item)
                    }
                } else {
                    items.push(item)
                }
            }
        })
        return items
    }

    override destroy(_options?: IDestroyOptions | boolean): void {
        super.destroy(_options)
    }
}
