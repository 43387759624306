import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { Sprite } from 'pixi.js'

export class FieldG extends Sprite {
    private type: string
    private totalHeight: number
    private totalWidth: number

    constructor(innerWidth: number, innerHeight: number) {
        super()
        const bg: Container = Pooler.newContainer()
        const bL = Pooler.newSprite('field.g.bottom.left')
        const bR = Pooler.newSprite('field.g.bottom.right')
        const mM = Pooler.newSprite('field.g.middle.middle')
        const mT = Pooler.newSprite('field.g.middle.topbottom')
        const mB = Pooler.newSprite('field.g.middle.topbottom')
        const tL = Pooler.newSprite('field.g.top.left')
        const tR = Pooler.newSprite('field.g.top.right')
        bg.addChild(bL, bR, mT, mB, tL, tR)
        if (innerHeight !== null) {
            mM.width = innerWidth + bL.width + bR.width
            mM.height = innerHeight
            mM.y = tL.y + tL.height
            bg.addChild(mM)
        }
        bL.y = tL.y + tL.height + innerHeight
        mB.width = mT.width = innerWidth
        mT.x = tL.x + tL.width
        mB.y = bL.y
        mB.x = bL.width
        bR.x = tR.x = bL.x + bL.width + innerWidth
        bR.y = tL.y + tL.height + innerHeight
        this.addChild(bg)
        this.totalHeight = bL.height + innerHeight + tL.height
        this.totalWidth = bL.width + innerWidth + bR.width
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
