import { Graphics, type Sprite } from 'pixi.js'
import { Client } from '../../../Client.js'
import type { AvatarEntity } from '../../entities/AvatarEntity.js'
import { AvatarVisual } from '../../entities/AvatarVisual.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class SteamboatHandler extends WalkableRoomExtension {
    static override identifier = 'steamboatPodium'

    private presenter?: AvatarVisual

    override async init(): Promise<this> {
        await super.init()

        Client.shared.serverBroker.onEvent('sb_project', (entityId?: string) => {
            this.present(entityId)
        })

        const oldMask = this.room.sprites.getChildByName('screen') as Sprite
        oldMask.visible = false

        return this
    }

    present(entityId?: string) {
        if (this.presenter) {
            ;(this.presenter.mask as Graphics).destroy()
            this.presenter.destroy()
            this.presenter = undefined
        }
        if (entityId) {
            const object = this.room.getEntityByRef(entityId) as AvatarEntity

            if (object) {
                const tempAvi = new AvatarVisual(this.room.provider, 4)
                tempAvi.setUpdatedOutfit(object.getOutfit())
                tempAvi.x = 300 - 58 + 8
                tempAvi.y = -56 + 135 + 20
                tempAvi.zIndex = -940
                tempAvi.scale.set(3)

                const aviMask = new Graphics().beginFill(0x00ff00).drawRect(0, 0, 130, 160).endFill()
                aviMask.position.set(tempAvi.x - 65, tempAvi.y - 311)
                this.room.sprites.addChild(aviMask)

                tempAvi.mask = aviMask

                const screenMask = this.room.sprites.getChildByName('screen_mask') as Sprite
                tempAvi.zIndex = screenMask.zIndex - 1
                this.room.sprites.addChild(tempAvi)
                this.room.sprites.sortChildren()

                this.presenter = tempAvi
            }
        }
    }

    override teardown() {
        super.teardown()

        if (this.presenter) {
            ;(this.presenter.mask as Graphics).destroy()
            this.presenter.destroy()
            this.presenter = undefined
        }

        Client.shared.serverBroker.offEvent('sb_project')
    }
}
