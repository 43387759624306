import type { EntityRoomIdentifier } from '../../enums.js'
import type { RoomViewer } from '../renderer/RoomViewer.js'
import { ERoomObjType } from '../RoomObject.js'
import { InvisibleNPCVisual } from './InvisibleNPCVisual.js'
import { WalkableEntity } from './WalkableEntity.js'

export class InvisibleNPCEntity extends WalkableEntity<InvisibleNPCVisual> {
    readonly entityType = ERoomObjType.NPC

    constructor(roomContext: RoomViewer, id: EntityRoomIdentifier, ign: string) {
        super(roomContext, id, ign, 0, 0, {})
    }

    protected override createVisual(): InvisibleNPCVisual {
        let tint: string | number = 182
        switch (this.id) {
            case 'npc-esmeralda': // 4005
                tint = '#B500BC'
                break
            case 'npc-ned': // 4002
                tint = '#7C4A1C'
                break
            case 'npc-yeti': // 4003
                tint = '#004FE2'
                break
            case 'npc-blackheart': // 4004
                tint = '#BC0000'
                break
            case 'npc-dragon': // 4001
                tint = '#7C4A1C'
                break
            case 'npc-gator': // 4009
                tint = '#1F412F'
                break
            // in CRoomHandler.ls, other NPCs have a random color
        }

        // other npc item IDs, these don't have colors defined
        // 4006 = Phineas the Hitchhiking Ghost
        // 4007 = Ezra the Hitchhiking Ghost
        // 4008 = Gus the Hitchhiking Ghost

        return new InvisibleNPCVisual(tint)
    }
}
