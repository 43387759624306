import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { RoomInfoBox } from '../../ui/windows/RoomInfoBox.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RoomInfoUpdate extends ServerEvent {
    static readonly type = MessageType.ROOM_UPDATE

    async handle(data: any) {
        console.log('Room info update', data)
        RoomInfoBox.currentData = data

        const infoWindow = await Client.shared.userInterface.getWin(EWindow.RoomInfoBox)
        if (infoWindow instanceof RoomInfoBox) {
            infoWindow.onRoomInfoUpdate(data)
        }

        Client.shared.roomViewer?.receiveExtraData(data.data)
    }
}
