/*
 * PotcVisualCannonball.ts
 * VMK Legacy Client
 */

import type { EPotcAmmo } from '@vmk-legacy/potc-engine'
import type { Sprite } from 'pixi.js'
import { Texture } from 'pixi.js'
import type { PotcFactorBase } from './PotcFactorBase.js'
import type { PotcScene } from './PotcScene.js'
import { PotcVisualBase } from './PotcVisualBase.js'

export class PotcVisualCannonball extends PotcVisualBase {
    factor: PotcFactorBase
    ball: Sprite

    startX: number
    startY: number

    velocityX = 0.2
    velocityY = 0.2

    angle: number

    constructor(
        readonly scene: PotcScene,
        readonly type: EPotcAmmo
    ) {
        super()

        this.texture = Texture.from('cannonball')
    }

    applyServerActions(actions: any): void {}

    setDirection(dir): void {}

    update(dt: number): void {
        this.position.set(this.x + dt * this.velocityX, this.y + dt * this.velocityY)
    }
}

/*

on update me
  tTime = the milliSeconds
  tMyObject = me.getObject()
  tLastMove = tMyObject.GetParam("LastMove")
  tDt = (tTime - tLastMove) / 1000.0
  tGameParams = tMyObject.GetParam("GameParams")
  tVelocity = intvector(tGameParams[2], tGameParams[3], 0)
  tPos = me.getObject().GetParam("WorldPosition")
  if m_rMGE.getSyncState() then
    tPos = tPos.add(tVelocity.multiply(tDt / m_rMGE.GetSubturnSpacing()))
  end if
  tloc = ProjectAxonometricPosition(point(tPos.x, tPos.y))
  m_rSprite.loc = tloc
 */
