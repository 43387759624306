/*
 * ViewBuilder.ts
 * VMK Legacy Client
 */

import { View } from './View.js'

export abstract class ViewBuilder extends View {
    content: (View | undefined)[]

    abstract override loadView(): void

    abstract override sizeToFit(proposed: {
        width?: number
        height?: number
    }): void

    abstract override layoutSubviews(): void

    foregroundColor(color: number): View {
        for (const child of this.content) {
            child?.foregroundColor?.(color)
        }

        return this
    }

    override didLayoutSubviews() {
        super.didLayoutSubviews()

        for (const child of this.content) {
            child?.didLayoutSubviews()
        }
    }
}
