/*
 * CollectablesExtension.ts
 * VMK Legacy Client
 */

import { EItemType } from '@vmk-legacy/common-ts'
import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { ServerCollectableCollected, ServerCollectableSpawned } from '@vmk-legacy/server-protos'
import { Client } from '../../../Client'
import { UISoundLibrary } from '../../../ui/UISoundLibrary'
import { RoomItem } from '../../RoomItem'
import { FurniAppearAnim } from '../FurniAppearAnim'
import { WalkableRoomExtension } from './WalkableRoomExtension'

export class CollectablesExtension extends WalkableRoomExtension {
    static override identifier = 'collectables'

    override async init(): Promise<this> {
        await super.init()

        Client.shared.serverBroker.onMessage(ServerCollectableSpawned, (data) => {
            console.log('SPAWN', data)
            this.room.furniController.addItem(
                new RoomItem({
                    itemId: data.id,
                    ref: data.tileRef,
                    defUid: data.furniDefUid,
                    index: 0,
                    rotation: data.direction,
                    defType: EItemType.Furniture
                }),
                false,
                FurniAppearAnim.scaleBounce
            )
            SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.NautilusPickupShort5)
            if (data.state === 1) {
                SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.NautilusPickupLong2) // yes, state 1 plays sound 2
            } else if (data.state === 2) {
                SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.NautilusPickupLong1)
            }
        })

        Client.shared.serverBroker.onMessage(ServerCollectableCollected, (data) => {
            this.room.furniController.removeItemById(data.id)

            SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.NautilusPickupShort1)
        })

        return this
    }

    override teardown(): void {
        super.teardown()

        Client.shared.serverBroker.off(ServerCollectableSpawned, ServerCollectableCollected)
    }
}
