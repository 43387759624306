import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { Container, Sprite } from 'pixi.js'
import { Client } from '../../../Client.js'
import TextScroller from '../../TextScroller.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

const moveLocX = (moveSpots) => {
    const angle = (26.3 * Math.PI) / 180

    return moveSpots * Math.cos(angle) - 3
}

const moveLocY = (moveSpots) => {
    const angle = (26.3 * Math.PI) / 180

    return moveSpots * Math.sin(angle) - 27
}

export enum MonorailState {
    Travelling = 'travelling',
    EnteringStation = 'entering',
    StoppedInStation = 'stopped',
    AboutToLeave = 'allaboard',
    LeavingStation = 'leaving'
}

export class MonorailStationHandler extends WalkableRoomExtension {
    static override identifier = 'monoStation'

    protected state: MonorailState

    private door1: Sprite
    private door2: Sprite
    private door3: Sprite
    private innerMusic: AudioBuffer

    monorail: Container
    private scr: TextScroller

    override async init(): Promise<this> {
        await super.init()

        this.loadArrangements(['monorail_open', 'monorail_close'])

        Client.shared.serverBroker.onEvent('mono_update', (data: { state: MonorailState; disable: boolean }) => {
            console.log('New monorail state from server: ' + data.state)

            if (data.state === MonorailState.LeavingStation) {
                this.scr.setText('NEXT MONORAIL ARRIVES IN 30 SECONDS', false)
                this.closeDoors().then(() => this.monorailLeaveStation())
            } else if (data.state === MonorailState.EnteringStation) {
                this.monorailEnterStation().then(() => (data.disable ? null : this.openDoors()))
            } else if (data.state === MonorailState.AboutToLeave) {
                this.scr?.setText('ALL ABOARD')
                SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MRVO-allaboard'))
            } else if (data.state === MonorailState.StoppedInStation) {
                if (this.state !== MonorailState.EnteringStation) {
                    if (!data.disable) {
                        this.openDoors()
                    }
                }
            }

            this.state = data.state
        })

        this.monorail = this.room.sprites.addChild(Pooler.newContainer())

        const monoStart = this.room.sprites.getChildByName('monorail_start') as Sprite

        const monoMid = this.room.sprites.getChildByName('monorail_mid') as Sprite
        const monoEnd = this.room.sprites.getChildByName('monorail_end') as Sprite

        this.door1 = this.room.sprites.getChildByName('door_1') as Sprite
        this.door2 = this.room.sprites.getChildByName('door_2') as Sprite
        this.door3 = this.room.sprites.getChildByName('door_3') as Sprite

        this.room.sprites.removeChild(monoStart, monoMid, monoEnd)
        this.monorail.addChild(monoStart, monoMid, monoEnd)

        monoStart.anchor.set(0)
        monoStart.position.set(0)
        monoMid.anchor.set(0)
        monoMid.position.set(664, 298)
        monoEnd.anchor.set(0)
        monoEnd.position.set(1260, 598)
        monoStart.zIndex = 3
        monoMid.zIndex = 2
        monoEnd.zIndex = 1

        this.monorail.cacheAsBitmap = true

        this.monorail.position.set(moveLocX(450), moveLocY(450))
        this.monorail.zIndex = this.door1.zIndex - 1

        this.door1.visible = false
        this.door2.visible = false
        this.door3.visible = false

        this.innerMusic = this.room.loadedSounds.get('MS-train-music-FX')

        this.room.sprites.sortChildren()

        if (
            this.state === MonorailState.StoppedInStation ||
            this.state === MonorailState.AboutToLeave ||
            this.state === MonorailState.EnteringStation
        ) {
            this.door1.visible = true
            this.door2.visible = true
            this.door3.visible = true

            SoundManager.shared.play(ESndGrp.SFX, this.innerMusic, true)

            this.monorail.position.set(moveLocX(-400), moveLocY(-400))
            this.playArrangement('monorail_open')?.goToFrame(-1)
        } else if (this.state === MonorailState.LeavingStation) {
            void this.monorailLeaveStation()
        }

        await Client.shared.assetLoader.loadCasts(['space_extensions/text_scroller'], { retains: this.room.provider })

        const display = this.room.sprites.getChildByName('display') as Sprite
        display.visible = false

        this.scr = new TextScroller(13, 1, 1)
        this.scr.zIndex = display.zIndex
        this.room.sprites.addChild(this.scr)
        this.scr.position.set(display.x, display.y)

        Client.shared.slowTicker.add(this.scr.tick, this.scr as any)

        return this
    }

    override receiveInitialData(data?: any): void {
        super.receiveInitialData(data)

        this.state = data.state
    }

    async monorailEnterStation(): Promise<void> {
        this.monorail.position.set(moveLocX(400), moveLocY(400))

        this.scr.setText('TRAIN APPROACHING. STAND CLEAR OF THE DOORS.')

        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MRVO-stand-clear'))
        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-train-arrive'))

        await this.tween(
            this.monorail,
            3,
            {
                x: moveLocX(-400),
                y: moveLocY(-400)
            },
            'power1.out'
        )
    }

    async monorailLeaveStation(): Promise<void> {
        this.monorail.position.set(moveLocX(-400), moveLocY(-400))

        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-train-leave'))

        await this.tween(
            this.monorail,
            6,
            {
                x: moveLocX(-3000),
                y: moveLocY(-3000)
            },
            'power1.in'
        )
    }

    async openDoors(): Promise<void> {
        this.door1.visible = true
        this.door2.visible = true
        this.door3.visible = true

        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-doors-open'))
        SoundManager.shared.play(ESndGrp.SFX, this.innerMusic, true)

        await this.playArrangement('monorail_open')?.getFinishPromise()
    }

    async closeDoors(): Promise<void> {
        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-doors-close'))
        gsap.fromTo(
            this.innerMusic,
            { volume: 1 },
            {
                volume: 0,
                duration: 0.5
            }
        )
        await this.playArrangement('monorail_close')?.getFinishPromise()

        this.door1.visible = false
        this.door2.visible = false
        this.door3.visible = false
    }

    override teardown(): void {
        Client.shared.serverBroker.offEvent('mono_update')

        super.teardown()

        console.log('station teardown')
        console.log(this.monorail)

        SoundManager.shared.release(this.innerMusic)
        Client.shared.slowTicker.remove(this.scr.tick, this.scr)
    }
}
