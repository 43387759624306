import { EPref } from '@vmk-legacy/common-ts'
import type { IDestroyOptions } from 'pixi.js'
import { BitmapText, Graphics } from 'pixi.js'
import { Client } from '../../Client.js'
import { Fonts } from '../../Fonts.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { CheckBox } from '../buttons/CheckBox.js'
import { CheckBoxController } from '../CheckBoxController.js'
import { DropDown } from '../fields/DropDown.js'
import { Scrollbar } from '../scrollbars/Scrollbar.js'
import type { SettingsWindow } from './SettingsWindow.js'
import { UIWindowView } from './UIWindowView.js'

export class SettingsView extends UIWindowView<SettingsWindow> {
    private discordBtn: BitmapTextButton

    constructor() {
        super()
        const soundSettingsHeader = new BitmapText('SOUND SETTINGS', {
            fontName: 'Folio',
            fontSize: 12
        })
        soundSettingsHeader.x = Math.round((265 - soundSettingsHeader.textWidth) / 2)
        this.addChild(soundSettingsHeader)

        const scrollbar: Scrollbar = new Scrollbar((percent: number) => {
            Client.shared.prefs.set(EPref.GlobalVol, percent.toString())
        })
        this.addChild(scrollbar)
        scrollbar.x = 45
        scrollbar.y = 37

        const volumePercent = +Client.shared.prefs.get(EPref.GlobalVol)
        if (!isNaN(volumePercent)) {
            scrollbar.setPercentScrolled(volumePercent)
        }

        const loud: BitmapText = new BitmapText('LOUD', {
            fontName: 'Folio',
            fontSize: 12
        })
        loud.x = Math.round((scrollbar.width + scrollbar.x - loud.textWidth) / 2) + scrollbar.x - 22
        loud.y = 18
        this.addChild(loud)
        const silent: BitmapText = new BitmapText('SILENT', {
            fontName: 'Folio',
            fontSize: 12
        })
        silent.x = Math.round((scrollbar.width + scrollbar.x - silent.textWidth) / 2) + scrollbar.x - 22
        silent.y = 183

        this.addChild(silent)

        const cbOffsetX = 120
        const cbPaddingY = 25
        const initOffsetX = 4
        const initOffsetY = 15
        const musicCB = new CheckBox('music', 'Music', true)
        musicCB.setActive(!Client.shared.prefs.get(EPref.MuteMusic))
        musicCB.y = soundSettingsHeader.y + soundSettingsHeader.textHeight + initOffsetX + initOffsetY
        const fxCB = new CheckBox('sfx', 'Sound Effects', true)
        fxCB.setActive(!Client.shared.prefs.get(EPref.MuteSFX))
        fxCB.y = musicCB.y + cbPaddingY
        const uiCB = new CheckBox('ui', 'Game Interface', true)
        uiCB.setActive(!Client.shared.prefs.get(EPref.MuteUI))
        uiCB.y = fxCB.y + cbPaddingY
        musicCB.x = fxCB.x = uiCB.x = cbOffsetX
        this.addChild(musicCB, fxCB, uiCB)
        const chk = new CheckBoxController([musicCB, fxCB, uiCB])
        chk.setChangeHandler((status) => {
            Client.shared.prefs.set(EPref.MuteMusic, !status.music)
            Client.shared.prefs.set(EPref.MuteSFX, !status.sfx)
            Client.shared.prefs.set(EPref.MuteUI, !status.ui)
            Client.shared.prefs.save()
        })

        const settingExplanation = new BitmapText('Check a box to turn sound on.\nUncheck the box to turn it off.', {
            ...Fonts.Foxley_16
        })
        settingExplanation.maxWidth = 120
        settingExplanation.x = musicCB.x
        settingExplanation.y = uiCB.y + cbPaddingY
        this.addChild(settingExplanation)

        const separator = new Graphics()
        separator.beginFill(0x346fbd)
        separator.drawRect(0, 0, 265, 1)
        separator.endFill()
        separator.y = 205
        this.addChild(separator)

        const otherSettingsHeader = new BitmapText('OTHER SETTINGS', {
            fontName: 'Folio',
            fontSize: 12
        })
        otherSettingsHeader.x = Math.round((265 - otherSettingsHeader.textWidth) / 2)
        otherSettingsHeader.y = 215
        this.addChild(otherSettingsHeader)

        const friendCB = new CheckBox('friend', 'Show friend requests', true)
        friendCB.setActive(!Client.shared.prefs.get(EPref.HideFriendReqs))
        friendCB.y = 235
        const tradeCB = new CheckBox('trade', 'Show trade requests', true)
        tradeCB.setActive(!Client.shared.prefs.get(EPref.HideTrades))
        tradeCB.y = friendCB.y + cbPaddingY
        const tooltipCB = new CheckBox('tooltips', 'Show extra tooltips', true)
        tooltipCB.setActive(Client.shared.prefs.get(EPref.ShowExtraTooltips) as boolean)
        tooltipCB.y = tradeCB.y + cbPaddingY
        friendCB.x = tradeCB.x = tooltipCB.x = 51

        this.addChild(friendCB, tradeCB, tooltipCB)
        new CheckBoxController([friendCB, tradeCB, tooltipCB])

        const pronounLabel = new BitmapText('My pronouns:', Fonts.FoxleyBold_16)
        pronounLabel.position.set(20, tooltipCB.y + cbPaddingY)

        const pronounSelector = new DropDown(
            [
                {
                    label: '(not set)',
                    value: '_unset'
                },
                {
                    label: 'they / them',
                    value: 'they'
                },
                {
                    label: 'she / her',
                    value: 'she'
                },
                {
                    label: 'he / him',
                    value: 'he'
                },
                {
                    label: 'ze / zir',
                    value: 'ze'
                },
                {
                    label: 'name',
                    value: 'name'
                },
                {
                    label: 'ask me',
                    value: 'ask'
                },
                {
                    label: 'any',
                    value: 'any'
                }
            ],
            '(not set)',
            Client.shared.prefs.get(EPref.Pronouns1)
        )
        pronounSelector.zIndex = 100
        pronounSelector.position.set(pronounLabel.x + pronounLabel.textWidth + 8, pronounLabel.y - 2)
        const pronounSelector2 = new DropDown(
            [
                {
                    label: '(not set)',
                    value: '_unset'
                },
                {
                    label: 'they / them',
                    value: 'they'
                },
                {
                    label: 'she / her',
                    value: 'she'
                },
                {
                    label: 'he / him',
                    value: 'he'
                },
                {
                    label: 'ze / zir',
                    value: 'ze'
                },
                {
                    label: 'name',
                    value: 'name'
                }
            ],
            '(not set)',
            Client.shared.prefs.get(EPref.Pronouns2)
        )

        pronounSelector2.visible =
            Client.shared.prefs.get(EPref.Pronouns1) &&
            !['_unset', 'ask', 'any'].includes(Client.shared.prefs.get(EPref.Pronouns1))
        pronounSelector2.zIndex = 100
        pronounSelector2.position.set(pronounSelector.x + 85, pronounSelector.y)

        pronounSelector.on('value-changed', (newValue) => {
            if (['_unset', 'ask', 'any'].includes(newValue)) {
                pronounSelector2.setValue('_unset', false)
                pronounSelector2.visible = false
            } else {
                pronounSelector2.visible = true
            }
            if (newValue === pronounSelector2.getValue()) {
                pronounSelector2.setValue('_unset', false)
            }
        })
        pronounSelector2.on('value-changed', (newValue) => {
            if (newValue === pronounSelector.getValue()) {
                pronounSelector2.setValue('_unset', false)
            }
        })

        this.addChild(pronounLabel, pronounSelector, pronounSelector2)

        const discordBtn = new BitmapTextButton(95, 'LOADING...', 'b')
        discordBtn.disable()
        discordBtn.x = 86
        discordBtn.y = pronounSelector.y + cbPaddingY + 10
        this.addChild(discordBtn)
        this.discordBtn = discordBtn

        const okBtn = new BitmapTextButton(95, 'OK', 'i')
        okBtn.x = 15
        okBtn.y = discordBtn.y + 35
        this.addChild(okBtn)
        okBtn.addEventListener('pointerup', () => {
            Client.shared.prefs.set(EPref.MuteMusic, !musicCB.getActive())
            Client.shared.prefs.set(EPref.MuteSFX, !fxCB.getActive())
            Client.shared.prefs.set(EPref.MuteUI, !uiCB.getActive())
            Client.shared.prefs.set(EPref.HideFriendReqs, !friendCB.getActive())
            Client.shared.prefs.set(EPref.HideTrades, !tradeCB.getActive())
            Client.shared.prefs.set(EPref.ShowExtraTooltips, tooltipCB.getActive())
            Client.shared.prefs.set(
                EPref.Pronouns1,
                pronounSelector.getValue() === '_unset' ? null : pronounSelector.getValue()
            )
            Client.shared.prefs.set(
                EPref.Pronouns2,
                pronounSelector2.getValue() === '_unset' ? null : pronounSelector2.getValue()
            )
            Client.shared.prefs.save()

            this.closeWindow()
        })

        const cancelBtn = new BitmapTextButton(95, 'CANCEL', 'i')
        cancelBtn.x = 153
        cancelBtn.y = okBtn.y
        this.addChild(cancelBtn)
        cancelBtn.addEventListener('pointerup', () => {
            this.closeWindow()
        })
        this.sortChildren()

        Client.shared.serverBroker.onEvent('discord_status', this.updateDiscordStatus)
        Client.shared.serverBroker.send('discord_status')
    }

    private updateDiscordStatus = (name: string | false) => {
        if (name === false) {
            this.discordBtn.setText('LINK DISCORD')
            this.discordBtn.enable()
            this.discordBtn.off('pointerup')
            this.discordBtn.once('pointerup', () => {
                this.discordBtn.disable()

                const intents = ['identify', 'guilds.join', 'gdm.join']

                if (location.hash.includes('bot')) {
                    intents.push('bot', 'guilds', 'applications.commands')
                }

                const params = new URLSearchParams({
                    client_id: process.env.VMKL_DISCORD_APP_ID,
                    redirect_uri: process.env.VMKL_DISCORD_REDIRECT_URI,
                    response_type: 'code',
                    prompt: 'consent',
                    scope: intents.join(' '),
                    state: Client.shared.selfRecord.getTkn()
                })

                const authWin = window.open(
                    'https://discord.com/api/oauth2/authorize?' + params.toString(),
                    'DiscordAuth',
                    'height=1024,width=800'
                )
                if (authWin && authWin.focus) {
                    authWin.focus()
                }
            })
        } else {
            this.discordBtn.setText('UNLINK DISCORD')
            this.discordBtn.enable()

            this.discordBtn.off('pointerup')
            this.discordBtn.once('pointerup', () => {
                this.discordBtn.disable()
                Client.shared.serverBroker.send('discord_unlink', () => {
                    this.discordBtn.setText('LINK DISCORD')
                })
            })
        }
    }

    closeWindow() {
        Client.shared.userInterface.removeWindow(this.parent)
    }

    override destroy(_options?: IDestroyOptions | boolean): void {
        super.destroy(_options)

        Client.shared.serverBroker.offEvent('discord_status', this.updateDiscordStatus)
    }
}
