import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RegistrationCompleteModule extends ServerEvent {
    static readonly type = MessageType.REGISTRATION_COMPLETE

    async handle(data: any) {
        Client.shared.regView?.destroy()
        Client.shared.regView = undefined

        if (!Client.shared.roomViewer) {
            Client.shared.serverBroker.requestRoom('mainstreet.esplanade')
        } else {
            Client.shared.userInterface.showToolbars()
        }
    }
}
