import { Assets, Texture } from 'pixi.js'
import Config from '../Config.js'

interface ILoadScreenManifest {
    [id: string]: {
        start: string
        end: string
        images: string[]
    }

    fallback: {
        images: string[]
    }
}

export class LoadingScreensManager {
    private screens: Texture[] = []

    async load(manifest: ILoadScreenManifest): Promise<void> {
        if (!manifest) {
            console.log('missing screen paths')
            return
        }

        const images = []

        try {
            if (Array.isArray(manifest)) {
                images.push(...(manifest as string[]))
            } else {
                const now = Date.now()

                for (const name in manifest) {
                    if (name === 'fallback') {
                        continue
                    }
                    const option = manifest[name]
                    const start = Date.parse(option.start)
                    const end = Date.parse(option.end)

                    if (now >= start && now < end) {
                        images.push(...option.images)
                    }
                }
                if (!images.length && manifest.fallback) {
                    images.push(...manifest.fallback.images)
                }
            }
        } catch (e) {
            console.error(e)
        }
        try {
            const screens = await Assets.load(
                images.map((p) => {
                    return {
                        src: (p.startsWith('http') ? '' : Config.environment.assetRoot + '/') + p
                    }
                })
            )
            this.screens = Object.values(screens)
        } catch (e) {
            console.log(e)
        }
    }

    getRandomLoadingScreenTexture(): Texture {
        if (!this.screens.length) {
            return Texture.EMPTY
        }
        const index = Math.floor(Math.random() * this.screens.length)
        return this.screens[index]
    }

    private static _instance: LoadingScreensManager

    static get instance(): LoadingScreensManager {
        return this._instance || (this._instance = new LoadingScreensManager())
    }
}
