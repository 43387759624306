import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container, Graphics, SCALE_MODES } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Constants } from '../../../Constants.js'
import { type EntityRoomIdentifier, EWindow, UILayer } from '../../../enums.js'
import { Fonts } from '../../../Fonts.js'
import { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../../buttons/ImageButton.js'
import { BasicBox } from '../../containers/BasicBox.js'
import { FieldH } from '../../fields/FieldH.js'
import type { IWindow } from '../IWindow.js'

export class TradePromptWindow extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.Legacy
    private ign: string
    private id: EntityRoomIdentifier

    header: Sprite
    isDraggable = true
    exitBtn: ImageButton

    ignoreBtn: BitmapTextButton
    okBtn: BitmapTextButton
    cancelBtn: BitmapTextButton

    getWindowWidth(): number {
        return 286
    }

    getWindowHeight(): number {
        return 134
    }

    setVisible(visible: boolean) {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset() {}

    container: Container
    private title: string
    private body: string // \n for newline

    private totalWidth: number
    private totalHeight: number

    private typeBody: BitmapText
    private ownerBody: BitmapText
    private tradeableBody: BitmapText

    private containerContainer: Container

    constructor(fromId: EntityRoomIdentifier, fromIgn: string) {
        super()

        this.id = fromId
        this.ign = fromIgn

        this.title = 'Invitation to Trade'
        this.body = `Do you want to trade with ${fromIgn}?`

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        if (!this.body) {
            return
        }

        const bg: BasicBox = new BasicBox(286, 134)
        this.container.addChild(bg)

        const titleBg: FieldH = new FieldH(279)

        this.container.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titleTextWidth: number = titleText.textWidth
        titleText.x = Math.round((bg.width - titleTextWidth) / 2)
        this.container.addChild(titleText)
        const bodyText = new BitmapText(this.body || '', {
            ...Fonts.Foxley_16
        })

        bodyText.x = Math.round((bg.width - bodyText.textWidth) / 2)
        bodyText.y = 38

        this.container.addChild(bodyText)

        this.ignoreBtn = new BitmapTextButton(80, 'IGNORE', BitmapTextButtonType.GREEN)
        this.okBtn = new BitmapTextButton(80, 'OK', BitmapTextButtonType.GREEN)
        this.cancelBtn = new BitmapTextButton(80, 'CANCEL', BitmapTextButtonType.GREEN)

        this.ignoreBtn.position.set(12, 120)
        this.okBtn.position.set(109, 120)
        this.cancelBtn.position.set(206, 120)

        this.cancelBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('trade_reject', {
                id: fromId
            })
            Client.shared.userInterface.removeWindow(this)
        })

        this.ignoreBtn.addEventListener('pointerup', async () => {
            if (Client.shared.serverBroker.serverMinVers(10)) {
                Client.shared.serverBroker.send('trade_reject', {
                    id: fromId,
                    ignore: true
                })
            } else {
                Client.shared.serverBroker.send('trade_reject', {
                    id: fromId
                })
            }
            Client.shared.userInterface.removeWindow(this)
        })

        this.okBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('trade_accept', {
                id: fromId
            })
            Client.shared.userInterface.removeWindow(this)
        })

        this.container.addChild(this.ignoreBtn, this.okBtn, this.cancelBtn)

        const dragGfx = new Graphics()
        dragGfx.beginFill(0xffffff)
        dragGfx.drawRect(0, 0, 267, 15)
        dragGfx.endFill()
        dragGfx.alpha = 0
        const texture = Client.shared.renderer.generateTexture(
            dragGfx,
            SCALE_MODES.NEAREST,
            Client.shared.renderer.resolution
        )
        this.header = Pooler.newSprite(texture)
        this.header.position.set(27, 15)
        this.container.addChild(this.header)

        this.containerContainer.addChild(this.container)

        this.addChild(this.containerContainer)

        Client.shared.userInterface.register(this)

        this.center()
    }

    onIgnore() {}

    onOk() {}

    center() {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove() {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
