import type { FederatedEvent } from 'pixi.js'
import { Client } from '../../../Client.js'
import { UILayer } from '../../../enums.js'
import type { MixerSong } from '../../../minigame/music_mixer/MusicMixer.js'
import { ScrollArea } from '../../../ui/containers/ScrollArea.js'
import { FieldHlp } from '../../../ui/fields/FieldHlp.js'
import { AccordionList } from '../../../ui/lists/AccordionList.js'
import { AccordionListItem } from '../../../ui/lists/AccordionListItem.js'
import LegacyWindow from '../../../ui/windows/LegacyWindow.js'

export class PickMix extends LegacyWindow {
    layer = UILayer.GameWindows

    list: AccordionList

    selectedSong?: MixerSong

    protected casts = ['interface/window_rec']

    constructor(readonly mixer: string) {
        super('vmk_musicmix', new LegacyWindow('vmk_musicmix_play'))
    }

    override async windowWasBuilt(): Promise<void> {
        this.setField('musicmix.dialog.hd', 'musicmix.dialog.playsong.title')

        this.getElement('musicmix.dontperform.button').addEventListener('pointerup', () =>
            Client.shared.userInterface.removeWindow(this)
        )
        this.list = new AccordionList(
            this.replaceElement('musicmix.playsongs.list', new ScrollArea(295, 90, null, 5, 5), true)
        )

        this.getElement('musicmix.playsong.button').addEventListener(
            'pointerup',
            () => {
                if (this.selectedSong) {
                    Client.shared.serverBroker.send('mix_queue', this.selectedSong.id)
                    Client.shared.userInterface.removeWindow(this)
                }
            },
            { once: true }
        )

        this.reload()
    }

    reload() {
        Client.shared.serverBroker.sendAck('mix_list', this.mixer).then((list: MixerSong[]) => {
            this.list.clear()

            for (const song of list) {
                const item = new AccordionListItem()
                const field = item.addChild(new FieldHlp(song.name))
                field.addEventListener('pointerup', (e: FederatedEvent) => {
                    this.list.clearSelection()

                    field.setSelected(!field.isSelected())
                    this.list.setExpanded(item, field.isSelected())

                    this.selectedSong = song
                })
                this.list.add(item, false)
            }

            this.list.update()

            if (this.list.children < 1) {
                Client.shared.helpers.alert('musicmix.dialog.performsong.nosongs.text')
            }
        })
    }
}
