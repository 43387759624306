import type { ICastProvides } from '@vmk-legacy/render-utils'
import { Pooler } from '@vmk-legacy/render-utils'
import type { DisplayObject, IDestroyOptions } from 'pixi.js'
import { BitmapText, Container, Graphics, Sprite, Text, TextStyle, Texture } from 'pixi.js'
import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import ClientHelpers from '../../ClientHelpers'
import { Constants } from '../../Constants.js'
import { EWindow } from '../../enums.js'
import { StringUtil } from '../../util/StringUtil.js'
import { AvatarTooltip } from '../AvatarTooltip.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { DOMText } from '../DOMText.js'
import { Scrollbar } from '../scrollbars/Scrollbar.js'
import type { PagedThumbnailBox } from '../thumbs/PagedThumbnailBox.js'
import { UIWindow } from './UIWindow.js'
import { UIWindowType } from './UIWindowType.js'

interface WinDef {
    elements: WinElem[];
    rect: [ left: number, top: number, right: number, bottom: number ]
    border?: number[4];
    clientrect?: number[4];
}

export enum Strech {
    fixed = '#fixed',
    strechH = '#strechH',
    strechV = '#strechV',
    moveH = '#moveH',
    moveV = '#moveV',
    strechHV = '#strechHV',
    moveHV = '#moveHV',
    centerH = '#centerH',
    centerV = '#centerV',
    moveHstrechV = '#moveHstrechV',
    moveVstrechH = '#moveVstrechH'
}

interface WinElem {
    member: string;
    media: '#bitmap' | '#text' | '#field';
    locH: number;
    locV: number;
    ink: number;
    blend: number;
    width: number;
    height: number;

    bgColor: string;

    color: string;
    cursor: string;

    editable: 0 | 1;
    fontSize: number;
    fontStyle: string[];
    font: string;
    alignment: '#left' | '#center' | '#right';
    wordWrap: 0 | 1;
    boxType: '#adjust' | '#fixed';
    fixedLineSpace: number;
    lineHeight: number;
    autoTab: 0 | 1;
    txtColor: string;
    antialias: 0 | 1;
    maxlength: number;
    txtBgColor?: string;

    type: 'piece' | 'text';
    id: string;
    strech: Strech;

    key: string;
    model: string;
}

export type ElementObject = Sprite | Container | BitmapTextButton | BitmapText | Text | ImageButton | PagedThumbnailBox;

export default class LegacyWindow extends UIWindow {
    readonly kind: EWindow = EWindow.PiratesModal
    abstract windowTitle: string
    protected casts: string[]

    protected isEmbedded = false
    protected embeddedParent?: LegacyWindow
    protected elements: Map<string, ElementObject[]> = new Map()
    protected def: WinDef
    protected mainContainer: Container
    protected clientContainer?: Container
    protected resizable = {
        stretchH: new Set<ElementObject>(),
        stretchV: new Set<ElementObject>(),
        moveH: new Set<ElementObject>(),
        moveV: new Set<ElementObject>()
    }

    constructor(
        protected winDef: string,
        protected embedding?: DisplayObject, protected fillText?: { [id: string]: TextKey }
    ) {
        super(400, UIWindowType.INVENTORY)

        if (embedding instanceof LegacyWindow) {
            if (this.casts) {
                embedding.casts = this.casts
            }
            embedding.isEmbedded = true
            embedding.embeddedParent = this
        }

        this.mainContainer = this.addChild(Pooler.newContainer())
    }

    replaceElement<T extends ElementObject>(id: string, object: T, inheritSize = false): T {
        const els = this.getElements(id)

        if (els?.length) {
            const first = els[0]
            const parent = first.parent

            object.name = id

            if (inheritSize) {
                if ('innerWidth' in object) {
                    object.innerWidth = first.width - 30
                    object.innerHeight = first.height - 20
                }
            }

            object.x = first.x + (inheritSize ? 0 : (first.width - object.width) / 2)
            object.y = first.y + (inheritSize ? 0 : (first.height - object.height) / 2)

            for (const e of els) {
                e.destroy()
            }
            els.length = 0

            parent.addChild(object)
            els.push(object)

            if (inheritSize && object.sizeDidChange) {
                object.sizeDidChange()
            }
        }

        return object
    }

    getElement(id: string): any | undefined {
        const els = this.getElements(id)

        return els.length ? els[0] : undefined
    }

    getElements(id: string | ElementObject | ElementObject[], mediaType?: 'sprite' | 'text'): any[] {
        const result = []

        if (typeof id !== 'string') {
            if (Array.isArray(id)) {
                return id
            }
            return [ id ]
        }
        if (id.includes('*')) {
            const regex = StringUtil.wildcardToRegExp(id)

            this.elements.forEach((els, key) => {
                if (key.search(regex) !== -1) {
                    result.push(...els)
                }
            })
        } else {
            const els = this.elements.get(id)

            if (els) {
                result.push(...els)
            }
        }

        if (this.embedding instanceof LegacyWindow) {
            result.push(...this.embedding.getElements(id))
        }

        if (mediaType === 'sprite') {
            return result.filter(e => !!e.isSprite && !(e instanceof Text))
        }

        return result
    }

    setField(id: string, text: TextKey): Text | BitmapText | BitmapTextButton | undefined {
        if (!this.alreadyBuilt) {
            return undefined
        }
        const els = this.getElements(id)

        if (!els || !els.length) {
            throw new Error('Could not find window element with ID ' + id + ', available: ' + (Array.from(this.elements.keys())
                .join(', ') || '[NONE]'))
        }

        for (let el of els) {
            if (!(el instanceof Text) && !(el instanceof BitmapText) && !(el instanceof DOMText) && !(el instanceof BitmapTextButton)) {
                //@ts-expect-error
                el = (el as Container).children[0]
            }
            if (!el) {
                continue
            }
            if (el instanceof DOMText) {
                el.setValue(getText(text))
            } else if (el instanceof Text || el instanceof BitmapText || el instanceof BitmapTextButton) {
                el.text = getText(text)
            }
        }

        return els[0]
    }

    setBitmap(id: string, texture: Texture | string | null, size?: [ number, number ]): void {
        if (!this.alreadyBuilt) {
            return
        }
        const els = this.getElements(id)

        if (!els) {
            throw new Error('Could not find window element with ID ' + id + ', available: ' + (Array.from(this.elements.keys())
                .join(', ') || '[NONE]'))
        }

        if (texture === null) {
            texture = Texture.EMPTY
        }

        const newTex = typeof texture === 'string' ? Texture.from(texture) : texture

        els.forEach(el => {
            if (!el) {
                return
            }
            if (!(el instanceof Sprite) && (el instanceof Container)) {
                //@ts-expect-error
                el = (el as Container).children[0]
            }
            if (!(el instanceof Sprite)) {
                return
            }


            el.texture = newTex
            if (typeof size !== 'undefined') {
                el.width = size[0]
                el.height = size[1]
            }
        })
    }

    hide(...ids: (string | ElementObject)[]): void {
        ids.forEach(id => this.getElements(id)?.forEach(e => e.visible = false))
    }

    show(...ids: (string | ElementObject)[]): void {
        ids.forEach(id => this.getElements(id)?.forEach(e => e.visible = true))
    }

    embed(clientWin: DisplayObject): Promise<void> {
        this.embedding = clientWin

        if (clientWin instanceof LegacyWindow) {
            clientWin.casts = this.casts
            clientWin.isEmbedded = true
            clientWin.embeddedParent = this
            clientWin.eventMode = 'auto'
        }

        return this.buildingPromise = this._buildWindow()
    }

    async rebuild(): Promise<void> {
        this.alreadyBuilt = false

        return super.rebuild()
    }

    protected castProvided(provides: ICastProvides): void {
        //
    }

    protected _buildWindow(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.isEmbedded && !this.casts && this.parent instanceof LegacyWindow) {
                this.casts = this.parent.casts
            }

            const loadCasts = this.casts || [ 'interface/window_rec' ]

            this.provider.get(loadCasts).then(async (provides) => {
                if (this.destroyed) {
                    return
                }
                const def: WinDef = provides?.files[this.winDef + '.window']

                if (!def) {
                    return reject(new Error('Window definition ' + this.winDef + ' is not loaded in specified casts: ' + loadCasts.join(', ')))
                }
                this.def = def

                if (!this.alreadyBuilt) {
                    if (!this.isEmbedded) {
                        this.position.set(def.rect[0], def.rect[1])
                    }

                    this.elements.clear()
                    this.mainContainer.removeChildren()
                    this.resizable.moveH.clear()
                    this.resizable.moveV.clear()
                    this.resizable.stretchH.clear()
                    this.resizable.stretchV.clear()
                }

                let deltaH = 0
                let deltaV = 0

                if (def.clientrect) {
                    this.clientContainer = this.clientContainer || this.addChild(Pooler.newContainer())
                    this.clientContainer.position.set(def.clientrect[0], def.clientrect[1])
                    this.clientContainer.removeChildren()

                    if (this.embedding) {
                        if (this.embedding instanceof LegacyWindow) {
                            await this.embedding.waitToBeBuilt()
                        }
                        this.embedding.visible = true
                        this.clientContainer.addChild(this.embedding)
                        this.embedding.position.set(0, 0)
                    }

                    const clientRectW = def.clientrect[2] - def.clientrect[0]
                    const clientRectH = def.clientrect[3] - def.clientrect[1]
                    const embedRectW = this.clientContainer.width
                    const embedRectH = this.clientContainer.height

                    if (embedRectW > clientRectW) {
                        deltaH = embedRectW - clientRectW
                    }
                    if (embedRectH !== clientRectH) {
                        deltaV = embedRectH - clientRectH + ('padBottom' in def ? +def.padBottom : 7)
                    }
                }

                if (!this.alreadyBuilt) {
                    this.layoutElements(def.elements, deltaH, deltaV, this.mainContainer)
                    this.exitBtn = this.getElement('close') as ImageButton
                    this.alreadyBuilt = true
                    this.castProvided(provides)
                    await this.windowWasBuilt()
                } else {
                    this.resizable.moveH.forEach(e => e.x = e['initialX'] + deltaH)
                    this.resizable.moveV.forEach(e => e.y = e['initialY'] + deltaV)
                    this.resizable.stretchH.forEach(e => {
                        const newWidth = e['initialW'] + deltaH

                        if (e instanceof Text) {
                            e.style.wordWrapWidth = newWidth

                            if (e.style.align === 'center') {
                                e.position.x = e['initialX'] + newWidth / 2
                            } else if (e.style.align === 'right') {
                                e.position.x = e['initialX'] + newWidth
                            }
                        } else {
                            e.width = newWidth
                        }
                    })
                    this.resizable.stretchV.forEach(e => e.height = e['initialH'] + deltaV)
                }

                resolve()
            }).catch(reject)
        })
    }

    private layoutElements(elems: WinElem[], deltaH: number, deltaV: number, parent: Container): void {
        const debug = false//Config.environment.debug;
        // parse elements
        for (let memb of elems) {
            memb = { ...memb }

            const initialX = memb.locH
            const initialY = memb.locV
            const initialW = memb.width
            const initialH = memb.height

            if (memb.strech && memb.strech !== Strech.fixed) {
                if ([ Strech.strechH, Strech.strechHV, Strech.moveVstrechH ].includes(memb.strech)) {
                    memb.width += deltaH
                }
                if ([ Strech.strechV, Strech.strechHV, Strech.moveHstrechV ].includes(memb.strech)) {
                    memb.height += deltaV
                }
                if ([ Strech.moveH, Strech.moveHV, Strech.moveHstrechV ].includes(memb.strech)) {
                    memb.locH += deltaH
                }
                if ([ Strech.moveV, Strech.moveHV, Strech.moveVstrechH ].includes(memb.strech)) {
                    memb.locV += deltaV
                }
            }

            let element: ElementObject

            if ((memb.media === '#field' && memb.type === 'text') || memb.media === '#text') {
                let textValue = getText(this.fillText?.hasOwnProperty(memb.id) ? this.fillText[memb.id] : memb.key)

                const align = (memb.alignment.replace('#', '') || 'left') as 'left' | 'center' | 'right'
                const tint = memb.txtColor || memb.color || '#000000'
                const wordWrap = memb.wordWrap === 1
                const fontSize = +memb.fontSize
                const lineHeight = memb.fixedLineSpace || memb.lineHeight || memb.fontSize

                const firstBreak = textValue.indexOf('\n')
                if (!wordWrap && firstBreak !== -1) {
                    textValue = textValue.substring(0, firstBreak)
                }

                let text: DOMText | Text | Graphics
                if (memb.editable) {
                    text = parent.addChild(new DOMText({
                        kind: wordWrap ? 'area' : 'field',
                        className: '',
                        id: '',
                        maxlength: +(memb.maxlength || 0),
                        fieldWidth: +memb.width,
                        fieldHeight: +memb.height,
                        fontSize: fontSize,
                        fontColor: tint,
                        initialValue: textValue,
                        bgObject: null,
                        padLeft: 0,
                        padTop: 0,
                        noBreaks: false,
                        enterHint: '',
                        inputMode: 'text',
                        lineHeight: lineHeight
                    }))
                    text.position.set(memb.locH, memb.locV)
                } else {
                    text = new Text(textValue, new TextStyle({
                        fontFamily: memb.font.includes('Foxley') ? 'web-foxley' : 'web-folio',
                        fontSize,
                        fontWeight: memb.font.includes('Bold') || memb.font.includes('Folio') ? 'bold' : 'normal',
                        lineHeight,
                        align,
                        fill: ('0x' + tint.substr(1, tint.length)) as number,
                        whiteSpace: 'pre',
                        breakWords: true,
                        wordWrap,
                        wordWrapWidth: memb.width,
                        textBaseline: 'bottom'
                    }))

                    if (align === 'center') {
                        text.anchor.set(0.5, 0)
                        text.position.set(memb.locH + memb.width / 2, memb.locV)
                    } else if (align === 'right') {
                        text.anchor.set(1, 0)
                        text.position.set(memb.locH + memb.width, memb.locV)
                    } else {
                        text.anchor.set(0)
                        text.position.set(memb.locH, memb.locV)
                    }

                    if (memb.boxType === '#fixed' || wordWrap) {
                        const mask = new Graphics()
                            .beginFill(0xffffff)
                            .drawRect(memb.locH, memb.locV, memb.width, Math.max(fontSize, lineHeight, memb.height) + (memb.fixedLineSpace || 0))
                            .endFill()
                        mask.eventMode = 'auto'
                        parent.addChildAt(mask, 0)
                        text.mask = mask
                    }

                    // if (memb.txtBgColor) {
                    // 	const bg = new Graphics().beginFill(
                    // 		("0x" + memb.txtBgColor.substr(1, memb.txtBgColor.length)) as number)
                    // 							 .drawRect(memb.locH, memb.locV, memb.width, memb.height);
                    // 	bg.addChild(text);
                    // 	text = bg;
                    // }
                    parent.addChild(text)
                }

                element = text
            } else if (memb.media === '#bitmap' && memb.member) {
                if (memb.type === 'button') {
                    const textValue = getText(memb.key) || memb.key || ''
                    let button: BitmapTextButton | ImageButton

                    if (memb.model <= 9) {
                        const model = {
                            1: 'a',
                            2: 'b',
                            3: 'c',
                            4: 'd',
                            5: 'e',
                            6: 'f',
                            7: 'g',
                            8: 'h',
                            9: 'i'
                            // 14 = help/close
                            // 15 = emoticons help
                            // 16 = emoticons dance/wave button
                        }[memb.model]
                        button = parent.addChild(new BitmapTextButton(memb.width, textValue, model))
                    } else {
                        button = parent.addChild(new ImageButton(memb.member, memb.member.replace('active', 'pressed')))
                    }
                    button.position.set(memb.locH, memb.locV)

                    element = button
                } else if (memb.type === 'scrollbarv') {
                    const elToScroll = memb.client
                    element = new Scrollbar((percent) => {
                        const el = (this.getElement(elToScroll) as Text)
                        if (!el) {
                            return
                        }
                        el.pivot.y = el.height * percent
                    })
                    element.position.set(memb.locH, memb.locV)
                    parent.addChild(element)

                } else {
                    if (memb.member === 'null') {
                        memb.bgColor = '#ffffff'
                    }

                    let sprite: Sprite

                    try {
                        sprite = Pooler.newSprite(memb.member === 'shadow.pixel' ? Texture.WHITE : memb.member)
                        ClientHelpers.genHitmap(sprite.texture, 125)
                    } catch (e) {
                        console.error(e)
                        sprite = Pooler.newSprite()
                    }

                    parent.addChild(sprite)

                    sprite.anchor.set(0)

                    sprite.position.set(memb.locH, memb.locV)
                    sprite.width = memb.width
                    sprite.height = memb.height

                    if ('color' in memb) {
                        sprite.tint = ('0x' + memb.color.substr(1, memb.color.length)) as number
                    }
                    if ('blend' in memb) {
                        sprite.alpha = memb.blend / 100
                    }

                    if ('ink' in memb) {
                        switch (memb.ink) {
                            case 0:
                                break
                            case 9:
                                sprite.blendMode = 31 //xor
                                break
                            case 33:
                            case 34:
                                sprite.blendMode = 3 //screen
                                break
                            case 35:
                            case 38:
                                sprite.blendMode = 11 //difference
                                break
                            case 36:

                                //background transparent
                                break
                            case 37:
                            case 40:
                                sprite.blendMode = 6 //lighten
                                break
                            case 39:
                            case 41:
                                sprite.blendMode = 5 //darken
                                break
                        }
                    }

                    element = sprite
                }
            } else {
                console.warn('Unknown window member media type: ' + memb.type + ' ' + memb.media)
            }

            if (memb.id) {
                element.name = memb.id
                let elArr = this.elements.get(memb.id)
                if (!elArr) {
                    elArr = []
                    this.elements.set(memb.id, elArr)
                }
                elArr.push(element)
            }
            if (memb.strech && memb.strech !== Strech.fixed) {
                element['initialX'] = initialX
                element['initialY'] = initialY
                element['initialW'] = initialW
                element['initialH'] = initialH

                if ([ Strech.strechH, Strech.strechHV, Strech.moveVstrechH ].includes(memb.strech)) {
                    this.resizable.stretchH.add(element)
                }
                if ([ Strech.strechV, Strech.strechHV, Strech.moveHstrechV ].includes(memb.strech)) {
                    this.resizable.stretchV.add(element)
                }
                if ([ Strech.moveH, Strech.moveHV, Strech.moveHstrechV ].includes(memb.strech)) {
                    this.resizable.moveH.add(element)
                }
                if ([ Strech.moveV, Strech.moveHV, Strech.moveVstrechH ].includes(memb.strech)) {
                    this.resizable.moveV.add(element)
                }
            }
            if (memb.cursor === 'cursor.finger' || memb.type === 'button') {
                element.eventMode = 'static'
                element.cursor = 'pointer'
            }
            if (!element) {
                console.log('Element not constructed for window member: ', memb)
            }
            if (debug) {
                let hoverInfo: AvatarTooltip
                let border: Graphics
                element.eventMode = 'static'
                element.addEventListener('pointerover', () => {
                    hoverInfo = (element.parent as Container).addChild(new AvatarTooltip(element.name))

                    let x = element.x + element.width / 2
                    let y = element.y - 25

                    if (x < 0) {
                        x = element.x + element.width
                    } else if (x + hoverInfo.width > Constants.SIZE[0]) {
                        x = Constants.SIZE[0] - hoverInfo.width
                    }
                    if (y < 0) {
                        y = element.y + element.height
                    } else if (y + 25 > Constants.SIZE[1]) {
                        y = Constants.SIZE[1] - 25
                    }
                    hoverInfo.position.set(x, y)

                    border = new Graphics().beginFill(0xFFFF00).lineStyle(2, 0xFF0000)
                        .drawRect(element.x, element.y, element.width, element.height);
                    (element.parent as Container).addChildAt(
                        border,
                        (element.parent as Container).getChildIndex(element)
                    )
                })
                element.addEventListener('pointerup', () => {
                    try {
                        window.navigator.clipboard.writeText(element.name)
                    } catch (e) {
                        //prompt('Clicked element name/ID', element.name);
                    }
                })
                element.addEventListener('pointerout', () => {
                    border?.destroy()
                    border = null
                    hoverInfo?.destroy()
                    hoverInfo = null
                })
            }
        }
    }

    async windowWasBuilt(): Promise<void> {
        //
    }

    destroy(_options?: IDestroyOptions | boolean): void {
        this.elements.forEach(els => {
            for (const el of els) {
                Pooler.release(el)
            }
            els.length = 0
        })
        this.embedding?.destroy()
        this.embedding = undefined
        this.elements.clear()
        super.destroy()
    }
}
