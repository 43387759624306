import { AnimatedSprite, Sprite, Texture } from 'pixi.js'

export class ThumbnailLoadAnim extends Sprite {
    constructor() {
        super()
        const loadingFrames = []
        for (let i = 1; i < 9; i++) {
            loadingFrames.push(Texture.from('thumbload_' + i))
        }
        const anim = new AnimatedSprite(loadingFrames)
        anim.animationSpeed = 0.22
        anim.play()
        // anim.scale.x = anim.scale.y = (24/32);
        this.addChild(anim)
    }
}
