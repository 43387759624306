import { Client } from '../../Client.js'
import { NPCDialogWindow } from '../../ui/windows/npc_popup/NPCDialogWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class NPCVisitModule extends ServerEvent {
    static readonly type = MessageType.NPC_VISIT

    async handle(data: any) {
        const title: string = data.title
        const body: string = data.body
        const gfx: string = data.gfx

        Client.shared.userInterface.register(new NPCDialogWindow(title, body, gfx), true)
    }
}
