import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class FurniRemovedModule extends ServerEvent {
    static readonly type = MessageType.FURNI_REMOVED

    async handle(data: { itemId?: number; all?: true }) {
        if (data.itemId) {
            Client.shared.roomViewer.furniController.removeItemById(data.itemId)
        } else if (data.all) {
            Client.shared.roomViewer.furniController.clearAll()
        }
    }
}
