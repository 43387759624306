import type { IFurniAnim } from '@vmk-legacy/common-ts'
import { EFurniBehavior, EItemType } from '@vmk-legacy/common-ts'
import type { ScoreVisualChannelStep } from '@vmk-legacy/render-utils'
import { ESndGrp, FurniMapItem, ScoreRunner, SoundManager, TextureChannelItem } from '@vmk-legacy/render-utils'
import { EventEmitter } from 'eventemitter3'
import { gsap } from 'gsap'
import type { FederatedEvent, FederatedPointerEvent } from 'pixi.js'
import { AnimatedSprite, Sprite, Texture } from 'pixi.js'
import { Client } from '../../../Client.js'
import ClientHelpers from '../../../ClientHelpers.js'
import { Constants } from '../../../Constants.js'
import { ContextMenu } from '../../../ui/ContextMenu.js'
import { UISoundLibrary } from '../../../ui/UISoundLibrary.js'
import { AlertView } from '../../../ui/views/AlertView.js'
import { DarkRideConfigWindow } from '../../../ui/windows/rooms/DarkRideConfigWindow'
import { Helpers } from '../../../util/Helpers.js'
import { AvatarEntity } from '../../entities/AvatarEntity'
import { AvatarVisual } from '../../entities/AvatarVisual'
import type { RoomItem } from '../../RoomItem.js'
import { ManagedSingleContainer, ManagedSprite } from '../FakeContainer.js'
import type FurniController from '../FurniController.js'
import type { WalkableRoomViewer } from '../WalkableRoomViewer'
import { FurniEntityPart } from './FurniEntityPart.js'
import type { TileEntity } from './TileEntity.js'

// this cannot be a container because the parts must be able to inter-stack with others
export class FurniEntity extends EventEmitter {
    readonly type: 'furni' | 'poster' | 'avatar' = 'furni'
    parts: FurniEntityPart[] = []
    protected pulsing?: gsap.core.Tween
    _eventMode = 'static'
    anim?: IFurniAnim
    animScore?: ScoreRunner

    placeTimer = null
    playingSound?: AudioBufferSourceNode
    handledSoundForState?: number

    tapTimer = null

    wholeAlpha = 1

    constructor(
        readonly controller: FurniController,
        public furnimap: FurniMapItem,
        protected item: RoomItem,
        protected rootTile: TileEntity,
        public isPlaced: boolean
    ) {
        super()
        if (item.customData?.hide) {
            this.wholeAlpha = Client.shared.selfRecord.isStaff() ? 0.25 : 0
        }

        if (!this.furnimap) {
            this.furnimap = new FurniMapItem([], item.defUid, true, 32, true)

            const dir = {
                num: 3,
                blocks: [
                    {
                        sprites: [
                            {
                                gfx: 'loadingfx_0',
                                x: 0,
                                y: 0,
                                z: 0
                            }
                        ],
                        hgt: 32
                    }
                ]
            }

            this.furnimap.addDirection(dir)
            this.anim = {
                states: {
                    0: {
                        data: {
                            3: {
                                0: {
                                    0: {
                                        frames: [
                                            'loadingfx_0',
                                            'loadingfx_1',
                                            'loadingfx_2',
                                            'loadingfx_3',
                                            'loadingfx_4'
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            this.anim = Client.shared.furniMapMgr.getAnim(item.defUid)
        }
    }

    static getLoadingBlock() {
        const anim = AnimatedSprite.fromFrames([0, 1, 2, 3, 4].map((n) => `loadingfx_${n}`))
        anim.animationSpeed = 0.08
        anim.updateAnchor = true
        anim.play()

        return anim
    }

    set eventMode(val: string) {
        if (this._eventMode !== val) {
            this._eventMode = val
            for (const p of this.parts) {
                p.eventMode = val
            }
        }
    }

    reinitAnimScore(newState = true): void {
        if (!this.anim) {
            console.log('No animation for furni #' + this.item.itemId + ' def uid #' + this.item.defUid)
            return
        }
        this.animScore?.teardown()
        this.animScore = undefined

        if (newState && this.playingSound) {
            SoundManager.shared.release(this.playingSound)
            this.playingSound = undefined
        }

        console.log('Anim ' + this.item.defUid, this.anim)
        const data = this.anim.states[this.item.state]
        if (!data) {
            console.log('missing state ' + this.item.state)
            this.render(false, true)
            return
        }

        if (newState || this.handledSoundForState !== this.item.state) {
            if (this.playingSound) {
                SoundManager.shared.release(this.playingSound)
                this.playingSound = undefined
            }
            if (data.sound) {
                if (newState || !!data.soundLoop) {
                    // play if state change was triggered OR initial load for a sound looping item
                    console.log('Playing furni sound: ', data.sound)
                    const sound = this.controller.sounds.get(data.sound) || UISoundLibrary.all.get(data.sound)

                    if (sound) {
                        this.playingSound = SoundManager.shared.play(ESndGrp.SFX, sound, !!data.soundLoop)
                    } else {
                        console.log('Missing furni sound: ' + data.sound)
                    }
                }
            }
            this.handledSoundForState = this.item.state
        }

        if (data.label) {
            const statue = this.parts[0]?.children[0]?.children[0]
            if (statue instanceof AvatarVisual) {
                if (data.label === 'dance') {
                    statue.startDancing()
                } else {
                    statue.stopDancing()
                }
                if (data.label === 'wave') {
                    statue.wave()
                }
                if (data.label === 'sit') {
                    statue.startSitting()
                } else {
                    statue.stopSitting()
                }
                if (data.label === 'steer') {
                    statue.steer()
                }
                if (data.label === 'move') {
                    statue.startWalking()
                } else {
                    statue.stopWalking()
                }
            }
        }

        if (!data.data) {
            return
        }

        const dir = data.data[this.item.rotation]
        if (!dir) {
            return
        }
        this.animScore = new ScoreRunner({ name: 'Furni ' + this.item.itemId })
        this.animScore.autoplays = false

        if (typeof data.loop === 'undefined' || data.loop === 0) {
            this.animScore.loops = true
        } else if (data.loop !== 1) {
            this.animScore.loops = true
            this.animScore.loopTimes = data.loop
        } else {
            this.animScore.loops = false
        }

        if (this.item.behavior === EFurniBehavior.Proximity) {
            this.animScore.scoreEndCallback = () => {
                Helpers.delay((this.anim.delay ?? 1) * 100).then(() => {
                    this.setState(0, true)
                })
            }
        }
        let lastFrame = 0

        for (let index = 0; index < this.parts.length; index++) {
            const part = this.parts[index]
            if (!part) {
                continue
            }
            const partNum = index + 1
            const blockAnim = dir[partNum]
            if (!blockAnim) {
                console.log('no block anim ' + partNum)
                continue
            }
            for (const spr of part.children) {
                const sprAnim = blockAnim[1 + (spr.index ?? 0)]
                if (!sprAnim) {
                    continue
                }
                if (sprAnim.events?.length) {
                    //TODO: events were not properly decoded in JSON, they should happen on timeline with frames
                    if (sprAnim.events.includes('stop')) {
                        this.animScore.loops = false
                    }
                }
                if (!(spr instanceof Sprite) || !sprAnim.frames.length) {
                    continue
                }

                if (spr instanceof ManagedSprite) {
                    if (spr.selfAlpha === 0) {
                        spr.selfAlpha = 1
                        spr.alpha = 1
                    }
                }

                const spriteChan = this.animScore.addTextureChannel(this.item.defUid + ' ' + spr.index, spr)
                for (let i = 0; i < sprAnim.frames.length; i++) {
                    const scoreStep: ScoreVisualChannelStep = {
                        start: 1 + i * (this.anim.delay || 3),
                        end: 1 + (i + 1) * (this.anim.delay || 3),
                        type: 'sprite',
                        asset: sprAnim.frames[i]
                    }
                    if (scoreStep.end > lastFrame) {
                        lastFrame = scoreStep.end
                    }
                    let tex: Texture
                    if (!scoreStep.asset || scoreStep.asset === 'null') {
                        tex = Texture.EMPTY
                    } else {
                        try {
                            tex = Texture.from(scoreStep.asset)
                            ClientHelpers.genHitmap(tex, 125)
                        } catch (e) {
                            console.log(e)
                            tex = Texture.EMPTY
                        }
                    }
                    const item = new TextureChannelItem(spriteChan, spr as ManagedSprite, tex, scoreStep)
                    item.clearOnDeactivate = false
                    spriteChan.addItem(item, scoreStep.start, scoreStep.end)
                }
            }
        }
        this.animScore.setLastFrame(lastFrame)
        Client.shared.attachScore(this.animScore)
        this.animScore.playFromBeginning(true)
    }

    getAniProp(struct: [number, number, string]) {
        if (!this.anim) {
            return undefined
        }
        const [blkNum, sprNum, prop] = struct
        for (const [_blkId, _blk] of Object.entries(this.anim.states[this.item.state].data)) {
            if (+_blkId === +blkNum) {
                for (const [_sprId, _spr] of Object.entries(_blk)) {
                    if (+_sprId === +sprNum) {
                        return _spr[prop]
                    }
                }
            }
        }
    }

    setState(state: number, internal: boolean): void {
        console.log('Set State Animation ' + state + ' ' + this.item.defUid + ' ' + this.item.rotation)

        this.item.state = state
        this.reinitAnimScore()
    }

    getPart(blockX: number, blockY: number): FurniEntityPart | undefined {
        return this.parts.find((part) => part.getBlockX() === blockX && part.getBlockY() === blockY)
    }

    setTile(tile: TileEntity, finalPlace: boolean, stackFloor = false): void {
        this.rootTile = tile

        this.item.ref = tile.ref

        this.updatePartTiles(finalPlace, stackFloor)
    }

    getTile(): TileEntity {
        return this.rootTile
    }

    getParts(): FurniEntityPart[] {
        return this.parts
    }

    getItem(): RoomItem {
        return this.item
    }

    itemUpdated(item: RoomItem, isPlaced = true): void {
        this.isPlaced = isPlaced

        const oldRef = this.item.ref
        const oldWallX = this.item.wallX
        const oldWallY = this.item.wallY
        const oldWallRef = this.item.wallRef
        const oldRotation = this.item.rotation
        const oldState = this.item.state
        const oldAlpha = this.wholeAlpha
        const oldCustomData = this.item.customData

        if (item.itemId) {
            this.item.itemId = item.itemId
        }
        this.item.ref = item.ref
        this.item.rotation = item.rotation
        this.item.state = item.state
        this.item.customData = item.customData
        this.item.behavior = item.behavior

        if (item.customData?.hide) {
            this.wholeAlpha = Client.shared.selfRecord.isStaff() ? 0.25 : 0
        } else {
            this.wholeAlpha = 1
        }

        this.rootTile = this.controller.room.tiles.get(item.ref)

        if (oldState !== item.state) {
            this.reinitAnimScore(true)
        }

        if (oldRotation !== item.rotation) {
            this.emit('rotated')
        }

        const customDataChanged = JSON.stringify(oldCustomData) !== JSON.stringify(item.customData)

        if (oldRotation !== item.rotation || oldAlpha !== this.wholeAlpha || customDataChanged) {
            this.render()
        } else if (
            oldRef !== item.ref ||
            oldWallX !== item.wallX ||
            oldWallY !== item.wallY ||
            oldWallRef !== item.wallRef
        ) {
            this.updatePartTiles(isPlaced)
        }
    }

    rotate(): void {
        if (this.type === 'poster') {
            return
        }
        const allDirs = this.furnimap.getDirections().map((d) => d.num)
        const currentIndex = allDirs.indexOf(+this.item.rotation)

        let rotation
        if (currentIndex === allDirs.length - 1) {
            rotation = allDirs[0]
        } else {
            rotation = allDirs[currentIndex + 1]
        }

        this.itemUpdated(
            {
                ...this.item,
                rotation
            },
            this.isPlaced
        )

        if (this.isPlaced) {
            this.sendPlacement({ rotation })
        }
    }

    sendPlacement(opts: any = {}): void {
        if (this.placeTimer) {
            clearTimeout(this.placeTimer)
        }
        this.placeTimer = setTimeout(() => {
            if (this.isPlaced) {
                Client.shared.serverBroker.send('furni_move', {
                    itemId: this.item.itemId,
                    tileRef: this.item.ref,
                    rotation: this.item.rotation,
                    ...opts
                })
            } else {
                Client.shared.serverBroker
                    .sendAck('furni_place', {
                        defUid: this.item.defUid,
                        teleId: this.item.teleporterId,
                        tileRef: this.item.ref,
                        rotation: this.item.rotation,
                        ...opts
                    })
                    .then((success) => {
                        if (success) {
                            this.isPlaced = true
                            if (typeof success === 'number') {
                                this.item.itemId = success
                            } else {
                                this.itemUpdated(success, false)
                            }
                            this.emit('place_finished')
                        } else {
                            this.controller.removeItem(this)
                            this.off('place_finished')
                        }
                    })
            }
        }, 250)
    }

    removeFromRoom(): void {
        const statue = this.parts[0]?.children[0]?.children[0]
        if (statue instanceof AvatarVisual) {
            this.controller.room.removeEntity(statue.factor)
        }
        this.controller.removeItemById(this.item.itemId)
        Client.shared.serverBroker.send('furni_remove', {
            itemId: this.item.itemId
        })
    }

    getFurniScale(): number {
        return this.controller.room.getTilesize() / this.furnimap.getTilesize()
    }

    render(hide = false, doNotAnimate = false): void {
        const dir = this.furnimap.getDirection(this.item.rotation) || this.furnimap.getDirection(3)
        console.log('Rendering', this.item)
        if (!dir) {
            return
        }
        if (this.item.defType !== EItemType.Poster && !this.rootTile) {
            return
        }
        const tile = this.item.defType === EItemType.Poster ? null : this.rootTile
        const wasPulsing = this.pulsing

        this.stopPulse()

        const entityParts: FurniEntityPart[] = []

        for (const part of this.parts) {
            part.getTile()?.removePart(part)
            part.destroy()
        }
        const scale = this.getFurniScale()
        const height = tile?.getTotalHeight() || 0
        const blocks = dir.blocks
        if (blocks) {
            for (const block of blocks) {
                let partTile
                if (tile) {
                    const aX = tile.getMapX() + block.x
                    const aY = tile.getMapY() + block.y

                    partTile = tile.getFloor().getTile(aX, aY)
                    if (!partTile) {
                        console.log(
                            'Could not get tile for furniture #' + this.getItem().itemId + ' (' + aX + ', ' + aY + ')'
                        )
                    }
                }

                // order of block sprites is important for applying anims! don't sort by z order here
                const part = new FurniEntityPart(this, partTile, block, height)

                if (block.name === 'avatar') {
                    this.controller.room.removeEntityByRef(`furni-${this.item.itemId}`)
                    const outfit = this.item.customData?.outfit ?? {
                        headId: 2,
                        eyeId: 1,
                        faceId: 1,
                        hairId: '50fac60406',
                        hatId: 'f7bb8677ce',
                        shirtId: 'f284e0884a',
                        pantsId: 'cd6a175034',
                        shoesId: 'a8718691d0',
                        skinTint: 17,
                        eyeTint: 1,
                        hairTint: 8
                    }
                    const entity = new AvatarEntity(
                        this.controller.room,
                        `furni-${this.item.itemId}`,
                        outfit,
                        0,
                        this.item.rotation,
                        {
                            move: false,
                            wave: false,
                            steer: false,
                            sit: false,
                            dance: false,
                            carry: false,
                            eat: false
                        },
                        'Mannequin',
                        [],
                        [],
                        ''
                    )
                    this.controller.room.addEntity(entity)
                    const managedStatue = new ManagedSingleContainer(entity.getVisual(), 0)
                    part.addChild(managedStatue)
                } else {
                    // order of block sprites is important for applying anims! don't sort by z order here
                    const sprites = block.sprites

                    for (const sprite of sprites) {
                        const index = sprites.indexOf(sprite)
                        // if you're getting missing texture errors, make sure you arent trying to add multiple of the same
                        // item async at the same time, the texture might be in progress loading
                        let spr: ManagedSprite
                        if (!sprite.gfx || sprite.gfx === 'null') {
                            console.log('missing gfx', sprite)
                            spr = ManagedSprite.from(Texture.EMPTY, index)
                        } else {
                            spr = ManagedSprite.from(sprite.gfx, index)

                            ClientHelpers.genHitmap(spr.texture, 125)
                        }

                        if (sprite.bgc && sprite.bgc !== 'FFFFFF' && sprite.bgc !== '#FFFFFF') {
                            spr.tint = sprite.bgc
                        }
                        if (typeof sprite.bld === 'number') {
                            spr.selfAlpha = sprite.bld / 100
                            spr.alpha = sprite.bld / 100
                            console.log('>> part has blend/alpha: ' + spr.selfAlpha)
                        }

                        const sprX = sprite.x ? sprite.x * scale : 0
                        const sprY = sprite.y ? sprite.y * scale : 0

                        spr.eventMode = 'static'
                        spr.setPosition(sprX, sprY)
                        if (sprite.z !== undefined) {
                            spr.setZIndex(sprite.z)
                        }
                        spr.scale.set(scale)

                        if (sprite.flp) {
                            spr.scale.x *= -1
                        }

                        part.addChild(spr)
                    }
                }
                if (hide) {
                    part.alpha = 0
                } else {
                    console.log('wholeAlpha set to ' + this.wholeAlpha)
                    part.alpha = this.wholeAlpha
                }
                part.setParent(this.controller.room.sprites)

                part.addEventListener('pointerdown', this.furniDown)
                part.addEventListener('pointerup', this.furniClick)
                part.addEventListener('pointerover', this.furniOver)

                entityParts.push(part)
            }
        }
        this.parts = entityParts
        if (!doNotAnimate) {
            this.reinitAnimScore(false)
        }

        this.controller.room.sprites.sortChildren()

        if (wasPulsing) {
            this.pulseOverlay()
        }
    }

    updatePartTiles(finalPlace: boolean, stackFloor = false): void {
        const dir = this.furnimap.getDirection(this.item.rotation) || this.furnimap.getDirection(3)

        if (!dir) {
            console.error('ERROR! DIRECTION ' + this.item.rotation + ' NOT FOUND ON ITEM ID ' + this.item.defUid)
            return
        }

        const rootTile = this.rootTile
        if (!rootTile) {
            console.log('cant update tile parts for entity on missing tile')
            return
        }

        const coord = {
            x: rootTile.getMapX(),
            y: rootTile.getMapY()
        }

        for (const p of this.parts) {
            p.placing = !finalPlace
        }

        let atHeight = 0

        for (const index in rootTile.itemParts) {
            if (+index < this.item.index && rootTile.itemParts[+index].furni !== this) {
                const part = rootTile.itemParts[+index]
                atHeight += part.getHeight()
            }
        }

        for (const part of this.parts) {
            const aX = coord.x + part.getBlockX()
            const aY = coord.y + part.getBlockY()
            const newPartTile = rootTile.getFloor().getTile(aX, aY)
            if (!newPartTile) {
                console.log(`Tile at ${aX},${aY} not found`)
                continue
            }
            part.setTile(newPartTile, stackFloor, atHeight)
        }

        for (const p of this.parts) {
            p.placing = false
        }
    }

    doubleClick = (e: FederatedEvent): void => {
        if ((Client.shared.roomViewer as WalkableRoomViewer)?.furniController.isEnabled()) {
            if (this.item.behavior === EFurniBehavior.Proximity) {
                ;(Client.shared.roomViewer as WalkableRoomViewer)?.furniController.openDarkRideConfig()
                return
            }
        }
        console.log('Double click furni')
        Client.shared.serverBroker.send('trigger_furni', {
            itemId: this.item.itemId
        })
    }

    furniDown = (e: FederatedPointerEvent): void => {
        console.log('furniDown')
        if (e && e.data.button === 2) {
            e.stopPropagation()
            const menu = new ContextMenu()
            const items: any[] = []

            if (this.type === 'furni') {
                if (this.hasSittable() || this.hasWalkable()) {
                    items.push(this.getTile())
                }
                items.push(this, ...this.getTile().getEntities())
            }
            if (!items.length) {
                items.push(this)
            }
            menu.populateFor(...items)
            menu.position.copyFrom(Client.shared.stage.toLocal(e.global))
            if (menu.y + menu.height > Constants.SIZE[1] - 27) {
                menu.y -= menu.height
            }
            if (menu.x + menu.width > Constants.SIZE[0]) {
                menu.x -= menu.width
            }
            Client.shared.stage.addChild(new AlertView(menu, 0, true))
        }
    }

    furniClick = (e: FederatedPointerEvent): void => {
        console.log('furniClick')
        if (this.tapTimer) {
            window.clearTimeout(this.tapTimer)
            this.tapTimer = null
            this.doubleClick(e)
            return
        }

        const partSprite = (e?.currentTarget || e?.target) as ManagedSprite
        if (!partSprite) {
            return
        }
        if (this.controller.isEnabled()) {
            if (e?.ctrlKey) {
                this.getTile().getHighestPart()?.furni?.removeFromRoom()
                return
            }
        }
        if (this.type === 'poster') {
            if (this.controller.isEnabled()) {
                this.controller.setActiveFurniEntity(this)
            }
        } else {
            const part = partSprite.manager as FurniEntityPart

            let controllerTile: TileEntity

            if (this.controller.isEnabled()) {
                controllerTile = part.getTile()
                if (this.controller.isMoving()) {
                    console.log('placing')
                    this.controller.placeFurni(controllerTile)
                } else {
                    this.controller.setActiveFurniEntity(this.getTile().getHighestPart()?.furni || this)
                }
            } else {
                // if the part clicked is not walkable/sittable, user probably meant to click a part that is
                if (part.getBlock().sit || part.getBlock().wlk) {
                    controllerTile = part.getTile()
                } else {
                    const meantPart = this.parts.find((p) => p.getBlock().sit || p.getBlock().wlk)
                    if (meantPart) {
                        controllerTile = meantPart.getTile()
                    }
                }

                controllerTile?.emit('pointerup')
            }
        }

        this.tapTimer = window.setTimeout(() => {
            this.tapTimer = null
        }, 300) // time for double click detection
    }

    hasWalkable(): boolean {
        return this.parts.some((p) => p.getBlock().wlk)
    }

    hasSittable(): boolean {
        return this.parts.some((p) => p.getBlock().sit)
    }

    furniOver = (e: FederatedEvent): void => {
        const part = (e?.currentTarget || e?.target) as ManagedSprite

        if (!part) {
            return
        }
        console.log('furni over')
        const tile = (part.manager as FurniEntityPart).getTile()

        if (this.controller.isMoving()) {
            this.controller.onTileOver(tile)
        }
    }

    stopPulse(): void {
        this.pulsing?.kill()
        this.pulsing = undefined
        for (const p of this.parts) {
            p.setAlpha(this.wholeAlpha)
        }
    }

    pulseOverlay(): void {
        this.pulsing = gsap.fromTo(
            this.parts,
            { alpha: this.wholeAlpha },
            {
                alpha: Math.max(0, this.wholeAlpha - 0.5),
                duration: 0.5,
                repeat: -1,
                yoyoEase: true
            }
        )
    }

    isTrack(): boolean {
        return Client.shared.furniMapMgr.isTrack(this.item.defUid)
    }

    teardown(): void {
        this.stopPulse()
        this.animScore?.teardown()
        this.animScore = null
        SoundManager.shared.release(this.playingSound)
        this.playingSound = null

        if (this.parts) {
            for (const part of this.parts) {
                part.getTile()?.removePart(part)
                part.destroy({
                    children: true,
                    texture: false,
                    baseTexture: false
                })
            }

            this.parts = []
        }
    }
}
