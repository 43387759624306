import { Pooler } from '@vmk-legacy/render-utils'
import { Container } from 'pixi.js'

export class BasicBox extends Container {
    constructor(
        protected innerWidth: number,
        innerHeight: number
    ) {
        super()
        const topLeft = Pooler.newSprite('basic.top.left')
        const topMiddle = Pooler.newSprite('basic.top.middle')
        const topRight = Pooler.newSprite('basic.top.right')
        const rightMiddle = Pooler.newSprite('basic.right.middle')
        const bottomRight = Pooler.newSprite('basic.bottom.right')
        const bottomMiddle = Pooler.newSprite('basic.bottom.middle')
        const bottomLeft = Pooler.newSprite('basic.bottom.left')
        const leftMiddle = Pooler.newSprite('basic.left.middle')
        const middle = Pooler.newSprite('basic.middle.middle')

        this.addChild(topLeft)
        topMiddle.x = topLeft.width
        topMiddle.width = innerWidth
        this.addChild(topMiddle)
        topRight.x = topMiddle.x + topMiddle.width
        this.addChild(topRight)
        leftMiddle.y = topLeft.height
        leftMiddle.height = innerHeight
        this.addChild(leftMiddle)
        middle.x = topLeft.width
        middle.y = topLeft.height
        middle.width = innerWidth
        middle.height = innerHeight
        this.addChild(middle)
        rightMiddle.x = middle.x + middle.width
        rightMiddle.y = middle.y
        rightMiddle.height = innerHeight
        this.addChild(rightMiddle)
        bottomLeft.y = topLeft.height + middle.height
        this.addChild(bottomLeft)
        bottomMiddle.x = bottomLeft.width
        bottomMiddle.y = bottomLeft.y
        bottomMiddle.width = middle.width
        this.addChild(bottomMiddle)
        bottomRight.x = rightMiddle.x
        bottomRight.y = rightMiddle.y + rightMiddle.height
        this.addChild(bottomRight)
        this.cacheAsBitmap = true
    }
}
