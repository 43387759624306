/*
 * View.ts
 * VMK Legacy Client
 */

import { Pooler } from '@vmk-legacy/render-utils'
import type { ColorSource, Sprite } from 'pixi.js'
import { Color, Container, Graphics, Texture } from 'pixi.js'

export class View {
    private _rootMostContainer?: Container

    body?: () => View
    bodyEvaluated?: View

    view: Container

    backgroundView?: Sprite

    gestures: Record<string, () => void> = {}

    borderRadius?: number
    roundedCorners?: ('topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight')[]
    x?: number
    y?: number

    background(color: ColorSource): this {
        console.log('>> background color ' + color)
        const colorSprite = Pooler.newSprite(Texture.WHITE)
        colorSprite.tint = new Color(color)
        this.backgroundView = colorSprite

        return this
    }

    // Creates the view without considering sizing.
    loadView(): void {
        if (this.body) {
            this.bodyEvaluated = this.body()
        }

        if (!this.bodyEvaluated) {
            return
        }
        this.bodyEvaluated.loadView()

        this.view = new Container()
        this.view.addChild(this.bodyEvaluated.view)

        if (this.x && this.y) {
            this.view.position.set(this.x, this.y)
        }
    }

    sizeThatFits(proposed: { width?: number; height?: number }): {
        width?: number
        height?: number
    } {
        return proposed
    }

    sizeToFit(proposed: { width?: number; height?: number }): void {
        this.bodyEvaluated?.sizeToFit(this.sizeThatFits(proposed))
    }

    layoutSubviews(): void {
        this.bodyEvaluated?.layoutSubviews()
        this.bodyEvaluated?.sizeToFit(this.getActualSize())

        for (const gesture in this.gestures) {
            this.view.eventMode = 'static'
            this.view.cursor = 'pointer'
            this.view.removeAllListeners(gesture)
            this.view.on(gesture, this.gestures[gesture])
        }
    }

    didLayoutSubviews() {
        const size = this.getActualSize()

        if (this.backgroundView) {
            this.backgroundView.width = size.width
            this.backgroundView.height = size.height
            this.view.addChildAt(this.backgroundView, 0)
        }
        if (this.borderRadius) {
            const mask = new Graphics()
                .beginFill(0x000000)
                .drawRoundedRect(0, 0, size.width, size.height, this.borderRadius)

            if (!this.roundedCorners.includes('topLeft')) {
                mask.drawRect(0, 0, this.borderRadius, this.borderRadius)
            }
            if (!this.roundedCorners.includes('topRight')) {
                mask.drawRect(size.width - this.borderRadius, 0, this.borderRadius, this.borderRadius)
            }
            if (!this.roundedCorners.includes('bottomLeft')) {
                mask.drawRect(0, size.height - this.borderRadius, this.borderRadius, this.borderRadius)
            }
            if (!this.roundedCorners.includes('bottomRight')) {
                mask.drawRect(
                    size.width - this.borderRadius,
                    size.height - this.borderRadius,
                    this.borderRadius,
                    this.borderRadius
                )
            }

            this.view.mask = mask.endFill()
            this.view.addChild(this.view.mask)
        }
        this.bodyEvaluated?.didLayoutSubviews()
    }

    rerender() {
        this.bodyEvaluated.view.destroy()
        this.bodyEvaluated = this.body()
        this.bodyEvaluated.loadView()
        this.view.addChild(this.bodyEvaluated.view)
        this.sizeToFit({
            width: this._rootMostContainer.width,
            height: this._rootMostContainer.height
        })
        this.layoutSubviews()
        this.didLayoutSubviews()
    }

    putIn(container: Container): this {
        this._rootMostContainer = container
        this.loadView()
        this.sizeToFit({
            width: container.width,
            height: container.height
        })
        this.layoutSubviews()
        this.didLayoutSubviews()

        container.addChild(this.view)

        return this
    }

    offset(x: number, y: number): View {
        this.x = x
        this.y = y

        return this
    }

    frame({
        width,
        height,
        maxWidth,
        maxHeight
    }: {
        width?: number
        height?: number
        maxWidth?: number
        maxHeight?: number
    }): View {
        const view = new Frame()
        view.bodyEvaluated = this

        if (width !== undefined) {
            view.frameWidth = width
        }
        if (height !== undefined) {
            view.frameHeight = height
        }
        if (maxWidth !== undefined) {
            view.frameMaxWidth = maxWidth
        }
        if (maxHeight !== undefined) {
            view.frameMaxHeight = maxHeight
        }

        return view
    }

    padding(
        sides: ('horizontal' | 'vertical' | 'leading' | 'trailing' | 'top' | 'bottom')[] | number,
        value?: number
    ): View {
        const view = new Padding()
        view.bodyEvaluated = this
        if (typeof sides === 'number') {
            view.value = sides
            view.sides = ['horizontal', 'vertical']
        } else {
            view.value = value
            view.sides = sides
        }
        return view
    }

    cornerRadius(corners: string[] | number, radius?: number): View {
        if (typeof corners === 'number') {
            this.borderRadius = corners
            this.roundedCorners = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight']
        } else {
            this.borderRadius = radius
            this.roundedCorners = corners
        }

        return this
    }

    foregroundColor(color: number): View {
        this.bodyEvaluated?.foregroundColor(color)

        return this
    }

    getActualSize() {
        return {
            width: this.view.width,
            height: this.view.height
        }
    }

    onTapGesture(handler: () => void): this {
        this.gestures['pointertap'] = handler

        return this
    }

    when(condition: boolean): this | undefined {
        if (condition) {
            return this
        }

        return undefined
    }

    remove() {
        this.view?.destroy({ children: true })
    }
}

export class Frame extends View {
    frameWidth?: number
    frameHeight?: number

    frameMaxWidth?: number
    frameMaxHeight?: number

    override sizeThatFits(proposed: { width?: number; height?: number }): {
        width?: number
        height?: number
    } {
        const size = super.sizeThatFits(proposed)

        size.width = this.frameWidth ?? size.width
        if (this.frameMaxWidth !== undefined && size.width > this.frameMaxWidth) {
            size.width = this.frameMaxWidth
        }
        size.height = this.frameHeight ?? size.height
        if (this.frameMaxHeight !== undefined && size.height > this.frameMaxHeight) {
            size.height = this.frameMaxHeight
        }
        return size
    }

    override getActualSize(): { width: any; height: any } {
        const size = super.getActualSize()

        if (this.frameWidth !== undefined) {
            size.width = this.frameWidth
        }
        if (this.frameHeight !== undefined) {
            size.height = this.frameHeight
        }

        return size
    }
}

export class Padding extends View {
    value: number
    sides: ('horizontal' | 'vertical' | 'leading' | 'trailing' | 'top' | 'bottom')[]

    get leading(): number {
        if (this.sides.includes('horizontal') || this.sides.includes('leading')) {
            return this.value
        }
        return 0
    }

    get trailing(): number {
        if (this.sides.includes('horizontal') || this.sides.includes('trailing')) {
            return this.value
        }
        return 0
    }

    get top(): number {
        if (this.sides.includes('vertical') || this.sides.includes('top')) {
            return this.value
        }
        return 0
    }

    get bottom(): number {
        if (this.sides.includes('vertical') || this.sides.includes('bottom')) {
            return this.value
        }
        return 0
    }

    override sizeThatFits(proposed: { width?: number; height?: number }): {
        width?: number
        height?: number
    } {
        const size = proposed
        if (size.width !== undefined) {
            size.width -= this.leading + this.trailing
        }
        if (size.height !== undefined) {
            size.height -= this.top + this.bottom
        }
        return this.bodyEvaluated.sizeThatFits(size)
    }

    override loadView(): void {
        super.loadView()

        this.bodyEvaluated.view.position.set(this.leading, this.top)
    }

    override getActualSize() {
        return {
            width: this.bodyEvaluated.getActualSize().width + this.leading + this.trailing,
            height: this.bodyEvaluated.getActualSize().height + this.top + this.bottom
        }
    }
}
