import type { IFurniMapBlock } from '@vmk-legacy/common-ts'
import { FakeContainer } from '../FakeContainer.js'
import type { FurniEntity } from './FurniEntity.js'
import type { TileEntity } from './TileEntity.js'

export class FurniEntityPart extends FakeContainer {
    private block: IFurniMapBlock

    private tile?: TileEntity

    placing = false
    stackFloor = false
    height: number

    constructor(
        readonly furni: FurniEntity,
        tile?: TileEntity,
        block: IFurniMapBlock,
        atHeight?: number
    ) {
        super()

        this.block = block

        if (tile) {
            this.setTile(tile, false, atHeight)
        }
    }

    setTile(tile: TileEntity, stackFloor = false, height?: number): void {
        this.stackFloor = stackFloor
        this.height = height

        this.tile?.removePart(this)
        this.tile = tile

        this.updatePosition()

        tile?.addPart(this)
    }

    updatePosition(): void {
        if (this.tile) {
            this.position.set(
                this.tile.x,
                this.tile.y -
                    (this.stackFloor ? 0 : typeof this.height === 'number' ? this.height : this.tile.getTotalHeight())
            )
            this.zIndex = this.tile.initialZ + (this.stackFloor ? -1 : this.furni.getItem().index * 100)
        }
    }

    getFurni(): FurniEntity {
        return this.furni
    }

    getBlock(): IFurniMapBlock {
        return this.block
    }

    getHeight(): number {
        return this.block.hgt * this.furni.getFurniScale()
    }

    getTile(): TileEntity | undefined {
        return this.tile
    }

    getBlockX(): number {
        return this.block.x
    }

    getBlockY(): number {
        return this.block.y
    }
}
