import { Minigame } from '@vmk-legacy/common-ts'
import { Client } from '../../Client.js'
import { MinigameDelegate } from '../MinigameDelegate.js'
import { MixerManager } from './MixerManager.js'
import { MusicMixer } from './MusicMixer.js'

export class MusicMixerDelegate extends MinigameDelegate {
    window: MusicMixer
    manager: MixerManager

    constructor(readonly codename: Minigame.StreetMixer | Minigame.MonoMixer) {
        super()
    }

    get mixerId(): string {
        return this.codename === Minigame.MonoMixer ? 'mono' : 'street'
    }

    protected async loadGame(): Promise<void> {
        await Client.shared.assetLoader.loadCasts(
            ['minigame/music_mixer', 'minigame/music_mixer_samples_' + this.mixerId],
            { retains: this.provider }
        )
    }

    protected beginGame(): void {
        this.window = new MusicMixer(this.mixerId, this)
        Client.shared.userInterface.register(this.window)

        Client.shared.userInterface.hideToolbars()
    }

    showManager(): void {
        this.manager = new MixerManager(this)
        Client.shared.userInterface.register(this.manager, true)
    }

    override async teardown(): Promise<void> {
        Client.shared.serverBroker.send('mg_end', [this.codename, 'e', 0])

        Client.shared.userInterface.removeWindow(this.window)

        await super.teardown()
    }
}
