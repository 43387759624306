import type { EAvatarDir } from '@vmk-legacy/common-ts'
import type { DisplayObject } from 'pixi.js'
import { Point, Polygon } from 'pixi.js'

export class Plane3D {
    protected origin: Point3D

    constructor(
        x: number,
        y: number,
        z: number,
        protected xd: number,
        protected yd: number,
        protected zd: number,
        protected dir: EAvatarDir.SouthEast | EAvatarDir.SouthWest
    ) {
        this.origin = new Point3D(x, y, z)
    }

    setWidth(width: number): void {
        if (this.dir === 3) {
            this.yd = width
        } else {
            this.xd = width
        }
    }

    setHeight(height: number): void {
        this.zd = height
    }

    toPolygon(xd = this.xd, yd = this.yd, zd = this.zd): Polygon {
        let points: Point3D[]
        if (this.dir === 3) {
            points = [
                this.origin,
                this.origin.plus(xd, 0, zd),
                this.origin.plus(xd, yd, zd),
                this.origin.plus(0, yd, 0)
            ]
        } else {
            points = [
                this.origin.plus(xd, yd, zd),
                this.origin.plus(xd, 0, 0),
                this.origin.plus(0, yd, 0),
                this.origin.plus(0, 0, zd)
            ]
        }

        return new Polygon(points.map((p) => p.toPoint()))
    }

    crop(horiz: number, vert: number, width: number, height: number): Plane3D {
        const plane = new Plane3D(
            (this.dir === 3 ? 0 : horiz) + this.origin.get3DX(),
            (this.dir === 3 ? horiz : 0) + this.origin.get3DY(),
            this.origin.get3DZ() + vert,
            this.dir === 3 ? this.xd : width,
            this.dir === 3 ? width : this.yd,
            height,
            this.dir
        )

        return plane
    }
}

export class Point3D {
    constructor(
        protected x: number,
        protected y: number,
        protected z: number
    ) {}

    get3DX(): number {
        return this.x
    }

    get3DY(): number {
        return this.y
    }

    get3DZ(): number {
        return this.z
    }

    set3DX(val: number): void {
        this.x = val
    }

    set3DY(val: number): void {
        this.y = val
    }

    set3DZ(val: number): void {
        this.z = val
    }

    get screenX(): number {
        return this.x - this.y
    }

    get screenY(): number {
        return (this.x + this.y) / 2 - this.z
    }

    get screenStack(): number {
        return 10 * this.x + 10 * this.y + 5 * this.z
    }

    applyTo(object: DisplayObject): void {
        object.position.set(this.screenX, this.screenY)
        object.zIndex = this.screenStack
    }

    addTo(object: DisplayObject): void {
        object.x += this.screenX
        object.y += this.screenY
    }

    plus(x: number, y: number, z: number): Point3D {
        return new Point3D(this.x + x, this.y + y, this.z + z)
    }

    minus(x: number, y: number, z: number): Point3D {
        return new Point3D(this.x - x, this.y - y, this.z - z)
    }

    toPoint(): Point {
        return new Point(this.screenX, this.screenY)
    }

    toMap(tileSize: number): [number, number] {
        return [Math.round(this.x / tileSize), Math.round(this.y / tileSize)]
    }

    static apply(object: DisplayObject, x: number, y: number, z: number): void {
        new Point3D(x, y, z).applyTo(object)
    }
}
