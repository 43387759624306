import { Pooler } from '@vmk-legacy/render-utils'
import { Sprite } from 'pixi.js'

export class Separator extends Sprite {
    constructor(width: number) {
        super()
        const bg = Pooler.newSprite('text.underline.tile')
        bg.width = width
        this.addChild(bg)
    }
}
