export class StringUtil {
    static addNumberCommas(x: number): string | null {
        if (x === null) {
            return null
        }

        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    static getBoolean01(str: string | number): boolean {
        return String(str) !== '0'
    }

    /**
     * Returns a number array from a space-separated string arr
     * @param str A string of numbers separated by spaces
     */
    static getNumArray(str: string): number[] {
        const strArr: string[] = String(str).split(' ')
        const numArr: number[] = []

        for (let i = 0; i < strArr.length; i++) {
            numArr.push(+strArr[i])
        }

        return numArr
    }

    //https://gist.github.com/donmccurdy/6d073ce2c6f3951312dfa45da14a420f
    static wildcardToRegExp(s) {
        return new RegExp('^' + s.split(/\*+/).map(this.regExpEscape).join('.*') + '$')
    }

    /**
     * RegExp-escapes all characters in the given string.
     */
    static regExpEscape(s) {
        return s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    }
}
