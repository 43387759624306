import { TextureCache } from '@pixi/utils'
// styles loaded here for font-face observing ability
import './scss/styles.scss'
import * as Sentry from '@sentry/browser'
import { SoundManager } from '@vmk-legacy/render-utils'
import * as PIXI from 'pixi.js'
import posthog from 'posthog-js'
import { Client } from './Client.js'
import Config from './Config.js'

try {
    const startArgs = new URLSearchParams(decodeURIComponent(location.search).replace('web+vmkl:', ''))

    if (startArgs.has('code')) {
        window.opener.postMessage(
            {
                type: 'discord_code',
                params: String(location.search)
            },
            '*'
        )
        window.close()
    } else {
        if (location.search) {
            let replaced = location.pathname
            if (location.hash) {
                replaced += `#${location.hash}`
            }
            window.history.replaceState({}, '', replaced)
        }

        if (Config.environment.which === 'production') {
            console.error(
                'Caution! If someone told you to type something in here, they could be trying to steal your account! Using these tools to manipulate the game will get you banned. If you have a concern, please bring it to staff instead of tinkering around.'
            )
        }

        if (process.env['SENTRY_DSN_URL']) {
            Sentry.init({
                dsn: process.env['SENTRY_DSN_URL'],
                maxBreadcrumbs: 50,
                sampleRate: 0.25
            })
        }

        try {
            navigator.wakeLock
                ?.request('screen')
                .then((wakeLock) => {
                    wakeLock.addEventListener('release', () => {
                        console.log('Wake Lock was released')
                    })
                    console.log('Wake Lock is active')
                })
                .catch((e) => {
                    console.log('Unable to lock wake (1)', e)
                })
        } catch (err) {
            console.error('Unable to lock wake (2)', err)
        }

        // unless focused, force reload the page every 12 hours
        setInterval(
            () => {
                if (!document.hasFocus()) {
                    location.reload()
                }
            },
            1000 * 60 * 60 * 12
        )

        const client = Client.instantiate()

        // support triggering auth from parent window when in iframe
        // such as a Discord embedded app activity
        window.addEventListener('vmkl-auth', (e: CustomEvent) => {
            const token = e.detail
            if (typeof token !== 'string') {
                return
            }
            Client.shared.api.apiToken = token

            if (client.serverBroker.getLoginWindow()) {
                // connection gets attempted before showing login window
                // if login window showing, trigger connect
                client.serverBroker.authConnect(token)
            }
        })

        if (Config.environment.which !== 'production') {
            console.log(`NODE_ENV = "${Config.environment.which}"`)
            window._vmkl_client = client
            window._vmkl_TextureCache = TextureCache
            window._vmkl_SoundManager = SoundManager

            window.__PIXI_DEVTOOLS__ = {
                pixi: PIXI,
                app: client
            }
        }

        if (process.env['POSTHOG_KEY'] && process.env['POSTHOG_HOST'] && Config.environment.which === 'production') {
            posthog.init(process.env['POSTHOG_KEY'], {
                autocapture: false,
                api_host: process.env['POSTHOG_HOST'],
                person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
            })
        }

        if (Config.environment.which === 'production' || !startArgs.has('test')) {
            client.initialize(startArgs).catch((error) => {
                Sentry.captureException(error)
                alert(
                    `Something went wrong while initializing VMK Legacy. Please make sure your browser is up to date. Try another browser or report this issue if it persists. (Error code: 1000)\n${String(error)}`
                )
            })
        }
    }
} catch (error) {
    Sentry.captureException(error)
    alert(
        `Something went wrong while initializing VMK Legacy. Please refresh and try again, or report this issue if it persists. (Error code: 565)\n${String(error)}`
    )
}
