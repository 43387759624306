import { EventEmitter } from '@pixi/utils'

export abstract class ServerTransportAdapting extends EventEmitter {
    static readonly Constants = {
        established: 'established',
        dropped: 'dropped',
        kicked: 'kicked',
        connectError: 'connectError',
        event: 'event'
    }

    /**
     * Called every time an event is sent.
     */
    didSendEvent?: (event: string, payload: any, awaitingAck: boolean) => void
    /**
     * Called every time an event is received.
     */
    didReceiveEvent?: (event: string, payload: any, isAckResponse: boolean) => void

    /**
     * Called to open a connection to the server.
     */
    abstract initConnection(): void

    /**
     * Returns whether there is currently an active connection.
     */
    abstract isConnected(): boolean

    /**
     * Destroy the provider, it cannot be used again.
     */
    abstract teardown(): void

    /**
     * Send an event to the server.
     * @param event
     * @param payload
     */
    send(event: string, payload?: any): void {
        this.didSendEvent?.(event, payload, false)
    }

    /**
     * Send an event to the server, expecting an acknowledgement.
     * @param event
     * @param payload
     */
    async sendAck(event: string, payload?: any): Promise<any> {
        this.didSendEvent?.(event, payload, true)
    }

    /**
     * Called when an acknowledgement is received from server for an event we previously sent.
     * @param id
     * @param payload
     */
    abstract handleAck(id: number, payload?: any): void
}
