import { EItemType } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { getText } from '../../../../assets/ExternalConfigManager.js'
import { Client } from '../../../../Client.js'
import type { ItemStack } from '../../../../data/ItemStack.js'
import { Fonts } from '../../../../Fonts.js'
import { PrefTemp } from '../../../../SessionPrefs.js'
import { Helpers } from '../../../../util/Helpers.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import type { ImageButton } from '../../../buttons/ImageButton.js'
import type { ThumbnailBox } from '../../../containers/ThumbnailBox.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { Separator } from '../../Separator.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { InventoryWindow } from '../InventoryWindow.js'

export class ClothingView extends UIWindowView<InventoryWindow> {
    inventoryThumbnails: ThumbnailBox[]

    private pageIndicatorText: BitmapText
    private itemName: BitmapText

    pageIndex = 0

    selectedItem: ItemStack = null

    private pageTurnL: ImageButton
    private pageTurnR: ImageButton

    numPages = 1

    private itemPreviewImage: Container & { previewScale?: number }

    private sellBtn: BitmapTextButton
    private wearBtn: BitmapTextButton

    private ptb: PagedThumbnailBox

    tryFocusSearch(e) {
        this.ptb.searchBar?.forceFocus(e)
    }

    update() {
        const clothing = [...Client.shared.selfRecord.getInventory().values()].filter(
            (i) => i.defType === EItemType.Clothing
        )

        clothing.sort((a, b) => {
            const nameA = a.getName().toLowerCase()
            const nameB = b.getName().toLowerCase()

            if (nameA === nameB) {
                return 0
            }

            return nameA > nameB ? 1 : -1
        })

        this.ptb.setItems(...clothing)
    }

    constructor() {
        super()

        this.inventoryThumbnails = []

        this.itemName = new BitmapText('Select an item to learn more', {
            ...Fonts.Foxley_16
        })
        this.itemName.x = Math.round((374 - this.itemName.textWidth) / 2)
        this.addChild(this.itemName)

        const sep = new Separator(374)
        sep.y = 15
        this.addChild(sep)

        const previewBox = Pooler.newSprite('previewbox')
        previewBox.scale.set(0.5)
        previewBox.x = 271
        previewBox.y = 20
        this.addChild(previewBox)

        this.sellBtn = new BitmapTextButton(104, 'SELL', 'a')
        this.sellBtn.x = 271
        this.sellBtn.y = 152
        this.sellBtn.disable()
        this.addChild(this.sellBtn)
        this.sellBtn.addEventListener('pointertap', async () => {
            this.sellBtn.disable()

            if (!this.selectedItem) {
                return
            }

            const price = (await Client.shared.serverBroker.sendAck('sell_item', {
                defUid: this.selectedItem.defUid
            })) as number | null

            if (!price) {
                this.sellBtn.enable()
                return
            }
            const confirmed = await Client.shared.helpers.confirm(
                'Are you sure you want to sell the item? You will receive ' +
                    price.toLocaleString() +
                    ' credits but lose the item permanently.'
            )

            if (confirmed) {
                await Client.shared.serverBroker.sendAck('sell_item', {
                    defUid: this.selectedItem.defUid,
                    price
                })
            } else {
                this.sellBtn.enable()
            }
        })

        this.wearBtn = new BitmapTextButton(104, 'WEAR', 'a')
        this.wearBtn.x = 271
        this.wearBtn.y = 176
        this.addChild(this.wearBtn)
        this.wearBtn.disable()
        this.wearBtn.addEventListener('pointertap', async () => {
            if (!this.selectedItem) {
                return
            }

            this.wearBtn.disable()

            await Client.shared.serverBroker.send('wear', this.selectedItem.defUid)

            this.wearBtn.enable()
        })

        this.ptb = new PagedThumbnailBox({
            search: true,
            rows: 4,
            cols: 6,
            items: [],
            spacing: 3,
            onItemTap: (item: ItemStack, preview: Container) => {
                this.onItemTap(item, preview)
            },
            onItemDoubleTap: (item: ItemStack) => {
                Client.shared.serverBroker.send('wear', item.defUid)
            },
            pageTurnSpacing: 80,
            pageTurnFormat: PagedThumbnailBox.FORMAT_C,
            dimUntradeables: false,
            initialPage: Client.shared.prefs.getTemp(PrefTemp.ClothingPage)
        })
        this.ptb.y = 21
        this.addChild(this.ptb)

        this.ptb.on('change', () => {
            Client.shared.prefs.setTemp(PrefTemp.ClothingPage, this.ptb.getPageNumber())
        })

        const controller = this.ptb.getControllerContainer()
        controller.y = 232
        this.addChild(controller)
    }

    private onItemTap(item: ItemStack, container: Container): void {
        this.ptb.setSelected(item)
        this.selectItem(item, container)
    }

    setPreviewItem(preview?: Container): void {
        if (this.itemPreviewImage) {
            this.removeChild(this.itemPreviewImage)
        }

        if (preview) {
            this.itemPreviewImage = preview
            this.itemPreviewImage.cacheAsBitmap = true
            this.itemPreviewImage.x = 273
            this.itemPreviewImage.y = 23

            Helpers.scalePreviewImage(this.itemPreviewImage, 100, 125)

            this.addChildAt(this.itemPreviewImage, this.getChildIndex(this.ptb))
        }
    }

    deselectItem(): void {
        if (this.selectedItem) {
            this.selectedItem = null
            this.sellBtn.disable()
            this.wearBtn.disable()
            this.setItemName('Select an item to learn more')
            this.setPreviewItem(undefined)
        }
    }

    selectItem(item: ItemStack, preview: Container): void {
        const itemName = getText('item.' + item.defUid)
        this.selectedItem = item
        this.sellBtn.enable()
        this.wearBtn.enable()
        this.setItemName(itemName)
        this.setPreviewItem(preview)
    }

    setItemName(name: string): void {
        if (this.itemName) {
            this.itemName.text = name
            this.itemName.x = Math.round((374 - this.itemName.textWidth) / 2)
        }
    }
}
