import type { IItemFurniMap, ItemDefUid } from '@vmk-legacy/common-ts'
import { EAvatarDir, EItemType, EPerm } from '@vmk-legacy/common-ts'
import type { FurniMapItem } from '@vmk-legacy/render-utils'
import { Pooler, SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { Sprite } from 'pixi.js'
import { Point } from 'pixi.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import type { ItemStack } from '../../data/ItemStack.js'
import { EWindow } from '../../enums.js'
import type { InventoryWindow } from '../../ui/windows/inventory/InventoryWindow.js'
import { ItemInfoWindow } from '../../ui/windows/item_info/ItemInfoWindow.js'
import { DarkRideConfigWindow } from '../../ui/windows/rooms/DarkRideConfigWindow'
import { RoomInfoBox } from '../../ui/windows/RoomInfoBox.js'
import { AvatarVisual } from '../entities/AvatarVisual.js'
import { RoomItem } from '../RoomItem.js'
import { FurniAppearAnim } from './FurniAppearAnim'
import { FurniSelectionPopup } from './FurniSelectionPopup.js'
import { FurniEntity } from './types/FurniEntity.js'
import type { TileEntity } from './types/TileEntity.js'
import type { Wall } from './types/Wall.js'
import { WallPoster } from './types/WallPoster.js'
import type { WalkableRoomViewer } from './WalkableRoomViewer.js'

class FurniController {
    private selectionPopup?: FurniSelectionPopup

    private furniMoveMode = false
    private furniModeEnabled = false
    entities: FurniEntity[] = []
    activeFurni?: FurniEntity | WallPoster | AvatarVisual = undefined
    private rapidPlacing = false
    lastValid = true
    sounds: Map<string, AudioBuffer> = new Map()
    private phantom = false
    private placingIndex = -1
    private darkRideConfig?: DarkRideConfigWindow

    constructor(readonly room: WalkableRoomViewer) {}

    init(): void {}

    openDarkRideConfig(): void {
        if (!this.activeFurni) {
            return
        }
        if (this.darkRideConfig && !this.darkRideConfig.destroyed) {
            Client.shared.userInterface.removeWindow(this.darkRideConfig)
        }

        this.darkRideConfig = Client.shared.userInterface.register(new DarkRideConfigWindow(this.activeFurni))
    }

    takeAwayActive(): void {
        if (!this.activeFurni) {
            return
        }
        if (this.activeFurni.type === 'furni' || this.activeFurni.type === 'poster') {
            this.activeFurni.removeFromRoom()
        } else if (this.activeFurni instanceof AvatarVisual) {
            if (this.activeFurni.factor.isSelf()) {
                Client.shared.serverBroker.send('invisibility_toggle', {})
            } else {
                Client.shared.serverBroker.send('mk_boot', {
                    id: (this.activeFurni as AvatarVisual).factor.getRoomIdentifier()
                })
            }
        }
    }

    showInfoForActive(): void {
        console.log('>> showing info!')
        if (this.activeFurni instanceof FurniEntity) {
            const item = this.activeFurni.getItem()
            Client.shared.userInterface.register(
                new ItemInfoWindow(item.toDummy(), RoomInfoBox.currentData?.roomOwner ?? '', item)
            )
        }
    }

    setPhantomMode(enabled: boolean): void {
        if (!this.furniMoveMode || this.phantom === enabled) {
            return
        }
        this.phantom = enabled

        if (enabled) {
            for (const e of this.entities) {
                if (this.activeFurni !== e) {
                    for (const p of e.getParts()) {
                        p.setAlpha(0.4)
                        p.eventMode = 'none'
                        if (p.furni.type === 'furni') {
                            p.getTile()?.updateHiliter(true)
                        }
                    }
                }
            }
        } else {
            for (const e of this.entities) {
                for (const p of e.getParts()) {
                    p.setAlpha(1)
                    p.eventMode = 'static'
                    if (p.furni.type === 'furni') {
                        p.getTile()?.updateHiliter()
                    }
                }
            }
        }
    }

    clearAll(): void {
        this.setActiveFurniEntity(undefined)
        this.setMoveMode(false)
        if (this.entities) {
            for (const furni of this.entities) {
                furni.teardown()
            }
            this.entities = []
        }
    }

    removeItemById(itemId: number, animateOut = false): void {
        const item = this.entities.find((e) => e.getItem().itemId === itemId)
        if (item) {
            if (animateOut) {
                for (const part of item.getParts()) {
                    for (const sprite of part.children) {
                        gsap.to(sprite.scale, {
                            x: 0,
                            y: 0,
                            duration: 0.3,
                            ease: 'power2.out'
                        })
                    }
                }
            }
            this.removeItem(item)
        }
        if (
            this.activeFurni &&
            (this.activeFurni.type === 'furni' || this.activeFurni.type === 'poster') &&
            this.activeFurni.getItem().itemId === itemId
        ) {
            this.setActiveFurniEntity(undefined)
        }
    }

    removeItem(item: FurniEntity): void {
        item.teardown()

        const index = this.entities.indexOf(item)
        if (index !== -1) {
            this.entities.splice(index, 1)
        }

        if (this.activeFurni === item) {
            this.setActiveFurniEntity(undefined)
        }
        this.room.sprites.sortChildren()
    }

    async addItem(item: RoomItem, beginPlacing = false, animateIn?: FurniAppearAnim): Promise<FurniEntity | undefined> {
        const existing = this.entities.find((e) => e.getItem().itemId === item.itemId)

        if (existing) {
            if (beginPlacing) {
                this.removeItemById(item.itemId)
            } else {
                existing.itemUpdated(item)
                return undefined
            }
        } else {
            this.onFurniControllerClose()
        }

        let furniPromise: Promise<FurniEntity>
        if (item.defType === EItemType.Furniture) {
            let tile = this.room.tiles.get(item.ref)

            if (beginPlacing) {
                this.rapidPlacing = true
                if (!tile || (tile.getHighestPart() && !tile.getHighestPart().getBlock().stk)) {
                    for (const t of this.room.tiles) {
                        if (!t[1].getHighestPart() || t[1].getHighestPart().getBlock().stk) {
                            tile = t[1]
                            break
                        }
                    }
                }
            }

            if (!tile) {
                console.log('Unable to add item at missing tile: ' + item.ref)
                return undefined
            }
            const coord = {
                x: tile.getMapX(),
                y: tile.getMapY()
            }
            const loadingBlocks: Sprite[] = []
            const dirBlocks = Client.shared.furniMapMgr
                .getFurnimapByItemDefUid(item.defUid)
                ?.getDirectionMap()
                ?.get(item.rotation)
            const blocks = dirBlocks?.blocks
            if (blocks) {
                for (const block of blocks) {
                    const aX = coord.x + block.x
                    const aY = coord.y + block.y

                    const aTile = tile.getFloor().getTile(aX, aY)
                    if (!aTile) {
                        continue
                    }

                    const loadingBlock = Pooler.newSprite('loadingfx_0')
                    if (loadingBlock) {
                        loadingBlock.scale.set(this.room.getTilesize() / 32)
                        loadingBlock.x = aTile.x
                        loadingBlock.y = aTile.y
                        loadingBlock.zIndex = aTile.zIndex + 0.05
                        this.room.sprites.addChild(loadingBlock)
                        loadingBlocks.push(loadingBlock)
                    }
                }
            }

            furniPromise = this.drawFurni(item.defUid, item.rotation, tile, item, !beginPlacing, !!animateIn).then(
                (entity) => {
                    for (const b of loadingBlocks) {
                        b.destroy()
                    }

                    return entity
                }
            )
        } else {
            console.log('adding poster')
            animateIn = undefined
            furniPromise = this.drawPoster(
                item.defUid,
                item.rotation,
                this.room.walls.get(item.wallRef),
                item,
                !beginPlacing
            )
        }

        try {
            const entity = await furniPromise

            if (beginPlacing) {
                this.setActiveFurniEntity(entity)
            } else if (animateIn !== undefined) {
                if (animateIn === FurniAppearAnim.dropBounce) {
                    let firstY = null
                    let minY = 9999
                    let minX = 9999
                    console.log('animating furni in', entity.getParts().length)

                    for (const part of entity.getParts()) {
                        const endY = part.position.y
                        if (firstY === null) {
                            firstY = part.position.y
                        }
                        if (part.position.x < minX) {
                            minX = part.position.x
                        }
                        if (endY < minY) {
                            minY = endY
                        }
                        part.position.y = endY - firstY - 300
                        part.alpha = 1

                        gsap.to(part.position, {
                            y: endY,
                            duration: 0.5,
                            ease: 'bounce.out'
                        })
                    }
                } else if (animateIn === FurniAppearAnim.scaleBounce) {
                    console.log('Animating in!')
                    for (const part of entity.getParts()) {
                        part.alpha = 1

                        for (const sprite of part.children) {
                            const correctScaleX = sprite.scale.x
                            const correctScaleY = sprite.scale.y
                            sprite.scale.set(0, 0)
                            gsap.to(sprite.scale, {
                                x: correctScaleX,
                                y: correctScaleY,
                                duration: 0.3,
                                ease: 'bounce.out'
                            })
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error)

            Client.shared.helpers.alert({
                title: 'Furniture Error',
                message:
                    'Something went wrong while showing furniture in this room. Please report this to staff if the problem continues: ' +
                    ('name' in error ? error.name + ': ' + error.message : JSON.stringify(error))
            })

            return undefined
        }

        this.room.sprites.sortChildren()

        if (beginPlacing) {
            this.setMoveMode(true)
        }

        return await furniPromise
    }

    isEnabled(): boolean {
        return this.furniModeEnabled
    }

    async setFurniMode(enabled: boolean): Promise<void> {
        this.furniModeEnabled = enabled
        const inv = (await Client.shared.userInterface.getWin(EWindow.Inventory)) as InventoryWindow
        if (enabled) {
            inv?.getFurnishingsView().placeBtn.enable()
            for (const f of this.entities) {
                f.eventMode = 'static'
            }
            return
        }

        inv?.getFurnishingsView().placeBtn.disable()
        this.onFurniControllerClose()
    }

    setMoveMode(enabled: boolean): void {
        console.log('setMoveMode', enabled)
        if (!enabled) {
            this.setPhantomMode(false)
        }
        this.furniMoveMode = enabled

        if (enabled) {
            for (const f of this.entities) {
                f.eventMode = f.furnimap.getDirections()[0]?.blocks.some((b) => b.stk) ? 'static' : 'none'
            }
            if (this.activeFurni) {
                this.activeFurni.pulseOverlay()

                if (this.activeFurni.type === 'avatar') {
                    Client.shared.view.classList.add('draggable')
                } else {
                    this.activeFurni.eventMode = 'static'

                    if (this.activeFurni.type === 'poster') {
                        this.room.walls.forEach((wall) => (wall.eventMode = 'static'))
                    }
                }
            }
            this.onFurniControllerClose(false)

            return
        }
        this.room.walls.forEach((wall) => (wall.eventMode = 'none'))
        for (const f of this.entities) {
            f.eventMode = 'static'
        }
        if (this.activeFurni) {
            this.activeFurni.eventMode = 'static'
            this.activeFurni.stopPulse()

            if (this.activeFurni.type === 'avatar') {
                Client.shared.view.classList.remove('draggable')
            }
        }
    }

    isMoving(): boolean {
        return this.furniMoveMode
    }

    isValidPos(forFurni: FurniEntity | AvatarVisual, atTile: TileEntity): 'nostk' | 'badtile' | true {
        const direction = forFurni.type === 'avatar' ? forFurni.getDirection() : forFurni.getItem().rotation

        const coord = {
            x: atTile.getMapX(),
            y: atTile.getMapY()
        }

        if (forFurni.type === 'furni') {
            if (forFurni.getItem().internal) {
                return true
            }
            const itemMap = forFurni.furnimap.getDirection(direction) || forFurni.furnimap.getDirection(3)

            if (!itemMap) {
                return 'badtile'
            }

            let stackHeight = null
            const blocks = itemMap.blocks
            if (blocks) {
                for (const block of blocks) {
                    const aX = coord.x + block.x
                    const aY = coord.y + block.y
                    const partTile = atTile.getFloor().getTile(aX, aY)

                    if (!partTile) {
                        console.log('Invalid part tile ' + aX + ',' + aY)
                        return 'badtile'
                    }

                    if (!partTile.canStackMore(forFurni)) {
                        console.log('Cannot stack more here, highest: ', partTile.getHighestPart(true, forFurni))
                        return 'nostk'
                    }

                    // remember first tile stack height
                    if (stackHeight === null) {
                        stackHeight = partTile.getTotalHeight(forFurni)
                    } else if (partTile.getTotalHeight(forFurni) !== stackHeight) {
                        // if other tile stack heights dont match, not valid place
                        console.log('mismatched stack heights ' + partTile.getTotalHeight(forFurni) + ' ' + stackHeight)
                        return 'nostk'
                    }
                }
            }
        } else {
            const aX = coord.x
            const aY = coord.y
            const partTile = atTile.getFloor().getTile(aX, aY)

            if (!partTile) {
                console.log('Invalid part tile ' + aX + ',' + aY)
                return 'badtile'
            }

            if (!partTile.canStackMore()) {
                console.log('Cannot stack more here')
                return 'nostk'
            }
        }

        return true
    }

    movePoster(entity: WallPoster, wall: Wall, point: Point, finalPlace: boolean): void {
        entity.setWall(wall, point.x, point.y, finalPlace)

        this.room.sprites.sortChildren()
    }

    moveFurni(entity: FurniEntity | AvatarVisual, tile: TileEntity, finalPlace: boolean): void {
        const validity = this.isValidPos(entity, tile)

        if (validity === 'badtile') {
            this.lastValid = false
            return
        }

        const stackAtFloor =
            validity === 'nostk' && entity.type === 'furni' && entity.getParts()[0].getBlock().hgt === 0
        this.lastValid = validity === true || stackAtFloor

        if (entity instanceof AvatarVisual) {
            entity.factor.setTileRef(tile.ref)
            entity.factor.setTile(tile)
            entity.factor.visual.position.set(tile.x, tile.y - tile.getTotalHeight())
        } else {
            if (entity.getTile() !== tile) {
                //if (!finalPlace) {
                entity.getItem().index = tile.getNextIndex()
                //}
            }
            entity.setTile(tile, finalPlace, stackAtFloor)
        }

        this.room.sprites.sortChildren()
    }

    placeFurni(atTile?: TileEntity | Point): void {
        const placed = this.activeFurni
        if (!placed) {
            return
        }
        if (!(placed instanceof WallPoster)) {
            const validity = this.isValidPos(placed, placed.getTile())

            if (validity !== true) {
                if (
                    validity === 'nostk' &&
                    placed instanceof FurniEntity &&
                    placed.getParts()[0]?.getBlock().hgt === 0
                ) {
                    console.log(placed.getParts())
                    console.log('allowing to stack flat at bottom')
                } else {
                    console.log('cannot place, tile validity: ' + validity)
                    return
                }
            }
        }

        if (placed instanceof AvatarVisual) {
            placed.stopDrag()
            placed.getTile()?.updateHiliter()
        } else {
            for (const p of placed.getParts()) {
                p.placing = false
                p.getTile()?.updateHiliter()
            }
        }
        if (atTile) {
            this.setFurniControllerPos(atTile.x, atTile.y)
        }

        if (placed instanceof FurniEntity && !(placed instanceof WallPoster)) {
            const ref = placed.getTile()?.ref

            placed.once('place_finished', () => {
                if (Client.shared.keysDown.get(16)) {
                    // keep placing same kind of item
                    const nextItem = Client.shared.selfRecord.getInventory().get(placed.getItem().defUid + '.0.0.0')
                    if (nextItem) {
                        this.beginPlacing(nextItem, ref, placed.getItem().rotation)
                        return
                    }
                }

                if (this.rapidPlacing) {
                    Client.shared.userInterface.getWin(EWindow.Inventory, true).then((w) => (w.visible = true))

                    this.rapidPlacing = false
                }
            })
        } else if (placed instanceof WallPoster) {
            placed.updatePartTiles(true, false)
        }

        placed.sendPlacement()
        this.setMoveMode(false)

        this.setActiveFurniEntity(undefined)
    }

    beginPlacing(item: ItemStack, atRef?: number, withDir?: number): boolean {
        if (item.defType === EItemType.Poster && !this.room.walls.size) {
            return false
        }
        const furniMap = Client.shared.furniMapMgr.getFurnimapByItemDefUid(item.defUid)
        const defaultDir = withDir ?? furniMap?.getDirectionMap()?.keys().next().value ?? EAvatarDir.SouthEast

        const data: any = {
            itemId: this.placingIndex--,
            userId: Client.shared.selfRecord.getId(),
            roomId: this.room.instanceId,
            defUid: item.defUid,
            hgt: 1,
            index: 0,
            rotation: defaultDir,
            customData: null,
            createdAt: new Date(),
            updatedAt: new Date(),
            state: 0,
            teleporterId: item.teleporterId,
            defType: item.defType,
            internal: false
        }

        if (item.defType === EItemType.Poster) {
            data.wallRef = 0
            data.wallX = 0
            data.wallY = 0
        } else {
            data.ref = atRef || 25
        }

        const roomItem = new RoomItem(data)
        this.addItem(roomItem, true)

        return true
    }

    async drawFurni(
        itemDefUid: ItemDefUid,
        direction: EAvatarDir,
        tile: TileEntity,
        roomItem: RoomItem,
        isPlaced: boolean,
        hide = false
    ): Promise<FurniEntity> {
        const modelUid = Client.shared.furniMapMgr.getLinkedModelUid(itemDefUid)
        const provided = await this.room.provider.get('hof_furni/furni_' + modelUid)
        provided.sounds.forEach((s, k) => this.sounds.set(k, s))
        const furnimap = provided.files['_furnimap'] as IItemFurniMap | undefined
        let map: FurniMapItem
        if (furnimap) {
            map = Client.shared.furniMapMgr.addMap(modelUid, furnimap)

            if (map.getCasts()?.length) {
                await this.room.provider.get(map.getCasts().map((c) => 'hof_furni/furni_' + c))
            }
        }
        const entity = new FurniEntity(this, map, roomItem, tile, isPlaced)
        this.entities.push(entity)

        entity.render(hide)

        return entity
    }

    async drawPoster(
        itemDefUid: ItemDefUid,
        direction: EAvatarDir,
        wall: Wall,
        roomItem: RoomItem,
        isPlaced: boolean,
        hide = false
    ): Promise<WallPoster> {
        console.log('Drawing a poster', roomItem)

        const modelUid = Client.shared.furniMapMgr.getLinkedModelUid(itemDefUid)
        const provided = await this.room.provider.get('hof_furni/furni_' + modelUid)
        provided.sounds.forEach((s, k) => this.sounds.set(k, s))
        const furnimap = provided.files['_furnimap']
        let map: FurniMapItem
        if (furnimap) {
            map = Client.shared.furniMapMgr.addMap(modelUid, furnimap)
            if (map.getCasts()?.length) {
                for (const cast of map.getCasts()) {
                    await this.room.provider.get('hof_furni/furni_' + cast)
                }
            }
        }
        const entity = new WallPoster(this, roomItem, wall, isPlaced)
        this.entities.push(entity)

        entity.render(hide)
        entity.updatePartTiles(isPlaced, false)

        return entity
    }

    onFurniControllerClose(stopPulse = true): void {
        if (stopPulse) {
            this.activeFurni?.stopPulse()
            this.activeFurni = undefined
        }

        this.selectionPopup?.remove()
        this.selectionPopup = undefined
    }

    onTileOver(tileEntity: TileEntity): void {
        if (this.activeFurni && this.furniMoveMode && this.activeFurni.type !== 'poster') {
            this.moveFurni(this.activeFurni, tileEntity, false)
            this.setFurniControllerPos(tileEntity.x, tileEntity.y)
        }
    }

    onWallOver(wall: Wall, x: number, y: number): void {
        if (this.activeFurni && this.furniMoveMode && this.activeFurni.type === 'poster') {
            this.movePoster(this.activeFurni, wall, new Point(x, y), false)
        }
    }

    getActiveName() {
        if (!this.activeFurni) {
            return '?'
        }
        let itemName: string
        if (this.activeFurni instanceof AvatarVisual) {
            itemName = this.activeFurni.factor?.getName()
        } else {
            itemName = this.activeFurni.getItem()?.getName()
        }

        if (Client.shared.selfRecord.can(EPerm.MKTools) && this.activeFurni instanceof FurniEntity) {
            itemName += ' #' + this.activeFurni.getItem().itemId
        }

        return itemName
    }

    canTakeAwayItems() {
        if (this.activeFurni instanceof FurniEntity) {
            const selfOverride = this.room.ownRoom || Client.shared.selfRecord.can(EPerm.RoomsGuestFurni)

            return selfOverride
        }

        return false
    }

    canMove() {
        if (this.activeFurni instanceof FurniEntity) {
            const guestMove = !!this.activeFurni.getItem().customData?.guestMove
            const selfOverride = this.room.ownRoom || Client.shared.selfRecord.can(EPerm.RoomsGuestFurni)

            return selfOverride || guestMove
        }

        return false
    }

    setActiveFurniEntity(entity?: FurniEntity | AvatarVisual): void {
        this.activeFurni?.stopPulse()
        this.activeFurni = entity

        if (entity) {
            this.selectionPopup?.remove()
            this.selectionPopup = new FurniSelectionPopup(this)
            this.selectionPopup.putIn(this.room)

            if (entity instanceof WallPoster) {
                if (entity.getPoint()) {
                    this.setFurniControllerPos(entity.getPoint().x, entity.getPoint().y)
                }
            } else {
                this.setFurniControllerPos(entity.getTile().x, entity.getTile().y)
            }
            entity.pulseOverlay()
        } else {
            this.onFurniControllerClose()
        }
    }

    setFurniControllerPos(x: number, y: number): void {
        const view = this.selectionPopup?.view
        if (!view) {
            return
        }
        x = x - view.width / 2 + 400
        y = y + 150

        if (x < 0) {
            x = 0
        } else if (x + view.width > Constants.SIZE[0]) {
            x = Constants.SIZE[0] - view.width
        }

        if (y < 0) {
            y = 0
        } else if (y + view.height > Constants.SIZE[1]) {
            y = Constants.SIZE[1] - view.height
        }

        view.position.set(x, y)
    }

    teardown(): void {
        if (this.entities) {
            for (const furni of this.entities) {
                furni.teardown()
            }
            this.entities = []
        }

        this.activeFurni = null
        this.furniMoveMode = false

        this.sounds.forEach((s) => SoundManager.shared.release(s))
        this.sounds.clear()

        this.selectionPopup?.remove()
        this.selectionPopup = undefined
    }
}

export default FurniController
