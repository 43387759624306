import type { ScoreDefinition } from '@vmk-legacy/render-utils'
import { ScoreRunner } from '@vmk-legacy/render-utils'
import { Container } from 'pixi.js'
import type { RoomViewer } from './renderer/RoomViewer.js'

export class Animation extends Container {
    lastFrame: number
    color: string
    score: ScoreRunner

    constructor(
        readonly room: RoomViewer,
        def?: ScoreDefinition
    ) {
        super()

        this.score = new ScoreRunner({
            name: def?.name || 'Untitled Animation'
        })

        if (def) {
            this.score.loadDefinition(room.assets, def, this)

            this.name = def.name
            this.lastFrame = def.endFrame
        }
    }
}
