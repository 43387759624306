export class RandomUtil {
    static generateRandomNumberInRange(min: number, max: number) {
        return Math.random() * (max - min) + min
    }

    static generateRandomIntegerInRange(min: number, max: number) {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    static getRandomElementFromArray<T>(arr: T[]): T | null {
        if (arr.length === 0) {
            return null
        }
        return arr[Math.floor(Math.random() * arr.length)]
    }

    static getRandomIndexFromArray(arr: any[]): number | null {
        if (arr.length === 0) {
            return null
        }
        return Math.floor(Math.random() * arr.length)
    }
}
