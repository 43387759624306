import { Pooler } from '@vmk-legacy/render-utils'
import { Client } from '../../../Client.js'
import { Constants } from '../../../Constants.js'
import { RandomUtil } from '../../../util/RandomUtil.js'
import type { Animation } from '../../Animation.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class GatorWaiterHandler extends WalkableRoomExtension {
    static override identifier = 'blue_bayou'
    //TODO: Server-side extension that moves gator around

    animation: Animation
    animNames = ['Default', 'Idle', 'Talk', 'Walk', 'Serve']

    doRandomAnim() {
        const anim = this.animNames[RandomUtil.generateRandomIntegerInRange(0, this.animNames.length - 1)]
        const dir = RandomUtil.generateRandomIntegerInRange(0, 7)

        this.animation.score.goToMarker(anim + ' ' + dir)
    }

    async init(): Promise<this> {
        await super.init()

        // this.animation = await this.room.setupAnimation('npc_gatorwaiter_anim.ani');
        //
        // if (this.animation) {
        //     this.room.spriteContainer.addChild(this.animation);
        //
        //     this.animation.zIndex = 999999;
        //     this.animation.position.set(200, 250);
        //
        //     this.animation.score.playFromBeginning(true);
        //
        //     this.animation.interactive = this.animation.interactiveChildren = true;
        //     this.animation.addListener("click", () => {
        //         Client.shared.serverBroker.send(new ClientNPCVisit({
        //             npc: NPC.Gator
        //         }))
        //     });
        //
        //     this.setInterval(() => this.doRandomAnim(), 5000);
        // }

        const m = Client.shared.selfRecord.getMickeys()
        if (!m.includes(29)) {
            // TODO: why is this here / can we just add this to the room data
            await Client.shared.assetLoader.loadCasts(['questengine/quest_hidden_mickey'], {
                retains: this.room.provider
            })
            const mickey = Pooler.newSprite('t6')
            mickey.position.set(612 - 400, 170 - 300)
            mickey.zIndex = 999999
            this.room.sprites.addChild(mickey)

            mickey.alpha = Constants.HIDDEN_MICKEY_ALPHA
            mickey.eventMode = 'static'

            mickey.addEventListener('pointerup', () => {
                const newArr = Client.shared.selfRecord.getMickeys()
                newArr.push(29)
                Client.shared.selfRecord.setMickeys(newArr)
                mickey.eventMode = 'auto'
                const timing = 0.5
                Client.shared.serverBroker.send('mickey_found', {
                    id: 29
                })
                this.tween(mickey, timing, { alpha: 1 }).then(() => this.tween(mickey, timing, { alpha: 0 }))
            })
        }

        return this
    }
}
