import type { JungleCruiseDelegate } from './JungleCruiseDelegate.js'

export class JungleCruiseGameController {
    #fuel = 140
    #film = 25
    #points = 0

    #pendingScores: number[] = []

    constructor(protected readonly delegate: JungleCruiseDelegate) {}

    getFuel(): number {
        return this.#fuel
    }

    getFilm(): number {
        return this.#film
    }

    getPoints(): number {
        return this.#points
    }

    consumePending(): number[] {
        return this.#pendingScores.splice(0, 5)
    }

    addFuel(num = 10): void {
        this.#fuel += num
        this.delegate.ui.setGas(this.#fuel)
    }

    subtractFuel(num: number): void {
        this.#fuel -= num
        const gas = Math.max(Math.round(this.#fuel), 0)
        this.delegate.ui.setGas(gas)
    }

    addPoints(num: number): void {
        this.#points += num
        this.#pendingScores.push(num)
        this.delegate.ui.setScore(this.#points)
    }

    addFilm(num = 10): void {
        this.#film += num
        this.delegate.ui.setFilm(this.#film)
    }

    subtractFilm(num = 1): boolean {
        const filmRemaining = this.#film > 0

        this.#film -= num

        if (this.#film < 0) {
            this.#film = 0
        }
        this.delegate.ui.setFilm(this.#film)

        return filmRemaining
    }
}
