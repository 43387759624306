import type { BitmapText, Sprite } from 'pixi.js'
import { Texture } from 'pixi.js'
import { Client } from '../../../Client.js'
import { UILayer } from '../../../enums.js'
import type { ImageButton } from '../../../ui/buttons/ImageButton.js'
import LegacyWindow from '../../../ui/windows/LegacyWindow.js'
import type { PotcGameInfo } from './PiratesPreshowHandler.js'
import type { PiratesSignupView } from './PiratesSignupView.js'

export class PiratesStartPlayers extends LegacyWindow {
    layer = UILayer.GameWindows

    protected casts = ['minigame/pirates2_interface']

    constructor(
        readonly signup: PiratesSignupView,
        readonly gameId: string
    ) {
        super('vmk_pirates_start_players')
    }

    teams: {
        [n: string]: {
            name: BitmapText
            kickBtn: ImageButton
            shipBtn: ImageButton
            shipIcon: Sprite
        }[]
    } = {}
    game: PotcGameInfo

    override async windowWasBuilt(): Promise<void> {
        // skull n crossbones buttons remove, only show for non-self players

        const switchToPort = this.getElement('button.toport') as ImageButton
        const switchToPirates = this.getElement('button.topirates') as ImageButton

        this.hide(switchToPort)

        switchToPort.addEventListener('pointertap', () => this.switchTeams('port'))
        switchToPirates.addEventListener('pointertap', () => this.switchTeams('pirates'))

        for (const team of ['port', 'pirates']) {
            const color = {
                port: 'red',
                pirates: 'blue'
            }[team]

            this.teams[team] = [1, 2, 3, 4].map((n) => {
                return {
                    name: this.getElement('team.' + color + '.player' + n),
                    kickBtn: this.getElement('button.pirateskick.' + color + n),
                    shipBtn: this.getElement('button.piratesship.' + color + n),
                    shipIcon: this.getElement('piratesship.' + color + n)
                }
            })
        }

        const startBtn = this.getElement('button.pirates.startnow') as ImageButton
        startBtn.addEventListener('pointertap', () => {
            startBtn.disable()
            Client.shared.serverBroker.sendAck('potc_begin').then((closeWin: boolean) => {
                if (closeWin) {
                    Client.shared.userInterface.removeWindow(this.embeddedParent)
                    this.signup.startPlayersWin = null
                } else {
                    startBtn.enable()
                }
            })
        })
        this.getElement('button.pirates.cancel').once('pointertap', () => {
            console.log('Cancel pirates')
            Client.shared.userInterface.removeWindow(this.embeddedParent)
            this.signup.startPlayersWin = null
            Client.shared.serverBroker.send('potc_cancel_game')
        })

        const game = this.signup.handler.games.find((g) => g.id === this.gameId)
        if (game) {
            this.renderGame(game)
        }
    }

    renderGame(game: PotcGameInfo) {
        this.game = game

        console.log('start players rendering', game)

        for (const team of ['port', 'pirates']) {
            for (let i = 0; i < 4; i++) {
                if (this.game.teams[team][i]) {
                    this.teams[team][i].name.text = this.game.teams[team][i].name
                    this.teams[team][i].name.visible = true
                    const ship = this.game.teams[team][i].ship

                    if (this.game.teams[team][i].id === Client.shared.selfRecord.getId()) {
                        this.teams[team][i].shipBtn.replaceTextures(
                            'button.' + ship + '.active',
                            'button.' + ship + '.pressed'
                        )
                        this.teams[team][i].shipBtn.visible = true

                        this.teams[team][i].shipBtn.addEventListener('pointertap', () => this.signup.changeShip())
                        this.teams[team][i].shipIcon.visible = false
                        this.teams[team][i].kickBtn.visible = false
                    } else {
                        this.teams[team][i].shipIcon.texture = Texture.from(ship + '.selected')
                        this.teams[team][i].shipBtn.visible = false
                        this.teams[team][i].shipIcon.visible = true
                        this.teams[team][i].kickBtn.visible = true
                        this.teams[team][i].kickBtn.addEventListener('pointertap', () =>
                            this.kick(this.game.teams[team][i].id)
                        )
                    }
                } else {
                    this.teams[team][i].name.visible = false
                    this.teams[team][i].shipBtn.visible = false
                    this.teams[team][i].shipIcon.visible = false
                    this.teams[team][i].kickBtn.visible = false
                }
            }
        }
    }

    switchTeams(toTeam: string) {
        Client.shared.serverBroker.sendAck('potc_switch_team', toTeam).then((success) => {
            if (success) {
                this.hide('button.to' + toTeam)
                this.show('button.to' + (toTeam === 'port' ? 'pirates' : 'port'))
            }
        })
    }

    kick(playerId: number) {
        Client.shared.serverBroker.send('potc_kick_player', playerId)
    }
}
