import type { ItemDefUid, ItemOwnedTuple } from '@vmk-legacy/common-ts'
import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { AvatarEntity } from '../../room/entities/AvatarEntity.js'
import { TradeWindow } from '../../ui/windows/trade/TradeWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export type TradeUpdatePayload = {
    ign?: string
    id?: number
    outfit?: (ItemDefUid | number)[]

    // restoring
    youAccept?: boolean
    theyAccept?: boolean
    chat?: string

    myOffer?: ItemOwnedTuple[]
    theirOffer?: ItemOwnedTuple[]
}

export class TradeBeginModule extends ServerEvent {
    static readonly type = MessageType.TRADE_BEGIN

    override async handle(payload: TradeUpdatePayload): Promise<void> {
        const win = await Client.shared.userInterface.getWin(EWindow.Trading, true)
        if (!(win instanceof TradeWindow)) {
            return
        }

        win.onTradeUpdate(payload)

        if (!payload.outfit) {
            const object = Client.shared.roomViewer.getEntityByRef(payload.id)
            if (object instanceof AvatarEntity) {
                win.setPartnerOutfit(object.getVisual().getOutfit().transformed)
            }
        }

        win.setVisible(true)
    }
}
