import type { Texture } from 'pixi.js'
import { Assets } from 'pixi.js'
import Config from '../Config.js'

export class PinCache {
    async getTexture(defUid: string, small: boolean = false): Promise<Texture | undefined> {
        return await Assets.load({
            src: `${Config.environment.thumbsUrl}/item_${defUid}${small ? '-sm' : ''}.png?ref=client`
        })
    }

    private static _instance: PinCache

    static get instance(): PinCache {
        return this._instance || (this._instance = new PinCache())
    }
}

export class ItemPackCache {
    private readonly basePath: string = Config.environment.assetRoot + '/items/pack_thumbs/'
    private readonly extension: string = '.png'
    private cache = new Map<string, Texture>()

    getTexture(castName: string): Promise<Texture> {
        return new Promise<Texture>((resolve, reject) => {
            if (this.cache.has(castName)) {
                return resolve(this.cache.get(castName))
            }
            Assets.load({
                src: '/items/pack_thumbs/' + castName + '.png'
            }).then((tex) => {
                this.cache.set(castName, tex)

                resolve(tex)
            })
        })
    }

    private static _instance: ItemPackCache
    static get instance() {
        return this._instance || (this._instance = new ItemPackCache())
    }
}
