import { EPref } from '@vmk-legacy/common-ts'
import { Client } from './Client.js'
import { InvTab } from './ui/windows/inventory/InventoryWindow.js'
import { NavArea } from './ui/windows/VMKNavigator.js'
import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'

export enum PrefTemp {
    NavTab = 'navtab',
    InvTab = 'invtab',
    FurniPage = 'frpg',
    PinsPage = 'pnpg',
    ClothingPage = 'clpg'
}

export class SessionPrefs {
    private _cachePerm: { [k: string]: any } = SessionPrefs.defaults
    private _cacheTemp: { [k: string]: any } = SessionPrefs.defaultsTemp

    constructor() {
        this._cachePerm = JSON.parse(window.localStorage.getItem('vmkl.prefs') as string)
    }

    replacePrefs(prefs: { [k: string]: any }): void {
        console.log('replacing prefs', prefs)
        if (!prefs) {
            return
        }

        if (prefs.t) {
            if (this._cachePerm && prefs.t === this._cachePerm.t) {
                console.log('new prefs have same t value')
                return
            }
        } else {
            prefs.t = Date.now()
        }

        this._cachePerm = prefs

        this.apply()
        this.saveLocal()
    }

    saveLocal(): void {
        console.log('saving local prefs', this._cachePerm)
        window.localStorage.setItem('vmkl.prefs', JSON.stringify(this._cachePerm))
    }

    apply(): void {
        console.log('applying prefs')

        const newGain = 1 - +this.get(EPref.GlobalVol)
        console.log('global gain: ', newGain)
        SoundManager.shared.globalVolGain.gain.value = newGain

        SoundManager.shared.groupVolGains[ESndGrp.Music].gain.value = this.get(EPref.MuteMusic) ? 0 : 1
        SoundManager.shared.groupVolGains[ESndGrp.SFX].gain.value = this.get(EPref.MuteSFX) ? 0 : 1
        SoundManager.shared.groupVolGains[ESndGrp.UI].gain.value = this.get(EPref.MuteUI) ? 0 : 1
    }

    save(): void {
        this.saveLocal()

        console.log('saving server prefs', this._cachePerm)
        Client.shared.serverBroker.send('save_prefs', this._cachePerm)
    }

    getTemp(key: PrefTemp) {
        if (this._cacheTemp && key in this._cacheTemp) {
            return this._cacheTemp[key]
        }

        return SessionPrefs.defaultsTemp[key]
    }

    setTemp(key: PrefTemp, value: boolean | number | string): void {
        if (!this._cacheTemp) {
            this._cacheTemp = {}
        }
        this._cacheTemp[key] = value
    }

    get(key: EPref): boolean | number | string {
        if (this._cachePerm && key in this._cachePerm) {
            return this._cachePerm[key]
        }

        return SessionPrefs.defaults[key]
    }

    set(key: EPref, value: boolean | number | string): void {
        if (!this._cachePerm) {
            this._cachePerm = {}
        }
        this._cachePerm[key] = value
        this._cachePerm.t = Date.now()

        this.apply()
    }

    static defaults = {
        [EPref.LowRes]: false,
        [EPref.MuteMusic]: false,
        [EPref.MuteSFX]: false,
        [EPref.MuteUI]: false,
        [EPref.HideTutorials]: false,
        [EPref.HideFriendReqs]: false,
        [EPref.HideTrades]: false,
        [EPref.GlobalVol]: 1,
        [EPref.ShowExtraTooltips]: true
    }

    static defaultsTemp = {
        [PrefTemp.NavTab]: NavArea.parkmap,
        [PrefTemp.InvTab]: InvTab.Credits,
        [PrefTemp.FurniPage]: 1,
        [PrefTemp.PinsPage]: 1,
        [PrefTemp.ClothingPage]: 1
    }
}
