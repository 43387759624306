export class Converter {
    static toSlug(str: string): string {
        let newString = str.replace(/ +/g, '-')
        newString = newString.replace(/&/g, 'and')
        newString = newString.replace(/@/g, 'at')
        newString = newString.replace(/%/g, 'percent')
        newString = newString.replace(/[?\!##\$*^\)]/g, '')
        newString = newString.toLowerCase()
        return newString
    }
}
