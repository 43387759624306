import type { FederatedEvent, FederatedPointerEvent, Sprite } from 'pixi.js'
import { Container, Graphics, Point } from 'pixi.js'

// https://github.com/gamestdio/pixi-virtual-joystick

export interface JoystickChangeEvent {
    angle: number
    direction: Direction
    power: number
}

export enum Direction {
    LEFT = 'left',
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
    TOP_LEFT = 'top_left',
    TOP_RIGHT = 'top_right',
    BOTTOM_LEFT = 'bottom_left',
    BOTTOM_RIGHT = 'bottom_right'
}

export interface JoystickSettings {
    outer?: Sprite | Graphics | Container
    inner?: Sprite | Graphics | Container
    outerScale?: { x: number; y: number }
    innerScale?: { x: number; y: number }
    onChange?: (data: JoystickChangeEvent) => void
    onStart?: () => void
    onEnd?: () => void
}

export class Joystick extends Container {
    settings: JoystickSettings

    outerRadius = 0
    innerRadius = 0

    outer!: Sprite | Graphics | Container
    inner!: Sprite | Graphics | Container

    innerAlphaStandby = 0.5

    constructor(opts: JoystickSettings) {
        super()

        this.settings = Object.assign(
            {
                outerScale: {
                    x: 1,
                    y: 1
                },
                innerScale: {
                    x: 1,
                    y: 1
                }
            },
            opts
        )

        if (!this.settings.outer) {
            const outer = new Graphics()
            outer.beginFill(0x000000)
            outer.drawCircle(0, 0, 60)
            outer.alpha = 0.5
            this.settings.outer = outer
        }

        if (!this.settings.inner) {
            const inner = new Graphics()
            inner.beginFill(0x000000)
            inner.drawCircle(0, 0, 35)
            inner.alpha = this.innerAlphaStandby
            this.settings.inner = inner
        }

        this.initialize()
    }

    initialize(): void {
        this.outer = this.settings.outer!
        this.inner = this.settings.inner!

        this.outer.scale.set(this.settings.outerScale!.x, this.settings.outerScale!.y)
        this.inner.scale.set(this.settings.innerScale!.x, this.settings.innerScale!.y)

        if ('anchor' in this.outer) {
            this.outer.anchor.set(0.5)
        }
        if ('anchor' in this.inner) {
            this.inner.anchor.set(0.5)
        }

        this.addChild(this.outer)
        this.addChild(this.inner)

        // this.outerRadius = this.containerJoystick.width / 2;
        this.outerRadius = this.width / 2.5
        this.innerRadius = this.inner.width / 2

        this.bindEvents()
    }

    protected bindEvents(): void {
        this.eventMode = 'static'

        let dragging = false
        let eventData: FederatedEvent
        let power: number
        let startPosition: Point

        const onDragStart = (event: FederatedPointerEvent) => {
            eventData = event
            startPosition = this.toLocal(event.global)

            dragging = true
            this.inner.alpha = 1

            this.settings.onStart?.()
        }

        const onDragEnd = (event: FederatedEvent) => {
            if (dragging == false) {
                return
            }

            this.inner.position.set(0, 0)

            dragging = false
            this.inner.alpha = this.innerAlphaStandby

            this.settings.onEnd?.()
        }

        const onDragMove = (event: FederatedPointerEvent) => {
            if (dragging == false) {
                return
            }

            const newPosition = this.toLocal(eventData.global)

            const sideX = newPosition.x - startPosition.x
            const sideY = newPosition.y - startPosition.y

            const centerPoint = new Point(0, 0)
            let angle = 0

            if (sideX == 0 && sideY == 0) {
                return
            }

            let calRadius = 0

            if (sideX * sideX + sideY * sideY >= this.outerRadius * this.outerRadius) {
                calRadius = this.outerRadius
            } else {
                calRadius = this.outerRadius - this.innerRadius
            }

            /**
             * x:   -1 <-> 1
             * y:   -1 <-> 1
             *          Y
             *          ^
             *          |
             *     180  |  90
             *    ------------> X
             *     270  |  360
             *          |
             *          |
             */

            let direction = Direction.LEFT

            if (sideX == 0) {
                if (sideY > 0) {
                    centerPoint.set(0, sideY > this.outerRadius ? this.outerRadius : sideY)
                    angle = 270
                    direction = Direction.BOTTOM
                } else {
                    centerPoint.set(0, -(Math.abs(sideY) > this.outerRadius ? this.outerRadius : Math.abs(sideY)))
                    angle = 90
                    direction = Direction.TOP
                }
                this.inner.position.set(centerPoint.x, centerPoint.y)
                power = this.getPower(centerPoint)
                this.settings.onChange?.({
                    angle,
                    direction,
                    power
                })
                return
            }

            if (sideY == 0) {
                if (sideX > 0) {
                    centerPoint.set(Math.abs(sideX) > this.outerRadius ? this.outerRadius : Math.abs(sideX), 0)
                    angle = 0
                    direction = Direction.LEFT
                } else {
                    centerPoint.set(-(Math.abs(sideX) > this.outerRadius ? this.outerRadius : Math.abs(sideX)), 0)
                    angle = 180
                    direction = Direction.RIGHT
                }

                this.inner.position.set(centerPoint.x, centerPoint.y)
                power = this.getPower(centerPoint)
                this.settings.onChange?.({
                    angle,
                    direction,
                    power
                })
                return
            }

            const tanVal = Math.abs(sideY / sideX)
            const radian = Math.atan(tanVal)
            angle = (radian * 180) / Math.PI

            let centerX = 0
            let centerY = 0

            if (sideX * sideX + sideY * sideY >= this.outerRadius * this.outerRadius) {
                centerX = this.outerRadius * Math.cos(radian)
                centerY = this.outerRadius * Math.sin(radian)
            } else {
                centerX = Math.abs(sideX) > this.outerRadius ? this.outerRadius : Math.abs(sideX)
                centerY = Math.abs(sideY) > this.outerRadius ? this.outerRadius : Math.abs(sideY)
            }

            if (sideY < 0) {
                centerY = -Math.abs(centerY)
            }
            if (sideX < 0) {
                centerX = -Math.abs(centerX)
            }

            if (sideX > 0 && sideY < 0) {
                // < 90
            } else if (sideX < 0 && sideY < 0) {
                // 90 ~ 180
                angle = 180 - angle
            } else if (sideX < 0 && sideY > 0) {
                // 180 ~ 270
                angle = angle + 180
            } else if (sideX > 0 && sideY > 0) {
                // 270 ~ 369
                angle = 360 - angle
            }
            centerPoint.set(centerX, centerY)
            power = this.getPower(centerPoint)

            direction = this.getDirection(centerPoint)
            this.inner.position.set(centerPoint.x, centerPoint.y)

            this.settings.onChange?.({
                angle,
                direction,
                power
            })
        }

        this.addEventListener('pointerdown', onDragStart)
        this.addEventListener('pointerup', onDragEnd)
        this.addEventListener('pointerupoutside', onDragEnd)
        this.addEventListener('pointermove', onDragMove)
    }

    protected getPower(centerPoint: Point): number {
        const a = centerPoint.x
        const b = centerPoint.y

        return Math.min(1, Math.sqrt(a * a + b * b) / this.outerRadius)
    }

    protected getDirection(center: Point): Direction {
        const rad = Math.atan2(center.y, center.x) // [-PI, PI]
        if ((rad >= -Math.PI / 8 && rad < 0) || (rad >= 0 && rad < Math.PI / 8)) {
            return Direction.RIGHT
        } else if (rad >= Math.PI / 8 && rad < (3 * Math.PI) / 8) {
            return Direction.BOTTOM_RIGHT
        } else if (rad >= (3 * Math.PI) / 8 && rad < (5 * Math.PI) / 8) {
            return Direction.BOTTOM
        } else if (rad >= (5 * Math.PI) / 8 && rad < (7 * Math.PI) / 8) {
            return Direction.BOTTOM_LEFT
        } else if ((rad >= (7 * Math.PI) / 8 && rad < Math.PI) || (rad >= -Math.PI && rad < (-7 * Math.PI) / 8)) {
            return Direction.LEFT
        } else if (rad >= (-7 * Math.PI) / 8 && rad < (-5 * Math.PI) / 8) {
            return Direction.TOP_LEFT
        } else if (rad >= (-5 * Math.PI) / 8 && rad < (-3 * Math.PI) / 8) {
            return Direction.TOP
        }
        return Direction.TOP_RIGHT
    }
}
