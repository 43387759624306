import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import type { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { RoomItem } from '../../room/RoomItem.js'
import { UISoundLibrary } from '../../ui/UISoundLibrary.js'
import { IndeterminateLoadingView } from '../../ui/views/IndeterminateLoadingView.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import { RoomJoinModule } from './RoomJoinModule.js'

type Payload = {
    items: any[]
}

export class RoomFurniLoad extends ServerEvent {
    static readonly type = MessageType.ROOM_LOAD_FURNI

    async handle(data: Payload): Promise<void> {
        console.log('>> RoomFurniLoad')

        const loading = new IndeterminateLoadingView('room.loading.assets.headline', 'Please wait')
        Client.shared.userInterface.register(loading, true)

        const sfx = SoundManager.shared.play(ESndGrp.SFX, UISoundLibrary.LoadingLoop, true)

        const viewer = Client.shared.roomViewer as WalkableRoomViewer
        viewer.disableSound = true
        await viewer.reveal()
        viewer.disableSound = false
        RoomJoinModule.loader.destroy()
        RoomJoinModule.loader = null

        data.items.sort((a, b) => a.index - b.index)

        try {
            for (const item of data.items) {
                const roomItem = new RoomItem(item)

                await viewer.addToItemQueue(roomItem)
            }
        } catch (error) {
            console.error(error)
            try {
                await viewer.teardown()
            } catch (err) {
                console.error(error)
            }
            Client.shared.helpers.alert({
                message:
                    'Something went wrong while loading this room. Please try again or report this to staff: ' +
                    ('name' in error ? error.name + ': ' + error.message : JSON.stringify(error)),
                title: 'Room Item Load Error',
                tag: 'roomload.error'
            })

            Client.shared.userInterface.showWindow(EWindow.Navigator)
            Client.shared.loadingView.setVisible(false)
            Client.shared.userInterface.removeWindow(loading)
            SoundManager.shared.release(sfx)
            RoomJoinModule.loader?.destroy()
            RoomJoinModule.loader = null

            throw error
        }

        Client.shared.userInterface.removeWindow(loading)
        SoundManager.shared.release(sfx)
        Client.shared.soundScore.playFromBeginning(false)

        console.log('>> room load stage 2 done')
        Client.shared.serverBroker.send('room_loaded', 2)
    }
}
