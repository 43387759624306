import { EItemType } from '@vmk-legacy/common-ts'
import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import { PrefTemp } from '../../../SessionPrefs.js'
import type { ImageButton } from '../../buttons/ImageButton.js'
import { TabController } from '../../TabController.js'
import LegacyWindow from '../LegacyWindow.js'
import type { UIWindowTab } from '../UIWindowTab.js'
import type { UIWindowView } from '../UIWindowView.js'
import { ClothingView } from './views/ClothingView.js'
import { CreditView } from './views/CreditView.js'
import { FurnishingsView } from './views/FurnishingsView.js'
import { PinsView } from './views/PinsView.js'
import { RedemptionView } from './views/RedemptionView.js'

export enum InvTab {
    Credits = 'credits',
    Furni = 'furni',
    Pins = 'pins',
    Clothing = 'clothing',
    Codes = 'codes',
    Posters = 'posters'
}

export class InventoryWindow extends LegacyWindow {
    readonly kind = EWindow.Inventory

    isDraggable = true
    exitBtn: ImageButton

    private tabController: TabController

    private creditView: CreditView
    private furnishingsView: FurnishingsView
    private postersView: FurnishingsView
    private pinsView: PinsView
    private clothingView: ClothingView
    private redemptionView: RedemptionView

    constructor() {
        super('vmk_inv')

        this.position.set(174, 146)
    }

    async windowWasBuilt(): Promise<void> {
        this.tabController = this.addChild(new TabController(this, [25, 7]))
        this.tabController.position.set(28, 44)
        this.tabController.on('change', (newTab: UIWindowTab) => {
            Client.shared.prefs.setTemp(PrefTemp.InvTab, newTab.id as InvTab)
            ;(newTab.view as UIWindowView).update()
        })

        this.hide('inv.tab.*')

        const invTab = Client.shared.prefs.getTemp(PrefTemp.InvTab)

        this.creditView = new CreditView()
        this.tabController.addTab('Credit', this.creditView, invTab === InvTab.Credits).setId(InvTab.Credits)

        this.furnishingsView = new FurnishingsView(this, EItemType.Furniture)
        this.tabController.addTab('Furnishings', this.furnishingsView, invTab === InvTab.Furni).setId(InvTab.Furni)
        if (invTab === InvTab.Furni) {
            this.furnishingsView.update()
        }

        this.pinsView = new PinsView()
        this.tabController.addTab('Pins', this.pinsView, invTab === InvTab.Pins).setId(InvTab.Pins)
        if (invTab === InvTab.Pins) {
            this.pinsView.update()
        }

        this.clothingView = new ClothingView()
        this.tabController.addTab('Clothing', this.clothingView, invTab === InvTab.Clothing).setId(InvTab.Clothing)
        if (invTab === InvTab.Clothing) {
            this.clothingView.update()
        }
        this.postersView = new FurnishingsView(this, EItemType.Poster)
        this.tabController.addTab('Posters', this.postersView, invTab === InvTab.Posters).setId(InvTab.Posters)
        if (invTab === InvTab.Posters) {
            this.postersView.update()
        }

        this.redemptionView = new RedemptionView()
        this.tabController.addTab('Codes', this.redemptionView, invTab === InvTab.Codes).setId(InvTab.Codes)
    }

    getFurnishingsView(): FurnishingsView {
        return this.furnishingsView
    }

    getPinsView(): PinsView {
        return this.pinsView
    }

    getClothingView(): ClothingView {
        return this.clothingView
    }

    updateCurrentView() {
        this.getCurrentView()?.update()
    }

    getCurrentView() {
        return this.tabController.getActive()?.view as UIWindowView
    }
}
