import type { Sprite } from 'pixi.js'

export class ShopRoom {
    private catalogId: number
    private name: string
    private desc: string
    private preview: Sprite
    private price: number

    constructor(catalogId: number, name: string, desc: string, price: number, preview: Sprite) {
        this.catalogId = catalogId
        this.name = name
        this.desc = desc
        this.price = price
        this.preview = preview
    }

    getCatalogId(): number {
        return this.catalogId
    }

    getName(): string {
        return this.name
    }

    getDesc(): string {
        return this.desc
    }

    getPrice(): number {
        return this.price
    }

    getPreview(): Sprite {
        return this.preview
    }
}
