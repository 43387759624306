import type { FederatedEvent } from 'pixi.js'
import type { RadioButton } from './buttons/RadioButton.js'
import { UISoundLibrary } from './UISoundLibrary.js'
import { SoundManager } from '@vmk-legacy/render-utils'

export class RadioController {
    private buttons: RadioButton[]
    private activeButton: RadioButton
    id: any

    constructor(a: RadioButton[]) {
        this.activeButton = null
        this.buttons = a
        for (const button of this.buttons) {
            button.addEventListener('pointerup', (e: FederatedEvent) => {
                this.clear()
                this.activeButton = (e.currentTarget || e.target) as RadioButton
                this.activeButton.setActive(true)
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.UIClick)
            })
        }
    }

    /**
     * Adds a RadioButton from the controller list
     * @param r The RadioButton to be added
     */
    add(r: RadioButton): void {
        this.buttons.push(r)
        r.addEventListener('pointerup', (e: FederatedEvent) => {
            this.clear()
            this.activeButton = (e.currentTarget || e.target) as RadioButton
            this.activeButton.setActive(true)
            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.UIClick)
        })
    }

    /**
     * Removes a RadioButton from the controller list
     * @param r The RadioButton to be removed
     */
    remove(r: RadioButton): void {
        const rI = this.buttons.indexOf(r)
        if (rI !== -1) {
            this.buttons.splice(rI, 1)
        }
    }

    /**
     * Deselects all active RadioButtons
     */
    clear(): void {
        this.buttons.forEach((button) => {
            button.setActive(false)
        })
    }

    /**
     * Returns the active RadioButton. Returns null if none are active
     */
    getActive(): RadioButton {
        return this.activeButton
    }

    getValue(): string | number | null {
        return this.getActive()?.getValue()
    }

    setValue(value: string | number): void {
        this.clear()
        const active = this.buttons.find((b) => b.getValue() == value)
        if (active) {
            active.setActive(true)
            this.activeButton = active
        }
    }
}
