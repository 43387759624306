import { ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import { Fonts } from '../../../Fonts.js'
import { ImageButton } from '../../buttons/ImageButton.js'
import { Field } from '../../fields/Field.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import { UIWindow } from '../UIWindow.js'
import { UIWindowTab } from '../UIWindowTab.js'
import { UIWindowType } from '../UIWindowType.js'
import { RoomsView } from './views/RoomsView.js'
import { ShopView } from './views/ShopView.js'

export class ShopWindow extends UIWindow {
    readonly kind = EWindow.Shopping

    isDraggable: boolean
    exitBtn: ImageButton
    isFocused = true

    roomsView: RoomsView
    furnishingsView: ShopView
    pinsView: ShopView
    clothingView: ShopView
    specialsView: ShopView
    postersView: ShopView
    private yourCreditNumText: BitmapText

    constructor() {
        super(335, UIWindowType.SHOP)
        this.position.set(100, 25)
        this.isDraggable = true
    }

    override async windowWasBuilt(): Promise<void> {
        this.exitBtn = new ImageButton('button.close.active', 'button.close.pressed')
        this.addChild(this.exitBtn)
        this.exitBtn.x = this.header.x + this.header.width - 16
        this.exitBtn.y = this.header.y

        this.roomsView = new RoomsView()
        const roomsTab = new UIWindowTab('Rooms', this.roomsView)
        this.addChild(roomsTab)

        this.furnishingsView = new ShopView()
        const furnishingsTab = new UIWindowTab('Furnishings', this.furnishingsView)
        this.addChild(furnishingsTab)

        this.pinsView = new ShopView()
        const pinsTab = new UIWindowTab('Pins', this.pinsView)
        this.addChild(pinsTab)

        this.clothingView = new ShopView()
        const clothingTab = new UIWindowTab('Clothing', this.clothingView)
        this.addChild(clothingTab)

        this.postersView = new ShopView()
        const postersTab = new UIWindowTab('Posters', this.postersView)
        this.addChild(postersTab)

        this.specialsView = new ShopView()
        const specialsTab = new UIWindowTab('Specials', this.specialsView)
        this.addChild(specialsTab)

        this.views.push(
            this.roomsView,
            this.furnishingsView,
            this.pinsView,
            this.clothingView,
            this.postersView,
            this.specialsView
        )
        this.tabs.push(roomsTab, furnishingsTab, pinsTab, clothingTab, postersTab, specialsTab)

        let currentX = 0
        this.tabs.forEach((t) => {
            t.view.x = 33
            t.view.y = 85
            this.addChild(t.view)
            t.eventMode = 'static'
            t.interactiveChildren = true
            t.cursor = 'pointer'

            t.y = 44 // static
            t.x = currentX + 28
            currentX += t.getWidth()

            t.setActive(false)

            t.addEventListener('pointerup', (e: FederatedEvent) => {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.PopupTabChange)
                this.setActiveTab(t)
            })
        })

        const yourCreditBg = new Field('b', 77)
        yourCreditBg.x = this.width - 106
        yourCreditBg.y = 47
        this.addChild(yourCreditBg)

        const credIcon = Pooler.newSprite('creds')
        credIcon.scale.set(0.35)
        this.addChild(credIcon)
        credIcon.x = yourCreditBg.x + 4
        credIcon.y = yourCreditBg.y + 3

        this.yourCreditNumText = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        this.yourCreditNumText.anchor.set(0.5, 0)
        this.yourCreditNumText.x = yourCreditBg.x + credIcon.width + (yourCreditBg.width - credIcon.width) / 2
        this.yourCreditNumText.y = yourCreditBg.y + 5
        this.addChild(this.yourCreditNumText)

        // set default tab as active
        this.setActiveTab(roomsTab)

        Client.shared.serverBroker.send('catalog_request')

        this.creditsUpdated()
    }

    creditsUpdated() {
        this.yourCreditNumText.text = '' + Client.shared.selfRecord.getCredits()
    }
}
