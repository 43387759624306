import { TintColor } from '@vmk-legacy/common-ts'
import { Graphics } from 'pixi.js'

export class ThumbPickerColor extends Graphics {
    colorId: number

    constructor(colorId: number, gWidth?: number, gHeight?: number) {
        super()

        this.colorId = colorId

        if (gWidth && gHeight) {
            this.beginFill(TintColor[colorId] ?? colorId)
            this.drawRect(0, 0, gWidth, gHeight)
            this.endFill()
        }
    }
}
