import { Pooler } from '@vmk-legacy/render-utils'
import type { Container, IDestroyOptions } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../Client.js'
import { Fonts } from '../../Fonts.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { ScrollArea } from '../containers/ScrollArea.js'
import { PopupBox } from './PopupBox.js'
import { UIWindow } from './UIWindow.js'

export class RoomInspector extends UIWindow {
    limitTxt: BitmapText
    eventStatus: BitmapText
    queueStatus: BitmapText
    queueCount: BitmapText
    list: Container
    furniData: { [placedId: number]: any } = {}
    data: {
        queue: any[]
        queueClosed: boolean
        roomLimit: number
        entryPaused: boolean
        event: boolean
    } = {}
    private scrollArea: ScrollArea
    private bg: PopupBox

    constructor() {
        super()

        this.isDraggable = true

        this.bg = this.addChild(new PopupBox(160, 150, true))
        this.bg.height = 40
        this.bg.alpha = 0.75

        const toggleBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')
        toggleBtn.position.set(140, 2)
        toggleBtn.addEventListener('pointertap', () => {
            this.visible = false
            this.unhighlight()
        })
        this.addChild(toggleBtn)

        const limitLbl = this.addChild(new BitmapText('LIMIT:', Fonts.Folio_12))
        limitLbl.position.set(3, 5)
        this.limitTxt = this.addChild(new BitmapText('?', Fonts.Foxley_16))
        this.limitTxt.position.set(limitLbl.x + limitLbl.width + 4, limitLbl.y - 2)

        const eventLabel = this.addChild(new BitmapText('EVENT:', Fonts.Folio_12))
        eventLabel.position.set(75, 5)
        this.eventStatus = this.addChild(new BitmapText('?', Fonts.Foxley_16))
        this.eventStatus.position.set(eventLabel.x + eventLabel.width + 4, eventLabel.y - 2)

        const queueLabel = this.addChild(new BitmapText('QUEUE:', Fonts.Folio_12))
        queueLabel.position.set(3, 22)

        this.queueCount = this.addChild(new BitmapText('?', Fonts.Foxley_16))
        this.queueCount.position.set(queueLabel.x + queueLabel.width + 4, queueLabel.y - 2)

        this.queueStatus = this.addChild(new BitmapText('?', Fonts.Foxley_12))
        this.queueStatus.position.set(eventLabel.x, queueLabel.y - 7)

        this.list = Pooler.newContainer()
        this.list.eventMode = 'auto'
        this.list.interactiveChildren = false

        this.scrollArea = this.addChild(new ScrollArea(120, 90, this.list, 0, 2, true))
        this.scrollArea.visible = false
        this.scrollArea.eventMode = 'auto'
        this.scrollArea.position.set(queueLabel.x, this.queueStatus.y + this.queueStatus.height + 15)

        Client.shared.serverBroker.onEvent('inspect', (data: any) => {
            if ('furniData' in data) {
                console.log('got new furniData', data)
                this.furniData = data.furniData

                if (this.visible) {
                    this.highlightPiecesWithData()
                }
                return
            }
            this.data = { ...this.data, ...data }

            if ('roomLimit' in data) {
                this.limitTxt.text = String(data.roomLimit)
            }
            if ('event' in data) {
                this.eventStatus.text = String(data.event ? 'ON' : 'OFF')
                this.eventStatus.updateText()
            }
            this.queueStatus.text =
                'Entry ' +
                (this.data.entryPaused ? 'PAUSED' : 'Open') +
                '\n' +
                'Queue ' +
                (this.data.queueClosed ? 'CLOSED' : 'Open')

            if ('queue' in data) {
                this.queueCount.text = String(data.queue.length)
                this.setQueue(data.queue)
            }
        })
    }

    highlightPiecesWithData() {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            for (const entity of Client.shared.roomViewer.furniController.entities) {
                const itemData = this.furniData[entity.getItem().itemId]
                entity.getParts().forEach((p) => {
                    p.children.forEach((c) => {
                        if (itemData) {
                            c['origtint'] = c.tint
                            c.tint = 0xfa0000
                        } else if (c['origtint']) {
                            c.tint = c['origtint']
                            delete c.origtint
                        }
                    })
                })
            }
        }
    }

    unhighlight() {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            for (const entity of Client.shared.roomViewer.furniController.entities) {
                entity.getParts().forEach((p) => {
                    p.children.forEach((c) => {
                        if (c['origtint']) {
                            c.tint = c['origtint']
                            delete c.origtint
                        }
                    })
                })
            }
        }
    }

    setQueue(queue: [string, string][]) {
        this.list.removeChildren()

        if (queue.length) {
            let nextY = 0
            for (const entity of queue) {
                const i = queue.indexOf(entity)
                const line = i + 1 + '. ' + entity[0]
                const name = new BitmapText(line, Fonts.Foxley_12)
                name.maxWidth = 105

                const platform = new BitmapText(entity[1] || '?', Fonts.Foxley_12)
                name.position.set(2, nextY)
                platform.position.set(110, nextY)
                nextY += name.height + 4
                this.list.addChild(name, platform)
            }
            this.bg.height = 150
            this.scrollArea.setScroll(0)
            this.scrollArea.visible = true
        } else {
            this.scrollArea.visible = false
            this.bg.height = 40
        }
    }

    override destroy(_options?: IDestroyOptions | boolean): void {
        super.destroy(_options)

        this.unhighlight()

        Client.shared.serverBroker.offEvent('inspect')
    }
}
