import { Client } from '../../Client.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class EntityLeaveModule extends ServerEvent {
    static readonly type = MessageType.ENTITY_LEAVE

    async handle(entityId: EntityRoomIdentifier): Promise<void> {
        Client.shared.roomViewer?.removeEntityByRef(entityId)
    }
}
