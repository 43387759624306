import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container } from 'pixi.js'
import { Client } from '../../../../../Client.js'
import { Constants } from '../../../../../Constants.js'
import { EWindow, UILayer } from '../../../../../enums.js'
import { Fonts } from '../../../../../Fonts.js'
import { BitmapTextButton } from '../../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../../buttons/BitmapTextButtonType.js'
import { CheckBox } from '../../../../buttons/CheckBox.js'
import { ImageButton } from '../../../../buttons/ImageButton.js'
import { BasicBox } from '../../../../containers/BasicBox.js'
import { DOMText } from '../../../../DOMText.js'
import { FieldH } from '../../../../fields/FieldH.js'
import type { IWindow } from '../../../IWindow.js'
import { Separator } from '../../../Separator.js'

export class MKBanPopup extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop
    private ign: string
    private id: number

    header: Sprite
    isDraggable = true
    exitBtn: ImageButton

    setVisible(visible: boolean) {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset() {}

    container: Container
    private title: string
    private body: string // \n for newline

    private totalWidth: number
    private totalHeight: number

    private typeBody: BitmapText
    private ownerBody: BitmapText
    private tradeableBody: BitmapText

    private daysTf: DOMText
    private hoursTf: DOMText
    private reasonTf: DOMText
    private permaCheckbox: CheckBox

    private containerContainer: Container

    constructor(id: number, ign: string) {
        super()

        this.id = id
        this.ign = ign

        this.title = 'Ban ' + ign
        this.body = `Complete the form to ban ${ign} (uid ${id})`

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        const bg: BasicBox = new BasicBox(286, 134)
        this.container.addChild(bg)

        const titleBg: FieldH = new FieldH(279)

        this.container.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titleTextWidth: number = titleText.textWidth
        titleText.x = Math.round((bg.width - titleTextWidth) / 2)
        this.container.addChild(titleText)

        const bodyText = new BitmapText(this.body || '', {
            ...Fonts.Foxley_16
        })

        bodyText.x = Math.round((bg.width - bodyText.textWidth) / 2)
        bodyText.y = 38

        this.container.addChild(bodyText)

        this.header = Pooler.newSprite()

        const separator = new Separator(277)
        separator.position.set(15, 54)
        this.container.addChild(separator)

        const days = new BitmapText('DAYS:', {
            ...Fonts.FoxleyBold_16
        })
        days.position.set(15, 60)
        this.container.addChild(days)

        this.daysTf = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'mk.ban.days',
            maxlength: 3,
            fieldWidth: 40,
            fieldHeight: 16,
            fontSize: 16,
            fontColor: '#FFFFFF',
            initialValue: '0',
            bgObject: new FieldH(40),
            padLeft: 9,
            padTop: -3
        })

        this.daysTf.position.set(60, 60)
        this.container.addChild(this.daysTf)

        const hoursOffsetX = 100
        const hours = new BitmapText('HOURS:', {
            ...Fonts.FoxleyBold_16
        })
        hours.position.set(15 + hoursOffsetX, 60)
        this.container.addChild(hours)

        this.hoursTf = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'mk.ban.hrs',
            maxlength: 3,
            fieldWidth: 40,
            fieldHeight: 16,
            fontSize: 16,
            fontColor: '#FFFFFF',
            initialValue: '0',
            bgObject: new FieldH(40),
            padLeft: 9,
            padTop: -3
        })
        this.hoursTf.position.set(60 + hoursOffsetX + 8, 60)
        this.container.addChild(this.hoursTf)

        const permanent = new BitmapText('PERMA:', {
            ...Fonts.FoxleyBold_16
        })
        permanent.position.set(15 + hoursOffsetX + 110, 60)
        this.container.addChild(permanent)
        this.permaCheckbox = new CheckBox('perma')
        this.permaCheckbox.position.set(277, 59)
        this.container.addChild(this.permaCheckbox)

        this.permaCheckbox.addEventListener('pointerup', () => {
            this.permaCheckbox.setActive(!this.permaCheckbox.getActive())
        })

        const reason = new BitmapText('REASON:', {
            ...Fonts.FoxleyBold_16
        })
        reason.position.set(15, 82)
        this.container.addChild(reason)

        this.reasonTf = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'mk.ban.reason',
            maxlength: 128,
            fieldWidth: 209,
            fieldHeight: 16,
            fontSize: 16,
            fontColor: '#FFFFFF',
            initialValue: '',
            bgObject: new FieldH(209),
            padLeft: 9,
            padTop: -3
        })
        this.reasonTf.position.set(60 + 8 + 8, 82)
        this.container.addChild(this.reasonTf)

        const verifyText = new BitmapText('Verify the information above before submitting.', {
            ...Fonts.Foxley_16
        })
        verifyText.tint = 0xff0000
        verifyText.align = 'center'
        verifyText.maxWidth = 280
        verifyText.x = Math.round((bg.width - verifyText.textWidth) / 2)
        verifyText.y = 106
        this.container.addChild(verifyText)

        const submitBtn = new BitmapTextButton(100, 'SUBMIT', BitmapTextButtonType.GREEN)
        submitBtn.position.set(43, 125)
        this.container.addChild(submitBtn)
        submitBtn.addEventListener('pointerup', () => {
            const daysTrimmed = +this.daysTf.getValue().trim()
            const hoursTrimmed = +this.hoursTf.getValue().trim()
            const reasonTrimmed = this.reasonTf.getValue().trim()
            const perma = this.permaCheckbox.getActive()

            if (
                isNaN(daysTrimmed) ||
                isNaN(hoursTrimmed) ||
                !Number.isInteger(daysTrimmed) ||
                !Number.isInteger(hoursTrimmed)
            ) {
                Client.shared.helpers.alert({
                    title: 'Error Banning ' + this.ign,
                    message: 'The days or hours value provided was not an integer. The ban was cancelled.'
                })
                Client.shared.userInterface.removeWindow(this)

                return
            }

            if (reasonTrimmed === '') {
                Client.shared.helpers.alert({
                    title: 'Error Banning ' + this.ign,
                    message: 'A reason was not provided. The ban was cancelled.'
                })
                Client.shared.userInterface.removeWindow(this)

                return
            }

            const totalHours = hoursTrimmed + daysTrimmed * 24

            Client.shared.serverBroker.send('mk_ban', {
                id: this.id,
                hours: totalHours,
                perma: perma,
                reason: reasonTrimmed
            })

            Client.shared.userInterface.removeWindow(this)
        })

        const cancelBtn = new BitmapTextButton(100, 'CANCEL', BitmapTextButtonType.RED)
        cancelBtn.position.set(43 + 115, 125)
        this.container.addChild(cancelBtn)
        cancelBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.removeWindow(this)
        })

        this.containerContainer.addChild(this.container)
        this.addChild(this.containerContainer)
        this.center()
        Client.shared.userInterface.register(this)

        Client.shared.userInterface.bringToFront(this)
    }

    center() {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    tryRemove() {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
