import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { Graphics } from 'pixi.js'
import { Client } from '../Client.js'
import { Constants } from '../Constants.js'
import { UILayer } from '../enums.js'
import { UIWindow } from '../ui/windows/UIWindow.js'
import { RegistrationStep1View } from './RegistrationStep1View.js'
import { RegistrationStep2View } from './RegistrationStep2View.js'
import { RegistrationStep3View } from './RegistrationStep3View.js'

export class RegistrationView extends UIWindow {
    layer = UILayer.GameWindows
    isDraggable = false

    background: Sprite
    private step1?: RegistrationStep1View
    private step2?: RegistrationStep2View
    private step3: RegistrationStep3View
    private nouns: string[]
    private adjectives: string[]

    constructor(private rulesOnly = false) {
        super()
        const g = new Graphics()
        g.beginFill(0xa8d533)
        g.drawRect(0, 0, Constants.SIZE[0], Constants.SIZE[1])
        g.endFill()
        this.addChild(g)
        this.setVisible(false)
    }

    private buildInterface(): void {
        this.background = this.addChild(Pooler.newSprite('regorange.bg'))
        if (!this.rulesOnly) {
            this.step1 = new RegistrationStep1View(this.nouns, this.adjectives, this.provider)
            this.addChild(this.step1)
            this.step2 = new RegistrationStep2View()
            this.step2.visible = false
            this.addChild(this.step2)
        }
        this.step3 = new RegistrationStep3View(this.rulesOnly)
        this.step3.visible = this.rulesOnly

        this.addChild(this.step3)

        this.goToStep(this.rulesOnly ? 3 : 1)
    }

    goToStep(step: number): void {
        this.step1?.setVisible(step === 1)
        this.step2?.setVisible(step === 2)
        this.step3.setVisible(step === 3)
    }

    override setVisible(visible = true): void {
        this.visible = visible
        this.eventMode = visible ? 'static' : 'auto'
    }

    async loadAssets(): Promise<void> {
        console.log('loading reg assets')
        const provided = await this.provider.get(['registration/registration'])

        this.nouns = provided.files.ign_generator.nouns
        this.adjectives = provided.files.ign_generator.adjectives

        this.buildInterface()
    }

    register(): void {
        if (this.rulesOnly) {
            Client.shared.serverBroker.sendAck('rules_ack').then((success) => {
                if (!success) {
                    this.step3.agreeBtn.enable()
                } else {
                    Client.shared.regView = undefined
                    this.setVisible(false)
                    this.destroy()
                }
            })
            return
        }
        const packet = {
            outfit: this.step1.getOutfit(),
            ign: this.step1.getIgn(),
            room: this.step1.getRoomId()
        }
        Client.shared.serverBroker.sendAck('registration_request', packet).then((success) => {
            if (!success) {
                this.step3.agreeBtn.enable()
            } else {
                Client.shared.regView = undefined
                this.setVisible(false)
                this.destroy()
            }
        })
    }
}
