import { Pooler } from '@vmk-legacy/render-utils'
import type { BitmapText, Container, IDestroyOptions, Point, Sprite } from 'pixi.js'
import { Graphics, HTMLText, Text, TextStyle } from 'pixi.js'
import { Client } from '../Client.js'
import { ResponsiveContainer } from './views/AlertView.js'

type InputMode = 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url'

interface IDOMTextParams {
    kind: 'field' | 'area'
    className?: string
    id?: string
    minlength?: number
    maxlength?: number
    fieldWidth?: number
    fieldHeight?: number
    fontSize?: number
    fontColor?: string
    initialValue?: string
    bgObject?: Sprite | Container
    padLeft?: number
    padTop?: number
    noBreaks?: boolean
    enterHint?: string
    inputMode?: InputMode
    lineHeight?: number
    accHint?: string
    pattern?: string
}

export class DOMText extends ResponsiveContainer {
    protected readonly element: HTMLTextAreaElement | HTMLInputElement
    protected readonly rasterText: BitmapText | Text | HTMLText
    protected readonly background: Sprite | Container
    protected readonly domOnly: boolean = false

    protected readonly kind: 'field' | 'area'
    protected readonly fieldWidth: number
    protected readonly fieldHeight: number
    protected readonly fontSize: number
    protected readonly fontColor: string
    protected readonly padLeft: number
    protected readonly padTop: number

    protected readOnly = false

    constructor(opts: IDOMTextParams) {
        super()

        this.kind = opts.kind
        this.fieldWidth = opts.fieldWidth
        this.fieldHeight = opts.fieldHeight
        this.fontSize = opts.fontSize
        this.fontColor = opts.fontColor
        this.padLeft = opts.padLeft || 0
        this.padTop = opts.padTop || 0

        const initialValue = String(opts.initialValue)
        let bgObject = opts.bgObject
        if (!bgObject) {
            bgObject = Pooler.newSprite()
            bgObject.width = this.fieldWidth + this.padLeft
            bgObject.height = this.fieldHeight + this.padTop
        }
        this.background = this.addChild(bgObject)

        this.eventMode = 'static'
        this.cursor = 'text'
        this.addEventListener('pointerdown', () => this.forceFocus())

        this.element = document.createElement(this.kind === 'field' ? 'input' : 'textarea')
        if (this.kind === 'field') {
            this.element.setAttribute(
                'type',
                opts.inputMode === 'email' ? 'email' : opts.inputMode === 'numeric' ? 'number' : 'text'
            )
        } else {
            if (!opts.enterHint) {
                opts.enterHint = 'enter'
            }
        }
        if (opts.id) {
            this.element.id = opts.id
        }
        this.element.classList.add('vmk-input')
        if (opts.className) {
            this.element.classList.add(opts.className)
        }

        if (opts.maxlength) {
            this.element.maxLength = opts.maxlength
        }
        if (opts.minlength) {
            this.element.minLength = opts.minlength
        }
        this.element.value = initialValue
        if (opts.enterHint) {
            this.element.enterKeyHint = opts.enterHint
        }
        if (opts.inputMode) {
            this.element.inputMode = opts.inputMode
        }

        if (opts.accHint) {
            this.element.title = opts.accHint
        }
        if (this.element instanceof HTMLInputElement && opts.pattern) {
            this.element.pattern = opts.pattern
            this.element.spellcheck = false
        }

        this.element.style.width = this.fieldWidth + 'px'
        this.element.style.height = this.fieldHeight + 'px'
        this.element.style.fontFamily = 'web-foxley'
        this.element.style.fontSize = this.fontSize + 'px'
        this.element.style.color = this.fontColor
        this.element.style.lineHeight = (opts.lineHeight || this.fontSize) + 'px'
        this.element.style.visibility = this.domOnly ? null : 'hidden'

        this.element.addEventListener('pointerdown', () => Client.shared.helpers.setInteractive(false), {
            passive: true
        })
        this.element.addEventListener('pointerleave', () => Client.shared.helpers.setInteractive(true), {
            passive: true
        })
        this.element.addEventListener('pointerup', () => Client.shared.helpers.setInteractive(true), { passive: true })
        if (opts.noBreaks) {
            this.element.addEventListener('keydown', (e: KeyboardEvent) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
                    // on enter key press
                    e.preventDefault()
                    return false
                }
            })
        }
        this.element.addEventListener('blur', this.didBlur, { passive: true })
        this.element.addEventListener('focus', this.didFocus, { passive: true })
        this.element.addEventListener('focus', (e) => {
            e.preventDefault()
            document.body.scrollTop = 0
        })

        if (opts.inputMode === 'email') {
            this.element.autocomplete = 'email'
        }
        if (opts.id === 'login.pass') {
            this.element.autocomplete = 'current-password'
            this.element.setAttribute('type', 'password')
        } else {
            if (this.kind === 'field') {
                this.element.placeholder = ' '
            }
        }
        if (opts.id === 'login.pass' || opts.id === 'login.name') {
            this.domOnly = true
            this.element.style.visibility = null
        } else {
            this.element.style.visibility = 'hidden'
        }

        const fontColorUINTStr = ('0x' + this.fontColor.substr(1, this.fontColor.length)) as unknown
        const fontColorNum = fontColorUINTStr as number

        if (!this.domOnly) {
            if (this.kind === 'field') {
                this.rasterText = new HTMLText(initialValue, {
                    fontFamily: 'web-foxley',
                    fontSize: this.fontSize,
                    lineHeight: opts.lineHeight || this.fontSize,
                    align: 'left',
                    fill: fontColorNum,
                    whiteSpace: 'pre',
                    breakWords: true,
                    wordWrap: true,
                    wordWrapWidth: this.fieldWidth,
                    textBaseline: 'alphabetic'
                })

                this.rasterText.style.loadFont('./web-foxley.ttf', { family: 'web-foxley' }).then(() => {
                    this.rasterText.updateText(false)
                })
            } else {
                this.rasterText = new Text(
                    initialValue,
                    new TextStyle({
                        fontFamily: 'web-foxley',
                        fontSize: this.fontSize,
                        lineHeight: opts.lineHeight || this.fontSize,
                        align: 'left',
                        fill: fontColorNum,
                        whiteSpace: 'pre',
                        breakWords: true,
                        wordWrap: true,
                        wordWrapWidth: this.fieldWidth,
                        textBaseline: 'alphabetic'
                    })
                )
            }

            this.rasterText.eventMode = 'auto'
            this.rasterText.position.set(this.padLeft, this.padTop + 2)
            const mask = new Graphics().beginFill(0xffffff).drawRect(0, 0, this.fieldWidth, this.fieldHeight).endFill()
            this.rasterText.addChild(mask)
            this.rasterText.mask = mask
            this.addChild(this.rasterText)
        }

        this.once('added', (p) => {
            document.getElementById('overlay').appendChild(this.getElement())
            if (this.rasterText instanceof HTMLText) {
                this.rasterText.updateText(false)
            }
        })
        this.once('removed', () => this.getElement()?.remove())
    }

    protected didFocus = (): Promise<void> => {
        if (this.readOnly) {
            if (this.rasterText) {
                this.rasterText.visible = true
            }
            return this.setElementVis(true)
        }
        if (this.domOnly) {
            return this.setElementVis(true)
        }
        if (this.rasterText) {
            this.rasterText.visible = false
        }
        return this.setElementVis(true)
    }

    protected didBlur = (): Promise<void> => {
        if (this.domOnly) {
            return Promise.resolve()
        }
        Client.shared.helpers.setInteractive(true)
        this.rasterText.visible = true
        this.updateRasterText()
        return this.setElementVis(false)
    }

    override sizeDidChange(): void {
        if (this.destroyed) {
            return
        }
        const gPos = Client.shared.stage.toLocal(this.getGlobalPosition())
        if (gPos) {
            this.#setGlobalPos(gPos)
        }
    }

    setElementVis(visible: boolean) {
        if (visible) {
            this.sizeDidChange()
        } else if (document.activeElement === this.element) {
            this.forceBlur()
        }
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                this.element.style.visibility = visible ? null : 'hidden'
                resolve()
            }, 0)
        })
    }

    show(): void {
        this.visible = true
        if (this.domOnly) {
            this.setElementVis(true)
        } else {
            this.setElementVis(false)
            this.rasterText.visible = true
        }
    }

    hide(): void {
        this.visible = false
        if (this.element === document.activeElement) {
            this.element.blur()
        }
        this.setElementVis(false)
        this.rasterText.visible = false
    }

    updateRasterText(): void {
        if (this.destroyed || this.domOnly) {
            return
        }
        if (this.kind === 'field') {
            //(this.rasterText as BitmapText).maxWidth = this.fieldWidth;
        } else {
            if (this.rasterText instanceof Text && this.rasterText.style) {
                this.rasterText.style.wordWrapWidth = this.fieldWidth
            }
        }
        this.rasterText.text = this.element.value
        if (this.rasterText instanceof HTMLText && this.rasterText.style) {
            this.rasterText.updateText(false)
        }
    }

    forceFocus(e?: KeyboardEvent): void {
        console.log('forcing focus ' + this.visible + ' ' + this.readOnly)
        if (this.visible && !this.readOnly) {
            this.didFocus().then(() => {
                this.element.focus()
                if (
                    this.element instanceof HTMLTextAreaElement ||
                    ['text', 'search', 'url', 'password'].includes(this.element.type)
                ) {
                    this.element.selectionStart = this.element.selectionEnd = this.element.value.length

                    if (e && e.key.length === 1) {
                        this.element.value += e.key
                    }
                }
            })
        }
    }

    forceBlur(): void {
        if (document.activeElement === this.element) {
            this.element.blur()
            this.didBlur()
        }
    }

    setValue(value: string): void {
        if (this.element.value === value) {
            return
        }
        this.element.value = value
        this.updateRasterText()
    }

    getValue(): string {
        return this.element.value
    }

    setReadonly(value: boolean): void {
        if (this.readOnly !== value) {
            this.readOnly = value
            this.background.cursor = value ? 'default' : 'text'
            this.setElementVis(!value)
            if (this.rasterText) {
                this.rasterText.visible = value
            }
        }
    }

    getElement(): HTMLTextAreaElement | HTMLInputElement {
        return this.element
    }

    #setGlobalPos(coord: Point): void {
        this.element.style.left = Math.round(coord.x + this.padLeft) + 'px'
        this.element.style.top = Math.round(coord.y + this.padTop) + 'px'
    }

    setSubmitHandler(func: () => void): void {
        this.element.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
                // on enter key press
                e.preventDefault()

                func()
                return false
            }
        })
    }

    triggerSubmit(): void {
        this.element.dispatchEvent(new KeyboardEvent('keydown', { code: 'Enter' }))
    }

    override destroy(_options?: IDestroyOptions | boolean): void {
        super.destroy(_options)

        this.getElement()?.remove()
    }
}
