/*
 * Theme.ts
 * VMK Legacy Client
 */

export class Theme {
    static getCurrent(): ThemeDefinition {
        return DefaultTheme
    }
}

interface ThemeDefinition {
    name: string

    label: number

    modalOutline: number
    buttonOutline: number

    panelOutline: number

    background: number
    secondaryBackground: number

    tabContentOutline: number

    fieldBackground: number

    sliderBackground: number
}

const DefaultTheme: ThemeDefinition = {
    name: 'Default',

    label: 0xefefef,

    modalOutline: 0xc0c0c0,
    buttonOutline: 0xefefef,

    panelOutline: 0xadd1fe,

    background: 0x011e55,
    secondaryBackground: 0x275692,

    tabContentOutline: 0xadd1fe,

    fieldBackground: 0x99beff,
    sliderBackground: 0x018bbe
}
