import LegacyWindow from '../../ui/windows/LegacyWindow.js'

export class WinStart extends LegacyWindow {
    protected casts = ['minigame/pirates2_interface']

    constructor(
        protected level: number,
        protected stage: number
    ) {
        super('pirates_start')
    }

    override async windowWasBuilt(): Promise<void> {
        this.setField('pirates.gamestart', 'pirates.gamestart')
        this.setBitmap('pirates.storyline.image', 'storyline.level' + this.level)
    }
}
