import type { EAvatarDir } from '@vmk-legacy/common-ts'
import { Container } from 'pixi.js'
import type { RoomEntityVisualizing } from '../RoomObject.js'
import type { AvatarEntity } from './AvatarEntity.js'

export class InvisibleNPCVisual extends Container implements RoomEntityVisualizing {
    factor!: AvatarEntity

    readonly type = 'npc'

    constructor(readonly tint?: number | string) {
        super()
    }

    getChatTint(): number | string | undefined {
        return this.tint
    }

    applyServerActions(actions: any) {
        //
    }

    setDirection(dir: EAvatarDir): void {}

    setTileSize(tileSize: number): void {}

    update = (): void => {}
}
