import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText, Sprite } from 'pixi.js'
import { Fonts } from '../../Fonts.js'
import type { Friend } from '../windows/messenger/views/Friend.js'

export class FieldMes extends Sprite {
    private user: string
    private onlineState: Container
    private offlineState: Container
    private selectedState: Container
    private selected: boolean
    private text: string
    private isOnline: boolean
    private totalHeight: number
    private friend: Friend

    constructor(user: string, text: string, isOnline: boolean, friend: Friend) {
        super()
        this.user = user
        this.isOnline = isOnline
        this.text = text
        this.friend = friend
        this.onlineState = Pooler.newContainer()
        this.offlineState = Pooler.newContainer()
        this.selectedState = Pooler.newContainer()

        const online = Pooler.newContainer()
        const onlineBG = Pooler.newSprite('field.mes.online')
        online.addChild(onlineBG)
        this.totalHeight = onlineBG.height

        const selected = Pooler.newContainer()
        const selectedBG = Pooler.newSprite('field.mes.selected')
        selected.addChild(selectedBG)

        const offline = Pooler.newContainer()
        const offlineBG = Pooler.newSprite('field.mes.offline')
        offline.addChild(offlineBG)

        const fieldText: BitmapText = new BitmapText(text, {
            ...Fonts.Foxley_16
        })

        this.onlineState.addChild(online)
        this.offlineState.addChild(offline)
        this.selectedState.addChild(selected)

        this.addChild(this.selectedState)
        this.addChild(this.onlineState)
        this.addChild(this.offlineState)
        this.addChild(fieldText)
        fieldText.x = 5
        fieldText.y = 2

        this.selectedState.alpha = 0
        this.onlineState.alpha = isOnline ? 1 : 0
        this.offlineState.alpha = isOnline ? 0 : 1
        this.onlineState.eventMode = 'static'
        this.onlineState.cursor = 'pointer'

        this.selected = false
    }

    setFriend(friend: Friend): void {
        this.friend = friend
    }

    getFriend(): Friend {
        return this.friend
    }

    select(): void {
        this.selectedState.alpha = 1
        this.onlineState.alpha = 0
        this.offlineState.alpha = 0
        this.selected = true
    }

    deselect(): void {
        this.selectedState.alpha = 0
        this.onlineState.alpha = this.isOnline ? 1 : 0
        this.offlineState.alpha = this.isOnline ? 0 : 1
        this.selected = false
    }

    setOffline(): void {
        this.selectedState.alpha = 0
        this.onlineState.alpha = 0
        this.offlineState.alpha = 1
        this.selected = false
    }

    setOnline(online = true): void {
        this.selectedState.alpha = 0
        this.onlineState.alpha = online ? 1 : 0
        this.offlineState.alpha = online ? 0 : 1
        this.selected = false
    }

    setSelected(selected: boolean): void {
        if (selected) {
            this.select()
        } else {
            this.deselect()
        }
    }

    isSelected(): boolean {
        return this.selected
    }

    getText(): string {
        return this.text
    }

    getUser(): string {
        return this.user
    }

    getOnline(): boolean {
        return this.isOnline
    }

    getTotalHeight(): number {
        return this.totalHeight
    }
}
