import { Minigame } from '@vmk-legacy/common-ts'
import type { EPotcAmmo, PotcMode, POTCStage } from '@vmk-legacy/potc-engine'
import { Vector } from '@vmk-legacy/potc-engine'
import type { ICastProvides } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import type { PotcGameRejoin } from '../../room/renderer/extensions/PiratesPreshowHandler.js'
import { Helpers } from '../../util/Helpers.js'
import { MinigameDelegate } from '../MinigameDelegate.js'
import { PotcFactorCannonball } from './PotcFactorCannonball.js'
import type { PotcFactorShip } from './PotcFactorShip.js'
import { PotcModal } from './PotcModal.js'
import { PotcScene } from './PotcScene.js'
import { PotcSound } from './PotcSound.js'
import { PotcUI } from './PotcUI.js'
import { WinStart } from './WinStart.js'

const CannonballSpeed = 1400
const CannonballTTL = 25

export class PotcDelegate extends MinigameDelegate {
    readonly codename = Minigame.Pirates

    private state: {
        port: { gold: number; vault: number }
        pirates: { gold: number; vault: number }
    }
    private myTeam: 'port' | 'pirates'
    private health: number
    private ammo: number

    private ui: PotcUI
    private scene?: PotcScene

    private stageStart: number
    private stageLength: number
    private stageTimer

    mode: PotcMode

    sound = new PotcSound(this)

    assets: ICastProvides

    protected async loadGame(): Promise<void> {
        Client.shared.loadingView.setWaitText('Loading assets...')

        this.assets = await Client.shared.assetLoader.loadCasts(
            [
                'minigame/pirates2_elements',
                'minigame/pirates2_interface',
                'minigame/pirates2_minigame',
                'minigame/pirates2_ship',
                'minigame/pirates2_sound'
            ],
            { retains: this.provider }
        )

        if (!this.assets) {
            throw new Error('Could not load POTC assets')
        }

        this.ui = Client.shared.viewport.addChild(new PotcUI(this))

        await this.ui.waitToBeBuilt()
    }

    protected async beginGame(data: PotcGameRejoin): Promise<void> {
        console.log('PotcDelegate.beginGame', data)
        // server has given us the OK
        Client.shared.loadingView.setVisible(false)

        const modal = Client.shared.userInterface.register(new PotcModal())
        await modal.embed(new WinStart(data.level, data.stage))

        await this.renderStage(data)

        this.ui.visible = true

        Client.shared.userInterface.hideToolbars()

        console.log('PotcDelegate.CHECKPOINT')

        if ('start' in data && data.start) {
            Client.shared.userInterface.removeWindow(modal)
            this.stageStart = data.start
            this.stageLength = data.length
            this.startStage()
        } else {
            Client.shared.serverBroker.onceEvent(
                'potc_stage_starting',
                (data: {
                    start: number
                    length: number
                }) => {
                    console.log('potc_stage_starting', data)
                    Client.shared.userInterface.removeWindow(modal)
                    this.stageStart = data.start
                    this.stageLength = data.length
                    this.startStage()
                }
            )
        }

        Client.shared.serverBroker.onEvent('potc_next_stage', (data) => {
            this.nextStage(data)
        })
        Client.shared.serverBroker.onEvent('potc_pos', ([id, x, y]: [number, number, number]) => {
            const obj = this.scene.getEntityByRef(id) as PotcFactorShip
            if (obj) {
                obj.worldPosition = new Vector(x, y)
            }
        })
        Client.shared.serverBroker.onEvent(
            'potc_nav',
            ([id, curX, curY, x, y]: [number, number, number, number, number]) => {
                const obj = this.scene.getEntityByRef(id) as PotcFactorShip
                if (obj) {
                    obj.collideCount = 0
                    obj.worldPosition = new Vector(curX, curY)
                    obj.navigationTarget = new Vector(x, y)
                    obj.needToSync = true
                    obj.state.move = true
                    obj.state.shoot = false
                }
            }
        )
        Client.shared.serverBroker.onEvent('potc_dir', ([id, dir]: [number, number]) => {
            const entity = this.scene.getEntityByRef(id) as PotcFactorShip
            if (entity) {
                entity.direction = dir
            }
        })
        Client.shared.serverBroker.onEvent(
            'potc_shot',
            ([id, x, y, type, dir, side]: [number, number, number, EPotcAmmo, number, 'l' | 'r']) => {
                const entity = this.scene.getEntityByRef(id) as PotcFactorShip
                if (entity) {
                    const ball = new PotcFactorCannonball(this.scene, entity.worldPosition)

                    this.scene.addEntity(ball)
                }
            }
        )
        Client.shared.serverBroker.onEvent('potc_state', ([id, state]: [number, [string, boolean][]]) => {
            const entity = this.scene.getEntityByRef(id) as PotcFactorShip
            if (entity) {
                entity.stateUpdated(state)
            }
        })
        Client.shared.serverBroker.onEvent('potc_stage_ending', () => {
            console.log('Stage ended, showing recap')
            // show recap
            clearTimeout(this.stageTimer)
            this.ui.setTime('0:00')
        })

        Client.shared.serverBroker.send('potc_ready_stage')
    }

    async nextStage(data: PotcGameRejoin): Promise<void> {
        console.log('PotcDelegate.nextStage')

        const modal = Client.shared.userInterface.register(new PotcModal())
        await modal.embed(new WinStart(data.level, data.stage))

        await this.renderStage(data)

        Client.shared.serverBroker.onceEvent('potc_stage_starting', (data: { start: number; length: number }) => {
            Client.shared.userInterface.removeWindow(modal)
            this.stageStart = data.start
            this.stageLength = data.length
            this.startStage()
        })
        Helpers.delay(500).then(() => {
            Client.shared.serverBroker.send('potc_ready_stage')
        })
    }

    protected rejoin(data: { token: string; data: PotcGameRejoin }): void {
        this.token = data.token

        this.beginGame(data.data)
    }

    shipShoot = ([shooter, cannonball, x, y]: [number]) => {
        // const ship = this.scene?.getEntity(shooter)?.visual;
        // if (ship) {
        // 	if (ship.useAmmo()) {
        // 		const params: any = {
        // 			ref: cannonball, type: 2, owner: shooter, team: ship.team, dir: 0
        // 		};
        // 		const worldPos = ship.worldPosition;
        // 		const diff = new Vec3(x, y, 0).subtract(worldPos);
        // 		const dir = PirateShip.getAngleFromComponents(diff.x, diff.y);
        // 		const newPos = new Vec3(worldPos.x + PirateShip.velTable[0][dir] * ship.bowCannonOffset / 256,
        // 			worldPos.y + PirateShip.velTable[1][dir] * ship.bowCannonOffset / 256, 500);
        // 		params.worldPos = newPos;
        // 		const vel = new Vec3(PirateShip.velTable[0][dir] * CannonballSpeed / 256,
        // 			PirateShip.velTable[1][dir] * CannonballSpeed / 256, 0);
        // 		const invincible = ship.state.invincibility;
        //
        // 		const nextGolden = ship.ammo[0] === 1;
        //
        // 	}
        // }
    }

    private startStage(): void {
        console.log('PotcDelegate.startStage')

        clearInterval(this.stageTimer)
        this.stageTimer = setInterval(() => {
            const secsLeft = this.stageLength - Math.floor((Date.now() - this.stageStart) / 1000)
            const secPart = secsLeft % 60

            this.ui.setTime(`${Math.floor(secsLeft / 60)}:${(secPart < 10 ? '0' : '') + secPart}`)

            this.scene.moveClouds()
        }, 1000)
        const secsPassed = Math.floor((Date.now() - this.stageStart) / 1000)

        if (secsPassed < 2) {
            this.ui.showPrepare()
        }
    }

    private async renderStage(data: PotcGameRejoin): Promise<void> {
        console.log('PotcDelegate.renderStage')
        this.scene?.teardown()
        this.scene?.destroy()

        if (Client.shared.roomViewer !== this.scene) {
            await Client.shared.roomViewer?.teardown()
            Client.shared.roomViewer?.destroy()
        }

        const stageData: POTCStage = await import(
            '../../potc_stages/stage.pirates_level' + data.level + '_stage' + data.stage + '.json'
        )
        const collisionData: number[][] = await import(
            '../../potc_stages/collision.stage.pirates_level' + data.level + '_stage' + data.stage + '.json'
        )

        Client.shared.roomViewer = this.scene = Client.shared.viewport.addChildAt(
            new PotcScene(this, stageData, collisionData, data),
            0
        )

        await this.scene.loadAssets()

        this.ui.setScore(data.teams.port.wins, data.teams.pirates.wins)
        this.ui.setGold(data.teams.port.vault, 'port')
        this.ui.setGold(data.teams.pirates.vault, 'pirates')
        this.ui.setVault(data.teams.pirates.vault, 'port')
        this.ui.setVault(data.teams.pirates.vault, 'pirates')

        await this.scene.reveal()
    }

    async teardown(): Promise<void> {
        await super.teardown()
        const listeners = [
            'potc_stage_starting',
            'potc_next_stage',
            'potc_pos',
            'potc_nav',
            'potc_dir',
            'potc_state',
            'potc_stage_ending'
        ]
        for (const event of listeners) {
            Client.shared.serverBroker.offEvent(event)
        }

        clearTimeout(this.stageTimer)

        this.ui?.destroy()
        this.ui = undefined

        this.scene?.teardown()
        this.scene?.destroy()
        this.scene = undefined
    }
}
