import type { Minigame } from '@vmk-legacy/common-ts'
import { AssetProvider } from '@vmk-legacy/render-utils'
import { Client } from '../Client.js'

export abstract class MinigameDelegate {
    abstract readonly codename: Minigame
    // set skipToken to true to skip starting session on server
    skipToken = false
    protected token: string
    protected lastCheckpoint: string

    protected provider = new AssetProvider(Client.shared.assetLoader)

    // 1. (optional) do any setup needed before loading, such as asking for input mode
    protected initialize(): Promise<void> {
        return Promise.resolve()
    }

    // 2. server started session, actually begin loading assets
    protected abstract loadGame(): Promise<void>

    // 3. server has set the start time, actually begin game
    protected abstract beginGame(data?: any): void

    getObscuringTopHeight(): number {
        return 0
    }

    async teardown(): Promise<void> {
        if (Client.shared.runningMinigame === this) {
            Client.shared.runningMinigame = null
        }
        this.token = null
        this.lastCheckpoint = null
        Client.shared.serverBroker.offEvent('mg_fail')
        Client.shared.serverBroker.offEvent('mg_load')
        Client.shared.serverBroker.offEvent('mg_ok')
        this.provider.teardown()
    }

    prepare(rejoin?: any): void {
        if (Client.shared.runningMinigame) {
            throw new Error('This client already has a minigame running. Please tear it down first.')
        }

        Client.shared.runningMinigame = this
        Client.shared.serverBroker.onceEvent('mg_fail', (error?: string) => {
            Client.shared.helpers.alert(
                error || 'Oops, there was a problem starting your minigame session. Please try again.'
            )
            this.teardown()
        })
        if (rejoin) {
            this.initialize().then(() => this.loadGame().then(() => this.rejoin(rejoin)))
        } else {
            this.initialize().then(() => this.#askForSession())
        }
    }

    #askForSession(): void {
        if (this.skipToken) {
            this.loadGame().then(() => this.#notifyBegin())
            return
        }
        Client.shared.serverBroker.onceEvent('mg_load', (token) => {
            if (this.token) {
                return
            }
            this.token = token
            this.loadGame().then(() => this.#notifyBegin())
        })

        Client.shared.serverBroker.send('mg_launch', this.codename)
    }

    #notifyBegin(): void {
        if (this.skipToken) {
            this.beginGame()
            return
        }
        Client.shared.serverBroker.onceEvent('mg_ok', (data?: any) => {
            this.beginGame(data)
        })

        Client.shared.serverBroker.send('mg_begin')
    }

    protected checkpoint(name: string, score: number): void {
        if (this.lastCheckpoint === name) {
            return
        }
        this.lastCheckpoint = name
        Client.shared.serverBroker.send('mg_chk', [name, score])
    }

    protected scorePing(score: number, additions: number[]): void {
        Client.shared.serverBroker.send('mg_png', [score, ...additions])
    }

    protected rejoin(data: { token: string; [k: string]: any }): void {
        throw new Error('This game does not support rejoining, but rejoin method was called.')
    }
}
