import { BitmapText, Sprite, Texture } from 'pixi.js'
import { Fonts } from '../../Fonts.js'
import type { IAccordionField } from './IAccordionField.js'

export class FieldHlp extends Sprite implements IAccordionField {
    private activeState: Texture
    private selectedState: Texture
    private selected: boolean
    private text: string
    private fieldText: BitmapText

    constructor(text: string) {
        super()
        this.text = text

        this.activeState = Texture.from('field.hlp.active')
        this.selectedState = Texture.from('field.hlp.selected')

        this.fieldText = new BitmapText(text, {
            ...Fonts.Foxley_16
        })

        this.addChild(this.fieldText)
        this.fieldText.x = 5
        this.fieldText.y = 5

        this.texture = this.activeState
        this.eventMode = 'static'
        this.cursor = 'pointer'
        this.cursor = 'pointer'

        this.selected = false
    }

    select() {
        this.texture = this.selectedState
        this.selected = true
    }

    deselect() {
        this.texture = this.activeState

        this.selected = false
    }

    setSelected(selected: boolean) {
        if (selected) {
            this.select()
        } else {
            this.deselect()
        }
    }

    isSelected(): boolean {
        return this.selected
    }

    getText(): string {
        return this.text
    }

    setText(text: string) {
        this.text = text
        this.fieldText.text = text
    }
}
