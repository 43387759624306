import { Client } from '../../../Client.js'
import { UILayer } from '../../../enums.js'
import { PotcModal } from '../../../minigame/potc/PotcModal.js'
import LegacyWindow from '../../../ui/windows/LegacyWindow.js'
import { PiratesNewGameWin } from './PiratesNewGameWin.js'
import type { PiratesPreshowHandler, SignupGame } from './PiratesPreshowHandler.js'
import { PiratesSelectShipWin } from './PiratesSelectShipWin.js'
import type { PiratesStartPlayers } from './PiratesStartPlayers.js'

export class PiratesSignupView extends LegacyWindow {
    layer = UILayer.GameWindows

    protected casts = ['minigame/pirates2_interface']

    games: [SignupGame, SignupGame] = []

    createGameWin?: PotcModal
    changeShipWin?: PotcModal
    startPlayersWin?: PiratesStartPlayers

    constructor(readonly handler: PiratesPreshowHandler) {
        super('vmk_pirates_preshow_signup')
    }

    override async windowWasBuilt(): Promise<void> {
        const newGameBtn = this.getElement('button.newgame')
        newGameBtn.addEventListener('pointertap', () => {
            if (this.createGameWin) {
                Client.shared.userInterface.bringToFront(this.createGameWin)
            } else {
                this.createGameWin = Client.shared.userInterface.register(new PotcModal(new PiratesNewGameWin(this)))
            }
        })

        for (let i = 0; i < 2; i++) {
            const gameNum = i + 1
            this.games[i] = {
                title: this.getElement('signup.level.team' + gameNum)
            } as SignupGame

            for (const team of ['port', 'pirates']) {
                const color = {
                    port: 'red',
                    pirates: 'blue'
                }[team]

                this.games[i][team] = {
                    join: this.getElement('button.join.' + color + 'team' + (gameNum === 2 ? '2' : '')),
                    leave: this.getElement('button.leave.' + color + 'team' + (gameNum === 2 ? '2' : '')),
                    players: [1, 2, 3, 4].map((n) => {
                        const shipBtn = this.getElement(
                            'button.piratesship' + (gameNum === 2 ? '2' : '') + '.' + color + n
                        )
                        shipBtn.addEventListener('pointertap', () => this.changeShip())
                        return {
                            name: this.getElement('team.' + color + (gameNum === 2 ? '2' : '') + '.player' + n),
                            shipBtn,
                            shipIcon: this.getElement('piratesship' + (gameNum === 2 ? '2' : '') + '.' + color + n)
                        }
                    })
                }

                this.games[i][team].join.addEventListener('pointertap', () => this.handler.joinGame(i, team))
                this.games[i][team].leave.addEventListener('pointertap', () => this.handler.leaveGame(i, team))
            }
        }
    }

    changeShip() {
        if (this.changeShipWin) {
            Client.shared.userInterface.bringToFront(this.changeShipWin)
        } else {
            this.changeShipWin = Client.shared.userInterface.register(
                new PotcModal(new PiratesSelectShipWin(this, this.handler.myInfo.ship))
            )
        }
    }
}
