import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RefreshBillboardsModule extends ServerEvent {
    static readonly type = MessageType.REFRESH_BILLBOARDS

    async handle(data: any) {
        console.log('Asked to reload billboards')

        Client.shared.billboardMgr.reload()
    }
}
