import { Minigame } from '@vmk-legacy/common-ts'
import { Client } from '../../Client.js'
import { UILayer } from '../../enums.js'
import { JungleCruiseDelegate } from '../../minigame/jungle_cruise/JungleCruiseDelegate.js'
import { MusicMixerDelegate } from '../../minigame/music_mixer/MusicMixerDelegate.js'
import { PotcDelegate } from '../../minigame/potc/PotcDelegate.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class GameJoinModule extends ServerEvent {
    static override readonly type = MessageType.GAME_JOIN

    override async handle(data: {
        game: Minigame
        restore?: any
    }): Promise<void> {
        Client.shared.userInterface.closeWindows(UILayer.GameWindows)

        if (Client.shared.userInterface?.toolbar) {
            Client.shared.userInterface.toolbar.chatbarInput.setValue('')
        }

        if (Client.shared.roomViewer) {
            Client.shared.viewport.removeChild(Client.shared.roomViewer)
            await Client.shared.roomViewer.teardown()
            Client.shared.roomViewer.destroy()
            Client.shared.roomViewer = null
        }

        Client.shared.loadingView.setStatusText('game.name.' + data.game)
        Client.shared.loadingView.setWaitText('Starting game...')
        Client.shared.loadingView.setVisible(true)

        switch (data.game) {
            case Minigame.JungleCruise:
                new JungleCruiseDelegate().prepare(data.restore)
                return
            case Minigame.MonoMixer:
            case Minigame.StreetMixer:
                new MusicMixerDelegate(data.game).prepare()
                break
            case Minigame.Pirates:
                new PotcDelegate().prepare(data.restore)
                break
            default:
                break
        }
    }
}
