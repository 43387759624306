import type { Ticket } from './Ticket.js'

export class StaffData {
    private tickets: Ticket[]

    constructor() {
        this.tickets = []
    }

    getTickets(): Ticket[] {
        return this.tickets
    }

    clearTickets(): void {
        this.tickets = []
    }
}
