import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { BitmapTextButton } from '../../buttons/BitmapTextButton.js'
import { Client } from '../../../Client.js'
import { Helpers } from '../../../../src/util/Helpers.js'
import { AvatarVisual } from '../../../room/entities/AvatarVisual.js'
import { EWindow } from '../../../enums.js'
import { ImageButton } from '../../buttons/ImageButton.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import { UIWindow } from '../UIWindow.js'
import { UIWindowTab } from '../UIWindowTab.js'
import { UIWindowType } from '../UIWindowType.js'
import { DOMText } from '../../DOMText.js'
import { FieldG } from '../../fields/FieldG.js'
import { CharacterClothingView } from './views/CharacterClothingView.js'
import { CharacterAvatarView } from './views/CharacterAvatarView.js'

export class CharacterWindow extends UIWindow {
    readonly kind = EWindow.Character

    isDraggable = true
    exitBtn: ImageButton

    avatar!: AvatarVisual

    avatarView!: CharacterAvatarView
    hatsView!: CharacterClothingView
    topsView!: CharacterClothingView
    bottomsView!: CharacterClothingView
    shoesView!: CharacterClothingView
    //accessoriesView!: CharacterClothingView

    //Saved Selections
    storedHair!: string | number
    storedHairColor!: string | number
    storedHead!: string | number
    storedSkinColor!: string | number

    constructor() {
        super(360, UIWindowType.CHARACTER)
        this.position.set(120, 40)
        this.avatar = new AvatarVisual(this.provider, 5)
    }

    override async windowWasBuilt(): Promise<void> {
        this.exitBtn = new ImageButton('button.close.active', 'button.close.pressed')
        this.addChild(this.exitBtn)
        this.exitBtn.x = this.header.x + this.header.width - 18
        this.exitBtn.y = this.header.y + 2

        this.avatar.setUpdatedOutfit(Client.shared.selfRecord.getOutfit())
        this.avatar.x = 415
        this.avatar.y = 90
        this.avatar.pivot.y = -130
        this.avatar.scale.set(2)
        this.avatar.addEventListener('pointertap', () => {
            Helpers.delay(1500).then(() => {
                this.avatar.wave()
                Client.shared.slowTicker.add(this.avatar.update, this as any)
            })
            Helpers.delay(4500).then(() => {
                Client.shared.slowTicker.remove(this.avatar.update, this as any)
            })
        })
        this.addChild(this.avatar)

        this.avatarView = new CharacterAvatarView(this.provider, this.avatar)
        const avatarTab = new UIWindowTab('AVATAR', this.avatarView)
        this.addChild(avatarTab)

        this.hatsView = new CharacterClothingView(this.provider, this.avatar)
        const hatsTab = new UIWindowTab('HATS', this.hatsView)
        this.addChild(hatsTab)

        this.topsView = new CharacterClothingView(this.provider, this.avatar)
        const topsTab = new UIWindowTab('TOPS', this.topsView)
        this.addChild(topsTab)

        this.bottomsView = new CharacterClothingView(this.provider, this.avatar)
        const bottomsTab = new UIWindowTab('BOTTOMS', this.bottomsView)
        this.addChild(bottomsTab)

        this.shoesView = new CharacterClothingView(this.provider, this.avatar)
        const shoesTab = new UIWindowTab('SHOES', this.shoesView)
        this.addChild(shoesTab)

        //this.accessoriesView = new CharacterClothingView(this.provider, this.avatar)
        //const accessoriesTab = new UIWindowTab('ACCESSORIES', this.accessoriesView)
        //this.addChild(accessoriesTab)

        this.views.push(
            this.avatarView,
            this.hatsView,
            this.topsView,
            this.bottomsView,
            this.shoesView
            //this.accessoriesView,
        )
        this.tabs.push(
            avatarTab,
            hatsTab,
            topsTab,
            bottomsTab,
            shoesTab
            //accessoriesTab
        )

        let currentX = 0
        this.tabs.forEach((t) => {
            t.y = 44 // static
            t.x = currentX + 28
            currentX += t.getWidth()

            t.addEventListener('pointerup', (e: FederatedEvent) => {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.PopupTabChange)
                this.setActiveTab(t)
                console.log(t.id)
                switch (t.id) {
                    case 'HATS':
                        this.hatsView.updateHatOptions()
                        break
                    case 'TOPS':
                        this.topsView.updateShirtOptions()
                        break
                    case 'BOTTOMS':
                        this.bottomsView.updatePantsOptions()
                        break
                    case 'SHOES':
                        this.shoesView.updateShoesOptions()
                        break
                    case 'ACCESSORIES':
                        //console.log("He has risen bb gurl");
                        break
                    default:
                        break
                }
            })
        })

        const signatureHeadline = new BitmapText('MY SIGNATURE', {
            fontName: 'Folio',
            fontSize: 12
        })
        this.addChild(signatureHeadline)
        signatureHeadline.x = 30
        signatureHeadline.y = 340

        const signatureField = new DOMText({
            kind: 'area',
            className: 'clothing',
            id: 'avi.sig',
            maxlength: 64,
            fieldWidth: 290,
            fieldHeight: 34,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: Client.shared.selfRecord.getSignature(),
            bgObject: new FieldG(290, 24),
            padLeft: 7,
            padTop: 0,
            noBreaks: true
        })
        signatureField.position.set(signatureHeadline.x, signatureHeadline.y + 20)
        this.addChild(signatureField)

        const saveButton = new BitmapTextButton(100, 'SAVE', 'd')
        saveButton.x = signatureField.x
        saveButton.y = signatureField.y + 45
        this.addChild(saveButton)

        saveButton.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('figure_change_request', {
                outfit: this.avatar.getOutfit(),
                signature: signatureField.getValue()
            })
            Client.shared.userInterface.removeWindow(this)
        })

        const rotateButton = new BitmapTextButton(100, 'ROTATE', 'b')
        rotateButton.x = 365
        rotateButton.y = signatureField.y + 25
        this.addChild(rotateButton)

        rotateButton.addEventListener('pointerup', () => {
            this.avatar.rotate()
        })

        this.setActiveTab(avatarTab)
    }

    getStoredAvatarEntries(type: string): any {
        switch (type) {
            case 'head':
                return this.storedHead
            case 'skin-color':
                return this.storedSkinColor
            case 'hair':
                return this.storedHair
            case 'hair-color':
                return this.storedHairColor
            default:
                throw new Error('Invalid Type (Get): ' + type)
        }
    }

    setStoredAvatarEntries(type: string, id: string | number) {
        switch (type) {
            case 'head':
                this.storedHead = id
                break
            case 'skin-color':
                this.storedSkinColor = id
                break
            case 'hair':
                this.storedHair = id
                break
            case 'hair-color':
                this.storedHairColor = id
                break
            default:
                throw new Error('Invalid Type (Set): ' + type)
        }
    }

    inventoryUpdated(): void {
        this.avatarView.update()
        this.hatsView.updateHatOptions()
        this.bottomsView.updatePantsOptions()
        this.shoesView.updateShoesOptions()
        this.topsView.updateShirtOptions()
    }
}
