import { Client } from '../../../Client.js'
import { Constants } from '../../../Constants.js'
import { UILayer } from '../../../enums.js'
import LegacyWindow from '../LegacyWindow.js'

export class NPCDialogWindow extends LegacyWindow {
    layer = UILayer.GameWindows
    windowTitle = 'NPCDialogWindow'

    constructor(
        readonly title: string,
        readonly body: string,
        readonly npcGfx: string
    ) {
        super('vmk_npc_greeting')
    }

    async windowWasBuilt(): Promise<void> {
        this.setBitmap('npc.greeting', this.npcGfx)
        this.setField('npc.window.title', this.title)
        this.setField('npc.greeting.text', this.body)
        this.getElement('npc.greeting.close')?.addEventListener('pointerup', () =>
            Client.shared.userInterface.removeWindow(this)
        )
    }

    sizeDidChange() {
        this.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }
}
