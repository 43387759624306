import { Minigame } from '@vmk-legacy/common-ts'
import { SoundManager } from '@vmk-legacy/render-utils'
import type { Container, Graphics, Point } from 'pixi.js'
import { Client } from '../../Client.js'
import { AvatarVisual } from '../../room/entities/AvatarVisual.js'
import { MinigameDelegate } from '../MinigameDelegate.js'
import { JungleCruiseBoat } from './JungleCruiseBoat.js'
import { JungleCruiseGameController } from './JungleCruiseGameController.js'
import { JungleCruiseRoom } from './JungleCruiseRoom.js'
import { JungleCruiseUI } from './JungleCruiseUI.js'

export enum InputMode {
    MouseKeyboard = 0,
    Touch = 1
}

export class JungleCruiseDelegate extends MinigameDelegate {
    readonly codename = Minigame.JungleCruise

    soundMap: Map<string, AudioBuffer>
    stageData: Map<string, any>
    ui: JungleCruiseUI
    animals: any

    controller: JungleCruiseGameController
    rails: Graphics[]

    rooms = ['dock', 'cambodian', 'tiki', 'indy', 'tent', 'safari']
    levels = [1, 2, 3]
    currentRoom = 0
    currentLevel = 0
    currentViewer: JungleCruiseRoom

    viewers: { [k: string]: JungleCruiseRoom } = {}

    #pinger: number

    inputMode: InputMode

    player: AvatarVisual
    boat: JungleCruiseBoat

    protected override async initialize(): Promise<void> {
        if (Client.shared.supportsMouse && Client.shared.supportsTouch) {
            const useTouch = await Client.shared.helpers.alert({
                title: 'Input Mode',
                message:
                    'Your device supports both mouse & keyboard and touch input. Which would you like to use to play Jungle Cruise Photo Safari?',
                okLabel: 'Touch',
                cancelLabel: 'Mouse'
            })

            this.inputMode = useTouch ? InputMode.Touch : InputMode.MouseKeyboard
        } else {
            this.inputMode = Client.shared.supportsTouch ? InputMode.Touch : InputMode.MouseKeyboard
        }
    }

    /**
     * Loads the stage backgrounds, layers,
     * and game resources
     */
    protected override async loadGame(): Promise<void> {
        Client.shared.loadingView.setWaitText('Loading assets...')
        this.soundMap = new Map<string, AudioBuffer>()
        this.stageData = new Map<string, any>()

        this.controller = new JungleCruiseGameController(this)

        const suffix = Client.shared.holiday === 'xmas' ? '.xmas' : ''

        const provided = await this.provider.get([
            'minigame/junglecruise_animals' + suffix,
            'minigame/junglecruise_sound',
            'minigame/junglecruise_stages',
            'minigame/junglecruise_boat' + suffix
        ])

        if (!provided) {
            throw new Error('Could not load game assets.')
        }

        this.animals = provided.files.animals
        this.soundMap = provided.sounds

        for (const f in provided.files) {
            this.stageData.set(f, provided.files[f])
        }

        for (const r of this.rooms) {
            const room = new JungleCruiseRoom(this, r)
            await room.loadAssets(false)
            this.viewers[r] = room
        }
        this.player = new AvatarVisual(this.viewers['dock'].provider, 3, Client.shared.selfRecord.getIgn())
        this.player.setUpdatedOutfit(Client.shared.selfRecord.getOutfit())
        this.player.scale.set(0.75)
        this.boat = new JungleCruiseBoat('red', 'up')

        this.ui = new JungleCruiseUI(this)
        this.ui.y = Client.shared.userInterface.getObscuringTopHeight() - this.getObscuringTopHeight()
        Client.shared.userInterface.register(this.ui)

        Client.shared.userInterface.toolbar.chatbarInput.hide()
        Client.shared.userInterface.toolbar.disableChatbar()
    }

    protected override beginGame(): void {
        Client.shared.loadingView.setVisible(false)

        this.drawRoom(this.rooms[this.currentRoom], this.levels[this.currentLevel])

        this.#pinger = window.setInterval(() => {
            const points = this.controller.getPoints()
            if (!points) {
                return
            }
            this.scorePing(points, this.controller.consumePending())
        }, 3500)
    }

    drawRoom(roomName: string, level: number): void {
        console.log('JC START Room ' + roomName + ' Level ' + level)
        if (Client.shared.roomViewer && Client.shared.roomViewer !== this.currentViewer) {
            Client.shared.roomViewer.teardown()
        }
        this.currentViewer?.resetForReuse()

        const newViewer = this.viewers[roomName]
        newViewer.setupJCLevel(level)

        Client.shared.roomViewer = this.currentViewer = Client.shared.viewport.addChildAt(newViewer, 0)

        Client.shared.userInterface.register(this.ui)

        this.currentViewer.reveal()

        this.checkpoint(roomName + level, this.controller.getPoints())
    }

    override getObscuringTopHeight(): number {
        return 35
    }

    endGame(): void {
        window.clearInterval(this.#pinger)
        Client.shared.loadingView.setStatusText('Jungle Cruise Dock')
        Client.shared.loadingView.setWaitText('Processing your score...')
        Client.shared.loadingView.setVisible(true)

        const pending = this.controller.consumePending()
        if (pending.length) {
            this.scorePing(this.controller.getPoints(), pending)
        }
        Client.shared.serverBroker.send('mg_end', [this.token, this.lastCheckpoint, this.controller.getPoints()])
        this.teardown()
    }

    override async teardown(): Promise<void> {
        await super.teardown()

        console.log('tearing down jc delegate')

        for (const v in this.viewers) {
            if (this.viewers.hasOwnProperty(v)) {
                await this.viewers[v].teardown()
            }
        }

        JungleCruiseRoom.rockTex = null
        JungleCruiseRoom.gasTex = null
        JungleCruiseRoom.filmTex = null

        this.soundMap.forEach((snd) => SoundManager.shared.release(snd))
        this.soundMap.clear()
        this.stageData.clear()

        window.clearInterval(this.#pinger)
        this.controller = null
        this.currentLevel = this.currentRoom = 0

        this.currentViewer = null

        if (this.ui) {
            if (this.ui.mask) {
                Client.shared.stage.mask = Client.shared.stage.addChildAt(this.ui.mask as Container, 0)
            }
            this.ui.destroy()
            this.ui = null
        }
    }

    nextStage(): void {
        this.currentRoom++

        if (this.currentRoom >= this.rooms.length) {
            this.currentRoom = 0
            this.currentLevel++
        }

        if (this.currentLevel >= this.levels.length) {
            this.endGame()
            return
        }

        this.drawRoom(this.rooms[this.currentRoom], this.levels[this.currentLevel])
    }

    vectorToTriple(shockwaveVector: string): {
        x: number
        y: number
        z: number
    } {
        shockwaveVector = shockwaveVector.replace(/\s/g, '')
        shockwaveVector = shockwaveVector.replace('vector(', '')
        shockwaveVector = shockwaveVector.replace(')', '')

        const nums = shockwaveVector.split(',')

        return {
            x: +nums[0],
            y: +nums[1],
            z: +nums[2]
        }
    }

    animalWithin(center: Point): boolean {
        if (!this.currentViewer) {
            return false
        }
        const point = this.currentViewer.toLocal(center, Client.shared.viewport)

        if (this.currentViewer.activeAnimals.length === 0) {
            return false
        }

        // get adjacent animals
        for (const animal of this.currentViewer.activeAnimals) {
            if (animal.snapped) {
                continue
            }

            const screenX = animal.x - this.currentViewer.sprites.pivot.x
            const screenY = animal.y - this.currentViewer.sprites.pivot.y

            const distance = Math.hypot(point.x - screenX, point.y - screenY)

            if (distance < 100) {
                return true
            }
        }

        return false
    }
}
