import { Pooler } from '@vmk-legacy/render-utils'
import type { Text } from 'pixi.js'
import { BLEND_MODES, Texture } from 'pixi.js'
import { Client } from '../../Client.js'
import { UILayer } from '../../enums.js'
import { MessageType } from '../../server/MessageType.js'
import type { DOMText } from '../../ui/DOMText.js'
import LegacyWindow from '../../ui/windows/LegacyWindow.js'
import { Helpers } from '../../util/Helpers.js'
import { Validator } from '../../util/Validator.js'
import type { PotcDelegate } from './PotcDelegate.js'
import { ClientChat } from '@vmk-legacy/server-protos'

export class PotcUI extends LegacyWindow {
    layer = UILayer.GameWindows
    protected casts = ['minigame/pirates2_interface']

    private timeTxt: Text

    private goldPort: Text
    private goldPirates: Text

    private vaultPort: Text
    private vaultPirates: Text

    private scoreTxt: Text
    private chatBar: DOMText

    constructor(readonly delegate: PotcDelegate) {
        super('vmk_pirates_ui_ctf')

        this.visible = false
    }

    override async windowWasBuilt(): Promise<void> {
        this.goldPort = this.getElement('gold.portroyal')
        this.goldPirates = this.getElement('gold.pirates')

        this.vaultPort = this.getElement('vault.portroyal')
        this.vaultPirates = this.getElement('vault.pirates')

        this.timeTxt = this.getElement('gametime')
        this.scoreTxt = this.getElement('stagescores')

        this.chatBar = this.getElement('pirates.chat.field')
        this.chatBar.setSubmitHandler(() => {
            const chatEl = this.chatBar.getElement()
            const message = this.chatBar
                .getValue()
                .trim()
                .replace(/[\u2018\u2019]/g, "'")
                .replace(/[\u201C\u201D]/g, '"')

            console.log('>> before supported char check ' + message)
            if (message.length === 0 || !Validator.isSupportedChar(message)) {
                return
            }
            // if (UserInterface.filter.isProfane(message)) {
            //     const clean = UserInterface.filter.clean(message)
            //
            //     if (clean !== message) {
            //         chatEl.style.color = 'red'
            //         this.chatBar.setValue(clean)
            //         return
            //     }
            // }

            Client.shared.serverBroker.send(new ClientChat({ message }))

            this.chatBar.setValue('')
            chatEl.style.color = '#fff'
        })
    }

    setTime(time: string) {
        this.timeTxt.text = time
    }

    setScore(port: number, pirates: number) {
        this.scoreTxt.text = `${port}-${pirates}`
    }

    setGold(gold: number, team: 'pirates' | 'port') {
        if (team === 'port') {
            this.goldPort.text = String(gold)
        } else {
            this.goldPirates.text = String(gold)
        }
    }

    setVault(vault: number, team: 'pirates' | 'port') {
        if (team === 'port') {
            this.vaultPort.text = String(vault)
        } else {
            this.vaultPirates.text = String(vault)
        }
    }

    async showPrepare() {
        const splashBg = this.addChild(Pooler.newSprite('pirates.start.bg'))
        splashBg.blendMode = BLEND_MODES.SCREEN
        splashBg.position.set(400, 300)

        const splashNum = this.addChild(Pooler.newSprite('pirates.start.3'))
        splashNum.position.set(400, 300)
        this.delegate.sound.playCountdown(0)

        await Helpers.delay(1000)

        splashNum.texture = Texture.from('pirates.start.2')
        splashNum.anchor.copyFrom(splashNum.texture.defaultAnchor)
        this.delegate.sound.playCountdown(1)
        await Helpers.delay(1000)

        splashNum.texture = Texture.from('pirates.start.1')
        splashNum.anchor.copyFrom(splashNum.texture.defaultAnchor)
        this.delegate.sound.playCountdown(2)
        await Helpers.delay(1000)

        splashNum.texture = Texture.from('pirates.start.fight')
        splashNum.anchor.copyFrom(splashNum.texture.defaultAnchor)
        this.delegate.sound.playCountdown(3)
        await Helpers.delay(1000)

        Pooler.release(splashNum)
        Pooler.release(splashBg)
    }
}
