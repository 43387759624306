export class Ticket {
    ticketId: number
    state: number
    senderId: number
    sender: string
    reportedUser: string
    roomId: number
    modId: number
    modIgn: string
    issue: string
    type: number
    createdAt: Date
    updatedAt: Date

    // tslint:disable-next-line:max-line-length
    constructor(
        ticketId: number,
        state: number,
        senderId: number,
        sender: string,
        reportedUser: string,
        roomId: number,
        modId: number,
        modIgn: string,
        issue: string,
        type: number,
        createdAt: Date,
        updatedAt: Date
    ) {
        this.ticketId = ticketId
        this.state = state
        this.senderId = senderId
        this.sender = sender
        this.reportedUser = reportedUser
        this.roomId = roomId
        this.modId = modId
        this.modIgn = modIgn
        this.issue = issue
        this.type = type
        this.createdAt = new Date(createdAt)
        this.updatedAt = new Date(updatedAt)
    }
}
