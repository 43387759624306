import type { FederatedEvent } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../Client.js'
import { Fonts } from '../../Fonts.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { DOMText } from '../DOMText.js'
import { FieldG } from '../fields/FieldG.js'
import type { HelpWindow } from './HelpWindow.js'
import { UIWindowView } from './UIWindowView.js'

export class IGNView extends UIWindowView<HelpWindow> {
    padding = 10

    constructor() {
        super()
        const header = new BitmapText('CHANGE YOUR IN-GAME NAME', {
            fontName: 'Folio',
            fontSize: 12,
            align: 'left'
        })
        this.addChild(header)

        const reasonHeader = new BitmapText(
            "Feeling like a change of things? Request a new in-game name here! Names can only be changed once in a 6 month period. You'll receive a message when your request has been reviewed.\n\nRequirements: Letters, numbers, underscore (_), dash (-), and period (.). 2-25 long. Must start with letter and end with letter or number",
            {
                ...Fonts.Foxley_16,
                align: 'left'
            }
        )
        reasonHeader.maxWidth = 290
        reasonHeader.y = 24
        this.addChild(reasonHeader)

        const nameHeader = new BitmapText('Enter your desired in-game name:', {
            ...Fonts.Foxley_16,
            align: 'left'
        })
        nameHeader.maxWidth = 290
        nameHeader.y = reasonHeader.y + reasonHeader.height + 15
        this.addChild(nameHeader)

        const nameTF = new DOMText({
            kind: 'field',
            className: 'help',
            id: 'cfh.ign',
            pattern: '(?!.*(?:[_\\-.]{2}))[A-Za-z]{1}[A-Za-z0-9_.\\-]{0,23}[A-Za-z0-9]{1}',
            minlength: 2,
            maxlength: 25,
            fieldWidth: 270,
            fieldHeight: 18,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: '',
            bgObject: new FieldG(275, 11),
            padLeft: 7,
            padTop: -1
        })
        nameTF.y = nameHeader.y + nameHeader.height + 10

        this.addChild(nameTF)

        const submitBtn: BitmapTextButton = new BitmapTextButton(145, 'SUBMIT REQUEST', 'c')
        submitBtn.y = nameTF.y + nameTF.height + 20
        this.addChild(submitBtn)
        submitBtn.addEventListener('pointerup', (e: FederatedEvent) => {
            submitBtn.disable()
            if (nameTF.getValue().trim() !== '') {
                Client.shared.serverBroker
                    .sendAck('change_ign_request', {
                        ign: nameTF.getValue().trim()
                    })
                    .then((success) => {
                        if (success) {
                            Client.shared.userInterface.removeWindow(this.parent)
                        } else {
                            submitBtn.enable()
                        }
                    })
            }
        })
    }
}
