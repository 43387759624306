import { Client } from '../../Client.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class TransitionModel extends ServerEvent {
    static readonly type = MessageType.TRANSITION_ROOM_MODEL

    async handle(variation: string | undefined) {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            Client.shared.roomViewer.transitionVariation(variation)
        }
    }
}
