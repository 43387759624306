/*
 * FurniSelectionPopup.ts
 * VMK Legacy Client
 */

import { BitmapTextButtonType } from '../../ui/buttons/BitmapTextButtonType.js'
import { ImageButton } from '../../ui/buttons/ImageButton.js'
import { Button } from '../../ui/layoutui/Button.js'
import { Text } from '../../ui/layoutui/Text.js'
import { View } from '../../ui/layoutui/View.js'
import { HStack, VStack } from '../../ui/layoutui/VStack.js'
import type FurniController from './FurniController.js'

export class FurniSelectionPopup extends View {
    constructor(readonly furniController: FurniController) {
        super()
    }

    override body = () =>
        new VStack(
            {},
            new HStack(
                {},
                new Text(this.furniController.getActiveName()).foregroundColor(0xffffff),

                new Button(new ImageButton('button.closeb.active', 'button.closeb.pressed', '', ''))
                    .onTapGesture(() => this.furniController.onFurniControllerClose())
                    .padding(['leading'], 15)
            )
                .padding(['vertical'], 2)
                .padding(['leading'], 15)
                .padding(['trailing'], 2)
                .background(0x022c63)
                .cornerRadius(5),

            new HStack(
                { spacing: 10 },
                new Button('Rotate', BitmapTextButtonType.GREEN)
                    .frame({ width: 75 })
                    .onTapGesture(() => this.furniController.activeFurni.rotate())
                    .when(this.furniController.canMove()),
                new Button('Move', BitmapTextButtonType.GREEN)
                    .frame({ width: 75 })
                    .onTapGesture(() => this.furniController.setMoveMode(true))
                    .when(this.furniController.canMove())
            ),
            new HStack(
                { spacing: 10 },
                new Button('Info', BitmapTextButtonType.GREEN)
                    .frame({ width: 75 })
                    .onTapGesture(() => this.furniController.showInfoForActive()),
                new Button('Take Away', BitmapTextButtonType.RED)
                    .frame({ width: 75 })
                    .onTapGesture(() => this.furniController.takeAwayActive())
                    .when(this.furniController.canMove())
            )
        )
}
