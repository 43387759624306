import { Client } from '../../Client.js'
import { Ticket } from '../../data/Ticket.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class CFHModule extends ServerEvent {
    static readonly type = MessageType.CFH_RECEIVED

    async handle(tickets: any) {
        Client.shared.staffData.clearTickets()

        tickets.forEach((ticketData: any) => {
            const ticket: Ticket = new Ticket(
                ticketData.id,
                ticketData.state,
                ticketData.sender_id,
                ticketData.sender,
                ticketData.reported_user,
                ticketData.room_id,
                ticketData.mod_id,
                ticketData.mod_ign,
                ticketData.issue,
                ticketData.type,
                ticketData.created_at,
                ticketData.updated_at
            )
            Client.shared.staffData.getTickets().push(ticket)
        })
    }
}
