import type { ICastProvides } from '@vmk-legacy/render-utils'

export class UISoundLibrary {
    static ClosePopup: AudioBuffer
    static OpenPopup: AudioBuffer
    static OpenMap: AudioBuffer
    static MapRoomSelection: AudioBuffer
    static MapTabChange: AudioBuffer
    static PopupTabChange: AudioBuffer
    static UIClick: AudioBuffer
    static LoadingLoop: AudioBuffer
    static MapLoop: AudioBuffer
    static MailReceived: AudioBuffer
    static Camera: AudioBuffer
    static TurnstileEntrance: AudioBuffer
    static Teleport2: AudioBuffer
    static Teleport4: AudioBuffer
    static NautilusPickupLong1: AudioBuffer
    static NautilusPickupLong2: AudioBuffer
    static NautilusPickupShort1: AudioBuffer
    static NautilusPickupShort5: AudioBuffer

    static all: Map<string, AudioBuffer>

    static async loadSounds(provided: ICastProvides): Promise<void> {
        if (!provided.sounds.size || ![...provided.sounds.values()].every((v) => v)) {
            throw new Error('UI sounds cast did not load')
        }

        UISoundLibrary.ClosePopup = provided.sounds.get('close-popup')
        UISoundLibrary.OpenPopup = provided.sounds.get('open-popup')
        UISoundLibrary.OpenMap = provided.sounds.get('open-map')
        UISoundLibrary.Camera = provided.sounds.get('VMK-camera')
        UISoundLibrary.MapRoomSelection = provided.sounds.get('map-room-selection')
        UISoundLibrary.MapTabChange = provided.sounds.get('map-tab-change')
        UISoundLibrary.PopupTabChange = provided.sounds.get('popup-tab-change')
        UISoundLibrary.UIClick = provided.sounds.get('UI-click')
        UISoundLibrary.LoadingLoop = provided.sounds.get('VMK-loadingloop-v1')
        UISoundLibrary.MapLoop = provided.sounds.get('vmk-map-5')
        UISoundLibrary.MailReceived = provided.sounds.get('UI-message')
        UISoundLibrary.TurnstileEntrance = provided.sounds.get('VMK-entrance-1')
        UISoundLibrary.Teleport2 = provided.sounds.get('teleport-2')
        UISoundLibrary.Teleport4 = provided.sounds.get('teleport-4')
        UISoundLibrary.NautilusPickupLong1 = provided.sounds.get('nautilus-pickup-long-1')
        UISoundLibrary.NautilusPickupLong2 = provided.sounds.get('nautilus-pickup-long-2')
        UISoundLibrary.NautilusPickupShort1 = provided.sounds.get('nautilus-pickup-short-1')
        UISoundLibrary.NautilusPickupShort5 = provided.sounds.get('nautilus-pickup-short-5')
        UISoundLibrary.all = provided.sounds
    }
}
