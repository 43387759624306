import { ExternalConfigManager } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class UpdateTextModule extends ServerEvent {
    static readonly type = MessageType.UPDATED_TEXT

    async handle(data: [string, string] | true) {
        if (data === true) {
            const externals = await Client.shared.api.loadExternals(['texts'])

            if (externals.texts) {
                for (const k in externals.texts._vars) {
                    ExternalConfigManager.instance.setVariable(k, externals.texts._vars[k])
                }
                for (const k in externals.texts._texts) {
                    ExternalConfigManager.instance.setText(k, externals.texts._texts[k])
                }
            }

            return
        }
        console.log('Text updated: ' + data[0] + ' => ' + data[1])
        ExternalConfigManager.instance.setText(data[0], data[1])
    }
}
