import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import type { IPaginator } from '../../../server/messages/MessagesModule.js'
import LegacyWindow from '../LegacyWindow.js'
import { PhotoCatalogThumbs } from './PhotoCatalogThumbs.js'
import { PhotoCatalogView } from './PhotoCatalogView.js'

export class PhotoCatalog extends LegacyWindow {
    readonly kind = EWindow.PhotoCatalog

    photoRequest: Promise<IPaginator<[number, string]>>

    #thumbs: PhotoCatalogThumbs
    #view: PhotoCatalogView

    constructor() {
        super('vmk_basic', undefined, {
            'basic.window.title': 'photocatalog_confirm'
        })

        this.#thumbs = new PhotoCatalogThumbs(this)
        this.#view = new PhotoCatalogView(this)
        this.requestPage(1).then(() => this.#thumbs.populate())

        Promise.all([this.#thumbs.waitToBeBuilt(), this.#view.waitToBeBuilt()]).then(() => {
            this.embed(this.#thumbs)
        })
    }

    async windowWasBuilt(): Promise<void> {
        Client.shared.userInterface.closeWindow(EWindow.CamViewfinder)
    }

    async viewPhoto(photoId: number) {
        this.#view.photoData = await this.photoRequest
        this.#view.showPhoto(this.#view.photoData.data.findIndex((p) => p[0] === photoId))
        this.embed(this.#view)

        // this.#view.photoIndex = this.#photoData.data.findIndex(d => d[0] === photoId) || 0;
        // this.#view.showPhoto(this.#view.photoIndex);
    }

    async requestPage(page: number, repopThumbs = false) {
        console.log('Showing page ' + page)
        this.photoRequest = Client.shared.serverBroker.sendAck('photos', page)
        await this.photoRequest

        if (repopThumbs) {
            this.#thumbs.populate()
        }
    }

    showThumbs() {
        this.embed(this.#thumbs)
    }
}
