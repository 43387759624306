import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container } from 'pixi.js'
import { Client } from '../../../../../Client.js'
import { Constants } from '../../../../../Constants.js'
import { EWindow, UILayer } from '../../../../../enums.js'
import { Fonts } from '../../../../../Fonts.js'
import { BitmapTextButton } from '../../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../../../../buttons/ImageButton.js'
import { BasicBox } from '../../../../containers/BasicBox.js'
import { DOMText } from '../../../../DOMText.js'
import { FieldG } from '../../../../fields/FieldG.js'
import { FieldH } from '../../../../fields/FieldH.js'
import type { IWindow } from '../../../IWindow.js'
import { Separator } from '../../../Separator.js'

export class MKSendMessagePopup extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.AlwaysOnTop
    private ign: string
    private id: number

    header: Sprite
    isDraggable = true
    exitBtn: ImageButton

    setVisible(visible: boolean) {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset() {}

    container: Container
    private title: string
    private body: string // \n for newline

    private totalWidth: number
    private totalHeight: number

    private msgField: DOMText

    private containerContainer: Container

    constructor(id: number, ign: string) {
        super()

        this.id = id
        this.ign = ign

        this.title = `Send Message to ${ign}`
        this.body = 'Enter a message (sends from "VMK Staff")'

        this.exitBtn = new ImageButton('button.closeb.active', 'button.closeb.pressed')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        const bg = new BasicBox(286, 270)
        this.container.addChild(bg)

        const titleBg = new FieldH(279)

        this.container.addChild(titleBg)
        titleBg.x = 9
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titleTextWidth: number = titleText.textWidth
        titleText.x = Math.round((bg.width - titleTextWidth) / 2)
        this.container.addChild(titleText)

        const bodyText = new BitmapText(this.body || '', {
            ...Fonts.Foxley_16
        })

        bodyText.x = Math.round((bg.width - bodyText.textWidth) / 2)
        bodyText.y = 38

        this.container.addChild(bodyText)
        this.header = Pooler.newSprite()

        const separator = new Separator(277)
        separator.position.set(15, 54)
        this.container.addChild(separator)

        const offY = -14

        this.msgField = new DOMText({
            kind: 'area',
            className: 'mk',
            id: 'mk.sig',
            maxlength: 2048,
            fieldWidth: 275,
            fieldHeight: 160,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: '',
            bgObject: new FieldG(275, 160),
            padLeft: 9,
            padTop: -3
        })
        this.msgField.position.set(10, 82 + offY)
        this.container.addChild(this.msgField)

        const submitBtn = new BitmapTextButton(100, 'SEND', BitmapTextButtonType.GREEN)
        submitBtn.position.set(43, 20 + this.msgField.y + this.msgField.height)
        this.container.addChild(submitBtn)
        submitBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('mk_send_message', {
                id: id,
                body: this.msgField.getValue().trim()
            })
            Client.shared.userInterface.removeWindow(this)
        })

        const cancelBtn = new BitmapTextButton(100, 'CANCEL', BitmapTextButtonType.RED)
        cancelBtn.position.set(43 + 115, 20 + this.msgField.y + this.msgField.height)
        this.container.addChild(cancelBtn)
        cancelBtn.addEventListener('pointerup', () => {
            Client.shared.userInterface.removeWindow(this)
        })

        this.containerContainer.addChild(this.container)
        this.addChild(this.containerContainer)
        this.center()
        Client.shared.userInterface.bringToFront(this)
    }

    center() {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - this.width) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - this.height) / 2)
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
