import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../../Client.js'
import { Fonts } from '../../../../Fonts.js'
import type { AvatarTooltip } from '../../../AvatarTooltip.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../buttons/BitmapTextButtonType.js'
import { ScrollArea } from '../../../containers/ScrollArea.js'
import { DOMText } from '../../../DOMText.js'
import { Field } from '../../../fields/Field.js'
import { UIWindowView } from '../../UIWindowView.js'
import { ChatLogContainer } from '../ChatLogContainer.js'
import type { ModWindow } from '../ModWindow.js'

export class ChatLogView extends UIWindowView<ModWindow> {
    padding = 5
    private logsContainer: Container
    private scrollArea: ScrollArea
    private toolTip: AvatarTooltip
    private nameTf: DOMText

    constructor() {
        super()

        const ign = new BitmapText('In-Game name: ', {
            ...Fonts.Foxley_16
        })
        ign.y = 3
        this.addChild(ign)
        const searchBtn = new BitmapTextButton(79, 'SEARCH', BitmapTextButtonType.LIGHT_BLUE)
        searchBtn.x = 239
        this.addChild(searchBtn)
        searchBtn.addEventListener('pointerup', () => {
            if (this.nameTf.getValue().trim() === '') {
                return
            }
            Client.shared.serverBroker.send('mk_ulogs_request', {
                ign: this.nameTf.getValue()
            })
            const oldVal = this.nameTf.getValue()
            this.nameTf.setValue('')
            this.nameTf.getElement().placeholder = oldVal
        })

        const text = new BitmapText('or ', {
            ...Fonts.Foxley_16
        })
        text.y = 3
        text.x = searchBtn.x + 90
        this.addChild(text)

        this.nameTf = new DOMText({
            kind: 'field',
            className: 'mk',
            id: 'chlog.name',
            maxlength: 20,
            fieldWidth: 130,
            fieldHeight: 19,
            fontSize: 16,
            fontColor: '#ffffff',
            initialValue: '',
            bgObject: new Field('b', 120),
            padLeft: 6,
            padTop: -2
        })
        this.nameTf.position.set(96, 2)
        this.addChild(this.nameTf)

        this.nameTf.setSubmitHandler(() => {
            if (this.nameTf.getValue().trim() === '') {
                return
            }
            Client.shared.serverBroker.send('mk_ulogs_request', {
                ign: this.nameTf.getValue()
            })
            // this.setSearching(true);
            const oldVal = this.nameTf.getValue()
            this.nameTf.setValue('')
            this.nameTf.getElement().placeholder = oldVal
        })

        const currRoomBtn = new BitmapTextButton(100, 'CURRENT ROOM', BitmapTextButtonType.LIGHT_BLUE)
        currRoomBtn.x = text.x + 25
        currRoomBtn.y = 0
        this.addChild(currRoomBtn)
        currRoomBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('mk_rlogs_request')
        })

        this.logsContainer = Pooler.newContainer()

        this.once('added', () => {
            Client.shared.serverBroker.onEvent('mk_ulogs', (data) => {
                this.setRequests(data.chatLogs)
            })

            Client.shared.serverBroker.onEvent('mk_rlogs', (data) => {
                this.setRequests(data.roomLogs)
            })
        })
    }

    searchFor(ign: string) {
        this.nameTf.setValue(ign)
        this.nameTf.triggerSubmit()
    }

    setRequests(requests: any) {
        this.logsContainer.removeChildren()
        for (let i = 0; i < requests.length; i++) {
            // if (requests.length <= i) break;

            const req = requests[i]
            // console.log(req);

            let warning = ''
            if (req.hasOwnProperty('warning') && req['warning'] === true) {
                warning = req['warningReason']
            }
            const h = 35
            const reqBox: ChatLogContainer = new ChatLogContainer(
                676,
                h,
                req.fromName,
                req.roomName,
                req.message,
                req.timeStamp
            )
            reqBox.position.set(0, (h + 6) * i)
            this.logsContainer.addChild(reqBox)
        }

        if (this.scrollArea) {
            this.removeChild(this.scrollArea)
        }

        this.scrollArea = new ScrollArea(640, 320, this.logsContainer)
        this.addChild(this.scrollArea)
        this.scrollArea.y = 32
    }

    update() {}
}
