import { EPerm } from '@vmk-legacy/common-ts'
import { AvatarOutfit } from '@vmk-legacy/render-utils'
import { AlphaFilter } from 'pixi.js'
import { Client } from '../../Client.js'
import { DummyItem } from '../../data/ItemStack.js'
import { type EntityRoomIdentifier, EWindow } from '../../enums.js'
import type { EntityDataPayload } from '../../server/messages/EntityJoinModule.js'
import { LanyardBox } from '../../ui/windows/LanyardBox.js'
import type { WalkableRoomViewer } from '../renderer/WalkableRoomViewer.js'
import { ERoomObjType } from '../RoomObject.js'
import { AvatarVisual } from './AvatarVisual.js'
import { WalkableEntity } from './WalkableEntity.js'

/**
 * A room entity which is a human avatar.
 */
export class AvatarEntity extends WalkableEntity<AvatarVisual> {
    readonly entityType = ERoomObjType.Avatar

    private invisible = false
    private levitate = false
    private rotating = false

    constructor(
        roomContext: WalkableRoomViewer,
        instanceId: EntityRoomIdentifier,
        protected outfit: AvatarOutfit,
        tileRef: number,
        direction: number,
        actions: any,
        name: string,
        protected lanyard: DummyItem[],
        protected badges: number[],
        protected signature: string,
        entryAnim?: (number | string)[],
        protected pronouns?: any
    ) {
        super(roomContext, instanceId, name, tileRef, direction, actions)

        this.getVisual().setUpdatedOutfit(outfit)

        this.entryAnim = entryAnim
    }

    override isDummy(): boolean {
        console.log('isDummy', this.id, this.id.startsWith('furni-'))
        return this.id.startsWith('furni-')
    }

    protected createVisual(): AvatarVisual {
        const visual = new AvatarVisual(
            this.roomContext.provider,
            this.direction,
            this.name,
            this.actions,
            this.isDummy()
        )

        visual.factor = this

        return visual
    }

    async populateLanyard(): Promise<void> {
        const lanyard = (await Client.shared.userInterface.getWin(EWindow.Lanyard, true)) as LanyardBox

        if (!lanyard) {
            return
        }

        lanyard.setIgn(this.name)
        lanyard.setSignature(this.signature)
        lanyard.setBadges(this.badges)
        lanyard.setPins(this.lanyard)
        lanyard.setOutfit(this.outfit)
        lanyard.setPronouns(this.pronouns)

        if (this.badges.includes(4) && !Client.shared.selfRecord.can(EPerm.MKAccessClient)) {
            lanyard.setSelf(true)
        } else {
            lanyard.setSelf(this.id === Client.shared.selfRecord.getRoomIdentifier())
        }

        lanyard.currentInstanceId = this.id
        lanyard.updatePosition()
    }

    setEmote(emote: number): void {
        this.visual.setEmote(emote, emote !== 1000)
    }

    setLanyard(lanyard: DummyItem[]): void {
        this.lanyard = lanyard
    }

    setBadges(badges: number[]): void {
        this.badges = badges
    }

    setSignature(signature: string): void {
        this.signature = signature
    }

    setOutfit(outfit: AvatarOutfit): void {
        this.visual.setUpdatedOutfit(outfit)
        if (!this.isDummy()) {
            this.roomContext.updateTooltip(this)
        }
        this.outfit = outfit
    }

    setInvisible(invisible: boolean): void {
        if (invisible === this.invisible) {
            return
        }
        this.invisible = invisible

        if (invisible && (this.isSelf() || Client.shared.selfRecord.can(EPerm.GameInvisibility))) {
            this.visual.filters = [new AlphaFilter(0.5)]
            this.visual.visible = true
        } else {
            this.visual.filters = []
            this.visual.visible = !invisible
        }
        if (this.visual.pulsing) {
            this.visual.pulseOverlay()
        }
        if (invisible) {
            this.roomContext.hideArrow(this)
        }
    }

    isStaff(): boolean {
        return this.badges.includes(4) // staff badge
    }

    updateFigure(data: EntityDataPayload): void {
        console.log('>> Updating figure ' + data.id + ' to ', data)
        const newLanyard = data.lanyard.map((d) => new DummyItem(...d))
        const outfit = AvatarOutfit.from(data.outfit)
        this.setOutfit(outfit)
        this.setName(data.ign)
        this.setBadges(data.badges)
        this.setSignature(data.signature)
        this.setLanyard(newLanyard)
        this.setInvisible(data.invisible === true)

        Client.shared.userInterface.getWin(EWindow.Lanyard).then((lanyard) => {
            if (lanyard instanceof LanyardBox && lanyard.currentInstanceId === data.id) {
                lanyard.setIgn(data.ign)
                lanyard.setSignature(data.signature)
                lanyard.setBadges(data.badges)
                lanyard.setPins(newLanyard)
                lanyard.setOutfit(outfit)
                lanyard.setPronouns(data.pronouns)
                lanyard.updatePosition()
            }
        })
    }

    isInvisible(): boolean {
        return this.invisible
    }

    getOutfit(): AvatarOutfit {
        return this.visual.getOutfit()
    }
}
