import type { EPronounOpt } from '@vmk-legacy/common-ts'
import { Filter } from 'bad-words'
import type { Sprite } from 'pixi.js'
import { Client } from '../../../Client.js'
import ClientHelpers from '../../../ClientHelpers.js'
import Config from '../../../Config.js'
import { MusicMixer } from '../../../minigame/music_mixer/MusicMixer.js'
import { Helpers } from '../../../util/Helpers.js'
import type TextScroller from '../../TextScroller.js'
import { PickMix } from './PickMix.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export abstract class MixController extends WalkableRoomExtension {
    static filter = new Filter({
        placeHolder: '#'
    })

    protected mixScroller: TextScroller

    abstract readonly mixer: 'street' | 'mono'
    abstract readonly pickSongSprites: string[]

    player: MusicMixer

    protected muted = false

    protected specialTracks: {
        file: string
        title: string
        duration_ms: number
    }[]
    protected playingTrack: {
        track: { file: string; title: string; duration_ms: number }
        since: number
    }

    specialPlayer: HTMLAudioElement

    async init() {
        await super.init()

        for (const name of this.pickSongSprites) {
            const sprite = this.room.sprites.getChildByName(name) as Sprite
            sprite.eventMode = 'static'
            sprite.cursor = 'pointer'

            ClientHelpers.genHitmap(sprite.texture, 125)

            sprite.addEventListener('pointerup', async () => {
                const picker = new PickMix(this.mixer)

                await Client.shared.userInterface.register(picker, true).waitToBeBuilt()
            })
        }

        this.player = new MusicMixer()
        this.player.castProvided(
            await this.room.provider.get(['minigame/music_mixer', 'minigame/music_mixer_samples_' + this.mixer])
        )

        this.mixScroller = this.setupMixScroller()
        this.room.sprites.addChild(this.mixScroller)

        Client.shared.slowTicker.add(this.mixScroller.tick, this.mixScroller)

        Client.shared.serverBroker.onEvent(
            'mix_play',
            (data: {
                player: string
                pronouns?: EPronounOpt
                name: string
                sounds: any
            }) => {
                this.playMix(data)
            }
        )
        Client.shared.serverBroker.onEvent('mix_stop', () => {
            this.mixDidEnd()
        })
        Client.shared.serverBroker.onEvent('special_tracks', (data: any[]) => {
            this.specialTracks = data
        })
        Client.shared.serverBroker.onEvent('play_special_track', (data: any) => {
            this.playSpecialTrack(data.track, data.since)
        })
        Client.shared.serverBroker.onEvent('play_room_audio', () => {
            if (this.specialPlayer) {
                this.specialPlayer.pause()
                this.specialPlayer.remove()
                this.specialPlayer = null
            }

            this.mixDidEnd()
        })

        return this
    }

    playMix(data: {
        player: string
        pronouns?: EPronounOpt
        name: string
        sounds: any
    }) {
        console.log('[Mixer] Playing mix: ', data)
        this.mixDidStart()

        if (MixController.filter.isProfane(data.name)) {
            data.name = MixController.filter.clean(data.name)
        }

        this.mixScroller.setText(
            `Playing ${data.name && data.name !== 'Untitled' ? data.name : 'Live Performance'} mixed by ${data.player}`
        )
        this.player.populateSong({
            name: '',
            sounds: data.sounds
        })
        this.player.score.playFromBeginning(true)
        this.player.score
            .getFinishPromise()
            .then(() => this.mixDidEnd())
            .catch(() => null)
    }

    receiveInitialData(data?: {
        mix?: { player: string; name: string; sounds: any[] }
        specialTracks?: { file: string; title: string; duration_ms: number }[]
        specialTrackPlaying?: {
            track: { file: string; title: string; duration_ms: number }
            since: number
        }
    }) {
        if (data.mix) {
            this.playMix(data.mix)
        }
        this.specialTracks = data.specialTracks
        if (data.specialTrackPlaying) {
            this.playSpecialTrack(data.specialTrackPlaying.track, data.specialTrackPlaying.since)
        }
    }

    async playSpecialTrack(track: { file: string; title: string; duration_ms: number }, since: number) {
        console.log('Playing special track', track)
        this.playingTrack = {
            track,
            since
        }
        if (!this.specialPlayer) {
            this.specialPlayer = new Audio()
        }
        this.specialPlayer.pause()
        this.mixScroller.setText('Loading...$$')

        this.specialPlayer.oncanplaythrough = async () => {
            this.specialPlayer.oncanplaythrough = null
            const elapsed = Date.now() - since
            console.log('Special track ready, elapsed: ' + elapsed)

            if (elapsed < 0) {
                await Helpers.delay(-elapsed)
            } else {
                this.specialPlayer.currentTime = elapsed / 1000
            }
            this.mixScroller.setText('Playing ' + track.title)
            this.mixDidStart()

            await this.specialPlayer.play()
        }
        this.specialPlayer.onerror = () => {
            this.mixScroller.setText('Could not load track!')
        }

        this.specialPlayer.preload = 'auto'
        this.specialPlayer.loop = false
        this.specialPlayer.autoplay = false
        this.specialPlayer.src = Config.environment.uploadsRoot + '/' + track.file
    }

    protected mixDidStart() {
        this.muted = true
        Client.shared.roomViewer.disableSound = true
        Client.shared.soundScore.pause()
    }

    protected mixDidEnd() {
        console.log('>> mixDidEnd')
        this.muted = false
        this.player.score?.teardown()
        Client.shared.roomViewer.disableSound = false
        Client.shared.soundScore.resume()
    }

    abstract setupMixScroller(): TextScroller

    teardown() {
        super.teardown()

        this.specialPlayer?.remove()
        this.specialPlayer = null

        this.player.destroy()

        if (this.mixScroller) {
            Client.shared.slowTicker.remove(this.mixScroller.tick, this.mixScroller)
        }
        Client.shared.serverBroker.offEvent('mix_play')
        Client.shared.serverBroker.offEvent('mix_stop')
    }
}
