import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { UISoundLibrary } from '../../ui/UISoundLibrary.js'
import { MessengerWindow } from '../../ui/windows/messenger/MessengerWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import type { IMessageData } from './ReceivedMessage.js'

export interface IPaginator<T = any> {
    page: number
    per: number
    total: number
    data: T[]
}

export class MessagesModule extends ServerEvent {
    static readonly type = MessageType.MESSAGES

    static didAskToClear = false

    async handle(page: IPaginator<IMessageData>) {
        console.log('received message page', page)

        if (!MessagesModule.didAskToClear) {
            if (page.total >= 100) {
                Client.shared.helpers
                    .alert({
                        title: 'Too Many Messages',
                        message:
                            'You have 100 or more unread messages, would you like to clear them all? After 250, the in-game count of messages will not go up.',
                        okLabel: 'Clear All',
                        cancelLabel: 'Keep'
                    })
                    .then((pressedClear) => {
                        if (pressedClear) {
                            Client.shared.serverBroker.send('messages_clear')
                        }
                    })
            }
            MessagesModule.didAskToClear = true
        }

        const totalIncreased = page.total > MessengerWindow.messageData.total

        MessengerWindow.messageData = page

        const visible = (await Client.shared.userInterface?.getWin(EWindow.Messenger)) as MessengerWindow
        visible?.updateMessages()

        if (totalIncreased) {
            MessagesModule.glowOrShow()
        }
    }

    static glowOrShow() {
        if (MessengerWindow.messageData.total > 0) {
            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.MailReceived)

            if (MessengerWindow.messageData.data[0].urg) {
                Client.shared.userInterface.getWin(EWindow.Messenger, true)
            }

            Client.shared.userInterface.toolbar?.messageButtonGlow()
            Client.shared.helpers.vibrate(200, 'low')
        } else {
            Client.shared.userInterface.toolbar?.stopMessageGlow()
        }
    }
}
