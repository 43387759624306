import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class StartShow extends ServerEvent {
    static readonly type = MessageType.START_SHOW

    async handle() {
        Client.shared.roomViewer?.show?.play()
    }
}
