import { EItemType, EPerm } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container, Sprite } from 'pixi.js'
import { getText, getVar } from '../../../assets/ExternalConfigManager.js'
import { Client } from '../../../Client.js'
import type { DummyItem } from '../../../data/ItemStack.js'
import { EWindow } from '../../../enums.js'
import type { RoomItem } from '../../../room/RoomItem.js'
import { CheckBox } from '../../buttons/CheckBox.js'
import { CheckBoxController } from '../../CheckBoxController.js'
import { MagicPinBox } from '../FXWindow.js'
import LegacyWindow from '../LegacyWindow.js'

export class ItemInfoWindow extends LegacyWindow {
    readonly kind = EWindow.Other
    windowTitle = 'ItemInfoWindow'

    constructor(
        readonly item: DummyItem,
        readonly owner: string,
        readonly furniRoomItem?: RoomItem
    ) {
        super('vmk_basic', new LegacyWindow('vmk_item_info'), {
            'basic.window.title': 'Item Info'
        })
    }

    override async windowWasBuilt(): Promise<void> {
        this.hide(
            'iteminfo.check.guestsmove',
            'iteminfo.text.guestsmove',
            'magics.word',
            'iteminfo.magicword.hd',
            'singleuse',
            'expiredate.info'
        )

        let type = {
            [EItemType.Pin]: 'Pin',
            [EItemType.Clothing]: 'Clothing',
            [EItemType.Furniture]: 'Furniture',
            [EItemType.Poster]: 'Poster',
            [EItemType.Room]: 'Room',
            [EItemType.Pack]: 'Pack'
        }[this.item.defType]

        if (this.item.defType === EItemType.Pin) {
            if (this.item.stars >= 1) {
                type = 'Magic Pin'
            } else if (this.item.single) {
                type = 'Single-Use Magic Pin'
            }
        }

        const isTradeable = !getVar('nontradable').includes(this.item.defUid)

        this.setField('iteminfo.item', this.item.getName())
        this.setField('iteminfo.type', type)
        this.setField('roominfog.owner', this.owner)
        this.setField('iteminfo.tradeable.off', isTradeable ? 'Tradeable' : 'Non-tradeable')
        this.setBitmap('inv.preview', 'previewbox')

        if (this.furniRoomItem) {
            const moveCheck = new CheckBox('guestMove', getText('iteminfo.letguestsmovethisitem'), true, true)
            moveCheck.setActive(this.furniRoomItem.customData?.guestMove)
            this.replaceElement('iteminfo.check.guestsmove', moveCheck)

            if (
                Client.shared.serverBroker.serverMinVers(4) &&
                (this.furniRoomItem.userId === Client.shared.selfRecord.getId() ||
                    Client.shared.selfRecord.can(EPerm.MKAccessClient))
            ) {
                const checkBoxController = new CheckBoxController([moveCheck])

                checkBoxController.setChangeHandler((status) => {
                    moveCheck.eventMode = 'auto'
                    moveCheck.alpha = 0.75
                    Client.shared.serverBroker
                        .sendAck('furni_data', {
                            itemId: this.furniRoomItem.itemId,
                            updates: {
                                guestMove: status.guestMove
                            }
                        })
                        .then(() => {
                            moveCheck.eventMode = 'static'
                            moveCheck.alpha = 1
                        })
                })
            } else {
                moveCheck.eventMode = 'auto'
                moveCheck.alpha = 0.75
            }
        }

        const previewTex = await this.item.getRendition(this.provider)

        if (previewTex) {
            const previewSprite = Pooler.newSprite(previewTex)
            const previewBg = this.getElement('inv.preview') as Sprite

            const maxWidth = previewBg.width - 25
            const maxHeight = previewBg.height - 25

            const ratio = MagicPinBox.calculateAspectRatioFit(
                previewSprite.width,
                previewSprite.height,
                maxWidth,
                maxHeight
            )
            previewSprite.width = ratio.width
            previewSprite.height = ratio.height

            previewSprite.anchor.set(0.5)

            previewSprite.x = previewBg.x + previewBg.width / 2
            previewSprite.y = previewBg.y + previewBg.height / 2
            ;(this.embedding as Container)?.addChild(previewSprite)
        }
    }
}
