import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { EWindow } from '../../enums.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { UISoundLibrary } from '../UISoundLibrary.js'
import { CFHView } from './CFHView.js'
import { GuideView } from './GuideView.js'
import { IGNView } from './IGNView.js'
import { UIWindow } from './UIWindow.js'
import { UIWindowTab } from './UIWindowTab.js'
import { UIWindowType } from './UIWindowType.js'

export class HelpWindow extends UIWindow {
    readonly kind = EWindow.Help

    isDraggable = true
    exitBtn: ImageButton

    isFocused = true
    private cfhTab: UIWindowTab

    constructor() {
        super(250, UIWindowType.HELP)
        this.position.set(224, 64)
    }

    beginReport(name: string) {
        this.setActiveTab(this.cfhTab)
        ;(this.cfhTab.view as CFHView).nameField.setValue(name)
        ;(this.cfhTab.view as CFHView).bodyField.setValue('')
        ;(this.cfhTab.view as CFHView).bodyField.forceFocus()
    }

    override async windowWasBuilt(): Promise<void> {
        this.exitBtn = new ImageButton('button.close.active', 'button.close.pressed')
        this.addChild(this.exitBtn)
        this.exitBtn.x = this.header.x + this.header.width - 18
        this.exitBtn.y = this.header.y + 2

        const guideView = new GuideView()
        this.addChild(guideView)

        const cfhView = new CFHView()
        this.addChild(cfhView)

        const ignView = new IGNView()
        this.addChild(ignView)

        this.views.push(guideView, cfhView, ignView)

        const guideTab = new UIWindowTab('Guide', guideView)
        this.addChild(guideTab)
        this.cfhTab = new UIWindowTab('Call For Help', cfhView)
        this.addChild(this.cfhTab)
        const ignTab = new UIWindowTab('In-game name', ignView)
        this.addChild(ignTab)

        this.tabs.push(guideTab, this.cfhTab, ignTab)

        let currentX = 0
        this.tabs.forEach((t) => {
            t.y = 44 // static
            t.x = currentX + 28
            currentX += t.getWidth()

            t.addEventListener('pointerup', (e: FederatedEvent) => {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.PopupTabChange)
                this.setActiveTab(t)
            })
        })

        // set default tab as active
        this.setActiveTab(guideTab)
    }
}
