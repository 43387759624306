import type { EItemType, ItemDefUid } from '@vmk-legacy/common-ts'
import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { ShopCategoryParent } from '../../ui/windows/shop/model/ShopCategoryParent.js'
import { ShopItem } from '../../ui/windows/shop/model/ShopItem.js'
import type { ShopWindow } from '../../ui/windows/shop/ShopWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class CatalogModule extends ServerEvent {
    static readonly type = MessageType.CATALOG

    async handle(catalog: ParsedCatalog) {
        const shopWin = (await Client.shared.userInterface.getWin(EWindow.Shopping)) as ShopWindow
        if (!shopWin) {
            return
        }

        const roomItems: ShopItem[] = []
        catalog.rooms.forEach((room) => {
            roomItems.push(new ShopItem(room as ClientCatalogItem))
        })

        shopWin?.roomsView.setRoomsForSale(roomItems)

        const shops = catalog.shops

        // FURNI
        const furniShops: ShopCategoryParent[] = []
        for (const [shopId, items] of Object.entries(catalog.furnishings)) {
            const shop = shops[shopId] as IShop

            const storeItems = items.map((i) => new ShopItem(i))
            const shopCat = new ShopCategoryParent(shop.name, storeItems, shop.logo)

            furniShops.push(shopCat)
        }
        shopWin?.furnishingsView.setShops(furniShops)

        // PINS
        const pinsShops: ShopCategoryParent[] = []
        for (const [shopId, items] of Object.entries(catalog.pins)) {
            const shop = shops[shopId] as IShop

            const storeItems = items.map((i) => new ShopItem(i))
            const shopCat = new ShopCategoryParent(shop.name, storeItems, shop.logo)

            pinsShops.push(shopCat)
        }
        shopWin?.pinsView.setShops(pinsShops)

        // CLOTHING
        const clothingShops: ShopCategoryParent[] = []
        for (const [shopId, items] of Object.entries(catalog.clothing)) {
            const shop = shops[shopId] as IShop

            const storeItems = items.map((i) => new ShopItem(i))
            const shopCat = new ShopCategoryParent(shop.name, storeItems, shop.logo)

            clothingShops.push(shopCat)
        }
        shopWin?.clothingView.setShops(clothingShops)

        const posterShops: ShopCategoryParent[] = []
        for (const [shopId, items] of Object.entries(catalog.posters)) {
            const shop = shops[shopId] as IShop

            const storeItems = items.map((i) => new ShopItem(i))
            const shopCat = new ShopCategoryParent(shop.name, storeItems, shop.logo)

            posterShops.push(shopCat)
        }
        shopWin?.postersView.setShops(posterShops)

        // SPECIALS
        const specialsShop: ShopCategoryParent[] = []
        for (const [shopId, items] of Object.entries(catalog.specials)) {
            const shop = shops[shopId] as IShop

            const storeItems = items.map((i) => new ShopItem(i))
            const shopCat = new ShopCategoryParent(shop.name, storeItems, shop.logo)

            specialsShop.push(shopCat)
        }
        shopWin?.specialsView.setShops(specialsShop)
    }
}

export interface ClientCatalogItem {
    id: number
    itemUid?: ItemDefUid
    spaceId?: number
    packId?: number
    type: EItemType
    price: number
}

interface ParsedCatalog {
    shops: { [shop: number]: IShop }
    rooms: ClientCatalogItem[]
    furnishings: {
        [shop: number]: ClientCatalogItem[]
    }
    pins: {
        [shop: number]: ClientCatalogItem[]
    }
    clothing: {
        [shop: number]: ClientCatalogItem[]
    }
    posters: {
        [shop: number]: ClientCatalogItem[]
    }
    specials: {
        [shop: number]: ClientCatalogItem[]
    }
}

export interface IShop {
    id: number
    name: string
    logo: number | null
}
