import { SoundManager } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { TextKey } from '../assets/ExternalConfigManager.js'
import { getText } from '../assets/ExternalConfigManager.js'
import { Client } from '../Client.js'
import Config from '../Config.js'
import { EWindow, UILayer } from '../enums.js'
import { AlertView } from '../ui/views/AlertView.js'
import { LoginWindow } from '../ui/windows/LoginWindow.js'
import { Helpers } from '../util/Helpers.js'
import { serverAlertsHandler } from './handlers/alerts.js'
import { serverChatHandler, serverClearChatHandler } from './handlers/chat.js'
import { BeginRoomLoad } from './messages/BeginRoomLoad.js'
import { BootedModule } from './messages/BootedModule.js'
import { CatalogModule } from './messages/CatalogModule.js'
import { CFHModule } from './messages/CFHModule.js'
import { ConfirmModule } from './messages/ConfirmModule.js'
import { DisableTileRefs } from './messages/DisableTileRefs.js'
import { serverEmoteHandler } from './handlers/emotes.js'
import { EntityBeginMoving } from './messages/EntityBeginMoving.js'
import { EntityBodyStateModule } from './messages/EntityBodyStateModule.js'
import { EntityChangeModule } from './messages/EntityChangeModule.js'
import { EntityJoinModule } from './messages/EntityJoinModule.js'
import { EntityLeaveModule } from './messages/EntityLeaveModule.js'
import { EntityMagicModule } from './messages/EntityMagicModule.js'
import { EntityStopModule } from './messages/EntityStopModule.js'
import { FriendsListModule } from './messages/FriendsListModule.js'
import { FriendUpdate } from './messages/FriendUpdate.js'
import { FurniPlacedModule } from './messages/FurniPlacedModule.js'
import { FurniRemovedModule } from './messages/FurniRemovedModule.js'
import { GameJoinModule } from './messages/GameJoinModule.js'
import { GracefulDisconnectWarning } from './messages/GracefulDisconnectWarning.js'
import { InventoryModule } from './messages/InventoryModule.js'
import { InvUpdateModule } from './messages/InvUpdateModule.js'
import { MessagesModule } from './messages/MessagesModule.js'
import { NPCVisitModule } from './messages/NPCVisitModule.js'
import { QueueExpired } from './messages/QueueExpired.js'
import { ReceivedMessage } from './messages/ReceivedMessage.js'
import { RecordsModule } from './messages/RecordsModule.js'
import { RefetchFurnimap } from './messages/RefetchFurnimap.js'
import { RefreshBillboardsModule } from './messages/RefreshBillboardsModule.js'
import { RegistrationCompleteModule } from './messages/RegistrationCompleteModule.js'
import { ReloadDataModule } from './messages/ReloadDataModule.js'
import { RoomFurniLoad } from './messages/RoomFurniLoad.js'
import { RoomInfoUpdate } from './messages/RoomInfoUpdate.js'
import { RoomJoinModule } from './messages/RoomJoinModule.js'
import { RoomListModule } from './messages/RoomListModule.js'
import { RoomPurchaseSuccessModule } from './messages/RoomPurchaseSuccessModule.js'
import { RoomQueueModule } from './messages/RoomQueueModule.js'
import { RoomQueueMoved } from './messages/RoomQueueMoved.js'
import { RoomScoreModule } from './messages/RoomScoreModule.js'
import { RulesUpdated } from './messages/RulesUpdated.js'
import { SaveFile } from './messages/SaveFile.js'
import { ShowStep } from './messages/ShowStep.js'
import { StartShow } from './messages/StartShow.js'
import { TeleportedModule } from './messages/TeleportedModule.js'
import { TradeBeginModule } from './messages/TradeBeginModule.js'
import { TradeRequestModule } from './messages/TradeRequestModule.js'
import { TransitionModel } from './messages/TransitionModel.js'
import { UpdateTextModule } from './messages/UpdateTextModule.js'
import { Vibrate } from './messages/Vibrate.js'
import type { EventHandling } from './ServerEvent.js'
import { ServerTransportAdapting } from './ServerTransportAdapting.js'
import { VanillaWebsocketAdapter } from './VanillaWebsocketAdapter.js'
import { Message } from '@bufbuild/protobuf'
import { serverMsgRegistry } from '@vmk-legacy/server-protos'
import posthog from 'posthog-js'

export enum ConnectReject {
    CantConnect = 0,
    MissingLogin = 1,
    BadLogin = 2
}

enum State {
    Uninitialized = 0,
    ReadyForInitialConnection = 1,
    TryingInitialConnection = 2,
    Established = 3,
    AttemptingRecovery = 4,
    Torndown = 5
}

export class ServerBroker {
    #state: State = State.Uninitialized

    #hasConnected = false
    #sessionActive = false
    #provider: ServerTransportAdapting
    #authToken?: string
    #serverVersion?: number
    serverInfo?: string
    private loginWindow?: AlertView
    handlers = new Map<string, EventHandling>()
    expectDisconnect = false

    constructor(provider?: ServerTransportAdapting) {
        this.#state = State.ReadyForInitialConnection
        this.#provider = provider
    }

    createProvider(provider: ServerTransportAdapting = new VanillaWebsocketAdapter()): void {
        console.log('ServerBroker.createProvider')

        this.#provider = provider

        provider.on(ServerTransportAdapting.Constants.established, () => this.connectionDidEstablish())
        provider.on(ServerTransportAdapting.Constants.dropped, () => this.connectionDidDrop())
        provider.on(ServerTransportAdapting.Constants.kicked, () => this.sessionDidEnd(true))
        provider.on(ServerTransportAdapting.Constants.event, (event, payload, ackId) =>
            this.handleEvent(event, payload, ackId)
        )
    }

    serverMinVers(min: number): boolean {
        if (!this.#serverVersion) {
            return false
        }
        return this.#serverVersion >= min
    }

    getServerVersion(): number | undefined {
        return this.#serverVersion
    }

    async authConnect(useToken?: string): Promise<void> {
        if (useToken) {
            Client.shared.api.apiToken = useToken
        } else {
            useToken = Client.shared.api.apiToken
        }
        if (useToken !== 'skip') {
            try {
                const newSession = await Client.shared.api.getPlayerBySession(!useToken)
                if (newSession) {
                    posthog.identify(`player:${newSession.user.id}`, {
                        ign: newSession.user.ign
                    })
                }
                if (newSession?.apiToken) {
                    useToken = newSession.apiToken
                }
            } catch (ignored) {
                //
            }
        }
        if (!useToken || useToken === 'skip') {
            this.loginFailed()
            return
        }
        if (Client.shared.api.player) {
            Client.shared.loadingView.setStatusText('Hello ' + Client.shared.api.player.ign)
        }
        this.createProvider(this.#provider)
        this.#state = State.TryingInitialConnection

        this.#authToken = useToken
        this.#provider.initConnection()
    }

    /**
     * Called when the connection to the server has been established.
     */
    connectionDidEstablish(): void {
        console.log('connectionDidEstablish')
        this.#hasConnected = true

        Client.shared.helpers.removeAlert('reboot')
        this.attachListeners()

        if (this.loginWindow) {
            this.loginWindow.destroy({ children: true })
            this.loginWindow = undefined
        }

        this.sendAck('auth', this.#authToken).then(
            (payload: {
                success: boolean
                id?: number
                ign?: string
                currentVersion?: number
                debugInfo?: string
                minVersion?: number
                holiday?: string
                error?: string
                restored?: any
                firstLogin?: boolean
                termsUpdated?: boolean
            }) => {
                console.log('login response', payload)
                if (payload.currentVersion) {
                    this.#serverVersion = payload.currentVersion
                    this.serverInfo = payload.debugInfo
                    Client.shared.updateWatermark()
                }
                if (payload.minVersion && Config.version < payload.minVersion) {
                    console.log(
                        'Not compatible with server! Server requires ' +
                            payload.minVersion +
                            ' and this client is version ' +
                            Config.version
                    )
                    this.#sessionActive = false
                    this.expectDisconnect = true
                    this.#provider.teardown()
                    this.loginFailed(
                        'VMK Legacy needs an update to be able to connect to the game server. Please refresh to receive the latest updates. If this issue persists, try clearing your browser cache.'
                    )
                    return
                }
                if (payload.holiday) {
                    Client.shared.holiday = payload.holiday
                }
                if (payload.success) {
                    this.#state = State.Established
                    console.log('success login')

                    Client.shared.selfRecord.setId(payload.id)
                    Client.shared.selfRecord.setIgn(payload.ign)

                    Client.shared.userInterface?.showToolbars()

                    this.#sessionActive = true

                    if (payload.firstLogin) {
                        Client.shared.showRegistration()
                    } else if (payload.termsUpdated) {
                        Client.shared.showRegistration(true)
                    } else {
                        Client.shared.revealGame(payload.restored)
                    }
                } else {
                    this.#state = State.ReadyForInitialConnection
                    this.#sessionActive = false
                    this.#provider.teardown()
                    this.loginFailed(payload.error)
                }
            }
        )
    }

    getLoginWindow(): AlertView | undefined {
        return this.loginWindow
    }

    loginFailed(error: TextKey = 'login.failed.text'): void {
        console.log('>> loginFailed ' + error)
        Client.shared.loadingView.setVisible(false)
        if (this.loginWindow && !this.loginWindow.destroyed) {
            ;(this.loginWindow.alert as LoginWindow).retryLogin().setInfoText(getText(error))
        } else {
            this.loginWindow = Client.shared.userInterface.register(new AlertView(new LoginWindow(error), 0))
        }
    }

    handleEvent(event: string, payload?: any, ackId?: number): void {
        const handler = this.handlers.get(event)
        if (handler) {
            const msgType = serverMsgRegistry.findMessage(event)
            if (msgType) {
                console.log('Input: ', payload)
                try {
                    payload = msgType?.fromJson(payload)
                    console.log('Result: ', payload)
                } catch (error) {
                    console.error(error)

                    return
                }
            }
            console.log('Handling event ' + event, payload, ackId)
            const result = handler.handle(payload)

            if (ackId) {
                if (result instanceof Promise) {
                    result.then((p) => this.send('ack-' + ackId, p))
                } else {
                    this.send('ack-' + ackId, result)
                }
            }
        } else {
            console.log('Cannot handle event ' + event)
        }
    }

    /**
     * Called when the connection to the server disconnected unexpectedly.
     */
    async connectionDidDrop(): Promise<void> {
        console.log('connectionDidDrop')

        if (this.expectDisconnect) {
            console.log('Ignoring disconnect')
            this.sessionDidEnd(false)
            return
        }

        this.sessionDidEnd()
        this.showReconnector()
    }

    async showReconnector(): Promise<void> {
        this.#state = State.AttemptingRecovery

        console.log('showing reconnect loader')

        Client.shared.loadingView.setStatusText('Attempting to reconnect')
        Client.shared.loadingView.setWaitText('Disconnected from server, please wait')
        Client.shared.loadingView.setVisible(true)

        await Helpers.delay(2500)

        this.createProvider()
        this.#provider.initConnection()
    }

    sessionDidEnd(showLogin = false): void {
        this.#sessionActive = false
        this.#provider?.teardown()
        this.#provider = undefined
        Client.shared.userInterface.closeWindows(UILayer.GameWindows)
        Client.shared.userInterface.closeWindows(UILayer.Legacy)
        Client.shared.runningMinigame?.teardown()
        Client.shared.runningMinigame = null
        if (Client.shared.userInterface?.toolbar) {
            Client.shared.userInterface.toolbar.visible = false
        }
        RoomJoinModule.loader?.destroy()
        Client.shared.roomViewer?.teardown()

        if (showLogin) {
            this.loginFailed('Your play session ended. Come back real soon!')
        }
    }

    attachListeners(): void {
        console.log('Attaching socket listeners')

        const events = [
            GracefulDisconnectWarning,
            InventoryModule,
            FriendsListModule,
            serverAlertsHandler,
            serverChatHandler,
            serverClearChatHandler,
            RoomJoinModule,
            CatalogModule,
            EntityJoinModule,
            EntityLeaveModule,
            RegistrationCompleteModule,
            EntityChangeModule,
            EntityStopModule,
            EntityBodyStateModule,
            CFHModule,
            RoomListModule,
            TradeRequestModule,
            TradeBeginModule,
            NPCVisitModule,
            serverEmoteHandler,
            RoomPurchaseSuccessModule,
            BootedModule,
            RefreshBillboardsModule,
            UpdateTextModule,
            DisableTileRefs,
            TransitionModel,
            FurniPlacedModule,
            FurniRemovedModule,
            RecordsModule,
            RoomScoreModule,
            ReloadDataModule,
            RoomQueueModule,
            RoomQueueMoved,
            InvUpdateModule,
            FriendUpdate,
            MessagesModule,
            QueueExpired,
            RoomInfoUpdate,
            GameJoinModule,
            TeleportedModule,
            StartShow,
            ShowStep,
            EntityMagicModule,
            ConfirmModule,
            ReceivedMessage,
            EntityBeginMoving,
            BeginRoomLoad,
            RoomFurniLoad,
            Vibrate,
            SaveFile,
            RefetchFurnimap,
            RulesUpdated
        ]

        for (const eventType of events) {
            const inst = new eventType()

            if (eventType.type) {
                if (this.handlers.has(eventType.type)) {
                    console.error('Not re-registering handler for ' + eventType.type)
                } else {
                    console.log('Attaching handler for ' + eventType.type)
                    this.handlers.set(eventType.type, inst)
                }
            }
            if (eventType.accepts) {
                if (this.handlers.has(eventType.accepts)) {
                    console.error('Not re-registering handler for ' + eventType.accepts)
                } else {
                    console.log('Attaching handler for ' + eventType.accepts)
                    this.handlers.set(eventType.accepts, inst)
                }
            }
        }
    }

    onceEvent(name: string, handler: (...args: any[]) => any): void {
        const oldHandler = handler
        handler = (data, ack) => {
            this.offEvent(name)
            oldHandler(data, ack)
        }

        this.onEvent(name, handler)
    }

    onMessage<MessageType extends { typeName: string; new (...args: any): Message }>(
        type: MessageType,
        handle: (data: InstanceType<MessageType>) => any
    ): void {
        this.handlers.set(type.typeName, { handle })
    }

    onEvent(name: string | typeof Message, handle: (...args: any[]) => any): void {
        this.handlers.set(name, { handle })
    }

    offEvent(name: string): void {
        this.handlers.delete(name)
    }

    off<
        MessageTypeArray extends {
            typeName: string
            new (...args: any): Message
        }[]
    >(...messages: MessageTypeArray): void {
        for (const messageType of messages) {
            this.offEvent(messageType.typeName)
        }
    }

    teardown(): void {
        console.log('Networker teardown')

        this.#provider.teardown()

        Client.shared.loadingView.setVisible(false)
        Client.shared.userInterface.toolbar?.chatbarInput?.hide()
        Client.shared.userInterface.closeWindow(EWindow.Navigator)
        SoundManager.shared.globalVolGain.gain.value = 0
        Client.shared.roomViewer?.teardown()
        gsap.globalTimeline.kill()

        const message =
            'Unfortunately, your connection to the game was lost. Please reload the page to reconnect and continue playing.'

        Client.shared.helpers
            .confirm({
                title: 'Disconnected',
                message,
                okLabel: 'RELOAD',
                cancelLabel: 'EXIT'
            })
            .then((reload) => {
                if (reload) {
                    location.reload()
                } else {
                    window.opener?.location?.reload()
                    window.close()
                    location.href = 'https://vmklegacy.com'
                }
            })
    }

    requestRoom(id: number | string): void {
        Client.shared.userInterface.closeWindow(EWindow.Navigator)
        console.log('requesting room ' + id.toString())
        this.send('room_join_request', {
            roomId: id.toString()
        })
    }

    send(event: string | Message, payload?: any): void {
        console.log('> send', event, payload)
        if (event instanceof Message) {
            payload = event
            event = event.getType().typeName
        }
        if (payload instanceof Message) {
            console.log('Converting protobuf to json')
            payload = payload.toJson()
        }
        this.#provider.send(event, payload)
    }

    sendAck(event: string, payload?: any): Promise<any> {
        return this.#provider.sendAck(event, payload)
    }

    sessionActive(): boolean {
        return this.#sessionActive
    }

    getProvider(): ServerTransportAdapting | undefined {
        if (process.env.NODE_ENV !== 'production') {
            return this.#provider
        }
        return undefined
    }
}
