/*
 * chat.ts
 * VMK Legacy Client
 */

import { ServerChat, ServerClearChat } from '@vmk-legacy/server-protos'
import { Client } from '../../Client.js'
import { AvatarEntity } from '../../room/entities/AvatarEntity.js'
import { defineHandler } from '../ServerEvent.js'

export const serverChatHandler = defineHandler(ServerChat, async ({ message, entityId, invisible }) => {
    const emotes: Record<number, string[]> = {
        1: [':)', '(:', ':-)', '(-:', ':D', ':-D', '=D', ':]', '[:', ':-]', '[-:', '😃', '😀', '🙂', '😺'], // smile open eyes,
        2: ['😡', '😠', '>:(', '>:-(', '😤', '🤬', '😾'], //mad
        3: ['🎂', '🍰', '🧁'], //birthday cake
        4: ['💀', '☠️', '☠'], //skullandcrossbones
        5: ['👎', '👎🏻', '👎🏼', '👎🏽', '👎🏾', '👎🏿'], //thumbsdown
        6: ['👍', '👍🏻', '👍🏼', '👍🏽', '👍🏾', '👍🏿'], //thumbsup
        7: ['😊', '😄', '😁', '😆', '🤣', '😸', 'xD'], //smile closed eyes
        8: ['🤮', '🤢', '🤒'], //sick
        9: ['😴', '💤'], //sleepy
        10: [':o', ':-o', '😮', '😯', '😦', '😲', '🫨', '🙀', '😱', '😨'], //surprised
        11: [':p', ':P', ':-P', '😝'], //tongue stuck out mad
        12: [';)', ';-)', '😉', '😜'], //wink
        13: [':(', ':-(', '😭', '😢', '😔', '😥', '😿'] //crying
    }

    const entity = Client.shared.roomViewer?.getEntityByRef(entityId)
    if (entity instanceof AvatarEntity) {
        entity.getVisual().speak(2)
        for (const emoteId in emotes) {
            if (emotes[emoteId]?.some((t) => message.includes(t))) {
                entity.getVisual().setEmote(+emoteId, true)
                break
            }
        }
    }
    Client.shared.roomViewer?.chatView.pushMessage({
        message: message,
        entityId: entityId,
        invisible: invisible === true,
        tween: true
    })
})

export const serverClearChatHandler = defineHandler(ServerClearChat, async () => {
    Client.shared.roomViewer?.chatView.removeAllBubbles()
})
