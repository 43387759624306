import { TintColor } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import { BitmapText, Container } from 'pixi.js'
import { Fonts } from '../Fonts.js'
import { ScaleField } from './fields/ScaleField.js'

export class AvatarTooltip extends Container {
    #text: string
    #tint?: string | number

    constructor(text = '') {
        super()
        this.#text = text

        if (text) {
            this.setText(this.#text)
        }
    }

    getText(): string {
        return this.#text
    }

    setText(text: string, tintId: string | number = 182): void {
        this.removeChildren()

        this.#text = text
        this.#tint = tintId

        const tooltipText = new BitmapText(text, Fonts.FoxleyBold_16)

        tooltipText.x = 6
        tooltipText.y = 1

        const bg = new ScaleField(tooltipText.textWidth + 12, 18, 0xffffff)
        const innerLeft = Pooler.newSprite('mouseover.left')
        innerLeft.x = 2
        const innerMid = Pooler.newSprite('mouseover.tile')
        innerMid.x = innerLeft.x + innerLeft.width
        innerMid.width = tooltipText.textWidth - 2
        const innerRight = Pooler.newSprite('mouseover.right')
        innerRight.x = innerMid.x + innerMid.width

        const midpointX = Math.round((innerMid.x + innerMid.width) / 2) + 3

        const spikeOutline = Pooler.newSprite('mouseover.spike.outline')
        const spike = Pooler.newSprite('mouseover.spike')
        spikeOutline.anchor.set(0.5, 0)
        spike.anchor.set(0.5, 0)
        spikeOutline.x = spike.x = midpointX
        spike.y = 16
        spikeOutline.y = 18

        const tint = TintColor[tintId] || tintId

        if (tint) {
            bg.setTint(tint)
            spikeOutline.tint = tint
        }

        this.addChild(bg)
        this.addChild(innerMid)
        this.addChild(innerLeft)
        this.addChild(innerRight)
        this.addChild(spikeOutline)
        this.addChild(spike)
        innerLeft.y = innerMid.y = innerRight.y = 2
        this.addChild(tooltipText)
        this.pivot.set(this.width / 2, 0)
    }
}
