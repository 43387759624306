import type { Message } from '@bufbuild/protobuf'
import type { MessageType } from './MessageType.js'

// Intentionally specifying void in union return type, otherwise have to
// return undefined for non-async implementation of handler.

/* eslint-disable @typescript-eslint/no-invalid-void-type */

export interface EventHandling<EventPayloadType> {
    handle(data?: EventPayloadType): Promise<any> | void
}

export abstract class ServerEvent<PayloadType> implements EventHandling<PayloadType> {
    static readonly type?: MessageType
    static readonly accepts?: string

    handle(payload: PayloadType): Promise<any> | void {
        console.log('ServerMessage.handle not overridden.')
        return undefined
    }
}

type MessageClassType = { typeName: string; new (...args: any[]): Message }

export function defineHandler<
    MessageStaticType extends MessageClassType,
    MessageInstanceType = InstanceType<MessageStaticType>
>(
    forMessage: MessageStaticType,
    handler: (payload: MessageInstanceType) => Promise<any> | void
): typeof ServerEvent<MessageInstanceType> {
    return class extends ServerEvent<MessageInstanceType> {
        static override readonly accepts = forMessage.typeName

        override handle = handler
    } as typeof ServerEvent<MessageInstanceType>
    // const type = forMessage.constructor.typeName
}
