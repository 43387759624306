import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class Vibrate extends ServerEvent {
    static readonly type = MessageType.Vibrate

    async handle(): Promise<void> {
        await Client.shared.helpers.vibrate(150, 'low')
    }
}
