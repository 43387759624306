import { Client } from '../../Client.js'
import { RoomPurchaseWindow } from '../../ui/windows/RoomPurchaseWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RoomPurchaseSuccessModule extends ServerEvent {
    static readonly type = MessageType.ROOM_PURCHASE_SUCCESS

    async handle(data: any) {
        const roomTitle: string = data.title
        const roomDesc: string = data.desc
        const ownerIgn: string = data.owner
        const roomId: number = +data.id

        const purchaseWindow = new RoomPurchaseWindow(roomTitle, ownerIgn, roomDesc)
        Client.shared.userInterface.register(purchaseWindow)
        purchaseWindow.center()

        purchaseWindow.enterButton.addEventListener('pointerup', () => {
            Client.shared.serverBroker.requestRoom(roomId)
            purchaseWindow.visible = false
            purchaseWindow.tryRemove()
        })

        purchaseWindow.continueButton.addEventListener('pointerup', () => {
            purchaseWindow.visible = false
            purchaseWindow.tryRemove()
        })
    }
}
