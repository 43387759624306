import { TextureCache } from '@pixi/utils'
import type { AssetProvider } from '@vmk-legacy/render-utils'
import { Assets } from 'pixi.js'
import { Client } from '../../../Client.js'
import type { StackedProvider } from '../../../data/ItemStack.js'
import { EWindow } from '../../../enums.js'
import type { IPaginator } from '../../../server/messages/MessagesModule.js'
import { PagedThumbnailBox } from '../../thumbs/PagedThumbnailBox.js'
import LegacyWindow from '../LegacyWindow.js'
import type { PhotoCatalog } from './PhotoCatalog.js'

class CatalogThumb implements StackedProvider {
    constructor(
        readonly photoId: number,
        readonly caption: string
    ) {}

    getCount(): number {
        return 1
    }

    getKey() {
        return this.photoId
    }

    getName(): string {
        return this.caption || 'Photo'
    }

    getNote(): string | null {
        return undefined
    }

    async getRendition(provider: AssetProvider) {
        const res = await Client.shared.api.get('photo/' + this.photoId)
        console.log('image fetched')
        let tex = TextureCache['fto-' + this.photoId]
        if (!tex) {
            Assets.add('fto-' + this.photoId, res.data)
            tex = await Assets.load('fto-' + this.photoId)
        }

        return tex
    }
}

export class PhotoCatalogThumbs extends LegacyWindow {
    readonly kind = EWindow.Other

    photoData: IPaginator<[number, string]>

    #thumbs: PagedThumbnailBox

    constructor(readonly container: PhotoCatalog) {
        super('vmk_fto_catalog_thumbs')

        this.isEmbedded = true
        this.embeddedParent = container
    }

    async populate() {
        this.photoData = await this.container.photoRequest

        const items: CatalogThumb[] = []

        for (const photo of this.photoData.data) {
            items.push(new CatalogThumb(photo[0], photo[1]))
        }

        this.#thumbs.setItems(...items)
    }

    override async windowWasBuilt(): Promise<void> {
        this.#thumbs = this.replaceElement(
            'fto.thumbs.grid',
            new PagedThumbnailBox({
                rows: 4,
                cols: 5,
                items: [],
                spacing: 1,
                onItemTap: (item) => this.container.viewPhoto(item.getKey()),
                onItemDoubleTap: null,
                pageTurnSpacing: 100,
                pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK,
                dimUntradeables: false,
                initialPage: 1,
                background: false,
                thumbWidth: 61,
                thumbHeight: 41
            }),
            false
        )

        const backBtn = this.getElement('fto.previouspage.button')
        const nextBtn = this.getElement('fto.nextpage.button')

        backBtn.addEventListener('pointertap', async () => {
            const maxPage = Math.ceil(this.photoData.total / this.photoData.per)
            if (this.photoData.page === 1) {
                await this.container.requestPage(maxPage)
            } else {
                await this.container.requestPage(this.photoData.page - 1)
            }
            this.populate()
        })
        nextBtn.addEventListener('pointertap', async () => {
            const maxPage = Math.ceil(this.photoData.total / this.photoData.per)
            if (this.photoData.page === maxPage) {
                await this.container.requestPage(1)
            } else {
                await this.container.requestPage(this.photoData.page + 1)
            }
            this.populate()
        })
    }
}
