import { EPerm, EPronounOpt, Pronouns } from '@vmk-legacy/common-ts'
import type { AvatarOutfit } from '@vmk-legacy/render-utils'
import { AssetProvider, ESndGrp, Pooler, SoundManager } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Container } from 'pixi.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import type { DummyItem } from '../../data/ItemStack.js'
import { type EntityRoomIdentifier, EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { AvatarVisual } from '../../room/entities/AvatarVisual.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../buttons/BitmapTextButtonType.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { FieldH } from '../fields/FieldH.js'
import { TextRenderer } from '../TextRenderer.js'
import { PagedThumbnailBox } from '../thumbs/PagedThumbnailBox.js'
import { UISoundLibrary } from '../UISoundLibrary.js'
import type { HelpWindow } from './HelpWindow.js'
import { ItemInfoWindow } from './item_info/ItemInfoWindow.js'
import type { IWindow } from './IWindow.js'
import type { ModWindow } from './mod/ModWindow.js'
import { MKGivePopup } from './mod/views/popups/MKGivePopup.js'
import type { UsersView } from './mod/views/UsersView.js'
import { PopupBox } from './PopupBox.js'

export class LanyardBox extends Container implements IWindow {
    readonly kind = EWindow.Lanyard
    layer = UILayer.GameWindows
    isDraggable = false
    exitBtn: ImageButton
    header: Sprite
    readonly BOX_WIDTH: number = 235
    readonly ROW_1_Y: number = 5
    readonly NAME_FIELD_X: number = 40
    readonly NAME_FIELD_WIDTH: number = 163
    readonly SIG_X: number = 50
    readonly SIG_Y: number = 48
    readonly LANYARD_X: number = 4
    readonly LANYARD_Y: number = 87
    private ign: string
    private signature: string

    private ignField: BitmapText
    private signatureField: Sprite
    private badgeSprites: Sprite[]
    currentInstanceId?: EntityRoomIdentifier

    private friend: BitmapTextButton
    private trade: BitmapTextButton
    private report: BitmapTextButton
    private ignore: BitmapTextButton
    private boot: BitmapTextButton
    private pinThumbs: PagedThumbnailBox
    private head: AvatarVisual
    private pronounStr = ''
    private rankName = ''
    private subtitle: BitmapText
    private bottom: Container
    private box: PopupBox

    constructor() {
        super()

        this.badgeSprites = []
        this.isDraggable = false

        this.box = new PopupBox(this.BOX_WIDTH, 345, false)

        this.addChild(this.box)

        const xButton = new ImageButton('button.closeb.active', 'button.closeb.pressed')
        xButton.x = this.BOX_WIDTH - 20
        xButton.y = this.ROW_1_Y
        this.addChild(xButton)

        this.exitBtn = xButton
        this.on('removed', () => Client.shared.roomViewer.hideArrow())

        const nameBg = new FieldH(this.NAME_FIELD_WIDTH)
        nameBg.x = this.NAME_FIELD_X
        nameBg.y = this.ROW_1_Y
        this.addChild(nameBg)

        this.ignField = new BitmapText('Unknown', {
            ...Fonts.FoxleyBold_16,
            align: 'center'
        })
        this.ignField.anchor.set(0.5, 0)
        this.ignField.x = nameBg.width / 2 + nameBg.x
        this.ignField.y = this.ROW_1_Y + 2
        this.addChild(this.ignField)

        this.subtitle = this.addChild(new BitmapText('', Fonts.Foxley_16))
        this.subtitle.position.set((this.BOX_WIDTH - 70) / 2 + 70, nameBg.y + nameBg.height + 8)
        this.subtitle.anchor.set(0.5, 0)

        const provider = new AssetProvider(Client.shared.assetLoader)
        this.head = this.addChild(new AvatarVisual(provider, 4, '', { wave: true }))
        this.head.pivot.set(-20, -105)
        this.head.position.set(7, 3)
        this.head.eventMode = 'auto'
        this.head.interactiveChildren = false

        this.signatureField = Pooler.newSprite()
        this.signatureField.x = this.SIG_X
        this.signatureField.y = this.SIG_Y
        this.addChild(this.signatureField)

        this.bottom = this.addChild(Pooler.newContainer())
        this.bottom.position.set(this.LANYARD_X, this.signatureField.y + this.signatureField.height + 10)

        this.pinThumbs = this.bottom.addChild(
            new PagedThumbnailBox({
                rows: 3,
                cols: 5,
                items: [],
                spacing: 2,
                onItemTap: null,
                onItemDoubleTap: this.doubleClickPin,
                pageTurnSpacing: 100,
                pageTurnFormat: PagedThumbnailBox.FORMAT_BLANK,
                dimUntradeables: false,
                initialPage: 1,
                background: false,
                thumbWidth: 44,
                hideEmpty: false,
                stack: false
            })
        )

        const k = this.pinThumbs.height + 7

        if (Client.shared.selfRecord.can(EPerm.PlayersBrowse)) {
            const mkBtn = new BitmapTextButton(23, 'MK', BitmapTextButtonType.PINK)
            mkBtn.x = 2
            mkBtn.y = k
            this.bottom.addChild(mkBtn)
            mkBtn.addEventListener('pointerup', async () => {
                const modWin = (await Client.shared.userInterface.getWin(EWindow.Mod, true)) as ModWindow
                if (modWin) {
                    modWin.setActiveTab(modWin.usersTab)
                    ;(modWin.usersTab.view as UsersView).nameField.getElement().placeholder = this.ign
                    ;(modWin.usersTab.view as UsersView).setSearching(true)
                    Client.shared.serverBroker.send('mk_search', {
                        ign: this.ign
                    })
                }
            })
        }
        if (Client.shared.selfRecord.can(EPerm.PlayersInventoryAddition)) {
            const giveBtn = new BitmapTextButton(23, 'GIVE', BitmapTextButtonType.TEAL)
            giveBtn.x = 2
            giveBtn.y = k + 40
            this.bottom.addChild(giveBtn)
            giveBtn.addEventListener('pointerup', async () => {
                new MKGivePopup(this.currentInstanceId, this.ign)
            })
        }

        this.friend = new BitmapTextButton(170, 'avatarinfo.askfriendship', 'd')
        this.friend.x = 30
        this.friend.y = k
        this.bottom.addChild(this.friend)
        this.friend.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('friend_request', {
                id: this.currentInstanceId
            })
        })

        this.trade = new BitmapTextButton(83, 'avatarinfo.trade', 'd')
        this.trade.x = 30
        this.trade.y = k + 25
        this.bottom.addChild(this.trade)
        this.trade.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('trade_request', {
                id: this.currentInstanceId
            })
        })

        this.report = new BitmapTextButton(83, 'mes.report', 'e')
        this.report.x = 30 + 87
        this.report.y = k + 25
        this.bottom.addChild(this.report)
        this.report.addEventListener('pointerup', async () => {
            const help = (await Client.shared.userInterface.getWin(EWindow.Help, true)) as HelpWindow
            if (help) {
                help.beginReport(this.ign)
            }
        })

        this.ignore = new BitmapTextButton(83, 'avatarinfo.ignore', 'e')
        this.ignore.x = 30
        this.ignore.y = k + 25 + 25
        if (Client.shared.serverBroker.serverMinVers(10)) {
            this.ignore.addEventListener('pointerup', () => {
                Client.shared.serverBroker.send('ignore_player', this.currentInstanceId)
            })
        }
        this.bottom.addChild(this.ignore)

        this.boot = new BitmapTextButton(83, 'avatarinfo.kick', 'e')
        this.boot.x = 30 + 87
        this.boot.y = k + 25 + 25
        this.boot.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('boot', {
                id: this.currentInstanceId
            })
        })
        this.bottom.addChild(this.boot)

        this.friend.alpha = this.trade.alpha = this.report.alpha = this.ignore.alpha = this.boot.alpha = 1

        this.visible = false
    }

    setBadges(badges: number[]): void {
        // Remove old badges
        for (const oldSprite of this.badgeSprites) {
            this.removeChild(oldSprite)
        }

        // Add new ones
        for (let i = 0; i < badges.length; i++) {
            if (i > 1) {
                break
            }
            const id = badges[i]
            const badge = Pooler.newSprite(`badge_${id}`)
            badge.width = 20
            badge.height = 20
            badge.x = 55 + 10 * i
            badge.y = 26
            this.badgeSprites.push(badge)
            this.addChild(badge)
            let badgeName = ''
            switch (id) {
                case 1:
                    badgeName = 'In Park'
                    break
                case 3:
                    badgeName = 'LC'
                    break
                case 4:
                    badgeName = 'Staff'
                    break
                case 5:
                    badgeName = 'VIP'
                    break
                case 6:
                    badgeName = 'Day 1'
                    break
                case 7:
                    badgeName = 'Pin Trading'
                    break
                case 8:
                    badgeName = 'Passholder'
                    break
            }

            badge.eventMode = 'static'
            badge.addEventListener('pointertap', () => this.setRankName(badgeName))
            if (i === 0) {
                this.setRankName(badgeName)
            }
        }
        if (!badges.length) {
            this.setRankName('')
        }
    }

    setRankName(name: string): void {
        this.rankName = name

        if (this.rankName && this.pronounStr) {
            this.subtitle.text = this.rankName + ' · ' + this.pronounStr
        } else if (this.rankName) {
            this.subtitle.text = this.rankName
        } else if (this.pronounStr) {
            this.subtitle.text = this.pronounStr
        } else {
            this.subtitle.text = ''
        }
    }

    setSelf(self: boolean): void {
        if (self) {
            this.friend.disable()
            this.trade.disable()
            this.report.disable()
            this.boot.disable()
            this.ignore.disable()
        } else {
            this.friend.enable()
            this.trade.enable()
            this.report.enable()
            this.boot.enable()
            this.ignore.enable()
        }
    }

    setPins(pins: DummyItem[]): void {
        console.log('pins', pins)
        this.pinThumbs.setItems(...pins)
    }

    setIgn(ign: string): void {
        this.ign = ign
        this.ignField.text = ign
    }

    async setSignature(signature: string): Promise<void> {
        this.signature = String(signature).trim()
        const sigTexture = await TextRenderer.get(this.signature, 178, 'left', 0xffffff)
        this.signatureField.texture = sigTexture
        this.signatureField.width = sigTexture.width
        this.signatureField.height = sigTexture.height
        const sigHeight = this.signature ? this.signatureField.height : 0
        this.bottom.position.set(this.LANYARD_X, this.signatureField.y + sigHeight + 10)
        this.head.setLegsVisible(sigHeight > 0)
        this.updatePosition()
    }

    setPronouns(pronouns: EPronounOpt[] | null): void {
        let pronounStr = ''
        const pro1 = pronouns ? pronouns[0] : null
        const pro2 = pronouns ? pronouns[1] : null

        if (pro1 && pro1 in Pronouns) {
            if (pro1 === EPronounOpt.Any) {
                pronounStr = 'any pronouns'
            } else if (pro1 === EPronounOpt.Ask) {
                pronounStr = 'ask pronouns'
            } else {
                pronounStr = Pronouns[pro1][0]

                if (pro2 && pro2 !== pro1 && pro2 in Pronouns) {
                    pronounStr += '/' + Pronouns[pro2][1]
                } else {
                    pronounStr += '/' + Pronouns[pro1][1]
                }

                pronounStr = pronounStr.replaceAll(':name', 'use name')
            }
        }

        this.pronounStr = pronounStr
        console.log('pronouns: ', pronouns, pronounStr)

        if (this.rankName && this.pronounStr) {
            this.subtitle.text = this.rankName + ' · ' + this.pronounStr
        } else if (this.pronounStr) {
            this.subtitle.text = this.pronounStr
        } else if (this.rankName) {
            this.subtitle.text = this.rankName
        } else {
            this.subtitle.text = ''
        }
    }

    setOutfit(outfit: AvatarOutfit): void {
        this.head.setUpdatedOutfit(outfit)
    }

    doubleClickPin = (item: DummyItem): void => {
        SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.OpenPopup)
        const infoWindow = new ItemInfoWindow(item, this.ign)
        Client.shared.userInterface.register(infoWindow)
    }

    updatePosition(): void {
        const height = this.bottom.y + this.bottom.height + 5
        this.box.changeHeight(height)
        this.position.set(Constants.SIZE[0] - this.width, Constants.SIZE[1] - 28 - height)
        this.visible = true
    }

    getWindowWidth(): number {
        return this.BOX_WIDTH
    }

    getWindowHeight(): number {
        return 345
    }

    setVisible(visible: boolean): void {
        this.visible = visible
    }

    reset(): void {
        // clear fields,
        // set active tab,
        // etc.
    }
}
