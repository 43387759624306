import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { ClientNPCVisit, NPC } from '@vmk-legacy/server-protos'
import { Graphics } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Helpers } from '../../../util/Helpers.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class EsmeraldaHandler extends WalkableRoomExtension {
    private levitateSfx: AudioBuffer
    private levitateSfxPlaying: AudioBufferSourceNode

    async init(): Promise<this> {
        await super.init()

        const npcArrangements = ['esmeralda_levitate']
        this.loadArrangements(npcArrangements)

        this.levitateSfx = this.room.loadedSounds.get('NPC-esmeralda-levitate-III')

        const npcTapArea = new Graphics().beginFill(0x00ff00).drawRect(0, 0, 60, 135).endFill()
        npcTapArea.eventMode = 'static'
        npcTapArea.cursor = 'pointer'
        npcTapArea.zIndex = 99999
        npcTapArea.position.set(300 - 400, 335 - 300)
        npcTapArea.alpha = 0
        this.room.sprites.addChild(npcTapArea)
        npcTapArea.addEventListener('pointerup', () => {
            this.onTap()
        })

        const npc = this.room.addNPC('npc-esmeralda', 0, 0, {}, 'Esmeralda the Fortuneteller', true)
        npc.getVisual().position.set(npcTapArea.x + npcTapArea.width / 2, npcTapArea.y + npcTapArea.height / 2)
        this.room.addEntity(npc)

        this.loop()

        return this
    }

    override teardown(): void {
        super.teardown()

        SoundManager.shared.release(this.levitateSfxPlaying)
        this.levitateSfxPlaying = null
        this.levitateSfx = null
    }

    private async loop(): Promise<void> {
        if (!this.running) {
            return
        }
        this.playArrangement('esmeralda_levitate').removeTapListeners().addTapListener(this.onTap)

        this.levitateSfxPlaying = SoundManager.shared.play(ESndGrp.SFX, this.levitateSfx)
        await Helpers.delay(30000)

        this.loop()
    }

    onTap = () => {
        Client.shared.serverBroker.send(
            new ClientNPCVisit({
                npc: NPC.Esmeralda
            })
        )
    }
}
