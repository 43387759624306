import { Pooler } from '@vmk-legacy/render-utils'
import { BitmapText, Sprite } from 'pixi.js'
import { Fonts } from '../../Fonts.js'
import type { CheckBoxController } from '../CheckBoxController.js'

export class CheckBox extends Sprite {
    private activeState: Sprite
    private passiveState: Sprite

    private isActive: boolean

    id: string

    private t: BitmapText
    controller: CheckBoxController

    constructor(id: string, text = '', isBold = false, isFolio = false) {
        super()

        this.id = id

        this.activeState = Pooler.newSprite('button.checkbox.1')
        this.passiveState = Pooler.newSprite('button.checkbox.0')

        this.addChild(this.activeState, this.passiveState)

        this.setActive(false)

        this.eventMode = 'static'
        this.interactiveChildren = true
        this.cursor = 'pointer'

        const paddingX = 4
        const paddingY = 3
        if ('' !== text) {
            if (isFolio) {
                this.t = new BitmapText(text, {
                    fontName: 'Folio',
                    fontSize: 15,
                    align: 'left'
                })
                this.t.y = paddingY - 1
            } else {
                if (isBold) {
                    this.t = new BitmapText(text, {
                        ...Fonts.FoxleyBold_16,
                        align: 'left'
                    })
                    this.t.y = paddingY - 1
                } else {
                    this.t = new BitmapText(text, {
                        ...Fonts.Foxley_16,
                        align: 'left'
                    })
                    this.t.y = paddingY
                }
            }
            this.t.x = this.activeState.width + paddingX
            this.t.eventMode = 'auto'
            this.t.interactiveChildren = false
            this.addChild(this.t)
        }
    }

    setText(text: string): void {
        this.t.text = text
    }

    setActive(active: boolean): void {
        if (this.isActive !== active) {
            this.isActive = active

            this.activeState.visible = active
            this.passiveState.visible = !active
            this.controller?.changed()
        }
    }

    getActive(): boolean {
        return this.isActive
    }
}
