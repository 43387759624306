import { SoundManager } from '@vmk-legacy/render-utils'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class GracefulDisconnectWarning extends ServerEvent {
    static readonly type = MessageType.GRACEFUL_DC

    async handle(data: {
        reason: number | string | null
        willReconnect?: boolean
    }) {
        console.log('about-to-dc', data)
        Client.shared.serverBroker.expectDisconnect = true

        SoundManager.shared.globalVolGain.gain.value = 0

        if (data.reason !== null) {
            const message = typeof data.reason === 'number' ? getText('connection.dropped.' + data.reason) : data.reason

            Client.shared.helpers
                .alert({
                    message: message,
                    title: 'Connection Lost'
                })
                .then(() => {
                    if (!data.willReconnect) {
                        window.close()
                        location.href = 'https://vmklegacy.com'
                    }
                })
        }
    }
}
