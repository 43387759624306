import { EPerm } from '@vmk-legacy/common-ts'
import { AvatarOutfit } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { MKGivePopup } from '../../ui/windows/mod/views/popups/MKGivePopup.js'
import type { ShopWindow } from '../../ui/windows/shop/ShopWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RecordsModule extends ServerEvent {
    static readonly type = MessageType.RECORDS_RECEIVED

    async handle(data: {
        id?: number
        signature?: string
        perms?: EPerm[]
        mickeys?: number[]
        outfit?: (string | number)[]
        credits?: number
        tkn?: string
        film?: number
        prefs?: { [k: string]: any }
        charging?: number[]
    }) {
        console.log('New records', data)

        if ('id' in data) {
            Client.shared.selfRecord.setId(data.id)
        }

        if ('prefs' in data) {
            Client.shared.prefs.replacePrefs(data.prefs)
        }

        if ('signature' in data) {
            Client.shared.selfRecord.setSignature(data.signature)
        }

        if ('perms' in data) {
            Client.shared.selfRecord.setPerms(data.perms)
            Client.shared.userInterface.toolbar?.updateExitButton()

            if (Client.shared.selfRecord.can(EPerm.PlayersInventoryAddition) && !MKGivePopup.itemDefs) {
                Client.shared.serverBroker.onEvent('mk_item_defs', (data: any) => {
                    MKGivePopup.itemDefs = data.items
                })
                Client.shared.serverBroker.send('mk_item_defs_req')
            }
        }

        if ('mickeys' in data) {
            Client.shared.selfRecord.setMickeys(data.mickeys)
        }
        if ('outfit' in data) {
            Client.shared.selfRecord.setOutfit(AvatarOutfit.from(data.outfit))
        }
        if ('tkn' in data) {
            Client.shared.selfRecord.setTkn(data.tkn)
        }
        if ('holiday' in data) {
            Client.shared.holiday = data.holiday as string
        }
        if ('film' in data) {
            if (data.film !== null) {
                Client.shared.userInterface.toolbar?.camBtn.enable()
            }
            Client.shared.selfRecord.setFilm(data.film)
        }

        if ('credits' in data) {
            Client.shared.selfRecord.setCredits(data.credits)

            if (!Client.shared.userInterface) {
                return
            }
            ;(await Client.shared.userInterface.getWin(EWindow.Inventory))?.updateCurrentView()

            const shopWin = (await Client.shared.userInterface.getWin(EWindow.Shopping)) as ShopWindow

            shopWin?.creditsUpdated()
        }

        if ('charging' in data) {
            Client.shared.selfRecord.setRecharging(data.charging || [])
            ;(await Client.shared.userInterface.getWin(EWindow.Magic))?.updateIcons()
        }
    }
}
