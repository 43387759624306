import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import { UISoundLibrary } from '../../ui/UISoundLibrary.js'
import { RandomUtil } from '../../util/RandomUtil.js'
import type { PotcDelegate } from './PotcDelegate.js'

const PotcFX = {
    Cannon: ['PG-broadside-1', 'PG-broadside-2', 'PG-cannon-2'],
    BroadSide: ['PG-broadside-1', 'PG-broadside-2'],
    Hit: ['PG-cannon-6', 'PG-cannon-7', 'PG-cannon-9', 'PG-cannon-11', 'PG-hit-4', 'PG-hit-5'],
    Splash: ['PG-miss-water'],
    Acknowledge: ['UI-click'],
    Fanfare: ['PG-fanfare'],
    Countdown: ['PVO-three', 'PVO-two', 'PVO-one', 'PVO-timetosetsail'],
    Moonlight: ['PG-moonlight'],
    Panic: ['PG-panic'],
    Preshoot: ['PG-preshoot-II'],
    LoadCannon: ['PG-load-cannon-II'],
    DropFlag: ['POTC2-SFX-drop-flag'],
    FlagToBase: ['POTC2-SFX-flagtobase'],
    TurnIntoRowboat: ['POTC2-SFX-turnintoarobot'],
    SeaMonsterAttack: ['POTC2-SFX-seamonster'],
    GoldenCannonball: ['POTC2-SFX-goldencannonball']
}
const PotcVOs = {
    Acknowledge: ['PVO-shipsahoy', 'PVO-apirateslifeforme', 'PVO-aicaptain'],
    fire: ['PVO-blastthose', 'PVO-cannonsaway', 'PVO-runashotacross', 'PVO-takethis'],
    CannonsReady: ['PVO-cannonsreadycaptain'],
    LowHealth: ['PVO-hullbreached'],
    OutOfAmmo: ['PVO-outofammo'],
    OwnTeamWins: ['PVO-goodworkmehardies'],
    OtherTeamWins: ['PVO-wevelost'],
    OwnShipHit: ['PVO-shivermetimbers', 'PVO-wevebeenhit'],
    GetHealth: ['PVO-weverepairedtheship'],
    GetAmmo: ['PVO-abountofcargoisyours'],
    GetFlag: ['POTC2-SFX-pickup-flag'],
    Death: ['PG-death'],
    BlowShip: ['PVO-wevebeensunk'],
    TimeLow: ['PVO-timeisrunningout']
}

const SoundVoiceOverSkip = 3

export class PotcSound {
    sea?: AudioBufferSourceNode
    music?: AudioBufferSourceNode
    voiceover?: AudioBufferSourceNode
    countdown?: AudioBufferSourceNode
    playingFX: AudioBufferSourceNode[] = []
    seldomAcc = 1

    constructor(readonly delegate: PotcDelegate) {}

    teardown() {
        SoundManager.shared.release(this.sea)
        this.sea = undefined
        SoundManager.shared.release(this.music)
        this.music = undefined
        for (const fx of this.playingFX) {
            SoundManager.shared.release(fx)
        }
        this.playingFX.length = 0
    }

    playFx(fx: keyof typeof PotcFX, which?: number) {
        if (this.playingFX.length === 4) {
            const removed = this.playingFX.shift()
            SoundManager.shared.release(removed)
        }
        const index = typeof which === 'number' ? which : RandomUtil.getRandomIndexFromArray(PotcFX[fx])
        const name = PotcFX[fx][index]
        const sound = name === 'UI-click' ? UISoundLibrary.UIClick : this.delegate.assets.sounds.get(name)

        if (sound) {
            this.playingFX.push(SoundManager.shared.play(ESndGrp.SFX, sound))
        }
    }

    playSea() {
        this.sea = SoundManager.shared.play(ESndGrp.SFX, this.delegate.assets.sounds.get('PG-sea-mono-loop'), true)
    }

    playCannon(golden: boolean) {
        this.playFx(golden ? 'GoldenCannonball' : 'Cannon')
    }

    playBroadSide() {
        this.playFx('BroadSide')
    }

    playSplash() {
        this.playFx('Splash')
    }

    playHit() {
        this.playFx('Hit')
    }

    playAcknowledge() {
        this.playFx('Acknowledge')
    }

    playCountdown(offset: number) {
        this.countdown = SoundManager.shared.play(
            ESndGrp.SFX,
            this.delegate.assets.sounds.get(PotcFX.Countdown[offset])
        )
    }

    playMoonlight() {
        this.playFx('Moonlight')
    }

    playPanic() {
        this.playFx('Panic')
    }

    playPreshoot() {
        this.playFx('Preshoot')
    }

    playLoadCannon() {
        this.playFx('LoadCannon')
    }

    playFanfare() {
        this.playFx('Fanfare')
    }

    playDropFlag() {
        this.playFx('DropFlag')
    }

    playFlagToBase() {
        this.playFx('FlagToBase')
    }

    playTurnIntoRowboat() {
        this.playFx('TurnIntoRowboat')
    }

    playSeaMonsterAttack() {
        this.playFx('SeaMonsterAttack')
    }

    playVoiceOver(type: keyof typeof PotcVOs) {
        SoundManager.shared.release(this.voiceover)
        const index = RandomUtil.getRandomIndexFromArray(PotcVOs[type])
        const sound = this.delegate.assets.sounds.get(PotcVOs[type][index])
        this.voiceover = SoundManager.shared.play(ESndGrp.SFX, sound)
    }

    playVoiceOverSeldom(type: keyof typeof PotcVOs) {
        if (this.seldomAcc >= SoundVoiceOverSkip) {
            SoundManager.shared.release(this.voiceover)
            const index = RandomUtil.getRandomIndexFromArray(PotcVOs[type])
            const sound = this.delegate.assets.sounds.get(PotcVOs[type][index])
            this.voiceover = SoundManager.shared.play(ESndGrp.SFX, sound)
            this.seldomAcc = 1
        } else {
            this.seldomAcc++
        }
    }
}
