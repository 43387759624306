import { AvatarOutfit } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import type { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import type { RoomLoadingView } from '../../ui/views/RoomLoadingView.js'
import { VMKPassWindow } from '../../ui/windows/rooms/VMKPassWindow.js'
import { Helpers } from '../../util/Helpers.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import { BeginRoomLoad } from './BeginRoomLoad.js'

type Payload = {
    entities: any[]
    myEntryAnim?: any
}

export class RoomJoinModule extends ServerEvent {
    static readonly type = MessageType.ROOM_JOIN

    static loader?: RoomLoadingView

    async handle(data: Payload): Promise<void> {
        console.log('>> RoomJoinModule')

        BeginRoomLoad.currentLoadingPayload = undefined

        const viewer = Client.shared.roomViewer as WalkableRoomViewer

        for (const entity of data.entities) {
            let object
            if ('outfit' in entity) {
                object = viewer.addAvatar(
                    entity.id,
                    AvatarOutfit.from(entity.outfit),
                    entity.tile,
                    entity.direction,
                    entity.actions,
                    entity.ign,
                    entity.lanyard,
                    entity.badges,
                    entity.signature,
                    entity.id === Client.shared.selfRecord.getRoomIdentifier() ? data.myEntryAnim : null,
                    !!entity.invisible,
                    !!entity.trading,
                    entity.effect?.effect,
                    entity.effect?.param,
                    entity.pronouns
                )
            } else {
                object = viewer.addNPC(
                    entity.id,
                    entity.tile,
                    entity.direction,
                    entity.actions,
                    entity.ign,
                    !!entity.invisible
                )
            }

            if (object && entity.moving) {
                object.startWalkingPath(entity.moving)
            }
        }

        await viewer.reveal()

        viewer.eventMode = 'static'

        Client.shared.userInterface.toolbar.infoButtonGlow()

        Helpers.delay(6000).then(() => Client.shared.userInterface.toolbar.stopInfoGlow())

        Client.shared.loadingView.setVisible(false)

        RoomJoinModule.loader?.destroy()
        RoomJoinModule.loader = undefined

        if (VMKPassWindow.instance) {
            Client.shared.userInterface.bringToFront(VMKPassWindow.instance)
        }
    }
}
