import { ClientNPCVisit, NPC } from '@vmk-legacy/server-protos'
import type { Sprite } from 'pixi.js'
import { Client } from '../../../Client.js'
import { Helpers } from '../../../util/Helpers.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class NedHandler extends WalkableRoomExtension {
    override async init(): Promise<this> {
        await super.init()

        const npcArrangements = ['talk', 'look', 'move', 'blink']
        this.loadArrangements(npcArrangements)

        const ned = this.room.sprites.getChildByName('neds_regpoint') as Sprite

        if (ned) {
            const npc = this.room.addNPC('npc-ned', 0, 0, {}, 'Shrunken Ned', true)
            npc.getVisual().position.set(600, 0)
            this.room.addEntity(npc)

            ned.eventMode = 'static'
            ned.cursor = 'pointer'
            ned.addEventListener('pointerup', () => {
                this.onTap()
            })
        }
        this.loop()

        return this
    }

    private async loop(): Promise<void> {
        if (!this.running) {
            console.log('HANDLER NOT RUNNING')
            return
        }
        console.log('Starting ned loop')
        this.playArrangement('look')
        await Helpers.delay(10000)
        this.playArrangement('blink')
        await Helpers.delay(500)
        this.playArrangement('blink')
        await Helpers.delay(10000)
        this.playArrangement('move')
        await Helpers.delay(5000)
        this.playArrangement('look')
        await Helpers.delay(5000)
        this.loop()
    }

    private onTap(): void {
        Client.shared.serverBroker.send(
            new ClientNPCVisit({
                npc: NPC.Ned
            })
        )
    }
}
