import { Pooler } from '@vmk-legacy/render-utils'
import { Container } from 'pixi.js'

export class PopupBox extends Container {
    boxHeight: number

    constructor(
        private boxWidth: number,
        boxHeight: number,
        private hasRoundBottom = true
    ) {
        super()

        this.changeHeight(boxHeight)
    }

    changeHeight(newHeight: number) {
        if (this.boxHeight !== newHeight) {
            this.boxHeight = newHeight

            this.removeChildren()

            const boxLeft = Pooler.newSprite('popup.top.left')
            const boxRight = Pooler.newSprite('popup.top.right')
            const boxTop = Pooler.newSprite('popup.middle')
            const boxFill = Pooler.newSprite('popup.middle.middle')

            boxLeft.x = boxLeft.y = 0
            boxTop.x = boxLeft.width
            boxTop.width = this.boxWidth - boxLeft.width - boxRight.width
            boxRight.x = boxTop.x + boxTop.width
            boxFill.y = boxTop.height
            boxFill.height = this.boxHeight - boxTop.height
            boxFill.width = this.boxWidth

            this.addChild(boxLeft, boxRight, boxTop, boxFill)

            if (this.hasRoundBottom) {
                const boxBottomLeft = Pooler.newSprite('popup.top.left')
                const boxBottomRight = Pooler.newSprite('popup.top.right')
                const boxBottomFill = Pooler.newSprite('popup.middle')

                boxFill.height -= boxBottomRight.height

                boxBottomLeft.scale.y = boxBottomRight.scale.y = -1
                boxBottomLeft.y = boxBottomRight.y = boxFill.y + boxFill.height + boxBottomLeft.height
                boxBottomRight.x = boxRight.x
                boxBottomFill.x = boxBottomLeft.x + boxBottomLeft.width
                boxBottomFill.y = boxBottomLeft.y - boxBottomLeft.height
                boxBottomFill.height = boxBottomLeft.height
                boxBottomFill.width = this.boxWidth - (boxBottomLeft.width + boxBottomRight.width)
                this.addChild(boxBottomLeft, boxBottomRight, boxBottomFill)
            }
        }
    }
}
