import type { HTMLTextStyle, TextStyleAlign, Texture } from 'pixi.js'
import { HTMLText, SCALE_MODES } from 'pixi.js'
import { Client } from '../Client.js'

export class TextRenderer {
    static pool: HTMLText[] = []
    static readonly defaultStyle: Partial<HTMLTextStyle> = {
        fontFamily: 'web-foxley',
        fontSize: 16,
        lineHeight: 16,
        align: 'left',
        padding: 0
    }

    static init(): void {
        Promise.all([this.create(), this.create(), this.create()]).then((newPool) => this.pool.push(...newPool))
    }

    static async create(): Promise<HTMLText> {
        const text = new HTMLText('', this.defaultStyle)
        text.style.addOverride(
            'font-smoothing: none',
            'text-rendering: geometricPrecision',
            '-webkit-font-smoothing: none'
        )
        await text.style.loadFont('./web-foxley.ttf', { family: 'web-foxley' })

        return text
    }

    static async getPool(): Promise<HTMLText> {
        const next = this.pool.shift()

        return next || (await this.create())
    }

    static release(renderer: HTMLText): void {
        if (this.pool.length > 5) {
            renderer.destroy()
            return
        }

        renderer.style.wordWrapWidth = undefined
        renderer.style.wordWrap = false
        renderer.style.align = 'left'
        renderer.style._fill = undefined

        this.pool.push(renderer)
    }

    static async get(string: string, wordWrap?: number, align?: TextStyleAlign, fill?: number): Promise<Texture> {
        const renderer = await this.getPool()
        if (wordWrap) {
            renderer.style.wordWrapWidth = wordWrap
            renderer.style.wordWrap = true
        }
        if (align) {
            renderer.style.align = align
        }
        renderer.style.fill = typeof fill === 'number' ? fill : 0x000000
        renderer.text = string

        await renderer.updateText()

        const texture = Client.shared.renderer.generateTexture(renderer, {
            resolution: Client.shared.renderer.resolution,
            scaleMode: SCALE_MODES.LINEAR
        })

        this.release(renderer)

        return texture
    }
}
