export enum UIWindowType {
    INVENTORY = 'inv',
    GIFTS = 'gifts',
    HELP = 'hlp',
    MESSENGER = 'mes',
    TRADE = 'tra',
    SHOP = 'cat',
    MOUSEKEEPING = 'mod', // QUEST = "quest",
    SETTINGS = 'set',
    GUEST_ROOMS = 'nav',
    CHARACTER = 'char'
}
