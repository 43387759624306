import streamsaver from 'streamsaver'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class SaveFile extends ServerEvent {
    static readonly type = MessageType.SaveFile

    async handle(payload: { name: string; content: string }): Promise<void> {
        const fileStream = streamsaver.createWriteStream(payload.name, {
            size: payload.content.length
        })

        const readableStream = new Response(payload.content).body

        // more optimized pipe version
        // (Safari may have pipeTo but it's useless without the WritableStream)
        if ('WritableStream' in window && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream).then(() => {
                console.log('done writing')
            })
        }

        // Write (pipe) manually
        const writer = fileStream.getWriter()

        const reader = readableStream.getReader()
        const pump = () => reader.read().then((res) => (res.done ? writer.close() : writer.write(res.value).then(pump)))

        pump()
    }
}
