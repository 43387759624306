import { Client } from '../Client.js'
import { WalkableRoomViewer } from '../room/renderer/WalkableRoomViewer.js'

export class BillboardManager {
    private billboards = new Map<number, BillboardInfo[]>()

    fill(boards: BillboardInfo[]): void {
        this.billboards?.clear()
        this.billboards = new Map<number, BillboardInfo[]>()

        for (const board of boards) {
            if (!this.billboards.get(board.room_id)) {
                this.billboards.set(board.room_id, [])
            }
            this.billboards.get(board.room_id).push(board)
        }
    }

    async reload(): Promise<void> {
        const externals = await Client.shared.api.loadExternals(['billboards'])
        if (!externals) {
            return
        }
        this.fill(externals.billboards)
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            await Client.shared.roomViewer.setBillboards(true)
        }
    }

    getForRoom(modelId: number): BillboardInfo[] {
        return this.billboards.get(modelId) || []
    }
}

export interface BillboardInfo {
    room_id: number // model ID
    sprite_node: string
    click_node?: string
    image_url: string
    open_url?: string
}
