/*
 * AlertBox.ts
 * VMK Legacy Client
 */

import { Container, Graphics, Text } from 'pixi.js'
import { getText } from '../assets/ExternalConfigManager.js'
import { Constants } from '../Constants.js'
import { UILayer } from '../enums.js'
import { Theme } from '../Theme.js'
import type { IWindow } from '../ui/windows/IWindow.js'
import type { ButtonConstructorParams } from './Button.js'
import { Button } from './Button.js'

export interface AlertBoxConstructorParams {
    version?: 'legacy' | 'modern'
    title: string
    message: string
    width?: number
    height?: number
    leftButton?: ButtonConstructorParams
    rightButton?: ButtonConstructorParams
}

export class AlertBox extends Container implements IWindow {
    view: Container

    constructor(params: AlertBoxConstructorParams) {
        super()
        if (params.version === 'modern') {
            this.setupModern(params)
        } else {
            this.setupLegacy(params)
        }
    }

    layer: UILayer = UILayer.AlwaysOnTop
    isDraggable?: boolean = true

    setVisible(visible: boolean): void {
        this.visible = visible
    }

    setupLegacy({ title, message, width = 250, leftButton, rightButton }: AlertBoxConstructorParams) {
        this.view = this //new Container()

        const titleLabel = new Text(getText(title), {
            fontFamily: 'web-foxley',
            fontWeight: 'bold',
            fill: Theme.getCurrent().label,
            fontSize: 16,
            align: 'center',
            wordWrapWidth: width - 40,
            wordWrap: true,
            textBaseline: 'alphabetic',
            breakWords: true
        })

        titleLabel.position.set(width / 2, 13)
        titleLabel.anchor.set(0.5, 0)

        const messageLabel = new Text(getText(message), {
            fontFamily: 'web-foxley',
            fill: Theme.getCurrent().label,
            fontSize: 16,
            align: 'center',
            wordWrapWidth: width - 30,
            wordWrap: true,
            textBaseline: 'alphabetic',
            breakWords: true
        })

        messageLabel.position.set(width / 2, 34)
        messageLabel.anchor.set(0.5, 0)

        const innerHeight = messageLabel.y + messageLabel.height + 20 + 23 + 25

        const background = new Graphics()
        background.lineStyle(3, Theme.getCurrent().modalOutline, 1, 0)
        background.beginFill(Theme.getCurrent().background)
        background.drawRoundedRect(0, 0, width, innerHeight, 10)
        background.endFill()
        background.lineStyle(undefined)
        background.beginFill(Theme.getCurrent().secondaryBackground)
        background.drawRoundedRect(10, 13, width - 20, 15, 5)
        this.view.addChild(background, titleLabel, messageLabel)

        if (leftButton) {
            const leftBtn = new Button(leftButton)
            leftBtn.view.position.set(13, innerHeight - 15 - leftBtn.view.height)
            this.view.addChild(leftBtn.view)
        }

        if (rightButton) {
            const rightBtn = new Button(rightButton)
            rightBtn.view.position.set(width - 13 - rightBtn.view.width, innerHeight - 15 - rightBtn.view.height)
            this.view.addChild(rightBtn.view)
        }
    }

    setupModern({ title, message, width = 275, leftButton, rightButton }: AlertBoxConstructorParams) {
        this.view = new Container()

        const titleLabel = new Text(String(title), {
            fontFamily: 'Dongle',
            fontWeight: 'bold',
            fill: Theme.getCurrent().label,
            fontSize: 30,
            align: 'center',
            wordWrapWidth: width - 40,
            wordWrap: true
        })

        titleLabel.position.set(width / 2, 12)
        titleLabel.anchor.set(0.5, 0)

        const messageLabel = new Text(String(message), {
            fontFamily: 'Dongle',
            fill: Theme.getCurrent().label,
            fontSize: 25,
            fontWeight: '300',
            align: 'center',
            wordWrapWidth: width - 30,
            wordWrap: true,
            lineHeight: 22
        })

        messageLabel.position.set(width / 2, 45)
        messageLabel.anchor.set(0.5, 0)

        const innerHeight = messageLabel.y + messageLabel.height + 20 + 23 + 25

        const background = new Graphics()
        background.lineStyle(2, Theme.getCurrent().modalOutline)
        background.beginFill(Theme.getCurrent().background)
        background.drawRoundedRect(0, 0, width, innerHeight, 10)
        background.endFill()
        background.lineStyle(undefined)
        background.beginFill(Theme.getCurrent().secondaryBackground)
        background.drawRoundedRect(10, 12, width - 20, 25, 7)
        this.view.addChild(background, titleLabel, messageLabel)

        if (leftButton) {
            const leftBtn = new Button(leftButton)
            leftBtn.view.position.set(12, innerHeight - 15 - leftBtn.view.height)
            this.view.addChild(leftBtn.view)
        }

        if (rightButton) {
            const rightBtn = new Button(rightButton)
            rightBtn.view.position.set(width - 12 - rightBtn.view.width, innerHeight - 15 - rightBtn.view.height)
            this.view.addChild(rightBtn.view)
        }
    }

    resize(w: number, h: number): void {
        this.view.x = w / 2 - this.view.width / 2
        this.view.y = h / 2 - this.view.height / 2
    }

    sizeDidChange(): void {
        this.resize(Constants.SIZE[0], Constants.SIZE[1])
    }
}
