import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { GuestRoomsWindow } from '../../ui/windows/rooms/GuestRoomsWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RoomListModule extends ServerEvent {
    static readonly type = MessageType.ROOM_LIST

    async handle(data: any) {
        GuestRoomsWindow.currentData = data
        ;(await Client.shared.userInterface?.getWin(EWindow.GuestRooms))?.update()
    }
}
