import type { EPronounForm } from '@vmk-legacy/common-ts'
import { EPronounOpt, Pronouns } from '@vmk-legacy/common-ts'
import 'joypad.js'
import { Pooler } from '@vmk-legacy/render-utils'
import type { RenderTexture } from 'pixi.js'
import { BLEND_MODES, RENDERER_TYPE, Texture } from 'pixi.js'
import type { TextKey } from './assets/ExternalConfigManager.js'
import { getText } from './assets/ExternalConfigManager.js'
import { Client } from './Client.js'
import { Constants } from './Constants.js'
import { AlertBox } from './stories/AlertBox.js'
import type { ButtonConstructorParams } from './stories/Button.js'
import type { AlertWindow } from './ui/windows/AlertWindow.js'
import { Helpers } from './util/Helpers.js'
import { RandomUtil } from './util/RandomUtil.js'

const joypad = window['joypad']

export interface IAlertConfig {
    title?: TextKey
    message: TextKey
    okLabel?: TextKey | false
    cancelLabel?: TextKey | false
    forceLg?: boolean
    headerBtns?: boolean
    tag?: string
    randomLoc?: boolean
}

export default class ClientHelpers {
    alerts = new Map<string, AlertWindow>()

    constructor() {
        joypad.set({ axisMovementThreshold: 0.5 })
        joypad.on('connect', (e) => {
            Client.shared.activeGamepad = e.gamepad

            this.vibrate(150, 'med')
        })
        joypad.on('disconnect', (e) => {
            if (Client.shared.activeGamepad === e.gamepad) {
                Client.shared.activeGamepad = null
            }
        })
        joypad.on('button_press', (e: { detail: { buttonName: string } }) => {
            if (!document.hasFocus()) {
                return
            }
            Client.shared.stage.emit('game_btn', e.detail.buttonName)
        })
        let lastMove = 0
        joypad.on(
            'axis_move',
            (e: {
                detail: { stickMoved: boolean; directionOfMovement: string }
            }) => {
                if (!document.hasFocus()) {
                    return
                }
                if (e.detail.stickMoved === 'left_stick') {
                    const now = Date.now()
                    if (now - lastMove < 400) {
                        return
                    }
                    lastMove = now
                    Client.shared.stage.emit('axis_move', e)
                }
            }
        )
    }

    getPronounStr(which: EPronounForm, forPronoun: EPronounOpt = EPronounOpt.They, name?: string): string {
        const str = String(Pronouns[forPronoun as keyof typeof Pronouns][which])

        return str.replace(':name', name ?? Client.shared.selfRecord.getIgn())
    }

    async alert(opts: IAlertConfig | TextKey): Promise<boolean> {
        const options: IAlertConfig = typeof opts === 'string' ? { message: opts } : opts

        const title = options.title === undefined ? 'Alert' : getText(options.title)
        const okLabel = options.okLabel === undefined ? 'OK' : options.okLabel
        const cancelLabel = options.cancelLabel === undefined ? false : options.cancelLabel
        const large = options.forceLg === undefined ? false : options.forceLg

        return new Promise<boolean>((resolve) => {
            const rightButton: ButtonConstructorParams | undefined = cancelLabel
                ? {
                      text: cancelLabel,
                      action: () => {
                          Client.shared.userInterface.removeWindow(newAlert)
                          resolve(false)
                      }
                  }
                : undefined
            const leftButton: ButtonConstructorParams | undefined = okLabel
                ? {
                      text: okLabel,
                      action: () => {
                          Client.shared.userInterface.removeWindow(newAlert)
                          resolve(true)
                      }
                  }
                : undefined
            const newAlert = new AlertBox({
                title,
                message: options.message,
                leftButton,
                rightButton
            })
            newAlert.sizeDidChange()
            if (options.randomLoc) {
                newAlert.x = RandomUtil.generateRandomNumberInRange(0, Constants.SIZE[0] - newAlert.width)
                newAlert.y = RandomUtil.generateRandomNumberInRange(0, Constants.SIZE[1] - newAlert.height - 27)
            }
            Client.shared.userInterface?.register(newAlert, true)

            if (options.tag) {
                this.removeAlert(options.tag)
                this.alerts.set(options.tag, newAlert)
            }

            this.vibrate(150, 'low').then(() => {
                console.log('done')
            })
        })
    }

    removeAlert(withTag: string): void {
        if (this.alerts.has(withTag)) {
            Client.shared.userInterface.removeWindow(this.alerts.get(withTag))
            this.alerts.delete(withTag)
        }
    }

    vibrate(duration = 150, power: 'low' | 'med' | 'high'): Promise<void> {
        return new Promise((resolve) => {
            const gamepad = Client.shared.activeGamepad
            if (gamepad && window['joypad']) {
                const opts = {
                    startDelay: 0,
                    duration,
                    weakMagnitude: 0.25,
                    strongMagnitude: 0.5
                }
                if (power === 'low') {
                    opts.weakMagnitude = 0.25
                    opts.strongMagnitude = 0
                } else if (power === 'med') {
                    opts.weakMagnitude = 0
                    opts.strongMagnitude = 0.5
                } else if (power === 'high') {
                    opts.weakMagnitude = 1
                    opts.strongMagnitude = 1
                }
                window['joypad'].vibrate(gamepad, opts)
            } else if (window.navigator.vibrate) {
                try {
                    window.navigator.vibrate(duration)
                } catch (ignored) {}
            }
            Helpers.delay(duration + 50).then(resolve)
        })
    }

    async confirm(opts: IAlertConfig | string): Promise<boolean> {
        const options: IAlertConfig = typeof opts === 'string' ? { message: opts } : opts

        if (!options.title) {
            options.title = 'Confirm'
        }
        if (!options.cancelLabel) {
            options.cancelLabel = 'CANCEL'
        }
        if (!options.okLabel) {
            options.okLabel = 'OK'
        }

        return this.alert(options)
    }

    setInteractive(value: boolean): void {
        Client.shared.stage.eventMode = value ? 'static' : 'auto'
        Client.shared.stage.interactiveChildren = value
    }

    static genHitmap(
        texture: Texture | RenderTexture,
        threshold: number,
        blendMode: BLEND_MODES = BLEND_MODES.NORMAL
    ): boolean {
        if (!texture || 'hitmap' in texture || texture === Texture.EMPTY) {
            return false
        }
        if (!texture.width || !texture.height) {
            return false
        }
        const tempSprite = Pooler.newSprite(texture)
        const oldRes = Client.shared.renderer.resolution
        Client.shared.renderer.resolution = 1
        const canvas = Client.shared.renderer.extract.canvas(tempSprite) as HTMLCanvasElement
        Client.shared.renderer.resolution = oldRes
        Pooler.release(tempSprite)
        if (!canvas.width || !canvas.height) {
            canvas.remove()
            return false
        }
        const context = canvas.getContext('2d')

        const w = canvas.width
        const h = canvas.height

        const imageData = context.getImageData(0, 0, w, h)
        canvas.remove()
        const hitmap = new Uint32Array(Math.ceil((w * h) / 32))

        for (let i = 0; i < w * h; i++) {
            //lower resolution to make it faster
            const ind1 = i % 32
            const ind2 = (i / 32) | 0
            //check every 4th value of image data (alpha number; opacity of the pixel)
            //if it's visible add to the array

            let hittable = imageData.data[i * 4 + 3] > threshold

            // if not translucent enough, but has a blend mode, consider low RGB values also
            if (hittable && blendMode !== BLEND_MODES.NORMAL) {
                hittable =
                    imageData.data[i * 4] > 20 && imageData.data[i * 4 + 1] > 20 && imageData.data[i * 4 + 2] > 20
            }

            if (hittable) {
                hitmap[ind2] = hitmap[ind2] | (1 << ind1)
            }
        }
        texture['hitmap'] = hitmap

        return true
    }
}

export function _inkToBlend(ink: number): BLEND_MODES {
    const blend =
        {
            32: BLEND_MODES.NORMAL, //non-opaque [no blending]
            33: BLEND_MODES.ADD, //add
            35: BLEND_MODES.SUBTRACT, //subtract
            37: BLEND_MODES.LIGHTEN, //lighten
            40: BLEND_MODES.LIGHTEN, //lighten
            39: BLEND_MODES.DARKEN, //darken
            41: BLEND_MODES.DARKEN //darken
        }[+ink] || BLEND_MODES.NORMAL

    return _getSupportedBlend(blend)
}

export function _getSupportedBlend(blend: BLEND_MODES): BLEND_MODES {
    if (Client.shared.renderer.type === RENDERER_TYPE.WEBGL) {
        return (
            {
                [BLEND_MODES.LIGHTEN]: BLEND_MODES.SCREEN,
                [BLEND_MODES.DARKEN]: BLEND_MODES.SCREEN
            }[blend] || blend
        )
    }

    return blend
}
