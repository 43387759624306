import { Client } from '../../Client.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import { TradePromptWindow } from '../../ui/windows/trade/TradePromptWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class TradeRequestModule extends ServerEvent {
    static readonly type = MessageType.TRADE_REQUEST

    override async handle(data: {
        fromId: EntityRoomIdentifier
        fromIgn: string
    }) {
        const fromId = data.fromId
        const fromIgn = data.fromIgn

        const tradePrompt = new TradePromptWindow(fromId, fromIgn)

        Client.shared.userInterface.register(tradePrompt)
    }
}
