import type { AssetProvider } from '@vmk-legacy/render-utils'
import { buildClothingItem, Pooler } from '@vmk-legacy/render-utils'
import { Container, Sprite } from 'pixi.js'
import { Client } from '../../Client.js'
import { ImageButton } from '../buttons/ImageButton.js'

export class ThumbPicker extends Container {
    protected thumbImage: Sprite | Container
    protected arrowLeft: ImageButton
    protected arrowRight: ImageButton
    protected pickerBg: Sprite
    protected index = -1
    protected changeCallback: (i) => void
    protected sprites: (Container | Sprite)[]
    parent: Container
    protected items: {
        id: string
        type: string
    }[]

    constructor(
        readonly provider: AssetProvider,
        items: {
            id: string | number
            type: string
        }[],
        onChange: (i) => void,
        sprites: Sprite[] | Container[] = [],
        readonly emptyValueTexture: string = 'nohats.preview'
    ) {
        super()

        this.items = items.map((i) => {
            return {
                id: String(i.id),
                type: i.type
            }
        })
        this.sprites = sprites
        this.changeCallback = onChange

        if (this.items.length > 0 || this.sprites.length > 0) {
            this.index = 0
        }

        this.pickerBg = Pooler.newSprite('char.part.viewer')
        this.addChild(this.pickerBg)
        this.pickerBg.x = 17

        this.arrowLeft = new ImageButton('char.button.left.active', 'char.button.left.pressed', '', '')
        this.addChild(this.arrowLeft)
        this.arrowLeft.addEventListener('pointerup', () => {
            if (this.index > -1) {
                this.index--

                if (this.index < 0) {
                    this.index = this.items.length - 1
                }
                this.updateThumb()
            }
        })

        this.arrowRight = new ImageButton('char.button.right.active', 'char.button.right.pressed', '', '')
        this.addChild(this.arrowRight)
        this.arrowRight.x = 60
        this.arrowRight.addEventListener('pointerup', () => {
            if (this.index > -1) {
                this.index++

                if (this.index > this.items.length - 1) {
                    this.index = 0
                }
                this.updateThumb()
            }
        })

        this.arrowLeft.y = this.arrowRight.y = 12

        this.updateThumb(false)
    }

    setItem(id: string): this {
        id = String(id)
        const foundIndex = this.items.findIndex((i) => i.id === id)

        if (foundIndex !== -1) {
            this.index = foundIndex
            this.updateThumb(false)
        }

        return this
    }

    updateThumb(persist = true): void {
        if (!this.parent) {
            return
        }
        if (this.index !== -1) {
            const i = this.index

            if (this.sprites[i]) {
                this.setThumbImage(this.sprites[i])
            } else {
                if (!this.items[this.index].id || this.items[this.index].id === '0') {
                    const noHatIcon = Pooler.newSprite(this.emptyValueTexture)
                    this.setThumbImage(noHatIcon)
                } else {
                    const defUid = this.items[this.index].id
                    buildClothingItem(defUid, 3, Client.shared.figuresMgr, this.provider).then((container) => {
                        this.setThumbImage(container)
                    })
                }
            }

            if (persist) {
                this.changeCallback(this.index)
            }
        }
    }

    setThumbImage(image: Sprite | Container): void {
        if (!image) {
            image = Pooler.newSprite()
        }
        if (this.thumbImage && this.thumbImage !== image) {
            Pooler.release(this.thumbImage)
        }
        const canvas = Client.shared.renderer.extract.canvas(image) as HTMLCanvasElement
        this.thumbImage = Sprite.from(canvas)
        this.addChild(this.thumbImage)

        const size = 36
        const sA: number = size / size
        const pA: number = this.thumbImage.width / this.thumbImage.height

        let sF: number
        if (sA > pA) {
            sF = size / this.thumbImage.height
        } else {
            sF = size / this.thumbImage.width
        }
        this.thumbImage.scale.set(sF)

        this.thumbImage.x = this.pickerBg.x + this.pickerBg.width / 2 - this.thumbImage.width / 2
        this.thumbImage.y = this.pickerBg.y + this.pickerBg.height / 2 - this.thumbImage.height / 2
    }
}
