import { Pooler } from '@vmk-legacy/render-utils'
import { Sprite } from 'pixi.js'

export class UIContentBG extends Sprite {
    constructor(innerWidth: number, innerHeight: number) {
        const topLeft: Sprite = Pooler.newSprite('content.top.left')
        const topMiddle: Sprite = Pooler.newSprite('content.top.middle')
        const topRight: Sprite = Pooler.newSprite('content.top.right')
        const rightMiddle: Sprite = Pooler.newSprite('content.right.middle')
        const bottomRight: Sprite = Pooler.newSprite('content.bottom.right')
        const bottomMiddle: Sprite = Pooler.newSprite('content.bottom.middle')
        const bottomLeft: Sprite = Pooler.newSprite('content.bottom.left')
        const leftMiddle: Sprite = Pooler.newSprite('content.left.middle')
        const middle: Sprite = Pooler.newSprite('content.middle.middle')

        super()

        this.eventMode = 'static'
        this.interactiveChildren = true

        topLeft.x = topLeft.y = 0
        topMiddle.x = topLeft.width
        topMiddle.width = innerWidth
        topRight.x = topLeft.width + topMiddle.width
        rightMiddle.x = topRight.x
        rightMiddle.y = topMiddle.height
        rightMiddle.height = innerHeight
        bottomRight.x = rightMiddle.x
        bottomRight.y = topRight.height + rightMiddle.height
        bottomMiddle.x = bottomLeft.width
        bottomMiddle.y = bottomRight.y
        bottomMiddle.width = innerWidth
        bottomLeft.y = bottomMiddle.y
        leftMiddle.y = topLeft.height
        leftMiddle.height = innerHeight
        middle.x = topLeft.width
        middle.y = topLeft.height
        middle.width = innerWidth
        middle.height = innerHeight

        this.addChild(
            topLeft,
            topMiddle,
            topRight,
            rightMiddle,
            bottomRight,
            bottomMiddle,
            bottomLeft,
            leftMiddle,
            middle
        )
    }
}
