import { ServerEmote } from '@vmk-legacy/server-protos'
import { Client } from '../../Client.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { defineHandler } from '../ServerEvent.js'

export const serverEmoteHandler = defineHandler(ServerEmote, ({ entityId, emote }) => {
    if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
        const entity = Client.shared.roomViewer.getEntityByRef(entityId)
        if (entity) {
            entity.getVisual().setEmote(emote, emote !== 1000)
        } else {
            console.error('Entity not found: ' + entityId)
        }
    }
})
