import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums'
import { ImageButton } from '../../buttons/ImageButton'
import { UISoundLibrary } from '../../UISoundLibrary'
import { UIWindow } from '../UIWindow'
import { UIWindowTab } from '../UIWindowTab'
import { UIWindowType } from '../UIWindowType'
import { GuestRoomListView } from './GuestRoomListView'

export class GuestRoomsWindow extends UIWindow {
    readonly kind = EWindow.GuestRooms

    isDraggable: boolean
    exitBtn: ImageButton

    popularRoomsView: GuestRoomListView
    friendsRoomsView: GuestRoomListView
    ownRoomsView: GuestRoomListView

    static currentData: any

    constructor() {
        super(400, UIWindowType.GUEST_ROOMS)
        this.isDraggable = true
    }

    windowWasBuilt(): void {
        this.exitBtn = new ImageButton('button.close.active', 'button.close.pressed')
        this.addChild(this.exitBtn)
        this.exitBtn.x = this.header.x + this.header.width - 18
        this.exitBtn.y = this.header.y + 2

        this.popularRoomsView = new GuestRoomListView()
        const popularRoomsTab = new UIWindowTab('POPULAR GUEST ROOMS', this.popularRoomsView)
        this.addChild(popularRoomsTab)

        this.friendsRoomsView = new GuestRoomListView()
        const friendsRoomsTab = new UIWindowTab("FRIENDS' ROOMS", this.friendsRoomsView)
        this.addChild(friendsRoomsTab)

        this.ownRoomsView = new GuestRoomListView()
        const ownRoomsTab = new UIWindowTab('OWN ROOMS', this.ownRoomsView)
        this.addChild(ownRoomsTab)

        this.views.push(this.popularRoomsView, this.friendsRoomsView, this.ownRoomsView)
        this.tabs.push(popularRoomsTab, friendsRoomsTab, ownRoomsTab)

        // loop over tabs... add event listeners, interactive(Children), set positions
        let currentX = 0
        for (const t of this.tabs) {
            t.eventMode = 'static'
            t.interactiveChildren = true
            t.cursor = 'pointer'

            t.y = 45 // static
            t.x = currentX + 28
            currentX += t.getWidth()

            t.setActive(false)

            t.addEventListener('pointerup', (e: FederatedEvent) => {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.PopupTabChange)
                this.setActiveTab(t)
                Client.shared.serverBroker.send('room_list_request')
            })
        }

        // set default tab as active
        this.setActiveTab(popularRoomsTab)

        if (GuestRoomsWindow.currentData) {
            this.update()
        }
        Client.shared.serverBroker.send('room_list_request')
    }

    update(data?: any): void {
        data = data || GuestRoomsWindow.currentData
        if (!data) {
            return
        }

        this.popularRoomsView.setRooms(data.public)
        this.friendsRoomsView.setRooms(data.friends)
        this.ownRoomsView.setRooms(data.own)
    }
}
