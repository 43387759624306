import { ShopCategory } from './ShopCategory.js'
import type { ShopItem } from './ShopItem.js'

export class ShopCategoryParent extends ShopCategory {
    private categories: ShopCategory[]

    constructor(name: string, items: ShopItem[], logoId = 6, categories: ShopCategory[] | null = null) {
        super(name, items, logoId)

        this.categories = []

        if (categories !== null) {
            this.categories = categories
        }
    }

    addCategory(category: ShopCategory): void {
        this.categories.push(category)
    }

    getCategories(): ShopCategory[] {
        return this.categories
    }
}
