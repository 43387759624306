import { Sprite } from 'pixi.js'
import type { RoomEntityVisualizing } from '../../room/RoomObject.js'
import type { PotcFactorBase } from './PotcFactorBase.js'

export abstract class PotcVisualBase extends Sprite implements RoomEntityVisualizing {
    abstract factor: PotcFactorBase

    abstract setActions(actions: any): void

    abstract setDirection(dir): void

    abstract update(dt: number): void
}
