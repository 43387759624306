import type { ICastProvides } from '@vmk-legacy/render-utils'
import { animArrangementToScore, ScoreRunner } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import { Client } from '../../../Client.js'
import type { WalkableRoomViewer } from '../WalkableRoomViewer.js'

// An object that extends the room's functionality.
// Multiple room extensions may be used in one room.
export abstract class WalkableRoomExtension {
    // If this is a counterpart to a server-side extension, the identifier must match.
    static identifier?: string

    provided?: ICastProvides
    requireCasts: string[] = []
    npcActions: { [k: string]: ScoreRunner } = {}
    lastArrangement: ScoreRunner
    running = true

    runners = {
        timeouts: new Set<number>(),
        intervals: new Set<number>(),
        tweens: new Set<gsap.core.Tween>()
    }

    constructor(protected readonly room: WalkableRoomViewer) {}

    async init(): Promise<this> {
        this.running = true

        if (this.requireCasts.length) {
            this.provided = await this.room.provider.get(this.requireCasts)
        }

        return this
    }

    teardown(): void {
        this.running = false

        for (const t of this.runners.timeouts) {
            window.clearTimeout(t)
        }
        for (const i of this.runners.intervals) {
            window.clearInterval(i)
        }
        for (const t of this.runners.tweens) {
            t.kill()
        }
    }

    // This is called after init but before roomWillReveal.
    receiveInitialData(data?: unknown): void {
        // implement me
    }

    // This is called when the room is initialized and is about to be shown.
    async roomWillReveal(): Promise<void> {}

    protected setInterval(func: () => void, interval: number): number | undefined {
        if (!this.running) {
            return undefined
        }
        const id = window.setInterval(() => func(), interval)

        this.runners.intervals.add(id)

        return id
    }

    protected setTimeout(func: () => void, delay: number): number | undefined {
        if (!this.running) {
            return undefined
        }
        const id = window.setTimeout(() => {
            func()

            this.runners.intervals.delete(id)
        }, delay)

        this.runners.timeouts.add(id)

        return id
    }

    protected clearInterval(id: number): void {
        this.runners.intervals.delete(id)

        clearInterval(id)
    }

    protected clearTimeout(id: number): void {
        this.runners.timeouts.delete(id)

        clearTimeout(id)
    }

    protected tween(
        target: any,
        duration: number,
        vars: any,
        ease?: string | gsap.EaseFunction,
        completion?: () => any
    ): Promise<void> {
        return new Promise<void>((resolve) => {
            if (!this.running) {
                return resolve()
            }
            if ('onComplete' in vars) {
                completion = vars.onComplete
                delete vars.onComplete
            }
            if (ease) {
                vars.ease = ease
            }
            const tween = gsap.to(target, {
                duration,
                ...vars,
                onComplete: () => {
                    if (completion) {
                        completion()
                    }
                    this.runners.tweens.delete(tween)
                    resolve()
                }
            })

            this.runners.tweens.add(tween)
        })
    }

    playArrangement(named: string, allowDouble = false): ScoreRunner | undefined {
        if (!this.running) {
            console.log('Not playing arrangement, handler not yet running. Trying to play in constructor?')
            return undefined
        }

        const arr = this.npcActions[named]
        if (!arr) {
            return undefined
        }
        console.log('Playing arrangement: ' + named, arr)

        arr.playFromBeginning(true)

        if (!allowDouble) {
            // if (this.lastArrangement && this.lastArrangement !== arr) {
            //     console.log('hide arrangement next tick', this.lastArrangement)
            //     arr.hideScoreNextTick = this.lastArrangement
            // }
            this.lastArrangement = arr
        }

        return arr
    }

    loadArrangements(npcArrangements: string[]): void {
        for (const named of npcArrangements) {
            const arrangement = this.room.assets.vars[named + '_arrangement'] as any[]
            if (!arrangement) {
                continue
            }

            const scoreDef = animArrangementToScore(named, this.room.assets, arrangement)
            const animScore = new ScoreRunner()
            animScore.loadDefinition(this.room.assets, scoreDef, undefined, this.room.sprites)
            Client.shared.attachScore(animScore)

            this.npcActions[named] = animScore

            animScore.autoplays = false
            animScore.loops = false
        }
    }
}
