import { ColorMatrixFilter } from '@pixi/filter-color-matrix'
import type { AvatarOutfit } from '@vmk-legacy/render-utils'
import { Pooler } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { Sprite } from 'pixi.js'
import { BLEND_MODES, Container, Point, Polygon } from 'pixi.js'
import { Client } from '../../Client.js'
import { _getSupportedBlend } from '../../ClientHelpers.js'
import { AvatarVisual } from '../../room/entities/AvatarVisual.js'
import type { RoomEntityVisualizing } from '../../room/RoomObject.js'
import type { JCPersonEntity } from './JCPersonEntity.js'
import { JungleCruiseBoatTilt } from './JungleCruiseBoatTilt.js'
import type { JungleCruiseRoom } from './JungleCruiseRoom.js'

export class JungleCruiseBoat extends Container implements RoomEntityVisualizing {
    private factor: JCPersonEntity
    private avatar: AvatarVisual

    private color: string
    private dirNum: number

    lastBoat: Container
    overlay: Container

    private flame: Sprite
    hitPoly?: Polygon

    parent: JungleCruiseRoom
    private tilt: JungleCruiseBoatTilt

    static readonly hitPolyUp = new Polygon([
        new Point(16, 70),
        new Point(38, 82),
        new Point(99, 55),
        new Point(77, 42)
    ])
    static readonly hitPolyDown = new Polygon([
        new Point(39, 0),
        new Point(17, 14),
        new Point(77, 42),
        new Point(100, 29)
    ])

    constructor(color: string, direction: 'up' | 'down', withAviOutfit?: AvatarOutfit) {
        super()

        this.eventMode = 'auto'
        this.interactiveChildren = false

        this.color = color

        this.setDirection(direction)

        if (withAviOutfit) {
            const avatar = new AvatarVisual(Client.shared.roomViewer.provider, this.dirNum)
            avatar.setUpdatedOutfit(withAviOutfit)
            this.boardAvi(avatar)
        } else {
            this.setTilt(JungleCruiseBoatTilt.STRAIGHT)
        }
    }

    setDirection(direction: 'up' | 'down'): void {
        this.dirNum = direction === 'up' ? 1 : 3
        this.hitPoly = direction === 'up' ? JungleCruiseBoat.hitPolyUp : JungleCruiseBoat.hitPolyDown

        if (this.avatar && this.avatar.getDirection() !== this.dirNum) {
            this.avatar.setDirection(this.dirNum)

            if (direction === 'up') {
                this.avatar.x = -25
                this.avatar.y = 25
            } else {
                this.avatar.x = -20
                this.avatar.y = -5
            }

            this.setTilt(this.tilt)
        }
    }

    boardAvi(avi: AvatarVisual): void {
        avi.eventMode = 'auto'
        avi.reloadOutfitItems().then(() => {
            avi.steer()
        })
        avi.setDirection(this.dirNum)
        avi.scale.set(0.75)

        if (this.dirNum === 1) {
            avi.x = -25
            avi.y = 25
        } else {
            avi.x = -20
            avi.y = -5
        }

        this.avatar = avi

        this.setTilt(this.tilt)
    }

    setTilt(tilt: JungleCruiseBoatTilt): void {
        this.tilt = tilt

        this.removeChild(this.lastBoat)
        this.removeChild(this.overlay)

        this.overlay = this.getBoat(this.color, this.dirNum, tilt, true)
        this.overlay.alpha = 0

        const c = new ColorMatrixFilter()
        const tint = 0xff0000
        const r = (tint >> 16) & 0xff
        const g = (tint >> 8) & 0xff
        const b = tint & 0xff
        c.matrix[0] = r / 255
        c.matrix[6] = g / 255
        c.matrix[12] = b / 255
        this.overlay.filters = [c]
        // this.overlay.cacheAsBitmap = true;

        this.lastBoat = this.getBoat(this.color, this.dirNum, tilt)

        this.addChildAt(this.overlay, 0)
        this.addChildAt(this.lastBoat, 0)
    }

    private getBoat(
        color: string,
        dir = 1,
        tilt: JungleCruiseBoatTilt = JungleCruiseBoatTilt.STRAIGHT,
        isOverlay = false
    ): Container {
        const boat = Pooler.newContainer()
        let pieces = [
            `wake${dir}`,
            `reflection${dir}`,
            `hull${dir}_${tilt}`,
            'AVATAR',
            `hull${dir}_${tilt}_mask`,
            `${color}${dir}_${tilt}`,
            `light${dir}`
        ]
        if (dir === 3) {
            pieces = [
                `wake${dir}`,
                `reflection${dir}`,
                `hull${dir}_${tilt}`,
                `${color}${dir}_${tilt}`,
                'AVATAR',
                `hull${dir}_${tilt}_mask`,
                `light${dir}`
            ]
        }

        pieces.forEach((piece) => {
            if (piece === 'AVATAR') {
                if (this.avatar) {
                    boat.addChild(this.avatar)
                }
                return
            }
            let sprite = Pooler.newSprite(piece)

            if (piece.startsWith('reflection') || piece.startsWith('light') || piece.startsWith('wake')) {
                if (isOverlay) {
                    sprite = Pooler.newSprite()
                }
                sprite.blendMode = _getSupportedBlend(BLEND_MODES.ADD)
            }

            boat.addChild(sprite)
        })
        return boat
    }

    override destroy(options?: {
        children?: boolean
        texture?: boolean
        baseTexture?: boolean
    }): void {
        super.destroy(options)

        gsap.killTweensOf(this)
    }
}
