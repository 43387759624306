import type { VMKLegacyAPIInteracting } from '@vmk-legacy/common-ts'
import { EMagicEffectTypes, VMKLegacyAPI } from '@vmk-legacy/common-ts'
import type { ICastProvides } from '@vmk-legacy/render-utils'
import { AssetLoader, ESndGrp, FigureSetsManager, Pooler, ScoreRunner, SoundManager } from '@vmk-legacy/render-utils'
import FontFaceObserver from 'fontfaceobserver'
import { gsap } from 'gsap'
import type { AllFederatedEventMap, FederatedEventTarget, Renderer } from 'pixi.js'
import {
    Application,
    Container,
    DisplayObject,
    Graphics,
    Point,
    RENDERER_TYPE,
    RenderTexture,
    settings,
    Sprite,
    Text,
    Texture,
    Ticker
} from 'pixi.js'
import streamsaver from 'streamsaver'
import { BillboardManager } from './assets/BillboardManager.js'
import { ExternalConfigManager, getText } from './assets/ExternalConfigManager.js'
import { ClientFurniMapManager } from './assets/furnimap/ClientFurniMapManager.js'
import { LoadingScreensManager } from './assets/LoadingScreensManager.js'
import { RoomInfoManager } from './assets/RoomInfoManager.js'
import ClientHelpers from './ClientHelpers.js'
import Config from './Config.js'
import { Constants } from './Constants.js'
import { SelfRecord } from './data/SelfRecord.js'
import { StaffData } from './data/StaffData.js'
import { EWindow } from './enums.js'
import type { MinigameDelegate } from './minigame/MinigameDelegate.js'
import { RegistrationView } from './registration/RegistrationView.js'
import { MagicDefinitions } from './room/entities/fx/MagicDefinitions.js'
import type { RoomViewer } from './room/renderer/RoomViewer.js'
import { WalkableRoomViewer } from './room/renderer/WalkableRoomViewer.js'
import { GameJoinModule } from './server/messages/GameJoinModule.js'
import { MessagesModule } from './server/messages/MessagesModule.js'
import { MockServerTransportAdapter } from './server/MockServerTransportAdapter.js'
import { ServerBroker } from './server/ServerBroker.js'
import { VanillaWebsocketAdapter } from './server/VanillaWebsocketAdapter.js'
import { SessionPrefs } from './SessionPrefs.js'
import { TextRenderer } from './ui/TextRenderer.js'
import { UISoundLibrary } from './ui/UISoundLibrary.js'
import { UserInterface } from './ui/UserInterface.js'
import type { ISizeChanging } from './ui/views/AlertView.js'
import { SizeInfo } from './ui/views/AlertView.js'
import { LoadingView } from './ui/views/LoadingView.js'
import { GuideView } from './ui/windows/GuideView.js'
import type { InventoryWindow } from './ui/windows/inventory/InventoryWindow.js'
import LegacyWindow from './ui/windows/LegacyWindow.js'
import { VMKPassWindow } from './ui/windows/rooms/VMKPassWindow.js'
import type { UIWindowView } from './ui/windows/UIWindowView.js'
import * as Sentry from '@sentry/browser'

export class Client extends Application {
    loadingView: LoadingView
    userInterface = new UserInterface()
    viewport: Container

    roomViewer?: RoomViewer

    // managers
    helpers = new ClientHelpers()
    assetLoader = new AssetLoader(Config.environment.assetRoot)
    serverBroker = new ServerBroker(new VanillaWebsocketAdapter())
    soundScore: ScoreRunner
    furniMapMgr = new ClientFurniMapManager()
    figuresMgr = new FigureSetsManager()
    roomInfoMgr = new RoomInfoManager()
    billboardMgr = new BillboardManager()
    runningMinigame?: MinigameDelegate
    essentialAssets: ICastProvides

    // data
    selfRecord = new SelfRecord()
    staffData = new StaffData()
    keysDown = new Map<number, boolean>()

    // collections
    activeScores: ScoreRunner[] = []
    slowTicker = new Ticker()

    // settings
    supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    supportsMouse = window.matchMedia('(any-hover: hover)').matches
    buildHash = __webpack_hash__

    // html
    readonly overlayEl
    readonly containerEl
    regView: RegistrationView

    activeGamepad

    startArgs?: URLSearchParams
    holiday?: string
    api: VMKLegacyAPIInteracting = new VMKLegacyAPI(Config.environment.apiUrl, true)

    roomDebugWin: Window | null
    #debugReady: (v?: any) => void

    responsiveMode = false
    size: SizeInfo

    static shared: Client
    isSpectating = false

    static instantiate(): Client {
        if (this.shared) {
            return this.shared
        }
        // configure gsap
        gsap.defaults({
            ease: 'none',
            overwrite: 'auto',
            duration: 1
        })
        gsap.ticker.lagSmoothing(0)

        // configure pixi
        settings.STRICT_TEXTURE_CACHE = true

        const realAddEvent = DisplayObject.prototype.addEventListener

        DisplayObject.prototype.addEventListener = function <K extends keyof AllFederatedEventMap>(
            type: K,
            listener: (e: AllFederatedEventMap[K]) => any,
            options?: any
        ): void {
            realAddEvent.call(this, type, (e: AllFederatedEventMap[K]) => {
                if (globalOpts.onlyAllowEventsOn && e.currentTarget !== globalOpts.onlyAllowEventsOn) {
                    return
                }

                listener(e)
            })
        }

        Sprite.prototype.containsPoint = function (point) {
            if (!this.visible || this.eventMode === 'none') {
                return false
            }
            const tempPoint = new Point()
            this.worldTransform.applyInverse(point, tempPoint)

            const width = Math.ceil(this._texture.width)
            const height = Math.ceil(this._texture.height)

            const x1 = -width * this.anchor.x
            let y1 = 0

            let flag = false

            if (tempPoint.x >= x1 && tempPoint.x < x1 + width) {
                y1 = -height * this.anchor.y

                if (tempPoint.y >= y1 && tempPoint.y < y1 + height) {
                    flag = true
                }
            }

            if (!flag) {
                return false
            }
            // bitmap check
            const hitmap = this._texture['hitmap']
            if (!hitmap) {
                return true
            }

            const dx = Math.round(tempPoint.x - x1)
            const dy = Math.round(tempPoint.y - y1)

            const ind = dx + dy * width
            const ind1 = ind % 32
            const ind2 = (ind / 32) | 0

            return (hitmap[ind2] & (1 << ind1)) !== 0
        }

        this.shared = new Client()
        return this.shared
    }

    private constructor(
        protected readonly domRoot: Document = document,
        readonly prefs: SessionPrefs = new SessionPrefs()
    ) {
        super({
            width: window.innerWidth,
            height: window.innerHeight,
            backgroundColor: 0x001f55, //0x000000,
            antialias: false,
            premultipliedAlpha: false,
            autoDensity: true,
            sharedTicker: true,
            backgroundAlpha: 1,
            resolution: window.devicePixelRatio || 1
        })

        delete this.renderer.plugins.accessibility

        this.viewport = this.stage.addChild(new Container())

        if (this.renderer.type === RENDERER_TYPE.CANVAS) {
            alert(
                "Your web browser did not allow VMK Legacy to use WebGL. It is recommended to switch to a newer device, or configure your browser to use WebGL. Try enabling accelerated graphics in your browser's settings."
            )
        }

        const canvas = this.view as HTMLCanvasElement

        canvas.classList.add('loading')
        canvas.id = 'canvas'
        canvas.style.touchAction = 'manipulation' // must set here because pixi overrides the CSS
        domRoot.getElementById('viewport').prepend(canvas)

        this.overlayEl = domRoot.getElementById('overlay')
        this.overlayEl.onsubmit = (e) => {
            e.preventDefault()

            return false
        }
        this.containerEl = domRoot.getElementById('viewport')

        this.slowTicker.maxFPS = ScoreRunner.frameRate
        this.slowTicker.minFPS = ScoreRunner.frameRate
        this.slowTicker.autoStart = true

        const g = new Graphics()
        g.beginFill(0x000000)
        g.drawRect(0, 0, Constants.SIZE[0], Constants.SIZE[1])
        g.endFill()
        g.eventMode = 'auto'
        this.stage.addChildAt(g, 0)
        this.viewport.mask = g

        TextRenderer.init()
    }

    createMockNetwork(): MockServerTransportAdapter {
        const provider = new MockServerTransportAdapter()
        this.serverBroker = new ServerBroker(provider)
        return provider
    }

    toggleFullScreen(): void {
        if (!this.domRoot.fullscreenElement) {
            this.domRoot.documentElement.requestFullscreen()
        } else {
            if (this.domRoot.exitFullscreen) {
                this.domRoot.exitFullscreen()
            }
        }
    }

    togglePIP(): void {
        if (!this.domRoot.pictureInPictureEnabled) {
            alert('Sorry, your browser does not support Picture-in-Picture.')

            return
        }
        if (this.domRoot.pictureInPictureElement) {
            this.domRoot.exitPictureInPicture()
        } else {
            const video = this.domRoot.createElement('video')
            video.playsInline = true
            video.autoplay = true
            video.muted = true
            video['autoPictureInPicture'] = true
            this.domRoot.body.appendChild(video)
            video.style.position = 'fixed'
            video.style.top = '0'
            video.style.left = '0'
            video.style.width = '100%'
            video.style.height = '100%'
            this.view.style.display = 'none'
            video.addEventListener('leavepictureinpicture', () => {
                video.pause()
                this.view.style.display = 'block'
                video.srcObject = null
                video.remove()
            })
            video.onloadedmetadata = () => {
                video.requestPictureInPicture().catch(() => {
                    this.helpers.alert(
                        'Something went wrong entering Picture-in-Picture mode. It may not be supported in your browser.'
                    )

                    video.pause()
                    this.view.style.display = 'block'
                    video.srcObject = null
                    video.remove()
                })
            }
            video.srcObject = (this.view as HTMLCanvasElement).captureStream()
        }
    }

    async initialize(startArgs?: URLSearchParams): Promise<void> {
        window.name = 'vmk-client'

        this.startArgs = startArgs
        this.isSpectating = !!startArgs?.has('spectate')

        try {
            await this.assetLoader.init()
        } catch (error) {
            Sentry.captureException(error)
            console.error(error)
            alert(
                'There was a problem loading assets for VMK Legacy. (Error code: 825)\n' +
                    '1. Click OK, then try refreshing the page.\n' +
                    "2. If you use a security program, VPN, or browser add-on, ensure they are not modifying SSL (HTTPS) certificates. If present, disable this setting as it interferes with your browser's ability to authenticate secure requests. In some cases, this happens at a network-level like in a corporate environment.\n" +
                    "3. Update your OS and browser, and verify that your device's date/time is correct."
            )
        }

        this.viewport.addChild(this.userInterface)
        this.setupEventListeners()

        if (process.env.NODE_ENV === 'development') {
            await this.#setupDebugger()
        }
        SoundManager.shared.init()
        this.prefs.apply()

        this.soundScore = new ScoreRunner({
            name: 'Main Score',
            persists: true
        })
        Client.shared.attachScore(this.soundScore)
        this.soundScore.setupSoundChannels()

        this.resizeFunc()

        await this.loadCoreAssets()

        try {
            this.loadingView = this.viewport.addChild(new LoadingView(getText('roomqueue.preloader.hd'), ''))
        } catch (e) {
            Sentry.captureException(e)
            console.log(e)
            // error loading fonts, probably
            alert('Something went wrong while loading VMK Legacy. Please try refreshing. (Error code: 900)')
        }

        const logo = this.domRoot.getElementById('logo')

        this.view.style.opacity = '0'
        this.view.style.display = 'block'
        this.view.classList.remove('loading')

        gsap.to(logo.style, {
            duration: 0.5,
            opacity: 0,
            onComplete() {
                logo.parentNode.removeChild(logo)
            }
        })

        gsap.to(this.view.style, {
            duration: 0.5,
            opacity: 1
        })

        this.updateWatermark()

        if (this.startArgs?.has('win')) {
            console.log('showing window ' + this.startArgs.get('win'))
            const win = new LegacyWindow(this.startArgs.get('win'))
            this.stage.removeChildren()
            await win.waitToBeBuilt()
            this.stage.addChild(win)

            return
        }

        this.viewport.sortableChildren = true

        await this.loadEssentialGraphics()

        this.authenticate(this.startArgs?.get('at') as string)
    }

    attachScore(score: ScoreRunner): void {
        this.activeScores.push(score)
    }

    detachScore(score: ScoreRunner): void {
        const index = this.activeScores.indexOf(score)
        if (index !== -1) {
            Client.shared.activeScores.splice(index, 1)
        }
    }

    updateWatermark(): void {
        if (process.env.NODE_ENV !== 'production') {
            this.stage.getChildByName('debug-watermark1')?.destroy()
            this.stage.getChildByName('debug-watermark2')?.destroy()

            let browser = navigator.vendor
            if (navigator.userAgent.includes('LegacyPal')) {
                browser = 'LegacyPal'
            } else if (navigator.userAgent.includes('Edge')) {
                browser = 'Edge'
            } else if (navigator.userAgent.includes('Firefox')) {
                browser = 'Firefox'
            } else if (navigator.userAgent.includes('Chrome')) {
                browser = 'Chrome'
            } else if (navigator.userAgent.includes('Safari')) {
                browser = 'Safari'
            } else if (navigator.userAgent.includes('Opera')) {
                browser = 'Opera'
            }

            let osName = navigator.platform
            if (navigator.platform.includes('Mac')) {
                osName = 'Mac'
            } else if (navigator.platform.includes('Windows')) {
                osName = 'Windows'
            } else if (navigator.platform.includes('Linux')) {
                osName = 'Linux'
            } else if (navigator.platform.includes('Android')) {
                osName = 'Android'
            }

            const watermark = `[client] ${this.buildHash}\n[assets] ${this.assetLoader.buildVersion}\n[server] ${this.serverBroker.serverInfo ?? 'no data'} (v${this.serverBroker.getServerVersion() ?? 'X'})`
            const topLeft = new Text(watermark, {
                fontFamily: 'monospace',
                fill: 0xffffff,
                fontSize: 12,
                dropShadow: true,
                dropShadowDistance: 2,
                dropShadowColor: 0x000000
            })
            const bottomRight = new Text(`VMKL Test Build\n${browser} on ${osName}\n${new Date().toISOString()}`, {
                fontFamily: 'monospace',
                fill: 0xffffff,
                fontSize: 12,
                dropShadow: true,
                dropShadowDistance: 2,
                dropShadowColor: 0x000000,
                align: 'right'
            })
            topLeft.alpha = 0.6
            topLeft.eventMode = 'none'
            topLeft.position.set(this.size.safeArea.minX, this.size.safeArea.minY)
            bottomRight.alpha = 0.6
            bottomRight.eventMode = 'none'
            bottomRight.anchor.set(1, 1)
            bottomRight.position.set(this.size.width, this.size.height - 28)
            bottomRight.name = 'debug-watermark1'
            topLeft.name = 'debug-watermark2'
            this.stage.addChild(topLeft)
            this.stage.addChild(bottomRight)
        }
    }

    async loadCoreAssets(): Promise<void> {
        const provides = await this.assetLoader.loadCasts(
            ['core/framework', 'core/fonts', 'interface/interface', 'interface/window_rec'],
            { retains: true }
        )

        if (!provides) {
            throw new Error('Could not load essential assets.')
        }
        GuideView.helps = provides.files.helps

        const externals = await this.api.loadExternals(['loadscreens'])

        await LoadingScreensManager.instance.load(externals.loadscreens)
    }

    resizeFunc = (ev?: UIEvent, width?: number, height?: number, forceResolution?: number): void => {
        const winWidth = width ?? (this.responsiveMode ? window.visualViewport.width : window.innerWidth)
        const winHeight = height ?? (this.responsiveMode ? window.visualViewport.height : window.innerHeight)

        const rootStyle = getComputedStyle(document.documentElement)
        const safeArea: any = {
            minX: +rootStyle.getPropertyValue('--sal').replace('px', ''),
            minY: +rootStyle.getPropertyValue('--sat').replace('px', ''),
            maxX: winWidth - +rootStyle.getPropertyValue('--sar').replace('px', ''),
            maxY: winHeight - +rootStyle.getPropertyValue('--sab').replace('px', '')
        }

        safeArea.width = safeArea.maxX - safeArea.minX
        safeArea.height = safeArea.maxY - safeArea.minY

        console.log('Size is ' + safeArea.width + ', ' + safeArea.height)

        if (
            !this.responsiveMode &&
            this.size &&
            safeArea.width === this.size.width &&
            safeArea.height === this.size.height
        ) {
            return
        }

        let guessRes = window.devicePixelRatio || 1
        if (forceResolution !== undefined) {
            guessRes = forceResolution

            console.log('Forcing res -> ' + forceResolution)
        } else {
            guessRes = 2 * Math.round(guessRes / 2)
            guessRes = Math.max(1, guessRes)

            console.log('Guessed res -> ' + guessRes)
        }
        ;(this.renderer as Renderer).resolution = guessRes

        this.renderer.resize(safeArea.width, safeArea.height)
        this.render()

        if (!this.responsiveMode) {
            const newScale =
                Math.floor(
                    100 *
                        (Number.EPSILON +
                            Math.min(safeArea.width / Constants.SIZE[0], safeArea.height / Constants.SIZE[1]))
                ) / 100

            this.stage.scale.set(newScale)
            const xOffset = (safeArea.width - newScale * Constants.SIZE[0]) / 2
            this.stage.position.set(xOffset, 0)
            this.overlayEl.style.left = xOffset + 'px'

            this.overlayEl.style.transform = 'scale(' + newScale + ',' + newScale + ')'
            this.overlayEl.style.setProperty(
                '-webkit-font-smoothing',
                newScale % 1 !== 0 ? 'subpixel-antialiased' : 'none'
            )

            this.size = new SizeInfo(
                {
                    width: Constants.SIZE[0],
                    height: Constants.SIZE[1]
                },
                {
                    minX: 0,
                    minY: 0,
                    maxX: Constants.SIZE[0],
                    maxY: Constants.SIZE[1],
                    width: Constants.SIZE[0],
                    height: Constants.SIZE[1]
                }
            )
        } else {
            safeArea.width = safeArea.maxX - safeArea.minX
            safeArea.height = safeArea.maxY - safeArea.minY

            this.size = new SizeInfo(
                {
                    width: winWidth,
                    height: winHeight
                },
                safeArea
            )
        }

        this.updateWatermark()

        const mask = this.viewport.mask as Graphics
        if (mask) {
            mask.clear()
            mask.beginFill(0x000000)
            mask.drawRect(0, 0, this.size.width, this.size.height)
            mask.endFill()
        }

        this.overlayEl.style.width = this.size.width + 'px'
        this.overlayEl.style.height = this.size.height + 'px'

        this.refit()
    }

    viewportScroll(): void {
        if (this.responsiveMode) {
            this.domRoot.body.style.top = window.visualViewport.offsetTop + 'px'
            this.domRoot.body.style.left = window.visualViewport.offsetLeft + 'px'
        }
    }

    refit(): void {
        for (const child of this.viewport.children) {
            ;(child as ISizeChanging).refit?.(this.size)
        }
    }

    wheelFunc = (_: WheelEvent): void => {
        if (this.roomViewer instanceof WalkableRoomViewer) {
            const furni = (this.roomViewer as WalkableRoomViewer).furniController.activeFurni
            if (furni) {
                furni.rotate()

                return
            }
        }
    }

    setupEventListeners(): void {
        if (process.env.NODE_ENV === 'production') {
            this.domRoot.body.oncontextmenu = (e) => {
                e.preventDefault()
                return false
            }
        }
        this.domRoot.body.setAttribute('unselectable', 'on')

        this.domRoot.addEventListener(
            'visibilitychange',
            () => {
                if (this.domRoot.visibilityState === 'hidden') {
                    this.roomViewer?.didBecomeHidden()
                }
            },
            false
        )
        window.addEventListener('unload', () => {
            this.roomDebugWin?.close()
        })
        window.addEventListener('vmkl-logout', this.interceptLogout)
        if (this.responsiveMode) {
            window.visualViewport.addEventListener('resize', () => this.resizeFunc())
            window.visualViewport.addEventListener('scroll', () => this.viewportScroll())
        } else {
            window.addEventListener('resize', () => this.resizeFunc())
            screen.orientation.addEventListener('change', () => this.resizeFunc())
        }
        window.addEventListener('mousewheel', this.wheelFunc, {
            passive: false
        })
        window.addEventListener(
            'selectstart',
            (e: Event) => {
                if (!['input', 'textarea'].includes((e.target as HTMLElement)?.localName)) {
                    e.preventDefault()

                    return false
                }

                return true
            },
            { passive: false }
        )
        const prevDefault = (e) => e.preventDefault()
        window.addEventListener('gesturestart', prevDefault, { passive: false })
        window.addEventListener('gesturechange', prevDefault, {
            passive: false
        })
        window.addEventListener('gestureend', prevDefault, { passive: false })
        window.addEventListener(
            'keyup',
            (e: KeyboardEvent) => {
                this.keysDown.set(e.keyCode, false)

                if (!e.ctrlKey && this.roomViewer instanceof WalkableRoomViewer) {
                    this.roomViewer.furniController.setPhantomMode(false)
                }
            },
            { passive: true }
        )
        window.addEventListener(
            'keydown',
            async (e: KeyboardEvent) => {
                this.keysDown.set(e.keyCode, true)
                const ctrlCmd = window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey

                if (ctrlCmd) {
                    const key = e.key.toLowerCase()
                    if (key === 'r') {
                        // allow reload command
                        return
                    }
                }

                if (this.domRoot.activeElement.tagName === 'BODY') {
                    const inv = (await this.userInterface?.getWin(EWindow.Inventory)) as InventoryWindow
                    if (inv?.visible) {
                        const invView = inv?.getCurrentView() as UIWindowView & {
                            tryFocusSearch(e: Event): void
                        }
                        if (invView?.tryFocusSearch) {
                            invView.tryFocusSearch(e)
                            return
                        }
                    }
                    if (this.userInterface?.toolbar?.chatbarInput.visible) {
                        e.preventDefault()
                        this.userInterface?.toolbar?.chatbarInput.forceFocus(e)
                    }
                }

                if (ctrlCmd) {
                    const key = e.key.toLowerCase()
                    if (key === 's') {
                        await this.screenshotHotkey(e, true, false)

                        return
                    }

                    if (key === 'i') {
                        if (e.cancelable) {
                            e.preventDefault()
                            e.stopPropagation()
                            e.returnValue = false
                        }

                        if (this.roomViewer instanceof WalkableRoomViewer) {
                            this.roomViewer.showInspector(true)
                        }

                        return
                    }

                    if (key === 'f') {
                        if (e.cancelable) {
                            e.preventDefault()
                            e.stopPropagation()
                            e.returnValue = false
                        }
                        this.toggleFullScreen()

                        return
                    }
                    if (key === 'p') {
                        if (e.cancelable) {
                            e.preventDefault()
                            e.stopPropagation()
                            e.returnValue = false
                        }
                        this.togglePIP()

                        return
                    }

                    if (this.roomViewer instanceof WalkableRoomViewer) {
                        this.roomViewer.furniController.setPhantomMode(true)
                    }
                }
            },
            { passive: false }
        )

        window.addEventListener('message', (event) => {
            if (event.data.type === 'debug-room') {
                console.log('room debugger is ready')
                if (!this.roomDebugWin) {
                    return
                }

                this.roomDebugWin.focus()
                this.#debugReady(true)

                return
            }
            if (event.data.type !== 'discord_code' || !event.data.params) {
                return
            }
            const params = new URLSearchParams(event.data.params)

            this.serverBroker.send('discord_code', {
                addingBot: location.hash.includes('bot'),
                state: params.get('state'),
                code: params.get('code')
            })
        })
    }

    authenticate(useToken?: string): void {
        this.loadingView.setVisible(true)
        this.loadingView.setStatusText('roomqueue.preloader.hd')

        void this.serverBroker.authConnect(useToken)
    }

    async loadEssentialGraphics(retry = true): Promise<void> {
        this.loadingView.setStatusText('Loading VMK Legacy' + (!retry ? ' (Retrying)' : ''))
        this.loadingView.setWaitText('Loading essential assets...')

        const baseCasts = [
            'avatar/bodyparts',
            'avatar/magic_fx',
            'magic/magic_smallworld',
            'room/room_basics',
            'navigator/navigator',
            'sound/sound'
        ]
        const provided = await this.assetLoader.loadCasts(baseCasts, {
            retains: true
        })

        console.log('Beginning external api load')
        this.loadingView.setWaitText('Loading content data...')

        try {
            const externals = await this.api.loadExternals([
                'furnidata',
                'furnianims',
                'texts',
                'clothing',
                'rooms',
                'billboards'
            ])

            if (Object.hasOwn(externals, 'texts')) {
                provided.vars = {
                    ...provided.vars,
                    ...externals['texts']._vars
                }
                provided.texts = {
                    ...provided.texts,
                    ...externals['texts']._texts
                }
            } else {
                console.warn('External data missing `texts`')
            }

            for (const k in provided.vars) {
                ExternalConfigManager.instance.setVariable(k, provided.vars[k])
            }

            for (const k in provided.texts) {
                ExternalConfigManager.instance.setText(k, provided.texts[k])
            }

            // temporary external load magic here
            // TODO: Magic manager
            const magicMap: Record<string, EMagicEffectTypes> = {
                smallworld: EMagicEffectTypes.SmallWorldBoat
            }
            for (const file in provided.files) {
                if (file.startsWith('magic.')) {
                    const magicName = file.replace('magic.', '')
                    const effectType = magicMap[magicName]
                    if (effectType === undefined) {
                        continue
                    }
                    MagicDefinitions.defs[effectType] = provided.files[file]
                }
            }

            if ('rooms' in externals) {
                this.roomInfoMgr.fill(externals['rooms'])
            } else {
                console.warn('External data missing `rooms`')
            }
            if ('billboards' in externals) {
                this.billboardMgr.fill(externals['billboards'])
            } else {
                console.warn('External data missing `billboards`')
            }
            if ('clothing' in externals) {
                this.figuresMgr.fill(externals['clothing'])
            } else {
                console.warn('External data missing `clothing`')
            }
            if ('furnidata' in externals && 'furnianims' in externals) {
                this.furniMapMgr.parse(externals['furnidata'], externals['furnianims'])
            } else {
                console.warn('External data missing `furnidata`/`furnianims`')
            }
        } catch (error) {
            if (retry) {
                await this.loadEssentialGraphics(false)

                return
            }
            Sentry.captureException(error)
            alert(
                'Something went wrong while loading game configuration files. Please refresh and try again. (Error code: 1205)'
            )
            console.error(error)

            return
        }

        this.loadingView.setWaitText('Loading fonts...')

        await Promise.all([
            new FontFaceObserver('web-foxley').load(),
            new FontFaceObserver('web-foxley', { weight: 'bold' }).load(),
            new FontFaceObserver('web-folio').load()
        ])

        this.loadingView.setWaitText('Loading essential sounds...')

        try {
            await UISoundLibrary.loadSounds(provided)
        } catch (e) {
            console.error(e)

            await this.helpers.alert(
                'The interface sounds could not be loaded. Please refresh and try again or report this issue. (Error code: 1300)'
            )
            location.reload()
            return
        }
        this.essentialAssets = provided
        this.loadingView.setWaitText('Initializing interface...')

        this.userInterface.init()

        this.viewport.removeChild(this.userInterface)
        this.viewport.addChild(this.userInterface)

        this.slowTicker.add(this.#tickScores, this as any)

        this.loadingView.setWaitText('...')
    }

    async showRegistration(rulesOnly = false): Promise<void> {
        this.loadingView.setVisible(true)
        this.loadingView.setStatusText('Please wait')
        this.loadingView.setWaitText(rulesOnly ? 'Loading updated rules...' : 'Loading character creation...')

        this.regView = new RegistrationView(rulesOnly)
        await this.regView.loadAssets()
        console.log('reg assets loaded')

        this.regView.setVisible(true)
        this.userInterface.register(this.regView)
        this.loadingView.setVisible(false)
    }

    async revealGame(restore?: {
        queue?: { name: string; place: number }
        wait?: any
        game?: any
    }): Promise<void> {
        if (restore?.queue) {
            this.userInterface.bringToFront(new VMKPassWindow(restore?.queue.name, restore?.queue.place))
        }
        if (this.startArgs?.has('room')) {
            this.serverBroker.requestRoom(this.startArgs?.get('room') as string)
        } else if (restore?.wait) {
            this.loadingView.setVisible(true)
            this.loadingView.setWaitText('Restoring session...')
        } else if (restore?.game) {
            await new GameJoinModule().handle(restore?.game)
        } else {
            this.userInterface.showWindow(EWindow.Navigator).then(() => {
                this.userInterface.toolbar?.enableMapButtons()
                SoundManager.shared.play(ESndGrp.Music, UISoundLibrary.MapLoop, true)
                this.loadingView.setVisible(false)
                MessagesModule.glowOrShow()
            })
        }
    }

    #tickScores = (): void => {
        for (const score of this.activeScores) {
            score.tick()
        }
    }

    interceptLogout = async (): Promise<void> => {
        if (
            !(await this.helpers.confirm({
                message: 'Are you sure you want to leave the Kingdom and logout?',
                okLabel: 'STAY',
                cancelLabel: 'EXIT'
            }))
        ) {
            window.close()
            location.href = 'https://vmklegacy.com'
        }
    }

    debugTextures(textures: string[]): void {
        const contain = new Container()
        contain.y = 25
        this.stage.addChild(contain)
        let y = 5
        for (const texture of textures) {
            try {
                const spr = Pooler.newSprite(texture)
                spr.y = y
                spr.x = 5
                y += spr.height + 2
                contain.addChild(spr)
            } catch (e) {
                console.error(e)
            }
        }
        const bg = Pooler.newSprite(Texture.WHITE)
        bg.tint = 0xe01212
        bg.width = contain.width + 5
        bg.height = contain.height + 5
        contain.addChildAt(bg, 0)
    }

    async #setupDebugger(): Promise<void> {
        if (process.env.NODE_ENV === 'development') {
            console.log('Setting up debugger')

            if (window.electronAPI) {
                // Electron API available, running in Dev Pal
                setInterval(() => {
                    window.electronAPI.reportMemory(
                        JSON.stringify({
                            usedJSHeapSize: performance.memory.usedJSHeapSize,
                            totalJSHeapSize: performance.memory.totalJSHeapSize,
                            ign: this.selfRecord.getIgn()
                        })
                    )
                }, 2000)
            }

            if (window.location.hash.includes('roomdebug')) {
                console.log('Launching room debugger')
                window['_roomDebug'] = true
                const promise = new Promise((resolve) => (this.#debugReady = resolve))

                this.roomDebugWin = window.open(
                    './inspect-room.html',
                    'vmkl-inspect-room',
                    'height=300,width=600,menubar=off,toolbar=off,location=off,resizable,scrollbars,status'
                )
                this.roomDebugWin?.blur()
                window.focus()

                return promise
            }
        }

        return Promise.resolve()
    }

    screenshotHotkey = async (event?: KeyboardEvent, force = false, passive = false): Promise<void> => {
        if (
            force ||
            (event &&
                (window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) &&
                event.key.toLowerCase() === 's')
        ) {
            console.log(event)
            if (event && event?.cancelable && !passive) {
                event.preventDefault()
                event.stopPropagation()
                event.returnValue = false
            }

            if (['input', 'textarea'].includes(this.domRoot.activeElement?.localName)) {
                ;(this.domRoot.activeElement as HTMLInputElement).blur()
            }

            this.resizeFunc(undefined, Constants.SIZE[0], Constants.SIZE[1], 2)
            const renderTexture = RenderTexture.create({
                width: Constants.SIZE[0],
                height: Constants.SIZE[1],
                resolution: 2
            })

            this.renderer.render(this.stage, {
                renderTexture,
                transform: this.stage.localTransform.invert()
            })

            const canvas = this.renderer.extract.canvas(renderTexture) as HTMLCanvasElement
            renderTexture.destroy(true)

            this.resizeFunc()

            const shift = !force && event?.shiftKey

            SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.Camera)

            canvas.toBlob(
                (blob) => {
                    if (shift) {
                        try {
                            window.navigator.clipboard.write([
                                new ClipboardItem({ [blob.type]: blob }, { presentationStyle: 'attachment' })
                            ])

                            return
                        } catch (error) {
                            this.helpers.alert('Your browser does not support saving to clipboard, sorry!')
                        }
                    }
                    const date = new Date()
                    const filename = 'VMKL ' + date.toLocaleDateString() + ' at ' + date.toLocaleTimeString()
                    const fileStream = streamsaver.createWriteStream(filename + '.png', {
                        size: blob.size
                    })

                    const readableStream = blob.stream()

                    // more optimized pipe version
                    // (Safari may have pipeTo but it's useless without the WritableStream)
                    if ('WritableStream' in window && readableStream.pipeTo) {
                        return readableStream.pipeTo(fileStream).then(() => {
                            console.log('done writing')
                            canvas.remove()
                        })
                    }

                    // Write (pipe) manually
                    const writer = (window['writer'] = fileStream.getWriter())

                    const reader = readableStream.getReader()
                    const pump = () =>
                        reader.read().then((res) => (res.done ? writer.close() : writer.write(res.value).then(pump)))

                    pump()
                    canvas.remove()
                },
                'image/png',
                1
            )
        }
    }
}

export const globalOpts = {
    onlyAllowEventsOn: undefined as FederatedEventTarget | undefined
}
