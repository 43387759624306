import { ERoomWhoEnters } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { DisplayObject } from 'pixi.js'
import { BitmapText, Container } from 'pixi.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { RadioButton } from '../buttons/RadioButton.js'
import { BasicBox } from '../containers/BasicBox.js'
import { FieldH } from '../fields/FieldH.js'
import { RadioController } from '../RadioController.js'
import type { IWindow } from './IWindow.js'

export class RoomEntranceSettings extends Container implements IWindow {
    readonly kind = EWindow.Other
    layer = UILayer.Legacy
    private enterSetting: RadioController

    header: DisplayObject
    isDraggable = true
    exitBtn: ImageButton

    getWindowWidth(): number {
        return 300
    }

    getWindowHeight(): number {
        return 220
    }

    setVisible(visible: boolean): void {
        this.container.visible = visible
        this.containerContainer.visible = visible
        this.visible = visible
    }

    reset(): void {
        //
    }

    container: Container
    private title: string
    private body: string // \n for newline

    private totalWidth: number
    private totalHeight: number

    private containerContainer: Container

    constructor() {
        super()
        this.title = getText('whoenters.title')
        this.body = getText('roominfog.allow.hd')

        this.containerContainer = Pooler.newContainer()
        this.container = Pooler.newContainer()

        if (!this.body) {
            return
        }

        const bg: BasicBox = new BasicBox(300, 160)
        this.container.addChild(bg)

        const titleBg: FieldH = new FieldH(259)

        this.container.addChild(titleBg)
        titleBg.x = 26
        titleBg.y = 13

        this.eventMode = 'static'

        const titleText = new BitmapText(this.title || '', {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        titleText.y = 14
        const titleTextWidth: number = titleText.textWidth
        titleText.x = Math.round((bg.width - titleTextWidth) / 2)
        this.container.addChild(titleText)

        const qButton: ImageButton = new ImageButton('button.helpb.active', 'button.helpb.pressed')
        qButton.x = 8
        qButton.y = 13
        this.container.addChild(qButton)

        const xButton: ImageButton = new ImageButton('button.closeb.active', 'button.closeb.pressed')
        xButton.x = 297
        xButton.y = 13
        this.container.addChild(xButton)
        this.exitBtn = xButton

        const bodyText = new BitmapText(this.body || '', {
            ...Fonts.FoxleyBold_16
        })
        bodyText.maxWidth = 285
        this.body = this.body.split('\\n').join('\n')
        bodyText.y = 38
        bodyText.x = 16

        this.container.addChild(bodyText)

        const radioOwner = new RadioButton(getText('owner.checkbox.text'), 0, 6, 'a', Fonts.Folio_12)
        const radioFriends = new RadioButton(getText('friendsonly.checkbox.text'), 1, 6, 'a', Fonts.Folio_12)
        const radioTickets = new RadioButton(getText('ticketpeople.checkbox.text'), 2, 6, 'a', Fonts.Folio_12)
        const radioAlmostEv = new RadioButton(getText('almosteveryone.checkbox.text'), 3, 6, 'a', Fonts.Folio_12)
        const radioEveryone = new RadioButton(getText('everyone.checkbox.text'), 4, 6, 'a', Fonts.Folio_12)

        this.enterSetting = new RadioController([radioOwner, radioFriends, radioTickets, radioAlmostEv, radioEveryone])
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            this.enterSetting.setValue(Client.shared.roomViewer.whoEnters ?? ERoomWhoEnters.Everyone)
        }
        radioOwner.y = 54
        radioFriends.y = 72
        radioTickets.y = 90
        radioAlmostEv.y = 108
        radioEveryone.y = 126

        radioOwner.x = radioFriends.x = radioTickets.x = radioAlmostEv.x = radioEveryone.x = 15
        this.container.addChild(radioOwner, radioFriends, radioTickets, radioAlmostEv, radioEveryone)

        const actionButton = new BitmapTextButton(75, getText('btn.ok'), 'd')
        actionButton.x = 146
        actionButton.y = bg.height - 32
        this.container.addChild(actionButton)

        actionButton.addEventListener('pointerup', () => {
            if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
                Client.shared.roomViewer.whoEnters = this.enterSetting.getValue() as ERoomWhoEnters
                Client.shared.serverBroker.send('room_who_enters_change', {
                    whoEnters: this.enterSetting.getValue()
                })
            }
            Client.shared.userInterface.removeWindow(this)
        })

        const cancelButton = new BitmapTextButton(75, getText('btn.cancel'), 'd')
        cancelButton.x = 16
        cancelButton.y = bg.height - 32
        this.container.addChild(cancelButton)

        cancelButton.addEventListener('pointerup', () => {
            Client.shared.userInterface.removeWindow(this)
        })

        this.header = titleBg
        this.header.position.set(27, 15)
        this.container.addChild(this.header)

        this.containerContainer.addChild(this.container)

        this.addChild(this.containerContainer)

        Client.shared.userInterface.register(this)

        this.center()
    }

    center(): void {
        this.containerContainer.x = Math.round((Constants.SIZE[0] - 320) / 2)
        this.containerContainer.y = Math.round((Constants.SIZE[1] - 240) / 2)
    }

    tryRemove(): void {
        if (this.parent !== null) {
            this.parent.removeChild(this)
        }
    }

    getWidth(): number {
        return this.totalWidth
    }

    getHeight(): number {
        return this.totalHeight
    }
}
