import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import { EWindow } from '../../../enums.js'
import type { IPaginator } from '../../../server/messages/MessagesModule.js'
import type { IMessageData } from '../../../server/messages/ReceivedMessage.js'
import type { ImageButton } from '../../buttons/ImageButton.js'
import { UISoundLibrary } from '../../UISoundLibrary.js'
import LegacyWindow from '../LegacyWindow.js'
import { UIWindowTab } from '../UIWindowTab.js'
import { FriendsView } from './views/FriendsView.js'
import { MessengerView } from './views/MessengerView.js'

export class MessengerWindow extends LegacyWindow {
    readonly kind = EWindow.Messenger

    isDraggable: boolean
    exitBtn: ImageButton
    friendsView: FriendsView
    friendsTab: UIWindowTab
    messengerView: MessengerView
    messengerTab: UIWindowTab

    static messageData: IPaginator<IMessageData> = {
        page: 1,
        per: 15,
        total: 0,
        data: []
    }
    static friendData: any

    windowTitle = 'MessengerWindow'

    constructor() {
        super('vmk_mes')

        this.position.set(430, 23)

        this.isDraggable = true
    }

    override async windowWasBuilt(): Promise<void> {
        this.messengerView = new MessengerView(this)
        await this.messengerView.waitToBeBuilt()
        this.messengerTab = new UIWindowTab('MESSAGES', this.messengerView)
        this.addChild(this.messengerTab)

        this.friendsView = new FriendsView()
        this.friendsTab = new UIWindowTab('FRIENDS', this.friendsView)
        this.addChild(this.friendsTab)

        this.tabs.push(this.messengerTab, this.friendsTab)
        this.views.push(this.messengerView, this.friendsView)

        let currentX = 0
        this.tabs.forEach((t) => {
            t.eventMode = 'static'
            t.interactiveChildren = true
            t.cursor = 'pointer'

            t.y = 44 // static
            t.x = currentX + 28
            currentX += t.getWidth()

            t.setActive(false)

            t.addEventListener('pointerup', (e: FederatedEvent) => {
                SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.PopupTabChange)
                this.setActiveTab(t)
            })
        })

        await this.setActiveTab(this.messengerTab)

        this.hide('mes.tab.*')

        if (MessengerWindow.friendData) {
            this.updateFriends()
        }
        if (MessengerWindow.messageData) {
            this.updateMessages()
        }
    }

    updateMessages() {
        this.messengerView.setMessages()
    }

    updateFriends(data?: any) {
        data = data || MessengerWindow.friendData
        if (!data) {
            return
        }

        this.friendsView.populate(data.friends, data.requests)
    }
}
