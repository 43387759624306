import { EWindow } from '../../enums.js'
import { ImageButton } from '../buttons/ImageButton.js'
import { SettingsView } from './SettingsView.js'
import { UIWindow } from './UIWindow.js'
import { UIWindowType } from './UIWindowType.js'

export class SettingsWindow extends UIWindow {
    readonly kind = EWindow.Settings

    isDraggable: boolean
    exitBtn: ImageButton

    constructor() {
        super(420, UIWindowType.SETTINGS)
        this.position.set(20)

        this.isDraggable = true
    }

    override async windowWasBuilt(): Promise<void> {
        console.log('settings built')
        this.exitBtn = new ImageButton('button.close.active', 'button.close.pressed')
        this.addChild(this.exitBtn)
        this.exitBtn.x = this.header.x + this.header.width - 18
        this.exitBtn.y = this.header.y + 2

        const settingsView = new SettingsView()
        settingsView.x = 33
        settingsView.y = 85
        this.addChild(settingsView)
        this.views.push(settingsView)
        settingsView.visible = true
    }
}
