import type { ScoreRunner } from '@vmk-legacy/render-utils'
import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { getText } from '../../../assets/ExternalConfigManager.js'
import { Client } from '../../../Client.js'
import { Helpers } from '../../../util/Helpers.js'
import TextScroller from '../../TextScroller.js'
import { MixController } from './MixController.js'
import { MonorailState } from './MonorailStationHandler.js'

export enum MonorailStation {
    Adventureland = 21,
    Fantasyland = 23,
    Frontierland = 22,
    MainStreet = 20,
    NewOrleans = 92,
    Tomorrowland = 24
}

export class MonorailTrainHandler extends MixController {
    static override identifier = 'monoTrain'

    readonly pickSongSprites = ['dj_table_a', 'dj_table_b', 'dj_table_c']
    readonly mixer = 'mono'

    protected state: MonorailState
    protected nextStop: MonorailStation
    protected trainNum: number

    private innerMusic: AudioBufferSourceNode

    railsScore: ScoreRunner
    lcdScore: ScoreRunner
    private scroller: TextScroller

    override async init(): Promise<this> {
        await super.init()

        this.loadArrangements([
            'monorail_train_doors_open',
            'monorail_train_lcd',
            'monorail_train_doors_close',
            'monorail_train_rails',
            'monorail_train_doors_closed'
        ])

        Client.shared.serverBroker.onEvent(
            'mono_update',
            (data: {
                state: MonorailState
                nextStop: MonorailStation
            }) => {
                console.log('New monorail state from server: ' + data.state)

                this.nextStop = data.nextStop

                if (data.state === MonorailState.LeavingStation) {
                    this.closeDoors().then(() => this.monorailLeaveStation())
                } else if (data.state === MonorailState.EnteringStation) {
                    this.monorailEnterStation().then(() => this.openDoors())
                } else if (data.state === MonorailState.AboutToLeave) {
                    SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MRVO-allaboard'))
                } else if (data.state === MonorailState.StoppedInStation) {
                    if (this.state !== MonorailState.EnteringStation) {
                        this.openDoors()
                    }
                }

                this.state = data.state
            }
        )

        this.room.sprites.sortChildren()

        const display = this.room.sprites.getChildByName('display') as Sprite
        display.visible = false
        const displaySong = this.room.sprites.getChildByName('display_song') as Sprite
        displaySong.visible = false

        this.scroller = new TextScroller(19, 1, 1)
        this.scroller.zIndex = display.zIndex
        this.room.sprites.addChild(this.scroller)
        this.scroller.position.set(display.x - 10, display.y - 10)
        Client.shared.slowTicker.add(this.scroller.tick, this.scroller)

        return this
    }

    override async roomWillReveal(): Promise<void> {
        await super.roomWillReveal()

        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-monorail-VO-mono'))
    }

    override receiveInitialData(data?: any | { state: MonorailState; train: number; nextStop: MonorailStation }): void {
        super.receiveInitialData(data)

        this.trainNum = data.train
        this.nextStop = data.nextStop

        if (data.state === MonorailState.StoppedInStation || data.state === MonorailState.AboutToLeave) {
            this.playArrangement('monorail_train_rails')?.goToFrame(-1)
            this.playArrangement('monorail_train_lcd')?.goToFrame(-1)
            this.playArrangement('monorail_train_doors_open')?.goToFrame(-1)
        } else if (data.state === MonorailState.LeavingStation) {
            this.monorailLeaveStation()
        } else if (data.state === MonorailState.EnteringStation) {
            this.monorailEnterStation().then(() => this.openDoors())
        }
    }

    setupMixScroller(): TextScroller {
        const displaySong = this.room.sprites.getChildByName('display_song') as Sprite
        displaySong.visible = false

        const scroller = new TextScroller(10, 1, 2)
        scroller.zIndex = displaySong.zIndex
        scroller.position.set(displaySong.x - 3, displaySong.y - 35)

        scroller.setText(getText('monorail.musicmix.game.init.scroller'))

        return scroller
    }

    override mixDidStart(): void {
        super.mixDidStart()
        this.innerMusic?.stop(0)
    }

    override mixDidEnd(): void {
        super.mixDidEnd()
        this.mixScroller.setText(getText('monorail.musicmix.game.init.scroller'))
        if (!this.innerMusic && this.state === MonorailState.Travelling) {
            this.innerMusic = SoundManager.shared.play(
                ESndGrp.Music,
                this.room.loadedSounds.get('MR-music-' + this.trainNum),
                true
            )
        }
    }

    async monorailEnterStation(): Promise<void> {
        let text
        if (this.nextStop === MonorailStation.MainStreet) {
            text = '   MAIN STREET   '
        } else if (this.nextStop === MonorailStation.Adventureland) {
            text = '  ADVENTURELAND  '
        } else if (this.nextStop === MonorailStation.Frontierland) {
            text = '  FRONTIERLAND   '
        } else if (this.nextStop === MonorailStation.Fantasyland) {
            text = '  FANTASYLAND    '
        } else if (this.nextStop === MonorailStation.NewOrleans) {
            text = 'NEW ORLEANS SQUARE'
        } else if (this.nextStop === MonorailStation.Tomorrowland) {
            text = '  TOMORROWLAND    '
        }
        this.scroller.setText('NOW APPROACHING: ' + text + '$')

        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MRVO-move-towards'))
        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-train-arrive'))

        SoundManager.shared.release(this.innerMusic)
        this.innerMusic = undefined

        this.railsScore?.reset()
        this.lcdScore?.reset()
    }

    async monorailLeaveStation(): Promise<void> {
        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-train-leave'))

        if (!this.player?.score?.playing) {
            SoundManager.shared.release(this.innerMusic)
            this.innerMusic = SoundManager.shared.play(
                ESndGrp.Music,
                this.room.loadedSounds.get('MR-music-' + this.trainNum),
                true
            )
        }

        Helpers.delay(7000).then(() => {
            let stationAudio, text
            if (this.nextStop === MonorailStation.MainStreet) {
                stationAudio = 'mainstreet'
                text = 'MAIN STREET'
            } else if (this.nextStop === MonorailStation.Adventureland) {
                stationAudio = 'adventureland'
                text = 'ADVENTURELAND'
            } else if (this.nextStop === MonorailStation.Frontierland) {
                stationAudio = 'frontierland'
                text = 'FRONTIERLAND'
            } else if (this.nextStop === MonorailStation.Fantasyland) {
                stationAudio = 'fantasyland'
                text = 'FANTASYLAND'
            } else if (this.nextStop === MonorailStation.NewOrleans) {
                stationAudio = 'nos'
                text = 'NEW ORLEANS SQUARE'
            } else if (this.nextStop === MonorailStation.Tomorrowland) {
                stationAudio = 'tomorroland' //this is the audio spelling
                text = 'TOMORROWLAND'
            }
            this.scroller.setText('NEXT STOP: ' + text)
            if (stationAudio) {
                SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MRVO-nextstop-' + stationAudio))
            }
        })

        const railsScore = this.playArrangement('monorail_train_rails', true)
        if (railsScore) {
            this.railsScore = railsScore
            this.railsScore.loops = true
        }
        const lcdScore = this.playArrangement('monorail_train_lcd', true)
        if (lcdScore) {
            this.lcdScore = lcdScore
            this.lcdScore.loops = true
        }
    }

    async openDoors(): Promise<void> {
        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-doors-open'))

        await this.playArrangement('monorail_train_doors_open')?.getFinishPromise()
    }

    async closeDoors(): Promise<void> {
        this.scroller.setText('STAND CLEAR OF THE DOORS')

        SoundManager.shared.play(ESndGrp.SFX, this.room.loadedSounds.get('MR-doors-close'))

        await this.playArrangement('monorail_train_doors_close')?.getFinishPromise()
        await this.playArrangement('monorail_train_doors_closed')?.getFinishPromise()
    }

    override teardown(): void {
        super.teardown()

        SoundManager.shared.release(this.innerMusic)
        this.innerMusic?.stop()

        Client.shared.slowTicker.remove(this.scroller.tick, this.scroller)

        Client.shared.serverBroker.offEvent('mono_update')
    }
}
