import type { PotcShipType } from '@vmk-legacy/potc-engine'
import { EPotcTeam, PotcMode, PotcStageEngine } from '@vmk-legacy/potc-engine'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Sprite } from 'pixi.js'
import { BitmapText, Texture } from 'pixi.js'
import { Fonts } from '../../Fonts.js'
import type { PotcFactorShip } from './PotcFactorShip.js'
import type { PotcScene } from './PotcScene.js'
import { PotcVisualBase } from './PotcVisualBase.js'

export class PotcVisualShip extends PotcVisualBase {
    factor: PotcFactorShip

    protected ship: Sprite
    protected sail: Sprite
    protected shootIndicator: Sprite

    nameLabel: BitmapText
    shootMark: null | 'left' | 'right'
    private selfIndicator: Sprite
    sineOffset2 = 0

    constructor(
        readonly scene: PotcScene,
        readonly type: PotcShipType,
        readonly team: EPotcTeam,
        private name: string,
        self = false
    ) {
        super()

        console.log('created visual ship with type ' + this.type)

        this.anchor.set(0.5, 0.5)

        if (self) {
            this.selfIndicator = this.addChild(Pooler.newSprite('arrow.red'))
            this.selfIndicator.position.y = -55
        }

        this.shootIndicator = this.addChild(Pooler.newSprite())
        this.ship = this.addChild(Pooler.newSprite())
        this.sail = this.addChild(Pooler.newSprite())

        this.nameLabel = this.addChild(new BitmapText(name, { tint: 0xffffff, ...Fonts.Foxley_16 }))
        this.nameLabel.anchor.set(0.5, 0)
        this.nameLabel.position.y = -60

        this.updateLabel()
    }

    applyServerActions(actions: any) {}

    setDirection(dir) {}

    setTileSize(size: number): void {}

    updateLabel() {
        this.nameLabel.text = this.name + (this.scene.engine.gameMode === PotcMode.ShipBattle ? '\n' + this.gold : '')
    }

    markShoot(side: null | 'left' | 'right') {
        this.shootMark = side
        this.updateShootIndicator()
    }

    updateShootIndicator() {
        // switch (this.shootMark) {
        // 	case 'left': {
        // 		this.shootIndicator.visible = true;
        // 		let shootDir = Math.round(PotcStageEngine.adjustDirection(this.direction, -90) / 10) * 10;
        //
        // 		if (shootDir === 360) {
        // 			shootDir = 0;
        // 		}
        //
        // 		this.shootIndicator.texture = Texture.from('shoot0' + ('00' + shootDir).slice(-3));
        // 		this.shootIndicator.anchor.copyFrom(this.shootIndicator.texture.defaultAnchor);
        // 		break;
        // 	}
        // 	case 'right': {
        // 		this.shootIndicator.visible = true;
        // 		let shootDir = Math.round(PotcStageEngine.adjustDirection(this.direction, 90) / 10) * 10;
        //
        // 		if (shootDir === 360) {
        // 			shootDir = 0;
        // 		}
        // 		this.shootIndicator.texture = Texture.from('shoot0' + ('00' + shootDir).slice(-3));
        // 		this.shootIndicator.anchor.copyFrom(this.shootIndicator.texture.defaultAnchor);
        // 		break;
        // 	}
        // 	default: {
        // 		this.shootIndicator.visible = false;
        // 	}
        // }
    }

    setGraphic(dir: number) {
        this.ship.texture = Texture.from(`ship_${this.type}_${('00' + dir).slice(-3)}`)
        this.ship.anchor.copyFrom(this.ship.texture.defaultAnchor)

        this.sail.texture = Texture.from(`sail_${this.type}_${('00' + dir).slice(-3)}`)
        this.sail.anchor.copyFrom(this.sail.texture.defaultAnchor)

        if (this.team === EPotcTeam.PortRoyal) {
            this.sail.tint = 0xffefbe
        } else {
            this.sail.tint = 0x564b70
        }

        this.updateShootIndicator()
    }

    update() {
        const pos = this.factor.worldPosition
        const dir = this.factor.direction

        this.setGraphic(dir)

        this.sineOffset2 = this.sineOffset2 + 0.03
        if (this.sineOffset2 > 2.0 * Math.PI) {
            this.sineOffset2 = 0.0
        }
        const waveOffset = Math.sin(pos.x / 2300 + this.sineOffset2) * 100

        const loc = PotcStageEngine.projectAxonPosition({
            x: this.factor.worldPosition.x + waveOffset,
            y: this.factor.worldPosition.y + waveOffset
        })

        this.position.set(loc.x - 400, loc.y - 300)
    }
}
