import type { EMagicEffectTypes, EPronounOpt, IClientOutfit, ItemOwnedTuple } from '@vmk-legacy/common-ts'
import { AvatarOutfit } from '@vmk-legacy/render-utils'
import { Client } from '../../Client.js'
import { AvatarEntity } from '../../room/entities/AvatarEntity.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export interface EntityDataPayload {
    id: number
    direction: number
    tile: number
    ign: string
    effect?: { effect: EMagicEffectTypes; param?: number }
    fxParam?: string
    actions: any

    outfit?: IClientOutfit
    lanyard?: ItemOwnedTuple[]
    badges?: any
    signature?: string
    pronouns?: EPronounOpt[]
    trading?: boolean
    invisible?: boolean

    entryAnim?: any
    moving?: number[]
}

export class EntityJoinModule extends ServerEvent {
    static readonly type = MessageType.ENTITY_JOIN

    override async handle(entity: EntityDataPayload): Promise<void> {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            let object
            if ('outfit' in entity) {
                object = Client.shared.roomViewer.getEntityByRef(entity.id)
                if (object instanceof AvatarEntity) {
                    object.updateFigure(entity)
                } else {
                    object = Client.shared.roomViewer.addAvatar(
                        entity.id,
                        AvatarOutfit.from(entity.outfit),
                        entity.tile,
                        entity.direction,
                        entity.actions,
                        entity.ign,
                        entity.lanyard,
                        entity.badges,
                        entity.signature,
                        entity.entryAnim,
                        entity.invisible,
                        false,
                        entity.effect?.effect,
                        entity.effect?.param,
                        entity.pronouns
                    )
                }
            } else {
                object = Client.shared.roomViewer.addNPC(
                    entity.id,
                    entity.tile,
                    entity.direction,
                    entity.actions,
                    entity.ign,
                    !!entity.invisible
                )
            }
            if (entity.moving) {
                object.startWalkingPath(entity.moving)
            }
        }
    }
}
