export class Constants {
    static SIZE: [width: number, height: number] = [800, 600]

    static readonly HIDDEN_MICKEY_ALPHA: number = 0.15

    static readonly HELP_HEADER: string = 'How can we help you?'
    static readonly HELP_REASON_HEADER: string = 'Please tell us why you need help from staff:'
    static readonly HELP_NAME_PROMPT: string = 'If your problem is with another player, please enter their player name:'
    static readonly HELP_BODY_PROMPT: string = 'What happened?'
    static readonly HELP_SUBMIT_WARNING: string =
        'Using this tool for non-emergencies could get you banned! Use the Contact Us page on our website for general inquiry/non-emergencies.'
    static readonly HELP_SUBMIT_BUTTON: string = 'Call For Help'
    static readonly HELP_GUIDE_ENTRY_NOT_FOUND: string =
        'Uh oh! Looks like that Guide entry could not be found. Please consult our website for more information!'

    static readonly UI_BOX_CORNER_RADIUS: number = 10
    static readonly UI_BOX_BORDER_WIDTH: number = 3

    static readonly UI_POPUP_BOX_BG_COLOR: number = 0x001f55
    static readonly UI_POPUP_BOX_BORDER_COLOR: number = 0xc0c0c0

    static readonly UI_OK_BTN_COLOR: number = 0x40cf07

    static readonly TOOLBAR_BG_COLOR: number = 0x001f55

    static readonly TEXT_LIST_ITEM_BG_COLOR: number = 0x275692

    static readonly UI_LOADER_BAR_COLOR: number = 0x003e72
    static readonly UI_LOADER_BAR_BG: number = 0x0066a1
}
