export enum MessageType {
    _UNSET = '__networker_msg_type_unset__',
    CONNECT = 'connect',
    AUTH = 'auth',
    GRACEFUL_DC = 'about-to-dc',
    DISCONNECT = 'disconnect',
    ENTER_ROOM = 'enter_room',
    AVATAR_MOVE = 'avatar_move',
    ALERT = 'alert',
    CHAT = 'chat',
    POPULATE = 'populate',
    AUTH_RESPONSE = 'auth_response',
    SELF_RECORD = 'self_record',
    SELF_RECORD_REQUEST = 'req_self_record',
    FRIENDS_LIST = 'friends_list',
    FRIEND_UPDATE = 'friend_update',
    INVENTORY = 'inventory',
    INV_UPDATE = 'inv_update',
    ROOM_JOIN = 'room_join',
    ROOM_QUEUE = 'room_queue',
    QUEUE_PLACE = 'queue_place',
    CATALOG = 'catalog',
    REFRESH = 'refresh',
    REFRESH_BILLBOARDS = 'refresh_billboards',
    ENTITY_MOVING = 'moving',
    ENTITY_MOVE = 'entity_move',
    ENTITY_JOIN = 'entity_join',
    ENTITY_LEAVE = 'entity_leave',
    ENTITY_CHANGE = 'entity_figure_change',
    REGISTRATION_COMPLETE = 'registration_complete',
    ENTITY_STOP = 'entity_stop',
    ENTITY_BODY_STATE = 'entity_body_state',
    CFH_RECEIVED = 'cfh_received',
    ROOM_LIST = 'room_list',
    TRADE_REQUEST = 'trade_request',
    TRADE_BEGIN = 'trade_begin',
    NPC_VISIT = 'npc_visit',
    EMOTE = 'emote',
    ROOM_PURCHASE_SUCCESS = 'room_purchase_success',
    BOOTED = 'booted',
    UPDATED_TEXT = 'updated_text',
    DISABLE_REFS = 'disable_refs',
    TRANSITION_ROOM_MODEL = 'trans_var',
    FURNI_PLACED = 'furni_placed',
    FURNI_REMOVED = 'furni_removed',
    RECORDS_RECEIVED = 'recs',
    ROOM_SCORE_UPDATE = 'room_score',
    RELOAD_DATA = 'reload_data',
    MESSAGES = 'messages',
    MSG_SINGLE = 'message',
    QUEUE_EXPIRED = 'queue_expired',
    ROOM_UPDATE = 'room_info_update',
    GAME_JOIN = 'game_join',
    TELEPORTED = 'teleported',
    START_SHOW = 'start_show',
    ShowLoad = 'show_load',
    ShowStep = 'show_step',
    ENTITY_MAGIC = 'magic',
    CONFIRM = 'confirm',
    BEGIN_LOAD_ROOM = 'load_room',
    ROOM_LOAD_FURNI = 'room_furni',
    Vibrate = 'vibrate',
    SaveFile = 'save_file',
    FurnimapUpdated = 'furnimap_update',
    RulesUpdated = 'rules_updated'
}
