import type { FederatedEvent } from 'pixi.js'
import type { Plane3D } from '../3DUtils.js'
import { FakeContainer, ManagedGraphics } from '../FakeContainer.js'
import type { WalkableRoomViewer } from '../WalkableRoomViewer.js'
import type { WallPoster } from './WallPoster.js'

export class Wall extends FakeContainer {
    private posters: WallPoster[] = []

    private areas: ManagedGraphics[]

    spotWidth: number
    spotHeight: number

    constructor(
        readonly room: WalkableRoomViewer,
        public ref: number,
        public plane: Plane3D
    ) {
        super()

        this.renderArea()
        this.eventMode = 'none'
    }

    renderArea(): void {
        this.areas?.forEach((a) => a.destroy())
        this.areas = []

        this.spotHeight = this.plane.zd / 5
        this.spotWidth = (this.plane.dir === 3 ? this.plane.yd : this.plane.xd) / 5

        for (let y = 0; y < this.spotHeight; y++) {
            for (let x = 0; x < this.spotWidth; x++) {
                const area = this.addChild(new ManagedGraphics())
                const tile = this.plane.crop(x * 5, y * 5, 5, 5)
                area['wallX'] = x
                area['wallY'] = y
                area.zIndex = tile.origin.screenStack - 1000
                area.hitArea = tile.toPolygon()
                area.addEventListener('pointermove', this.onMove)
                area.addEventListener('pointertap', this.onTap)
            }
        }
    }

    onMove = (e: FederatedEvent): void => {
        if (this.room.furniController.isMoving()) {
            const spot = e.currentTarget
            this.room.furniController.onWallOver(this, spot['wallX'], spot['wallY'])
        }
    }
    onTap = (e: FederatedEvent): void => {
        if (this.room.furniController.isMoving()) {
            this.room.furniController.placeFurni()
        }
    }

    addPoster(poster: WallPoster): void {
        this.posters.push(poster)
    }

    removePoster(poster: WallPoster): void {
        const index = this.posters.indexOf(poster)

        if (index !== -1) {
            this.posters.splice(index, 1)
        }
    }
}
