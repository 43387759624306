import { Minigame } from '@vmk-legacy/common-ts'
import type { FederatedEvent } from 'pixi.js'
import { Client } from '../../Client.js'
import { UILayer } from '../../enums.js'
import type { ImageButton } from '../../ui/buttons/ImageButton.js'
import { ScrollArea } from '../../ui/containers/ScrollArea.js'
import { FieldHlp } from '../../ui/fields/FieldHlp.js'
import { AccordionList } from '../../ui/lists/AccordionList.js'
import { AccordionListItem } from '../../ui/lists/AccordionListItem.js'
import LegacyWindow from '../../ui/windows/LegacyWindow.js'
import type { MixerSong } from './MusicMixer.js'
import type { MusicMixerDelegate } from './MusicMixerDelegate.js'

export class MixerManager extends LegacyWindow {
    layer = UILayer.GameWindows

    list: AccordionList

    protected casts = ['interface/window_rec']

    constructor(readonly delegate: MusicMixerDelegate) {
        super('vmk_musicmix', new LegacyWindow('vmk_musicmix_start'))
    }

    override async windowWasBuilt(): Promise<void> {
        this.setField('musicmix.dialog.hd', 'musicmix.dialog.selectsong.title')
        this.setField('musicmix.edit.button', 'musicmix.edit')
        this.setField(
            'musicmix.returntoroom.button',
            this.delegate.codename === 'mxs' ? 'musicmix.returntoroom' : 'monorail.musicmix.returntoroom.button'
        )
        this.getElement('musicmix.cancel.button').addEventListener('pointerup', () =>
            Client.shared.userInterface.removeWindow(this)
        )
        this.list = new AccordionList(
            this.replaceElement('musicmix.editsongs.list', new ScrollArea(295, 90, null, 5, 5), true)
        )

        const exitBtn: ImageButton = this.getElement('musicmix.returntoroom.button')

        exitBtn.addEventListener(
            'pointerup',
            () => {
                this.delegate.teardown()
            },
            { once: true }
        )

        this.reload()
    }

    reload(): void {
        const editBtn: ImageButton = this.getElement('musicmix.edit.button')
        const deleteBtn: ImageButton = this.getElement('musicmix.delete.button')
        const renameBtn: ImageButton = this.getElement('musicmix.rename.button')

        editBtn.disable()
        deleteBtn.disable()
        renameBtn.disable()

        Client.shared.serverBroker
            .sendAck('mix_list', this.delegate.codename === Minigame.StreetMixer ? 'street' : 'mono')
            .then((list: MixerSong[]) => {
                this.list.clear()
                const item = new AccordionListItem()
                const field = item.addChild(new FieldHlp('Create New Mix'))
                field.addEventListener('pointerup', (e: FederatedEvent) => {
                    this.list.clearSelection()

                    field.setSelected(!field.isSelected())
                    this.list.setExpanded(item, field.isSelected())

                    editBtn.enable()
                    deleteBtn.disable()
                    renameBtn.disable()

                    editBtn.addEventListener(
                        'pointerup',
                        () => {
                            this.delegate.window.newSong()
                            Client.shared.userInterface.removeWindow(this)
                        },
                        { once: true }
                    )
                })
                this.list.add(item, false)

                list.forEach((song) => {
                    const item = new AccordionListItem()
                    const field = item.addChild(new FieldHlp(song.name))
                    field.addEventListener('pointerup', (e: FederatedEvent) => {
                        editBtn.off('pointerup')
                        deleteBtn.off('pointerup')
                        renameBtn.off('pointerup')

                        this.list.clearSelection()

                        field.setSelected(!field.isSelected())
                        this.list.setExpanded(item, field.isSelected())

                        editBtn.enable()
                        deleteBtn.enable()
                        renameBtn.enable()

                        editBtn.addEventListener(
                            'pointerup',
                            () => {
                                this.delegate.window.populateSong(song)
                                Client.shared.userInterface.removeWindow(this)
                            },
                            { once: true }
                        )

                        deleteBtn.addEventListener('pointerup', async () => {
                            deleteBtn.disable()
                            if (
                                await Client.shared.helpers.confirm({
                                    title: 'musicmix.dialog.delete.confirm.title',
                                    message: 'musicmix.dialog.delete.confirm.text'
                                })
                            ) {
                                Client.shared.serverBroker.sendAck('mix_delete', song.id).then((success) => {
                                    if (success) {
                                        if (this.delegate.window.editingSong.id === song.id) {
                                            this.delegate.window.newSong()
                                        }
                                        this.reload()
                                    } else {
                                        deleteBtn.enable()
                                    }
                                })
                            }
                        })

                        renameBtn.addEventListener(
                            'pointertap',
                            async () => {
                                renameBtn.disable()
                                const nameDialog = new LegacyWindow(
                                    'vmk_musicmix',
                                    new LegacyWindow('vmk_musicmix_editname')
                                )

                                await Client.shared.userInterface.register(nameDialog, true).waitToBeBuilt()

                                nameDialog.setField('musicmix.dialog.hd', 'Rename Mix')
                                nameDialog.setField('music.mixer.entername', song.name)

                                const okBtn = nameDialog.getElement('musicmix.ok')
                                okBtn.addEventListener('pointertap', () => {
                                    const newName = nameDialog.getElement('music.mixer.entername').getValue()

                                    if (newName.length < 3 || newName.length > 50) {
                                        Client.shared.helpers.alert(
                                            "Your mix's name must be between 3 and 50 characters long."
                                        )
                                        return
                                    }
                                    okBtn.disable()

                                    Client.shared.serverBroker
                                        .sendAck('mix_save', {
                                            id: song.id,
                                            name: newName
                                        })
                                        .then((id: number) => {
                                            if (id) {
                                                song.id = id
                                                song.name = newName
                                                field.setText(newName)
                                            }

                                            Client.shared.userInterface.removeWindow(nameDialog)
                                            this.delegate.showManager()
                                        })
                                })

                                nameDialog.getElement('musicmix.cancel').addEventListener('pointertap', () => {
                                    Client.shared.userInterface.removeWindow(nameDialog)
                                    this.delegate.showManager()
                                })

                                Client.shared.userInterface.removeWindow(this)
                            },
                            { once: true }
                        )
                    })
                    this.list.add(item, false)
                })

                this.list.update()
            })
    }
}
