import { Pooler } from '@vmk-legacy/render-utils'
import { AnimatedSprite, BitmapText, Container, Graphics, Texture } from 'pixi.js'
import type { TextKey } from '../../assets/ExternalConfigManager.js'
import { getText } from '../../assets/ExternalConfigManager.js'
import { Client } from '../../Client.js'
import { Constants } from '../../Constants.js'
import { EWindow, UILayer } from '../../enums.js'
import { Fonts } from '../../Fonts.js'
import type { IWindow } from '../windows/IWindow.js'

const WIDTH = 365
const HEIGHT = 140
const ANIMATION_SPEED = 0.18
const LOADING_BAR_PADDING_TOP = 28
const LOADING_BAR_PADDING_BOTTOM = 3

export class IndeterminateLoadingView extends Container implements IWindow {
    private statusText: BitmapText
    private waitText: BitmapText

    constructor(status: TextKey, wait: TextKey) {
        super()

        const g = new Graphics()
        g.beginFill(Constants.UI_POPUP_BOX_BORDER_COLOR)
        g.drawRoundedRect(0, 0, WIDTH, HEIGHT, Constants.UI_BOX_CORNER_RADIUS)
        g.beginFill(Constants.UI_POPUP_BOX_BG_COLOR)
        g.drawRoundedRect(
            Constants.UI_BOX_BORDER_WIDTH,
            Constants.UI_BOX_BORDER_WIDTH,
            WIDTH - 2 * Constants.UI_BOX_BORDER_WIDTH,
            HEIGHT - 2 * Constants.UI_BOX_BORDER_WIDTH,
            Constants.UI_BOX_CORNER_RADIUS
        )

        this.addChild(g)

        const loadingBarFrames: Texture[] = []
        for (let i = 1; i < 5; i++) {
            loadingBarFrames.push(Texture.from('Preloaqdingbar_anim_' + i))
        }
        const loadingAnim = new AnimatedSprite(loadingBarFrames)
        loadingAnim.x = Math.floor((WIDTH - loadingAnim.width) / 2)
        loadingAnim.y = Math.floor((HEIGHT - loadingAnim.height) / 2) - 8
        loadingAnim.animationSpeed = ANIMATION_SPEED
        loadingAnim.play()

        this.addChild(loadingAnim)

        const symbol = Pooler.newSprite('vmk_symbol')
        symbol.width = 37
        symbol.height = 34
        symbol.position.set(312, 92)
        this.addChild(symbol)

        this.statusText = new BitmapText(getText(status), {
            ...Fonts.FoxleyBold_16,
            align: 'left'
        })
        this.statusText.x = Math.floor((WIDTH - this.statusText.textWidth) / 2)
        this.statusText.y =
            Math.floor(HEIGHT / 2 - loadingAnim.height / 2 - this.statusText.textHeight) - LOADING_BAR_PADDING_TOP
        this.addChild(this.statusText)

        this.waitText = new BitmapText(getText(wait), {
            ...Fonts.Foxley_16,
            align: 'left'
        })
        this.waitText.x = Math.floor((WIDTH - this.waitText.textWidth) / 2)
        this.waitText.y =
            Math.floor(HEIGHT / 2 - loadingAnim.height / 2 + this.waitText.textHeight) + LOADING_BAR_PADDING_BOTTOM
        this.addChild(this.waitText)

        this.eventMode = 'static'

        this.refit()
    }

    refit() {
        this.x = Math.floor((Client.shared.size.width - this.width) / 2)
        this.y = Math.floor((Client.shared.size.height - this.height) / 2)
    }

    kind = EWindow.Other
    layer = UILayer.Loaders

    getWindowWidth(): number {
        return this.width
    }

    getWindowHeight(): number {
        return this.height
    }

    setVisible(visible: boolean): void {
        this.visible = visible
    }

    reset(): void {
        //
    }

    setStatusText(status: TextKey): void {
        this.statusText.text = getText(status)
        this.statusText.x = Math.floor((WIDTH - this.statusText.textWidth) / 2)
    }

    setWaitText(wait: TextKey): void {
        this.waitText.text = getText(wait)
        this.waitText.x = Math.floor((WIDTH - this.waitText.textWidth) / 2)
    }
}
