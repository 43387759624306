import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText, Graphics } from 'pixi.js'
import { getText } from '../../../../assets/ExternalConfigManager.js'
import { Client } from '../../../../Client.js'
import { Fonts } from '../../../../Fonts.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../../../buttons/BitmapTextButtonType.js'
import { ContentBox } from '../../../containers/ContentBox.js'
import { ScrollArea } from '../../../containers/ScrollArea.js'
import { UIContentBG } from '../../../containers/UIContentBG.js'
import { DOMText } from '../../../DOMText.js'
import { FieldG } from '../../../fields/FieldG.js'
import { FieldMes } from '../../../fields/FieldMes.js'
import { Separator } from '../../Separator.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { MessengerWindow } from '../MessengerWindow.js'
import { Friend } from './Friend.js'

export class FriendsView extends UIWindowView<MessengerWindow> {
    friendCells: FieldMes[] = []
    private messageBtn: BitmapTextButton
    private enterSameRoomBtn: BitmapTextButton
    private deleteFriendBtn: BitmapTextButton
    private friendDetailPlaceholderText: BitmapText
    private friendName: BitmapText
    private friendInfo: BitmapText
    private friendsScrollArea: ScrollArea
    private header: BitmapText
    private selectedCell: FieldMes
    private requestContainer: Container
    private requestUsername: BitmapText
    private requestBody: BitmapText
    private requestOk: BitmapTextButton
    private requestCancel: BitmapTextButton
    private requestIgnore: BitmapTextButton
    private showBtn: BitmapTextButton
    private requestBg: UIContentBG
    private requests: { ign: string; id: number }[]
    private friendDetailBG: UIContentBG

    private messageContainer: Container
    private messageTF: DOMText
    private toName: BitmapText
    toIgn: string
    toId: number
    private friends: any[]

    setMessageContainerVisible(visible: boolean) {
        this.messageTF.setValue('')

        if (visible) {
            this.toName.text = this.toIgn
        }

        this.messageContainer.visible = visible
    }

    update() {
        if (!this.friendCells) {
            return
        }
        const friendsContainer = Pooler.newContainer()
        const FRIEND_SPACING = 5
        for (let i = 0; i < this.friendCells.length; i++) {
            const friendCell: FieldMes = this.friendCells[i]
            friendCell.y = i * (friendCell.getTotalHeight() + FRIEND_SPACING)
            friendCell.setSelected(false)
            friendCell.eventMode = 'static'

            const lastOnline = friendCell.getFriend().getLastOnline()
            const roomId = friendCell.getFriend().getRoomId()
            const roomName = friendCell.getFriend().getRoomName()
            const signature = friendCell.getFriend().getSignature().trim()

            friendCell.addEventListener('pointerup', (e: FederatedEvent) => {
                this.friendCells.forEach((f: FieldMes) => {
                    f.setSelected(false)
                })
                this.selectedCell = friendCell
                this.deleteFriendBtn.visible = this.messageBtn.visible = true
                if (Client.shared.serverBroker.serverMinVers(12)) {
                    this.enterSameRoomBtn.visible = true
                }
                this.friendDetailPlaceholderText.visible = false
                this.friendName.text = friendCell.getUser()

                const isOnline = friendCell.getFriend().getIsOnline()
                friendCell.setSelected(true)
                if (isOnline) {
                    this.enterSameRoomBtn.enable()
                } else {
                    this.enterSameRoomBtn.disable()
                }

                const lastSeenText =
                    lastOnline == null
                        ? 'never'
                        : lastOnline.toLocaleDateString('en-US', {
                              timeZone: 'America/New_York',
                              day: 'numeric',
                              month: 'numeric',
                              year: '2-digit'
                          }) +
                          ' at ' +
                          lastOnline.toLocaleTimeString('en-US', {
                              timeZone: 'America/New_York',
                              hour: 'numeric',
                              minute: '2-digit'
                          })
                let statusText
                if (isOnline) {
                    if (roomId > -1) {
                        statusText = `Online in: ${roomName}`
                    } else {
                        statusText = 'Not in any room'
                    }
                } else {
                    statusText = `Last online ${lastSeenText}`
                }
                // let statusText = roomId > -1 ? `In room "${roomName}"` :
                //     `Last online ${lastSeenText}`;
                // Append signature
                statusText += `\n${signature}`
                this.friendInfo.text = statusText
            })

            friendsContainer.addChild(friendCell)
        }
        this.friendsScrollArea?.destroy({ children: true })
        this.friendsScrollArea = new ScrollArea(265, 200, friendsContainer)
        this.friendsScrollArea.x = 0
        this.friendsScrollArea.y = 30
        this.addChild(this.friendsScrollArea)

        this.header.text =
            'You have ' +
            (this.requests.length === 0 ? 'no' : this.requests.length.toString()) +
            ' new friend request' +
            (this.requests.length === 1 ? '.' : 's.')
        if (this.requests.length === 0) {
            this.setRequestsVisible(false)
            this.showBtn.disable()
            Client.shared.userInterface.toolbar.stopMessageGlow()
        } else {
            this.setRequestsVisible(this.requestBg.visible)
            this.setRequest(this.requests[0].id, this.requests[0].ign)
            this.showBtn.enable()
            Client.shared.userInterface.toolbar.messageButtonGlow()
        }

        if (this.messageContainer) {
            this.removeChild(this.messageContainer)
            this.addChild(this.messageContainer)
        }
    }

    onAppear() {}

    setRequestsVisible(visible: boolean) {
        this.requestBg.visible = this.requestContainer.visible = visible

        this.friendDetailBG.visible =
            this.friendDetailPlaceholderText.visible =
            this.friendName.visible =
            this.friendInfo.visible =
            this.deleteFriendBtn.visible =
            this.messageBtn.visible =
            this.friendsScrollArea.visible =
                !visible

        // this.showBtn.setT
        if (visible && this.requests.length > 0) {
            this.setRequest(this.requests[0].id, this.requests[0].ign)
            this.showBtn.setText('HIDE')
        } else {
            this.clearSelection()
            this.showBtn.setText('SHOW')
        }
    }

    getFriendsData() {
        return this.friends
    }

    repopulate() {
        this.populate(this.friends, this.requests)
    }

    populate(friends: any[], requests: any) {
        this.friendCells = []
        this.friends = friends
            .sort((a, b) => (a.ign > b.ign ? 1 : a.ign < b.ign ? -1 : 0))
            .sort((a, b) => (a.isOnline && !b.isOnline ? -1 : !a.isOnline && b.isOnline ? 1 : 0))
        this.friends.forEach((elem: any) => {
            const id: number = elem.id
            const ign: string = elem.ign
            const roomId: number = elem.roomId
            const roomName: string = elem.roomName
            const signature: string = elem.signature
            const lastOnline: Date = elem.lastOnline == null ? null : new Date(elem.lastOnline)
            const isOnline: boolean = elem.isOnline
            let statusText: string

            if (roomId > -1) {
                statusText = roomName
            } else {
                statusText =
                    lastOnline == null
                        ? ''
                        : lastOnline.toLocaleDateString([], {
                              day: '2-digit',
                              month: 'numeric',
                              year: '2-digit'
                          }) +
                          ' ' +
                          lastOnline.toLocaleTimeString([], {
                              hour: 'numeric',
                              minute: '2-digit'
                          })
            }

            const friend = new Friend(id, ign, lastOnline, roomId, roomName, signature, isOnline)
            const cell = new FieldMes(ign, `${ign} ${statusText ? '(' + statusText + ')' : ''}`, isOnline, friend)
            this.friendCells.push(cell)
        })
        this.requests = requests
        this.update()
    }

    constructor() {
        super()

        this.requests = []
        this.header = new BitmapText(
            'You have ' +
                (this.requests.length === 0 ? 'no' : this.requests.length.toString()) +
                ' new friend request' +
                (this.requests.length === 1 ? '' : 's'),
            {
                ...Fonts.Foxley_16
            }
        )
        this.header.x = 12
        this.header.y = 5
        this.addChild(this.header)

        const separator = new Separator(190)
        separator.x = 5
        separator.y = 22
        this.addChild(separator)

        this.showBtn = new BitmapTextButton(65, 'SHOW', BitmapTextButtonType.GREEN)
        this.showBtn.x = 213
        this.showBtn.y = 1
        this.addChild(this.showBtn)

        this.requestBg = new UIContentBG(284, 330)
        this.requestBg.x = 0
        this.requestBg.y = 30
        this.addChild(this.requestBg)
        this.requestBg.visible = false

        this.showBtn.addEventListener('pointerup', () => {
            this.setRequestsVisible(!this.requestBg.visible)
        })

        if (this.requests.length === 0) {
            this.showBtn.disable()
        }

        this.friendDetailBG = new UIContentBG(284, 113)
        this.friendDetailBG.x = 0
        this.friendDetailBG.y = 254
        this.addChild(this.friendDetailBG)
        this.friendDetailPlaceholderText = new BitmapText('Click on a friend to see more', {
            ...Fonts.Foxley_16
        })
        this.friendDetailPlaceholderText.x = this.friendDetailBG.x + 11
        this.friendDetailPlaceholderText.y = this.friendDetailBG.y + 10
        this.addChild(this.friendDetailPlaceholderText)
        this.friendName = new BitmapText('', {
            ...Fonts.FoxleyBold_16
        })
        this.friendName.x = this.friendDetailBG.x + 8
        this.friendName.y = this.friendDetailBG.y + 8
        this.addChild(this.friendName)
        this.friendInfo = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        this.friendInfo.x = this.friendName.x
        this.friendInfo.y = this.friendName.y + 31
        this.friendInfo.maxWidth = 280
        this.addChild(this.friendInfo)

        this.deleteFriendBtn = new BitmapTextButton(75, 'DELETE', 'e')
        this.deleteFriendBtn.x = this.friendName.x
        this.deleteFriendBtn.y = this.friendInfo.y + 60
        this.deleteFriendBtn.visible = false
        this.addChild(this.deleteFriendBtn)

        this.deleteFriendBtn.addEventListener('pointerup', () => {
            if (this.selectedCell !== null) {
                Client.shared.serverBroker.send('remove_friend', {
                    id: this.selectedCell.getFriend().getId()
                })
                this.clearSelection()
            }
        })

        this.messageBtn = new BitmapTextButton(120, 'SEND MESSAGE', 'd')
        this.messageBtn.x = this.friendName.x + 165
        this.messageBtn.y = this.friendInfo.y + 62
        this.messageBtn.visible = false
        this.addChild(this.messageBtn)
        this.messageBtn.addEventListener('pointerup', () => {
            this.toIgn = this.selectedCell.getFriend().getIgn()
            this.toId = this.selectedCell.getFriend().getId()
            this.setMessageContainerVisible(true)
        })

        this.enterSameRoomBtn = new BitmapTextButton(120, 'ENTER SAME ROOM', 'd')
        this.enterSameRoomBtn.x = this.messageBtn.x
        this.enterSameRoomBtn.y = this.friendDetailBG.y + 7
        this.enterSameRoomBtn.visible = false
        this.addChild(this.enterSameRoomBtn)
        this.enterSameRoomBtn.addEventListener('pointerup', () => {
            Client.shared.serverBroker.send('friend_follow', this.selectedCell.getFriend().getId())
        })

        // Friend Request UI

        const ign = '?'
        this.requestContainer = Pooler.newContainer()
        this.requestUsername = new BitmapText(ign, {
            ...Fonts.FoxleyBold_16
        })
        this.requestUsername.x = Math.round((300 - this.requestUsername.textWidth) / 2) + 2
        this.requestUsername.y = 40
        this.requestContainer.addChild(this.requestUsername)

        this.requestBody = new BitmapText('', {
            ...Fonts.Foxley_16
        })
        this.requestBody.x = 10
        this.requestBody.y = 70
        this.requestBody.roundPixels = true
        this.requestBody.align = 'center'
        this.requestBody.maxWidth = 280
        this.requestContainer.addChild(this.requestBody)

        this.requestOk = new BitmapTextButton(70, 'OK', BitmapTextButtonType.GREEN)
        this.requestOk.x = 10
        this.requestOk.y = 350
        this.requestContainer.addChild(this.requestOk)
        this.requestOk.addEventListener('pointerup', () => {
            const req = this.requests.shift()
            Client.shared.serverBroker.send('friend_req_accept', req)
            this.update()
        })

        this.requestCancel = new BitmapTextButton(70, 'CANCEL', BitmapTextButtonType.GREEN)
        this.requestCancel.x = 92
        this.requestCancel.y = 350
        this.requestContainer.addChild(this.requestCancel)
        this.requestCancel.addEventListener('pointerup', () => {
            this.setRequestsVisible(false)
        })

        this.requestIgnore = new BitmapTextButton(70, 'IGNORE', BitmapTextButtonType.RED)
        this.requestIgnore.x = 214
        this.requestIgnore.y = 350
        this.requestContainer.addChild(this.requestIgnore)
        this.requestIgnore.addEventListener('pointerup', () => {
            const req = this.requests.shift()
            Client.shared.serverBroker.send('friend_req_ignore', req)
            this.update()
        })

        this.addChild(this.requestContainer)

        this.messageContainer = Pooler.newContainer()
        const bg = new Graphics().beginFill(0x275692).drawRect(0, 0, 305, 385).endFill()
        this.messageContainer.addChild(bg)
        bg.eventMode = 'static'
        this.addChild(this.messageContainer)

        const countText = new BitmapText('Type a message', Fonts.Foxley_16)
        countText.x = Math.round((295 - countText.textWidth) / 2)
        countText.y = -3
        this.messageContainer.addChild(countText)

        const sep = new Separator(261)
        sep.position.set(21, 15)
        this.messageContainer.addChild(sep)

        const to = new BitmapText('To:', Fonts.FoxleyBold_16)
        to.position.set(10, 24)
        this.messageContainer.addChild(to)

        this.toName = new BitmapText('', Fonts.Foxley_16)
        this.toName.position.set(34, 23)
        this.messageContainer.addChild(this.toName)

        const bodyContainer = new ContentBox(278, 315)
        bodyContainer.position.set(5, 43)
        this.messageContainer.addChild(bodyContainer)

        const sendBtn = new BitmapTextButton(130, 'SEND MESSAGE', BitmapTextButtonType.GREEN)
        sendBtn.position.set(15, 346)
        this.messageContainer.addChild(sendBtn)
        const cancelBtn = new BitmapTextButton(130, 'CANCEL', BitmapTextButtonType.GREEN)
        cancelBtn.position.set(156, 346)
        this.messageContainer.addChild(cancelBtn)

        this.messageTF = new DOMText({
            kind: 'area',
            className: 'messenger',
            id: 'mes.body',
            maxlength: 512,
            fieldWidth: 265,
            fieldHeight: 290,
            fontSize: 16,
            fontColor: '#000000',
            initialValue: '',
            bgObject: new FieldG(270, 277),
            padLeft: 7,
            padTop: 2
        })
        this.messageTF.position.set(13, 51)
        this.messageContainer.addChild(this.messageTF)

        cancelBtn.addEventListener('pointerup', () => {
            this.setMessageContainerVisible(false)
        })

        sendBtn.addEventListener('pointerup', () => {
            const message = this.messageTF.getValue().trim()

            if (message.length > 255) {
                void Client.shared.helpers.alert(
                    `Sorry, your message is too long. It can only be 255 characters, yours is ${message.length}.`
                )
                return
            }

            Client.shared.serverBroker.send('message_send', {
                id: this.toId,
                body: message
            })
            this.setMessageContainerVisible(false)
        })

        this.setMessageContainerVisible(false)
        this.visible = true
    }

    private setRequest(id: number, ign: string) {
        this.requestUsername.text = ign
        this.requestUsername.x = Math.round((300 - this.requestUsername.textWidth) / 2) - 8
        this.requestUsername.y = 40
        this.requestContainer.addChild(this.requestUsername)

        this.requestBody.text = getText('mes.friends.requestwhoinfo').replaceAll('%senderName%', ign)
    }

    private clearSelection() {
        this.friendCells.forEach((cell: FieldMes) => {
            cell.setSelected(false)
        })
        this.deleteFriendBtn.visible = this.messageBtn.visible = this.enterSameRoomBtn.visible = false
        this.selectedCell = null
        this.friendDetailPlaceholderText.visible = true
        this.friendName.text = ''

        this.friendInfo.text = ''
    }
}
