import { PotcShipType } from '@vmk-legacy/potc-engine'
import { Client } from '../../../Client.js'
import { UILayer } from '../../../enums.js'
import LegacyWindow from '../../../ui/windows/LegacyWindow.js'
import type { PiratesSignupView } from './PiratesSignupView.js'

export class PiratesSelectShipWin extends LegacyWindow {
    layer = UILayer.GameWindows

    protected casts = ['minigame/pirates2_interface']

    constructor(
        readonly signup: PiratesSignupView,
        protected ship: PotcShipType
    ) {
        super('vmk_pirates_select_ship')
    }

    async windowWasBuilt(): Promise<void> {
        console.log('PiratesSelectShipWin.windowWasBuilt')

        this.setShip(this.ship)

        const ship1Btn = this.getElement('pirates.ship.pearl')
        const ship2Btn = this.getElement('pirates.ship.dutch')
        const ship3Btn = this.getElement('pirates.ship.winch')

        ship1Btn.addEventListener('pointertap', () => this.setShip(PotcShipType.BlackPearl))
        ship2Btn.addEventListener('pointertap', () => this.setShip(PotcShipType.FlyingDutchman))
        ship3Btn.addEventListener('pointertap', () => this.setShip(PotcShipType.WickedWinch))

        this.getElement('button.pirates.cancel').once('pointertap', () => {
            Client.shared.userInterface.removeWindow(this.embeddedParent)
            this.signup.changeShipWin = null
        })

        this.getElement('button.pirates.selectship').once('pointertap', () => {
            Client.shared.userInterface.removeWindow(this.embeddedParent)
            this.signup.changeShipWin = null

            Client.shared.serverBroker.send('potc_change_ship', this.ship)
        })
    }

    setShip(ship: PotcShipType) {
        if (ship === PotcShipType.BlackPearl) {
            this.hide('pirates.shipselector2', 'pirates.shipselector3')
            this.show('pirates.shipselector1')
        } else if (ship === PotcShipType.FlyingDutchman) {
            this.hide('pirates.shipselector1', 'pirates.shipselector3')
            this.show('pirates.shipselector2')
        } else {
            this.hide('pirates.shipselector1', 'pirates.shipselector2')
            this.show('pirates.shipselector3')
        }
        this.ship = ship
    }
}
