import { ResponsiveContainer } from '../views/AlertView.js'

export class AccordionListItem extends ResponsiveContainer {
    expanded = false
    selected = false

    constructor() {
        super()
        this.eventMode = 'static'
        this.interactiveChildren = true
    }

    override sizeDidChange(): void {
        //
    }
}
