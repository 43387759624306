import { Pooler } from '@vmk-legacy/render-utils'
import type { Rectangle, Sprite } from 'pixi.js'
import { BitmapText, Container, Graphics } from 'pixi.js'
import type { StackedProvider } from '../../data/ItemStack.js'
import type { AvatarStackedProvider } from '../../data/AvatarStack.js'
import { Fonts } from '../../Fonts.js'
import { PagedThumbnailBox } from '../thumbs/PagedThumbnailBox.js'
import { MagicPinBox } from '../windows/FXWindow.js'
import { ThumbnailLoadAnim } from './ThumbnailLoadAnim.js'

export class ThumbnailBox extends Container {
    private selected: boolean = null
    private borderBoxBorderSize = 2
    private borderGfx: Graphics
    private quantityText: BitmapText
    private noteText: BitmapText

    private quantity: number
    private note: string | null

    private lastTap: Date

    private stars: Sprite[]

    private loadingAnim: ThumbnailLoadAnim

    private sourceItem?: StackedProvider | AvatarStackedProvider
    private thumbImage: Sprite | Container
    private previewImage: Sprite | Container
    private bg: Graphics
    private singleIcon?: Sprite

    constructor(
        private boxWidth = PagedThumbnailBox.THUMB_SIZE,
        private boxHeight = boxWidth,
        private hideEmpty = true
    ) {
        super()

        this.stars = []
        this.cursor = 'default'

        this.bg = new Graphics()
        this.bg.beginFill(0x265692)
        this.bg.drawRect(0, 0, this.boxWidth, this.boxHeight)
        this.bg.endFill()
        this.bg.visible = !hideEmpty
        this.addChild(this.bg)

        this.borderGfx = new Graphics()
        this.addChild(this.borderGfx)

        this.loadingAnim = new ThumbnailLoadAnim()
        this.loadingAnim.x = 23
        this.loadingAnim.y = 23
        this.addChild(this.loadingAnim)

        this.lastTap = new Date(1970, 1)

        this.setSelected(false)
    }

    setNote(note: any): void {
        this.note = !note ? null : String(note)
        this.setSelected(this.selected)
    }

    setQuantity(quantity: number): void {
        this.quantity = quantity
        this.setSelected(this.selected)
    }

    getLastTapMS(): number {
        return this.lastTap.getTime()
    }

    setLastTap(date: Date): void {
        this.lastTap = date
    }

    hideLoadingAnim(): void {
        this.loadingAnim.visible = false
    }

    showLoadingAnim(): void {
        this.loadingAnim.visible = true
    }

    setPreviewImage(preview: Container | Sprite): void {
        this.previewImage = preview
    }

    getPreviewImage(): Container | Sprite {
        return this.previewImage
    }

    setSelected(selected = true): void {
        this.selected = selected

        if (this.quantityText) {
            this.removeChild(this.quantityText)
        }
        if (this.noteText) {
            this.removeChild(this.noteText)
        }

        if (!this.sourceItem && this.hideEmpty) {
            this.borderGfx.clear()
            this.removeChild(this.borderGfx)
            return
        }

        if (this.borderGfx) {
            this.borderGfx.clear()
            this.borderGfx.beginFill(selected ? 0xfbcf0a : 0x50a2d4) // yellow if selected, blue if not
            this.borderGfx.drawRect(0, 0, this.borderBoxBorderSize, this.boxHeight) // left border
            this.borderGfx.drawRect(0, 0, this.boxWidth, this.borderBoxBorderSize) // top
            this.borderGfx.drawRect(
                this.boxWidth - this.borderBoxBorderSize,
                0,
                this.borderBoxBorderSize,
                this.boxHeight
            ) // right
            this.borderGfx.drawRect(
                0,
                this.boxHeight - this.borderBoxBorderSize,
                this.boxWidth,
                this.borderBoxBorderSize
            ) // bottom
            this.borderGfx.endFill()
        }

        this.removeChild(this.borderGfx)
        this.addChild(this.borderGfx)

        if (this.quantity && !isNaN(this.quantity) && this.quantity > 1) {
            this.quantityText = new BitmapText(this.quantity.toString(), {
                ...Fonts.FoxleyBold_16
            })
            const quantityBgWidth: number = this.quantityText.textWidth + 4
            const quantityBgHeight = 12
            this.borderGfx.beginFill(selected ? 0xfbcf0a : 0x50a2d4)
            this.borderGfx.drawRect(
                this.boxWidth - this.borderBoxBorderSize - quantityBgWidth,
                this.borderBoxBorderSize,
                quantityBgWidth,
                quantityBgHeight
            )
            this.borderGfx.endFill()

            this.quantityText.y = 1
            this.quantityText.x = this.boxWidth - this.quantityText.textWidth - 3
            this.addChild(this.quantityText)
        }

        if (this.note) {
            this.noteText = new BitmapText(String(this.note), {
                ...Fonts.Foxley_16,
                letterSpacing: -3,
                fontSize: 14
            })
            const noteBgWidth: number = this.noteText.textWidth + 1
            const noteBgHeight = 11
            this.borderGfx.beginFill(selected ? 0xfbcf0a : 0x50a2d4)
            this.borderGfx.drawRect(
                this.borderBoxBorderSize,
                this.boxHeight - noteBgHeight - this.borderBoxBorderSize,
                noteBgWidth,
                noteBgHeight
            )
            this.borderGfx.endFill()

            this.noteText.y = this.boxWidth - 14
            this.noteText.x = 1
            this.addChild(this.noteText)
        }
    }

    getSelected(): boolean {
        return this.selected
    }

    setSourceItem(sourceItem: StackedProvider | AvatarStackedProvider | undefined): void {
        this.bg.visible = this.hideEmpty ? !!sourceItem : true
        this.sourceItem = sourceItem
    }

    setSingleUse(singleUse: boolean): void {
        if (singleUse) {
            this.setStars(0)
            if (!this.singleIcon) {
                this.singleIcon = Pooler.newSprite('icon.singleuse')
                this.singleIcon.position.set(2, 2)
                this.addChild(this.singleIcon)
            }
        } else if (this.singleIcon) {
            Pooler.release(this.singleIcon)
            this.singleIcon = undefined
        }
    }

    setStars(count: number, texture = 'inv.pin.star.thumb'): void {
        while (this.stars.length > 0) {
            this.removeChild(this.stars[0])
            this.stars.shift()
        }

        if (count > 5 || count < 1) {
            return
        }

        for (let i = 0; i < count; i++) {
            let star: Sprite
            try {
                star = Pooler.newSprite(texture)
            } catch (error) {
                star = Pooler.newSprite()
            }
            star.x = i * 7 + 5
            star.y = this.boxWidth - 9
            this.addChild(star)
            this.stars.push(star)
        }
    }

    setThumbImage(image: Sprite | null, bounds?: Rectangle): Sprite | null {
        if (image === this.thumbImage) {
            return image
        }

        Pooler.release(this.thumbImage)
        if (!image || image.destroyed) {
            this.thumbImage = null
            return null
        }

        this.thumbImage = image
        this.addChild(this.thumbImage)

        const newSize = MagicPinBox.calculateAspectRatioFit(
            this.thumbImage.width,
            this.thumbImage.height,
            this.boxWidth - 4,
            this.boxHeight - 4
        )

        this.thumbImage.scale.set(newSize.width / this.thumbImage.width)
        this.thumbImage.anchor.set(0.5)
        this.thumbImage.position.set(this.boxWidth / 2, this.boxHeight / 2)

        this.loadingAnim.visible = false

        this.setSelected(this.selected)

        return image
    }

    getSourceItem(): StackedProvider | AvatarStackedProvider | undefined {
        return this.sourceItem
    }

    clear(): void {
        this.quantity = 0
        this.thumbImage?.destroy({ children: true })
        this.thumbImage = null
        if (!this.previewImage?.parent || !this.previewImage?.visible) {
            this.previewImage?.destroy({ children: true })
        }
        this.previewImage = null
        this.setSourceItem(undefined)
        this.setSingleUse(false)
        this.setStars(0)
        this.off('pointertap')
        this.hideLoadingAnim()
        this.setSelected(false)
    }
}
