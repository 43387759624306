/*
 * DarkRideConfigWindow.ts
 * VMK Legacy Client
 */

import { getAnimAreaTileOffsets } from '@vmk-legacy/common-ts'
import type { Vector } from '@vmk-legacy/potc-engine'
import { Pooler } from '@vmk-legacy/render-utils'
import { type Sprite, Texture } from 'pixi.js'
import { Client } from '../../../Client'
import { UILayer } from '../../../enums'
import type { FurniEntity } from '../../../room/renderer/types/FurniEntity'
import type { WalkableRoomViewer } from '../../../room/renderer/WalkableRoomViewer'
import LegacyWindow from '../LegacyWindow'

export class DarkRideConfigWindow extends LegacyWindow {
    layer = UILayer.GameWindows

    private configuration: {
        f: number
        fr: number
        r: number
        br: number
        b: number
        bl: number
        l: number
        fl: number
    }
    private tileMarker: Texture
    private markerTiles: Sprite[] = []
    private room: WalkableRoomViewer

    constructor(readonly furni: FurniEntity) {
        super('vmk_basic', new LegacyWindow('dark_ride_configuration'), {
            'basic.window.title': 'item.configuration.darkride.title'
        })

        this.room = Client.shared.roomViewer as WalkableRoomViewer

        const configArray = furni.getItem().customData?.animArea ?? []
        this.configuration = {
            f: configArray[0] ?? 0,
            fr: configArray[1] ?? 0,
            r: configArray[2] ?? 0,
            br: configArray[3] ?? 0,
            b: configArray[4] ?? 0,
            bl: configArray[5] ?? 0,
            l: configArray[6] ?? 0,
            fl: configArray[7] ?? 0
        }
        this.tileMarker = Texture.from(`proximity_marker_${this.room.getTilesize()}`)
    }

    override async windowWasBuilt(): Promise<void> {
        this.initTiles()

        for (const entry of Object.entries(this.configuration)) {
            this.activateDirection(entry[0], entry[1])
        }

        for (const tile of this.getElements('darkride.dir.*')) {
            tile.on('pointertap', () => this.eventProc(tile))
        }

        this.furni.on('rotated', this.furniWasChanged)

        this.getElement('btn.ok').on('pointertap', () => {
            Client.shared.serverBroker.send('furni_data', {
                itemId: this.furni.getItem().itemId,
                updates: {
                    ...this.furni.getItem().customData,
                    animArea: [
                        this.configuration.f,
                        this.configuration.fr,
                        this.configuration.r,
                        this.configuration.br,
                        this.configuration.b,
                        this.configuration.bl,
                        this.configuration.l,
                        this.configuration.fl
                    ]
                }
            })
            Client.shared.userInterface.removeWindow(this)
        })
        this.getElement('btn.cancel').on('pointertap', () => {
            Client.shared.userInterface.removeWindow(this)
        })
    }

    override destroy(_options?: boolean) {
        super.destroy(_options)

        this.clearTiles()

        this.furni.off('rotated', this.furniWasChanged)
    }

    furniWasChanged = () => {
        this.initTiles()
    }

    initTiles(): void {
        this.showTiles(this.getSurroundingBlocks())
    }

    eventProc = (tile: Sprite) => {
        const [_, side, num] = tile.name.match(/darkride.dir.([f|b]?[r|l]?).([1-5])/)
        this.clickRadioButton(side, num)
    }

    clickRadioButton(side: string, num: number) {
        if (this.configuration[side] === +num) {
            this.configuration[side] = 0
        } else {
            this.configuration[side] = +num
        }
        this.activateDirection(side, this.configuration[side])
        this.initTiles()
    }

    clearTiles(): void {
        for (const t of this.markerTiles) {
            t.destroy()
        }
        this.markerTiles.length = 0
    }

    showTiles(positions: Vector[]): void {
        this.clearTiles()
        for (const pos of positions) {
            const marker = Pooler.newSprite(this.tileMarker)
            this.markerTiles.push(marker)
            marker.position.set(pos.x, pos.y)
            marker.zIndex = (this.furni.getPart(0, 0)?.zIndex ?? 0) - 1
            this.room.sprites.addChild(marker)
        }
        this.room.sprites.sortChildren()
    }

    getSurroundingBlocks(): Vector[] {
        const offsets = getAnimAreaTileOffsets(this.furni.getItem().rotation, Object.values(this.configuration))
        const locations: Vector[] = []
        for (const block of this.furni.parts) {
            const blockTile = block.getTile()
            if (!blockTile) {
                continue
            }
            for (const offset of offsets) {
                const x = blockTile.getMapX() + offset.x
                const y = blockTile.getMapY() + offset.y
                const tile = blockTile.getFloor().getTile(x, y)
                if (!tile) {
                    continue
                }

                const newLoc = tile.position
                if (!locations.some((l) => l.x === newLoc.x && l.y === newLoc.y)) {
                    locations.push(newLoc)
                }
            }
        }

        return locations
    }
    activateDirection(dir: string, num: number): void {
        let tNum = 1
        while (true) {
            const elem = this.getElement(`darkride.dir.${dir}.${tNum}`)
            if (elem) {
                elem.alpha = 0
                tNum++
                continue
            }
            break
        }
        for (let i = 1; i <= num; i++) {
            const elem = this.getElement(`darkride.dir.${dir}.${i}`)
            if (elem) {
                elem.alpha = 1
            }
        }
    }
}
