import type { ScaleField } from '../../fields/ScaleField.js'

export class RoomField {
    roomId: number
    nameField: ScaleField
    peopleField: ScaleField
    queueField: ScaleField
    name: string
    owner: string
    description: string
    event: boolean
    own: boolean
    lastTap: Date = new Date(0)

    constructor(
        roomId: number,
        nameField: ScaleField,
        peopleField: ScaleField,
        queueField: ScaleField,
        name: string,
        owner: string,
        description: string,
        event: boolean,
        own: boolean
    ) {
        this.roomId = roomId
        this.nameField = nameField
        this.peopleField = peopleField
        this.queueField = queueField
        this.name = name
        this.owner = owner
        this.description = description
        this.event = event
        this.own = own
    }

    getLastTap(): Date {
        return this.lastTap
    }

    setLastTap(date: Date) {
        this.lastTap = date
    }
}
