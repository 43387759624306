import { Pooler } from '@vmk-legacy/render-utils'
import { Container } from 'pixi.js'

export class FieldH extends Container {
    constructor(innerWidth: number) {
        super()

        const left = Pooler.newSprite('field.h.left')
        const right = Pooler.newSprite('field.h.right')
        const mid = Pooler.newSprite('field.h.middle')

        mid.width = innerWidth
        mid.x = left.width

        right.x = left.width + mid.width

        this.addChild(left, mid, right)
    }
}
