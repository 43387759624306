import { Pooler } from '@vmk-legacy/render-utils'
import { BLEND_MODES, Container, Texture } from 'pixi.js'

export default class TextScroller extends Container {
    #text: string
    #viewableChars = 6
    #speed: number
    #textIndex = -1
    #usingFont: 1 | 2
    #fontTex: Texture
    #letters: { [char: string]: Texture } = {}
    #pauseTicks = 0
    #scrambleTicks = 0
    #blinkTicks = 0
    #currentText: string[] = []
    #loop = true
    #lastTime = 0

    constructor(chars: number, speed: number, font: 1 | 2) {
        super()
        this.#viewableChars = chars
        this.#speed = speed
        this.#usingFont = font
        this.#fontTex = Texture.from(this.#fonts[font].name)
        this.#currentText = new Array(chars).fill(' ', 0, chars)

        const fnt = this.#fonts[font]

        let x = 0
        for (const letter of fnt.letters) {
            const width = fnt[letter] ?? fnt.width

            const tex = this.#fontTex.clone()
            tex.frame.x += x
            tex.frame.width = width
            tex.updateUvs()
            this.#letters[letter] = tex
            x += width
        }
    }

    setText(text: string, loop = true): void {
        this.#text = text.toUpperCase() + ' '
        this.#textIndex = -1
        this.#scrambleTicks = 0
        this.#pauseTicks = 0
        this.#blinkTicks = 0
        this.#lastTime = 0
        this.#currentText = new Array(this.#viewableChars).fill(' ', 0, this.#viewableChars)
        this.#loop = loop
    }

    getCurrentText(): string[] {
        if (this.#scrambleTicks > 0) {
            this.#scrambleTicks--

            const letters = 'ABCDEFGHJKLMNOPQRSTUVWXYZ023456789'

            const result = []
            const charactersLength = letters.length
            for (let i = 0; i < this.#viewableChars; i++) {
                result.push(letters.charAt(Math.floor(Math.random() * charactersLength)))
            }

            return result
        }

        if (this.#textIndex === this.#text.length - 1) {
            this.#currentText.push(' ')
            this.#currentText.shift()
            if (this.#currentText.filter((r) => r === ' ').length === this.#viewableChars) {
                if (!this.#loop) {
                    this.#text = null
                } else {
                    this.#textIndex = 0
                }
            }
            return this.#currentText
        }

        const nextLetter = this.#text[this.#textIndex]
        this.#textIndex++

        if (nextLetter === '|') {
            this.#pauseTicks = Math.floor(3000 / 150)

            // add a space in its place but only AFTER the pause
            const t = this.#currentText.slice(0)

            this.#currentText.push(' ')
            this.#currentText.shift()

            return t
        }
        if (nextLetter === '@') {
            this.#scrambleTicks = Math.floor(3000 / 150)

            const t = this.#currentText.slice(0)

            this.#currentText.push(' ')
            this.#currentText.shift()

            return t
        }
        if (nextLetter === '$') {
            this.#blinkTicks = Math.floor(3000 / 150)

            const t = this.#currentText.slice(0)

            this.#currentText.push(' ')
            this.#currentText.shift()

            return t
        }

        this.#currentText.push(nextLetter)
        this.#currentText.shift()

        return this.#currentText
    }

    tick(): void {
        const time = Date.now()
        if (!this.#text || time - this.#lastTime < 150) {
            return
        }
        this.#lastTime = time
        if (this.#pauseTicks > 0) {
            this.#pauseTicks--
            return
        }

        if (this.#blinkTicks > 0) {
            if (this.#blinkTicks % 2) {
                this.visible = !this.visible
            }
            this.#blinkTicks--
            return
        }
        const current = this.getCurrentText()

        this.visible = true

        let x = this.#fonts[this.#usingFont].width / 2
        const defaultWidth = this.#fonts[this.#usingFont].width
        const b = this.#fonts[this.#usingFont].height / 2
        const slope = this.#fonts[this.#usingFont].slope
        const newSprites = []
        const reserved = ['@', '$', '|']
        for (const letter of current) {
            if (letter === ' ' || !this.#letters[letter]) {
                x += defaultWidth
                continue
            }
            const tex = this.#letters[letter]
            const y = slope * x + b
            const spr = Pooler.newSprite(tex)
            spr.blendMode = BLEND_MODES.SCREEN
            spr.position.set(x, y)
            newSprites.push(spr)
            x += tex.width
        }
        this.removeChildren()
        if (newSprites.length) {
            this.addChild(...newSprites)
        }
    }

    #fonts = {
        1: {
            name: 'Digital_font',
            letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.:!?',
            slope: 0.5,
            width: 10,
            height: 10,
            stepH: 0,
            stepV: 0,
            I: 5,
            L: 8,
            M: 11,
            N: 11,
            S: 9,
            '.': 5,
            ':': 5,
            T: 9,
            1: 5,
            3: 9,
            5: 9,
            7: 7,
            9: 9,
            '!': 6
        },
        2: {
            name: 'Digital_font2',
            letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.:!?"/()=\'',
            slope: -0.5,
            width: 10,
            height: 10,
            stepH: 0,
            stepV: 0,
            E: 9,
            F: 9,
            I: 5,
            L: 9,
            M: 16,
            T: 9,
            W: 16,
            X: 9,
            Y: 11,
            Z: 8,
            0: 9,
            1: 6,
            3: 9,
            5: 11,
            7: 9,
            8: 9,
            '.': 5,
            ':': 4,
            '!': 4,
            '?': 8,
            '(': 6,
            ')': 6,
            '=': 8,
            "'": 4,
            '"': 7
        }
    }
}
