import { VMKPassWindow } from '../../ui/windows/rooms/VMKPassWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class QueueExpired extends ServerEvent {
    static readonly type = MessageType.QUEUE_EXPIRED

    async handle(data: { name: string }) {
        VMKPassWindow.instance?.destroy()
    }
}
