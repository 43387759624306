import { EMagicEffectTypes } from '@vmk-legacy/common-ts'
import type { MagicDef } from '@vmk-legacy/render-utils'
import { Limb, LimbAnimation } from '@vmk-legacy/render-utils'
import { BLEND_MODES } from 'pixi.js'

export class MagicDefinitions {
    static raftTotalPivotY = -20
    static raftAvPivotY = 35
    static defs: { [k: number]: MagicDef } = {
        [EMagicEffectTypes.YzmaCart]: {
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideShadow: true,
                avPivotY: 30,
                framesBack: [
                    {
                        format: 'YzmaCart_back_%d',
                        num: 1
                    }
                ],
                framesFront: [
                    {
                        format: 'YzmaCart_front_%d',
                        num: 1
                    }
                ]
            }
        },
        [EMagicEffectTypes.PoofTeleMagic]: {
            word: 'Disappear',
            intro: {
                hideAvatar: true,
                framesFront: [
                    {
                        format: 'pinkcloud_%f',
                        num: 6
                    }
                ],
                sound: 'MFX-bat-1'
            },
            magic: {
                hideAvatar: true
            },
            outro: {
                framesFront: [
                    {
                        format: 'pinkcloud_%f',
                        num: 5
                    }
                ],
                sound: 'MFX-bat-1'
            }
        },
        [EMagicEffectTypes.Lightning]: {
            word: 'Zap',
            magic: {
                sound: 'Teleport-electricity',
                framesFront: [
                    {
                        format: 'lightningSpell_glow',
                        num: 1,
                        blend: BLEND_MODES.SCREEN,
                        scaleXPulse: true
                    },
                    {
                        format: 'lightning_0_%f',
                        num: 4
                    }
                ]
            }
        },
        [EMagicEffectTypes.WinifredsCurse]: {
            word: 'Winifred',
            magic: {
                framesFront: [
                    {
                        format: 'lightningSpell_glow',
                        num: 1,
                        blend: BLEND_MODES.SCREEN,
                        scaleXPulse: true
                    },
                    {
                        format: 'lightning_0_%f',
                        num: 4,
                        tint: 0x00ff14
                    }
                ]
            }
        },
        [EMagicEffectTypes.Confetti]: {
            word: 'Confetti',
            intro: {
                sound: 'MFX-confette'
            },
            magic: {
                framesFront: [
                    {
                        format: 'confetti_magic_%f',
                        num: 10
                    }
                ]
            }
        },
        [EMagicEffectTypes.Invisibility]: {
            word: 'Spooky',
            invisible: 'others',
            intro: {
                sound: 'MFX-ghost'
            },
            magic: {
                alpha: 0.5,
                framesFront: [
                    {
                        format: 'magic_glow',
                        num: 1,
                        blend: BLEND_MODES.SCREEN,
                        scaleXPulse: true
                    }
                ]
            }
        },
        [EMagicEffectTypes.Swimming]: {
            pivotY: -70,
            magic: {
                headOnly: true,
                framesBack: [
                    {
                        format: 'magic_swimming_%d_%f',
                        num: 4
                    }
                ],
                hideShadow: true
            }
        },
        [EMagicEffectTypes.TurnIntoBat]: {
            word: 'Dracula',
            intro: {
                hideAvatar: true,
                framesFront: [
                    {
                        format: 'magic_batcloud_%f',
                        num: 6
                    }
                ],
                sound: 'MFX-bat-1'
            },
            magic: {
                hideAvatar: true,
                framesFront: [
                    {
                        format: 'magic_bat_%d_%f',
                        num: 4
                    }
                ],
                sound: 'MFX-bat-2'
            }
        },
        [EMagicEffectTypes.DancingInferno]: {
            word: 'Inferno',
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Dance
                }
            ],
            intro: { sound: 'MFX-discoinferno' },
            magic: {
                framesFront: [
                    {
                        format: 'magic_inferno_%f',
                        num: 21,
                        blend: BLEND_MODES.ADD
                    }
                ]
            }
        },
        [EMagicEffectTypes.Fireworks]: {
            word: 'Rocket',
            intro: { sound: 'MFX-firework' },
            magic: {
                framesFront: [
                    {
                        format: 'magic_fireworks_%f',
                        num: 14,
                        tint: 0xff0000
                    }
                ]
            }
        },
        [EMagicEffectTypes.BuriedTreasure]: {
            word: 'BlingBling',
            magic: {
                sound: 'MFX-moneyrain',
                framesBack: [
                    {
                        format: 'magic_goldpile_BACK_%f',
                        num: 5,
                        loop: false
                    }
                ],
                framesFront: [
                    {
                        format: 'magic_glow',
                        num: 1,
                        blend: BLEND_MODES.SCREEN
                    },
                    {
                        format: 'magic_goldpile_FRONT_%f',
                        num: 5,
                        loop: false
                    },
                    {
                        format: 'magic_fallingcoin_%f',
                        num: 11
                    }
                ]
            }
        },
        [EMagicEffectTypes.Snowman]: {
            word: 'Frosty',
            intro: { sound: 'MFX-snowman' },
            magic: {
                framesFront: [
                    {
                        format: 'magic_snowman_%f',
                        num: 13,
                        loop: false
                    },
                    {
                        format: 'magic_snowfall_%f',
                        num: 11,
                        loop: true
                    }
                ]
            }
        },
        [EMagicEffectTypes.Driving]: {
            word: 'Drive',
            preventAnims: [LimbAnimation.Move],
            intro: { sound: 'MFX-car-start-1' },
            magic: {
                sound: 'MFX-car-loop-1',
                hideLegs: true,
                framesBack: [
                    {
                        format: 'wlk_carback_%p_%d_%f',
                        num: {
                            115: 2,
                            default: 4
                        },
                        walkAnimOnly: true
                    }
                ],
                framesFront: [
                    {
                        format: 'wlk_carfront_%p_%d_%f',
                        num: {
                            115: 2,
                            default: 4
                        },
                        walkAnimOnly: true
                    }
                ]
            }
        },
        [EMagicEffectTypes.PirateCostume]: {
            word: 'YohoYoho',
            outfit: {
                hat: '274d522dec',
                shirt: '8f8aef85aa',
                pants: 'fbc31fdbf2',
                shoes: '821b33d976'
            },
            magic: {
                sound: 'MFX-pirate-parrot-curse',
                framesFront: [
                    {
                        format: 'magic_pirate_%f',
                        num: 10,
                        blend: BLEND_MODES.SCREEN
                    }
                ]
            }
        },
        [EMagicEffectTypes.Sparkles]: {
            word: 'Wondrous',
            intro: {
                sound: 'MFX-wondrous'
            },
            magic: {
                framesFront: [
                    {
                        format: 'magic_pixie_%f',
                        num: 10
                    }
                ]
            }
        },
        [EMagicEffectTypes.CursedStorm]: {
            word: 'Storm',
            rotating: true,
            magic: {
                sound: 'MFX-curse-storm',
                framesFront: [
                    {
                        format: 'magic_tornado_%f',
                        num: 6
                    },
                    {
                        format: 'magic_stormcloud_%f',
                        num: 4
                    },
                    {
                        format: 'magic_lightning_%f',
                        speed: 0.025,
                        num: 2
                    }
                ]
            }
        },
        [EMagicEffectTypes.Levitation]: {
            word: 'Levitate',
            intro: {
                sound: 'MFX-levitate'
            },
            magic: {
                avPivotY: 30,
                avFloatHeight: 15,
                avFloatDelta: 5,
                framesFront: [
                    {
                        format: 'magic_levitation_%f',
                        num: 12
                    }
                ]
            }
        },
        [EMagicEffectTypes.FlyingCarpet]: {
            word: 'Woosh',
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                avPivotY: 10,
                avFloatHeight: 10,
                avFloatDelta: 5,
                framesFront: [
                    {
                        format: 'magic_carpet_%d_%f',
                        num: 9
                    }
                ]
            }
        },
        [EMagicEffectTypes.CoralCostume]: {
            word: 'Ahoy',
            outfit: {
                hat: 'e42b701cac',
                shirt: '27153eaa54',
                pants: '99ceafef05',
                shoes: '3944a4b1f0'
            },
            magic: {
                sound: 'MFX-coral-1',
                framesFront: [
                    {
                        format: 'magic_pirate_%f',
                        num: 10,
                        blend: BLEND_MODES.SCREEN
                    }
                ]
            }
        },
        [EMagicEffectTypes.DivingSuit]: {
            word: 'Dive',
            outfit: {
                hat: '13ce62a3d5',
                shirt: 'cd9823d849',
                pants: '0d2ee9e3de',
                shoes: '2df0e6f762'
            },
            magic: {
                sound: 'SPS-scuba-breath',
                avPivotY: 20,
                avFloatHeight: 10,
                avFloatDelta: 5,
                framesFront: [
                    {
                        format: 'bubbles_%d_%f',
                        num: 5
                    }
                ]
            }
        },
        [EMagicEffectTypes.AliceCaterpillar]: {
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideLegs: true,
                hideShadow: true,
                framesBack: [
                    {
                        format: 'alicecar_back_0_%d',
                        num: 1,
                        tint: 'shirt'
                    },
                    {
                        format: 'alicecar_back_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ],
                framesFront: [
                    {
                        format: 'alicecar_front_0_%d',
                        num: 1,
                        tint: 'shirt'
                    },
                    {
                        format: 'alicecar_front_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ]
            }
        },
        [EMagicEffectTypes.PeterPanPirateShip]: {
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideLegs: true,
                hideShadow: true,
                framesBack: [
                    {
                        format: 'ppship_back_0_%d',
                        num: 1
                    },
                    {
                        format: 'ppship_back_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ],
                framesFront: [
                    {
                        format: 'ppship_front_0_%d',
                        num: 1
                    },
                    {
                        format: 'ppship_front_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ]
            }
        },
        [EMagicEffectTypes.TyphoonRaft]: {
            pivotY: this.raftTotalPivotY,
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideShadow: true,
                avPivotY: this.raftAvPivotY,
                framesBack: [
                    {
                        format: 'magic_rafttl_back_%d_%f',
                        num: 2
                    }
                ],
                framesFront: [
                    {
                        format: 'magic_rafttl_front_%d_%f',
                        num: 2
                    }
                ]
            }
        },
        [EMagicEffectTypes.BlizzardRaft]: {
            pivotY: this.raftTotalPivotY,
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideShadow: true,
                avPivotY: this.raftAvPivotY,
                framesBack: [
                    {
                        format: 'magic_raftbb_back_%d_%f',
                        num: 2
                    }
                ],
                framesFront: [
                    {
                        format: 'magic_raftbb_front_%d_%f',
                        num: 2
                    }
                ]
            }
        },
        [EMagicEffectTypes.TyphoonDonut]: {
            pivotY: this.raftTotalPivotY,
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideShadow: true,
                avPivotY: this.raftAvPivotY,
                framesBack: [
                    {
                        format: 'magic_donuttl_back_%d_%f',
                        num: 5
                    }
                ],
                framesFront: [
                    {
                        format: 'magic_donuttl_front_%d_%f',
                        num: 5
                    }
                ]
            }
        },
        [EMagicEffectTypes.BlizzardDonut]: {
            pivotY: this.raftTotalPivotY,
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideShadow: true,
                avPivotY: this.raftAvPivotY,
                framesBack: [
                    {
                        format: 'magic_donutbb_back_%d_%f',
                        num: 5
                    }
                ],
                framesFront: [
                    {
                        format: 'magic_donutbb_front_%d_%f',
                        num: 5
                    }
                ]
            }
        },
        [EMagicEffectTypes.CarnivalMidway]: {
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Stand,
                    limbs: [Limb.ArmLeft, Limb.ArmRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideLegs: true,
                hideShadow: true,
                framesBack: [
                    {
                        format: 'tsmcar_back_%d',
                        num: 1
                    }
                ],
                framesFront: [
                    {
                        format: 'tsmcar_front_%d',
                        num: 1
                    }
                ]
            }
        },
        [EMagicEffectTypes.RoyalCarriage]: {
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideShadow: true,
                avPivotY: 35,
                framesBack: [
                    {
                        format: 'royalcar_back_0_%d',
                        num: 1
                    },
                    {
                        format: 'royalcar_back_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ],
                framesFront: [
                    {
                        format: 'royalcar_front_0_%d',
                        num: 1
                    },
                    {
                        format: 'royalcar_front_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ]
            }
        },
        [EMagicEffectTypes.RoyalGazebo]: {
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Stand,
                    limbs: [Limb.ArmLeft, Limb.ArmRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                avPivotY: 25,
                hideShadow: true,
                framesBack: [
                    {
                        format: 'royalgazebo_back_0_3',
                        num: 1
                    }
                ],
                framesFront: [
                    {
                        format: 'royalgazebo_front_0_3',
                        num: 1
                    }
                ]
            }
        },
        [EMagicEffectTypes.RoyalThrone]: {
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Sit,
                    limbs: [Limb.LegLeft, Limb.LegRight]
                }
            ],
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideShadow: true,
                avPivotY: 50,
                framesBack: [
                    {
                        format: 'royalthrone_back_0_%d',
                        num: 1
                    },
                    {
                        format: 'royalthrone_back_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ],
                framesFront: [
                    {
                        format: 'royalthrone_front_0_%d',
                        num: 1
                    },
                    {
                        format: 'royalthrone_front_1_%d',
                        num: 1,
                        tint: 'shirt'
                    }
                ]
            }
        },
        [EMagicEffectTypes.SpaceRocket]: {
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideLegs: true,
                hideShadow: true,
                framesBack: [
                    {
                        format: 'magic_rocket_back_%d',
                        num: 1
                    }
                ],
                framesFront: [
                    {
                        format: 'magic_rocket_front_%d',
                        num: 1
                    }
                ]
            }
        },
        [EMagicEffectTypes.FlumeLog]: {
            preventAnims: [LimbAnimation.Move],
            pivotY: -15,
            magic: {
                hideLegs: true,
                hideShadow: true,
                framesFront: [
                    {
                        format: 'log_%d',
                        num: 1
                    }
                ]
            }
        },
        [EMagicEffectTypes.Everest]: {
            preventAnims: [LimbAnimation.Move],
            magic: {
                hideLegs: true,
                hideShadow: true,
                framesBack: [
                    {
                        format: 'magic_everestcar_back_%d',
                        num: 1
                    }
                ],
                framesFront: [
                    {
                        format: 'magic_everestcar_front_%d',
                        num: 1
                    }
                ]
            }
        },
        [EMagicEffectTypes.CarryItem]: {
            outfit: {
                heldLeft: '%p'
            },
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Carry,
                    limbs: [Limb.ArmLeft]
                }
            ]
        },
        [EMagicEffectTypes.DrinkItem]: {
            outfit: {
                heldLeft: '%p'
            },
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Drink,
                    limbs: [Limb.ArmLeft, Limb.MouthAndNose]
                }
            ]
        },
        [EMagicEffectTypes.EatItem]: {
            outfit: {
                heldLeft: '%p'
            },
            forceAvatarAnims: [
                {
                    anim: LimbAnimation.Eat,
                    limbs: [Limb.ArmLeft, Limb.MouthAndNose]
                }
            ]
        }
    }

    static getEffectForWord(word: string): string | undefined {
        if (!word) {
            return undefined
        }
        word = word.toLowerCase().replace('!', '')

        for (const fx in this.defs) {
            if (this.defs[fx]?.word && this.defs[fx].word.toLowerCase() === word) {
                return fx
            }
        }

        return undefined
    }
}
