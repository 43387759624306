import type { gsap } from 'gsap'
import { Client } from '../../Client.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import type { AvatarTooltip } from '../../ui/AvatarTooltip.js'
import { RoomViewer } from '../renderer/RoomViewer.js'
import type { TileEntity } from '../renderer/types/TileEntity.js'
import type { WalkableRoomViewer } from '../renderer/WalkableRoomViewer.js'
import type { RoomEntityVisualizer } from '../RoomObject.js'
import { RoomEntity } from '../RoomObject.js'
import { AvatarVisual } from './AvatarVisual.js'

export interface TileBoundVisual {
    setTileSize(size: number): void
    startWalking(): void
    stopWalking(): void
}

/**
 * A room entity which can walk around tiles.
 */
export abstract class WalkableEntity<Visual extends RoomEntityVisualizer & TileBoundVisual> extends RoomEntity<Visual> {
    tile?: TileEntity
    protected tooltip: AvatarTooltip | null
    entryAnim?: (number | string)[]
    tweener?: gsap.core.Tween
    currentPath: number[] = []
    moveStartX?: number = null
    moveStartY?: number = null
    moveDiffX?: number
    moveDiffY?: number
    moveStart?: number

    constructor(
        override readonly roomContext: RoomViewer,
        id: EntityRoomIdentifier,
        protected name = '',
        protected tileRef: number,
        protected direction: number,
        protected actions: {
            wave: boolean
            move: boolean
            steer: boolean
            sit: boolean
            dance: boolean
            carry: boolean
            eat: boolean
        }
    ) {
        super(roomContext, id)

        if (this.visual) {
            this.visual.applyServerActions(actions)
            this.visual.setDirection(direction)
            this.visual.setTileSize(roomContext.getTilesize())
        }
    }

    isWalking(): boolean {
        return this.currentPath.length > 0
    }

    startWalkingPath(path: number[]): void {
        console.log('walking path:', path)
        if (this.visual instanceof AvatarVisual) {
            this.visual.setHeadDirection(undefined)
        }
        this.moveStartX = null
        this.moveStartY = null
        this.visual.startWalking()
        this.currentPath = path
        this.walkTick()
    }

    walkTick = (percentSkip = 0): void => {
        const nextTile = this.currentPath.shift()
        if (nextTile) {
            const room = this.roomContext as WalkableRoomViewer
            const destTile = room.tiles.get(nextTile)
            if (!destTile) {
                return
            }

            this.moveStartX = this.visual.x
            this.moveStartY = this.visual.y

            this.moveDiffX = (destTile.x - this.visual.x) * (1 - percentSkip)
            this.moveDiffY = (destTile.y - destTile.getTotalHeight() - this.visual.y) * (1 - percentSkip)

            this.moveStart = Date.now()

            const lastPos = room.tiles.get(this.tileRef)
            this.tileRef = nextTile
            destTile.addOccupant(this)

            const deltaX =
                destTile.getFloor().point.get3DX() +
                destTile.getMapX() -
                (lastPos.getFloor().point.get3DX() + lastPos.getMapX())
            const deltaY =
                destTile.getFloor().point.get3DY() +
                destTile.getMapY() -
                (lastPos.getFloor().point.get3DY() + lastPos.getMapY())
            let dir = null

            if (deltaY < 0 && deltaX < 0) {
                dir = 0 // N
            } else if (deltaY < 0 && deltaX === 0) {
                dir = 1 // NE
            } else if (deltaY < 0 && deltaX > 0) {
                dir = 2 // E
            } else if (deltaY === 0 && deltaX > 0) {
                dir = 3 // SE
            } else if (deltaY > 0 && deltaX > 0) {
                dir = 4 // S
            } else if (deltaY > 0 && deltaX === 0) {
                dir = 5 // SW
            } else if (deltaY > 0 && deltaX < 0) {
                dir = 6 // W
            } else if (deltaY === 0 && deltaX < 0) {
                dir = 7 // NW
            }
            if (dir !== null) {
                this.setDirection(dir)
            }
            room.sprites.sortChildren()
        } else {
            this.moveStartX = null
            this.moveStartY = null
            this.moveStart = null
            this.visual.stopWalking()
        }
    }

    update(dt: number): void {
        if (this.moveStartX !== null) {
            const percent = (Date.now() - this.moveStart) / 400

            this.visual.x = this.moveStartX + this.moveDiffX * Math.min(1, percent)
            this.visual.y = this.moveStartY + this.moveDiffY * Math.min(1, percent)

            this.roomContext.updateTooltip(this)
            this.roomContext.updateArrow(this)

            if (percent >= 1) {
                this.walkTick(percent - 1)
            }
        }
    }

    jumpToPosition(): void {
        const avi = this.visual
        if (!avi) {
            return
        }
        const room = this.roomContext as WalkableRoomViewer
        const tile = room.tiles.get(this.getTileRef())

        if (!tile) {
            return
        }

        const destY = tile.y - tile.getTotalHeight()

        tile.addOccupant(this)

        room.sprites.sortChildren()

        avi.position.set(tile.x, destY)
        avi.updateTransform()

        this.roomContext.updateArrow(this)
    }

    getTooltipPosition(): { x: number; y: number } {
        let offset = 120
        const tilesize = this.roomContext.getTilesize()

        if (tilesize === 32) {
            offset = 130
        } else if (tilesize === 24) {
            offset = 105
        } else if (tilesize === 16) {
            offset = 82
        }

        return {
            x: RoomViewer.roomWidth / 2 + this.visual.x,
            y: RoomViewer.roomHeight / 2 + this.visual.y - offset
        }
    }

    getTileRef(): number {
        return this.tileRef
    }

    setTile(tile: TileEntity): void {
        this.tile = tile
        this.visual.zIndex = (tile.getHighestPart()?.zIndex || tile.zIndex) + 1
    }

    getTile(): TileEntity | undefined {
        return this.tile
    }

    getDirection(): number {
        return this.direction
    }

    setTileRef(ref: number): void {
        this.tileRef = ref
    }

    setDirection(dir: number): void {
        if (typeof dir !== 'number') {
            throw new Error('setDirection called with undefined direction.')
        }
        this.direction = dir
        this.visual.setDirection(dir)
    }

    setHovering(hovering = true): void {
        if (hovering) {
            this.roomContext.showTooltipFor(this)
        } else {
            this.roomContext.hideTooltipFor(this)
        }
    }

    setName(ign: string): void {
        this.name = ign
    }

    isSelf(): boolean {
        return this.id === Client.shared.selfRecord.getRoomIdentifier()
    }

    getRoomIdentifier(): EntityRoomIdentifier {
        return this.id
    }

    override getName(): string {
        return this.name
    }

    repairStacked(): void {
        if (!this.tile || this.tile.destroyed) {
            return
        }
        const avi = this.visual
        if (avi && !avi.destroyed) {
            avi.y = this.tile.y - this.tile.getTotalHeight()
            avi.x = this.tile.x
            avi.zIndex = this.tile.zIndex + this.tile.getTotalHeight() + 1
        }
    }

    destroy(): void {
        this.roomContext?.hideTooltipFor(this)
        this.tweener?.kill()
        this.visual?.destroy()
    }
}
