import type { AssetProvider } from '@vmk-legacy/render-utils'
import { buildClothingItem, Pooler } from '@vmk-legacy/render-utils'
import { Container, Sprite } from 'pixi.js'
import { Client } from '../../Client.js'

export class ThumbPresenter extends Container {
    protected thumbImage: Sprite | Container
    protected pickerBg: Sprite
    protected index = 0
    protected sprites: (Container | Sprite)[]
    parent: Container
    protected items: {
        id: string | number
        type: string
        index: number
    }[]

    constructor(
        readonly provider: AssetProvider,
        items: {
            id: string | number
            type: string
            index: number
        }[],
        sprites: Sprite[] | Container[] = []
    ) {
        super()

        this.items = items.map((i) => {
            return {
                id: String(i.id),
                type: i.type,
                index: i.index
            }
        })

        this.sprites = sprites

        this.pickerBg = Pooler.newSprite('char.part.viewer')
        this.addChild(this.pickerBg)
        this.pickerBg.x = 17

        this.updateThumb()
    }

    setItem(id: string | number): this {
        id = String(id)
        const foundIndex = this.items.findIndex((i) => i.id === id)
        this.index = foundIndex
        this.updateThumb()
        return this
    }

    presentThumb(index: number): void {
        this.index = index
        this.updateThumb()
    }

    updateThumb(): void {
        if (!this.parent) {
            return
        }
        const i = this.index
        if (this.sprites[i]) {
            this.setThumbImage(this.sprites[i])
        } else {
            const defUid = this.items[i].id
            buildClothingItem(defUid.toString(), 3, Client.shared.figuresMgr, this.provider).then((container) => {
                this.setThumbImage(container)
            })
        }
    }

    setThumbImage(image: Sprite | Container): void {
        if (!image) {
            image = Pooler.newSprite()
        }
        if (this.thumbImage && this.thumbImage !== image) {
            Pooler.release(this.thumbImage)
        }
        const canvas = Client.shared.renderer.extract.canvas(image) as HTMLCanvasElement
        this.thumbImage = Sprite.from(canvas)
        this.addChild(this.thumbImage)

        const size = 36
        const sA: number = size / size
        const pA: number = this.thumbImage.width / this.thumbImage.height

        let sF: number
        if (sA > pA) {
            sF = size / this.thumbImage.height
        } else {
            sF = size / this.thumbImage.width
        }
        this.thumbImage.scale.set(sF)

        this.thumbImage.x = this.pickerBg.x + this.pickerBg.width / 2 - this.thumbImage.width / 2
        this.thumbImage.y = this.pickerBg.y + this.pickerBg.height / 2 - this.thumbImage.height / 2
    }
}
