import type { PotcEngineEntity, PotcEntityKind, Vector } from '@vmk-legacy/potc-engine'
import type { RoomEntityVisualizer } from '../../room/RoomObject.js'
import { ERoomObjType, RoomEntity } from '../../room/RoomObject.js'
import type { PotcScene } from './PotcScene.js'
import type { PotcVisualBase } from './PotcVisualBase.js'

export abstract class PotcFactorBase<T extends RoomEntityVisualizer = PotcVisualBase>
    extends RoomEntity<T>
    implements PotcEngineEntity
{
    readonly entityType = ERoomObjType.Avatar
    readonly boundsType: 'circle' | 'triplecircle' | 'box' | 'point' | 'none'
    readonly kind: PotcEntityKind
    state: any
    readonly windAffects: boolean
    worldPosition: Vector

    constructor(
        public roomContext: PotcScene,
        instanceId: number,
        readonly name: string
    ) {
        super(roomContext, instanceId)
    }

    stateUpdated(changes: any): void {}

    // sprZToScreenSpace(sprites: Sprite[], zLayer: number) {
    // 	const pos = this.worldPosition;
    //
    // 	const testPos = new Vec3(pos.x / 100, pos.y / 100, pos.z / 100);
    // 	testPos.add(new Vec3(0, 0, zLayer));
    //
    // 	this.setAbsPos(testPos);
    //
    // }
    //
    // setAbsPos(pos: Vec3) {
    // 	this.visual.position.copyFrom(PiratesScene.axonometricToLocal(pos));
    // }
}
