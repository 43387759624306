import type { Animation } from '../../Animation.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class TreasureRoomHandler extends WalkableRoomExtension {
    static override identifier = 'treasure_room'

    animation: Animation

    async init() {
        const bronzeScore = this.room.animationMap.get('vmk_anim_7').score // right
        const silverScore = this.room.animationMap.get('vmk_anim_5').score // left
        const goldScore = this.room.animationMap.get('vmk_anim_6').score // mid
        /*

        "vmk_anim_1": "treasure_room_flame1.ani",
        "vmk_anim_2": "treasure_room_flame2.ani",
        "vmk_anim_5": "left_chest_anim.ani",
        "vmk_anim_6": "mid_chest_anim.ani",
        "vmk_anim_7": "right_chest_anim.ani"
             */

        bronzeScore.autoplays = bronzeScore.loops = false
        silverScore.autoplays = silverScore.loops = false
        goldScore.autoplays = goldScore.loops = false

        bronzeScore.goToMarker('right_chest_idle')
        silverScore.goToMarker('left_chest_idle')
        goldScore.goToMarker('mid_chest_idle')

        return this
    }
}
