import { Pooler } from '@vmk-legacy/render-utils'
import { Container } from 'pixi.js'

export class Field extends Container {
    readonly SUPPORTED_TYPES: string[] = ['b', 'f', 'h']

    constructor(type: string, innerWidth: number) {
        super()

        type = type.toLowerCase()
        if (!this.SUPPORTED_TYPES.includes(type)) {
            throw new Error('Unsupported field type ' + type)
        }

        const bgLeft = Pooler.newSprite('field.' + type + '.left')
        const bgMid = Pooler.newSprite('field.' + type + '.middle')
        const bgRight = Pooler.newSprite('field.' + type + '.right')

        bgMid.x = bgLeft.width
        bgMid.width = innerWidth
        bgRight.x = bgMid.x + bgMid.width

        this.addChild(bgLeft, bgMid, bgRight)
    }
}
