import { TintColor } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import { BitmapText, Container, Graphics, Point } from 'pixi.js'
import { Constants } from '../Constants.js'
import { Fonts } from '../Fonts.js'
import { TextRenderer } from '../ui/TextRenderer.js'

export class ChatBubble extends Container {
    xAnchor = 0.5

    constructor(
        readonly sender: string,
        private message: string,
        readonly colorId: string | number = 182,
        readonly spikeLeft = false,
        readonly bgAlpha = 1
    ) {
        super()
    }

    setMessage(message: string): void {
        this.message = message
        this.removeChildren()
        this.load()
    }

    async load(): Promise<void> {
        const senderText = new BitmapText(this.sender ? this.sender + ':' : '', {
            ...Fonts.FoxleyBold_16,
            tint: 0x000000
        })
        senderText.position.set(6, 2)

        const messageText = Pooler.newSprite(
            await TextRenderer.get(this.message, Constants.SIZE[0] - senderText.textWidth - 28)
        )
        messageText.position.set(senderText.x + Math.round(senderText.textWidth) + 6, 0)

        const totalTextWidth = senderText.textWidth + messageText.width + 6
        const tint = TintColor[this.colorId] || this.colorId

        console.log('From color ID', this.colorId, ' using tint:', tint)

        const bubbleOuterWidth = totalTextWidth + 18
        const textHeight = messageText.height + 1

        const whiteBack = new Graphics()
        whiteBack.beginFill(0xefefef, this.bgAlpha)
        whiteBack.drawRoundedRect(0, 0, bubbleOuterWidth, textHeight + 2, 8)
        whiteBack.endFill()
        this.addChild(whiteBack)

        this.addChild(senderText)
        this.addChild(messageText)

        const outline = new Graphics()
        outline.lineStyle({
            width: 2,
            color: tint,
            alpha: this.bgAlpha,
            alignment: 0
        })
        outline.drawRoundedRect(0, 0, bubbleOuterWidth, textHeight + 2, 8)
        this.addChild(outline)

        const center = this.spikeLeft ? 10 : Math.round(bubbleOuterWidth / 2)
        const spikeBg = new Graphics()
        spikeBg.beginFill(tint, this.bgAlpha)
        spikeBg.drawPolygon([new Point(0, 0), new Point(0, 8), new Point(9, 0)])
        spikeBg.endFill()
        this.addChild(spikeBg)
        spikeBg.y = textHeight
        spikeBg.x = center - 4

        const spikeInner = new Graphics()
        spikeInner.beginFill(0xefefef, this.bgAlpha)
        spikeInner.drawPolygon([new Point(2, 0), new Point(2, 4), new Point(6, 0)])
        spikeInner.endFill()
        this.addChild(spikeInner)
        spikeInner.y = textHeight
        spikeInner.x = center - 4
    }
}
