import type { EPotcTeam, PotcEngineShipEntity, PotcShipType, Vector } from '@vmk-legacy/potc-engine'
import { EPotcAmmo, PotcEntityKind } from '@vmk-legacy/potc-engine'
import type { RoomEntity } from '../../room/RoomObject.js'
import { PotcFactorBase } from './PotcFactorBase.js'
import type { PotcScene } from './PotcScene.js'
import { PotcVisualShip } from './PotcVisualShip.js'

export class PotcFactorShip extends PotcFactorBase<PotcVisualShip> implements PotcEngineShipEntity, RoomEntity {
    visual: PotcVisualShip

    readonly boundsType = 'triplecircle'
    readonly bowCannonOffset = 2000
    readonly firstOffset = 1500
    readonly firstRadius = 1000
    readonly largestRadius = 2500
    readonly navigationStopRadius = 1423
    readonly secondRadius = 1500
    readonly sideCannonOffset = 1000
    readonly thirdOffset = -1500
    readonly thirdRadius = 1000
    readonly windAffects: boolean = true
    collideCount = 0

    ammo = [EPotcAmmo.Regular, EPotcAmmo.Regular, EPotcAmmo.Regular, EPotcAmmo.Regular, EPotcAmmo.Regular]

    direction: number
    id: number
    lastMove: number
    maxAmmo: number
    maxHealth: number
    health: number
    navigationTarget: Vector
    needToSync: boolean
    speed: number
    state: {
        move: boolean
        destroyed: boolean
        floatingWreck: boolean
        slow: boolean
        shoot: boolean
        invincibility: boolean
        carryingOwnFlag: boolean
        carryingEnemyFlag: boolean
        rowingBoat: boolean
    } = {}
    turningRate: number
    velocity: Vector
    worldPosition: Vector

    constructor(
        roomContext: PotcScene,
        readonly team: EPotcTeam,
        instanceId: number,
        ign: string,
        public type: PotcShipType
    ) {
        super(roomContext, instanceId, ign)

        this.visual = this.createVisual()
        roomContext.sprites.addChild(this.visual)
        console.log('constructed factor ship with type ' + this.type)
    }

    positionUpdated(): void {
        if (!this.state.move) {
            this.roomContext.navTarget.visible = false
        }
    }

    stateUpdated(changes: [string, boolean][]): void {
        //
    }

    protected createVisual(): PotcVisualShip {
        if (!this.type) {
            return
        }
        console.log('about to create visual ship with type ' + this.type)

        const visual = new PotcVisualShip(this.roomContext, this.type, this.team, this.name)

        visual.factor = this

        return visual
    }

    readonly kind = PotcEntityKind.Ship

    update(): void {
        //
    }
}
