import type { ShopItem } from './ShopItem'

export class ShopCategory {
    private name: string
    private items: ShopItem[]
    private logoId: number

    constructor(name: string, items: ShopItem[], logoId = 6) {
        this.name = name
        this.items = items
        this.logoId = logoId
    }

    getLogoId(): number {
        return this.logoId
    }

    getName(): string {
        return this.name
    }

    getItems(): ShopItem[] {
        return this.items
    }
}
