import { Client } from '../../Client.js'
import { EWindow, UILayer } from '../../enums.js'
import { RoomLoadingView } from '../../ui/views/RoomLoadingView.js'
import { VMKPassWindow } from '../../ui/windows/rooms/VMKPassWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import { RoomJoinModule } from './RoomJoinModule.js'

export class RoomQueueModule extends ServerEvent {
    static readonly type = MessageType.ROOM_QUEUE

    async handle(data: { name: string; place: number }) {
        if (VMKPassWindow.instance) {
            Client.shared.userInterface.bringToFront(VMKPassWindow.instance)
            VMKPassWindow.instance.setQueuePlace(data.place)
            return
        }

        if (RoomJoinModule.loader) {
            RoomJoinModule.loader.destroy()
        }

        Client.shared.userInterface.closeWindows(UILayer.GameWindows)

        RoomJoinModule.loader = new RoomLoadingView(data.name, data.place)
        Client.shared.userInterface.register(RoomJoinModule.loader)

        Client.shared.roomViewer?.teardown()

        if (Client.shared.userInterface?.toolbar) {
            Client.shared.userInterface.toolbar.chatbarInput.setValue('')
        }

        Client.shared.userInterface.closeWindow(EWindow.Navigator)
        Client.shared.userInterface.toolbar.disableAll()
    }
}
