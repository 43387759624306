import { EItemType } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container, Sprite, Texture } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { getText } from '../../../../assets/ExternalConfigManager.js'
import { PinCache } from '../../../../assets/PinCache.js'
import { Client } from '../../../../Client.js'
import type { DummyItem, ItemStack } from '../../../../data/ItemStack.js'
import { Fonts } from '../../../../Fonts.js'
import { PrefTemp } from '../../../../SessionPrefs.js'
import { Helpers } from '../../../../util/Helpers.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import type { ImageButton } from '../../../buttons/ImageButton.js'
import { ThumbnailBox } from '../../../containers/ThumbnailBox.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { Separator } from '../../Separator.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { InventoryWindow } from '../InventoryWindow.js'

export class PinsView extends UIWindowView<InventoryWindow> {
    inventoriedThumbnails: ThumbnailBox[]
    lanyardThumbnails: ThumbnailBox[]

    private ptb: PagedThumbnailBox

    private readonly LANYARD_ROWS: number = 3
    private readonly LANYARD_COLS: number = 5

    private pageIndicatorText: BitmapText
    private itemName: BitmapText

    pageIndex = 0

    selectedItem: ItemStack = null
    private combining?: ItemStack

    private pageTurnL: ImageButton
    private pageTurnR: ImageButton

    numPages = 1

    private itemPreviewImage: Sprite

    private wearBtn: BitmapTextButton
    private takeOffBtn: BitmapTextButton
    private combineBtn: BitmapTextButton
    private sellBtn: BitmapTextButton
    private pinTypeIcon: Sprite
    private pinType: BitmapText

    update() {
        const pins = [...Client.shared.selfRecord.getInventory().values()].filter((i) => i.defType === EItemType.Pin)
        pins.sort((a, b) => {
            const nameA = a.getName().toLowerCase()
            const nameB = b.getName().toLowerCase()

            if (nameA === nameB) {
                return 0
            }

            return nameA > nameB ? 1 : -1
        })

        const lanyardPins = Client.shared.selfRecord.getLanyard()

        this.ptb.setItems(...pins)

        for (let i = 0; i < lanyardPins.length; i++) {
            const pin = lanyardPins[i]
            if (i >= 0 && i < this.LANYARD_COLS * this.LANYARD_ROWS) {
                this.lanyardThumbnails[i].eventMode = 'static'
                this.lanyardThumbnails[i].removeAllListeners('pointerup')
                this.lanyardThumbnails[i].addEventListener('pointerup', () => {
                    this.lanyardThumbnails.forEach((thumb: ThumbnailBox) => {
                        thumb.setSelected(false)
                    })
                    this.inventoriedThumbnails.forEach((thumb: ThumbnailBox) => {
                        thumb.setSelected(false)
                    })
                    this.lanyardThumbnails[i].setSelected(true)
                    this.selectItem(pin)

                    if (new Date().getTime() - this.lanyardThumbnails[i].getLastTapMS() < 500) {
                        this.takeOffPin(this.lanyardThumbnails[i].getSourceItem())
                        this.lanyardThumbnails[i].setLastTap(new Date(1970, 1))
                    } else {
                        this.lanyardThumbnails[i].setLastTap(new Date())
                    }

                    // Selected lanyard pin... disable "Wear" button
                    this.wearBtn.disable()
                    this.takeOffBtn.enable()
                })
                this.lanyardThumbnails[i].setSourceItem(pin)

                this.lanyardThumbnails[i].showLoadingAnim()
                PinCache.instance.getTexture(pin.defUid, true).then((texture: Texture) => {
                    const baseItem = Pooler.newSprite(texture)

                    this.lanyardThumbnails[i].setThumbImage(baseItem)
                    this.lanyardThumbnails[i].setStars(pin.stars)
                    this.lanyardThumbnails[i].setSingleUse(pin.single)
                    this.lanyardThumbnails[i].hideLoadingAnim()
                })
            }
        }
        const lastLanyardIndex = lanyardPins.length
        for (let i = lastLanyardIndex; i < this.lanyardThumbnails.length; i++) {
            this.lanyardThumbnails[i].clear()
        }
    }

    constructor() {
        super()

        this.inventoriedThumbnails = []
        this.lanyardThumbnails = []

        this.itemName = new BitmapText('Select a pin to learn more', {
            ...Fonts.Foxley_16
        })
        this.itemName.x = Math.round((374 - this.itemName.textWidth) / 2)
        this.addChild(this.itemName)

        const sep = new Separator(374)
        sep.y = 15
        this.addChild(sep)

        const previewBox = Pooler.newSprite('previewbox')
        previewBox.scale.set(0.5)
        previewBox.x = 271
        previewBox.y = 20
        this.addChild(previewBox)

        this.pinType = new BitmapText('Normal Pin.', {
            ...Fonts.Foxley_16
        })
        this.pinType.x = 286
        this.pinType.y = 151
        this.addChild(this.pinType)

        this.pinTypeIcon = Pooler.newSprite('icon.singleuse')
        this.pinTypeIcon.position.set(270, 152)
        this.pinTypeIcon.visible = false
        this.addChild(this.pinTypeIcon)

        const lanyardY = 252
        const innerWidth = 355
        const innerHeight = 140

        const lanyardBoxTopLeft = Pooler.newSprite('content.top.left')
        this.addChild(lanyardBoxTopLeft)
        lanyardBoxTopLeft.y = lanyardY

        const lanyardBoxLeft = Pooler.newSprite('content.left.middle')
        lanyardBoxLeft.y = lanyardY + lanyardBoxTopLeft.height
        lanyardBoxLeft.height = innerHeight
        this.addChild(lanyardBoxLeft)

        const lanyardBoxBottomLeft = Pooler.newSprite('content.bottom.left')
        lanyardBoxBottomLeft.y = lanyardBoxLeft.y + lanyardBoxLeft.height
        this.addChild(lanyardBoxBottomLeft)

        const lanyardBoxTop = Pooler.newSprite('content.top.middle')
        lanyardBoxTop.x = lanyardBoxTopLeft.width
        lanyardBoxTop.width = innerWidth
        lanyardBoxTop.y = lanyardY
        this.addChild(lanyardBoxTop)

        const lanyardBoxTopRight = Pooler.newSprite('content.top.right')
        lanyardBoxTopRight.x = lanyardBoxTop.x + lanyardBoxTop.width
        lanyardBoxTopRight.y = lanyardY
        this.addChild(lanyardBoxTopRight)

        const lanyardBoxRight = Pooler.newSprite('content.right.middle')
        lanyardBoxRight.x = lanyardBoxTopRight.x
        lanyardBoxRight.y = lanyardBoxTopRight.y + lanyardBoxTopRight.height
        lanyardBoxRight.height = lanyardBoxLeft.height
        this.addChild(lanyardBoxRight)

        const lanyardBoxBottomRight = Pooler.newSprite('content.bottom.right')
        lanyardBoxBottomRight.y = lanyardBoxRight.y + lanyardBoxRight.height
        lanyardBoxBottomRight.x = lanyardBoxRight.x
        this.addChild(lanyardBoxBottomRight)

        const lanyardBoxBottom = Pooler.newSprite('content.bottom.middle')
        lanyardBoxBottom.y = lanyardBoxBottomRight.y
        lanyardBoxBottom.x = lanyardBoxBottomLeft.x + lanyardBoxBottomLeft.width
        lanyardBoxBottom.width = innerWidth
        this.addChild(lanyardBoxBottom)

        const lanyardFill = Pooler.newSprite('content.middle.middle')
        lanyardFill.x = lanyardBoxTopLeft.x + lanyardBoxTopLeft.width
        lanyardFill.y = lanyardBoxTopLeft.y + lanyardBoxTopLeft.height
        lanyardFill.width = innerWidth
        lanyardFill.height = innerHeight
        this.addChild(lanyardFill)

        let lanyardIndex = 0
        for (let row = 0; row < this.LANYARD_ROWS; row++) {
            for (let col = 0; col < this.LANYARD_COLS; col++) {
                const b = new ThumbnailBox(42, 42, false)
                b.y = lanyardY + 20 + 44 * row
                b.x = 8 + 44 * col
                this.addChild(b)

                this.lanyardThumbnails[lanyardIndex] = b
                lanyardIndex++

                b.eventMode = 'static'
            }
        }

        this.wearBtn = new BitmapTextButton(120, 'WEAR', 'a')
        this.wearBtn.x = 235
        this.wearBtn.y = 253 + 20
        this.addChild(this.wearBtn)
        this.wearBtn.addEventListener('pointerup', () => this.wearPin(this.selectedItem))

        this.takeOffBtn = new BitmapTextButton(120, 'TAKE OFF', 'a')
        this.takeOffBtn.x = 235
        this.takeOffBtn.y = 253 + 24 + 20
        this.addChild(this.takeOffBtn)
        this.takeOffBtn.addEventListener('pointerup', () => this.takeOffPin(this.selectedItem))

        this.combineBtn = new BitmapTextButton(72, 'COMBINE', 'a')
        this.combineBtn.x = 235
        this.combineBtn.y = 253 + 48 + 20
        this.addChild(this.combineBtn)
        this.combineBtn.addEventListener('pointertap', () => {
            if (!this.selectedItem) {
                return
            }
            if (this.selectedItem.single) {
                Client.shared.helpers.alert('error.4533')
                return
            }
            if (this.selectedItem.stars === 5) {
                Client.shared.helpers.alert('error.4525')
                return
            }
            if (!this.selectedItem.stars) {
                Client.shared.helpers.alert('error.4526')
                return
            }
            this.combining = this.selectedItem
            this.setItemName(getText('inv.select.combine'))
            this.combineBtn.setText('COMBINING')
            this.combineBtn.disable()
        })

        this.sellBtn = new BitmapTextButton(36, 'SELL', 'a')
        this.sellBtn.x = 319
        this.sellBtn.y = 253 + 48 + 20
        this.sellBtn.disable()
        this.addChild(this.sellBtn)

        this.sellBtn.addEventListener('pointertap', () => {
            if (!this.selectedItem) {
                return
            }
            this.sellBtn.disable()

            const identifier = {
                defUid: this.selectedItem.defUid,
                stars: this.selectedItem.stars,
                expires: this.selectedItem.expires,
                linked: this.selectedItem.teleporterId
            }

            Client.shared.serverBroker.sendAck('sell_item', identifier).then((price?: number) => {
                if (!price) {
                    this.sellBtn.enable()
                    return
                }
                Client.shared.helpers
                    .confirm(
                        'Are you sure you want to sell the item? You will receive ' +
                            price.toLocaleString() +
                            ' credits but lose the item permanently.'
                    )
                    .then((confirmed) => {
                        if (confirmed) {
                            Client.shared.serverBroker
                                .sendAck('sell_item', {
                                    ...identifier,
                                    price
                                })
                                .then(() => {
                                    this.sellBtn.enable()
                                })
                        } else {
                            this.sellBtn.enable()
                        }
                    })
            })
        })

        const fxHint = new BitmapText('Some magic pins can be combined into a more powerful pin.', {
            ...Fonts.Foxley_16
        })
        fxHint.maxWidth = 128
        fxHint.x = 237
        fxHint.y = 332 + 20
        this.addChild(fxHint)

        const lanyardTitleBGLeft = Pooler.newSprite('content.top.left')
        lanyardTitleBGLeft.x = 143
        lanyardTitleBGLeft.y = lanyardY - 9
        this.addChild(lanyardTitleBGLeft)

        const pinsWorn = new BitmapText('PINS WORN', {
            fontName: 'Folio',
            fontSize: 12
        })
        pinsWorn.x = 157
        pinsWorn.y = lanyardY - 2

        const lanyardTitleBGMiddle = Pooler.newSprite('content.top.middle')
        lanyardTitleBGMiddle.width = pinsWorn.textWidth + 10
        lanyardTitleBGMiddle.x = lanyardTitleBGLeft.x + lanyardTitleBGLeft.width
        lanyardTitleBGMiddle.y = lanyardTitleBGLeft.y
        this.addChild(lanyardTitleBGMiddle)

        const lanyardTitleBGRight = Pooler.newSprite('content.top.right')
        lanyardTitleBGRight.x = lanyardTitleBGMiddle.x + lanyardTitleBGMiddle.width
        lanyardTitleBGRight.y = lanyardTitleBGMiddle.y
        this.addChild(lanyardTitleBGRight)

        lanyardTitleBGMiddle.scale.y = lanyardTitleBGLeft.scale.y = lanyardTitleBGRight.scale.y = 1.25

        this.addChild(pinsWorn)

        this.ptb = new PagedThumbnailBox({
            search: true,
            rows: 4,
            cols: 6,
            items: [],
            spacing: 3,
            onItemTap: (item: ItemStack, preview: Container) => {
                this.ptb.setSelected(item)
                this.selectItem(item)
            },
            onItemDoubleTap: (item: ItemStack, preview: Container) => {
                this.wearPin(item)
            },
            pageTurnSpacing: 80,
            pageTurnFormat: PagedThumbnailBox.FORMAT_C,
            dimUntradeables: false,
            initialPage: Client.shared.prefs.getTemp(PrefTemp.PinsPage)
        })
        this.ptb.y = 21
        this.addChild(this.ptb)

        this.ptb.on('change', () => {
            Client.shared.prefs.setTemp(PrefTemp.PinsPage, this.ptb.getPageNumber())
        })

        const controller = this.ptb.getControllerContainer()
        controller.y = 232
        this.addChild(controller)
    }

    tryFocusSearch(e) {
        this.ptb.searchBar?.forceFocus(e)
    }

    setPreviewItem(item: ItemStack) {
        this.removeChild(this.itemPreviewImage)

        PinCache.instance
            .getTexture(item.defUid)
            .then((texture: Texture) => {
                this.itemPreviewImage = Pooler.newSprite(texture)
                this.itemPreviewImage.x = 279
                this.itemPreviewImage.y = 39

                Helpers.scalePreviewImage(this.itemPreviewImage, 88, 88)

                this.addChildAt(this.itemPreviewImage, this.getChildIndex(this.ptb))
            })
            .catch((reason) => {
                console.log(reason)
            })
    }

    selectItem(item: ItemStack) {
        let itemName = item.getName()

        this.sellBtn.enable()
        if (item.single || item.stars < 1 || item.stars === 5) {
            this.combineBtn.disable()
            if (this.combining) {
                if (item.single) {
                    Client.shared.helpers.alert('error.4533')
                } else if (!item.stars) {
                    Client.shared.helpers.alert('error.4526')
                } else {
                    Client.shared.helpers.alert('error.4525')
                }
            }
        } else {
            if (this.combining) {
                if (item.defUid !== this.combining.defUid) {
                    Client.shared.helpers.alert('error.4525')
                } else {
                    Client.shared.helpers
                        .confirm({
                            title: 'inv.pins.combine.dialog.title',
                            message: 'inv.pins.combine.dialog.text'
                        })
                        .then((ok) => {
                            if (ok) {
                                Client.shared.serverBroker
                                    .sendAck('combine_pins', [item.defUid, item.stars, this.combining.stars])
                                    .then((newId) => {
                                        if (newId) {
                                            console.log('New pin id: ' + newId)
                                        }
                                    })
                            }
                            this.combineBtn.setText('COMBINE')
                            this.combining = null
                        })
                    return
                }
            }

            this.combineBtn.enable()
        }
        this.combining = null
        this.combineBtn.setText('COMBINE')

        this.selectedItem = item

        if (itemName === undefined || itemName == null || itemName === 'undefined') {
            itemName = 'Unknown Item #' + item.defUid
        }

        if (item.single) {
            let left = '?'

            if (item.expires) {
                let seconds = Math.floor((item.expires - Date.now()) / 1000)
                let minutes = Math.floor(seconds / 60)
                let hours = Math.floor(minutes / 60)
                const days = Math.floor(hours / 24)

                hours -= days * 24
                minutes -= days * 24 * 60 - hours * 60
                seconds -= days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

                if (days) {
                    left = days === 1 ? '1 day' : days + ' days'
                } else if (hours) {
                    left = hours === 1 ? '1 hour' : hours + ' hours'
                } else if (minutes) {
                    left = minutes === 1 ? '1 minute' : minutes + ' minutes'
                } else {
                    left = seconds === 1 ? '1 second' : seconds + ' seconds'
                }
            }

            this.pinType.text = 'Expires in\n' + left
            this.pinTypeIcon.visible = true
        } else {
            this.pinType.text = item.stars > 0 ? item.stars + '-Star Pin.' : 'Normal Pin.'
            this.pinTypeIcon.visible = false
        }
        const stars = item.stars > 0 && !item.single ? `(${item.stars} star)` : ''
        this.setItemName(itemName + ' ' + stars)

        this.setPreviewItem(item)
    }

    setItemName(name: string) {
        this.itemName.text = name
        this.itemName.x = Math.round((374 - this.itemName.textWidth) / 2)
    }

    private wearPin(pin: ItemStack) {
        if (!pin) {
            return
        }
        Client.shared.serverBroker.send('pin_wear', {
            defUid: pin.defUid,
            stars: pin.stars,
            expires: pin.expires
        })
    }

    private takeOffPin(pin: DummyItem) {
        if (!pin) {
            return
        }

        Client.shared.serverBroker.send('pin_take_off', {
            id: pin.id
        })
    }
}
