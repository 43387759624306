/*
 * Button.ts
 * VMK Legacy Client
 */

import { Container, Graphics, Rectangle, Text } from 'pixi.js'
import { getText } from '../assets/ExternalConfigManager.js'
import { Theme } from '../Theme.js'
import { Helpers } from '../util/Helpers.js'

export enum VMKButtonColor {
    Orange = 'a',
    Pink = 'b',
    Blue = 'c',
    DarkGreen = 'd',
    Red = 'e',
    LimeGreen = 'f',
    Teal = 'g',
    Gold = 'h',
    LightBlue = 'i'
}

export interface ButtonConstructorParams {
    text: string
    width?: number
    color?: VMKButtonColor
    enabled?: boolean
    action: () => void
}

export class Button {
    view: Container

    readonly colorOptions = {
        [VMKButtonColor.Orange]: [0xff7a13, 0xfce02b], // [default, pressed]
        [VMKButtonColor.Pink]: [0xc0218f, 0xe530da],
        [VMKButtonColor.Blue]: [0x4f00e7, 0xae31eb],
        [VMKButtonColor.LimeGreen]: [0x43d30c, 0x8cfe2b],
        [VMKButtonColor.Red]: [0xec201e, 0xff7a13],
        [VMKButtonColor.DarkGreen]: [0x84b640, 0xfce02b],
        [VMKButtonColor.Teal]: [0x19bdba, 0x02e8e1],
        [VMKButtonColor.Gold]: [0xb39d1f, 0xe8cc3f],
        [VMKButtonColor.LightBlue]: [0x3073dd, 0x3699f2]
    }

    protected color: VMKButtonColor
    protected height = 20
    protected width: number
    protected enabled: boolean

    protected label: Text
    protected fillGraphics: Graphics

    readonly supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    constructor({
        text,
        width = 80,
        color = VMKButtonColor.LimeGreen,
        enabled = true,
        action
    }: ButtonConstructorParams) {
        this.color = color
        this.width = width
        this.enabled = enabled

        this.view = new Container()

        const vertPadding = 44 - this.height // must be at least 44 pixels for touch devices

        if (this.supportsTouch) {
            this.view.hitArea = new Rectangle(0, -vertPadding, width, this.height + vertPadding * 2)
        } else {
            this.view.hitArea = new Rectangle(0, 0, width, this.height)
        }

        this.fillGraphics = new Graphics()
        this.fillGraphics.beginFill(Theme.getCurrent().buttonOutline)
        this.fillGraphics.drawRoundedRect(0, 0, this.width, this.height, 5)
        this.fillGraphics.eventMode = 'auto'

        const label = new Text(getText(text).toUpperCase(), {
            fontFamily: 'web-folio',
            fill: Theme.getCurrent().label,
            fontSize: 14,
            letterSpacing: 1,
            textBaseline: 'alphabetic'
        })
        label.eventMode = 'auto'

        const outline = new Graphics()
        outline.eventMode = 'auto'
        outline.lineStyle(2, 0xffffff)
        outline.drawRoundedRect(0, 0, this.width, this.height, 5)

        label.position.set(width / 2, this.height / 2)
        label.anchor.set(0.5, 0.5)

        this.view.addChild(this.fillGraphics, label, outline)

        this.setActive(false)
        this.setEnabled(enabled)

        this.view.eventMode = 'static'
        this.view.cursor = 'pointer'

        this.view.addEventListener('pointertap', (e) => {
            if (!this.enabled) {
                return
            }
            e.stopPropagation()
            action?.()
            Helpers.delay(50).then(() => this.setActive(false))
        })
        this.view.addEventListener('pointerdown', (e) => {
            if (!this.enabled) {
                return
            }
            e.stopPropagation()
            this.setActive(true)
        })
        this.view.addEventListener('pointercancel', () => {
            this.setActive(false)
        })
        this.view.addEventListener('pointerout', () => {
            this.setActive(false)
        })
    }

    setLabel(text: string): void {
        this.label.text = String(text).toUpperCase()
    }

    resize(w: number, h: number): void {
        this.view.x = w / 2 - this.view.width / 2
        this.view.y = h / 2 - this.view.height / 2
    }

    setActive(active: boolean): void {
        this.fillGraphics.tint = this.colorOptions[this.color][active ? 1 : 0]
    }

    setEnabled(enabled: boolean): void {
        this.view.alpha = enabled ? 1 : 0.5
    }

    update(delta) {}

    destroy() {
        this.fillGraphics = undefined
        this.view.destroy(true)
    }
}
