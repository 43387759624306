/*
 * JCRoomStartData.ts
 * VMK Legacy Client
 */

import { Point } from 'pixi.js'

export interface IJCRoomStartData {
    rails: { bounceDir: string; points: any[][] }[]
    name: string
    boatIndex: number
    startPos: { x: number; y: number }
    direction: 'up' | 'down'
}

export const jcRoomStartData: Record<string, IJCRoomStartData> = {
    dock: {
        name: 'dock',
        rails: [
            {
                points: [
                    [new Point(0, 0), new Point(-48, 513), new Point(153, 433)],
                    [new Point(153, 443), new Point(187, 449), new Point(0, 0)],
                    [new Point(0, 0), new Point(187, 449), new Point(400, 340)],
                    [new Point(400, 341), new Point(457, 360), new Point(423, 240)],
                    [new Point(470, 350), new Point(715, 227), new Point(578, 89)],
                    [new Point(669, 175), new Point(649, 7), new Point(884, 59)]
                ],
                bounceDir: 'down'
            },
            {
                points: [
                    [new Point(133, 631), new Point(434, 436), new Point(874, 267)],
                    [new Point(133, 631), new Point(874, 267), new Point(800, 600)]
                ],
                bounceDir: 'up'
            }
        ],
        startPos: {
            x: 80,
            y: 565
        },
        direction: 'up',
        boatIndex: 3000
    },
    cambodian: {
        name: 'cambodian',
        rails: [
            {
                points: [[new Point(-68, 300), new Point(715, 655), new Point(0, 600)]],
                bounceDir: 'up'
            },
            {
                points: [
                    [new Point(-46, 180), new Point(380, 327), new Point(485, 219)],
                    [new Point(841, 381), new Point(484, 222), new Point(670, 0)]
                ],
                bounceDir: 'down'
            }
        ],
        startPos: {
            x: 78,
            y: 286
        },
        direction: 'down',
        boatIndex: 2350
    },
    tiki: {
        name: 'tiki',
        rails: [
            {
                points: [
                    [new Point(0, 0), new Point(-39, 460), new Point(1010, 60)],
                    [new Point(-20, 447), new Point(181, 439), new Point(582, 225)],
                    [new Point(260, 374), new Point(385, 380), new Point(873, 107)]
                ],
                bounceDir: 'down'
            },
            {
                points: [
                    [new Point(16, 639), new Point(421, 519), new Point(800, 600)],
                    [new Point(428, 517), new Point(864, 212), new Point(800, 600)]
                ],
                bounceDir: 'up'
            }
        ],
        startPos: {
            x: 25,
            y: 575
        },
        direction: 'up',
        boatIndex: 3000
    },
    indy: {
        name: 'indy',
        rails: [
            {
                points: [[new Point(-81, 156), new Point(860, 600), new Point(523, 13)]],
                bounceDir: 'down'
            },
            {
                points: [[new Point(-82, 330), new Point(490, 622), new Point(0, 600)]],
                bounceDir: 'up'
            }
        ],
        startPos: {
            x: 47,
            y: 307
        },
        direction: 'down',
        boatIndex: 3000
    },
    tent: {
        name: 'tent',
        rails: [
            {
                points: [
                    [new Point(-55, 145), new Point(845, 590), new Point(0, 600)],
                    [new Point(415, 383), new Point(537, 396), new Point(537, 445)]
                ],
                bounceDir: 'up'
            },
            {
                points: [[new Point(-85, 25), new Point(862, 426), new Point(800, 0)]],
                bounceDir: 'down'
            }
        ],
        startPos: {
            x: 130,
            y: 180
        },
        direction: 'down',
        boatIndex: 1200
    },
    safari: {
        name: 'safari',
        rails: [
            {
                points: [
                    [new Point(469, 400), new Point(-61, 625), new Point(800, 600)],
                    [new Point(850, 180), new Point(469, 400), new Point(800, 600)]
                ],
                bounceDir: 'up'
            },
            {
                points: [
                    [new Point(-53, 454), new Point(418, 231), new Point(300, 40)],
                    [new Point(417, 230), new Point(523, 222), new Point(795, 38)],
                    [new Point(417, 230), new Point(795, 38), new Point(350, 0)]
                ],
                bounceDir: 'down'
            }
        ],
        startPos: {
            x: 60,
            y: 500
        },
        direction: 'up',
        boatIndex: 3000
    }
}
