import { ESndGrp, SoundManager } from '@vmk-legacy/render-utils'
import type { FederatedEvent } from 'pixi.js'
import type { CheckBox } from './buttons/CheckBox.js'
import { UISoundLibrary } from './UISoundLibrary.js'

type ChangeHandler = (status: { [id: string]: boolean }) => void

export class CheckBoxController {
    private buttons: CheckBox[] = []
    changeHandler?: ChangeHandler

    constructor(checkBoxes: CheckBox[]) {
        for (const c of checkBoxes) {
            this.add(c)
        }
    }

    setChangeHandler(handler: ChangeHandler): void {
        this.changeHandler = handler
    }

    add(r: CheckBox): void {
        this.buttons.push(r)
        r.controller = this
        r.addEventListener('pointerup', CheckBoxController.boxPress)
    }

    private static boxPress = (e: FederatedEvent): void => {
        const c = e.currentTarget as CheckBox
        c.setActive(!c.getActive())
        SoundManager.shared.play(ESndGrp.UI, UISoundLibrary.UIClick)
    }

    changed(): void {
        if (this.changeHandler) {
            this.changeHandler(this.getStatus())
        }
    }

    remove(r: CheckBox): void {
        const rI = this.buttons.indexOf(r)
        if (rI !== -1) {
            const removed = this.buttons.splice(rI, 1)

            removed[0]?.off('pointerup')
        }
    }

    getActive(): CheckBox[] {
        const active: CheckBox[] = []
        for (const button of this.buttons) {
            if (button.getActive()) {
                active.push(button)
            }
        }
        return active
    }

    getStatus(): { [id: string]: boolean } {
        const result: any = {}

        for (const c of this.getActive()) {
            result[c.id] = c.getActive()
        }

        return result
    }

    activateAll(): void {
        for (const button of this.buttons) {
            button.setActive(true)
        }
    }
}
