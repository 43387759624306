import { Client } from '../../Client.js'
import type { AvatarEntity } from '../../room/entities/AvatarEntity.js'
import { WalkableRoomViewer } from '../../room/renderer/WalkableRoomViewer.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import type { EntityDataPayload } from './EntityJoinModule.js'

export class EntityChangeModule extends ServerEvent {
    static readonly type = MessageType.ENTITY_CHANGE

    async handle(data: EntityDataPayload) {
        if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
            const object = Client.shared.roomViewer.getEntityByRef(data.id) as AvatarEntity
            if (object) {
                object.updateFigure(data)
            } else {
                console.warn('Entity change fired for nonexistent entity')
            }
        }
    }
}
