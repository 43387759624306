import { VMKPassWindow } from '../../ui/windows/rooms/VMKPassWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'
import { RoomJoinModule } from './RoomJoinModule.js'

export class RoomQueueMoved extends ServerEvent {
    static readonly type = MessageType.QUEUE_PLACE

    override async handle(place: number) {
        VMKPassWindow.instance?.setQueuePlace(place)
        RoomJoinModule.loader?.setQueuePlace(place)
    }
}
