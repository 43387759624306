import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RulesUpdated extends ServerEvent {
    static readonly type = MessageType.RulesUpdated

    async handle(): Promise<void> {
        Client.shared.showRegistration(true)
    }
}
