import { Container } from 'pixi.js'
import type { IWindowView } from '../views/IWindowView.js'
import type { UIWindow } from './UIWindow.js'
import type { UIWindowTab } from './UIWindowTab.js'

export abstract class UIWindowView<ParentWin extends UIWindow = UIWindow> extends Container implements IWindowView {
    padding = 0
    parent: ParentWin
    visible = false
    tab: UIWindowTab

    update() {}
}
