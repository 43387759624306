import type { PotcEngineEntity, PotcShipType } from '@vmk-legacy/potc-engine'
import { PotcMode } from '@vmk-legacy/potc-engine'
import type { BitmapText, Sprite } from 'pixi.js'
import { Texture } from 'pixi.js'
import { Client } from '../../../Client.js'
import { EWindow } from '../../../enums.js'
import type { ImageButton } from '../../../ui/buttons/ImageButton.js'
import { PiratesSignupView } from './PiratesSignupView.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export interface PotcPlayerInfo {
    id: number
    name: string
    ship: PotcShipType
}

export interface PotcPlayerInfoFull {
    id: number
    name: string
    ammo: any[]
    gold: number
}

export interface PotcGameInfo {
    id: string
    level: number
    mode: PotcMode
    teams: {
        port: PotcPlayerInfo[]
        pirates: PotcPlayerInfo[]
    }
}

export interface PotcRejoinTeamInfo {
    vault: number
    wins: number
    players: PotcPlayerInfoFull[]
}

export interface PotcGameRejoin {
    level: number
    stage: number
    mode: PotcMode
    status: any
    start: number | null
    length: number
    entities: PotcEngineEntity[]
    teams: {
        port: PotcRejoinTeamInfo
        pirates: PotcRejoinTeamInfo
    }
}

export class PiratesPreshowHandler extends WalkableRoomExtension {
    static override identifier = 'potcLobby'

    games: PotcGameInfo[] = []

    myGame?: PotcGameInfo
    myInfo?: PotcPlayerInfo

    private signup: PiratesSignupView

    async init(): Promise<this> {
        await super.init()

        this.signup = this.room.addChild(new PiratesSignupView(this))

        await this.signup.waitToBeBuilt()

        this.clearGameView(0)
        this.clearGameView(1)

        Client.shared.serverBroker.onEvent('potc_game_added', (data: PotcGameInfo) => {
            const newLen = this.games.push(data)
            this.checkIfMyGame(data)
            if (newLen < 3) {
                this.renderGameInfo(newLen - 1, data)
            }
        })
        Client.shared.serverBroker.onEvent('potc_game_removed', (gameId: string) => {
            if (this.myGame?.id === gameId) {
                this.myGame = null
                this.myInfo = null
            }
            const index = this.games.findIndex((g) => g.id === gameId)
            if (index !== -1) {
                this.games.splice(index, 1)
                this.clearGameView(0)
            }
        })

        Client.shared.serverBroker.onEvent(
            'potc_ship_changed',
            (data: {
                playerId: number
                gameId: string
                team: string
                ship: PotcShipType
            }) => {
                console.log('Ship changed!', data)
                const game = this.games.find((g) => g.id === data.gameId)

                if (game) {
                    const player: PotcPlayerInfo = game.teams[data.team].find(
                        (p: PotcPlayerInfo) => p.id === data.playerId
                    )
                    if (player) {
                        player.ship = data.ship

                        this.renderGameInfo(0, game)
                    }
                }
            }
        )
        Client.shared.serverBroker.onEvent(
            'potc_team_add',
            (data: {
                player: PotcPlayerInfo
                gameId: string
                team: string
            }) => {
                const game = this.games.find((g) => g.id === data.gameId)

                if (game) {
                    game.teams[data.team].push(data.player)
                    this.checkIfMyGame(game)
                    this.renderGameInfo(0, game)
                }
            }
        )
        Client.shared.serverBroker.onEvent(
            'potc_team_remove',
            (data: {
                id: number
                gameId: string
                team: string
            }) => {
                const game = this.games.find((g) => g.id === data.gameId)

                if (game) {
                    if (game === this.myGame && data.id === Client.shared.selfRecord.getId()) {
                        this.myInfo = null
                    }

                    const team = game.teams[data.team]
                    const playerIndex = team.findIndex((p) => p.id === data.id)

                    if (playerIndex !== -1) {
                        team.splice(playerIndex, 1)

                        this.renderGameInfo(0, game)
                    }
                }
            }
        )

        return this
    }

    checkIfMyGame(game: PotcGameInfo) {
        for (const t in game.teams) {
            for (const p of game.teams[t]) {
                if (p.id === Client.shared.selfRecord.getId()) {
                    this.myGame = game
                    this.myInfo = p

                    return true
                }
            }
        }

        return false
    }

    receiveInitialData(data) {
        this.games = data.games

        for (const game of this.games) {
            if (this.checkIfMyGame(game)) {
                break
            }
        }

        if (data.games.length) {
            this.renderGameInfo(0, data.games[0])

            if (data.games.length > 1) {
                this.renderGameInfo(1, data.games[1])
            }
        }
    }

    joinGame(index: 0 | 1, team: 'port' | 'pirates') {
        const game = this.games[index]

        return Client.shared.serverBroker.sendAck('potc_join_game', {
            gameId: game.id,
            team
        })
    }

    leaveGame(index: 0 | 1, team: 'port' | 'pirates') {
        const game = this.games[index]

        Client.shared.serverBroker.send('potc_leave_game', {
            gameId: game.id,
            team
        })
    }

    renderGameInfo = (index: 0 | 1, game: PotcGameInfo) => {
        this.signup.games[0].title.text =
            'LEVEL ' + game.level + ' - ' + (game.mode === PotcMode.CaptureTheFlag ? 'CAPTURE THE FLAG' : 'SHIP BATTLE')
        this.signup.games[0].title.visible = true

        for (const team of ['port', 'pirates']) {
            this.signup.games[index][team].leave.visible = false
            this.signup.games[index][team].join.visible = true

            for (let i = 0; i < 4; i++) {
                if (!game.teams[team][i]) {
                    this.signup.games[index][team].players[i].name.visible = false
                    this.signup.games[index][team].players[i].shipBtn.visible = false
                    this.signup.games[index][team].players[i].shipIcon.visible = false

                    continue
                }
                this.signup.games[index][team].players[i].name.text = game.teams[team][i].name
                this.signup.games[index][team].players[i].name.visible = true
                const ship = game.teams[team][i].ship

                if (!ship) {
                    continue
                }

                if (game.teams[team][i].id === Client.shared.selfRecord.getId()) {
                    this.signup.games[index][team].players[i].shipBtn.replaceTextures(
                        'button.' + ship + '.active',
                        'button.' + ship + '.pressed'
                    )
                    this.signup.games[index][team].players[i].shipBtn.visible = true
                    this.signup.games[index][team].players[i].shipIcon.visible = false
                    this.signup.games[index][team].leave.visible = true
                    this.signup.games[index][team].join.visible = false
                } else {
                    this.signup.games[index][team].players[i].shipIcon.texture = Texture.from(ship + '.selected')
                    this.signup.games[index][team].players[i].shipBtn.visible = false
                    this.signup.games[index][team].players[i].shipIcon.visible = true
                }
            }
        }

        if (this.signup.startPlayersWin?.gameId === game.id) {
            this.signup.startPlayersWin.renderGame(game)
        }
    }

    clearGameView(game: number) {
        const view = this.signup.games[game]

        view.title.visible = false

        for (const team of ['port', 'pirates']) {
            console.log(view[team])
            view[team].join.visible = false
            view[team].leave.visible = false

            for (let i = 0; i < 4; i++) {
                view[team].players[i].name.visible = false
                view[team].players[i].shipBtn.visible = false
                view[team].players[i].shipIcon.visible = false
            }
        }
    }

    teardown() {
        super.teardown()
        console.log('TEARDOWN POTC LOBBY')
        ;['potc_game_added', 'potc_game_removed', 'potc_ship_changed', 'potc_team_add', 'potc_team_remove'].forEach(
            (e) => Client.shared.serverBroker.offEvent(e)
        )

        this.signup.destroy()
        this.signup = null

        this.myGame = null

        Client.shared.userInterface.closeWindow(EWindow.PiratesModal)

        this.games = []
    }
}

export interface SignupGame {
    title: BitmapText
    port: {
        join: ImageButton
        leave: ImageButton
        players: { name: BitmapText; shipBtn: ImageButton; shipIcon: Sprite }[]
    }
    pirates: {
        join: ImageButton
        leave: ImageButton
        players: { name: BitmapText; shipBtn: ImageButton; shipIcon: Sprite }[]
    }
}
