/*
 * Button.ts
 * VMK Legacy Client
 */

import { Container } from 'pixi.js'
import { BitmapTextButton } from '../buttons/BitmapTextButton.js'
import type { BitmapTextButtonType } from '../buttons/BitmapTextButtonType.js'
import { View } from './View.js'

export class Button extends View {
    override view: Container

    constructor(
        readonly label: string | Container,
        readonly type?: BitmapTextButtonType
    ) {
        super()
    }

    override loadView(): void {
        if (this.label instanceof Container) {
            this.view = this.label
        } else {
            this.view = new BitmapTextButton(100, this.label, this.type)
        }
    }

    override sizeToFit(proposed: { width?: number; height?: number }) {
        if (this.view instanceof BitmapTextButton) {
            this.view.innerWidth = proposed.width
            this.view.rerender()
        }
    }
}
