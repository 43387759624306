import { Helpers } from '../util/Helpers.js'
import { ServerTransportAdapting } from './ServerTransportAdapting.js'

export class MockServerTransportAdapter extends ServerTransportAdapting {
    connected = false

    mockServerResponders = new Map<string, (...args: any[]) => any>()

    initConnection(): void {
        Helpers.delay(100).then(() => {
            this.connected = true
            this.emit('established')
        })
    }

    isConnected(): boolean {
        return false
    }

    restoreConnection(): void {}

    override send(event: string, payload?: any): void {
        super.send(event, payload)

        const mockServerHandler = this.mockServerResponders.get(event)
        if (mockServerHandler) {
            mockServerHandler(payload)
        } else {
            console.log('MockNetworkProvider cannot handle event ' + event)
        }
    }

    override async sendAck(event: string, payload?: any): Promise<any> {
        await super.sendAck(event, payload)

        const mockServerHandler = this.mockServerResponders.get(event)
        if (mockServerHandler) {
            const response = mockServerHandler(payload)

            this.didReceiveEvent?.(event, response, true)

            return response
        }

        console.log('MockNetworkProvider cannot handle ack event ' + event)
        return false
    }

    teardown(): void {}

    // Setup a function to respond to events client sends
    mockServerHandling(event: string, handler: (...args: any[]) => any): void {
        this.mockServerResponders.set(event, handler)
    }

    // Fake that the server sent client an event.
    mockReceive(event: string, payload?: any): void {
        console.log('Mocked client receiving:', event, payload)
        this.didReceiveEvent?.(event, payload, false)

        this.emit(ServerTransportAdapting.Constants.event, event, payload)
    }
}
