import { AlertSize, ServerAlert } from '@vmk-legacy/server-protos'
import { Client } from '../../Client.js'
import { defineHandler } from '../ServerEvent.js'

export const serverAlertsHandler = defineHandler(ServerAlert, async ({ title, body, size, tag }) => {
    await Client.shared.helpers.alert({
        title,
        message: body,
        forceLg: size === AlertSize.large,
        tag
    })

    if (tag === 'reboot') {
        location.reload()
    }
})
