import {
    EPotcAmmo,
    type PotcEngineCannonballEntity,
    type PotcEntityKind,
    PotcStageEngine,
    type Vector
} from '@vmk-legacy/potc-engine'
import { PotcFactorBase } from './PotcFactorBase.js'
import type { PotcScene } from './PotcScene.js'
import type { PotcVisualBase } from './PotcVisualBase.js'
import { PotcVisualCannonball } from './PotcVisualCannonball.js'

export class PotcFactorCannonball extends PotcFactorBase implements PotcEngineCannonballEntity {
    readonly boundsType = 'point'
    readonly windAffects = false
    lastMove = Date.now()

    collided = false
    velocity: Vector
    life: number

    state: {
        invincible: boolean
        broadside: boolean
        shrapnel: boolean
        golden: boolean
    }

    syncState = false

    constructor(room: PotcScene, worldPosition: Vector) {
        super(room, 1, '')

        this.worldPosition = worldPosition

        const loc = PotcStageEngine.projectAxonPosition({
            x: this.worldPosition.x,
            y: this.worldPosition.y
        })

        this.visual.position.set(loc.x - 400, loc.y - 300)
    }

    protected override createVisual(): PotcVisualBase {
        const visual = new PotcVisualCannonball(this.roomContext, EPotcAmmo.Regular)

        return visual
    }

    getLife() {
        return this.life
    }

    isRemovable() {
        return this.getLife() <= 0 || this.collided
    }

    move() {
        if (this.syncState) {
            this.lastMove = Date.now()
            this.worldPosition.add(this.velocity)
            this.life--

            if (this.roomContext.data.collisionMap.testShip(this) > 3) {
                this.collided = true
            }
        }
    }

    readonly kind: PotcEntityKind.Cannonball
    worldPosition: Vector

    stateUpdated(changes: any): void {}
}
