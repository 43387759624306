import type { EItemType } from '@vmk-legacy/common-ts'
import { Pooler } from '@vmk-legacy/render-utils'
import type { Container } from 'pixi.js'
import { BitmapText } from 'pixi.js'
import { Client } from '../../../../Client.js'
import type { Item } from '../../../../data/Item.js'
import type { ItemStack } from '../../../../data/ItemStack.js'
import { Fonts } from '../../../../Fonts.js'
import { WalkableRoomViewer } from '../../../../room/renderer/WalkableRoomViewer.js'
import { PrefTemp } from '../../../../SessionPrefs.js'
import { Helpers } from '../../../../util/Helpers.js'
import { BitmapTextButton } from '../../../buttons/BitmapTextButton.js'
import type { ThumbnailBox } from '../../../containers/ThumbnailBox.js'
import { PagedThumbnailBox } from '../../../thumbs/PagedThumbnailBox.js'
import { Separator } from '../../Separator.js'
import { UIWindowView } from '../../UIWindowView.js'
import type { InventoryWindow } from '../InventoryWindow.js'

export class FurnishingsView extends UIWindowView<InventoryWindow> {
    inventoryThumbnails: ThumbnailBox[]

    private itemName: BitmapText

    selectedItem: ItemStack = null

    ptb: PagedThumbnailBox

    private itemPreviewImage: Container

    private sellBtn: BitmapTextButton
    placeBtn: BitmapTextButton

    constructor(
        readonly win: InventoryWindow,
        readonly kind: EItemType
    ) {
        super()

        this.inventoryThumbnails = []

        this.itemName = new BitmapText('Select an item to learn more', {
            ...Fonts.Foxley_16
        })
        this.itemName.x = Math.round((374 - this.itemName.textWidth) / 2)
        this.addChild(this.itemName)

        const sep = new Separator(374)
        sep.y = 15
        this.addChild(sep)

        const previewBox = Pooler.newSprite('previewbox')
        previewBox.scale.set(0.5)
        previewBox.position.set(271, 20)
        this.addChild(previewBox)

        this.sellBtn = new BitmapTextButton(104, 'SELL', 'a')
        this.sellBtn.position.set(271, 152)
        this.addChild(this.sellBtn)
        this.sellBtn.addEventListener('pointertap', () => {
            this.sellBtn.disable()

            if (!this.selectedItem) {
                return
            }

            Client.shared.serverBroker
                .sendAck('sell_item', {
                    defUid: this.selectedItem.defUid,
                    linked: this.selectedItem.teleporterId
                })
                .then((price?: number | boolean) => {
                    if (typeof price === 'boolean') {
                        this.sellBtn.enable()
                        return
                    }
                    Client.shared.helpers
                        .confirm(
                            `Are you sure you want to sell the item? You will receive ${price.toLocaleString()} credits but lose the item permanently.`
                        )
                        .then((confirmed) => {
                            if (confirmed) {
                                Client.shared.serverBroker
                                    .sendAck('sell_item', {
                                        defUid: this.selectedItem.defUid,
                                        linked: this.selectedItem.teleporterId,
                                        price
                                    })
                                    .then(() => {
                                        this.sellBtn.enable()
                                    })
                            } else {
                                this.sellBtn.enable()
                            }
                        })
                })
        })

        this.placeBtn = new BitmapTextButton(104, 'PLACE', 'a')
        this.placeBtn.position.set(271, 176)
        this.placeBtn.disable()
        this.addChild(this.placeBtn)

        this.sellBtn.disable()

        this.placeBtn.addEventListener('pointertap', async () => {
            if (this.selectedItem) {
                if (Client.shared.roomViewer instanceof WalkableRoomViewer) {
                    if (!Client.shared.roomViewer.furniController.beginPlacing(this.selectedItem)) {
                        await Client.shared.helpers.alert({
                            title: 'Error',
                            message: "This room's walls cannot have posters placed on them."
                        })
                        return
                    }
                    win.visible = false
                    this.clearSelected()
                }
            }
        })

        if (
            Client.shared.roomViewer instanceof WalkableRoomViewer &&
            !Client.shared.roomViewer.furniController.isEnabled()
        ) {
            this.placeBtn.disable()
        }
        this.ptb = new PagedThumbnailBox({
            search: true,
            rows: 4,
            cols: 6,
            items: [],
            spacing: 3,
            onItemTap: (item: Item, preview: Container) => {
                this.onItemTap(item, preview)
            },
            onItemDoubleTap: (item: Item, preview: Container) => {
                this.onItemDoubleTap(item, preview)
            },
            pageTurnSpacing: 80,
            pageTurnFormat: PagedThumbnailBox.FORMAT_C,
            dimUntradeables: false,
            initialPage: Client.shared.prefs.getTemp(PrefTemp.FurniPage)
        })
        this.ptb.y = 21
        this.addChild(this.ptb)

        this.ptb.on('change', () => {
            Client.shared.prefs.setTemp(PrefTemp.FurniPage, this.ptb.getPageNumber())
        })

        const controller = this.ptb.getControllerContainer()
        controller.y = 232
        this.addChild(controller)
    }

    tryFocusSearch(e) {
        this.ptb.searchBar?.forceFocus(e)
    }

    update(): void {
        console.log('furni view update')
        const furni = [...Client.shared.selfRecord.getInventory().values()].filter((i) => i.defType === this.kind)
        furni.sort((a, b) => {
            const nameA = a.getName().toLowerCase()
            const nameB = b.getName().toLowerCase()

            if (nameA === nameB) {
                if (!a.teleporterId) {
                    return 0
                }
                return a.teleporterId === b.getMainId() ? 1 : -1
            }

            return nameA > nameB ? 1 : -1
        })
        // if (this.selectedItem) {
        // 	let selectedExists = false;
        // 	let updateSelected = null;
        //
        // 	furni.forEach((inventoryItem: Item) => {
        // 		if (inventoryItem.getId() === this.selectedItem.getId()) {
        // 			selectedExists = true;
        // 		} else if (inventoryItem.getDefId() === this.selectedItem.getDefId()) {
        // 			updateSelected = inventoryItem;
        // 		}
        // 	});
        //
        // 	if (!selectedExists) {
        // 		this.selectedItem = updateSelected;
        // 	}
        // }

        this.ptb.setItems(...furni)
    }

    private onItemTap(item: Item, preview: Container): void {
        this.ptb.setSelected(item)
        this.selectItem(item, preview)
    }

    private onItemDoubleTap(item: Item, preview: Container): void {}

    setPreviewItem(preview?: Container): void {
        this.removeChild(this.itemPreviewImage)

        this.itemPreviewImage = preview

        if (!this.itemPreviewImage) {
            return
        }

        this.itemPreviewImage.cacheAsBitmap = true
        this.itemPreviewImage.position.set(274, 23)

        Helpers.scalePreviewImage(this.itemPreviewImage, 100, 125)

        this.addChildAt(this.itemPreviewImage, this.getChildIndex(this.ptb))
    }

    selectItem(item: ItemStack, preview: Container): void {
        let itemName = item.getName()
        if (item.teleporterId) {
            itemName += ' (#' + item.getMainId() + ' » ' + item.teleporterId + ')'
        }
        this.selectedItem = item
        this.setItemName(itemName)
        this.setPreviewItem(preview)
        this.sellBtn.enable()

        if (
            Client.shared.roomViewer instanceof WalkableRoomViewer &&
            Client.shared.roomViewer.furniController.isEnabled()
        ) {
            this.placeBtn.enable()
        }
    }

    clearSelected(): void {
        this.selectedItem = null
        this.setItemName('')
        this.setPreviewItem(null)
        this.placeBtn.disable()
        this.sellBtn.disable()
    }

    setItemName(name: string): void {
        this.itemName.text = name
        this.itemName.x = Math.round((374 - this.itemName.textWidth) / 2)
    }
}
