import { Client } from '../../Client.js'
import type { IAlertConfig } from '../../ClientHelpers.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

type Payload = { title: string; body?: string }

export class ConfirmModule extends ServerEvent {
    static readonly type = MessageType.CONFIRM

    async handle(data: Payload): Promise<boolean> {
        const opts: IAlertConfig = {
            title: data.title || 'Confirmation',
            message: data.body || 'Do you confirm?'
        }

        if (opts.title === 'Entrance Fee Required') {
            opts.randomLoc = true
        }

        return await Client.shared.helpers.confirm(opts)
    }
}
