import type { IItemFurniMap } from '@vmk-legacy/common-ts'
import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class RefetchFurnimap extends ServerEvent {
    static readonly type = MessageType.FurnimapUpdated

    override async handle(payload: {
        modelUid: string
        defUid?: string
        furnimap: IItemFurniMap
    }): Promise<void> {
        if (payload.defUid) {
            Client.shared.furniMapMgr.addLink(payload.defUid, payload.modelUid)
        }

        if (payload.furnimap) {
            Client.shared.furniMapMgr.addMap(payload.modelUid, payload.furnimap)
        }
    }
}
