import { Pooler } from '@vmk-legacy/render-utils'
import { gsap } from 'gsap'
import type { IDestroyOptions, Sprite } from 'pixi.js'
import { Container } from 'pixi.js'
import { BitmapTextButton } from '../ui/buttons/BitmapTextButton.js'
import { BitmapTextButtonType } from '../ui/buttons/BitmapTextButtonType.js'
import type { RegistrationView } from './RegistrationView.js'

export class RegistrationStep2View extends Container {
    private readonly tip1: Container
    private readonly tip2: Container
    private readonly tip3: Container
    override parent: RegistrationView
    private readonly bubbleAnimator: number

    constructor() {
        super()

        const step = Pooler.newSprite('reg.stepnumbers.bg')
        step.x = 666
        step.y = 26
        this.addChild(step)
        const step1Active = Pooler.newSprite('reg.step1.passive')
        step1Active.x = 700
        step1Active.y = 25
        this.addChild(step1Active)
        const step2Passive = Pooler.newSprite('reg.step2.active')
        step2Passive.x = 725
        step2Passive.y = 25
        this.addChild(step2Passive)
        const step3Passive = Pooler.newSprite('reg.step3.passive')
        step3Passive.x = 750
        step3Passive.y = 25
        this.addChild(step3Passive)

        const title = Pooler.newSprite('tips_headline')
        title.x = 130
        title.y = 94
        this.addChild(title)

        const nextBtn = new BitmapTextButton(75, 'NEXT', BitmapTextButtonType.GREEN)
        nextBtn.x = 694
        nextBtn.y = 489
        this.addChild(nextBtn)

        // BEGIN TIP 1
        this.tip1 = Pooler.newContainer()
        this.tip1.addChild(Pooler.newSprite('1stbox'))
        this.tip1.x = 14
        this.tip1.y = 195
        this.addChild(this.tip1)

        const helpHeadline = Pooler.newSprite('help_headline')
        helpHeadline.x = 77
        helpHeadline.y = 8
        this.tip1.addChild(helpHeadline)

        const helpBtn = Pooler.newSprite('help_button')
        helpBtn.x = 15
        helpBtn.y = 54
        this.tip1.addChild(helpBtn)

        const textBox1 = Pooler.newSprite('textbox1')
        textBox1.x = 5
        textBox1.y = 132
        this.tip1.addChild(textBox1)

        const text1 = Pooler.newSprite('text1')
        text1.x = 15
        text1.y = 142
        this.tip1.addChild(text1)
        // END TIP 1

        // BEGIN TIP 2
        this.tip2 = Pooler.newContainer()
        this.tip2.addChild(Pooler.newSprite('2ndboxtbox'))
        this.tip2.x = 234
        this.tip2.y = 195
        this.addChild(this.tip2)

        const langHeadline = Pooler.newSprite('watch_your_language_headline')
        langHeadline.x = 36
        langHeadline.y = 8
        this.tip2.addChild(langHeadline)

        const cursorFrames: Sprite[] = []
        let curFrame = 0
        for (let i = 1; i <= 14; i++) {
            const frame = Pooler.newSprite(`cursor_animation_${i}`)
            frame.alpha = 0
            frame.x = 23
            frame.y = 58
            cursorFrames.push(frame)
            this.tip2.addChild(frame)
        }
        setInterval(() => {
            if (curFrame >= cursorFrames.length) {
                curFrame = 0
                return
            }
            cursorFrames.forEach((frame, index) => {
                if (index === curFrame) {
                    frame.alpha = 1
                } else {
                    frame.alpha = 0
                }
            })
            curFrame++
        }, 250)

        const textBox2 = Pooler.newSprite('textbox2')
        textBox2.x = 6
        textBox2.y = 132
        this.tip2.addChild(textBox2)
        const text2 = Pooler.newSprite('text2')
        text2.x = 16
        text2.y = 142
        this.tip2.addChild(text2)
        // END TIP 2

        // BEGIN TIP 3
        this.tip3 = Pooler.newContainer()
        this.tip3.addChild(Pooler.newSprite('1stbox'))
        this.tip3.x = 430
        this.tip3.y = 195
        this.addChild(this.tip3)

        const vmklHelpers = Pooler.newSprite('vmkhelpers_headline')
        vmklHelpers.x = 42
        vmklHelpers.y = 5
        this.tip3.addChild(vmklHelpers)

        const staffBadge = Pooler.newSprite('mikki_badge')
        staffBadge.x = 68
        staffBadge.y = 50
        this.tip3.addChild(staffBadge)

        const textBox3 = Pooler.newSprite('textbox3')
        textBox3.x = 6
        textBox3.y = 121
        this.tip3.addChild(textBox3)
        const text3 = Pooler.newSprite('text3')
        text3.x = 17
        text3.y = 129
        this.tip3.addChild(text3)

        // START STAFF
        const avi = Pooler.newSprite('Layer 1')
        avi.x = 682
        avi.y = 294
        this.addChild(avi)

        this.children.forEach((c: Sprite) => c.isSprite && c.anchor.set(0))
        this.tip1.children.forEach((c: Sprite) => c.isSprite && c.anchor.set(0))
        this.tip2.children.forEach((c: Sprite) => c.isSprite && c.anchor.set(0))
        this.tip3.children.forEach((c: Sprite) => c.isSprite && c.anchor.set(0))

        const bubbles: Sprite[] = []
        let bubbleIndex = 0
        for (let i = 1; i <= 5; i++) {
            const bubble = Pooler.newSprite(`bubbletext${i}`)
            bubble.anchor.set(0.5)
            bubbles.push(bubble)
            bubble.x = 711
            bubble.y = 272
            bubble.alpha = 0
            bubble.scale.set(0)
            this.addChild(bubble)
        }
        this.bubbleAnimator = setInterval(() => {
            if (bubbleIndex >= bubbles.length + 3) {
                bubbles.forEach((b) => {
                    b.x = 711
                    b.y = 272
                    b.alpha = 0
                    b.scale.set(0)
                })
                bubbleIndex = 0
                return
            }
            const bubble = bubbles[bubbleIndex]
            if (bubble) {
                bubble.alpha = 1
                gsap.to(bubble.scale, {
                    duration: 1,
                    x: 1,
                    y: 1
                })
            }
            bubbles.forEach((b) => {
                if (b.alpha === 1) {
                    gsap.to(b, {
                        duration: 2,
                        y: b.y - 25,
                        onComplete: () => {
                            if (b.y < 200) {
                                gsap.to(b.scale, {
                                    duration: 2,
                                    x: 0,
                                    y: 0,
                                    onComplete: () => {
                                        b.alpha = 0
                                    }
                                })
                            }
                        }
                    })
                }
            })
            bubbleIndex++
        }, 2000)
        // END STAFF

        nextBtn.addEventListener('pointerup', () => {
            this.parent.goToStep(3)
        })
    }

    setVisible(visible = true): void {
        this.visible = visible

        if (!visible) {
            return
        }

        // set element container scales to 0 and tween in
        this.tip1.pivot.set(Math.round(this.tip1.width / 2), Math.round(this.tip1.height / 2))
        this.tip1.x += Math.round(this.tip1.width / 2)
        this.tip1.y += Math.round(this.tip1.height / 2)
        this.tip1.scale.set(0)
        gsap.to(this.tip1.scale, {
            duration: 1,
            x: 1,
            y: 1
        })

        this.tip2.pivot.set(Math.round(this.tip2.width / 2), Math.round(this.tip2.height / 2))
        this.tip2.x += Math.round(this.tip2.width / 2)
        this.tip2.y += Math.round(this.tip2.height / 2)
        this.tip2.scale.set(0)
        gsap.to(this.tip2.scale, {
            duration: 1,
            x: 1,
            y: 1,
            delay: 0.3
        })

        this.tip3.pivot.set(Math.round(this.tip3.width / 2), Math.round(this.tip3.height / 2))
        this.tip3.x += Math.round(this.tip3.width / 2)
        this.tip3.y += Math.round(this.tip3.height / 2)
        this.tip3.scale.set(0)
        gsap.to(this.tip3.scale, {
            duration: 1,
            x: 1,
            y: 1,
            delay: 0.6
        })
    }

    override destroy(_options?: IDestroyOptions | boolean): void {
        super.destroy(_options)

        clearInterval(this.bubbleAnimator)
    }
}
