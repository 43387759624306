import { Packr } from 'msgpackr'
import Config from '../Config'
import { ServerTransportAdapting } from './ServerTransportAdapting.js'

const packr = new Packr({
    bundleStrings: true
})

type MessagePayload = { [k: string]: MessagePayload } | string | boolean | number | null | undefined
type MessageTuple = [event: string, payload?: MessagePayload, ackId?: number]

export class VanillaWebsocketAdapter extends ServerTransportAdapting {
    #socket: WebSocket
    ignoreDisconnect = false
    awaitingAcks = new Map<number, (payload?: any) => void>()
    messageId = 0

    async decode(message: Blob): Promise<void> {
        if (!(message instanceof Blob)) {
            console.error('Socket received message that was not a Blob, but was: ', message)
            return
        }
        const buffer = await message.arrayBuffer()
        const array = new Uint8Array(buffer)
        const decoded = packr.decode(array)

        return decoded
    }

    encode(...args: any[]): Buffer {
        return packr.encode(args)
    }

    initConnection(): void {
        console.log(`CONNECTING TO: ${Config.environment.serverUrl}`)

        if (this.#socket) {
            this.#socket.close()
        }

        this.ignoreDisconnect = false
        this.#socket = new WebSocket(Config.environment.serverUrl)

        this.#socket.addEventListener('close', (event) => {
            console.log('disconnect', event)

            if (event.wasClean) {
                this.emit(ServerTransportAdapting.Constants.kicked)
                return
            }
            if (this.ignoreDisconnect) {
                this.ignoreDisconnect = false
            } else {
                this.emit(ServerTransportAdapting.Constants.dropped)
                this.teardown()
            }
        })

        this.#socket.addEventListener('error', (e) => {
            console.log('Socket error', e)
        })
        this.#socket.addEventListener('open', () => {
            // this.emit(ServerTransportAdapting.Constants.established)
        })

        this.#socket.addEventListener('message', async (message) => {
            const decoded = await this.decode(message.data)

            if (!Array.isArray(decoded)) {
                console.error('Unexpected decoded non-array', decoded)
                return
            }

            const [event, payload, ackId] = decoded as MessageTuple
            console.log(event, payload)

            if (process.env.NODE_ENV !== 'production') {
                this.didReceiveEvent?.(event, payload, false)
            }

            // server is ready for commands when we receive 'ready'
            if (event === 'ready') {
                console.log('got ready!')
                this.emit(ServerTransportAdapting.Constants.established)
            } else if (event.startsWith('ack-')) {
                // a response to our sendAck
                this.handleAck(+event.substring('ack-'.length), payload)
            } else {
                this.emit(ServerTransportAdapting.Constants.event, event, payload, ackId)
            }
        })
    }

    isConnected(): boolean {
        return this.#socket?.readyState === WebSocket.OPEN
    }

    override send(event: string, payload?: any): void {
        super.send(event, payload)
        this.#socket.send(this.encode(event, payload))
    }

    override sendAck(event: string, payload?: any): Promise<any> {
        super.sendAck(event, payload)

        return new Promise<any>((resolve) => {
            const id = ++this.messageId
            this.#socket.send(this.encode(event, payload, id))

            this.awaitingAcks.set(id, resolve)
            // this.#socket.emit(event, payload, resolve)
        })
    }

    handleAck(id: number, payload?: any): void {
        console.log('Handling ack for msg ' + id)
        const ack = this.awaitingAcks.get(id)
        if (ack) {
            this.awaitingAcks.delete(id)
            ack(payload)
        }
    }

    teardown(): void {
        this.ignoreDisconnect = true
        this.removeAllListeners()

        this.#socket?.close()
        this.#socket = null
    }
}
