import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import { MessengerWindow } from '../../ui/windows/messenger/MessengerWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class FriendsListModule extends ServerEvent {
    static readonly type = MessageType.FRIENDS_LIST

    async handle(data: any) {
        MessengerWindow.friendData = data
        ;(await Client.shared.userInterface?.getWin(EWindow.Messenger))?.updateFriends()
    }
}
