import type { Sprite } from 'pixi.js'
import { getText } from '../../../assets/ExternalConfigManager.js'
import TextScroller from '../../TextScroller.js'
import { MixController } from './MixController.js'

export class StreetPartyMusicHandler extends MixController {
    static override identifier = 'streetPartyMixer'

    readonly pickSongSprites = ['mixer_table']
    readonly mixer = 'street'

    setupMixScroller(): TextScroller {
        const displaySong = this.room.sprites.getChildByName('display_song') as Sprite
        displaySong.visible = false

        const scroller = new TextScroller(12, 1, 2)
        scroller.zIndex = displaySong.zIndex
        scroller.position.set(displaySong.x - 6, displaySong.y - 30)

        scroller.setText(getText('musicmix.game.init.scroller'))

        return scroller
    }

    mixDidEnd() {
        super.mixDidEnd()
        this.mixScroller.setText(getText('musicmix.game.init.scroller'))
    }
}
