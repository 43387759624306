import { PotcMode } from '@vmk-legacy/potc-engine'
import { Client } from '../../../Client.js'
import { EWindow, UILayer } from '../../../enums.js'
import { PotcModal } from '../../../minigame/potc/PotcModal.js'
import LegacyWindow from '../../../ui/windows/LegacyWindow.js'
import type { PiratesSignupView } from './PiratesSignupView.js'
import { PiratesStartPlayers } from './PiratesStartPlayers.js'

export class PiratesNewGameWin extends LegacyWindow {
    layer = UILayer.GameWindows

    protected override casts = ['minigame/pirates2_interface']

    data = {
        mode: PotcMode.CaptureTheFlag as PotcMode,
        level: 1
    }

    constructor(readonly signup: PiratesSignupView) {
        super('vmk_pirates_create_game')
    }

    override async windowWasBuilt(): Promise<void> {
        console.log('PiratesNewGameWin.windowWasBuilt')
        this.hide('pirates.modeselector2', 'pirates.levelselector2', 'pirates.levelselector3')

        const ctfBtn = this.getElement('pirates.ctf.mode')
        const shbBtn = this.getElement('pirates.lms.mode')

        ctfBtn.addEventListener('pointertap', () => this.setMode(PotcMode.CaptureTheFlag))
        shbBtn.addEventListener('pointertap', () => this.setMode(PotcMode.ShipBattle))

        const lvl1Btn = this.getElement('pirates.level1.preview')
        const lvl2Btn = this.getElement('pirates.level2.preview')
        const lvl3Btn = this.getElement('pirates.level3.preview')

        lvl1Btn.addEventListener('pointertap', () => this.setLevel(1))
        lvl2Btn.addEventListener('pointertap', () => this.setLevel(2))
        lvl3Btn.addEventListener('pointertap', () => this.setLevel(3))

        this.getElement('button.pirates.cancel').once('pointertap', () => {
            Client.shared.userInterface.closeWindow(EWindow.PiratesModal)
            this.signup.createGameWin = undefined
        })

        this.getElement('button.pirates.creategame').once('pointertap', () => {
            console.log('Create game!')
            Client.shared.userInterface.closeWindow(EWindow.PiratesModal)
            this.signup.createGameWin = undefined

            Client.shared.serverBroker.sendAck('potc_new_game', this.data).then((gameId: string | false) => {
                if (gameId !== false) {
                    this.signup.startPlayersWin = new PiratesStartPlayers(this.signup, gameId)
                    Client.shared.userInterface.register(new PotcModal(this.signup.startPlayersWin))
                }
            })
        })
    }

    setMode(mode: PotcMode): void {
        this.data.mode = mode

        if (mode === PotcMode.ShipBattle) {
            this.show('pirates.modeselector2')
            this.hide('pirates.modeselector1')
        } else {
            this.hide('pirates.modeselector2')
            this.show('pirates.modeselector1')
        }
    }

    setLevel(level: number): void {
        this.data.level = level
        if (level === 1) {
            this.show('pirates.levelselector1')
            this.hide('pirates.levelselector2', 'pirates.levelselector3')
        } else if (level === 2) {
            this.show('pirates.levelselector2')
            this.hide('pirates.levelselector1', 'pirates.levelselector3')
        } else {
            this.show('pirates.levelselector3')
            this.hide('pirates.levelselector1', 'pirates.levelselector2')
        }
    }
}
