import type { Container } from 'pixi.js'
import { DisplayObject } from 'pixi.js'

/**
 * Put global helpers that do not have any side effects here. For helpers that do, put them in ClientHelpers to access
 * via the client instance.
 */
export class Helpers {
    static scalePreviewImage(
        preview: Container & {
            previewScale?: number
        },
        bgWidth: number,
        bgHeight: number
    ): Container {
        let scaleFit: number

        if (Object.hasOwn(preview, 'previewScale')) {
            scaleFit = preview.previewScale
        } else {
            const imageRatio = preview.width / preview.height

            if (imageRatio < 1) {
                scaleFit = bgWidth / preview.height
            } else {
                scaleFit = bgWidth / preview.width
            }

            const maxScale = 1.75
            scaleFit = Math.min(maxScale, scaleFit)
            preview.previewScale = scaleFit
        }

        preview.scale.set(scaleFit)

        if (preview.getLocalBounds() !== null) {
            preview.x += Math.round(Math.abs(preview.getLocalBounds().x) * scaleFit)
            preview.y += Math.round(Math.abs(preview.getLocalBounds().y) * scaleFit)
        }

        preview.x += Math.floor((bgWidth - preview.width) / 2)
        preview.y += Math.floor((bgHeight - preview.height) / 2)

        return preview
    }

    static numberize(object: any): any {
        if (object instanceof DisplayObject) {
            return object
        }

        if (typeof object === 'string') {
            if (isNaN(+object)) {
                return object
            }
            return Number(object)
        }
        // tslint:disable-next-line: forin
        for (const i in object) {
            object[i] = this.numberize(object[i])
        }
        return object
    }

    static delay(milliseconds: number): Promise<void> {
        return new Promise<void>((resolve) => setTimeout(resolve, milliseconds))
    }

    static mapFurniCoord(x: number, y: number): [number, number] {
        return [x * 32 - y * 32, y * 16 + x * 16]
    }
}
