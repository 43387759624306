import { gsap } from 'gsap'
import { Container } from 'pixi.js'
import { Client } from '../Client.js'
import { Constants } from '../Constants.js'
import type { EntityRoomIdentifier } from '../enums.js'
import { ChatBubble } from './ChatBubble.js'
import { AvatarEntity } from './entities/AvatarEntity.js'
import type { RoomViewer } from './renderer/RoomViewer.js'

export class ChatView extends Container {
    private autoShiftInterval: any
    bubbles: ChatBubble[] = []
    private queue: ChatBubble[] = []
    private shifting = false
    private readonly SHIFT_DELAY_MS: number = 7000
    private readonly SHIFT_TIME: number = 0.35
    private readonly SHIFT_DISTANCE: number = 25
    private readonly START_Y: number = 175
    private queueInterval: number

    constructor(readonly room: RoomViewer) {
        super()
    }

    teardown(): void {
        gsap.killTweensOf(this.bubbles)

        for (const bub of this.bubbles) {
            bub.destroy({ children: true })
        }
        this.bubbles.length = 0
        for (const bub of this.queue) {
            bub.destroy({ children: true })
        }
        this.queue.length = 0

        window.clearInterval(this.queueInterval)
        window.clearInterval(this.autoShiftInterval)

        this.removeChildren().forEach((c) => c.destroy())
    }

    pushMessage({
        message,
        entityId,
        invisible,
        tween = true
    }: {
        message: string
        entityId: EntityRoomIdentifier
        invisible?: boolean
        tween?: boolean
    }): void {
        const entity = this.room.getEntityByRef(entityId)
        if (!entity) {
            return
        }
        const ign = entity.getName()
        let tint = entity.getVisual().getChatTint() ?? 182

        if (entity instanceof AvatarEntity && entity.isStaff()) {
            tint = 205
        }

        const bubble = new ChatBubble(ign, message, tint)
        if (invisible) {
            bubble.alpha = 0.75
        }

        bubble.load().then(() => {
            bubble.y = Constants.SIZE[1] - 400

            if (entity) {
                bubble.xAnchor = Math.round(entity.visual.x - Math.round(bubble.width / 2) + 400) / 800
                Client.shared.roomViewer?.turnToFace(entity)
            } else {
                bubble.xAnchor = 0.5
            }
            bubble.x = bubble.xAnchor * Constants.SIZE[0]

            if (bubble.x < 0) {
                bubble.x = 0
            } else if (bubble.x > Constants.SIZE[0] - bubble.width) {
                bubble.x = Constants.SIZE[0] - Math.floor(bubble.width)
            }

            if (tween) {
                this.queue.push(bubble)
            } else {
                this.bubbles.push(bubble)
                this.addChild(bubble)
                this.shift(false)
            }
        })
    }

    // Snap bubbles to the screen when the viewport is resized.
    snapBubbles(): void {
        this.bubbles.forEach((bubble) => {
            const newX = bubble.xAnchor * Constants.SIZE[0]

            if (newX < 5) {
                bubble.x = 5
            } else if (newX > Constants.SIZE[0] - bubble.width - 5) {
                bubble.x = Constants.SIZE[0] - Math.floor(bubble.width) - 5
            } else {
                bubble.x = newX
            }
        })
    }

    startAutoShifting(): void {
        this.queueInterval = window.setInterval(() => this.addNextFromQueue(), 100)
        this.autoShiftInterval = window.setInterval(() => {
            if (!this.shifting) {
                this.shift()
            }
        }, this.SHIFT_DELAY_MS)
    }

    pause(): void {
        clearInterval(this.queueInterval)
        clearInterval(this.autoShiftInterval)
    }

    private addNextFromQueue(): void {
        if (this.queue.length > 0 && !this.shifting) {
            const newBubble = this.queue.shift()
            this.bubbles.push(newBubble)
            this.addChild(newBubble)
            this.shift(true, newBubble.height)
            this.clearAutoShiftInterval()
            // if (newBubble.entity) {
            // 	newBubble.entity.visual.speak(Math.ceil(newBubble.getMessage().length / 10) * 2);
            // }
        }
    }

    private clearAutoShiftInterval(): void {
        window.clearInterval(this.autoShiftInterval)
        this.autoShiftInterval = window.setInterval(() => {
            if (!this.shifting) {
                this.shift()
            }
        }, this.SHIFT_DELAY_MS)
    }

    private shift(tween = true, useDistance?: number): void {
        this.removeOffscreenBubbles()

        if (!this.bubbles.length) {
            this.shifting = false
            return
        }

        if (document.visibilityState === 'hidden') {
            tween = false
        }

        if (tween) {
            this.shifting = true

            let shiftDistance = this.SHIFT_DISTANCE

            if (useDistance) {
                shiftDistance = useDistance
            }

            gsap.to(this.bubbles, {
                duration: this.SHIFT_TIME,
                y: '-=' + shiftDistance,
                onComplete: () => {
                    this.shifting = false
                }
            })
        } else {
            this.bubbles.forEach((b) => (b.y -= this.SHIFT_DISTANCE))
            this.shifting = false
        }
    }

    removeAllBubbles(): void {
        this.shifting = true
        this.queue.forEach((q) => q.destroy())
        this.queue = []
        gsap.killTweensOf(this.bubbles)
        this.bubbles.forEach((b) => b.destroy())
        this.bubbles = []
        this.shifting = false
    }

    private removeOffscreenBubbles(): void {
        for (const bubble of this.bubbles) {
            if (bubble.toGlobal(bubble.position).y <= -20) {
                bubble.destroy()
                this.bubbles.splice(this.bubbles.indexOf(bubble), 1)
            }
        }
    }

    override destroy(options?: {
        children?: boolean
        texture?: boolean
        baseTexture?: boolean
    }): void {
        super.destroy(options)

        this.teardown()
    }
}
