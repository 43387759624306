import { Client } from '../../Client.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class ReloadDataModule extends ServerEvent {
    static readonly type = MessageType.RELOAD_DATA

    async handle(data: string[]) {
        if (!data.length) {
            return
        }
        const externals = await Client.shared.api.loadExternals(data)

        if (externals.furnidata) {
            Client.shared.furniMapMgr.parse(externals.furnidata)
        }
        if (externals.clothing) {
            Client.shared.figuresMgr.fill(externals.clothing)
        }
    }
}
