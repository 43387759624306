import { AvatarOutfit } from '@vmk-legacy/render-utils'
import { getVar } from '../../../assets/ExternalConfigManager.js'
import { Client } from '../../../Client.js'
import { jcRoomStartData } from '../../../minigame/jungle_cruise/JCRoomStartData.js'
import { JungleCruiseBoat } from '../../../minigame/jungle_cruise/JungleCruiseBoat.js'
import { RandomUtil } from '../../../util/RandomUtil.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export class BoatRoomHandler extends WalkableRoomExtension {
    static override identifier = 'jcBoats'

    override requireCasts = [`minigame/junglecruise_boat${Client.shared.holiday === 'xmas' ? '.xmas' : ''}`]

    // This uses the class's roomIdentifier property to get the JC room start data

    private boatLaunchEvent = 'boat_launch'

    override async init(): Promise<this> {
        await super.init()

        console.log('Initializing BoatRoomHandler for ' + this.room.layoutIdentifier)

        Client.shared.serverBroker.onEvent(this.boatLaunchEvent, (payload: any) => {
            this.launchBoat(payload.color)
        })
        return this
    }

    override teardown(): void {
        super.teardown()

        Client.shared.serverBroker.offEvent(this.boatLaunchEvent)
    }

    private launchBoat(color: string): void {
        console.log('Launching boat of color: ' + color)
        console.log('Boat incoming')
        const possibleHeads = [1, 2, 3, 4]
        const possibleHair = getVar('global_hair')
        const possibleHats = ['81f82fb492', 'f7bb8677ce', '0c5f0511ff', '6120b7bcea', 'c1040e57f4', '']
        const possibleFces = [1, 2, 3, 6, 8, 9]
        const possibleEyes = [1, 2, 3, 4, 5, 6, 7, 8, 9]
        const possibleShirts = [
            '309235823c',
            'f284e0884a',
            '639c132422',
            'ad0643b0d3',
            '682c60deac',
            '15679281a2',
            '9ad244f962',
            '31d0bd0138',
            'aa4ddcb9e0',
            '9657f2a69e',
            '1eddd97ac1',
            '70a443ab9f',
            '5974380d61',
            '4b46a0d46a',
            '50c99a0afb',
            '13eaef5cab',
            '54134a424a',
            'c8d5abdd19',
            '05cb5bb177',
            '133fffcbc1',
            '79f01e91ef',
            '3a58fed277',
            '0cf454a315',
            'c8b2450b66',
            '20e0e2ff8a',
            'c52a40474e',
            'fdb088b52d',
            'a7a2a77563',
            '6b8f7b027f',
            '036e32a2f5',
            '366be7907e',
            '7dc3393be6',
            'ba17d3db7e'
        ]
        const possiblePants = [
            '0fa40c6472',
            'cd6a175034',
            '9ebe7a2a77',
            '4393d01d84',
            'f875294515',
            '0a0dd1f1a1',
            '43c2ba1fd8',
            '416879fbc9',
            '0910813b08',
            '0a8b48bcab',
            'a4865fc463',
            '8a7d3ab39e',
            '1781f319c1',
            'a12cbc7113',
            '7fb7a771f0',
            'beff1e7615',
            'fd614f1134',
            'add20af4f4',
            '3df0bffeb1',
            '6dcd655080',
            'a65c73361b',
            'c8cfee119b',
            'f1dba51c8c'
        ]
        const possibleShoes = [
            '49857b21a0',
            'a8718691d0',
            'ba68096427',
            '804d1316fc',
            'af37f6ac0d',
            '86f8be266e',
            'b2c745698f',
            '9007ef2535',
            'a6c952cd9c',
            '85f2bf12ae',
            'de01b98358'
        ]
        const possibleHairColors = [
            7, 8, 9, 10, 11, 12, 19, 20, 21, 22, 24, 25, 26, 29, 31, 32, 37, 38, 52, 53, 66, 78, 99, 150
        ]
        const possibleSkinColors = [16, 17, 19, 22, 24, 20, 15, 21, 23, 18, 13, 112, 130, 161, 162, 180, 182]
        const outfit = new AvatarOutfit({
            headId: RandomUtil.getRandomElementFromArray(possibleHeads),
            eyeId: RandomUtil.getRandomElementFromArray(possibleEyes),
            faceId: RandomUtil.getRandomElementFromArray(possibleFces),
            hairId: RandomUtil.getRandomElementFromArray(possibleHair),
            hatId: RandomUtil.getRandomElementFromArray(possibleHats),
            shirtId: RandomUtil.getRandomElementFromArray(possibleShirts),
            pantsId: RandomUtil.getRandomElementFromArray(possiblePants),
            shoesId: RandomUtil.getRandomElementFromArray(possibleShoes),
            hairTint: RandomUtil.getRandomElementFromArray(possibleHairColors),
            skinTint: RandomUtil.getRandomElementFromArray(possibleSkinColors),
            eyeTint: 1
        })
        const roomStartData = jcRoomStartData[this.room.layoutIdentifier]
        const boat = new JungleCruiseBoat(color, roomStartData.direction, outfit)

        boat.zIndex = roomStartData.boatIndex
        this.room.sprites.addChild(boat)
        this.room.sprites.sortChildren()
        if (roomStartData.direction === 'up') {
            boat.position.set(roomStartData.startPos.x - 125 - 400, roomStartData.startPos.y + 50 - 300)
            this.tween(boat, 30, {
                x: roomStartData.startPos.x + 900 - 400, //982 - 400,
                y: roomStartData.startPos.y - 400 - 300 //145 - 300
            }).then(() => boat.destroy())
        } else {
            boat.position.set(roomStartData.startPos.x - 125 - 400, roomStartData.startPos.y - 50 - 300)
            this.tween(boat, 30, {
                x: roomStartData.startPos.x + 900 - 400, //982 - 400,
                y: roomStartData.startPos.y + 400 - 300 //145 - 300
            }).then(() => boat.destroy())
        }
    }
}
