export class Fonts {
    static readonly Foxley_16 = {
        fontName: 'Foxley',
        fontSize: 16
    }
    static readonly Foxley_12 = {
        fontName: 'Foxley',
        fontSize: 12
    }
    static readonly FoxleyBold_16 = {
        fontName: 'FoxleyBold',
        fontSize: 16
    }
    static readonly Folio_12 = {
        fontName: 'Folio',
        fontSize: 12
    }
}
