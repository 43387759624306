import { Client } from '../../Client.js'
import { EWindow } from '../../enums.js'
import type { MessengerWindow } from '../../ui/windows/messenger/MessengerWindow.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class FriendUpdate extends ServerEvent {
    static readonly type = MessageType.FRIEND_UPDATE

    async handle(data: {
        id: number
        ign: string
        lastOnline: string
        roomId: number | null
        roomName: number | null
        signature: string
        isOnline: boolean
    }) {
        const msgWin = (await Client.shared.userInterface.getWin(EWindow.Messenger)) as MessengerWindow
        if (!msgWin) {
            return
        }
        const friends = msgWin.friendsView.getFriendsData()
        const existing = friends.find((f) => f.id === data.id)

        if (existing) {
            existing.ign = data.ign
            existing.lastOnline = data.lastOnline
            existing.roomId = data.roomId
            existing.roomName = data.roomName
            existing.signature = data.signature
            existing.isOnline = data.isOnline
        } else {
            friends.push(data)
        }

        msgWin.friendsView.repopulate()
    }
}
