import { Client } from '../../Client.js'
import type { EntityRoomIdentifier } from '../../enums.js'
import { EWindow } from '../../enums.js'
import type { AvatarVisual } from '../../room/entities/AvatarVisual.js'
import { MessageType } from '../MessageType.js'
import { ServerEvent } from '../ServerEvent.js'

export class EntityStopModule extends ServerEvent {
    static readonly type = MessageType.ENTITY_STOP

    async handle(data: {
        entity: string
        actions: any
        ref?: number
    }): Promise<void> {
        const entityId: EntityRoomIdentifier = data.entity

        let secondAv: AvatarVisual
        if (entityId === Client.shared.selfRecord.getRoomIdentifier()) {
            secondAv = (await Client.shared.userInterface.getWin(EWindow.Character))?.avatar

            secondAv?.stopWalking()
        }

        const entity = Client.shared.roomViewer?.getEntityByRef(entityId)
        entity?.getVisual().stopWalking()
    }
}
