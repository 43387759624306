import { Client } from '../../../Client.js'
import type { Animation } from '../../Animation.js'
import { WalkableRoomExtension } from './WalkableRoomExtension.js'

export enum RocketState {
    Gone = 0,
    Stopped = 1
}

export class TomorrowlandRocketRideHandler extends WalkableRoomExtension {
    static override identifier = 'hubRocket'

    animation: Animation
    private rocketStopFront: Animation
    private rocketEnter: Animation
    private rocketStop: Animation
    private rocketStopRear: Animation
    private rocketLeaveBlur: Animation

    protected state: RocketState

    async init() {
        await super.init()

        const aniMap = this.room.animationMap
        this.rocketStopFront = aniMap.get('vmk_anim_1')
        this.rocketEnter = aniMap.get('vmk_anim_5')
        this.rocketStop = aniMap.get('vmk_anim_6')
        this.rocketStopRear = aniMap.get('vmk_anim_7')
        this.rocketLeaveBlur = aniMap.get('vmk_anim_8')
        /*

        "vmk_anim_1": "hub_rocketride_stop_front.ani",
        "vmk_anim_2": "tmrrwlandhub_bgrrocket.ani",
        "vmk_anim_3": "tmrrwlandhub_skybuckets.ani",
        "vmk_anim_4": "tmrrwlandhub_moonliners.ani",
        "vmk_anim_5": "hub_rocketride_enter.ani",
        "vmk_anim_6": "hub_rocketride_stop1.ani",
        "vmk_anim_7": "hub_rocketride_stop_rear.ani",
        "vmk_anim_8": "hub_rocketride_leave_blur.ani"
             */
        ;[this.rocketStop, this.rocketEnter, this.rocketStopRear, this.rocketStopFront, this.rocketLeaveBlur].forEach(
            (ani) => {
                ani.score.autoplays = false
                ani.score.loops = false
                ani.score.hidesWhenPaused = true
            }
        )
        this.rocketStopFront.score.loops = true
        this.rocketStopRear.score.loops = true
        this.rocketStop.score.hidesWhenPaused = false

        return this
    }

    receiveInitialData(data?: any) {
        super.receiveInitialData(data)

        this.state = data.state
    }

    async roomWillReveal() {
        if (this.state === RocketState.Stopped) {
            await this.rocketStop.score.playOnce()
            await this.rocketStopFront.score.playFromBeginning(true)
            await this.rocketStopRear.score.playFromBeginning(true)
        }

        Client.shared.serverBroker.onEvent('rocket_update', (data: { state: RocketState }) => {
            console.log('New rocket state from server: ' + data.state)

            if (data.state === RocketState.Gone) {
                this.rocketLeft()
            } else if (data.state === RocketState.Stopped) {
                this.rocketArrived()
            }

            this.state = data.state
        })
    }

    async rocketArrived() {
        await this.rocketEnter.score.playOnce()
        await this.rocketStop.score.playOnce()
        await this.rocketStopFront.score.playFromBeginning(true)
        await this.rocketStopRear.score.playFromBeginning(true)
    }

    async rocketLeft() {
        await this.rocketStop.score.reset(1, true)
        await this.rocketStopFront.score.reset(1, true)
        await this.rocketStopRear.score.reset(1, true)
        ;[
            ...this.room.namedTiles.get('sit_rocket_1').getEntities(),
            ...this.room.namedTiles.get('sit_rocket_2').getEntities()
        ].forEach((e) => (e.visual.visible = false))
        await this.rocketLeaveBlur.score.playOnce()
    }

    teardown() {
        super.teardown()

        Client.shared.serverBroker.offEvent('rocket_update')
    }
}
